import { useAuth } from "contexts/AuthProvider";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Unauthorized = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser && currentUser.permissions.authorized) {
      navigate("/panel");
    }
  }, [currentUser, navigate]);

  return (
    <div className="authentication">
      <div className="header">
        <div className="logo"></div>
      </div>
      <div className="card">
        <div className="card-body">
          <div>
            <h2 style={{ color: "black" }}>Unauthorized</h2>
            <div
              style={{
                fontWeight: "400",
                fontSize: "11px",
                lineHeight: "14px",
                color: "#6B778C",
              }}
            >
              You do not have access to this module.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Unauthorized;
