import { useState, useEffect, useCallback, useRef } from 'react'
import { getContactsAutocomplete } from 'api/registries'
import { useDebounce } from '@royan-co/user-interface'
import axios from 'axios'

const useContactAutocomplete = () => {
  const controller = useRef()
  const [contactAutocompleteData, setContactAutocompleteData] = useState([])
  const [term, setTerm] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const debouncedTerm = useDebounce(term)

  const getOptions = useCallback(async () => {
    try {
      setIsLoading(true)
      if (controller.current) {
        controller.current.abort()
      }

      controller.current = new AbortController()
      const data = await getContactsAutocomplete(debouncedTerm, controller.current)

      setContactAutocompleteData(data)
      setIsLoading(false)
    } catch (error) {
      if (!axios.isCancel(error)) {
        setIsLoading(false)
        console.error(error)
      }
    }
  }, [debouncedTerm])

  useEffect(() => {
    if (debouncedTerm) getOptions()
  }, [debouncedTerm, getOptions])

  return {
    contactAutocompleteData,
    setTermContactAutocomplete: setTerm,
    isLoadingContactAutocomplete: isLoading,
  }
}

export default useContactAutocomplete
