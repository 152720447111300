import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { getPaymentConditions } from 'api/accounting'

const usePaymentConditions = () => {
  const { isLoading, data } = useQuery(['payment-conditions'], getPaymentConditions, {
    retry: 2,
    staleTime: Infinity,
  })

  const paymentConditionOptions = useMemo(() => {
    if (!data) return []

    return data.map((c) => ({ label: c.description, value: c.code }))
  }, [data])

  return { paymentConditionOptions, isLoadingPaymentConditions: isLoading }
}

export default usePaymentConditions
