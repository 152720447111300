import get from 'lodash/get'
import { allCountries as countries } from '/node_modules/@atlaskit/select/dist/es2019/data/countries.js'

/**
 * helper function to find option item from a list
 * @param {Array.<{myNumber: Number, myString: String|Number}>} options
 * @param {String|Number} value
 * @returns {Object|undefined}
 */
export const findOption = (options, value) => {
  return options.find((o) => o.value === value)
}

/**
 * helper function to filter option item from a list by values
 * @param {Array.<{value: String|Number, label: String|Number}>} options
 * @param {Array.<String|Number>} value
 * @returns {<{value: String|Number, label: String|Number}>|undefined}
 */
export const filterOptions = (options, values) => {
  return options.filter((o) => values.includes(o.value))
}

/**
 * helper function to transform select field input and output by value
 * @param {Array.<{value: String|Number, label: String|Number}>} options
 * @returns {<{input: Function, output: Function}>}
 */
export const transformSelectField = (options) => {
  return {
    input: (v) => findOption(options, v) || null,
    output: (opt) => (opt?.value !== undefined ? opt.value : null),
  }
}

/**
 * helper function to transform select field input and output by value
 * @param {Array.<{value: String|Number, label: String|Number}>} options
 * @returns {<{input: Function, output: Function}>}
 */
export const transformMultiSelectField = (options) => {
  return {
    input: (v) => filterOptions(options, v || []),
    output: (opts) => opts.map((o) => o.value),
  }
}

export const getCountry = (abbr) => {
  return countries.find((c) => c.abbr === abbr)
}

export const addHttp = (url) => {
  if (typeof url === 'string' && !url.match(/^(?:f|ht)tps?:/g)) {
    url = 'http://' + url
  }

  return url
}

/**
 * get the property value if not null or undefined.
 * @param {*} obj The object to query.
 * @param {string} path The path of the property to get.
 * @param {*} defaultValue  The value returned if the resolved value is null or undefined.
 */
export const getFilled = (obj, path, defaultValue) => {
  const result = get(obj, path)

  return result == null ? defaultValue : result
}

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const getAddressLabel = (address) => {
  return [address.address, address.city, address.cap, address.country_iso]
    .filter((i) => i)
    .join(', ')
}

export const getContactLabel = (contact) => {
  return [contact.full_name, contact.email, contact.full_cellphone].filter((i) => i).join(', ')
}

export const getFullAddress = (address) => {
  let fullAddress = ''

  if (address?.address) fullAddress = fullAddress + address.address + ' \n'
  if (address?.city) fullAddress = fullAddress + address.city + ' - '

  fullAddress =
    fullAddress +
    [getCountry(address?.country_iso)?.name, address?.cap, address?.telephone, address?.fiscal_code]
      .filter((a) => a)
      .join(' ')

  return fullAddress
}
