import { v4 as uuidv4 } from 'uuid'

// Atlassian
import { ErrorMessage } from '@atlaskit/form'
import Toggle from '@atlaskit/toggle'

// Interfaces
import { Field, useFieldArray, useFormValues, useDisclosure } from '@royan-co/user-interface'

//Components
import Card from 'components/UI/Card'
import EditFinanceTableFields from './TableFields'
import RegistrySelect from 'components/Registries/RegistrySelect'
import EditFinanceSurchargeModal from './SurchargeModal'

const SupplierTable = ({ supplier, supplierIndex, removeSupplier }) => {
  const [isOpenSurchargeModal, surchargeModalHandler] = useDisclosure('surcharge')

  const { append: appendSurcharge, remove: removeSurcharge } = useFieldArray({
    name: `suppliers.${supplierIndex}.surcharges`,
  })

  const openSurchargeModal = () => {
    surchargeModalHandler.open()
  }

  const addSurcharge = (data) => {
    appendSurcharge({
      _id: uuidv4(),
      name: '',
      quantity: 1,
      rate: 1,
      calculation_method: 0,
      cost: 0,
      quotation: 0,
      vat: '',
      ...data,
    })
  }

  return (
    <div className="overflow-auto w-full inline-block -mb-6 removeAtlaskitTableBorder lessPaddingAtlaskitTable editFinanceAtlaskitTable">
      <EditFinanceTableFields
        supplier={supplier}
        addSurcharge={openSurchargeModal}
        removeSupplier={() => removeSupplier(supplierIndex)}
        removeRow={(i) => removeSurcharge(i)}
        supplierKeyName={`suppliers.${supplierIndex}`}
        keyName={`suppliers.${supplierIndex}.surcharges`}
      />

      <EditFinanceSurchargeModal
        onSubmit={addSurcharge}
        isOpen={isOpenSurchargeModal}
        onClose={surchargeModalHandler.close}
      />
    </div>
  )
}

const EditFinanceSuppliersCard = () => {
  const { suppliers } = useFormValues()
  const { remove: removeSupplier } = useFieldArray({ name: `suppliers` })

  return (
    <>
      {suppliers.map((supplier, i) => (
        <Card className="mb-4" key={supplier._id}>
          <div className="grid md:grid-cols-2 md:gap-4 mb-6">
            <Field
              isRequired
              type="select"
              name={`suppliers.${i}.supplier`}
              label="Supplier"
              className="!mt-0"
            >
              {({ fieldProps, error }) => (
                <>
                  <RegistrySelect
                    registryType="supplier"
                    defaultRegistryId={supplier.supplier_id}
                    {...fieldProps}
                  />
                  {error && <ErrorMessage>{error}</ErrorMessage>}
                </>
              )}
            </Field>

            <Field
              type="checkbox"
              name={`suppliers.${i}.selected`}
              label="Use this supplier"
              className="!mt-0"
            >
              {({ fieldProps }) => (
                <div>
                  <Toggle {...fieldProps} />
                </div>
              )}
            </Field>
          </div>

          <SupplierTable supplier={supplier} supplierIndex={i} removeSupplier={removeSupplier} />
        </Card>
      ))}
    </>
  )
}

export default EditFinanceSuppliersCard
