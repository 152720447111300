import Lozenge from '@atlaskit/lozenge'
import { findOption } from 'utils/helpers'
import { registryAccountingArcaOptions } from './constants'

const RegistryAccountingArcaTag = ({ value }) => {
  const option = findOption(registryAccountingArcaOptions, value)

  return option ? <Lozenge appearance={option.appearance}>{option.label}</Lozenge> : null
}

export default RegistryAccountingArcaTag
