import { useMemo } from 'react'
import { Link } from 'react-router-dom'

//Utils
import { formatNumber } from 'utils/numbers'

//Icons
import { CheckCircleIcon } from '@royan-co/user-interface'

//Atlassian
import Lozenge from '@atlaskit/lozenge'
import Heading from '@atlaskit/heading'

//Components
import RowTable from 'components/UI/RowTable'

//Interfaces
import { ShortcutIcon } from '@royan-co/user-interface'

const ConsolidationViewEntranceCard = ({ entrance, isOpen, handleOpenCard }) => {
  const isCompleted = useMemo(
    () => entrance?.total_collies && entrance?.total_collies === entrance?.loaded_collies,
    [entrance?.loaded_collies, entrance?.total_collies]
  )

  const isInProgress = useMemo(
    () =>
      entrance?.total_collies &&
      entrance?.total_collies !== entrance?.loaded_collies &&
      entrance?.loaded_collies > 0,
    [entrance?.loaded_collies, entrance?.total_collies]
  )

  const consigneeRows = [
    {
      title: 'Shipment reference',
      content: entrance?.shipment_id ? (
        <Link to={`/panel/shipments/${entrance?.shipment_id}`} target="_blank">
          {entrance?.shipment?.reference} <ShortcutIcon size={16} />
        </Link>
      ) : (
        <Lozenge>Not set</Lozenge>
      ),
    },
    {
      title: 'Consignor',
      content: entrance?.shipment?.shipper?.company_name || <Lozenge>Not set</Lozenge>,
    },
    {
      title: 'Consignee',
      content: entrance?.shipment.consignee?.company_name || <Lozenge>Not set</Lozenge>,
    },
  ]

  const entranceRows = [
    {
      title: 'Weight of consignment',
      content: entrance?.weight ? (
        `${formatNumber(entrance?.weight)} Kgs`
      ) : (
        <Lozenge>Not set</Lozenge>
      ),
    },
    {
      title: 'No. of collies (Pkgs)',
      content: entrance?.total_collies || 0,
    },
    {
      title: 'Loaded collies (Pkgs)',
      content: entrance?.loaded_collies || 0,
    },
  ]

  return (
    <div className="shadow rounded mt-4 cursor-pointer" onClick={handleOpenCard}>
      <div className="px-4 py-5 flex justify-between items-start">
        <div>
          <Heading level="h500">Entrance {entrance?.numerator}</Heading>
          <small className="whitespace-nowrap text-neutral-200">
            ({entrance?.loaded_collies || 0}/{entrance?.total_collies || 0})
          </small>
        </div>

        {isInProgress ? (
          <Lozenge appearance="inprogress">In progress</Lozenge>
        ) : isCompleted ? (
          <div className="self-center">
            <CheckCircleIcon className="text-green-400" size={24} />
          </div>
        ) : (
          <Lozenge appearance="default">Pending</Lozenge>
        )}
      </div>

      {isOpen && (
        <>
          <div className="px-4 pb-4 -mt-3 text-neutral-200">
            {consigneeRows?.map((item, index) => (
              <div className="mb-1" key={index}>
                <h5 className="text-xs font-semibold py-2.5 pb-1">{item.title}</h5>

                <p className="text-neutral-800">{item.content}</p>
              </div>
            ))}
          </div>

          <div className="p-4 pb-5 border-t border-solid border-neutral-40">
            <RowTable rows={entranceRows} width="w-3/5" />
          </div>
        </>
      )}
    </div>
  )
}

export default ConsolidationViewEntranceCard
