import { useMemo } from 'react'
import { Link } from 'react-router-dom'

// Utils
import { formatMonetaryNumber, formatNumber } from 'utils/numbers'

//Atlassian
import Lozenge from '@atlaskit/lozenge'
import EmptyState from '@atlaskit/empty-state'
import { DynamicTableStateless } from '@atlaskit/dynamic-table'

//Interfaces
import { ShortcutIcon } from '@royan-co/user-interface'

// Components
import Card from 'components/UI/Card'

const shipmentsHead = {
  cells: [
    { content: 'Shipment' },
    { content: 'Shipper' },
    { content: 'Consignee' },
    { content: 'Chargeable weight' },
    { content: 'Precarriage' },
    { content: 'House charter' },
    { content: 'Total cost' },
    { content: 'Quotation' },
    { content: 'Margin' },
  ],
}

const ConsolidationFinanceTable = ({ rows = [] }) => {
  const isNegative = (value) => value < 0 ? 'negative-margin' : '';
  
  const shipmentsRows = useMemo(() => {
    let body =
      rows.map((item, i) => {
        let shipmentsItems = [
          {
            id: `shipment${i}`,
            content: (
              <>
                <span className="whitespace-nowrap">
                  {item?.reference ? (
                    <Link to={`/panel/shipments/${item.id_auto}`} target="_blank">
                      {item.reference} <ShortcutIcon size={16} />
                    </Link>
                  ) : null}
                </span>

                {item?.voluminous ? (
                  <div>
                    <Lozenge appearance="moved">Voluminous</Lozenge>{' '}
                  </div>
                ) : null}

                {item?.dense ? (
                  <div>
                    <Lozenge appearance="moved">Dense</Lozenge>
                  </div>
                ) : null}
              </>
            ),
          },
          {
            id: `shipper${i}`,
            content: item?.shipper?.company_name || <Lozenge appearance="default">Not set</Lozenge>,
          },
          {
            id: `consignee${i}`,
            content: item?.consignee?.company_name || (
              <Lozenge appearance="default">Not set</Lozenge>
            ),
          },
          {
            id: `weight${i}`,
            content: item?.chargeable_weight ? (
              <span className="whitespace-nowrap">{formatNumber(item?.chargeable_weight)}</span>
            ) : (
              <Lozenge appearance="default">Not set</Lozenge>
            ),
          },
          {
            id: `precarriage${i}`,
            content: formatMonetaryNumber(item.shipmentPrecarriage),
          },
          {
            id: `house_charter${i}`,
            content: formatMonetaryNumber(item.shipmentHousecharter),
          },
          {
            id: `total_cost${i}`,
            content: formatMonetaryNumber(item.shipmentCost),
          },
          {
            id: `quotation${i}`,
            content: formatMonetaryNumber(item.shipmentQuotation),
          },
          {
            id: `margin${i}`,
            content: formatMonetaryNumber(item.shipmentMargin),
          },
        ]

        return {
          key: `shipments${i}`,
          cells: shipmentsItems,
          className: item.shipmentMargin < 0 ? 'negative-margin' : '',
        }
      }) || []

    body.push({
      key: 'total',
      cells: [
        ...new Array(shipmentsHead.cells?.length - 6 || 0).fill({
          content: '',
        }),
        {
          content: (
            <span className="whitespace-nowrap">
              {formatNumber(rows.reduce((p, c) => p + c.chargeable_weight * 1, 0)) + ' Kgs'}
            </span>
          ),
        },
        {
          content:
            formatMonetaryNumber(rows.reduce((p, c) => p + c.shipmentPrecarriage * 1, 0)) + ' €',
        },
        {
          content:
            formatMonetaryNumber(rows.reduce((p, c) => p + c.shipmentHousecharter * 1, 0)) + ' €',
        },
        {
          content: formatMonetaryNumber(rows.reduce((p, c) => p + c.shipmentCost * 1, 0)) + ' €',
        },
        {
          content:
            formatMonetaryNumber(rows.reduce((p, c) => p + c.shipmentQuotation * 1, 0)) + ' €',
        },
        {
          content: formatMonetaryNumber(
            rows.reduce((p, c) => p + c.shipmentQuotation * 1, 0) -
              rows.reduce((p, c) => p + c.shipmentCost * 1, 0)
          ),
          className: isNegative(
            rows.reduce((p, c) => p + c.shipmentQuotation * 1, 0) -
              rows.reduce((p, c) => p + c.shipmentCost * 1, 0)
          ),
        },
      ],
    })

    return body
  }, [rows])

  return (
    <>
      {rows.length === 0 ? (
        <Card className="mt-0">
          <EmptyState header="No shipments" />
        </Card>
      ) : (
        <div className="overflow-auto inline-block w-full consolidationFinanceAtlaskitTable -mb-6">
          <DynamicTableStateless head={shipmentsHead} rows={shipmentsRows} />
        </div>
      )}
    </>
  )
}

export default ConsolidationFinanceTable
