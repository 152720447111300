import React, { useMemo, useState } from 'react'

// Atlassian
import Button from '@atlaskit/button'
import { DynamicTableStateless } from '@atlaskit/dynamic-table'
import SectionMessage from '@atlaskit/section-message'
import EmptyState from '@atlaskit/empty-state'
import Textfield from '@atlaskit/textfield'

// Interfaces
import { CrossIcon, Field, Form, SearchIcon } from '@royan-co/user-interface'

// Components
import SelectRows from './SelectRows'
import Export from './Export'

const RemoteControlTable = ({
  type,
  status,
  params,
  exportParams,
  tableHead,
  onCreateRow,
  emptyState = '',
  query,
  exportMutation,
}) => {
  const [page, setPage] = useState(1)
  const [term, setTerm] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [highlightedItem, setHighlightedItem] = useState()

  const { data, isLoading, isError, refetch } = query(
    type,
    { ...params, status, page, limit: rowsPerPage, term: term || undefined },
    {
      enabled: !!status || !!type,
    }
  )

  const HighlightRow = (item) => {
    setHighlightedItem(item.id_auto)
  }

  const tableRows = useMemo(
    () =>
      data?.data?.map((item) => ({
        isHighlighted: highlightedItem === item?.id_auto,
        cells: onCreateRow(item, HighlightRow),
      })),
    [data, highlightedItem, onCreateRow]
  )

  return (
    <>
      {isError ? (
        <div className="my-2">
          <SectionMessage
            appearance="warning"
            title="Something wrong on loading data, please retry"
          >
            <Button onClick={() => refetch()}>Retry</Button>
          </SectionMessage>
        </div>
      ) : (
        <>
          <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-2 mt-2">
            <div className="flex gap-2 flex-wrap">
              <div style={{ maxWidth: '240px' }}>
                <Form onSubmit={({ term }) => setTerm(term)} defaultValues={{ term }}>
                  {({ setValue }, { submit }) => (
                    <Field name="term" className="!mt-0">
                      {({ fieldProps }) => (
                        <Textfield
                          isCompact
                          {...fieldProps}
                          placeholder="Search"
                          elemAfterInput={
                            <div className="mr-1">
                              {term ? (
                                <Button
                                  type="button"
                                  spacing="none"
                                  appearance="subtle"
                                  onClick={() => {
                                    setValue('term', '')
                                    submit()
                                  }}
                                >
                                  <CrossIcon size={16} className="text-neutral-500  -mt-1" />
                                </Button>
                              ) : (
                                <SearchIcon size={16} className="text-neutral-500  -mt-1" />
                              )}
                            </div>
                          }
                        />
                      )}
                    </Field>
                  )}
                </Form>
              </div>

              <Export type={type} params={exportParams} mutation={exportMutation} />
            </div>

            <SelectRows
              value={rowsPerPage}
              onChange={({ value }) => {
                setRowsPerPage(value)
                setPage(1)
              }}
            />
          </div>

          <div className="overflow-auto inline-block w-full mt-6">
            <DynamicTableStateless
              head={tableHead}
              rows={tableRows}
              page={page}
              onSetPage={setPage}
              rowsPerPage={rowsPerPage}
              totalRows={data?.total}
              isLoading={isLoading}
              emptyView={<EmptyState header={emptyState} />}
            />
          </div>
        </>
      )}
    </>
  )
}

export default RemoteControlTable
