import { useNavigate, useParams } from 'react-router-dom'

// Hooks
import useConsolidation from 'hooks/useConsolidation'

// Contexts
import { useAuth } from 'contexts/AuthProvider'

// Atlassian
import Button from '@atlaskit/button'

// Features
import { useCanAccess } from 'features/auth'

// Components
import { generateConsolidationUserPermissions } from 'components/Consolidations/Create/conslolidation-user-permissions'

const ConsolidationEditGeneralInformationAction = () => {
  const navigate = useNavigate()
  const { currentUser } = useAuth()
  const { consolidationId } = useParams()
  const { consolidation } = useConsolidation()

  const userPermissions = generateConsolidationUserPermissions(consolidation, currentUser)
  const canEdit = useCanAccess({ resource: 'consolidations', action: 'edit', userPermissions })

  return (
    <>
      {canEdit && (
        <Button
          appearance="primary"
          onClick={() => {
            navigate(`/panel/consolidations/${consolidationId}/edit-general-information`)
          }}
        >
          Edit general information
        </Button>
      )}
    </>
  )
}

export default ConsolidationEditGeneralInformationAction
