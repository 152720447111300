import React, { useMemo } from 'react'
import { Helmet } from 'react-helmet-async'

import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'

//Hooks
import useContact from 'hooks/useContact'

//Contexts
import { RegistryCreationProvider } from 'contexts/RegistryCreationProvider'

//Atlassian
import Spinner from '@atlaskit/spinner'
import PageHeader from '@atlaskit/page-header'
import SectionMessage from '@atlaskit/section-message'
import Button from '@atlaskit/button'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'
import Tabs, { Tab, TabList } from '@atlaskit/tabs'

//Interfaces
import { Grid, Skeleton } from '@royan-co/user-interface'

//Components
import RegistryContactCard from 'components/Registries/View/ContactCard'

const tabData = [
  { title: 'General information', path: '' },
  { title: 'Mail history', path: '/mails' },
  { title: 'Whatsapp history', path: '/whatsapp' },
]

const RegistryContactPage = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { registryId, creatorId, contactId } = useParams()

  const { contact, isError, refetch, isLoading, isFetching } = useContact(contactId)

  const tabs = useMemo(
    () =>
      tabData.map((t) => ({
        ...t,
        path: `/panel/registries/${registryId}/${
          creatorId ? `${creatorId}/` : ''
        }contact/${contactId}${t.path}`,
      })),
    [registryId, creatorId, contactId]
  )

  const selectedTabIndex = useMemo(
    () => tabs.findIndex((t) => t.path === pathname),
    [pathname, tabs]
  )

  const navigateToTab = (index) => {
    const tab = tabs[index]

    navigate(tab.path, { replace: true })
  }

  return (
    <RegistryCreationProvider>
      <Helmet>
        <title>{contact?.name}</title>
      </Helmet>

      <Grid gutter={22} className="w-full px-2.5">
        <Grid.Col md={9} lg={6} offsetMd={3}>
          <PageHeader
            breadcrumbs={
              <Breadcrumbs>
                <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
                <BreadcrumbsItem text="Companies" />
                <BreadcrumbsItem text="Registries" to="/panel/registries" component={Link} />
                <BreadcrumbsItem
                  text={isLoading ? <Spinner size="xsmall" /> : contact?.registry?.company_name}
                  to={`/panel/registries/${registryId}${creatorId ? `/${creatorId}` : ''}`}
                  component={Link}
                />
              </Breadcrumbs>
            }
          >
            {isLoading ? (
              <Skeleton height={32} maw={320} />
            ) : (
              <div className="flex items-center">
                {contact?.name}
                {isFetching && (
                  <span className="ml-3 text-xs font-light text-neutral-50">updating...</span>
                )}
              </div>
            )}
          </PageHeader>
        </Grid.Col>
      </Grid>

      {isLoading || isFetching ? (
        <div className="text-center my-8">
          <Spinner />
        </div>
      ) : isError ? (
        <Grid gutter={22} className="w-full px-2">
          <Grid.Col md={6} offsetMd={3}>
            <SectionMessage
              appearance="warning"
              title="Something wrong on loading contact data, please retry"
            >
              <Button onClick={() => refetch()}>Retry</Button>
            </SectionMessage>
          </Grid.Col>
        </Grid>
      ) : (
        <Grid gutter={22} className="w-full">
          <Grid.Col md={6} offsetMd={3}>
            <RegistryContactCard className="mx-2" />

            <div className="mt-6">
              <Tabs onChange={navigateToTab} selected={selectedTabIndex}>
                <TabList>
                  {tabs.map((tab, i) => (
                    <Tab key={i} on>
                      {tab.title}
                    </Tab>
                  ))}
                </TabList>
              </Tabs>
            </div>

            <div className="px-2 py-4">
              <Outlet />
            </div>
          </Grid.Col>
        </Grid>
      )}
    </RegistryCreationProvider>
  )
}

export default RegistryContactPage
