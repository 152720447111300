// Atlassian
import { CountrySelect } from '@atlaskit/select'
import { allCountries as countries } from '/node_modules/@atlaskit/select/dist/es2019/data/countries.js'

// Interfaces
import { Field } from '@royan-co/user-interface'

const LinesFilterFields = ({ appliedFilters }) => {
  return (
    <>
      <Field
        label="Departure country"
        name="departure_country"
        type="select"
        transform={{
          output: (c) => (c?.abbr !== undefined ? c.abbr : null),
          input: (v) => countries?.find((c) => c.abbr === v),
        }}
        className="!mt-4"
      >
        {({ fieldProps: { ref, ...fieldProps } }) => (
          <CountrySelect
            isClearable={true}
            className="w-100"
            placeholder="Choose a country"
            {...fieldProps}
          />
        )}
      </Field>

      <Field
        type="select"
        label="Arrival country"
        name="arrival_country"
        transform={{
          output: (c) => (c?.abbr !== undefined ? c.abbr : null),
          input: (v) => countries?.find((c) => c.abbr === v),
        }}
        className="!mt-4"
      >
        {({ fieldProps: { ref, ...fieldProps } }) => (
          <CountrySelect
            isClearable={true}
            className="w-100"
            placeholder="Choose a country"
            {...fieldProps}
          />
        )}
      </Field>
    </>
  )
}

export default LinesFilterFields
