// Utils
import { findOption } from 'utils/helpers'

// Constants
import { registrationTypeOptions } from './constants'

// Interfaces
import { InfoIcon } from '@royan-co/user-interface'

// Atlassian
import Lozenge from '@atlaskit/lozenge'
import Tooltip from '@atlaskit/tooltip'

const InvoiceRegistrationTag = ({ value, elaborated, error }) => {
  // we consider value 2 (Processed) as 1 (In progress) due to historical change.
  const option = findOption(registrationTypeOptions, value === 2 ? 1 : value)

  return elaborated ? (
    <Lozenge appearance="success">Elaborated</Lozenge>
  ) : option ? (
    <div className="flex items-baseline">
      <Lozenge appearance={option.appearance}>{option.label}</Lozenge>

      {option.value === 10 && error ? (
        <Tooltip content={error}>
          {(tooltipProps) => (
            <span className="text-neutral-500 ml-1" {...tooltipProps}>
              <InfoIcon size={18} />
            </span>
          )}
        </Tooltip>
      ) : null}
    </div>
  ) : null
}

export default InvoiceRegistrationTag
