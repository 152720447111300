import React from 'react'

// Hooks
import { useElaborateInvoice } from 'features/invoice/api/elaborateInvoice'

//Atlassian
import Modal, {
  ModalBody,
  ModalTitle,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import Button, { LoadingButton } from '@atlaskit/button'

//Interfaces
import { WarningBanner } from '@royan-co/user-interface'

const ElaborateInvoiceModal = ({ isOpen, onClose, invoiceId }) => {
  const elaborateInvoiceMutation = useElaborateInvoice({ invoiceId })

  const closeModal = () => {
    onClose()
    elaborateInvoiceMutation.reset()
  }

  const onElaborate = () => {
    elaborateInvoiceMutation.mutate('', {
      onSuccess: () => {
        closeModal()
      },
      onError: (e) => {
        console.error(e)
      },
    })
  }

  return (
    <>
      <ModalTransition>
        {isOpen && (
          <Modal onClose={closeModal}>
            <ModalHeader>
              <ModalTitle>Elaborate invoice</ModalTitle>
            </ModalHeader>
            <ModalBody>
              You are about to elaborate an invoice. Are you sure?
              {elaborateInvoiceMutation.isError && <WarningBanner />}
            </ModalBody>
            <ModalFooter>
              <Button appearance="subtle" onClick={closeModal}>
                Cancel
              </Button>
              <LoadingButton
                type="button"
                isLoading={elaborateInvoiceMutation.isLoading}
                appearance="primary"
                onClick={onElaborate}
                autoFocus
              >
                Elaborate
              </LoadingButton>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </>
  )
}

export default ElaborateInvoiceModal
