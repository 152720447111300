import { useCallback, useMemo } from 'react'

import { transformSelectField } from 'utils/helpers'

//Lodash
import toNumber from 'lodash/toNumber'
import isNumber from 'lodash/isNumber'
import toString from 'lodash/toString'

//Hooks
import useShipmentsEnumerations from 'hooks/useShipmentsEnumerations'
import useSurchargeEnumeration from 'hooks/useSurchargeEnumeration'

//Atlassian
import Button from '@atlaskit/button'
import { DynamicTableStateless } from '@atlaskit/dynamic-table'
import Textfield from '@atlaskit/textfield'
import Toggle from '@atlaskit/toggle'
import Select from '@atlaskit/select'

//Interfaces
import { Field, PlusIcon, TrashIcon, useFieldArray, useFormContext } from '@royan-co/user-interface'

//Components
import Divider from 'components/UI/Divider'
import { CardTitle } from 'components/UI/Card'

const RatesCreateSurchargeBrackets = ({ shipmentType }) => {
  const { getSurchargeCalculationMethod } = useSurchargeEnumeration()
  const { watch } = useFormContext()

  const surchargeId = watch('surcharge_id')

  const calculationMethod = useMemo(
    () => getSurchargeCalculationMethod(surchargeId),
    [getSurchargeCalculationMethod, surchargeId]
  )

  const { getEnumerationOptionsByKey, isLoadingShipmentEnumeration } = useShipmentsEnumerations({
    enabled: calculationMethod === 6,
  })

  const transportationOptions = useMemo(() => {
    return getEnumerationOptionsByKey(shipmentType === 0 ? 'truck_types' : 'container_types')
  }, [getEnumerationOptionsByKey, shipmentType])

  const {
    fields: bracketFields,
    append: appendBracket,
    remove: removeBracket,
  } = useFieldArray({ name: 'brackets' })

  const addBracket = useCallback(() => {
    const bracket = {
      from: '',
      to: '',
      transportation_unit: '',
      price: '',
      fix: false,
    }

    if ([1, 2, 3, 5].includes(calculationMethod)) {
      // Gross weight, Chargeable weight, Freight charge, Number of collies
      const lastIntervalValue = toNumber(watch(`brackets.${bracketFields.length - 1}.to`))
      const calculatedFromValue =
        lastIntervalValue && isNumber(lastIntervalValue) && lastIntervalValue >= 0
          ? toString(lastIntervalValue + 1)
          : ''
      bracket.from = calculatedFromValue
    }

    appendBracket(bracket)
  }, [appendBracket, bracketFields, calculationMethod, watch])

  const bracketHead = useMemo(() => {
    let bracketHeadTableByMethod = []

    if (calculationMethod === 1 || calculationMethod === 2)
      bracketHeadTableByMethod.push({ content: 'From (Kgs)' }, { content: 'To (Kgs)' })

    if (calculationMethod === 3)
      bracketHeadTableByMethod.push({ content: 'From (EUR)' }, { content: 'To (EUR)' })

    if (calculationMethod === 5)
      bracketHeadTableByMethod.push({ content: 'From (Pkgs)' }, { content: 'To (Pkgs)' })

    if (calculationMethod === 6) {
      if (shipmentType === 0) bracketHeadTableByMethod.push({ content: 'Truck type' })
      if (shipmentType === 1) bracketHeadTableByMethod.push({ content: 'Container type' })
    }

    bracketHeadTableByMethod.push(
      {
        content: (
          <span>
            Rate
            <span className="ml-0.5 font-normal text-red-400">*</span>
          </span>
        ),
      },
      { content: 'Fix' },
      { content: '' }
    )

    return {
      cells: bracketHeadTableByMethod,
    }
  }, [calculationMethod, shipmentType])

  const bracketRows = useMemo(
    () =>
      bracketFields?.map((item, index) => {
        let bracketRowsByMethod = []

        if ([1, 2, 3, 5].includes(calculationMethod)) {
          // Gross weight, Chargeable weight, Freight charge,Number of collies
          bracketRowsByMethod = [
            {
              content: (
                <Field name={`brackets.${index}.from`} className="!mt-0">
                  {({ fieldProps }) => <Textfield {...fieldProps} />}
                </Field>
              ),
            },

            {
              content: (
                <Field name={`brackets.${index}.to`} className="!mt-0">
                  {({ fieldProps }) => <Textfield {...fieldProps} />}
                </Field>
              ),
            },
          ]
        } else if (calculationMethod === 6) {
          // Transportation unit
          bracketRowsByMethod = [
            {
              content: (
                <Field
                  type="select"
                  name={`brackets.${index}.transportation_unit`}
                  transform={transformSelectField(transportationOptions)}
                  className="!mt-0"
                >
                  {({ fieldProps }) => (
                    <Select
                      isClearable={true}
                      options={transportationOptions}
                      placeholder="Choose something"
                      isLoading={isLoadingShipmentEnumeration}
                      {...fieldProps}
                    />
                  )}
                </Field>
              ),
            },
          ]
        }

        return {
          key: index,
          cells: [
            ...bracketRowsByMethod,
            {
              content: (
                <Field isRequired name={`brackets.${index}.price`} className="!mt-0">
                  {({ fieldProps }) => (
                    <Textfield
                      {...fieldProps}
                      elemAfterInput={
                        <div className="text-neutral-200 whitespace-nowrap bg-neutral-20 self-stretch flex items-center px-2">
                          Euro (€)
                        </div>
                      }
                    />
                  )}
                </Field>
              ),
            },
            {
              content: (
                <Field
                  type="checkbox"
                  name={`brackets.${index}.fix`}
                  key="awb_use"
                  className="!mt-0 !-ml-2"
                >
                  {({ fieldProps }) => <Toggle {...fieldProps} id="awb_use" />}
                </Field>
              ),
            },
            {
              content: (
                <Button
                  spacing="none"
                  appearance="subtle-link"
                  onClick={() => removeBracket(index)}
                >
                  <TrashIcon className="text-red-400" />
                </Button>
              ),
            },
          ],
        }
      }),
    [
      bracketFields,
      calculationMethod,
      isLoadingShipmentEnumeration,
      removeBracket,
      transportationOptions,
    ]
  )

  return (
    <>
      {/* calculationMethod for custom code (value = 4) is removed */}
      {[1, 2, 3, 5, 6].includes(calculationMethod) && (
        <>
          <Divider className="mt-8 mb-6" />
          <CardTitle className="mb-3">Brackets</CardTitle>

          {bracketFields.length > 0 ? (
            <div className="inline-block w-full removeAtlaskitTableBorder">
              <DynamicTableStateless head={bracketHead} rows={bracketRows} />
            </div>
          ) : (
            <div className="text-s text-neutral-200 mb-6">
              Click on New bracket to create new bracket
            </div>
          )}

          <div className="-mt-4">
            <Button appearance="link" iconBefore={<PlusIcon />} onClick={addBracket}>
              New bracket
            </Button>
          </div>
        </>
      )}
    </>
  )
}

export default RatesCreateSurchargeBrackets
