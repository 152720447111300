// Utils
import { findOption } from 'utils/helpers'
import { marginCalculatorTypeOptions } from '../../utils/constants'

// Atlassian
import Lozenge from '@atlaskit/lozenge'

const MarginCalculatorTypeTag = ({ type }) => {
  const option = findOption(marginCalculatorTypeOptions, type)

  return option ? <Lozenge appearance={option.appearance}>{option.label}</Lozenge> : null
}

export default MarginCalculatorTypeTag
