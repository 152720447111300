import axios from 'axios'
import Cookies from 'js-cookie'

const BASE_URL = process.env.REACT_APP_API_BASE_URL

const getToken = () => {
  let token = Cookies.get('auth_token')
  // let token = localStorage.getItem("auth_token");
  // if (!token) return

  // try {
  //   token = JSON.parse(token)
  // } catch (_) {
  //   console.error('error in parsing auth token.')
  // }

  return token
}

const addAuthTokenInterceptor = (instance) => {
  instance.interceptors.request.use(
    (config) => {
      config.headers['Authorization'] = `Bearer ${getToken()}`
      return config
    },
    (e) => {
      return Promise.reject(e)
    }
  )
}

const addResponseInterceptor = (instance) => {
  instance.interceptors.response.use(
    (res) => {
      if (res && res.data) return res.data
      return res
    },
    async (e) => {
      if (e.response.status === 401) {
        await new Promise(() => {
          const domain = window.location.hostname.split('.').slice(-2).join('.')
          Cookies.remove('auth_token', {
            path: '/',
            ...(domain !== 'localhost' && { domain: `.${domain}` }),
          })
          
          window.location.replace(
            `${process.env.REACT_APP_ACCOUNT_URL}/login?from=${encodeURIComponent(
              window.location.href
            )}`
          )
        })
      }
      return Promise.reject(e)
    }
  )
}

const createApiClient = () => {
  return axios.create({
    timeout: 60000,
    baseURL: BASE_URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
}

const addCSRFInterceptor = (instance) => {
  instance.interceptors.response.use(
    (res) => res,
    async (e) => {
      const config = e.config

      if (e?.response?.status !== 419 || config._retry) {
        return Promise.reject(e)
      }

      config._retry = true

      try {
        await csrf()
        return instance(config)
      } catch (error) {
        return Promise.reject(error)
      }
    }
  )
}

// TODO: use better name for axiosClient and axiosService
const axiosClient = createApiClient()
addAuthTokenInterceptor(axiosClient)
addResponseInterceptor(axiosClient)
addCSRFInterceptor(axiosClient)

const axiosService = createApiClient()
addResponseInterceptor(axiosService)
addCSRFInterceptor(axiosService)

const csrf = () => axiosClient.get('/sanctum/csrf-cookie')

export default axiosClient
export { csrf, axiosService }
