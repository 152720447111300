import { z } from 'zod'
import { SCIOptions } from '../constants'

export const airwayBillSchema = (airwaybill) => {
  const schema = z.object({
    sci: z
      .number({
        required_error: 'Please select SCI',
        invalid_type_error: 'Please select SCI',
      })
      .refine((val) => SCIOptions.map((o) => o.value).includes(val), {
        message: 'Please select SCI',
      }),
  })

  return schema
}
