import { getShipmentViewPages } from './pages'
import { useQueryClient } from '@tanstack/react-query'
import { createElement, useEffect, useMemo } from 'react'
import { matchRoutes, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'

// Hooks
import useShipment from 'hooks/useShipment'

// Utils
import { capitalizeFirstLetter } from 'utils/helpers'

// Atlassian
import Button from '@atlaskit/button'
import Spinner from '@atlaskit/spinner'
import SectionMessage from '@atlaskit/section-message'

// Interfaces
import { Grid, useFlags } from '@royan-co/user-interface'

// Components
import RequireAuth from '../RequireAuth'
import ShipmentLayoutHeader from './Header'
import ShipmentLayoutSidebar from './Sidebar'
import AppShell from 'components/UI/AppShell'
import TopNavigation from 'layouts/TopNavigation'
import ShipmentPDF from 'components/Shipments/View/PDF'
import DenyFromUserLevel from 'layouts/DenyFromUserLevel'

const ShipmentLayout = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { showWarningFlag } = useFlags()
  const { shipmentId, quotationId } = useParams()

  const entityId = shipmentId || quotationId
  const isShipment = !!shipmentId
  const entity = isShipment ? 'shipment' : 'quotation'

  const queryClient = useQueryClient()
  const { isLoading, isFetching, shipment, isError, error, refetch } = useShipment(entityId)

  const pages = useMemo(
    () => getShipmentViewPages(entityId, shipment, isShipment),
    [entityId, shipment, isShipment]
  )

  const filteredPages = useMemo(() => pages.filter((p) => p.show), [pages])

  const [{ route: currentPage }] = useMemo(() => matchRoutes(pages, location), [pages, location])

  useEffect(() => {
    if (error?.response?.status === 404) {
      queryClient.cancelQueries({ queryKey: ['shipment', entityId], exact: false })
      showWarningFlag(`${capitalizeFirstLetter(entity)} is not found!`)
      navigate(`/panel/${entity}s`, { replace: true })
    }

    if (shipmentId && shipment?.id_auto && shipment?.flag_status !== 2)
      navigate('/panel/shipments', { replace: true })
  }, [
    error,
    navigate,
    queryClient,
    showWarningFlag,
    shipmentId,
    shipment,
    quotationId,
    entityId,
    entity,
  ])

  return (
    <RequireAuth>
      <AppShell
        renderTopNavigation={(props) => <TopNavigation {...props} />}
        leftSidebar={<ShipmentLayoutSidebar pages={filteredPages} isShipment={isShipment} />}
      >
        <div className="mx-6 md:mx-8 my-6">
          <ShipmentLayoutHeader
            title={currentPage?.title}
            actions={createElement(currentPage?.actions || (() => ''), { isShipment: isShipment })}
            isShipment={isShipment}
            entityId={entityId}
            entity={entity}
          />

          <Grid grow>
            <Grid.Col sm={12} md={8} className="max-w-full">
              {isLoading || isFetching ? (
                <div className="text-center mt-24">
                  <Spinner />
                </div>
              ) : isError ? (
                <SectionMessage
                  appearance="warning"
                  title="Something wrong on loading data, please retry."
                >
                  <Button onClick={() => refetch()}>Retry</Button>
                </SectionMessage>
              ) : (
                <Outlet />
              )}
            </Grid.Col>

            <DenyFromUserLevel level="warehouse" fallback={null}>
              <Grid.Col md={4}>
                <ShipmentPDF
                  id={entityId}
                  status={shipment?.quotation_status}
                  isShipment={isShipment}
                />
              </Grid.Col>
            </DenyFromUserLevel>
          </Grid>
        </div>
      </AppShell>
    </RequireAuth>
  )
}

export default ShipmentLayout
