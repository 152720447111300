import { createElement, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { modes } from 'components/Shipments/Create/modes'

//Contexts
import { useShipmentCreationContext } from 'contexts/ShipmentCreationProvider'

//Atlassian
import Button from '@atlaskit/button'
import { ErrorMessage } from '@atlaskit/form'

//Interfaces
import {
  Field,
  ModeCheckbox,
  PlacesAutoComplete,
  useWizardFormContext,
  WizardForm,
} from '@royan-co/user-interface'
import RatesAirportsField from 'components/Rates/List/AirportsField'
import EnumerationField from 'components/Rates/List/EnumerationField'

const ShipmentCreationRoutingPage = ({ entity }) => {
  const navigate = useNavigate()
  const { nextStep } = useShipmentCreationContext()
  const isShipment = !!(entity === 'shipment')

  const {
    stepsValue: { routing },
  } = useWizardFormContext()

  const mode = useMemo(() => modes.find((m) => m.id === routing?.modeType), [routing?.modeType])
  const isAirFreight = useMemo(() => mode?.shipmentType === 2, [mode?.shipmentType])
  const isSeaFreight = useMemo(() => mode?.shipmentType === 1, [mode?.shipmentType])

  const handleRoutingStep = () => {
    navigate(nextStep.path)
  }

  return (
    <>
      <WizardForm name="routing" onSubmit={handleRoutingStep}>
        {({ setValue }) => (
          <>
            <div>
              <Field isRequired type="custom" name="modeType">
                {({ fieldProps }) => (
                  <div className="grid grid-cols-2 md:grid-cols-5 gap-4">
                    {modes.map((mode, index) => (
                      <div key={index} className="grid">
                        <ModeCheckbox
                          value={mode.id}
                          title={mode.title}
                          onChange={fieldProps.onChange}
                          description={mode.description}
                          isChecked={fieldProps.value === mode.id}
                          icon={createElement(mode.icon, { size: 48 })}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </Field>

              <div className="mt-8">
                <h3>Routing</h3>
              </div>

              <div className="shadow rounded mt-2 p-4">
                {[
                  {
                    key: 'placeOfLoading',
                    label: 'Place of loading',
                    placeholder: 'Departure',
                    airportKey: 'departure_airport',
                    airportLabel: 'Departure airport',
                    portKey: 'departure_port',
                    portLabel: 'Departure port',
                  },
                  {
                    key: 'placeOfDelivery',
                    label: 'Place of delivery',
                    placeholder: 'Destination',
                    airportKey: 'arrival_airport',
                    airportLabel: 'Arrival airport',
                    portKey: 'arrival_port',
                    portLabel: 'Arrival port',
                  },
                ].map(
                  ({ key, label, placeholder, airportKey, airportLabel, portKey, portLabel }) => (
                    <div key={key}>
                      <Field
                        key={key}
                        name={key}
                        label={label}
                        type="text"
                        isRequired="This field is required."
                        transform={{
                          output: (p) => {
                            if (p?.countryAbbr !== routing[key]?.countryAbbr) {
                              setValue(airportKey, null)
                              setValue(portKey, null)
                            }
                            return p
                          },
                        }}
                        validate={(p) =>
                          p.isEuropean && !p.zipCode ? 'Please enter a more accurate address' : true
                        }
                      >
                        {({ fieldProps, error }) => (
                          <>
                            <PlacesAutoComplete placeholder={placeholder} {...fieldProps} />
                            {error && <ErrorMessage>{error}</ErrorMessage>}
                          </>
                        )}
                      </Field>

                      {!isShipment && routing?.[key] && (
                        <>
                          {isAirFreight && (
                            <RatesAirportsField
                              name={airportKey}
                              label={airportLabel}
                              country={routing?.[key]?.countryAbbr}
                              defaultValue={routing[airportKey]}
                            />
                          )}

                          {isSeaFreight && (
                            <EnumerationField
                              name={portKey}
                              label={portLabel}
                              optionsKey="ports"
                              filterOptionsKey="country_iso"
                              filterOptionsValue={routing?.[key]?.countryAbbr}
                            />
                          )}
                        </>
                      )}
                    </div>
                  )
                )}
              </div>
            </div>

            <div className="flex justify-end mt-4">
              <Button type="submit" appearance="primary">
                Next step
              </Button>
            </div>
          </>
        )}
      </WizardForm>
    </>
  )
}

export default ShipmentCreationRoutingPage
