import { Helmet } from 'react-helmet-async'
import { useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'

// Hooks
import useShipment from 'hooks/useShipment'

//Contexts
import { useAuth } from 'contexts/AuthProvider'

// Atlassian
import PageHeader from '@atlaskit/page-header'
import Button from '@atlaskit/button'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'

// Interfaces
import { DotsIcon, useDisclosure, useRoyanTableLastFilters } from '@royan-co/user-interface'

// Components
import ShipmentStatusTimeline from 'components/Shipments/View/StatusTimeline'
import ShipmentsDeleteModal from 'components/Shipments/View/DeleteModal'
import ShipmentsShareModal from 'components/Shipments/View/ShareModal'
import ShipmentsCancelModal from 'components/Shipments/View/CancelModal'
import DuplicateShipment from 'components/Shipments/View/DuplicateShipment'
import ShipmentsCreateMasterAction from 'components/Shipments/Create/MasterAction'
import { useCanAccess } from 'features/auth'
import { generateShipmentUserPermissions } from 'components/Shipments/Create/shipment-user-permissions'
import AcceptRejectButtons from 'components/Shipments/View/AcceptRejectButtons'
import RejectQuoteModal from 'components/Shipments/View/RejectModal'
import AcceptQuoteModal from 'components/Shipments/View/AcceptModal'

const ShipmentLayoutHeader = ({ title, actions, isShipment, entityId, entity }) => {
  const { currentUser } = useAuth()
  const { shipment } = useShipment()

  const { lastUrlSearch } = useRoyanTableLastFilters(`${entity}s-list`)
  const userPermissions = generateShipmentUserPermissions(shipment, currentUser)

  const canShare = useCanAccess({ resource: 'shipments', action: 'share', userPermissions })
  const canDelete = useCanAccess({ resource: 'shipments', action: 'delete', userPermissions })
  const canCancel = useCanAccess({ resource: 'shipments', action: 'cancel', userPermissions })
  const canDuplicate = useCanAccess({ resource: 'shipments', action: 'duplicate', userPermissions })
  const canCreateMaster = useCanAccess({
    resource: 'shipments',
    action: 'createMaster',
    userPermissions,
  })

  const [isOpenDeleteShipmentModal, deleteShipmentModalHandler] = useDisclosure('delete-shipment')
  const [isOpenShareShipmentModal, shareShipmentModalHandler] = useDisclosure('share-shipment')
  const [isOpenCancelShipmentModal, cancelShipmentModalHandler] = useDisclosure('cancel-shipment')
  const [isOpenAcceptModal, acceptModalHandlers] = useDisclosure('accept-quotation')
  const [isOpenRejectModal, rejectModalHandlers] = useDisclosure('reject-quotation')

  const openDeleteModal = useCallback(
    (shipmentId) => {
      deleteShipmentModalHandler.open(shipmentId)
    },
    [deleteShipmentModalHandler]
  )

  const openCancelModal = useCallback(
    (shipmentId) => {
      cancelShipmentModalHandler.open(shipmentId)
    },
    [cancelShipmentModalHandler]
  )

  const openAcceptModal = useCallback(() => {
    acceptModalHandlers.open()
  }, [acceptModalHandlers])

  const openRejectModal = useCallback(() => {
    rejectModalHandlers.open()
  }, [rejectModalHandlers])

  const breadcrumbs = useMemo(
    () => (
      <Breadcrumbs onExpand={() => {}}>
        <BreadcrumbsItem href="/panel" text="Panel" component={Link} />
        <BreadcrumbsItem text="Operation" />
        {!isShipment ? (
          <BreadcrumbsItem to="/panel/quotations" text="Quotations" component={Link} />
        ) : (
          <BreadcrumbsItem to="/panel/shipments" text="Shipments" component={Link} />
        )}
        <BreadcrumbsItem text={isShipment ? 'View Shipment' : 'View quotation'} />
      </Breadcrumbs>
    ),
    [isShipment]
  )

  const actionsContent = useMemo(
    () => (
      <div className="flex flex-wrap justify-end gap-1">
        {actions}

        {shipment?.quotation_status !== 3 && shipment?.accepted === 0 && !isShipment && (
          <AcceptRejectButtons
            openAcceptModal={openAcceptModal}
            openRejectModal={openRejectModal}
          />
        )}

        <Button
          appearance="default"
          component={Link}
          to={`/panel/${entity}s${lastUrlSearch && `?${lastUrlSearch}`}`}
        >
          Back
        </Button>

        {(canDuplicate ||
          canCancel ||
          canDelete ||
          (isShipment && canShare) ||
          (isShipment && canCreateMaster)) && (
          <DropdownMenu
            placement="bottom-end"
            trigger={({ triggerRef, ...props }) => (
              <Button ref={triggerRef} appearance="default" children={<DotsIcon />} {...props} />
            )}
          >
            <DropdownItemGroup>
              <div style={{ minWidth: '130px' }}>
                {canShare && isShipment && (
                  <DropdownItem
                    children="Share shipment"
                    onClick={() => shareShipmentModalHandler.open(entityId)}
                  />
                )}

                {canDuplicate && <DuplicateShipment shipmentId={entityId} entity={entity} />}

                {canCancel && (
                  <DropdownItem children="Cancel" onClick={() => openCancelModal(entityId)} />
                )}

                {canDelete && (
                  <DropdownItem children="Delete" onClick={() => openDeleteModal(entityId)} />
                )}

                {!shipment?.is_master && canCreateMaster && isShipment && (
                  <ShipmentsCreateMasterAction shipmentId={entityId} />
                )}
              </div>
            </DropdownItemGroup>
          </DropdownMenu>
        )}
      </div>
    ),
    [
      actions,
      shipment,
      isShipment,
      openAcceptModal,
      openRejectModal,
      entity,
      lastUrlSearch,
      canDuplicate,
      canCancel,
      canDelete,
      canShare,
      canCreateMaster,
      entityId,
      shareShipmentModalHandler,
      openCancelModal,
      openDeleteModal,
    ]
  )
  return (
    <>
      <Helmet>
        <title>
          {isShipment ? 'Shipment' : 'Quotation'} - {title}
        </title>
      </Helmet>

      <PageHeader breadcrumbs={breadcrumbs} actions={actionsContent}>
        {title}
      </PageHeader>

      {isShipment && (
        <div className="mb-6">
          <ShipmentStatusTimeline />
        </div>
      )}

      <ShipmentsDeleteModal
        isOpen={isOpenDeleteShipmentModal}
        onClose={deleteShipmentModalHandler.close}
        shipmentId={deleteShipmentModalHandler.data}
        entity={entity}
      />

      <ShipmentsShareModal
        isOpen={isOpenShareShipmentModal}
        onClose={shareShipmentModalHandler.close}
        shipmentId={shareShipmentModalHandler.data}
      />

      <ShipmentsCancelModal
        isOpen={isOpenCancelShipmentModal}
        onClose={cancelShipmentModalHandler.close}
        shipmentId={cancelShipmentModalHandler.data}
        entity={entity}
      />

      <AcceptQuoteModal
        isOpen={isOpenAcceptModal}
        onClose={acceptModalHandlers.close}
        quotationId={entityId}
      />

      <RejectQuoteModal
        isOpen={isOpenRejectModal}
        onClose={rejectModalHandlers.close}
        quotationId={entityId}
      />
    </>
  )
}

export default ShipmentLayoutHeader
