import Button from '@atlaskit/button'
import useRegistry from 'hooks/useRegistry'
import RowTable from 'components/UI/RowTable'
import SelectCreatorModal from './SelectCreatorModal'
import { useNavigate, useParams } from 'react-router-dom'
import { RefreshIcon, Skeleton, useHistoryModal } from '@royan-co/user-interface'

const RegistryCreatorCard = ({ className = '', isEditPage = false }) => {
  const navigate = useNavigate()
  const { registryId, creatorId } = useParams()
  const {
    openModel: openModal,
    closeModel: closeModal,
    isOpenModel: isOpenModal,
  } = useHistoryModal('owners')

  const { isLoading, registry, creator } = useRegistry(registryId, { creatorId })

  const rows = [
    {
      title: 'Creator',
      content: isLoading ? (
        <Skeleton height={16} />
      ) : registry?.owners?.length > 1 ? (
        <Button appearance="link" onClick={openModal} className="!px-0">
          <div className="flex items-center font-normal">
            {creator?.user?.full_name} <RefreshIcon size={16} className="ml-1" />
          </div>
        </Button>
      ) : (
        creator?.user?.full_name
      ),
    },
    {
      title: 'Group',
      content: isLoading ? <Skeleton height={16} /> : creator?.creator_registry?.company_name,
    },
    {
      title: 'Code',
      content: isLoading ? <Skeleton height={16} /> : creator?.numerator_code,
    },
  ]

  const navigateToCreator = async (id) => {
    await new Promise((r) => setTimeout(r, 100))
    isEditPage
      ? navigate(`/panel/registries/${registryId}/${id}/edit`, { replace: true })
      : navigate(`/panel/registries/${registryId}/${id}`)
  }

  return (
    <div className={`shadow rounded p-4 ${className}`}>
      <RowTable rows={rows} />

      <SelectCreatorModal
        closeModal={closeModal}
        isOpenModal={isOpenModal}
        onChange={navigateToCreator}
        creators={registry?.owners || []}
        selectedCreatorId={creator?.user?.auto_id}
      />
    </div>
  )
}

export default RegistryCreatorCard
