import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router'
import { useQueryClient } from '@tanstack/react-query'

// Apis
import { editShipmentParam } from 'api/shipments'

// Hooks
import useShipment from 'hooks/useShipment'
import { useCanAccess } from 'features/auth'
import { useAuth } from 'contexts/AuthProvider'

// Icons
import { ShortcutIcon } from '@royan-co/user-interface'

// Atlassian
import Lozenge from '@atlaskit/lozenge'

// Components
import RowTable from 'components/UI/RowTable'
import Card from 'components/UI/Card'
import ShipmentOperatorInlineEdit from './Operator'
import ShipmentCountryInlineEdit from './Country'
import ShipmentTextInlineEdit from './Text'
import { generateShipmentUserPermissions } from 'components/Shipments/Create/shipment-user-permissions'
import ShipmentAgentInlineEdit from './Agent'
import ShipmentAirportInlineEdit from './Airport'

const ShipmentGeneralDetail = () => {
  const queryClient = useQueryClient()
  const { currentUser } = useAuth()
  const { shipmentId, quotationId } = useParams()
  const entityId = shipmentId || quotationId

  const { shipment } = useShipment()

  const canEdit = useCanAccess({
    resource: 'shipments',
    action: 'edit',
    userPermissions: generateShipmentUserPermissions(shipment, currentUser),
  })

  const changeOperatorFn = (data) =>
    editShipmentParam(entityId, 'take_in_charge_operator', {
      take_in_charge_operator: data.value,
    })

  const changeOperatorCallback = (res) =>
    queryClient.setQueryData(['shipment', entityId], (oldShipment) => ({
      ...oldShipment,
      operator: { ...res.shipment.operator },
    }))

  const changeAgentFn = (data) =>
    editShipmentParam(entityId, 'agent_id', {
      agent_id: data.value,
    })

  const changeAgentCallback = (res) =>
    queryClient.setQueryData(['shipment', entityId], (oldShipment) => ({
      ...oldShipment,
      agent: { ...res.shipment.agent },
    }))

  const generalRows = useMemo(() => {
    let generalRowsItem = []

    if (shipmentId) {
      generalRowsItem.push({
        title: 'Quotation',
        content: (
          <Link to={`/panel/quotations/${shipmentId}`} target="_blank">
            {shipment?.quotation_code} <ShortcutIcon size={16} />
          </Link>
        ),
      })
    }

    if (quotationId && shipment?.flag_status === 2) {
      generalRowsItem.push({
        title: 'Shipment',
        content: (
          <Link to={`/panel/shipments/${quotationId}`} target="_blank">
            {shipment?.shipment_code} <ShortcutIcon size={16} />
          </Link>
        ),
      })
    }

    if (quotationId)
      generalRowsItem.push({
        title: 'Inquiry mail subject',
        content: (
          <ShipmentTextInlineEdit
            canEdit={canEdit}
            shipmentId={entityId}
            attribute="subject_mail_request"
            value={shipment?.subject_mail_request}
          />
        ),
      })

    if (shipment?.consolidation_id) {
      generalRowsItem.push({
        title: 'Consolidation',
        content: (
          <Link to={`/panel/consolidations/${shipment?.consolidation_id}`} target="_blank">
            {shipment?.consolidation?.consolidation_code} <ShortcutIcon size={16} />
          </Link>
        ),
      })
    }

    if (shipment?.master_shipment?.id_auto)
      generalRowsItem.push({
        title: 'Master shipment',
        content: (
          <Link to={`/panel/shipments/${shipment?.master_shipment?.id_auto}`} target="_blank">
            {shipment?.master_shipment?.shipment_code} <ShortcutIcon size={16} />
          </Link>
        ),
      })

    generalRowsItem.push(
      {
        title: 'Departure country',
        content: (
          <ShipmentCountryInlineEdit
            canEdit={canEdit}
            shipmentId={entityId}
            attribute="departure_country"
            country={shipment?.departure_country}
          />
        ),
      },
      {
        title: 'Departure city',
        content: (
          <ShipmentTextInlineEdit
            canEdit={canEdit}
            shipmentId={entityId}
            attribute="departure_city"
            value={shipment?.departure_city}
          />
        ),
      },
      {
        title: 'Departure zip code',
        content: (
          <ShipmentTextInlineEdit
            canEdit={canEdit}
            value={shipment?.departure_cap}
            attribute="departure_cap"
            shipmentId={entityId}
          />
        ),
      }
    )

    if (shipment?.shipment_type === 2)
      generalRowsItem.push({
        title: 'Departure airport',
        content: (
          <ShipmentAirportInlineEdit
            canEdit={canEdit}
            airport={shipment?.departure_airport}
            attribute="departure_airport"
            shipmentId={entityId}
          />
        ),
      })

    generalRowsItem.push(
      {
        title: 'Arrival country',
        content: (
          <ShipmentCountryInlineEdit
            canEdit={canEdit}
            shipmentId={entityId}
            attribute="arrival_country"
            country={shipment?.arrival_country}
          />
        ),
      },
      {
        title: 'Arrival city',
        content: (
          <ShipmentTextInlineEdit
            canEdit={canEdit}
            shipmentId={entityId}
            attribute="arrival_city"
            value={shipment?.arrival_city}
          />
        ),
      },
      {
        title: 'Arrival zip code',
        content: (
          <ShipmentTextInlineEdit
            canEdit={canEdit}
            attribute="arrival_cap"
            shipmentId={entityId}
            value={shipment?.arrival_cap}
          />
        ),
      }
    )

    if (shipment?.shipment_type === 2)
      generalRowsItem.push({
        title: 'Arrival airport',
        content: (
          <ShipmentAirportInlineEdit
            canEdit={canEdit}
            airport={shipment?.arrival_airport}
            attribute="arrival_airport"
            shipmentId={entityId}
          />
        ),
      })

    if (quotationId)
      generalRowsItem.push({
        title: 'Agent',
        content: (
          <ShipmentAgentInlineEdit
            canEdit={canEdit}
            agent={shipment?.agent}
            changeAgentFn={changeAgentFn}
            changeAgentCallback={changeAgentCallback}
          />
        ),
      })
    else
      generalRowsItem.push(
        {
          title: 'Operator',
          content: (
            <ShipmentOperatorInlineEdit
              canEdit={canEdit}
              operator={shipment?.operator}
              changeOperatorFn={changeOperatorFn}
              changeOperatorCallback={changeOperatorCallback}
            />
          ),
        },
        {
          title: 'Entrance status',
          content: shipment?.has_entrance ? (
            <>
              <span className="mr-1">
                <Lozenge appearance="success">Entered in hub</Lozenge>
              </span>
              {!shipment?.entrance?.has_image && (
                <Lozenge appearance="removed">Missing entrance image</Lozenge>
              )}
            </>
          ) : (
            <Lozenge appearance="moved">Unknown</Lozenge>
          ),
        }
      )

    if (shipment?.has_entrance) {
      generalRowsItem.push(
        { title: 'Entrance date', content: shipment?.entrance?.creation_date },
        { title: 'Entrance hub', content: shipment?.entrance?.hub?.name }
      )
    }

    return generalRowsItem
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipmentId, quotationId, shipment, canEdit, entityId])

  return (
    <>
      <Card>
        <RowTable rows={generalRows} width="w-1/2" />
      </Card>
    </>
  )
}

export default ShipmentGeneralDetail
