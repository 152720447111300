import { useMemo } from 'react'
import { useMutation } from '@tanstack/react-query'
import { Helmet } from 'react-helmet-async'
import { Link, useNavigate, useParams } from 'react-router-dom'

// Apis
import { addLocalisationConsolidation } from 'api/consolidations'

// Hooks
import useConsolidation from 'hooks/useConsolidation'

// Utils
import { getFilled } from 'utils/helpers'
import { handleErrorOnSubmit } from 'utils/errors'

// Atlassian
import Button, { LoadingButton } from '@atlaskit/button'
import Spinner from '@atlaskit/spinner'
import PageHeader from '@atlaskit/page-header'
import SectionMessage from '@atlaskit/section-message'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'

// Interfaces
import { Form, Grid, WarningBannerField, useFlags } from '@royan-co/user-interface'

// Components
import ConsolidationAddLoacalisationGeneralForm from 'components/Consolidations/View/AddLoacalisationGeneralForm'
import ConsolidationAddLoacalisationDriverForm from 'components/Consolidations/View/AddLoacalisationDriverForm'
import ConsolidationAddLocalisationTable from 'components/Consolidations/View/AddLocalisationTable'

const ConsolidationViewAddLocalisationPage = () => {
  const navigate = useNavigate()
  const { showSuccessFlag } = useFlags()
  const { consolidationId } = useParams()
  const pageTitle = 'Add localisation'

  const { consolidation, isLoading, isFetching, isError, refetch } =
    useConsolidation(consolidationId)

  const loadedOnBoat = useMemo(() => !!consolidation?.loaded_on_boat, [consolidation])
  const hasReefer = useMemo(() => !!consolidation?.reefer, [consolidation])

  const addLocalisationMutation = useMutation(
    addLocalisationConsolidation.bind(null, consolidationId)
  )

  const defaultValues = useMemo(
    () => ({
      driver_name: getFilled(consolidation, 'driver_name', ''),
      driver_phone: getFilled(consolidation, 'driver_phone', ''),
      creation_date: '',
    }),
    [consolidation]
  )

  const addLocalisation = (data, { setError }) => {
    let formData = {
      ...data,
      country_iso: data?.address?.countryAbbr || null,
      city: data?.address?.city || null,
      cap: data?.address?.zipCode || null,
      lat: data?.address?.lat || null,
      lng: data?.address?.lng || null,
      address: data?.address?.label || null,
      custom_terminal: data?.custom_terminal || null,
      port_port_id: data?.port_port_id || null,
    }

    addLocalisationMutation.mutate(formData, {
      onError: (e) => {
        handleErrorOnSubmit(e, setError, formData)
        console.error(e)
      },
      onSuccess: (res) => {
        showSuccessFlag(res.message)
        navigate(`/panel/consolidations/${consolidationId}/tracking`)
      },
    })
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <Grid gutter={24} className="w-full">
        <Grid.Col md={10} lg={8} xl={6} offsetMd={1} offsetLg={2} offsetXl={3}>
          <PageHeader
            breadcrumbs={
              <Breadcrumbs>
                <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
                <BreadcrumbsItem
                  text="Consolidations"
                  to="/panel/consolidations"
                  component={Link}
                />
                <BreadcrumbsItem
                  text={isLoading ? <Spinner size="xsmall" /> : consolidation?.consolidation_code}
                  to={`/panel/consolidations/${consolidationId}`}
                  component={Link}
                />
              </Breadcrumbs>
            }
          >
            {pageTitle}
          </PageHeader>

          <div className="my-8">
            {isLoading || isFetching ? (
              <div className="text-center my-8">
                <Spinner />
              </div>
            ) : isError ? (
              <Grid gutter={24} className="w-full">
                <Grid.Col md={6} offsetMd={3}>
                  <SectionMessage
                    appearance="warning"
                    title={`Something wrong on loading consolidation data, please retry`}
                  >
                    <Button onClick={() => refetch()}>Retry</Button>
                  </SectionMessage>
                </Grid.Col>
              </Grid>
            ) : (
              <>
                <ConsolidationAddLocalisationTable consolidation={consolidation} />

                <Form onSubmit={addLocalisation} defaultValues={defaultValues}>
                  {() => (
                    <>
                      <ConsolidationAddLoacalisationGeneralForm
                        loadedOnBoat={loadedOnBoat}
                        hasReefer={hasReefer}
                      />

                      <ConsolidationAddLoacalisationDriverForm />

                      <WarningBannerField />

                      <div className="mt-6 w-full text-right">
                        <Button appearance="subtle" type="button" onClick={() => {}}>
                          Cancel
                        </Button>
                        <LoadingButton
                          appearance="primary"
                          type="submit"
                          isLoading={addLocalisationMutation.isLoading}
                        >
                          Submit
                        </LoadingButton>
                      </div>
                    </>
                  )}
                </Form>
              </>
            )}
          </div>
        </Grid.Col>
      </Grid>
    </>
  )
}

export default ConsolidationViewAddLocalisationPage
