import Lozenge from '@atlaskit/lozenge'
import { findOption } from 'utils/helpers'
import { invoiceTypeOptions } from './constants'

const InvoiceTypeTag = ({ value, activePassive }) => {
  const option = findOption(invoiceTypeOptions, !!value ? 1 : 0)
  const type = !activePassive ? 'active' : 'passive'

  return option ? (
    <Lozenge appearance={option.appearance}>
      {!value ? type + ' ' : ''}
      {option.label}
    </Lozenge>
  ) : null
}

export default InvoiceTypeTag
