import { useParams } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'

// APIS
import { uploadDocument } from 'api/consolidations'
import { uploadFileAttachment } from 'api/upload'

// Atlassian
import Button from '@atlaskit/button'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'

// Interfaces
import { PlusIcon, UploadModal, useDisclosure, useFlags } from '@royan-co/user-interface'

// Components
import ConsolidationCreateDocumentModal from './CreateDocumentModal'

const ConsolidationUploadDocument = () => {
  const { consolidationId } = useParams()
  const { showSuccessFlag } = useFlags()
  const queryClient = useQueryClient()
  const [isOpenUploadModal, uploadModalHandlers] = useDisclosure()
  const [isOpenCreateModal, createModalHandlers] = useDisclosure('create-document')

  const uploadMutation = useMutation((formData) => uploadDocument(formData, consolidationId))

  const upload = (files) => {
    if (files.length < 1) return

    const formData = {
      files: files.map((file) => ({
        title: file.title,
        tracking_share: 0,
        document_path: file.uploadResponse?.path,
      })),
    }

    uploadMutation.mutate(formData, {
      onSuccess: (res) => {
        queryClient.setQueryData(['consolidation_documents', consolidationId], (docs) => {
          return [...res?.documents, ...docs]
        })
        showSuccessFlag(res.message)
        uploadModalHandlers.close()
      },
    })
  }

  return (
    <>
      <DropdownMenu
        placement="bottom-end"
        trigger={({ triggerRef, ...props }) => (
          <Button
            ref={triggerRef}
            {...props}
            appearance="primary"
            iconBefore={<PlusIcon size={24} />}
            children={'New Document'}
          />
        )}
      >
        <DropdownItemGroup>
          <div style={{ minWidth: '100px' }}>
            <DropdownItem onClick={() => uploadModalHandlers.open()}>Upload</DropdownItem>
            <DropdownItem onClick={() => createModalHandlers.open()}>Create</DropdownItem>
          </div>
        </DropdownItemGroup>
      </DropdownMenu>

      <UploadModal
        maxFiles={5}
        onSubmit={upload}
        editableFileTitle
        useFileNameAsTitle
        isFileTitleRequired
        title="Upload document"
        isOpen={isOpenUploadModal}
        isSubmitting={uploadMutation.isLoading}
        accept={['csv', 'xls', 'pdf', 'jpg', 'jpeg', 'png']}
        uploadRequest={(file, signal) => uploadFileAttachment(file, signal)}
        error={
          uploadMutation.isError && (uploadMutation.error?.response?.data?.errors?.document || true)
        }
        onClose={() => {
          uploadMutation.reset()
          uploadModalHandlers.close()
        }}
      />

      <ConsolidationCreateDocumentModal
        isOpen={isOpenCreateModal}
        onClose={createModalHandlers.close}
        consolidationId={consolidationId}
      />
    </>
  )
}

export default ConsolidationUploadDocument
