import { useMemo } from 'react'
import { findOption, getCountry } from 'utils/helpers'

// Hooks
import useConsolidationReferenceAutocomplete from 'hooks/useConsolidationReferenceAutocomplete'

// Atlassian
import Select from '@atlaskit/select'

// Interfaces
import { Field } from '@royan-co/user-interface'

const ReferenceSelectLabelOption = ({ data }) => {
  return (
    <span>
      <span className="pr-2" style={{ minWidth: '20px', display: 'inline-block' }}>
        {getCountry(data.arrival)?.icon}
      </span>

      {data?.label}
    </span>
  )
}

const ConsolidationReferenceField = ({
  name,
  label,
  defaultValue,
  isDisabled = false,
  ...props
}) => {
  const {
    referenceAutocompleteOptions,
    setTermReferenceAutocomplete,
    isLoadingReferenceAutocomplete,
  } = useConsolidationReferenceAutocomplete()

  const options = useMemo(() => {
    if (!defaultValue) return referenceAutocompleteOptions

    return referenceAutocompleteOptions.concat([{ label: defaultValue, value: defaultValue }])
  }, [referenceAutocompleteOptions, defaultValue])

  return (
    <Field
      label={label}
      type="select"
      name={name}
      isDisabled={isDisabled}
      transform={{
        output: (opt) => (opt?.value !== undefined ? opt.value : null),
        input: (v) => findOption(options, v),
      }}
      {...props}
    >
      {({ fieldProps }) => (
        <Select
          isClearable={true}
          placeholder="Type a reference"
          options={options}
          isLoading={isLoadingReferenceAutocomplete}
          onInputChange={(newValue) => setTermReferenceAutocomplete(newValue)}
          formatOptionLabel={(data) => <ReferenceSelectLabelOption data={data} />}
          {...fieldProps}
        />
      )}
    </Field>
  )
}

export default ConsolidationReferenceField
