import { Link } from 'react-router-dom'

//Utils
import { formatNumber } from 'utils/numbers'

//Components
import CountryFlag from 'components/UI/CountryFlag'
import ShipmentModeTag from '../ModeTag'
import ShipmentStatusTag from '../StatusTag'
import ShipmentTypeTag from './../TypeTag'
import TemperatureTag from '../TemperatureTag'
import ShipmentInvoicedTag from './../InvoicedTag'
import ShipmentHubTag from './../HubTag'
import ShipmentDOTag from './../DOTag'
import DocumentTag from './../DocumentTag'
import ShipmentFinancialTag from './../FinancialTag'
import DuplicateShipment from '../View/DuplicateShipment'

//Interfaces
import { DotsIcon, Skeleton } from '@royan-co/user-interface'

//Atlassian
import Button from '@atlaskit/button'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'
import Lozenge from '@atlaskit/lozenge'
import { AccessControl } from 'features/auth'
import { generateShipmentUserPermissions } from '../Create/shipment-user-permissions'

//Components
import DropdownLink from 'components/UI/DropdownLink'

export const generateShipmentsColumns = (
  isWarehouseUser,
  openDeleteModal,
  openCancelModal,
  currentUser,
  entity
) => {
  return [
    {
      key: 'numerator_code',
      title: 'Reference',
      isSortable: false,
      entities: ['shipment', 'quotation'],
      show: true,
      content: (item) => (
        <>
          {entity === 'shipment' ? item.shipment_code : item.quotation_code}
          {item.shares?.map((share, index) => (
            <div key={index} className="block">
              <Lozenge appearance="inprogress">By {share.shared_by.company_name}</Lozenge>
            </div>
          ))}
        </>
      ),
    },
    {
      key: 'mode',
      title: 'Mode',
      isSortable: false,
      entities: ['shipment', 'quotation'],
      show: true,
      content: (item) => <ShipmentModeTag item={item} />,
    },
    {
      key: 'type',
      title: 'Type',
      isSortable: false,
      entities: ['shipment'],
      show: true,
      content: (item) => <ShipmentTypeTag type={item.is_master} />,
    },
    {
      key: 'status',
      title: 'Status',
      isSortable: false,
      entities: ['shipment', 'quotation'],
      show: true,
      content: (item) => (
        <>
          <ShipmentStatusTag
            value={entity === 'shipment' ? item.status : item.quotation_status}
            entity={entity}
          />
          {entity === 'shipment' && item.status === 4 && !item?.entrance?.has_image && (
            <span className="ml-1">
              <Lozenge appearance="removed">Missing entrance image</Lozenge>
            </span>
          )}
        </>
      ),
    },
    {
      key: 'departure_country',
      title: 'Country of loading',
      isSortable: false,
      entities: ['shipment', 'quotation'],
      show: false,
      content: (item) => <CountryFlag iso={item.departure_country} />,
    },
    {
      key: 'departure_city',
      title: 'Departure city',
      isSortable: false,
      entities: ['shipment', 'quotation'],
      show: false,
      content: (item) => item.departure_city,
    },
    {
      key: 'departure_cap',
      title: 'Departure zip code',
      isSortable: false,
      entities: ['shipment', 'quotation'],
      show: false,
      content: (item) => item.departure_cap,
    },
    {
      key: 'arrival_country',
      title: 'Country of delivery',
      isSortable: false,
      entities: ['shipment', 'quotation'],
      show: true,
      content: (item) => <CountryFlag iso={item.arrival_country} />,
    },
    {
      key: 'arrival_city',
      title: 'Arrival city',
      isSortable: false,
      entities: ['shipment', 'quotation'],
      show: false,
      content: (item) => item.arrival_city,
    },
    {
      key: 'arrival_cap',
      title: 'Arrival zip code',
      isSortable: false,
      entities: ['shipment', 'quotation'],
      show: false,
      content: (item) => item.arrival_cap,
    },
    {
      key: 'client',
      title: 'Client',
      isSortable: false,
      entities: ['shipment', 'quotation'],
      show: true,
      content: (item) => (
        <a href={`/panel/registries/${item.client.id_auto}`} target="_blank" rel="noreferrer">
          {item.client.company_name}
        </a>
      ),
    },
    {
      key: 'operator',
      title: 'Operator',
      isSortable: false,
      entities: ['shipment'],
      show: true,
      content: (item) => item.operator?.full_name,
    },
    {
      key: 'creator',
      title: 'Creator',
      isSortable: false,
      entities: ['quotation'],
      show: true,
      content: (item) => item.creator?.full_name,
    },
    {
      key: 'invoiced',
      title: 'Invoiced',
      isSortable: false,
      entities: ['shipment'],
      show: true,
      content: (item) => <ShipmentInvoicedTag value={item.invoiced_a} />,
    },
    {
      key: 'quotation_sent',
      title: 'Quotation sent',
      isSortable: false,
      entities: ['quotation'],
      show: true,
      content: (item) =>
        item.quotation_sent ? (
          <Lozenge appearance="success">Yes</Lozenge>
        ) : (
          <Lozenge appearance="moved">No</Lozenge>
        ),
    },
    {
      key: 'agent',
      title: 'Agent',
      isSortable: false,
      entities: ['shipment', 'quotation'],
      show: false,
      content: (item) => item.agent?.full_name,
    },
    {
      key: 'shipper',
      title: 'Shipper',
      isSortable: false,
      entities: ['shipment'],
      show: false,
      content: (item) => (
        <a href={`/panel/registries/${item.shipper.id_auto}`} target="_blank" rel="noreferrer">
          {item.shipper.company_name}
        </a>
      ),
    },
    {
      key: 'consignee',
      title: 'Consignee',
      isSortable: false,
      entities: ['shipment'],
      show: false,
      content: (item) => (
        <a href={`/panel/registries/${item.consignee.id_auto}`} target="_blank" rel="noreferrer">
          {item.consignee.company_name}
        </a>
      ),
    },
    {
      key: 'unit_awb',
      title: 'Unit no. / AWB',
      isSortable: false,
      entities: ['shipment'],
      show: true,
      content: (item) => (item.shipment_type === 2 ? item.waybill : item.means_code),
    },
    {
      key: 'custom_document',
      title: 'Customs document',
      isSortable: false,
      entities: ['shipment'],
      show: false,
      content: (item) => <DocumentTag flag={item.custom_document_flag} />,
    },
    {
      key: 'total_collies',
      title: 'No. of collies',
      isSortable: true,
      entities: ['shipment', 'quotation'],
      show: true,
      content: (item) => item.total_collies,
    },
    {
      key: 'weight',
      title: 'Weight (Kgs)',
      isSortable: true,
      entities: ['shipment', 'quotation'],
      show: true,
      content: (item) => formatNumber(item.weight || 0),
    },
    {
      key: 'reefer',
      title: 'Temperature control',
      isSortable: false,
      entities: ['shipment', 'quotation'],
      show: false,
      content: (item) => (
        <TemperatureTag reefer={item.reefer} temperature={item.reefer_temperature} />
      ),
    },
    {
      key: 'do_status',
      title: 'D/O status',
      isSortable: false,
      entities: ['shipment'],
      show: false,
      content: (item) => <ShipmentDOTag value={item.released} />,
    },
    {
      key: 'has_entrance',
      title: 'Entered in hub',
      isSortable: false,
      entities: ['shipment'],
      show: false,
      content: (item) => <ShipmentHubTag value={item.has_entrance} />,
    },
    {
      key: 'entrance_number',
      title: 'Entrance number',
      isSortable: false,
      entities: ['shipment'],
      show: false,
      content: (item) => item?.entrance?.numerator,
    },
    {
      key: 'entrance_date',
      title: 'Entrance date',
      isSortable: false,
      entities: ['shipment'],
      show: false,
      content: (item) => item?.entrance?.creation_date,
    },
    {
      key: 'entrance.hub.name',
      title: 'Hub',
      isSortable: false,
      entities: ['shipment'],
      show: false,
      content: (item) => item.entrance?.hub?.name,
    },
    {
      key: 'creation_date',
      title: 'Creation date',
      isSortable: true,
      entities: ['shipment', 'quotation'],
      show: true,
      content: (item) => item.creation_date,
    },
    {
      key: 'financial_status',
      title: 'Financial status',
      isSortable: false,
      entities: ['shipment'],
      show: true,
      content: (item) => (
        <ShipmentFinancialTag
          cost={item.financial_status.cost}
          quotation={item.financial_status.quotation}
          margin={item.financial_status.margin}
        />
      ),
    },
    {
      key: 'actions',
      title: 'Actions',
      entities: ['shipment', 'quotation'],
      show: true,
      content: (item) => (
        <DropdownMenu
          placement="bottom-end"
          trigger={({ triggerRef, ...props }) => (
            <Button
              {...props}
              appearance="subtle"
              children={<DotsIcon label="more" />}
              ref={triggerRef}
            />
          )}
        >
          <DropdownItemGroup>
            <DropdownLink children="View" to={`/panel/${entity}s/${item.id_auto}`} />

            <DropdownLink
              target="_blank"
              children="Open in new tab"
              to={`/panel/${entity}s/${item.id_auto}`}
            />

            <AccessControl
              resource="shipments"
              action="duplicate"
              userPermissions={generateShipmentUserPermissions(item, currentUser)}
            >
              <DuplicateShipment shipmentId={item.id_auto} entity={entity} />
            </AccessControl>

            <AccessControl
              resource="shipments"
              action="cancel"
              userPermissions={generateShipmentUserPermissions(item, currentUser)}
            >
              <DropdownItem children="Cancel" onClick={() => openCancelModal(item.id_auto)} />
            </AccessControl>

            <AccessControl
              resource="shipments"
              action="delete"
              userPermissions={generateShipmentUserPermissions(item, currentUser)}
            >
              <DropdownItem children="Delete" onClick={() => openDeleteModal(item.id_auto)} />
            </AccessControl>
          </DropdownItemGroup>
        </DropdownMenu>
      ),
    },
  ]
    .filter((column) => column.entities.includes(entity))
    .map((column) => ({
      ...column,
      content: (item) => {
        const isProtected = item?.shares?.[0]?.protected_keys?.includes(column.key)

        return isProtected ? (
          <Skeleton height={16} className="w-100" animate={false} />
        ) : (
          column.content(item)
        )
      },
    }))
}
