import { useMemo } from 'react'
import { getUserLevel } from 'api/users'
import { useQuery } from '@tanstack/react-query'

const useUserLevels = () => {
  const { isLoading, data } = useQuery(['user-levels'], getUserLevel, {
    retry: 2,
    staleTime: Infinity,
  })

  const userLevelsOptions = useMemo(() => {
    if (!data?.data) return []

    return data.data.map((u) => ({ label: u.friendly_name, value: u.name_id }))
  }, [data])

  return { userLevelsOptions, isLoadingUserLevels: isLoading }
}

export default useUserLevels
