import { useMutation, useQueryClient } from '@tanstack/react-query'

// Atlassian
import Modal, {
  ModalBody,
  ModalTitle,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import Button, { LoadingButton } from '@atlaskit/button'

// API
import { deleteLine } from 'features/lines/api/requests'

// Interfaces
import { useFlags, WarningBanner, useRoyanTable } from '@royan-co/user-interface'

const DeleteLineModal = ({ isOpen, onClose, lineId }) => {
  const { showSuccessFlag } = useFlags()
  const { refresh } = useRoyanTable()
  const queryClient = useQueryClient()

  const lineDeleteMutation = useMutation(deleteLine.bind(lineId))

  const closeModal = () => {
    onClose()
    lineDeleteMutation.reset()
  }

  const removeLine = () => {
    lineDeleteMutation.mutate(lineId, {
      onSuccess: (res) => {
        queryClient.removeQueries({
          queryKey: ['line', lineId],
        })

        if (refresh) refresh()

        showSuccessFlag(res.message)
        onClose()
      },
    })
  }

  return (
    <>
      <ModalTransition>
        {isOpen && (
          <Modal onClose={closeModal}>
            <ModalHeader>
              <ModalTitle appearance="danger">Delete line</ModalTitle>
            </ModalHeader>
            <ModalBody>
              You are about to delete a line. Are you sure?
              {lineDeleteMutation.isError && <WarningBanner />}
            </ModalBody>
            <ModalFooter>
              <Button appearance="subtle" onClick={closeModal}>
                Cancel
              </Button>
              <LoadingButton
                isLoading={lineDeleteMutation.isLoading}
                appearance="danger"
                onClick={removeLine}
                autoFocus
              >
                Delete
              </LoadingButton>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </>
  )
}

export default DeleteLineModal
