import { useState } from 'react'

//Atlassian
import Button from '@atlaskit/button'

//Components
import ShipmentTrackingModal from './TrackingModal'

const ShipmentViewTrackingActions = () => {
  const [isOpenShareModal, setIsOpenShareModal] = useState(false)

  return (
    <>
      <Button
        appearance="primary"
        onClick={() => {
          setIsOpenShareModal(true)
        }}
      >
        Share tracking details
      </Button>

      <ShipmentTrackingModal isOpen={isOpenShareModal} onClose={() => setIsOpenShareModal(false)} />
    </>
  )
}

export default ShipmentViewTrackingActions
