import { useCallback, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { getRegistryAirlines } from 'api/registries'

const useRegistryAirlines = (getAirline = true) => {
  const { isLoading, data } = useQuery(['registry-airlines'], getRegistryAirlines, {
    retry: 2,
    staleTime: Infinity,
    enabled: getAirline,
  })

  const registryAirlineOptions = useMemo(() => {
    if (!data) return []

    return data.map((a) => ({ label: a.company_name, value: a.acronym }))
  }, [data])

  const getPropertyOfAirline = useCallback(
    (acronym, property) => {
      const airline = data?.find((a) => a.acronym === acronym)
      return airline?.[property]
    },
    [data]
  )

  return { registryAirlineOptions, isLoadingRegistryAirlines: isLoading, getPropertyOfAirline }
}

export default useRegistryAirlines
