import { useMemo, useState } from 'react'
import { findOption } from 'utils/helpers'
import { useMutation } from '@tanstack/react-query'

// Hooks
import useShipmentsOperators from 'hooks/useShipmentsOperators'

// Atlassian
import Select from '@atlaskit/select'
import Spinner from '@atlaskit/spinner'
import Lozenge from '@atlaskit/lozenge'
import InlineEdit from '@atlaskit/inline-edit'

// Interfaces
import { useFlags } from '@royan-co/user-interface'

const ShipmentOperatorInlineEdit = ({
  operator,
  canEdit,
  changeOperatorFn,
  changeOperatorCallback,
}) => {
  const { showSuccessFlag, showWarningFlag } = useFlags()
  const [isEditMode, setIsEditMode] = useState(false)

  const { shipmentsOperators, isLoadingShipmentsOperators } = useShipmentsOperators({
    enabled: isEditMode,
  })

  const shipmentsOperatorsOptions = useMemo(
    () =>
      shipmentsOperators.length === 0 && operator?.id
        ? [{ label: operator.full_name, value: operator.id }]
        : shipmentsOperators.map((u) => ({ label: u.full_name, value: u.id })),
    [shipmentsOperators, operator]
  )

  const changeOperatorMutation = useMutation({
    mutationFn: (data) => changeOperatorFn(data),

    onSuccess: (res) => {
      showSuccessFlag(res.message)

      changeOperatorCallback(res)
    },

    onError: (e) => {
      console.error(e)
      showWarningFlag(e?.response?.data?.message || 'Something wrong happened.')
    },
  })

  const handleChangeOperator = (data) => {
    if (operator?.id === data.value) return

    changeOperatorMutation.mutate(data)
  }

  const viewTag = operator?.id ? (
    operator?.full_name
  ) : (
    <Lozenge appearance="default">Not set</Lozenge>
  )

  return !canEdit ? (
    viewTag
  ) : (
    <div className="!-mt-2">
      <InlineEdit
        keepEditViewOpenOnBlur={true}
        onEdit={() => setIsEditMode(true)}
        defaultValue={findOption(shipmentsOperatorsOptions, operator?.id)}
        editView={({ errorMessage, ...fieldProps }) => (
          <Select
            spacing="compact"
            options={shipmentsOperatorsOptions}
            isLoading={isLoadingShipmentsOperators}
            {...fieldProps}
          />
        )}
        readView={() => (
          <div className="flex items-center font-normal gap-1 my-1.5">
            {viewTag}
            {changeOperatorMutation.isLoading && <Spinner size="small" />}
          </div>
        )}
        onConfirm={(value) => handleChangeOperator(value)}
      />
    </div>
  )
}

export default ShipmentOperatorInlineEdit
