import { useMutation } from '@tanstack/react-query'
import { saveAs } from 'file-saver'
import dayjs from 'dayjs'

// Apis
import { downloadFinancialBalanceRegistry } from './registry-requests'

// Interfaces
import { useFlags } from '@royan-co/user-interface'

const useDownloadFinancialBalanceRegistry = ({ registryId, companyName }) => {
  const { showWarningFlag } = useFlags()

  return useMutation({
    mutationFn: () => downloadFinancialBalanceRegistry(registryId),

    onSuccess: (res) => {
      const date = dayjs().format('YYYY-MM-DD')
      saveAs(new Blob([res]), `Financial balance - ${companyName} - ${date}.pdf`)
    },

    onError: (e) => {
      console.error(e)
      showWarningFlag('Something wrong!')
    },

    onSettled: () => {
      setTimeout(() => {
        const event = new MouseEvent('click')
        document.body.dispatchEvent(event)
      }, 100)
    },
  })
}

export { useDownloadFinancialBalanceRegistry }
