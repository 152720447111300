// Utils
import { findOption } from 'utils/helpers'
import { mustGoOptions } from 'features/marginCalculator/utils/constants'

// Atlassian
import Lozenge from '@atlaskit/lozenge'

const MustGoTag = ({ value }) => {
  const option = findOption(mustGoOptions, value)
  return option ? <Lozenge appearance={option.appearance}>{option.label}</Lozenge> : null
}

export default MustGoTag
