import { useQuery, useQueryClient } from '@tanstack/react-query'

// API
import { getCommercialInvoice } from 'api/invoices'

const useCommercialInvoice = (shipmentId, invoiceId, { enabled = true } = {}) => {
  const queryClient = useQueryClient()

  const {
    data: commercialInvoice,
    isFetching,
    isLoading,
    isError,
    refetch,
  } = useQuery(
    ['commercial-invoice', invoiceId],
    () => getCommercialInvoice(shipmentId, invoiceId),
    {
      staleTime: Infinity,
      enabled,
    }
  )

  const invalidate = () => {
    queryClient.invalidateQueries({
      queryKey: ['commercial-invoice', invoiceId],
      refetchType: 'none',
    })
  }

  return { commercialInvoice, isLoading, isFetching, isError, refetch, invalidate }
}

export default useCommercialInvoice
