import { transformSelectField } from 'utils/helpers'

//Atlassian
import Select from '@atlaskit/select'

//Interfaces
import { Field } from '@royan-co/user-interface'

//Components
import { vehicleTypeOptions } from './../../utils/constants'

const VehicleTypeFilterFields = () => {
  return (
    <>
      <Field
        type="select"
        label="Type"
        name="type"
        transform={transformSelectField(vehicleTypeOptions)}
      >
        {({ fieldProps }) => (
          <Select
            isClearable={true}
            options={vehicleTypeOptions}
            placeholder="Choose a type"
            {...fieldProps}
          />
        )}
      </Field>
    </>
  )
}

export default VehicleTypeFilterFields
