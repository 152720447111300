import { useCallback, useMemo } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'

//Services
import { getRegistryAddress } from 'api/registries'
import { getAddressLabel } from 'utils/helpers'

const useRegistryAddress = (id) => {
  const queryClient = useQueryClient()

  const { isLoading, data } = useQuery(['address', id], () => getRegistryAddress(id), {
    staleTime: Infinity,
    retry: 2,
    enabled: !!id,
  })

  const registryAddressOptions = useMemo(() => {
    if (!data) return []

    return data.map((address) => ({
      label: getAddressLabel(address),
      value: address.id_auto,
    }))
  }, [data])

  const upsertAddressToData = (address) => {
    queryClient.setQueryData(['address', id], (oldRegistryAddress) => [
      ...oldRegistryAddress,
      address,
    ])
  }

  const generalAddress = useMemo(() => {
    const address = data?.find((address) => address.type?.split(';').some((a) => a === '0'))
    return address?.id_auto
  }, [data])

  const getAddressCountryIso = useCallback(
    (addressId) => {
      const address = data?.find((address) => address.id_auto === addressId)
      return address?.country_iso
    },
    [data]
  )

  return {
    upsertAddressToData,
    registryAddressOptions,
    generalAddress,
    getAddressCountryIso,
    isLoadingRegistryAddress: !!id && isLoading,
  }
}

export default useRegistryAddress
