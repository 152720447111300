import { getCountry } from 'utils/helpers'
import { useMutation, useQueryClient } from '@tanstack/react-query'

// APIS
import { editShipmentParam } from 'api/shipments'

// Atlassian
import { CountrySelect } from '@atlaskit/select'
import Spinner from '@atlaskit/spinner'
import Lozenge from '@atlaskit/lozenge'
import InlineEdit from '@atlaskit/inline-edit'

// Interfaces
import { EditIcon, useFlags } from '@royan-co/user-interface'

// Components
import CountryFlag from 'components/UI/CountryFlag'

const ShipmentCountryInlineEdit = ({ country, attribute, shipmentId, canEdit }) => {
  const queryClient = useQueryClient()
  const { showSuccessFlag, showWarningFlag } = useFlags()

  const changeCountryMutation = useMutation({
    mutationFn: (data) => editShipmentParam(shipmentId, attribute, data),

    onSuccess: (res) => {
      showSuccessFlag(res.message)

      queryClient.setQueryData(['shipment', shipmentId], (oldShipment) => ({
        ...oldShipment,
        [attribute]: res.shipment[attribute],
      }))
    },

    onError: (e) => {
      console.error(e)
      showWarningFlag(e?.response?.data?.message || 'Something wrong happened.')
    },
  })

  const handleChangeCountry = (data) => {
    if (country === data.abbr || !data?.abbr) return

    changeCountryMutation.mutate({ [attribute]: data.abbr })
  }

  const viewTag = <CountryFlag iso={country} /> || <Lozenge appearance="default">Not set</Lozenge>

  return !canEdit ? (
    viewTag
  ) : (
    <div className="!-mt-2">
      <InlineEdit
        keepEditViewOpenOnBlur={true}
        defaultValue={getCountry(country)}
        editView={(fieldProps) => <CountrySelect {...fieldProps} spacing="compact" />}
        readView={() => (
          <div className="flex items-center font-normal gap-1 my-1.5">
            {viewTag}
            {changeCountryMutation.isLoading && <Spinner size="small" />}
          </div>
        )}
        onConfirm={(value) => handleChangeCountry(value)}
      />
    </div>
  )
}

export default ShipmentCountryInlineEdit
