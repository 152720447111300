export const lineFilters = [
  {
    key: 'departure_country',
    content: (v) => `Departure country: ${v}`,
  },
  {
    key: 'arrival_country',
    content: (v) => `Arrival country: ${v}`,
  },
]
