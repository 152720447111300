import { useParams } from 'react-router-dom'

// Utils
import { transformSelectField } from 'utils/helpers'
import { handleErrorOnSubmit } from 'utils/errors'

// Context
import { useFinanceEditingContext } from 'contexts/FinanceEditingProvider'

// Hooks
import useShipment from 'hooks/useShipment'
import useConsolidation from 'hooks/useConsolidation'
import useSurchargeEnumeration from 'hooks/useSurchargeEnumeration'

// Atlassian
import Button from '@atlaskit/button'
import Select from '@atlaskit/select'
import Modal, {
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import { ErrorMessage } from '@atlaskit/form'
import Textfield from '@atlaskit/textfield'

// Interfaces
import { Field, Form, WarningBannerField } from '@royan-co/user-interface'

const EditFinanceSurchargeModal = ({ isOpen, onClose, onSubmit }) => {
  const { entityType, entityId } = useFinanceEditingContext()
  const { shipment, isLoading: isLoadingShipment } = useShipment(entityId, { enabled: isOpen })
  const { consolidation, isLoading: isLoadingConsolidation } = useConsolidation(entityId, {
    enabled: isOpen,
  })
  const entity = entityType === 'shipment' ? shipment : consolidation
  const isLoading = isLoadingShipment || isLoadingConsolidation

  const {
    isLoading: surchargesIsLoading,
    surchargeOptions,
    getSurchargeById,
  } = useSurchargeEnumeration({
    enabled: !!(entity?.id_auto && isOpen),
    mode: entity?.shipment_type,
  })

  const handleCloseModal = () => {
    onClose()
  }

  const handleEntity = (data, { setError }) => {
    try {
      let surchargeData = {}

      const selectedSurcharge = getSurchargeById(data.surcharge)
      const calculationMethod = selectedSurcharge.calculation_method
      const quantity =
        calculationMethod === 0
          ? 1
          : calculationMethod === 1
          ? entity?.weight || 1
          : calculationMethod === 2
          ? entity?.chargeable_weight || 1
          : calculationMethod === 5
          ? entity?.total_collies || 1
          : ''
      const price = data.rate * quantity
      const quotation = price && price < data.minimum * 1 ? data.minimum : price || ''

      surchargeData = {
        name: selectedSurcharge.name,
        quantity: quantity,
        rate: data.rate,
        calculation_method: calculationMethod,
        cost: quotation,
        quotation: quotation,
        surcharge_id: selectedSurcharge.id,
      }

      onSubmit(surchargeData)
      handleCloseModal()
    } catch (error) {
      handleErrorOnSubmit(error, setError, data)
    }
  }

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={handleCloseModal} width="small">
          <ModalHeader>
            <ModalTitle>New surcharge</ModalTitle>
          </ModalHeader>

          <Form onSubmit={handleEntity}>
            {({ watch }) => (
              <>
                <div className="px-6">
                  <Field
                    isRequired
                    label="Surcharge"
                    name="surcharge"
                    transform={transformSelectField(surchargeOptions)}
                    className="!mt-0"
                  >
                    {({ fieldProps, error }) => (
                      <>
                        <Select
                          options={surchargeOptions}
                          isLoading={isLoading || surchargesIsLoading}
                          {...fieldProps}
                        />
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                      </>
                    )}
                  </Field>

                  {watch('surcharge') && (
                    <>
                      <Field
                        isRequired
                        label="Rate"
                        name="rate"
                        defaultValue=""
                        validate={(cost) => (isNaN(+cost) ? `Rate should be a number.` : true)}
                        className="!mt-4"
                      >
                        {({ fieldProps, error }) => (
                          <>
                            <Textfield placeholder="Type the rate" {...fieldProps} />
                            {error && <ErrorMessage>{error}</ErrorMessage>}
                          </>
                        )}
                      </Field>

                      <Field
                        isRequired
                        label="Minimum"
                        name="minimum"
                        defaultValue=""
                        validate={(cost) =>
                          isNaN(+cost) ? `Minimum value should be a number.` : true
                        }
                        className="!mt-4"
                      >
                        {({ fieldProps, error }) => (
                          <>
                            <Textfield placeholder="Type the minimum" {...fieldProps} />
                            {error && <ErrorMessage>{error}</ErrorMessage>}
                          </>
                        )}
                      </Field>
                    </>
                  )}

                  <WarningBannerField />
                </div>

                <ModalFooter>
                  <Button type="button" appearance="subtle" onClick={handleCloseModal}>
                    Close
                  </Button>
                  <Button type="submit" appearance="primary">
                    Add
                  </Button>
                </ModalFooter>
              </>
            )}
          </Form>
        </Modal>
      )}
    </ModalTransition>
  )
}

export default EditFinanceSurchargeModal
