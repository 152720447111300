// Atlassian
import Button, { ButtonGroup } from '@atlaskit/button'
import Avatar from '@atlaskit/avatar'

// Interfaces
import { DotsIcon, EditIcon, TrashIcon } from '@royan-co/user-interface'

const RegistryContactCard = ({ className }) => {
  return (
    <div className={`shadow rounded ${className}`}>
      <div className="bg-neutral-20 py-8"></div>
      <div className="p-4 flex items-end flex-wrap justify-between sm:justify-start">
        <div className="-mt-16 rounded-full">
          <Avatar size="xlarge" />
        </div>

        <div className="ml-auto sm:mt-2">
          <ButtonGroup appearance="default">
            <Button spacing="compact" className="!px-0.5">
              <EditIcon size={16} />
            </Button>
            <Button spacing="compact" className="!px-0.5">
              <TrashIcon size={16} className="text-red-400" />
            </Button>
            <Button spacing="compact" className="!px-0.5">
              <DotsIcon size={16} vertical />
            </Button>
          </ButtonGroup>
        </div>
      </div>
    </div>
  )
}

export default RegistryContactCard
