import { forwardRef, useEffect, useMemo, useRef, useState } from 'react'

// Hooks
import useGetRegistryParam from 'hooks/useGetRegistryParam'
import useRegistryAutocomplete from 'hooks/useRegistryAutocomplete'

//Atlassian
import Select from '@atlaskit/select'

// Components
import RegistrySelectLabelOption from './RegistrySelectLabelOption'

const RegistrySelect = forwardRef(
  (
    {
      value,
      onChange,
      defaultRegistryId,
      tagOption,
      defaultData,
      registryType,
      isMulti,
      isImported,
      isActive,
      ...props
    },
    ref
  ) => {
    const processDefaultValue = useRef(false)
    const [selectedOption, setSelectedOption] = useState(value)

    const {
      registryAutocompleteOptions,
      setTermRegistryAutocomplete,
      isLoadingRegistryAutocomplete,
    } = useRegistryAutocomplete({ registryType, isImported, isActive })

    const { autocompleteValue, isLoadingAutocompleteValue } = useGetRegistryParam(
      !processDefaultValue.current && defaultRegistryId,
      'company_name'
    )

    const options = useMemo(() => {
      if (
        !isMulti &&
        !!value?.value &&
        !registryAutocompleteOptions.some((o) => o.value === value.value)
      ) {
        return registryAutocompleteOptions.concat(value)
      }

      if (isMulti && value?.length > 0) {
        let missingValues = []

        for (let i = 0; i < value.length; i++) {
          let found = false
          for (let j = 0; j < registryAutocompleteOptions.length; j++) {
            if (value[i].value === registryAutocompleteOptions[j].value) {
              found = true
              break
            }
          }
          if (!found) {
            missingValues.push(value[i])
          }
        }
        return registryAutocompleteOptions.concat(value)
      }

      return registryAutocompleteOptions
    }, [value, registryAutocompleteOptions, isMulti])

    const handleChange = (option) => {
      setSelectedOption(option)
      onChange(option)
    }

    useEffect(() => {
      if (!processDefaultValue.current && autocompleteValue && defaultRegistryId && !isMulti) {
        processDefaultValue.current = true
        setSelectedOption({
          label: autocompleteValue,
          value: defaultRegistryId,
        })
      }
      if (defaultData?.value) {
        setSelectedOption(
          isMulti
            ? defaultData
            : {
                label: defaultData?.label,
                value: defaultData?.value,
                imported: defaultData?.imported,
              }
        )
      }
    }, [autocompleteValue, defaultData, defaultRegistryId, isMulti])

    return (
      <Select
        ref={ref}
        isMulti={isMulti}
        isClearable
        options={options}
        value={selectedOption}
        onChange={handleChange}
        isLoading={isLoadingRegistryAutocomplete || isLoadingAutocompleteValue}
        onInputChange={(newValue) => setTermRegistryAutocomplete(newValue)}
        formatOptionLabel={(data) => (
          <RegistrySelectLabelOption data={data} tagOption={tagOption} />
        )}
        {...props}
      />
    )
  }
)

export default RegistrySelect
