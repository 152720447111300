import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { AccessControl } from 'features/auth'
import { useAuth } from 'contexts/AuthProvider'
import { useMutation } from '@tanstack/react-query'
import { generateShipmentUserPermissions } from '../Create/shipment-user-permissions'

//Apis
import { createRemindPayment } from 'api/shipments'

//Utils
import { handleErrorOnSubmit } from 'utils/errors'
import { findOption } from 'utils/helpers'

//Hooks
import useShipment from 'hooks/useShipment'

//Atlassian
import { ErrorMessage } from '@atlaskit/form'
import Button, { LoadingButton } from '@atlaskit/button'
import Modal, {
  ModalTitle,
  ModalHeader,
  ModalFooter,
  ModalTransition,
  ModalBody,
} from '@atlaskit/modal-dialog'
import Select from '@atlaskit/select'
import EmptyState from '@atlaskit/empty-state'

//Interfaces
import { Field, Form, useFlags, useDisclosure, WarningBannerField } from '@royan-co/user-interface'

const ShipmentViewPaymentReminderActions = () => {
  const { currentUser } = useAuth()
  const { shipmentId } = useParams()
  const { showSuccessFlag } = useFlags()
  const { shipment, isLoading } = useShipment()
  const [isOpenRemindPaymentModal, remindPaymentModalHandler] = useDisclosure('remind-payment')

  const shipmentsContactsOptions = useMemo(
    () => shipment?.contacts?.map((c) => ({ label: c.full_name, value: c.id_auto })) || [],
    [shipment]
  )

  const remindPaymentMutation = useMutation(createRemindPayment.bind(null, shipmentId))

  const handleSubmit = (data, { setError }) => {
    remindPaymentMutation.mutate(data, {
      onError: (e) => {
        handleErrorOnSubmit(e, setError, data)
        console.error(e)
      },
      onSuccess: (res) => {
        showSuccessFlag(res.message)
        handleCloseModal()
      },
    })
  }

  const defaultValues = useMemo(
    () => ({
      contacts: shipment?.contacts?.filter((c) => c?.reminder)?.map((c) => c.id_auto),
    }),
    [shipment]
  )

  const handleCloseModal = () => {
    remindPaymentModalHandler?.close()
    remindPaymentMutation.reset()
  }

  return (
    <AccessControl
      resource="shipments"
      action="remindPayment"
      userPermissions={generateShipmentUserPermissions(shipment, currentUser)}
    >
      {!isLoading ? (
        <Button appearance="primary" onClick={() => remindPaymentModalHandler.open()}>
          Remind payment
        </Button>
      ) : null}

      <ModalTransition>
        {isOpenRemindPaymentModal && (
          <Modal width={'medium'} onClose={handleCloseModal}>
            <ModalHeader>
              <ModalTitle>Remind payment</ModalTitle>
            </ModalHeader>

            {shipmentsContactsOptions.length === 0 ? (
              <ModalBody>
                <EmptyState description="This shipment has no contacts" />
              </ModalBody>
            ) : (
              <Form onSubmit={handleSubmit} defaultValues={defaultValues}>
                {() => (
                  <>
                    <div className="px-6">
                      <Field
                        isRequired
                        type="select"
                        name="contacts"
                        label="Contacts"
                        transform={{
                          input: (v) => v?.map((o) => findOption(shipmentsContactsOptions, o)),
                          output: (opt) => opt.map((o) => o.value),
                        }}
                      >
                        {({ fieldProps, error }) => (
                          <>
                            <Select
                              isMulti
                              isClearable={true}
                              options={shipmentsContactsOptions}
                              isLoading={isLoading}
                              {...fieldProps}
                            />
                            {error && <ErrorMessage>{error}</ErrorMessage>}
                          </>
                        )}
                      </Field>

                      <WarningBannerField />
                    </div>

                    <ModalFooter>
                      <Button appearance="subtle" onClick={handleCloseModal}>
                        Cancel
                      </Button>

                      <LoadingButton
                        type="submit"
                        appearance="primary"
                        isLoading={remindPaymentMutation.isLoading}
                        autoFocus
                      >
                        Send
                      </LoadingButton>
                    </ModalFooter>
                  </>
                )}
              </Form>
            )}
          </Modal>
        )}
      </ModalTransition>
    </AccessControl>
  )
}

export default ShipmentViewPaymentReminderActions
