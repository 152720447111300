import Select from '@atlaskit/select'

const rowsPerPageOptions = [
  { label: '10', value: 10 },
  { label: '25', value: 25 },
  { label: '50', value: 50 },
]

const SelectRows = ({ value = 10, onChange }) => {
  return (
    <div className="flex items-center">
      <label className="text-neutral-200 mr-2" htmlFor="row-per-page">
        Rows per page:
      </label>
      <div style={{ width: 70 }}>
        <Select
          placeholder={10}
          spacing="compact"
          inputId="row-per-page"
          options={rowsPerPageOptions}
          onChange={onChange}
          value={rowsPerPageOptions?.find((opt) => opt.value === value)}
        />
      </div>
    </div>
  )
}

export default SelectRows
