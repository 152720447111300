import { createContext, useContext, useMemo } from 'react'
import { matchPath, useLocation, useParams } from 'react-router-dom'

const _steps = [
  {
    id: 1,
    label: 'General',
    pathSuffix: '',
  },
  {
    id: 2,
    label: 'Cargo',
    pathSuffix: '/cargo',
  },
  {
    id: 3,
    label: 'Summary',
    pathSuffix: '/summary',
  },
]

const RegistriesContext = createContext()

const useShipmentCreationContext = () => {
  const context = useContext(RegistriesContext)

  return context
}

const ShipmentCreationProvider = ({ children, entity }) => {
  const { pathname } = useLocation()
  const { shipmentId, quotationId } = useParams()

  const steps = useMemo(
    () =>
      _steps.map((s) => ({
        ...s,
        path:
          shipmentId || quotationId
            ? `/panel/${entity}s/${shipmentId || quotationId}/edit${s.pathSuffix}`
            : `/panel/${entity}s/create${s.pathSuffix}`,
      })),
    [entity, shipmentId, quotationId]
  )

  const currentStepIndex = useMemo(
    () => steps.findIndex((s) => matchPath(s.path, pathname)),
    [steps, pathname]
  )

  const currentStep = steps[currentStepIndex]
  const prevStep = steps[currentStepIndex - 1]
  const nextStep = steps[currentStepIndex + 1]

  const value = { steps, currentStep, prevStep, nextStep }

  return <RegistriesContext.Provider value={value}>{children}</RegistriesContext.Provider>
}

export { useShipmentCreationContext, ShipmentCreationProvider }
