// Hooks
import useTrackingLogs from 'hooks/useTrackingLogs'

// Atlassian
import Button from '@atlaskit/button'
import Spinner from '@atlaskit/spinner'
import EmptyState from '@atlaskit/empty-state'
import SectionMessage from '@atlaskit/section-message'

// Interfaces
import { Timeline, TimelineItem } from '@royan-co/user-interface'

const ShipmentTrackingLogs = ({ parent, parentId }) => {
  const { data: logs, isLoading, isError, refetch } = useTrackingLogs(parent, parentId)

  return (
    <>
      {isLoading ? (
        <div className="text-center mt-6">
          <Spinner />
        </div>
      ) : isError ? (
        <SectionMessage appearance="warning" title="Something wrong on loading logs, please retry.">
          <Button onClick={() => refetch()}>Retry</Button>
        </SectionMessage>
      ) : !logs || logs?.length < 1 ? (
        <EmptyState header="No logs available." />
      ) : (
        <div>
          <Timeline>
            {logs.map((pin, index) => (
              <TimelineItem
                key={index}
                title={pin.title}
                description={
                  <>{`Via ${pin.contact_method} to ${pin?.recipient?.full_name} on ${
                    pin.date
                  }`}</>
                }
              />
            ))}
          </Timeline>
        </div>
      )}
    </>
  )
}

export default ShipmentTrackingLogs
