import axios from 'services/Request'

/**
 * export data as .xlsx
 * @param {Object} data
 * @param {String} data.table
 * @param {Array} data.filters
 * @param {Array} data.columns
 * @returns Promise
 */
export function exportData(data) {
  return axios.post('/api/exports/create', data)
}
