import axios from 'services/Request'

export function getCMRFormDefaultValues(documentId, parent) {
  return axios.get(`/api/documents/cmr/fetch/${parent}/${documentId}`)
}

export function editCMRDocument(documentId, data, parent) {
  return axios.post(`/api/documents/cmr/edit/${parent}/${documentId}`, data)
}

export function downloadCMRDocument({ documentId, format, parent }) {
  return axios.get(`/api/documents/cmr/download/${parent}/${documentId}/${format}`, {
    responseType: 'arraybuffer',
  })
}
