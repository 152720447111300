import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'

//Services
import { getRegistryContacts } from 'api/registries'

//Atlassian
import Select from '@atlaskit/select'
import { Field, useFormContext } from '@royan-co/user-interface'
import { ErrorMessage } from '@atlaskit/form'

//Components
import Card from 'components/UI/Card'
import RegistrySelect from 'components/Registries/RegistrySelect'

const ShipmentViewEditRegistriesClient = () => {
  const { watch, getValues, setValue } = useFormContext()

  const { isLoading: clientContactsIsLoading, data: clientContacts } = useQuery(
    ['contact', getValues('client_id')?.value],
    () => getRegistryContacts(getValues('client_id')?.value),
    {
      staleTime: Infinity,
      retry: 2,
      enabled: getValues('client_id')?.value !== undefined,
    }
  )

  const registryContactsOptions = useMemo(() => {
    if (!clientContacts) return []

    return clientContacts.map((contact) => ({
      label: contact.full_name,
      value: contact.id_auto,
    }))
  }, [clientContacts])

  return (
    <Card className="w-full my-6">
      <Field
        isRequired
        type="select"
        name="client_id"
        label="Client"
        className="!mt-0"
        onValueChange={() => setValue('contacts', null)}
      >
        {({ fieldProps, error }) => (
          <>
            <RegistrySelect spacing="compact" registryType="client" {...fieldProps} />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </>
        )}
      </Field>

      {watch('client_id') && (
        <Field type="select" name="contacts" className="!mt-4" label="Contacts">
          {({ fieldProps, error }) => (
            <>
              <Select
                isMulti
                spacing="compact"
                options={registryContactsOptions}
                isLoading={clientContactsIsLoading}
                {...fieldProps}
              />
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </>
          )}
        </Field>
      )}
    </Card>
  )
}

export default ShipmentViewEditRegistriesClient
