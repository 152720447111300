// Atlassian
import { CountrySelect } from '@atlaskit/select'
import { allCountries as countries } from '/node_modules/@atlaskit/select/dist/es2019/data/countries.js'
import { ErrorMessage } from '@atlaskit/form'

// Interfaces
import { Field } from '@royan-co/user-interface'

// Components
import Card from 'components/UI/Card'

const LineCreateEditForm = () => {
  return (
    <>
      <Card className="mb-6">
        <Field
          type="select"
          label="Departure country"
          name="departure_country"
          transform={{
            input: (v) => countries?.find((c) => c.abbr === v),
            output: (opt) => (opt?.abbr !== undefined ? opt.abbr : null),
          }}
        >
          {({ fieldProps: { ref, ...fieldProps }, error }) => (
            <>
              <CountrySelect isClearable placeholder="Country" {...fieldProps} />
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </>
          )}
        </Field>

        <Field
          type="select"
          label="Arrival country"
          name="arrival_country"
          transform={{
            input: (v) => countries?.find((c) => c.abbr === v),
            output: (opt) => (opt?.abbr !== undefined ? opt.abbr : null),
          }}
        >
          {({ fieldProps: { ref, ...fieldProps }, error }) => (
            <>
              <CountrySelect isClearable placeholder="Country" {...fieldProps} />
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </>
          )}
        </Field>
      </Card>
    </>
  )
}

export default LineCreateEditForm
