import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'

// Atlassian
import PageHeader from '@atlaskit/page-header'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'
import Button, { ButtonGroup } from '@atlaskit/button'
import DropdownMenu, { DropdownItemGroup } from '@atlaskit/dropdown-menu'

// Interfaces
import { Grid, useRoyanTableLastFilters, DotsIcon } from '@royan-co/user-interface'

// Components
import AirWaybillViewGeneral from '../components/view/General'
import AirWaybillViewTable from '../components/view/table'

const pageTitle = 'View stock'

const AirWaybillStockViewPage = () => {
  const { lastUrlSearch } = useRoyanTableLastFilters('airwaybill-stock-list')

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <PageHeader
        breadcrumbs={
          <Breadcrumbs>
            <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
            <BreadcrumbsItem text="Operation" />
            <BreadcrumbsItem text="AWB stock" to="/panel/airwaybill-stock" component={Link} />
          </Breadcrumbs>
        }
        actions={
          <ButtonGroup>
            <Button
              component={Link}
              appearance="default"
              to={`/panel/airwaybill-stock${lastUrlSearch ? `?${lastUrlSearch}` : ''}`}
            >
              Back
            </Button>

            <DropdownMenu
              placement="bottom-end"
              trigger={({ triggerRef, ...props }) => (
                <Button
                  {...props}
                  appearance="default"
                  children={<DotsIcon label="more" />}
                  ref={triggerRef}
                />
              )}
            >
              <DropdownItemGroup></DropdownItemGroup>
            </DropdownMenu>
          </ButtonGroup>
        }
      >
        {pageTitle}
      </PageHeader>

      <Grid className="w-full">
        <Grid.Col md={12} lg={8}>
          <AirWaybillViewTable />
        </Grid.Col>

        <Grid.Col md={12} lg={4}>
          <AirWaybillViewGeneral />
        </Grid.Col>
      </Grid>
    </>
  )
}

export default AirWaybillStockViewPage
