import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { getShipmentsOperators } from 'api/shipments'
import { useAuth } from 'contexts/AuthProvider'

const useShipmentsOperators = ({ enabled = true } = {}) => {
  const { currentUser } = useAuth()
  const { isLoading, data } = useQuery(
    ['shipments', 'operators', currentUser?.registry_id],
    getShipmentsOperators,
    {
      staleTime: Infinity,
      retry: 2,
      enabled,
    }
  )

  const shipmentsOperators = useMemo(() => {
    if (!data?.operators) return []

    return data.operators
  }, [data])

  const shipmentsOperatorsOptions = useMemo(() => {
    if (!data?.operators) return []

    return data.operators.map((u) => ({ label: u.full_name, value: u.auto_id }))
  }, [data])

  return { shipmentsOperatorsOptions, shipmentsOperators, isLoadingShipmentsOperators: isLoading }
}

export default useShipmentsOperators
