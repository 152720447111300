import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Navigate, useParams } from 'react-router-dom'

// Services
import useShipment from 'hooks/useShipment'
import { getShipmentEntrances } from 'api/shipments'

// Atlassian
import Button from '@atlaskit/button'
import Spinner from '@atlaskit/spinner'
import EmptyState from '@atlaskit/empty-state'
import SectionMessage from '@atlaskit/section-message'
import { DynamicTableStateless } from '@atlaskit/dynamic-table'
import Lozenge from '@atlaskit/lozenge'

// Components
import EntranceActions from 'components/Shipments/View/EntranceActions'

const tableHead = {
  cells: [
    { content: 'Number' },
    { content: 'Hub' },
    { content: 'Days in warehouse' },
    { content: 'Creator' },
    { content: 'Creation date' },
    { content: 'Actions' },
  ],
}

const ShipmentViewEntrancesPage = () => {
  const { shipmentId } = useParams()
  const { shipment } = useShipment(shipmentId)

  const {
    data = [],
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ['shipment', 'entrances', shipmentId],
    queryFn: () => getShipmentEntrances(shipmentId),
  })

  const tableRows = useMemo(
    () =>
      data.map((entrance, i) => ({
        key: entrance.id,
        cells: [
          {
            content: (
              <div className="my-2">
                {entrance.numerator}

                {entrance?.partial ? (
                  <div>
                    <Lozenge appearance="moved">Partial</Lozenge>
                  </div>
                ) : null}

                {entrance?.collies_deformity ? (
                  <div>
                    <Lozenge appearance="removed">Deformed</Lozenge>
                  </div>
                ) : null}
              </div>
            ),
          },
          {
            content: entrance.hub?.name,
          },
          {
            content: entrance.days_in_warehouse + ' day(s)',
          },
          {
            content: entrance.creator?.full_name,
          },
          {
            content: entrance.creation_date,
          },
          {
            content: <EntranceActions entrance={entrance} />,
          },
        ],
      })),
    [data]
  )

  return shipment?.entrances_count === 0 ? (
    <Navigate to={`/panel/shipments/${shipmentId}`} />
  ) : isLoading ? (
    <div className="text-center mt-24">
      <Spinner />
    </div>
  ) : isError ? (
    <SectionMessage
      appearance="warning"
      title="Something wrong on loading entrances, please retry."
    >
      <Button onClick={() => refetch()}>Retry</Button>
    </SectionMessage>
  ) : (
    <div className="shadow rounded p-4 mt-4">
      {tableRows.length === 0 ? (
        <EmptyState header="No entrances" />
      ) : (
        <div className="overflow-x-auto inline-block w-full -mb-6">
          <DynamicTableStateless head={tableHead} rows={tableRows} />
        </div>
      )}
    </div>
  )
}

export default ShipmentViewEntrancesPage
