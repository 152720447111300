import { useState } from 'react'
import dayjs from 'dayjs'

// Atlassian
import Spinner from '@atlaskit/spinner'
import { ButtonItem } from '@atlaskit/menu'

// Interfaces
import { useFlags } from '@royan-co/user-interface'

// Components
import DisabledElementTooltip from 'components/UI/DisabledElementTooltip'

const CargowizPlannerAction = ({ endpoint, entity, isDisabled }) => {
  const [loading, setLoading] = useState(false)
  const { showWarningFlag } = useFlags()

  const handleDownloadPlanner = async () => {
    try {
      setLoading(true)

      const result = await endpoint(entity?.id)

      const blob = new Blob([result], { type: 'text/plain' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')

      link.download = `${entity?.reference}-cargowiz-planner-${dayjs().format(
        'YYYY-MM-DD HH-mm-ss'
      )}.txt`
      link.href = url
      link.click()
    } catch (e) {
      console.error(e)

      if ([400].includes(e.response?.status)) {
        showWarningFlag(e?.response?.data?.message || 'Something went wrong.')
      } else {
        showWarningFlag('Something went wrong.')
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <DisabledElementTooltip
        isDisabled={isDisabled}
        tooltipContent={<span>No cargo available</span>}
      >
        <ButtonItem onClick={handleDownloadPlanner} isDisabled={isDisabled} shouldTitleWrap>
          <div className="flex items-center justify-between">
            Cargowiz planner file
            {loading && (
              <span className="ml-2">
                <Spinner size="small" />
              </span>
            )}
          </div>
        </ButtonItem>
      </DisabledElementTooltip>
    </>
  )
}

export default CargowizPlannerAction
