import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

// Atlassian
import { DropdownItem } from '@atlaskit/dropdown-menu'

// Components
import DisabledElementTooltip from 'components/UI/DisabledElementTooltip'

const IssueCreditNoteDropdownItem = ({ invoice }) => {
  const navigate = useNavigate()

  const accessabilityIssueCreditNote = useMemo(() => {
    if (invoice?.do_boolean)
      return {
        access: false,
        message: 'Type of the invoice is a credit note.',
      }
    else if (invoice?.is_proforma)
      return {
        access: false,
        message: 'Invoice is PROFORMA.',
      }
    else
      return {
        access: true,
      }
  }, [invoice])

  return invoice?.id_auto ? (
    <DisabledElementTooltip
      tooltipContent={accessabilityIssueCreditNote?.message}
      isDisabled={!accessabilityIssueCreditNote?.access}
    >
      <DropdownItem
        onClick={() => navigate(`/panel/active-invoices/${invoice.id_auto}/credit-note`)}
        children="Issue credit note"
        isDisabled={!accessabilityIssueCreditNote?.access}
      ></DropdownItem>
    </DisabledElementTooltip>
  ) : null
}

export default IssueCreditNoteDropdownItem
