import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'

// Hooks
import { useShipmentStatistics } from 'features/dashboard/api/useShipmentStatistics'
import { useExportShipments } from 'features/dashboard/api/useExportShipments'

// Atlassian
import Modal, {
  ModalTitle,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import Lozenge from '@atlaskit/lozenge'
import Button from '@atlaskit/button'

// Interfaces
import { ShortcutIcon } from '@royan-co/user-interface'

// Components
import ShipmentStatusTag from 'components/Shipments/StatusTag'
import ShipmentModeTag from 'components/Shipments/ModeTag'
import RemoteControlTable from './RemoteControlTable'

const tableHead = {
  cells: [
    { content: 'Reference' },
    { content: 'Mode' },
    { content: 'Status' },
    { content: 'Client' },
    { content: 'Operator' },
    { content: 'Creation date' },
  ],
}

const type = 'quotations'

const QuotationListModal = ({ isOpen, onClose, status, params }) => {
  const createRow = useCallback(
    (item, highlightRow) => [
      {
        content: (
          <span className="whitespace-nowrap">
            {item?.reference ? (
              <Link
                to={`/panel/shipments/${item.id_auto}`}
                target="_blank"
                onClick={() => highlightRow(item)}
              >
                {item.reference} <ShortcutIcon size={16} />
              </Link>
            ) : null}
          </span>
        ),
      },
      {
        content: <ShipmentModeTag item={item} />,
      },
      {
        content: <ShipmentStatusTag value={item.status} entity={'quotation'} />,
      },
      {
        content: item?.client?.id_auto ? (
          <Link to={`/panel/registries/${item.client.id_auto}`} target="_blank">
            {item.client.company_name}
          </Link>
        ) : (
          <Lozenge>Not set</Lozenge>
        ),
      },
      {
        content: item.operator?.full_name || <Lozenge>Not set</Lozenge>,
      },
      {
        content: item.creation_date,
      },
    ],
    []
  )

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={onClose} width="large" shouldScrollInViewport>
          <ModalHeader>
            <ModalTitle>Quotations</ModalTitle>
          </ModalHeader>
          <div className="px-6">
            <RemoteControlTable
              key={status}
              type={type}
              status={status}
              params={params}
              exportParams={{ ...params, status }}
              emptyState="No Quotations"
              tableHead={tableHead}
              onCreateRow={createRow}
              query={useShipmentStatistics}
              exportMutation={useExportShipments}
            />
          </div>
          <ModalFooter>
            <Button appearance="subtle" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  )
}

export default QuotationListModal
