import { useMemo } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'

//Services
import { getRegistryContact } from 'api/registries'
import { getContactLabel } from 'utils/helpers'

const useRegistryContact = (id) => {
  const queryClient = useQueryClient()

  const { isLoading, data } = useQuery(['contact', id], () => getRegistryContact(id), {
    staleTime: Infinity,
    retry: 2,
    enabled: !!id,
  })

  const registryContactOptions = useMemo(() => {
    if (!data) return []

    return data.map((contact) => ({ label: getContactLabel(contact), value: contact.id_auto }))
  }, [data])

  const upsertContactToData = (contact) => {
    queryClient.setQueryData(['contact', id], (oldRegistryContact) => [
      ...oldRegistryContact,
      contact,
    ])
  }

  return {
    contacts: data,
    upsertContactToData,
    registryContactOptions,
    isLoadingRegistryContact: !!id && isLoading,
  }
}

export default useRegistryContact
