const generateConsolidationUserPermissions = (consolidation, currentUser) => {
  const permissions = []

  const isShared =
    currentUser?.registry_id === consolidation?.creator?.registry_id &&
    currentUser?.registry_address_id === consolidation?.creator?.registry_address_id

  if (isShared) {
    permissions.push(
      'canDelete.shipment',
      'canViewCargowizPlanner',
      'canStartTrip',
      'canFinishTrip',
      'canChangeTakeInCharge',
      'canCreateCargoCollection',
      'canEdit',
      'canDelete',
      'canEdit.shipment',
      'canView.share',
      'canCreate.share',
      'canCreate.trackingUnit',
      'canDelete.trackingUnit',
      'canDelete.document',
      'canEdit.document',
      'canView.finance',
    )
  }

  return permissions
}

export { generateConsolidationUserPermissions }
