//Atlassian
import Lozenge from '@atlaskit/lozenge'

const ShipmentContactShow = ({ contact }) => {
  const hasContact = contact && (contact?.full_name || contact?.email || contact?.full_cellphone)

  return hasContact ? (
    <div className="flex flex-col">
      <small>{contact?.full_name}</small>

      {contact?.email && (
        <small>
          <a href={`mailto:${contact?.email}`}>{contact?.email}</a>
        </small>
      )}

      {contact?.full_cellphone && <small>+{contact?.full_cellphone}</small>}
    </div>
  ) : (
    <Lozenge appearance="default">Not set</Lozenge>
  )
}

export default ShipmentContactShow
