//Atlassian
import Lozenge from '@atlaskit/lozenge'

const ShipmentAddressShow = ({ address }) => {
  const hasAddress =
    address && (address?.address || address?.city || address?.cap || address.country_iso)

  return hasAddress ? (
    <small>
      {[address.address, address.city, address.cap, address.country_iso]
        .filter((i) => i)
        .join(', ')}
    </small>
  ) : (
    <Lozenge appearance="default">Not set</Lozenge>
  )
}

export default ShipmentAddressShow
