import { useMutation } from '@tanstack/react-query'

// Api
import { editConsolidationParam } from 'api/consolidations'

// Atlassian
import Modal, {
  ModalFooter,
  ModalHeader,
  ModalBody,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import { DatePicker } from '@atlaskit/datetime-picker'
import { ErrorMessage } from '@atlaskit/form'
import Button, { LoadingButton } from '@atlaskit/button'

// Interfaces
import { Field, Form, useFlags, WarningBannerField } from '@royan-co/user-interface'
import { handleErrorOnSubmit } from 'utils/errors'

const ChangeTakeInChargeDate = ({ isOpen, onClose, consolidation, update }) => {
  const { showSuccessFlag } = useFlags()
  const takeInChargeMutation = useMutation((data) =>
    editConsolidationParam(consolidation?.id_auto, 'creation_date', data)
  )

  const handleChangeTakeInCharge = (data, { setError }) => {
    takeInChargeMutation.mutate(data, {
      onError: (e) => {
        handleErrorOnSubmit(e, setError, data)
        console.error(e)
      },
      onSuccess: (res) => {
        update(data?.creation_date)
        showSuccessFlag(res.message)
        onClose()
      },
    })
  }

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={onClose} width="small">
          <ModalHeader>
            <ModalTitle>Change take in charge date</ModalTitle>
          </ModalHeader>

          <Form onSubmit={handleChangeTakeInCharge}>
            {() => (
              <>
                <ModalBody>
                  <Field
                    isRequired
                    label="Take in charge date"
                    name="creation_date"
                    defaultValue={consolidation?.creation_date}
                  >
                    {({ fieldProps, error }) => (
                      <>
                        <DatePicker placeholder="2/18/2022" {...fieldProps} />
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                      </>
                    )}
                  </Field>

                  <WarningBannerField />
                </ModalBody>
                <ModalFooter>
                  <Button appearance="subtle" spacing="compact" type="button" onClick={onClose}>
                    Discard
                  </Button>
                  <LoadingButton
                    appearance="primary"
                    spacing="compact"
                    type="submit"
                    isLoading={takeInChargeMutation.isLoading}
                    autoFocus
                  >
                    Save
                  </LoadingButton>
                </ModalFooter>
              </>
            )}
          </Form>
        </Modal>
      )}
    </ModalTransition>
  )
}

export default ChangeTakeInChargeDate
