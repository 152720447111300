import React from 'react'
import { useParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

import { deleteSurcharge } from 'api/rates'

//Hooks
import useRateSurcharges from 'hooks/useRateSurcharges'

//Atlassian
import Modal, {
  ModalBody,
  ModalTitle,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import Button, { LoadingButton } from '@atlaskit/button'

//Interfaces
import { useFlags, WarningBanner } from '@royan-co/user-interface'

const RatesDeleteSurchargeModal = ({ surcharge, isOpen, onClose }) => {
  const { showSuccessFlag } = useFlags()
  const { rateId } = useParams()

  const SurchargeDeleteMutation = useMutation(deleteSurcharge)

  const { deleteSurchargeFromData } = useRateSurcharges(rateId)

  const handleDeleteNote = () => {
    SurchargeDeleteMutation.mutate(surcharge.id, {
      onSuccess: (res) => {
        deleteSurchargeFromData(surcharge.id)
        showSuccessFlag(res?.message)
        onClose?.()
      },
    })
  }

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={onClose}>
          <ModalHeader>
            <ModalTitle appearance="danger">Delete surcharge</ModalTitle>
          </ModalHeader>
          <ModalBody>
            You are about to delete a surcharge. Are you sure?
            {SurchargeDeleteMutation.isError && <WarningBanner />}
          </ModalBody>
          <ModalFooter>
            <Button appearance="subtle" onClick={onClose}>
              Cancel
            </Button>
            <LoadingButton
              isLoading={SurchargeDeleteMutation.isLoading}
              appearance="danger"
              onClick={handleDeleteNote}
              autoFocus
            >
              Delete
            </LoadingButton>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  )
}

export default RatesDeleteSurchargeModal
