// Components
import ElaborateInvoiceAction from './ElaborateInvoiceAction'
import DisputeInvoiceAction from './DisputeInvoiceAction'
import RegisteringInvoiceAction from './RegisteringInvoiceAction'
import ResetInvoiceAction from './ResetInvoiceAction'
import TakeInChargeInvoiceAction from './TakeInChargeInvoiceAction'

const BufferInvoiceActions = ({ invoice, openElaborateModal, openTakeInChargeModal }) => {
  return (
    <>
      <TakeInChargeInvoiceAction
        invoice={invoice}
        isBuffer
        openTakeInChargeModal={openTakeInChargeModal}
      />

      <ElaborateInvoiceAction invoice={invoice} openElaborateModal={openElaborateModal} />

      <DisputeInvoiceAction invoice={invoice} />

      <ResetInvoiceAction invoice={invoice} />

      <RegisteringInvoiceAction invoice={invoice} />
    </>
  )
}

export default BufferInvoiceActions
