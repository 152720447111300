import { useParams } from 'react-router-dom'
//Hooks
import useShipment from 'hooks/useShipment'

//Components
import DenyFromUserLevel from 'layouts/DenyFromUserLevel'
import ShipmentGeneralDetail from 'components/Shipments/View/GeneralDetails'
import ShipmentCustomClearance from 'components/Shipments/View/CustomClearance'
import ShipmentShipperDetail from 'components/Shipments/View/ShipperDetails'
import ShipmentConsigneeDetail from 'components/Shipments/View/ConsigneeDetails'
import ShipmentClientDetail from 'components/Shipments/View/ClientDetails'

const ShipmentViewGeneralPage = () => {
  const { shipmentId } = useParams()
  const { setLimitationExceptCreator } = useShipment()

  return (
    <>
      <ShipmentGeneralDetail />

      {shipmentId && (
        <>
          <ShipmentShipperDetail />

          <ShipmentConsigneeDetail />
        </>
      )}

      <DenyFromUserLevel level={setLimitationExceptCreator('warehouse')} fallback={null}>
        <ShipmentClientDetail />
      </DenyFromUserLevel>

      {shipmentId && <ShipmentCustomClearance />}
    </>
  )
}

export default ShipmentViewGeneralPage
