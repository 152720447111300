import LoadingOverlay from 'react-loading-overlay'
import { useMediaQuery } from 'react-responsive'

//Contexts
import { useApp } from 'contexts/AppProvider'

//Atlassian
import Spinner from '@atlaskit/spinner'

LoadingOverlay.propTypes = undefined

const LoadingOverlayContainer = ({ children }) => {
  const { loadingOverlay } = useApp()
  const isMobile = useMediaQuery({ query: `(max-width: 991.98px)` })

  return (
    <LoadingOverlay
      active={loadingOverlay}
      spinner={<Spinner size={isMobile ? 'large' : 75} appearance="invert" />}
      className="LoadingOverlay"
    >
      {children}
    </LoadingOverlay>
  )
}

export default LoadingOverlayContainer
