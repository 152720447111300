//Atlassian
import Lozenge from '@atlaskit/lozenge'

const YesNoTag = ({ value }) => {
  return value ? (
    <Lozenge appearance="success">Yes</Lozenge>
  ) : (
    <Lozenge appearance="default">No</Lozenge>
  )
}

export default YesNoTag
