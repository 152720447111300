import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

// Hooks
import useShipment from 'hooks/useShipment'

// Utils
import { formatNumber } from 'utils/numbers'

// Contexts
import { useAuth } from 'contexts/AuthProvider'

// Features
import { useCanAccess } from 'features/auth'

// Atlassian
import TableTree, { Cell, Row, Rows } from '@atlaskit/table-tree'

// Components
import Card from 'components/UI/Card'
import ShipmentViewCargoTrucks from 'components/Shipments/View/CargoTrucks'
import ShipmentViewCargoPackages from 'components/Shipments/View/CargoPackages'
import ShipmentViewCargoSpecialConditions from 'components/Shipments/View/CargoSpecialConditions'
import { generateShipmentUserPermissions } from 'components/Shipments/Create/shipment-user-permissions'
import ShipmentNumericInlineEdit from 'components/Shipments/View/GeneralDetails/Numeric'
import ShipmentViewCargoInvoiceInformation from 'components/Shipments/View/CargoInvoiceInformation'

const ShipmentViewCargoPage = () => {
  const { shipmentId, quotationId } = useParams()
  const entityId = shipmentId || quotationId

  const { shipment } = useShipment(entityId)
  const { currentUser } = useAuth()
  const userPermissions = generateShipmentUserPermissions(shipment, currentUser)

  const canCreateCargo = useCanAccess({
    resource: 'shipments.cargo',
    action: 'create',
    userPermissions,
  })
  const canEditCargo = useCanAccess({
    resource: 'shipments.cargo',
    action: 'edit',
    userPermissions,
  })
  const canDeleteCargo = useCanAccess({
    resource: 'shipments.cargo',
    action: 'delete',
    userPermissions,
  })

  const accessCreatingCargo = useMemo(
    () => !shipment?.is_master && canCreateCargo,
    [canCreateCargo, shipment]
  )
  const accessEditingCargo = useMemo(
    () => !shipment?.is_master && canEditCargo,
    [canEditCargo, shipment]
  )
  const accessDeletingCargo = useMemo(
    () => !shipment?.is_master && canDeleteCargo,
    [canDeleteCargo, shipment]
  )

  const canEditShipment = useCanAccess({
    resource: 'shipments',
    action: 'edit',
    userPermissions,
  })

  const totalWeightData = useMemo(
    () => [
      {
        title: 'Total collies',
        content: (
          <ShipmentNumericInlineEdit
            canEdit={canEditShipment}
            attribute="total_collies"
            shipmentId={entityId}
            value={shipment?.total_collies}
          />
        ),
      },
      {
        title: 'Total gross weight',
        content: (
          <ShipmentNumericInlineEdit
            canEdit={canEditShipment}
            attribute="weight"
            shipmentId={entityId}
            value={formatNumber(shipment?.weight || 0)}
            view={formatNumber(shipment?.weight || 0) + ' Kgs'}
            fullUpdate
          />
        ),
      },
      {
        title: 'Total chargeable weight',
        content: (
          <ShipmentNumericInlineEdit
            canEdit={canEditShipment}
            attribute="chargeable_weight"
            shipmentId={entityId}
            value={formatNumber(shipment?.chargeable_weight || 0)}
            view={formatNumber(shipment?.chargeable_weight || 0) + ' Kgs'}
          />
        ),
        children: [
          {
            title: 'Road national',
            content: formatNumber(shipment?.road_national_chargeable_weight || 0) + ' Kgs',
            show: shipment?.shipment_type === 0,
          },
          {
            title: 'Road international',
            content: formatNumber(shipment?.road_international_chargeable_weight || 0) + ' Kgs',
            show: shipment?.shipment_type === 0,
          },
          {
            title: 'Sea international',
            content: formatNumber(shipment?.sea_international_chargeable_weight || 0) + ' Kgs',
            show: shipment?.shipment_type === 1,
          },
          {
            title: 'Air international',
            content: formatNumber(shipment?.air_international_chargeable_weight || 0) + ' Kgs',
            show: shipment?.shipment_type === 2,
          },
        ].filter((i) => i.show),
      },
    ],
    [canEditShipment, entityId, shipment]
  )

  return (
    <>
      <Card className="mb-6">
        <TableTree>
          <Rows
            items={totalWeightData}
            render={({ id, title, content, children = [] }) => (
              <Row itemId={id} items={children} hasChildren={children.length > 0}>
                <Cell className="w-1/2 tableTreeCellAtlassian">
                  <span className="text-xs text-neutral-200 font-semibold">{title}</span>
                </Cell>
                <Cell>{content}</Cell>
              </Row>
            )}
          />
        </TableTree>
      </Card>

      {shipment.type_of_collies === 0 && (
        <ShipmentViewCargoPackages
          isMaster={shipment?.is_master}
          canCreateCargo={accessCreatingCargo}
          canEditCargo={accessEditingCargo}
          canDeleteCargo={accessDeletingCargo}
        />
      )}

      {shipment.type_of_collies === 1 && (
        <ShipmentViewCargoTrucks
          canCreateCargo={accessCreatingCargo}
          canEditCargo={accessEditingCargo}
          canDeleteCargo={accessDeletingCargo}
        />
      )}

      <ShipmentViewCargoSpecialConditions canEditSpecialConditions={canEditShipment} />

      {!!shipmentId && <ShipmentViewCargoInvoiceInformation />}
    </>
  )
}

export default ShipmentViewCargoPage
