import { useMutation } from '@tanstack/react-query'

// Api
import { deactivateUser } from './users-requests'

// Interfaces
import { useFlags, useRoyanTable } from '@royan-co/user-interface'

const useDeactivateUser = (userId) => {
  const { refresh } = useRoyanTable()
  const { showSuccessFlag } = useFlags()

  return useMutation({
    mutationFn: (access) => deactivateUser(userId, { access }),

    onSuccess: (res) => {
      showSuccessFlag(res.message)
      if (refresh) {
        refresh()
      }
    },

    onError: (e) => {
      console.error(e)
    },
  })
}

export { useDeactivateUser }
