import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getShipmentCargo } from 'api/shipments'

const useShipmentCargo = (shipmentId, { pickupStatus } = {}) => {
  const queryClient = useQueryClient()
  const queryKey = ['shipment', 'cargo', shipmentId, pickupStatus]

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: () => getShipmentCargo(shipmentId, pickupStatus),
    retry: 2,
    staleTime: Infinity,
  })

  const deleteCargoFromData = (deletedId) => {
    queryClient.setQueryData(queryKey, (cargoes) =>
      cargoes.filter((c) => c.id !== deletedId)
    )
  }

  const upsertCargoToData = (cargo) => {
    const isExist = data.some((n) => n.id === cargo.id)

    queryClient.setQueryData(queryKey, (cargoes) =>
      isExist ? cargoes.map((c) => (c.id === cargo.id ? cargo : c)) : [...cargoes, cargo]
    )
  }

  return {
    shipmentCargo: data,
    isLoadingShipmentCargo: isLoading,
    deleteCargoFromData,
    upsertCargoToData,
  }
}

export default useShipmentCargo
