// Utils
import { findOption } from 'utils/helpers'
import { fixMinimumOptions } from 'features/marginCalculator/utils/constants'

// Atlassian
import Lozenge from '@atlaskit/lozenge'

const FixMinimumTag = ({ value }) => {
  const option = findOption(fixMinimumOptions, value ? 1 : 0)
  return option ? <Lozenge appearance={option.appearance}>{option.label}</Lozenge> : null
}

export default FixMinimumTag
