import { Link } from 'react-router-dom'

import { findOption } from 'utils/helpers'

//Interfaces
import { DotsIcon } from '@royan-co/user-interface'

//Atlassian
import Button from '@atlaskit/button'
import Lozenge from '@atlaskit/lozenge'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'

//Components
import { calculationMethodOptions } from '../constants'
import RatesAllowCalculationTag from '../AllowCalculationTag'
import ShipmentModeTag from 'components/Shipments/ModeTag'
import CountryFlag from 'components/UI/CountryFlag'
import RatesFormatTag from '../FormatTag'
import RatesDuplicateRate from '../view/DuplicateRate'
import DropdownLink from 'components/UI/DropdownLink'

export const generateRatesColumns = (openDeleteModal) => {
  return [
    {
      key: 'name',
      title: 'Name',
      isSortable: true,
      show: true,
      content: (item) => item.name,
    },
    {
      key: 'type',
      title: 'Type',
      isSortable: false,
      show: true,
      content: (item) => item.from_to_label,
    },
    {
      key: 'shipment_type',
      title: 'Mode',
      isSortable: false,
      show: true,
      content: (item) => <ShipmentModeTag item={item} />,
    },
    {
      key: 'supplier',
      title: 'Supplier',
      isSortable: false,
      show: true,
      content: (item) => (
        <a href={`/panel/registries/${item.supplier.id_auto}`} target="_blank" rel="noreferrer">
          {item.supplier.company_name}
        </a>
      ),
    },
    {
      key: 'airline',
      title: 'Airline',
      isSortable: false,
      show: true,
      content: (item) => item.airline_data.company_name,
    },
    {
      key: 'pol',
      title: 'POL',
      isSortable: false,
      show: true,
      content: (item) =>
        item.departure === null ? (
          <Lozenge appearance="inprogress">Multiple</Lozenge>
        ) : [1, 2, 8, 14].includes(item.from_to) ? (
          <CountryFlag iso={item.departure_country} />
        ) : (
          item.departure
        ),
    },

    {
      key: 'pod',
      title: 'POD',
      isSortable: false,
      show: true,
      content: (item) =>
        item.arrival === null ? (
          <Lozenge appearance="inprogress">Multiple</Lozenge>
        ) : [0, 2, 9, 13].includes(item.from_to) ? (
          <CountryFlag iso={item.arrival_country} />
        ) : (
          item.arrival
        ),
    },
    {
      key: 'cost_code',
      title: 'Cost code',
      isSortable: false,
      show: true,
      content: (item) => item.cost_code,
    },

    {
      key: 'allow_calculate',
      title: 'Allow calculation',
      isSortable: true,
      show: true,
      content: (item) => <RatesAllowCalculationTag value={item.allow_calculate} />,
    },
    {
      key: 'calculation_method',
      title: 'Calculation method',
      isSortable: false,
      show: true,
      content: (item) => findOption(calculationMethodOptions, item.calculation_method)?.label,
    },
    {
      key: 'format_type',
      title: 'Format',
      isSortable: false,
      show: true,
      content: (item) => <RatesFormatTag value={item?.type} />,
    },
    {
      key: 'creation_date',
      title: 'Creation date',
      isSortable: true,
      show: true,
      content: (item) => item.creation_date,
    },
    {
      key: 'actions',
      title: 'Actions',
      show: true,
      content: (item) => (
        <DropdownMenu
          placement="bottom-end"
          trigger={({ triggerRef, ...props }) => (
            <Button
              {...props}
              appearance="subtle"
              children={<DotsIcon label="more" />}
              ref={triggerRef}
            />
          )}
        >
          <DropdownItemGroup>
            <DropdownLink to={'/panel/rates/' + item.id_auto} children="View" />

            <DropdownLink to={`/panel/rates/${item.id_auto}/edit`} children="Edit" />

            <RatesDuplicateRate rateId={item.id_auto} />

            <DropdownItem children="Delete" onClick={() => openDeleteModal(item.id_auto)} />
          </DropdownItemGroup>
        </DropdownMenu>
      ),
    },
  ]
}
