//Services
import useShipmentsHubs from 'hooks/useShipmentsHubs'
import { findOption, transformSelectField } from 'utils/helpers'

//Atlassian
import { Checkbox } from '@atlaskit/checkbox'
import { ErrorMessage } from '@atlaskit/form'
import Select from '@atlaskit/select'

//Interfaces
import { Field, useFormContext } from '@royan-co/user-interface'

//Components
import Card from 'components/UI/Card'
import Divider from 'components/UI/Divider'
import TripleFieldsRegistry from 'components/Shipments/View/TripleFieldsRegistry'
import {
  registryReasonExportClearanceOptions,
  registryReasonImportClearanceOptions,
} from 'components/Registries/constants'
import { registries } from './registries'

const departureFields = registries.departure_fields
const destinationFields = registries.destination_fields

const ShipmentViewEditRegistriesClearance = () => {
  const { watch } = useFormContext()
  const { shipmentsHubsOptions, isLoadingShipmentsHubs } = useShipmentsHubs()

  return (
    <Card className="w-full my-6">
      <Field
        label="Export custom clearance"
        type="checkbox"
        name="departure_custom_needed"
        className="!mt-0"
      >
        {({ fieldProps }) => <Checkbox label="Required" {...fieldProps} />}
      </Field>

      {!watch('departure_custom_needed') && (
        <Field
          isRequired
          label="Reason"
          type="select"
          className="!mt-4"
          name="departure_custom_nn_reason"
          transform={{
            output: (opt) => (opt?.value !== undefined ? opt.value : null),
            input: (v) => findOption(registryReasonExportClearanceOptions, v),
          }}
        >
          {({ fieldProps }) => (
            <Select
              isClearable={true}
              options={registryReasonExportClearanceOptions}
              placeholder="Choose one"
              spacing="compact"
              {...fieldProps}
            />
          )}
        </Field>
      )}

      {!!watch('departure_custom_needed') && (
        <TripleFieldsRegistry
          className="mt-4"
          names={departureFields.names}
          labels={departureFields.labels}
        />
      )}

      <Divider className="mt-8 mb-6" />

      <Field
        label="Import custom clearance"
        type="checkbox"
        name="destination_custom_needed"
        className="!mt-0"
      >
        {({ fieldProps }) => <Checkbox label="Required" spacing="compact" {...fieldProps} />}
      </Field>

      {!watch('destination_custom_needed') && (
        <>
          <Field
            isRequired
            label="Reason"
            type="select"
            className="!mt-4"
            name="destination_custom_nn_reason"
            transform={{
              output: (opt) => (opt?.value !== undefined ? opt.value : null),
              input: (v) => findOption(registryReasonImportClearanceOptions, v),
            }}
          >
            {({ fieldProps }) => (
              <Select
                isClearable={true}
                options={registryReasonImportClearanceOptions}
                placeholder="Choose one"
                spacing="compact"
                {...fieldProps}
              />
            )}
          </Field>

          <Field
            type="select"
            className="!mt-4"
            name="destination_custom_terminal"
            label="Destination terminal"
            transform={transformSelectField(shipmentsHubsOptions)}
          >
            {({ fieldProps, error }) => (
              <>
                <Select
                  isLoading={isLoadingShipmentsHubs}
                  options={shipmentsHubsOptions}
                  spacing="compact"
                  {...fieldProps}
                />
                {error && <ErrorMessage>{error}</ErrorMessage>}
              </>
            )}
          </Field>
        </>
      )}

      {!!watch('destination_custom_needed') && (
        <TripleFieldsRegistry
          className="mt-4"
          names={destinationFields.names}
          labels={destinationFields.labels}
        />
      )}
    </Card>
  )
}

export default ShipmentViewEditRegistriesClearance
