import { forwardRef } from 'react'
import { CustomItem } from '@atlaskit/side-navigation'
import { Link, useMatch, useResolvedPath } from 'react-router-dom'

const SidebarLink = forwardRef(({ to, children, exactPath = true, ...rest }, ref) => {
  let resolved = useResolvedPath(to)
  let match = useMatch({ path: resolved.pathname, end: exactPath })

  return (
    <CustomItem to={to} component={Link} isSelected={match} ref={ref} {...rest}>
      {children}
    </CustomItem>
  )
})

export default SidebarLink
