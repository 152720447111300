import React, { useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import Tabs, { Tab, TabList } from '@atlaskit/tabs'
import { Link, useLocation, useParams, Outlet, useNavigate } from 'react-router-dom'

import { exportRate } from 'api/rates'

//Hooks
import useRate from 'hooks/useRate'

//Icons
import { DotsIcon, Skeleton } from '@royan-co/user-interface'

//Atlassian
import Spinner from '@atlaskit/spinner'
import PageHeader from '@atlaskit/page-header'
import Button, { ButtonGroup } from '@atlaskit/button'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'

//Interfaces
import { useRoyanTableLastFilters, useDisclosure, useFlags } from '@royan-co/user-interface'

//Components
import DenyFromUserLevel from 'layouts/DenyFromUserLevel'
import RatesDuplicateRate from 'components/Rates/view/DuplicateRate'
import RatesDeleteRateModal from 'components/Rates/view/DeleteRateModal'

const tabData = [
  { title: 'General information', path: '' },
  { title: 'Surcharges', path: '/surcharges' },
  { title: 'Table rate', path: '/records' },
]

const RateViewPage = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  let { rateId } = useParams()
  const { rate, isFetching, isLoading } = useRate(rateId)
  const { lastUrlSearch } = useRoyanTableLastFilters('rates-list')
  const { showSuccessFlag, showWarningFlag } = useFlags()

  const [isOpenDeleteRateModal, deleteRateModalHandler] = useDisclosure('delete-rate')

  const tabs = useMemo(
    () =>
      tabData
        .filter((tab) => (rate?.calculation_method === 0 ? tab.path !== '/records' : true))
        .map((t) => ({
          ...t,
          path: `/panel/rates/${rateId}${t.path}`,
        })),
    [rateId, rate]
  )

  const selectedTabIndex = useMemo(
    () => tabs.findIndex((t) => t.path === pathname),
    [pathname, tabs]
  )

  const navigateToTab = (index) => {
    const tab = tabs[index]

    navigate(tab.path)
  }

  const handleExport = async () => {
    try {
      const res = await exportRate(rateId)
      showSuccessFlag(res?.message)
    } catch (e) {
      console.log(e)
      showWarningFlag('Something went wrong.')
    }
  }

  return (
    <DenyFromUserLevel level="warehouse">
      <Helmet>
        <title>{rate?.name}</title>
      </Helmet>

      <PageHeader
        breadcrumbs={
          <Breadcrumbs>
            <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
            <BreadcrumbsItem text="Sales machine" />
            <BreadcrumbsItem text="Rates" to="/panel/rates" component={Link} />
          </Breadcrumbs>
        }
        actions={
          <ButtonGroup>
            <Button component={Link} appearance="primary" to={`/panel/rates/${rateId}/edit`}>
              Edit information
            </Button>

            <Button
              component={Link}
              appearance="default"
              to={`/panel/rates${lastUrlSearch ? `?${lastUrlSearch}` : ''}`}
            >
              Back
            </Button>

            <DropdownMenu
              placement="bottom-end"
              trigger={({ triggerRef, ...props }) => (
                <Button
                  {...props}
                  appearance="default"
                  children={<DotsIcon label="more" />}
                  ref={triggerRef}
                />
              )}
            >
              <DropdownItemGroup>
                <DropdownItem children="Export" onClick={handleExport} />
                <DropdownItem
                  children="Delete"
                  onClick={() => deleteRateModalHandler.open(rateId)}
                />
                <RatesDuplicateRate rateId={rateId} />
              </DropdownItemGroup>
            </DropdownMenu>
          </ButtonGroup>
        }
      >
        {isLoading ? (
          <Skeleton height={32} maw={320} />
        ) : (
          <div className="flex items-center">
            {rate?.name}
            {isFetching && (
              <span className="ml-3 text-xs font-light text-neutral-50">updating...</span>
            )}
          </div>
        )}
      </PageHeader>

      <>
        <Tabs onChange={navigateToTab} selected={selectedTabIndex}>
          <TabList>
            {tabs.map((tab, i) => (
              <Tab key={i} on>
                {tab.title}
              </Tab>
            ))}
          </TabList>
        </Tabs>

        <div className="px-2 py-4">
          {isLoading ? (
            <div className="flex justify-center my-32">
              <Spinner />
            </div>
          ) : (
            selectedTabIndex > -1 && <Outlet />
          )}
        </div>

        <RatesDeleteRateModal
          isOpen={isOpenDeleteRateModal}
          onClose={deleteRateModalHandler.close}
          rateId={deleteRateModalHandler.data}
        />
      </>
    </DenyFromUserLevel>
  )
}

export default RateViewPage
