import { useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import { findOption } from 'utils/helpers'
import { formatNumber } from 'utils/numbers'

// Contexts
import { useAuth } from 'contexts/AuthProvider'

//Hooks
import useShipment from 'hooks/useShipment'

//Atlassian
import Lozenge from '@atlaskit/lozenge'
import { DynamicTableStateless } from '@atlaskit/dynamic-table'
import EmptyState from '@atlaskit/empty-state'

//Interfaces
import { ShortcutIcon } from '@royan-co/user-interface'

//Components
import { shipmentStatusOptions } from 'components/Shipments/constants'
import Card from 'components/UI/Card'
import CountryFlag from 'components/UI/CountryFlag'
import SearchInput from 'components/UI/SearchInput'

const ShipmentViewHouseShipmentsPage = () => {
  const { currentUser } = useAuth()
  const { shipmentId } = useParams()
  const [searchTerm, setSearchTerm] = useState('')

  const { shipment } = useShipment(shipmentId)

  const isCreatorUser = useMemo(
    () =>
      currentUser?.registry_id === shipment?.creator?.registry_id &&
      currentUser?.registry_address_id === shipment?.creator?.registry_address_id,
    [shipment, currentUser]
  )

  const shipmentsHead = useMemo(() => {
    let headItems = [
      { content: 'Reference' },
      { content: 'Client' },
      { content: 'Consignee' },
      { content: 'Shipper' },
      { content: 'Destination' },
      { content: 'Total collies' },
      { content: 'Weight (Kgs)' },
    ]

    return {
      cells: headItems.filter((item) => (item.content === 'Client' ? isCreatorUser : true)),
    }
  }, [isCreatorUser])

  const shipmentsRows = useMemo(() => {
    const body =
      shipment?.house_shipments
        ?.filter((d) => d?.reference?.toLocaleLowerCase()?.includes(searchTerm.toLocaleLowerCase()))
        ?.map((item, i) => {
          let shipmentsItems = [
            {
              id: `reference${i}`,
              content: (
                <>
                  {item?.reference ? (
                    <Link to={`/panel/shipments/${item?.id_auto}`} target="_blank">
                      <div className="whitespace-nowrap">
                        {item?.reference} <ShortcutIcon size={16} />
                      </div>
                    </Link>
                  ) : null}

                  {item?.status ? (
                    <div>
                      <Lozenge appearance="default">
                        {findOption(shipmentStatusOptions, item?.status)?.label}
                      </Lozenge>
                    </div>
                  ) : null}

                  {item?.voluminous ? (
                    <div>
                      <Lozenge appearance="moved">Voluminous</Lozenge>
                    </div>
                  ) : null}

                  {item?.dense ? (
                    <div>
                      <Lozenge appearance="moved">Dense</Lozenge>
                    </div>
                  ) : null}
                </>
              ),
            },
            {
              id: `client${i}`,
              content: item?.client?.company_name || (
                <Lozenge appearance="default">Not set</Lozenge>
              ),
            },
            {
              id: `consignee${i}`,
              content: item?.consignee?.company_name || (
                <Lozenge appearance="default">Not set</Lozenge>
              ),
            },
            {
              id: `shipper${i}`,
              content: item?.shipper?.company_name || (
                <Lozenge appearance="default">Not set</Lozenge>
              ),
            },
            {
              id: `arrival_country${i}`,
              content: (
                <>
                  <CountryFlag iso={item?.arrival_country} />
                  {item?.arrival_country && item?.arrival_city && <span>, </span>}
                  {item?.arrival_city}
                </>
              ),
            },
            {
              id: `total_collies${i}`,
              content: item.total_collies || <Lozenge appearance="default">Not set</Lozenge>,
            },
            {
              id: `weight${i}`,
              content: item?.weight ? (
                <span className="whitespace-nowrap">{formatNumber(item?.weight)}</span>
              ) : (
                <Lozenge appearance="default">Not set</Lozenge>
              ),
            },
          ]

          return {
            key: `packagesRows${i}`,
            // eslint-disable-next-line no-sparse-arrays
            cells: shipmentsItems.filter((item) =>
              item.id.includes('client') ? isCreatorUser : true
            ),
          }
        }) || []

    body.push({
      key: `shipmentsRows-total`,
      cells: [
        {
          content: <strong>Total</strong>,
        },
        ...new Array(shipmentsHead.cells?.length - 3 || 0).fill({
          content: '',
        }),
        {
          content: shipment?.house_shipments?.reduce((p, c) => p + c.total_collies * 1, 0)
        },
        {
          content: formatNumber(
            shipment?.house_shipments?.reduce((p, c) => p + c.weight * 1, 0) || 0
          ),
        },
      ],
    })

    return body
  }, [isCreatorUser, searchTerm, shipment?.house_shipments, shipmentsHead])

  return (
    <>
      <div style={{ maxWidth: '240px' }}>
        <SearchInput onSubmit={setSearchTerm} delay={150} />
      </div>

      <Card className="mt-4">
        {shipmentsRows?.length < 1 ? (
          <EmptyState header="No house shipments" />
        ) : (
          <div className="overflow-auto inline-block w-full -mb-6 borderOfAtlaskitTableWithTotalRow">
            <DynamicTableStateless head={shipmentsHead} rows={shipmentsRows} />
          </div>
        )}
      </Card>
    </>
  )
}

export default ShipmentViewHouseShipmentsPage
