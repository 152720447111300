import { useMemo } from 'react'
import { Link, useParams } from 'react-router-dom'

// Hooks
import { useStock } from 'features/AirWaybillStock/api/useStock'

//Atlassian
import Button from '@atlaskit/button'
import Spinner from '@atlaskit/spinner'
import SectionMessage from '@atlaskit/section-message'
import Lozenge from '@atlaskit/lozenge'

// Interfaces
import { ShortcutIcon } from '@royan-co/user-interface'

//Components
import RowTable from 'components/UI/RowTable'
import Card, { CardSubtitle } from 'components/UI/Card'

const AirWaybillViewGeneral = () => {
  const { stockId } = useParams()
  const { data: stock = {}, isLoading, isError, refetch } = useStock(stockId)

  const generalRows = useMemo(
    () => [
      {
        title: 'Airline',
        content: stock.airline_data?.company_name,
        // <Link to={''} target="_blank">
        //   {stock.airline_data?.company_name} <ShortcutIcon size={16} />
        // </Link>
      },
      {
        title: 'Start date',
        content: stock.start_date || <Lozenge>Not set</Lozenge>,
      },
      {
        title: 'End date',
        content: stock.end_date || <Lozenge>Not set</Lozenge>,
      },
      {
        title: 'From',
        content: stock.from || <Lozenge>Not set</Lozenge>,
      },

      {
        title: 'To',
        content: stock.to,
      },
    ],
    [stock]
  )

  return (
    <Card className="mt-0">
      <CardSubtitle className="pb-2 pt-1">General information</CardSubtitle>

      {isLoading ? (
        <div className="flex justify-center my-4">
          <Spinner />
        </div>
      ) : isError ? (
        <SectionMessage appearance="warning" title="Something wrong on loading data, please retry.">
          <Button onClick={() => refetch()}>Retry</Button>
        </SectionMessage>
      ) : (
        <RowTable rows={generalRows} width="w-1/3" />
      )}
    </Card>
  )
}

export default AirWaybillViewGeneral
