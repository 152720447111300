import { Link, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

// Api
import { getVehicleTypes } from '../api/vehicle-type-requests'

// Atlassian
import PageHeader from '@atlaskit/page-header'
import Button, { ButtonGroup } from '@atlaskit/button'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'

// Interfaces
import { RoyanTable, useDisclosure } from '@royan-co/user-interface'

// Components
import { generateVehicleTypesColumns } from '../components/List/columns'
import VehicleTypeDeleteModal from '../components/DeleteModal'
import VehicleTypeFilterFields from '../components/List/FilterFields'
import { vehicleTypeFilters } from '../components/List/filters'

const pageTitle = 'Vehicle types'

const VehicleTypeListPage = () => {
  const navigate = useNavigate()

  const [isOpenDeleteModal, deleteModalHandler] = useDisclosure('delete')

  const openDeleteModal = (typeId) => {
    deleteModalHandler.open(typeId)
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <PageHeader
        breadcrumbs={
          <Breadcrumbs>
            <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
            <BreadcrumbsItem text="Configurations" />
          </Breadcrumbs>
        }
        actions={
          <ButtonGroup>
            <Button appearance="primary" onClick={() => navigate('/panel/vehicle-types/create')}>
              New vehicle type
            </Button>
          </ButtonGroup>
        }
      >
        {pageTitle}
      </PageHeader>

      <RoyanTable
        sortOrder="DESC"
        name="vehicle-type-list"
        sortKey="creation_date"
        request={getVehicleTypes}
        filters={vehicleTypeFilters}
        filterFields={<VehicleTypeFilterFields />}
        columns={generateVehicleTypesColumns(openDeleteModal)}
      >
        <VehicleTypeDeleteModal
          isOpen={isOpenDeleteModal}
          onClose={deleteModalHandler.close}
          typeId={deleteModalHandler.data}
        />
      </RoyanTable>
    </>
  )
}

export default VehicleTypeListPage
