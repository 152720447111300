import { createElement } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

//Utils
import { formatMonetaryNumber } from 'utils/numbers'

//Apis
import { getRegistryBalances, getRegistryInvoices } from 'api/registries'

//Hooks
import useRegistry from 'hooks/useRegistry'

//Interfaces
import {
  Grid,
  Skeleton,
  RoyanTable,
  AspectRatio,
  WarningIcon,
  CreditcardIcon,
  SpreadsheetIcon,
} from '@royan-co/user-interface'

//Components
import { registryInvoicesColumns } from 'components/Registries/View/invoices-columns'
import {
  RegistriesFinancialBalanceFilterFields,
  RegistriesFinancialBalanceFilters,
} from 'components/Registries/View/FinancialBalanceFilters'

// Features
import { invoiceTypes } from 'features/invoice'

const balanceNumbers = [
  {
    key: 'total_paid',
    title: 'Total paid',
    icon: SpreadsheetIcon,
  },
  {
    key: 'credit',
    title: 'Credit',
    icon: CreditcardIcon,
  },
  {
    key: 'to_be_paid',
    title: 'To be Paid',
    icon: WarningIcon,
  },
]

const RegistryViewFinancialBalancePage = () => {
  const { registryId, creatorId } = useParams()
  const { creator } = useRegistry(registryId, { creatorId })

  const balancesQuery = useQuery(
    ['registry-balances', registryId],
    () => getRegistryBalances(registryId),
    { staleTime: Infinity }
  )

  const handleDoubleClick = (e, item) => {
    const _type = invoiceTypes.find(
      (type) =>
        type.invoiceKeys.active_passive === item?.active_passive &&
        type.invoiceKeys.buffer === item?.buffer
    )
    if (_type) window.open(`${_type.pathList}/${item.id_auto}`, '_blank')
  }

  return (
    <>
      <Grid>
        {balanceNumbers.map((b, i) => (
          <Grid.Col key={i} lg={4} className="auto-rows-fr">
            <div className="shadow rounded p-4 flex items-center h-full">
              <AspectRatio
                ratio={1}
                className="bg-neutral-40 text-neutral-500 rounded"
                style={{ width: '28%', minWidth: '40px', maxWidth: '60px' }}
              >
                <div>{createElement(b.icon, { size: 32 })}</div>
              </AspectRatio>

              <div className="ml-4">
                {balancesQuery.isLoading ? (
                  <Skeleton height={28} />
                ) : (
                  <div className="text-xl font-medium text-neutral-800">
                    €
                    {balancesQuery.data?.balances?.[b.key]?.[creator?.user?.auto_id] !== undefined
                      ? formatMonetaryNumber(
                          balancesQuery.data?.balances?.[b.key]?.[creator?.user?.auto_id] || 0
                        )
                      : formatMonetaryNumber(balancesQuery.data?.balances?.[b.key] || 0)}
                  </div>
                )}

                <div className="text-neutral-200 mt-1">{b.title}</div>
              </div>
            </div>
          </Grid.Col>
        ))}
      </Grid>

      <div className="mt-6">
        <RoyanTable
          columnsControl={false}
          name="registry_invoices"
          columns={registryInvoicesColumns}
          onRowDoubleClick={handleDoubleClick}
          request={getRegistryInvoices.bind(null, registryId)}
          filters={RegistriesFinancialBalanceFilters}
          filterFields={<RegistriesFinancialBalanceFilterFields />}
        />
      </div>
    </>
  )
}

export default RegistryViewFinancialBalancePage
