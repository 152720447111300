import React, { useMemo } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { Helmet } from 'react-helmet-async'

import { createRecordRate, editRecordRate } from 'api/rates'
import { getFilled } from 'utils/helpers'
import { handleErrorOnSubmit } from 'utils/errors'

//Hooks
import useRateRecords from 'hooks/useRateRecords'
import useRate from 'hooks/useRate'

//Atlassian
import Spinner from '@atlaskit/spinner'
import PageHeader from '@atlaskit/page-header'
import SectionMessage from '@atlaskit/section-message'
import Button, { LoadingButton } from '@atlaskit/button'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'

//Interfaces
import { Form, Grid, WarningBannerField, useFlags } from '@royan-co/user-interface'

//Components
import RatesCreationRecordGeneralSection from 'components/Rates/view/CreationRecordGeneralSection'
import RatesCreationRecordStepsSection from 'components/Rates/view/CreationRecordStepsSection'

const RateRecordsCreationPage = () => {
  const navigate = useNavigate()
  const { recordId, rateId } = useParams()
  const { showSuccessFlag } = useFlags()

  const { rate, isLoading, isFetching, isError, refetch } = useRate(rateId, { enabled: !recordId })

  const {
    record,
    isLoadingRateRecords,
    isFetchingRateRecords,
    isErrorRateRecords,
    refetchRateRecords,
    invalidate,
  } = useRateRecords(rateId, { recordId: recordId, enabled: !!recordId })

  const recordCreationMutation = useMutation(
    !!recordId ? editRecordRate.bind(null, recordId) : createRecordRate.bind(null, rateId)
  )

  const isLoadingData = useMemo(
    () => (!!recordId ? isLoadingRateRecords : isLoading),
    [isLoading, isLoadingRateRecords, recordId]
  )
  const isFetchingDate = useMemo(
    () => (!!recordId ? isFetchingRateRecords : isFetching),
    [isFetching, isFetchingRateRecords, recordId]
  )
  const isErrorData = useMemo(
    () => (!!recordId ? isErrorRateRecords : isError),
    [isError, isErrorRateRecords, recordId]
  )
  const refetchData = useMemo(
    () => (!!recordId ? refetchRateRecords : refetch),
    [recordId, refetch, refetchRateRecords]
  )

  const pageTitle = recordId ? 'Edit record' : 'New record'

  const rateType = useMemo(
    () => (!!recordId ? record?.table_rate?.from_to : rate?.from_to),
    [rate, record, recordId]
  )
  const rateCalculationMethod = useMemo(
    () => (!!recordId ? record?.table_rate?.calculation_method : rate?.calculation_method),
    [rate, record, recordId]
  )

  const rateName = useMemo(
    () => (!!recordId ? record?.table_rate?.name : rate?.name),
    [rate, record, recordId]
  )

  const defaultValues = useMemo(() => {
    const getPlacesValue = (key) => {
      if (!!recordId) {
        const ratePlace = getFilled(record?.table_rate, key, null)
        const recordPlace = getFilled(record, key, null)
        return recordPlace ? recordPlace : ratePlace
      } else {
        return getFilled(rate, key, null)
      }
    }

    return {
      description: getFilled(record, 'description', ''),
      departure_country: getPlacesValue('departure_country'),
      departure_hub: getPlacesValue('departure_hub'),
      departure_airport: getPlacesValue('departure_airport'),
      departure_port: getPlacesValue('departure_port'),
      departure_station: getPlacesValue('departure_station'),
      departure: !!recordId
        ? getFilled(record?.table_rate, 'departure', null)
        : getFilled(rate, 'departure', null),
      arrival_country: getPlacesValue('arrival_country'),
      arrival_hub: getPlacesValue('arrival_hub'),
      arrival_airport: getPlacesValue('arrival_airport'),
      arrival_port: getPlacesValue('arrival_port'),
      arrival_station: getPlacesValue('arrival_station'),
      arrival: !!recordId
        ? getFilled(record?.table_rate, 'arrival', null)
        : getFilled(rate, 'arrival', null),
      transit_time: getFilled(record, 'transit_time', ''),
      minimum: getFilled(record, 'minimum', ''),
      minimum_sell_price: getFilled(record, 'minimum_sell_price', ''),
      allow_calculate: getFilled(record, 'allow_calculate', true),
      arrival_zones: record?.zones?.filter((z) => !z.type || z.type === 'arrival') || [],
      departure_zones: record?.zones?.filter((z) => !z.type || z.type === 'departure') || [],

      steps: record?.brackets?.map((bracket) => ({
        ...bracket,
        from: getFilled(bracket, 'from', ''),
        to: getFilled(bracket, 'to', ''),
        transportation_unit_id: getFilled(bracket, 'transportation_unit_id', null),
        price: getFilled(bracket, 'price', ''),
        fix: getFilled(bracket, 'fix', ''),
        fix_more: getFilled(bracket, 'fix_more', ''),
        discount: getFilled(bracket, 'discount', ''),
        d_v_n: getFilled(bracket, 'd_v_n', null),
      })),
    }
  }, [rate, record, recordId])

  const onSubmit = (data, { setError }) => {
    if (!data.steps || data.steps.length === 0) {
      setError('unknown', { type: 'api', message: 'Add at least 1 step.' })
      return
    }

    let formData = { ...data, zones: [] }

    if ([1, 2, 8, 14].includes(rateType)) {
      formData.zones.push(...data?.departure_zones)
    }
    if ([0, 2, 9, 13].includes(rateType)) {
      formData.zones.push(...data?.arrival_zones)
    }

    delete formData.departure_zones
    delete formData.arrival_zones
    delete formData.arrival
    delete formData.departure

    recordCreationMutation.mutate(formData, {
      onError: (e) => {
        handleErrorOnSubmit(e, setError, formData)
        console.error(e)
      },
      onSuccess: (res) => {
        invalidate()
        showSuccessFlag(res?.message)
        navigate(`/panel/rates/${rateId}/records`)
      },
    })
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <Grid className="w-full">
        <Grid.Col md={8} xl={6} offsetMd={2} offsetXl={3}>
          <PageHeader
            breadcrumbs={
              <Breadcrumbs>
                <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
                <BreadcrumbsItem text="Sales machine" />
                <BreadcrumbsItem text="Rates" to="/panel/rates" component={Link} />
                <BreadcrumbsItem
                  text={isLoadingData ? <Spinner size="xsmall" /> : rateName}
                  to={`/panel/rates/${rateId}`}
                  component={Link}
                />
              </Breadcrumbs>
            }
          >
            {pageTitle}
          </PageHeader>

          {isLoadingData || isFetchingDate ? (
            <div className="text-center my-8">
              <Spinner />
            </div>
          ) : isErrorData ? (
            <Grid gutter={24} className="w-full">
              <Grid.Col md={6} offsetMd={3}>
                <SectionMessage
                  appearance="warning"
                  title="Something wrong on loading record data, please retry"
                >
                  <Button onClick={() => refetchData()}>Retry</Button>
                </SectionMessage>
              </Grid.Col>
            </Grid>
          ) : (
            <Form onSubmit={onSubmit} defaultValues={defaultValues}>
              {() => (
                <>
                  <RatesCreationRecordGeneralSection rateType={rateType} />

                  <RatesCreationRecordStepsSection
                    rateType={rateType}
                    rateCalculationMethod={rateCalculationMethod}
                  />

                  <WarningBannerField />

                  <div className="mt-6 text-right">
                    <LoadingButton
                      type="submit"
                      appearance="primary"
                      isLoading={recordCreationMutation?.isLoading}
                    >
                      {recordId ? 'Update' : 'Create'} record
                    </LoadingButton>
                  </div>
                </>
              )}
            </Form>
          )}
        </Grid.Col>
      </Grid>
    </>
  )
}

export default RateRecordsCreationPage
