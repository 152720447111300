import { useCallback, useMemo } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

// Hooks
import useConsolidation from 'hooks/useConsolidation'

// Contexts
import { useAuth } from 'contexts/AuthProvider'

// Atlassian
import PageHeader from '@atlaskit/page-header'
import Button, { ButtonGroup } from '@atlaskit/button'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'

// Interfaces
import { useRoyanTableLastFilters, useDisclosure, DotsIcon } from '@royan-co/user-interface'

// Components
import ConsolidationsDeleteModal from 'components/Consolidations/View/DeleteModal'
import { generateConsolidationUserPermissions } from 'components/Consolidations/Create/conslolidation-user-permissions'

// Features
import { useCanAccess } from 'features/auth'

const ConsolidationLayoutHeader = ({ title, actions }) => {
  const { currentUser } = useAuth()
  const { consolidationId } = useParams()

  const { consolidation } = useConsolidation()
  const { lastUrlSearch } = useRoyanTableLastFilters('consolidations-list')
  const userPermissions = generateConsolidationUserPermissions(consolidation, currentUser)

  const canDelete = useCanAccess({ resource: 'consolidations', action: 'delete', userPermissions })

  const [isOpenDeleteConsolidationModal, deleteConsolidationModalHandler] =
    useDisclosure('delete-consolidation')

  const openDeleteModal = useCallback(
    (consolidationId) => {
      deleteConsolidationModalHandler.open(consolidationId)
    },
    [deleteConsolidationModalHandler]
  )

  const breadcrumbs = useMemo(
    () => (
      <Breadcrumbs onExpand={() => {}}>
        <BreadcrumbsItem href="/panel" text="Panel" />
        <BreadcrumbsItem text="Operation" />
        <BreadcrumbsItem href="/panel/consolidations" text="Consolidations" />
        <BreadcrumbsItem text="View consolidation" />
      </Breadcrumbs>
    ),
    []
  )

  const actionsContent = useMemo(
    () => (
      <div className="flex flex-wrap justify-end gap-1">
        {actions}

        <Button
          appearance="default"
          component={Link}
          to={`/panel/consolidations${lastUrlSearch && `?${lastUrlSearch}`}`}
        >
          Back
        </Button>

        {canDelete && (
          <DropdownMenu
            placement="bottom-end"
            trigger={({ triggerRef, ...props }) => (
              <Button ref={triggerRef} appearance="default" children={<DotsIcon />} {...props} />
            )}
          >
            <DropdownItemGroup>
              <div style={{ minWidth: '130px' }}>
                {canDelete && (
                  <DropdownItem
                    children="Delete"
                    onClick={() => openDeleteModal(consolidationId)}
                  />
                )}
              </div>
            </DropdownItemGroup>
          </DropdownMenu>
        )}
      </div>
    ),
    [actions, canDelete, consolidationId, lastUrlSearch, openDeleteModal]
  )
  return (
    <>
      <Helmet>
        <title>Consolidation - {title}</title>
      </Helmet>

      <PageHeader breadcrumbs={breadcrumbs} actions={actionsContent}>
        {title}
      </PageHeader>

      <ConsolidationsDeleteModal
        isOpen={isOpenDeleteConsolidationModal}
        onClose={deleteConsolidationModalHandler.close}
        consolidationId={deleteConsolidationModalHandler.data}
      />
    </>
  )
}

export default ConsolidationLayoutHeader
