// Atlassian
import Heading from '@atlaskit/heading'

// Components
import { CardSubtitle } from 'components/UI/Card'
import TaggedLabel from './taggedLabel'
import CardDivider from './CardDivider'

const StatisticsCard = ({ tags, headingSubTitle, headingLabel, headingValue, onClick }) => {
  return (
    <div className="shadow rounded text-neutral-800 h-max">
      {headingSubTitle ? (
        <CardSubtitle className="m-0 p-4">{headingSubTitle}</CardSubtitle>
      ) : (
        <div className="p-4">
          <Heading level="h800">
            <span
              className={`!text-green-500 ${onClick ? 'cursor-pointer' : ''}`}
              onClick={onClick}
            >
              {headingValue}
            </span>
          </Heading>
          <p className="mt-1 !text-green-500">{headingLabel}</p>
        </div>
      )}

      <CardDivider />

      <div className="p-4 flex flex-col gap-2">
        {tags.map((tag) => (
          <TaggedLabel key={tag.label} {...tag} />
        ))}
      </div>
    </div>
  )
}

export default StatisticsCard
