import React, { useMemo, useState } from 'react'
import get from 'lodash/get'

// Atlassian
import Button from '@atlaskit/button'
import Spinner from '@atlaskit/spinner'
import SectionMessage from '@atlaskit/section-message'
import EmptyState from '@atlaskit/empty-state'
import { DynamicTableStateless } from '@atlaskit/dynamic-table'

// Components
import SearchInput from 'components/UI/SearchInput'
import SelectRows from './SelectRows'
import Export from './Export'

const LocalControlTable = ({
  type,
  params,
  exportParams,
  tableHead,
  onCreateRow,
  searchKeys,
  emptyState = '',
  query,
  exportMutation,
}) => {
  const [page, setPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [highlightedItem, setHighlightedItem] = useState()

  const { data, isLoading, isError, refetch } = query(type, params, {
    enabled: !!type,
  })

  const HighlightRow = (item) => {
    setHighlightedItem(item.id_auto)
  }

  const tableRows = useMemo(
    () =>
      data?.data
        ?.filter((item) =>
          searchKeys?.some?.((key) =>
            get(item, key)?.toLocaleLowerCase()?.includes(searchTerm.toLocaleLowerCase())
          )
        )
        ?.map((item) => ({
          isHighlighted: highlightedItem === item?.id_auto,
          cells: onCreateRow(item, HighlightRow),
        })),
    [data, highlightedItem, onCreateRow, searchKeys, searchTerm]
  )

  return (
    <>
      {isLoading ? (
        <div className="w-full text-center my-2">
          <Spinner />
        </div>
      ) : isError ? (
        <div className="my-2">
          <SectionMessage
            appearance="warning"
            title="Something wrong on loading data, please retry"
          >
            <Button onClick={() => refetch()}>Retry</Button>
          </SectionMessage>
        </div>
      ) : (
        <>
          <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-2 mt-2">
            <div className="flex gap-2 flex-wrap">
              <div style={{ maxWidth: '240px' }}>
                <SearchInput onSubmit={setSearchTerm} delay={50} isCompact />
              </div>

              <Export type={type} params={exportParams} mutation={exportMutation} />
            </div>

            <SelectRows
              value={rowsPerPage}
              onChange={({ value }) => {
                setRowsPerPage(value)
                setPage(1)
              }}
            />
          </div>

          <div className="overflow-auto inline-block w-full mt-6">
            <DynamicTableStateless
              head={tableHead}
              rows={tableRows}
              page={page}
              onSetPage={setPage}
              rowsPerPage={rowsPerPage}
              emptyView={<EmptyState header={emptyState} />}
            />
          </div>
        </>
      )}
    </>
  )
}

export default LocalControlTable
