import React, { Fragment, useMemo } from 'react'

import { getFilled } from 'utils/helpers'

//Atlassian
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import Button from '@atlaskit/button/standard-button'
import TextField from '@atlaskit/textfield'
import TextArea from '@atlaskit/textarea'
import LoadingButton from '@atlaskit/button/loading-button'
import { ErrorMessage } from '@atlaskit/form'

//Interfaces
import { Field, Form, WarningBannerField } from '@royan-co/user-interface'

const NoteModal = ({ isOpen, note, onClose, onSubmit, isLoading }) => {
  const isEditing = useMemo(() => note?.id !== undefined, [note])

  const defaultValues = useMemo(
    () => ({
      subject: getFilled(note, 'subject', ''),
      text: getFilled(note, 'text', ''),
    }),
    [note]
  )

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={onClose}>
          <Form onSubmit={onSubmit} defaultValues={defaultValues}>
            {() => (
              <>
                <ModalHeader>
                  <ModalTitle>{isEditing ? 'Edit note' : 'New note'}</ModalTitle>
                </ModalHeader>

                <ModalBody>
                  <Field name="subject" label={'Title'} isRequired>
                    {({ fieldProps, error }) => (
                      <Fragment>
                        <TextField
                          placeholder="Type the title"
                          autoComplete="off"
                          {...fieldProps}
                        />
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                      </Fragment>
                    )}
                  </Field>
                  <Field name="text" label={'Content'} isRequired>
                    {({ fieldProps, error }) => (
                      <Fragment>
                        <TextArea
                          placeholder="Type your content here"
                          {...fieldProps}
                          minimumRows={7}
                        />
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                      </Fragment>
                    )}
                  </Field>

                  <WarningBannerField />
                </ModalBody>

                <ModalFooter>
                  <Button onClick={onClose} appearance="subtle">
                    Cancel
                  </Button>
                  <LoadingButton isLoading={isLoading} type="submit" appearance="primary" autoFocus>
                    Submit
                  </LoadingButton>
                </ModalFooter>
              </>
            )}
          </Form>
        </Modal>
      )}
    </ModalTransition>
  )
}

export default NoteModal
