import { Link, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

// Apis
import { exportData } from 'api/exports'
import { getConsolidations, reportConsolidations } from 'api/consolidations'

// Contexts
import { useApp } from 'contexts/AppProvider'
import { useAuth } from 'contexts/AuthProvider'

// Atlassian
import PageHeader from '@atlaskit/page-header'
import Button, { ButtonGroup } from '@atlaskit/button'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'

// Interfaces
import { RoyanTable, useDisclosure } from '@royan-co/user-interface'

// Components
import { generateConsolidationsColumns } from 'components/Consolidations/List/columns'
import ConsolidationsFilterFields from 'components/Consolidations/List/FilterFields'
import { consolidationsFilters } from 'components/Consolidations/List/filters'
import ConsolidationsDeleteModal from 'components/Consolidations/View/DeleteModal'

const pageTitle = 'Consolidations'

const ConsolidationsListPage = () => {
  const navigate = useNavigate()
  const { setIsExportExcel } = useApp()
  const { currentUser, isWarehouseUser } = useAuth()

  const [isOpenDeleteConsolidationModal, deleteConsolidationModalHandler] =
    useDisclosure('delete-consolidation')

  const handleDoubleClick = (e, item) => {
    navigate(`/panel/consolidations/${item.id_auto}`)
  }

  const openDeleteModal = (consolidationId) => {
    deleteConsolidationModalHandler.open(consolidationId)
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <PageHeader
        breadcrumbs={
          <Breadcrumbs>
            <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
            <BreadcrumbsItem text="Operation" />
          </Breadcrumbs>
        }
        actions={
          <ButtonGroup>
            <Button appearance="primary" component={Link} to="/panel/consolidations/create">
              New Consolidation
            </Button>
          </ButtonGroup>
        }
      >
        {pageTitle}
      </PageHeader>

      <RoyanTable
        fixFirstColumn={true}
        sortOrder="DESC"
        name="consolidations-list"
        sortKey="creation_date"
        request={getConsolidations}
        filters={consolidationsFilters}
        onRowDoubleClick={handleDoubleClick}
        filterFields={<ConsolidationsFilterFields />}
        columns={generateConsolidationsColumns(currentUser, isWarehouseUser,openDeleteModal)}
        onExportSuccess={() => setIsExportExcel(true)}
        exports={[
          {
            title: 'Export as .xlsx',
            request: (filters, columns) => exportData({ table: 'consolidation', filters, columns }),
          },
          {
            title: 'Export financial balance',
            request: (filters, columns) => reportConsolidations({ filters }),
          },
        ]}
      >
        <ConsolidationsDeleteModal
          isOpen={isOpenDeleteConsolidationModal}
          onClose={deleteConsolidationModalHandler.close}
          consolidationId={deleteConsolidationModalHandler.data}
        />
      </RoyanTable>
    </>
  )
}

export default ConsolidationsListPage
