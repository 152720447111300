import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

// Apis
import { requestConsolidationTrackingLocalisation } from 'api/consolidations'

// Hooks
import useConsolidation from 'hooks/useConsolidation'

// Atlassian
import Button from '@atlaskit/button'
import Spinner from '@atlaskit/spinner'

// Interfaces
import { useFlags } from '@royan-co/user-interface'

// Components
import DisabledElementTooltip from 'components/UI/DisabledElementTooltip'

const ConsolidationTrackingRequestLocalisationAction = ({ appearance }) => {
  const [loading, setLoading] = useState(false)

  const { consolidationId } = useParams()
  const { showWarningFlag, showSuccessFlag } = useFlags()

  const { consolidation } = useConsolidation(consolidationId)

  const isDisabled = useMemo(() => !consolidation?.carrier_contacts?.length, [consolidation])

  const handleRequestLocalisation = async () => {
    if (isDisabled) return

    try {
      setLoading(true)
      const result = await requestConsolidationTrackingLocalisation(consolidationId)

      if (result?.success) {
        showSuccessFlag(result?.message || 'Request sent.')
      }
    } catch (e) {
      console.error(e)
      showWarningFlag('Something went wrong.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <DisabledElementTooltip
        isDisabled={isDisabled}
        tooltipContent={<span>Please add a contact for the carrier.</span>}
      >
        <Button
          onClick={handleRequestLocalisation}
          isDisabled={isDisabled}
          appearance={appearance}
          className="!w-full"
        >
          <div className="flex items-center justify-between">
            Request localisation
            {loading && (
              <span className="ml-2">
                <Spinner size="small" />
              </span>
            )}
          </div>
        </Button>
      </DisabledElementTooltip>
    </>
  )
}

export default ConsolidationTrackingRequestLocalisationAction
