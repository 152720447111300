import { useMemo } from 'react'

// Utils
import { findOption } from 'utils/helpers'
import { formatMonetaryNumber, formatNumber } from 'utils/numbers'

// Hooks
import useShipmentsEnumerations from 'hooks/useShipmentsEnumerations'

// Icons
import { EditIcon, TrashIcon, InfoIcon, DiscountCircleIcon } from '@royan-co/user-interface'

// Atlassian
import Button from '@atlaskit/button'
import Spinner from '@atlaskit/spinner'
import Lozenge from '@atlaskit/lozenge'
import Tooltip from '@atlaskit/tooltip'
import { DynamicTableStateless } from '@atlaskit/dynamic-table'

// Interfaces
import { useFieldArray, useHistoryModal } from '@royan-co/user-interface'

// Components
import Card from 'components/UI/Card'
import RateCostFormat from '../CostFormat'
import RatesStatusTag from '../StatusTag'
import StepDrawer from './StepDrawer'

const RatesCreationRecordStepsSection = ({ rateType, rateCalculationMethod }) => {
  const { getEnumerationOptionsByKey, isLoadingShipmentEnumeration } = useShipmentsEnumerations({
    enabled: rateCalculationMethod === 6,
  })

  const isContainer = [6, 7, 8, 9, 15, 16].includes(rateType) ? true : false

  const {
    modalData: StepsDrawerData,
    openModel: openStepsDrawer,
    closeModel: closeStepsDrawer,
    isOpenModel: isOpenStepsDrawer,
  } = useHistoryModal('steps')

  const {
    fields: stepsFields,
    append: appendStep,
    remove: removeStep,
    update: updateStep,
  } = useFieldArray({ name: 'steps', shouldUnregister: false, keyName: '_id' })

  const tableHead = useMemo(() => {
    let tableHeadItems = []

    if (rateCalculationMethod === 1 || rateCalculationMethod === 2)
      tableHeadItems.push({ content: 'From (Kgs)' }, { content: 'To (Kgs)' })

    if (rateCalculationMethod === 3)
      tableHeadItems.push({ content: 'From (EUR)' }, { content: 'To (EUR)' })

    if (rateCalculationMethod === 5)
      tableHeadItems.push({ content: 'From (Pkgs)' }, { content: 'To (Pkgs)' })

    if (rateCalculationMethod === 6) {
      if (isContainer) {
        tableHeadItems.push({ content: 'Container type' })
      } else {
        tableHeadItems.push({ content: 'Truck type' })
      }
    }

    tableHeadItems.push({ content: 'Rate' })

    if (rateCalculationMethod !== 6) tableHeadItems.push({ content: 'Fix' })

    tableHeadItems.push({ content: 'Valid cargo' }, { content: '' })

    return {
      cells: tableHeadItems,
    }
  }, [rateCalculationMethod, isContainer])

  const tableRows = useMemo(
    () =>
      stepsFields.map((bracket, i) => {
        let tableRowItems = []

        if ([1, 2, 3, 5].includes(rateCalculationMethod))
          tableRowItems.push(
            {
              content: bracket?.from ? (
                formatNumber(bracket?.from)
              ) : (
                <Lozenge appearance="default">Not set</Lozenge>
              ),
            },
            {
              content: bracket?.to ? (
                formatNumber(bracket?.to)
              ) : (
                <Lozenge appearance="default">Not set</Lozenge>
              ),
            }
          )

        if (rateCalculationMethod === 6) {
          tableRowItems.push({
            content: isLoadingShipmentEnumeration ? (
              <Spinner size="small" />
            ) : (
              findOption(
                getEnumerationOptionsByKey(isContainer ? 'container_types' : 'truck_types'),
                `${bracket?.transportation_unit_id}`
              )?.label
            ),
          })
        }

        tableRowItems.push({
          content: (
            <div className="inline-flex items-center">
              <RateCostFormat value={bracket?.price} />

              {bracket?.discount && (
                <Tooltip
                  content={
                    <>
                      <span>
                        Discount: {formatMonetaryNumber(bracket?.discount)}
                        {bracket?.discount_calculation_method === 0
                          ? '%'
                          : bracket?.discount_calculation_method === 1
                          ? ' Kgs'
                          : ''}
                      </span>
                    </>
                  }
                >
                  {(tooltipProps) => (
                    <div className="flex ml-1 text-neutral-500" {...tooltipProps}>
                      <DiscountCircleIcon size={18} />
                    </div>
                  )}
                </Tooltip>
              )}
            </div>
          ),
        })

        if (rateCalculationMethod !== 6)
          tableRowItems.push({
            content: (
              <div className="inline-flex items-center align-middle">
                <RatesStatusTag value={bracket?.fix} />

                {bracket?.fix_more && (
                  <Tooltip
                    content={
                      <>
                        <span>Fix more: {formatMonetaryNumber(bracket?.fix_more)}</span>
                      </>
                    }
                  >
                    {(tooltipProps) => (
                      <div className="flex items-end ml-1 text-neutral-500" {...tooltipProps}>
                        <InfoIcon size={18} />
                      </div>
                    )}
                  </Tooltip>
                )}
              </div>
            ),
          })

        tableRowItems.push(
          {
            content:
              bracket?.d_v_n === 0 ? (
                <Lozenge appearance="moved">Only Dense</Lozenge>
              ) : bracket?.d_v_n === 1 ? (
                <Lozenge appearance="moved">Only Voluminous</Lozenge>
              ) : bracket?.d_v_n === 2 ? (
                <Lozenge appearance="moved">Only normal</Lozenge>
              ) : (
                <Lozenge appearance="success">All</Lozenge>
              ),
          },
          {
            content: (
              <div className="flex justify-end">
                <Button
                  spacing="compact"
                  appearance="subtle"
                  iconBefore={<EditIcon />}
                  onClick={openStepsDrawer.bind(null, { ...bracket, index: i })}
                />
                <Button
                  spacing="compact"
                  appearance="subtle"
                  onClick={() => removeStep(i)}
                  iconBefore={<TrashIcon className="text-red-400" />}
                />
              </div>
            ),
          }
        )

        return {
          key: bracket._id,
          cells: tableRowItems,
        }
      }),
    [
      getEnumerationOptionsByKey,
      isLoadingShipmentEnumeration,
      openStepsDrawer,
      rateCalculationMethod,
      isContainer,
      removeStep,
      stepsFields,
    ]
  )

  const onSubmit = (data) => {
    data?.index !== undefined
      ? updateStep(data.index, data)
      : appendStep({
          ...data,
          fix: data?.fix || 0,
        })
    closeStepsDrawer()
  }

  return (
    <div className="mt-6">
      <div className="flex items-center">
        <h3>Steps</h3>

        <Button appearance="link" className="ml-auto" onClick={() => openStepsDrawer()}>
          New step
        </Button>
      </div>

      <Card className="mt-2">
        {tableRows.length === 0 ? (
          <div className="text-xs text-neutral-200">Click on New step to create one.</div>
        ) : (
          <div className="inline-block w-full -mb-6">
            <DynamicTableStateless head={tableHead} rows={tableRows} />
          </div>
        )}
      </Card>

      <StepDrawer
        isOpen={isOpenStepsDrawer}
        onClose={closeStepsDrawer}
        onSubmit={onSubmit}
        data={StepsDrawerData}
        calculationMethod={rateCalculationMethod}
        isContainer={isContainer}
      />
    </div>
  )
}

export default RatesCreationRecordStepsSection
