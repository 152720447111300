import { findOption } from 'utils/helpers'
import { shipmentInvoicedOptions } from './constants'

//Atlassian
import Lozenge from '@atlaskit/lozenge'

const ShipmentInvoicedTag = ({ value }) => {
  const option = findOption(shipmentInvoicedOptions, value)
  return option ? <Lozenge appearance={option.appearance}>{option.label}</Lozenge> : null
}

export default ShipmentInvoicedTag
