import { useCallback, useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, Outlet, useParams } from 'react-router-dom'

// Utils
import { getFilled } from 'utils/helpers'

// Hooks
import useConsolidation from 'hooks/useConsolidation'

// Contexts
import { ConsolidationCreationProvider } from 'contexts/ConsolidationCreationProvider'

// Atlassian
import Button from '@atlaskit/button'
import Spinner from '@atlaskit/spinner'
import PageHeader from '@atlaskit/page-header'
import SectionMessage from '@atlaskit/section-message'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'

// Interfaces
import { Grid, WizardFormProvider, useStateList } from '@royan-co/user-interface'

// Components
import ConsolidationCreationProgressTracker from 'components/Consolidations/Create/ProgressTracker'
import { modes } from 'components/Consolidations/Create/modes'
import { temperatureControlOptions } from 'components/Shipments/Create/constants'

const ConsolidationCreationPage = () => {
  const { consolidationId } = useParams()
  const pageTitle = consolidationId ? 'Edit consolidation' : 'New consolidation'

  const [selectedShipments, dispatchSelectedShipments] = useStateList([], { key: 'id_auto' })

  const { consolidation, isLoading, isFetching, isError, refetch } = useConsolidation(
    consolidationId,
    {
      enabled: !!consolidationId,
    }
  )

  const selectedReeferOption = useCallback((defaultRange) => {
    if (!defaultRange) return
    let [min, max] = defaultRange

    const selected = temperatureControlOptions.find((o) => {
      return o.value.range.min === min * 1 && o.value.range.max === max * 1
    })
    return selected || temperatureControlOptions.find((o) => o.value.type === 'custom')
  }, [])

  const dedicatedMode = modes?.find((m) => m.id === 2)

  const getSelectedModeId = useCallback(
    (typeOfCollies, shipmentType) => {
      let mode = modes.find(
        (m) => m?.shipmentType === shipmentType && m?.typeOfCollies === typeOfCollies
      )

      if (mode?.id) return mode?.id
      else if (typeOfCollies === 1 && [0, 1].includes(shipmentType)) return dedicatedMode.id
      return null
    },
    [dedicatedMode.id]
  )

  const defaultStepsValue = useMemo(() => {
    const stepsValue = {
      general: {
        modeType: consolidationId
          ? getSelectedModeId(consolidation?.type_of_collies, consolidation?.shipment_type)
          : 1,
        vehicle_type:
          getSelectedModeId(consolidation?.type_of_collies, consolidation?.shipment_type) === 2
            ? consolidation?.shipment_type
            : null,

        hub_consolidator: getFilled(consolidation, 'hub_consolidator', null),
        arrival_country: getFilled(consolidation, 'arrival_country', null),
        reefer: getFilled(consolidation, 'reefer', 0),
        range: selectedReeferOption(consolidation?.reefer_temperature?.split(',')) || '',
        reefer_temperature: consolidation?.reefer_temperature?.split(','),
      },
      agents: {
        carrier: consolidation?.carrier_data
          ? {
              value: consolidation?.carrier_data?.id_auto,
              imported: consolidation?.carrier_data?.arca_imported,
              label: consolidation?.carrier_data?.company_name,
            }
          : null,
        carrier_contact_id: getFilled(consolidation, 'carrier_contact_id', null),
        means: consolidation?.means?.map((mean) => ({ label: mean?.code, value: mean?.id_auto })),
        tracking_mail: getFilled(consolidation, 'tracking_mail', 0),
        agreed_cost: getFilled(consolidation, 'agreed_cost', ''),
        mail_tracking_date: getFilled(consolidation, 'mail_tracking_date', ''),
        agent_destination_id: consolidation?.receiving_agent
          ? {
              value: consolidation?.receiving_agent?.id_auto,
              imported: consolidation?.receiving_agent?.arca_imported,
              label: consolidation?.receiving_agent?.company_name,
            }
          : null,
        agent_destination_contact_id: getFilled(
          consolidation,
          'agent_destination_contact_id',
          null
        ),
      },
      shipments: {
        shipments: getFilled(consolidation, 'shipments', null),
      },
    }

    return stepsValue
  }, [consolidation, consolidationId, getSelectedModeId, selectedReeferOption])

  return (
    <>
      <ConsolidationCreationProvider>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>

        <Grid gutter={24} className="w-full">
          <Grid.Col md={10} lg={10} offsetMd={1} offsetLg={1}>
            <PageHeader
              breadcrumbs={
                <Breadcrumbs>
                  <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
                  <BreadcrumbsItem
                    text="Consolidations"
                    to="/panel/consolidations"
                    component={Link}
                  />
                  {consolidationId && (
                    <BreadcrumbsItem
                      text={
                        isLoading ? <Spinner size="xsmall" /> : consolidation?.consolidation_code
                      }
                      to={`/panel/consolidations/${consolidationId}`}
                      component={Link}
                    />
                  )}
                </Breadcrumbs>
              }
            >
              {pageTitle}
            </PageHeader>

            <ConsolidationCreationProgressTracker />

            <div className="my-8">
              {!!consolidationId && (isLoading || isFetching) ? (
                <div className="text-center my-8">
                  <Spinner />
                </div>
              ) : isError ? (
                <Grid gutter={24} className="w-full">
                  <Grid.Col md={6} offsetMd={3}>
                    <SectionMessage
                      appearance="warning"
                      title={`Something wrong on loading consolidation data, please retry`}
                    >
                      <Button onClick={() => refetch()}>Retry</Button>
                    </SectionMessage>
                  </Grid.Col>
                </Grid>
              ) : (
                <WizardFormProvider defaultStepsValue={defaultStepsValue}>
                  <Outlet context={[selectedShipments, dispatchSelectedShipments]} />
                </WizardFormProvider>
              )}
            </div>
          </Grid.Col>
        </Grid>
      </ConsolidationCreationProvider>
    </>
  )
}

export default ConsolidationCreationPage
