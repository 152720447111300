import React, { useMemo } from 'react'

// Hooks
import { useDeactivateUser } from '../api/useDeactivate'

// Utils
import { capitalizeFirstLetter } from 'utils/helpers'

//Atlassian
import Modal, {
  ModalBody,
  ModalTitle,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import Button, { LoadingButton } from '@atlaskit/button'

//Interfaces
import { WarningBanner } from '@royan-co/user-interface'

const UserDeactivateModal = ({ isOpen, onClose, user }) => {
  const deactivateMutation = useDeactivateUser(user?.auto_id)
  const status = useMemo(() => (!user?.active ? 'activate' : 'deactivate'), [user])

  const closeModal = () => {
    onClose()
    deactivateMutation.reset()
  }

  const deleteDocument = () => {
    deactivateMutation.mutate(!user?.active, {
      onError: (e) => {
        console.error(e)
      },
      onSuccess: () => {
        onClose()
      },
    })
  }

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={closeModal}>
          <ModalHeader>
            <ModalTitle appearance="warning">{capitalizeFirstLetter(status)} user</ModalTitle>
          </ModalHeader>
          <ModalBody>
            You are about to {status} a user. Are you sure?
            {deactivateMutation.isError && <WarningBanner />}
          </ModalBody>
          <ModalFooter>
            <Button appearance="subtle" onClick={closeModal}>
              Cancel
            </Button>
            <LoadingButton
              isLoading={deactivateMutation.isLoading}
              appearance="warning"
              onClick={deleteDocument}
              autoFocus
            >
              Confirm
            </LoadingButton>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  )
}

export default UserDeactivateModal
