import { useParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'

import { getRegistryContactWhatsapp } from 'api/registries'

// Atlassian
import Button from '@atlaskit/button'

import Modal, {
  ModalBody,
  ModalTitle,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from '@atlaskit/modal-dialog'

// Interfaces
import { RoyanTable, useDisclosure } from '@royan-co/user-interface'

// Components
import Card from 'components/UI/Card'
import Markdown from 'components/UI/Markdown'
import { generateContactWhatsappHistoryColumns } from './whatsapp-columns'

const RegistryContactWhatsappPage = () => {
  const { contactId } = useParams()
  const queryClient = useQueryClient()
  const [isOpenMessageDetails, messageDetailsHandlers] = useDisclosure()

  const request = (params) => {
    return queryClient.fetchQuery({
      staleTime: Infinity,
      queryKey: ['contact-whatsapp', contactId, params],
      queryFn: () => getRegistryContactWhatsapp(contactId, params),
    })
  }

  return (
    <>
      <Card className="mt-0">
        <RoyanTable
          filters={[]}
          request={request}
          searchable={false}
          columnsControl={false}
          name="registry-contact-whatsapp-history"
          columns={generateContactWhatsappHistoryColumns({
            onOpenDetails: messageDetailsHandlers.open,
          })}
        />
      </Card>

      <ModalTransition>
        {isOpenMessageDetails && (
          <Modal onClose={() => messageDetailsHandlers.close()} width="medium">
            <ModalHeader>
              <ModalTitle>Message content</ModalTitle>
            </ModalHeader>

            <ModalBody>
              <Markdown source={messageDetailsHandlers.data?.content} />
            </ModalBody>

            <ModalFooter>
              <Button appearance="subtle" onClick={() => messageDetailsHandlers.close()}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </>
  )
}

export default RegistryContactWhatsappPage
