import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'

// APIS
import { uploadDocument } from 'api/shipments'

// Hooks
import useShipment from 'hooks/useShipment'

// Atlassian
import Button from '@atlaskit/button'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'

// Interfaces
import { PlusIcon, UploadModal, useDisclosure, useFlags } from '@royan-co/user-interface'

// Features
import { CreateShipmentDocumentModal } from 'features/documents'

const fileTitleOptions = [
  {
    label: 'SHIPPER COMMERCIAL INVOICE',
    value: 'COMMERCIAL INVOICE',
  },
  {
    label: 'INVOICE FOR DESTINATION',
    value: 'INVOICE FOR DESTINATION',
  },
  {
    label: 'PACKING LIST FOR DESTINATION',
    value: 'PACKING LIST FOR DESTINATION',
  },
  {
    label: 'T1',
    value: 'T1',
  },
  {
    label: 'T2',
    value: 'T2',
  },
  {
    label: 'POWER OF ATTORNEY',
    value: 'POWER OF ATTORNEY',
  },
  {
    label: 'FREE EXPORT DECLARATION',
    value: 'FREE EXPORT DECLARATION',
  },
  {
    label: 'PACKING LIST',
    value: 'PACKING LIST',
  },
  {
    label: 'CERTIFICATIONS',
    value: 'CERTIFICATIONS',
  },
  {
    label: 'EX1',
    value: 'EXPORT CUSTOMS DECLARATION',
  },
  {
    label: 'IMPORT CUSTOMS DECLARATION',
    value: 'IMPORT CUSTOMS DECLARATION',
  },
  {
    label: 'CMR',
    value: 'CMR',
  },
  {
    label: 'ISSUING BL',
    value: 'BILL OF LADING',
  },
  {
    label: 'TRUE BILL OF LADING',
    value: 'TRUE BILL OF LADING',
  },
  {
    label: 'FIATA BILL OF LADING',
    value: 'FIATA BILL OF LADING',
  },
  {
    label: 'HOUSE BILL OF LADING',
    value: 'HOUSE BILL OF LADING',
  },
  {
    label: 'FCR',
    value: 'FCR',
  },
  {
    label: 'HAWB',
    value: 'HAWB',
  },
  {
    label: 'MAWB',
    value: 'MAWB',
  },
  {
    label: 'ENTRANCE IMAGE',
    value: 'ENTRANCE IMAGE',
  },
  {
    label: 'ATR-EUR1',
    value: 'ATR-EUR1',
  },
  {
    label: 'SHIPMENT FINAL MSG',
    value: 'SHIPMENT FINAL MSG',
  },
  {
    label: 'OTHER',
    value: 'other',
  },
]

const ShipmentUploadDocument = () => {
  const { shipmentId, quotationId } = useParams()
  const entityId = shipmentId || quotationId
  const { shipment } = useShipment()
  const { showSuccessFlag } = useFlags()
  const queryClient = useQueryClient()
  const [isOpenUploadModal, uploadModalHandlers] = useDisclosure()
  const [isOpenCreateModal, createModalHandlers] = useDisclosure('create-document')

  const isMasterShipment = useMemo(() => shipment?.is_master, [shipment])

  const uploadMutation = useMutation((formData) => uploadDocument(formData, entityId))

  const upload = (files) => {
    let file = files?.[0]

    if (!file) return

    const formData = new FormData()
    formData.append('title', file.title)
    formData.append('document', file)
    formData.append('flag_status', 2)
    formData.append('tracking_share', 1)

    uploadMutation.mutate(formData, {
      onSuccess: (res) => {
        queryClient.setQueryData(['shipment', 'documents', entityId], (docs) => [
          res.document,
          ...docs,
        ])
        showSuccessFlag(res.message)
        uploadModalHandlers.close()
      },
    })
  }

  return (
    <>
      {shipmentId ? (
        <DropdownMenu
          placement="bottom-end"
          trigger={({ triggerRef, ...props }) => (
            <Button
              ref={triggerRef}
              {...props}
              appearance="primary"
              iconBefore={<PlusIcon size={24} />}
              children={'New Document'}
            />
          )}
        >
          <DropdownItemGroup>
            <div style={{ minWidth: '100px' }}>
              <DropdownItem onClick={() => uploadModalHandlers.open()}>Upload</DropdownItem>
              <DropdownItem onClick={() => createModalHandlers.open()}>Create</DropdownItem>
            </div>
          </DropdownItemGroup>
        </DropdownMenu>
      ) : (
        <Button
          appearance="primary"
          children={'Upload'}
          onClick={() => uploadModalHandlers.open()}
        />
      )}

      <UploadModal
        maxFiles={1}
        onSubmit={upload}
        editableFileTitle
        isFileTitleRequired
        title="Upload document"
        isOpen={isOpenUploadModal}
        fileTitleOptions={fileTitleOptions}
        isSubmitting={uploadMutation.isLoading}
        accept={['csv', 'xls', 'pdf', 'jpg', 'jpeg', 'png']}
        onClose={() => {
          uploadMutation.reset()
          uploadModalHandlers.close()
        }}
        error={
          uploadMutation.isError && (uploadMutation.error?.response?.data?.errors?.document || true)
        }
      />

      <CreateShipmentDocumentModal
        isOpen={isOpenCreateModal}
        onClose={createModalHandlers.close}
        isMasterShipment={isMasterShipment}
        shipmentId={shipmentId}
        shipmentType={shipment?.shipment_type}
      />
    </>
  )
}

export default ShipmentUploadDocument
