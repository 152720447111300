import React, { useMemo, useState } from 'react'
import { GoogleMap, Polyline, Marker, InfoWindow } from '@react-google-maps/api'

//Hooks
import useOnloadMap from 'hooks/useOnloadMap'

//Atlassian
import { N200 } from '@atlaskit/theme/colors'

const PolylineOptions = {
  strokeColor: '#5AFF3E',
  strokeWeight: 4,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  zIndex: 1,
}

const polylineOptions = {
  strokeOpacity: 0,
  strokeColor: '#7A869A',
  strokeWeight: 3,
  icons: [
    {
      icon: {
        path: 'M 0,-1 0,1',
        strokeOpacity: 1,
        scale: 4,
      },
      offset: '0',
      repeat: '20px',
    },
  ],
}

const containerStyle = {
  width: '100%',
  height: '100%',
}

const pinSize = 30
const flagPinIcon = '/assets/images/shipmentIcons/Flag.png'
const defaultPinIcon = '/assets/images/shipmentIcons/pin.png'
const departurePinIcon = '/assets/images/shipmentIcons/departure.png'

const modeIcons = {
  0: '/assets/images/shipmentIcons/Road.png',
  1: '/assets/images/shipmentIcons/Sea.png',
  2: '/assets/images/shipmentIcons/Air.png',
}

const RoutingMap = ({
  showAirportsLine,
  pins,
  entityType,
  entityStatus,
  departure,
  arrival,
  arrivalStatus,
  arrivalAirport,
}) => {
  const [infoWindowData, setInfoWindowData] = useState(null)

  const path = useMemo(() => {
    const arrived = entityStatus === arrivalStatus && !!arrival?.lat && !!arrival?.lng

    const allPins = pins.map((p, i) => ({
      icon: i === 0 && !arrived ? modeIcons[entityType] : undefined,
      lat: p.lat * 1,
      lng: p.lng * 1,
      info: (
        <div>
          <div style={{ fontWeight: 500 }}>{p.address}</div>
          {p.reefer_temperature && (
            <div style={{ color: N200, fontSize: '.875em', marginTop: '0.25em' }}>
              Temperature: {p.reefer_temperature} °C
            </div>
          )}
          {p.creation_date && (
            <div style={{ color: N200, fontSize: '.875em', marginTop: '0.25em' }}>
              Recorded at {p.creation_date}
            </div>
          )}
        </div>
      ),
    }))

    if (departure?.lat && departure?.lng) {
      allPins.push({
        icon: departurePinIcon,
        lat: departure?.lat,
        lng: departure?.lng,
        info: (
          <div style={{ fontWeight: 500 }}>
            {[departure?.cap, departure?.city, departure?.country]?.filter((a) => a)?.join(', ')}
          </div>
        ),
      })
    }

    if (arrived) {
      allPins.unshift({
        icon: flagPinIcon,
        lat: arrival?.lat,
        lng: arrival?.lng,
        info: (
          <div style={{ fontWeight: 500 }}>
            {[arrival?.cap, arrival?.city, arrival?.country]?.filter((a) => a)?.join(', ')}
          </div>
        ),
      })
    }

    return allPins
  }, [pins, arrival, departure, arrivalStatus, entityType, entityStatus])

  const airportPath = useMemo(
    () =>
      showAirportsLine && arrivalAirport
        ? [path[0], { ...arrivalAirport, icon: defaultPinIcon }]
        : [],
    [arrivalAirport, path, showAirportsLine]
  )

  const { onLoad } = useOnloadMap(path.concat(airportPath))

  const handleInfoWindow = (pin) => {
    if (pin.info) {
      setInfoWindowData({
        center: { lat: pin.lat, lng: pin.lng },
        content: pin.info,
      })
    }
  }

  return (
    <div style={{ height: '100%' }} className="routing-map">
      <GoogleMap
        zoom={2}
        onLoad={onLoad}
        id="routing-map"
        mapContainerStyle={containerStyle}
        options={{ mapTypeControl: false, streetViewControl: false }}
      >
        {path && (
          <>
            {showAirportsLine && (
              <>
                <Polyline path={airportPath} options={polylineOptions} />

                <Marker
                  icon={{
                    url: defaultPinIcon,
                    scaledSize: new window.google.maps.Size(pinSize, pinSize),
                  }}
                  position={{ lat: airportPath?.[1]?.lat, lng: airportPath?.[1]?.lng }}
                />
              </>
            )}

            <Polyline path={path} options={PolylineOptions} />

            {path.map((pin, i) => (
              <Marker
                key={i}
                title={pin.reefer_temperature}
                icon={{
                  url: pin.icon || defaultPinIcon,
                  scaledSize: new window.google.maps.Size(pinSize, pinSize),
                }}
                position={{ lat: pin.lat, lng: pin.lng }}
                onMouseOver={() => handleInfoWindow(pin)}
                onMouseOut={() => setInfoWindowData(null)}
              />
            ))}

            {infoWindowData && (
              <InfoWindow
                options={{ pixelOffset: new window.google.maps.Size(0, -pinSize) }}
                position={infoWindowData.center}
                onCloseClick={() => setInfoWindowData(null)}
              >
                {infoWindowData.content}
              </InfoWindow>
            )}
          </>
        )}
      </GoogleMap>
    </div>
  )
}

export default RoutingMap
