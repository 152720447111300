import React, { useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

//Hooks
import useShipment from 'hooks/useShipment'

//Atlassian
import Spinner from '@atlaskit/spinner'
import PageHeader from '@atlaskit/page-header'
import SectionMessage from '@atlaskit/section-message'
import Button from '@atlaskit/button'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'

//Interfaces
import { Grid } from '@royan-co/user-interface'

//Components
import ShipmentComposingEmail from './ComposingEmail'

const ShipmentViewAirFreightBookingPage = () => {
  const { shipmentId } = useParams()
  const navigate = useNavigate()
  const { shipment, refetch, isError, isLoading, isFetching } = useShipment()

  useEffect(() => {
    if (shipment?.id_auto && shipment?.shipment_type !== 2)
      navigate(`/panel/shipments/${shipmentId}/actions`)
  }, [navigate, shipment, shipmentId])

  return (
    <>
      <Helmet>
        <title>Air freight booking</title>
      </Helmet>

      <Grid gutter={24} className="w-full">
        <Grid.Col md={9} lg={6} offsetMd={3}>
          <PageHeader
            breadcrumbs={
              <Breadcrumbs>
                <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
                <BreadcrumbsItem text="Shipments" to="/panel/shipments" component={Link} />
                <BreadcrumbsItem
                  text="View shipment"
                  to={`/panel/shipments/${shipmentId}`}
                  component={Link}
                />
                <BreadcrumbsItem
                  text={isLoading ? <Spinner size="xsmall" /> : shipment?.shipment_code}
                  to={`/panel/shipments/${shipmentId}`}
                  component={Link}
                />
              </Breadcrumbs>
            }
          >
            Air freight booking
          </PageHeader>
        </Grid.Col>
      </Grid>

      {isLoading || isFetching ? (
        <div className="text-center my-8">
          <Spinner />
        </div>
      ) : isError ? (
        <Grid gutter={24} className="w-full">
          <Grid.Col md={6} offsetMd={3}>
            <SectionMessage
              appearance="warning"
              title="Something wrong on loading shipment details, please retry"
            >
              <Button onClick={() => refetch()}>Retry</Button>
            </SectionMessage>
          </Grid.Col>
        </Grid>
      ) : (
        <Grid className="w-full">
          <Grid.Col md={6} offsetMd={3}>
            <ShipmentComposingEmail
              shipmentId={shipmentId}
              originTemplate={'freight-booking'}
              onSuccess={() => navigate(`/panel/shipments/${shipmentId}/actions`)}
              onCancel={() => navigate(`/panel/shipments/${shipmentId}/actions`)}
            />
          </Grid.Col>
        </Grid>
      )}
    </>
  )
}

export default ShipmentViewAirFreightBookingPage
