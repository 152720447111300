import { useMemo } from 'react'

// Utils
import { transformSelectField } from 'utils/helpers'
import { nodeTypes } from './nodeTypes'
import { handleErrorOnSubmit } from 'utils/errors'

// Atlassian
import Button from '@atlaskit/button'
import Select from '@atlaskit/select'
import Modal, {
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import { ErrorMessage } from '@atlaskit/form'

// Interfaces
import { Field, Form, WarningBannerField } from '@royan-co/user-interface'
import { legTypes } from './legTypes'

const EditFinanceLegModal = ({ isOpen, onClose, leg, completeLeg, onSubmit }) => {
  const isEditing = useMemo(() => leg?.index !== undefined, [leg?.index])
  const options = useMemo(() => (completeLeg ? legTypes : nodeTypes), [completeLeg])

  const handleCloseModal = () => {
    onClose()
  }

  const handleEntity = (data, { setError }) => {
    try {
      onSubmit(isEditing ? { ...data, ...leg } : data)
    } catch (error) {
      handleErrorOnSubmit(error, setError, data)
    }
  }

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={handleCloseModal} width="medium">
          <ModalHeader>
            <ModalTitle>{!isEditing ? 'New leg' : 'Edit'}</ModalTitle>
          </ModalHeader>

          <Form onSubmit={handleEntity}>
            {() => (
              <>
                <div className="px-6">
                  <Field
                    isRequired
                    label="Type"
                    name={completeLeg ? 'type' : 'node'}
                    transform={transformSelectField(options)}
                    className="!mt-0"
                  >
                    {({ fieldProps, error }) => (
                      <>
                        <Select options={options} {...fieldProps} />
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                      </>
                    )}
                  </Field>

                  <WarningBannerField />
                </div>

                <ModalFooter>
                  <Button type="button" appearance="subtle" onClick={handleCloseModal}>
                    Cancel
                  </Button>
                  <Button type="submit" appearance="primary">
                    {!isEditing ? 'Add' : 'Update'}
                  </Button>
                </ModalFooter>
              </>
            )}
          </Form>
        </Modal>
      )}
    </ModalTransition>
  )
}

export default EditFinanceLegModal
