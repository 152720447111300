import React from 'react'

// Hooks
import { useDeleteBracket } from './../../api/useDeleteBracket'

// Atlassian
import Modal, {
  ModalBody,
  ModalTitle,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import Button, { LoadingButton } from '@atlaskit/button'

// Interfaces
import { useFlags, WarningBanner } from '@royan-co/user-interface'

const DeleteBracketModal = ({ isOpen, onClose, bracketId, marginId }) => {
  const { showSuccessFlag } = useFlags()

  const bracketDeleteMutation = useDeleteBracket(marginId)

  const closeModal = () => {
    onClose()
    bracketDeleteMutation.reset()
  }

  const removeBracket = () => {
    bracketDeleteMutation.mutate(bracketId, {
      onSuccess: (res) => {
        showSuccessFlag(res.message)
        onClose()
      },
    })
  }

  return (
    <>
      <ModalTransition>
        {isOpen && (
          <Modal onClose={closeModal}>
            <ModalHeader>
              <ModalTitle appearance="danger">Delete bracket</ModalTitle>
            </ModalHeader>
            <ModalBody>
              You are about to delete a bracket. Are you sure?
              {bracketDeleteMutation.isError && <WarningBanner />}
            </ModalBody>
            <ModalFooter>
              <Button appearance="subtle" onClick={closeModal}>
                Cancel
              </Button>
              <LoadingButton
                isLoading={bracketDeleteMutation.isLoading}
                appearance="danger"
                onClick={removeBracket}
                autoFocus
              >
                Delete
              </LoadingButton>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </>
  )
}

export default DeleteBracketModal
