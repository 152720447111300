import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

// Apis
import { useImportToARCARegistry } from '../api/importToARCARegistry'

// Contexts
import { useAuth } from 'contexts/AuthProvider'

// Components
import ActionDropdownItem from 'components/ActionDropdownItem'

const ImportToARCAAction = ({ registry }) => {
  const { currentUser } = useAuth()
  const { creatorId } = useParams()

  const type = useMemo(() => {
    return registry?.owners?.[0]?.type === 0
      ? 'client'
      : registry?.owners?.[0]?.type === 1
      ? 'supplier'
      : ''
  }, [registry])

  const importARCARegistryMutation = useImportToARCARegistry({
    registryId: registry?.id_auto,
    owner: creatorId || registry?.owners?.[0]?.user?.auto_id,
    type,
  })

  const accessabilityImportARCA = useMemo(
    () =>
      ['master', 'admin', 'accounting'].includes(currentUser?.user_level_name?.name) &&
      !registry?.arca_imported
        ? true
        : false,
    [currentUser, registry]
  )

  return accessabilityImportARCA ? (
    <ActionDropdownItem
      label="Send to ARCA"
      mutation={importARCARegistryMutation}
      isDisabled={!accessabilityImportARCA || !type}
    />
  ) : null
}

export default ImportToARCAAction
