import { getConsolidationViewPages } from './pages'
import { useQueryClient } from '@tanstack/react-query'
import { createElement, useEffect, useMemo } from 'react'
import { matchRoutes, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'

//Atlassian
import Button from '@atlaskit/button'
import Spinner from '@atlaskit/spinner'
import SectionMessage from '@atlaskit/section-message'

//Interfaces
import { useFlags } from '@royan-co/user-interface'

//Components
import RequireAuth from '../RequireAuth'
import AppShell from 'components/UI/AppShell'
import TopNavigation from 'layouts/TopNavigation'
import ConsolidationLayoutSidebar from './Sidebar'
import ConsolidationLayoutHeader from './Header'
import useConsolidation from 'hooks/useConsolidation'

const ConsolidationLayout = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { consolidationId } = useParams()
  const { showWarningFlag } = useFlags()

  const { consolidation, isLoading, isFetching, isError, error, refetch } =
    useConsolidation(consolidationId)

  const pages = useMemo(
    () => getConsolidationViewPages(consolidationId, consolidation),
    [consolidationId, consolidation]
  )

  const filteredPages = useMemo(() => pages.filter((p) => p.show), [pages])

  const [{ route: currentPage }] = useMemo(() => matchRoutes(pages, location), [pages, location])

  useEffect(() => {
    if (error?.response?.status === 404) {
      queryClient.cancelQueries({ queryKey: ['consolidation', consolidationId], exact: false })
      showWarningFlag('Consolidation is not found!')
      navigate('/panel/consolidations', { replace: true })
    }
  }, [consolidationId, error, navigate, queryClient, showWarningFlag])

  return (
    <RequireAuth>
      <AppShell
        renderTopNavigation={(props) => <TopNavigation {...props} />}
        leftSidebar={<ConsolidationLayoutSidebar pages={filteredPages} />}
      >
        <div className="mx-6 md:mx-8 my-6">
          <ConsolidationLayoutHeader
            title={currentPage?.title}
            actions={createElement(currentPage?.actions || (() => ''))}
          />

          {isLoading || isFetching ? (
            <div className="text-center mt-24">
              <Spinner />
            </div>
          ) : isError ? (
            <SectionMessage
              appearance="warning"
              title="Something wrong on loading data, please retry."
            >
              <Button onClick={() => refetch()}>Retry</Button>
            </SectionMessage>
          ) : (
            <Outlet />
          )}
        </div>
      </AppShell>
    </RequireAuth>
  )
}

export default ConsolidationLayout
