import { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, useQueryClient } from '@tanstack/react-query'

//Apis
import { getShipment } from 'api/shipments'

//Contexts
import { useAuth } from 'contexts/AuthProvider'

const useShipment = (shipmentId, { enabled = true } = {}) => {
  const { currentUser } = useAuth()
  const queryClient = useQueryClient()
  const { shipmentId: shipmentIdInUrl, quotationId } = useParams()

  const _shipmentId = shipmentId || shipmentIdInUrl || quotationId

  if (enabled && !_shipmentId) {
    throw new Error('useShipment: shipment id is required')
  }

  const {
    error,
    refetch,
    isError,
    isLoading,
    isFetching,
    data: shipment,
  } = useQuery({
    queryKey: ['shipment', _shipmentId],
    queryFn: () => getShipment(_shipmentId),
    enabled,
    staleTime: Infinity,
    // placeholderData: placeholderData || {},
  })

  const isCreator = useMemo(
    () =>
      currentUser?.registry_id === shipment?.creator?.registry_id &&
      currentUser?.registry_address_id === shipment?.creator?.registry_address_id,
    [shipment, currentUser]
  )

  const setLimitationExceptCreator = useCallback(
    (limitationLevel) => {
      if (isLoading) return null

      if (isCreator) return null
      else return limitationLevel
    },
    [isLoading, isCreator]
  )

  const deleteTrackingContactFromData = (deletedId) => {
    queryClient.setQueryData(['shipment', _shipmentId], (oldShipment) => ({
      ...oldShipment,
      tracking_contacts: oldShipment.tracking_contacts.filter((c) => c.id !== deletedId),
    }))
  }

  const upsertTrackingContactToData = (contact) => {
    const isExist = (shipment?.tracking_contacts || []).some((n) => n.id === contact.id)

    queryClient.setQueryData(['shipment', _shipmentId], (oldShipment = {}) => ({
      ...oldShipment,
      tracking_contacts: isExist
        ? (oldShipment.tracking_contacts || []).map((c) => (c.id === contact.id ? contact : c))
        : [...(oldShipment.tracking_contacts || []), contact],
    }))
  }

  const upsertContactToData = (contact) => {
    const isExist = (shipment?.contacts || []).some((c) => c.id_auto === contact.id_auto)

    queryClient.setQueryData(['shipment', _shipmentId], (oldShipment = {}) => ({
      ...oldShipment,
      contacts: isExist
        ? (oldShipment.contacts || []).map((c) => (c.id_auto === contact.id_auto ? contact : c))
        : [...(oldShipment.contacts || []), contact],
    }))
  }

  const upsertContactPICToData = (contactId) => {
    const contacts = (shipment?.contacts || []).map((c) => {
      if (c.id_auto === contactId) return { ...c, pivot: { ...c.pivot, pic: true } }
      else return { ...c, pivot: { ...c.pivot, pic: false } }
    })

    queryClient.setQueryData(['shipment', _shipmentId], (oldShipment = {}) => ({
      ...oldShipment,
      contacts: contacts,
    }))
  }

  const upsertShipmentData = (data) => {
    queryClient.setQueryData(['shipment', _shipmentId], (oldShipment = {}) => ({
      ...oldShipment,
      ...data,
    }))
  }

  const changeQuotationStatus = (status) => {
    queryClient.setQueryData(['shipment', _shipmentId], (oldShipment) => ({
      ...oldShipment,
      quotation_status: status,
    }))
  }

  const invalidate = () => {
    queryClient.invalidateQueries({ queryKey: ['shipment', _shipmentId], refetchType: 'none' })
  }

  return {
    error,
    refetch,
    shipment,
    isError,
    isLoading,
    isFetching,
    invalidate,
    isCreator,
    upsertShipmentData,
    upsertContactPICToData,
    setLimitationExceptCreator,
    upsertTrackingContactToData,
    deleteTrackingContactFromData,
    upsertContactToData,
    changeQuotationStatus,
  }
}

export default useShipment
