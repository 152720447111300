import { useMemo } from 'react'
import { useMediaQuery } from 'react-responsive'

// Utils
import { transformSelectField } from 'utils/helpers'

// Hooks
import useShipmentsEnumerations from 'hooks/useShipmentsEnumerations'

// Atlassian
import Drawer from '@atlaskit/drawer'
import TextField from '@atlaskit/textfield'
import Select from '@atlaskit/select'
import Toggle from '@atlaskit/toggle'
import { ErrorMessage, Label } from '@atlaskit/form'
import { LoadingButton } from '@atlaskit/button'

// Interfaces
import { Field, Form, WarningBannerField, InputGroup } from '@royan-co/user-interface'

const PackageDrawer = ({ onClose, isOpen, data, onSubmit, isSubmitting = false }) => {
  const isMobile = useMediaQuery({ query: `(max-width: 991.98px)` })
  const isEditing = useMemo(() => !!data?.box_type, [data])

  const { getEnumerationOptionsByKey, isLoadingShipmentEnumeration } = useShipmentsEnumerations()

  const defaultValues = {
    box_type: data?.box_type || null,
    number: data?.number || '',
    width: data?.width || '',
    length: data?.length || '',
    height: data?.height || '',
    weight: data?.weight || '',
    is_stackable: data?.is_stackable || false,
  }

  return (
    <Drawer isOpen={isOpen} onClose={onClose} width={isMobile ? 'full' : 'medium'}>
      <div className="drawer">
        <h3>{isEditing ? 'Edit package' : 'New package'}</h3>

        <Form onSubmit={onSubmit} defaultValues={defaultValues}>
          {() => (
            <>
              <Field
                isRequired
                label="Type"
                type="select"
                className="!mt-4"
                name="box_type"
                transform={transformSelectField(getEnumerationOptionsByKey('packaging_types'))}
              >
                {({ fieldProps, error }) => (
                  <>
                    <Select
                      placeholder="Choose"
                      menuPlacement="auto"
                      isLoading={isLoadingShipmentEnumeration}
                      options={getEnumerationOptionsByKey('packaging_types')}
                      {...fieldProps}
                    />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>

              <Field label="Quantity" name="number">
                {({ fieldProps, error }) => (
                  <>
                    <TextField {...fieldProps} />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>

              <div className="mt-2">
                <Label>Dimensions (cm)</Label>
                <InputGroup>
                  <Field isRequired name="width" className="basis-1/3 !mt-0">
                    {({ fieldProps }) => <TextField placeholder="W" {...fieldProps} />}
                  </Field>
                  <Field isRequired name="length" className="basis-1/3 !mt-0">
                    {({ fieldProps }) => <TextField placeholder="L" {...fieldProps} />}
                  </Field>
                  <Field isRequired name="height" className="basis-1/3 !mt-0">
                    {({ fieldProps }) => <TextField placeholder="H" {...fieldProps} />}
                  </Field>
                </InputGroup>
              </div>

              <Field name="weight" label="Weight (Kgs)">
                {({ fieldProps, error }) => (
                  <>
                    <TextField {...fieldProps} />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>

              <Field type="checkbox" name="is_stackable" label="Stackable">
                {({ fieldProps, error }) => (
                  <div>
                    <Toggle {...fieldProps} />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </div>
                )}
              </Field>

              <WarningBannerField />

              <LoadingButton
                type="submit"
                className="mt-4"
                shouldFitContainer
                appearance="primary"
                isLoading={isSubmitting}
              >
                {isEditing ? 'Edit' : 'Create'}
              </LoadingButton>
            </>
          )}
        </Form>
      </div>
    </Drawer>
  )
}

export default PackageDrawer
