import { useMutation, useQueryClient } from '@tanstack/react-query'

// Apis
import { sendToARCARegistry } from './registry-requests'

// Interfaces
import { useFlags } from '@royan-co/user-interface'

const useImportToARCARegistry = ({ registryId, owner, type }) => {
  const queryClient = useQueryClient()
  const { showSuccessFlag, showWarningFlag } = useFlags()

  return useMutation({
    mutationFn: () => sendToARCARegistry(registryId, owner, type),

    onSuccess: (res) => {
      showSuccessFlag(res.message)

      queryClient.setQueryData(['registry', ('' + registryId)], (oldRegistry) => ({
        ...oldRegistry,
        registry: {
          ...oldRegistry.registry,
          arca_imported: true,
        },
      }))
    },

    onError: (e) => {
      console.error(e)
      showWarningFlag('Something wrong!')
    },
  })
}

export { useImportToARCARegistry }
