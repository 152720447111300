// Utils
import { findOption } from 'utils/helpers'
import { vehicleTypeOptions } from './../../utils/constants'

export const vehicleTypeFilters = [
  {
    key: 'type',
    content: (v) => `Type: ${findOption(vehicleTypeOptions, v)?.label}`,
  },
]
