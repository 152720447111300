export const invoicePaidOptions = [
  { label: 'Yes', value: 3, appearance: 'success' },
  { label: 'Partial', value: 2, appearance: 'default'},
  { label: 'No', value: 0, appearance: 'moved' },
]

export const invoiceProformaOptions = [
  { label: 'Yes', value: 1, appearance: 'default' },
  { label: 'No', value: 0, appearance: 'moved' },
]

export const invoiceImportedARCAOptions = [
  { label: 'Yes', value: 2 },
  { label: 'ARCA error', value: 10 },
  { label: 'Pending', value: 1 },
  { label: 'No', value: 0 },
]

export const invoiceCMROptions = [
  { label: 'Yes', value: 1 },
  { label: 'No', value: 0 },
]

export const invoiceVisibilityOperatorOptions = [
  { label: 'Hidden', value: 1 },
  { label: 'Visible', value: 0 },
]

export const invoiceMultipleOptions = [
  { label: 'Yes', value: 1 },
  { label: 'No', value: 0 },
]

export const invoiceDisputedOptions = [
  { label: 'Yes', value: 1 },
  { label: 'No', value: 0 },
]

export const invoiceTypeOptions = [
  { label: 'Invoice', value: 0, appearance: 'default' },
  { label: 'Credit note', value: 1, appearance: 'inprogress' },
]

export const registrationTypeOptions = [
  { label: 'To be processed', value: 0, appearance: 'removed' },
  { label: 'In progress', value: 1, appearance: 'moved' },
  // we removed Processed types, but in some date can have this value.
  // { label: 'Processed', value: 2, appearance: 'success' },
  { label: 'Elaborated', value: 3, appearance: 'success' },
  { label: 'Pending', value: 9, appearance: 'moved' },
  { label: 'ARCA error', value: 10, appearance: 'new' },
]
