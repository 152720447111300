import { useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import intersection from 'lodash/intersection'

//Atlassian
import Lozenge from '@atlaskit/lozenge'
import Tabs, { Tab, TabList, TabPanel } from '@atlaskit/tabs'

const FormTabs = ({ tabs, errors }) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const selectedTabIndex = useMemo(() => {
    let tabKeyInUrl = searchParams.get('tab')
    let index = tabs.findIndex((tab) => tab.key === tabKeyInUrl)
    return Array.from(tabs.keys()).includes(index) ? index : 0
  }, [searchParams, tabs])

  const setSelectedTab = (i) => {
    setSearchParams((s) => ({ ...Object.fromEntries(s), tab: tabs[i].key }), { replace: true })
  }

  const getTabErrors = useCallback(
    (key) => {
      const errorsName = Object.keys(errors)
      const fieldsName = tabs?.find((t) => t.key === key)?.fields
      const intersections = intersection(errorsName, fieldsName)

      return intersections?.length || 0
    },
    [errors, tabs]
  )

  return (
    <Tabs selected={selectedTabIndex} onChange={setSelectedTab}>
      <TabList>
        {tabs.map((tab) => (
          <Tab key={tab.key}>
            {tab.title}

            {getTabErrors(tab.key) !== 0 && (
              <span className="ml-1">
                <Lozenge appearance="removed">{getTabErrors(tab.key)}</Lozenge>
              </span>
            )}
          </Tab>
        ))}
      </TabList>

      {tabs.map((tab) => (
        <TabPanel key={tab.key}>{tab.component}</TabPanel>
      ))}
    </Tabs>
  )
}

export default FormTabs
