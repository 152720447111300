import { Link } from 'react-router-dom'

//Contexts
import { useConsolidationCreationContext } from 'contexts/ConsolidationCreationProvider'

//Interfaces
import { Stepper, StepperStep } from '@royan-co/user-interface'

const ConsolidationCreationProgressTracker = () => {
  const { steps, currentStep } = useConsolidationCreationContext()

  return (
    <>
      <Stepper>
        {steps.map((step, i) => (
          <StepperStep
            key={step.id}
            label={step.id < currentStep.id ? <Link to={step.path}>{step.label}</Link> : step.label}
            state={
              step.id === currentStep.id
                ? 'current'
                : step.id < currentStep.id
                ? 'visited'
                : 'unvisited'
            }
          />
        ))}
      </Stepper>
    </>
  )
}

export default ConsolidationCreationProgressTracker
