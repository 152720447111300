import Tag from '@atlaskit/tag'

const QuoteStatusTag = ({ value }) => {
  if (value === 2) {
    return <Tag isRemovable={false} text="Accepted" color="green" />
  } else if (value === 3) {
    return <Tag isRemovable={false} text="Rejected" color="red" />
  } else if (value === 4) {
    return <Tag isRemovable={false} text="Expired" color="redLight" />
  } else if (value === 1) {
    return <Tag isRemovable={false} text="Quoted" color="blue" />
  } else {
    return <Tag isRemovable={false} text="Pending" color="yellow" />
  }
}

export default QuoteStatusTag
