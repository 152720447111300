// Atlassian
import { ErrorMessage } from '@atlaskit/form'

// Interfaces
import { Field } from '@royan-co/user-interface'

// Components
import RegistrySelect from 'components/Registries/RegistrySelect'

const RegistrySelectField = ({ tagOption, registryType, defaultData, ...props }) => {
  return (
    <Field
      type="select"
      transform={{
        output: (opt) => (opt?.value !== undefined ? opt.value : null),
      }}
      {...props}
    >
      {({ fieldProps, error }) => (
        <>
          <RegistrySelect
            tagOption={tagOption}
            defaultData={defaultData}
            registryType={registryType}
            {...fieldProps}
          />
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </>
      )}
    </Field>
  )
}

export default RegistrySelectField
