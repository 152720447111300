import React from 'react'
import { useMutation } from '@tanstack/react-query'

// Apis
import { deleteInvoiceRow } from '../api/row'

// Hooks
import { useUpdateInvoice } from '../api/getInvoice'

//Atlassian
import Modal, {
  ModalBody,
  ModalTitle,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import Button, { LoadingButton } from '@atlaskit/button'

//Interfaces
import { useFlags, WarningBanner } from '@royan-co/user-interface'

const DeleteRowModal = ({ isOpen, onClose, rowId, invoiceId }) => {
  const { showSuccessFlag } = useFlags()
  const { upsertInvoiceProperties } = useUpdateInvoice(invoiceId)

  const invoiceRowDeleteMutation = useMutation(deleteInvoiceRow)

  function closeModal() {
    onClose()
    invoiceRowDeleteMutation.reset()
  }

  const removeProformaInvoice = () => {
    invoiceRowDeleteMutation.mutate(rowId, {
      onError: (e) => {
        console.error(e)
      },
      onSuccess: (res) => {
        upsertInvoiceProperties(res.invoice)
        showSuccessFlag(res.message)
        onClose()
      },
    })
  }

  return (
    <>
      <ModalTransition>
        {isOpen && (
          <Modal onClose={closeModal}>
            <ModalHeader>
              <ModalTitle appearance="danger">Delete row</ModalTitle>
            </ModalHeader>
            <ModalBody>
              You are about to delete a row. Are you sure?
              {invoiceRowDeleteMutation.isError && <WarningBanner />}
            </ModalBody>
            <ModalFooter>
              <Button appearance="subtle" onClick={closeModal}>
                Cancel
              </Button>
              <LoadingButton
                isLoading={invoiceRowDeleteMutation.isLoading}
                appearance="danger"
                onClick={removeProformaInvoice}
                autoFocus
              >
                Delete
              </LoadingButton>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </>
  )
}

export default DeleteRowModal
