import { useMutation } from '@tanstack/react-query'

// Apis
import { elaborateInvoice } from './invoice-requests'

// Hooks
import { useUpdateInvoice } from './getInvoice'

// Interfaces
import { useFlags, useRoyanTable } from '@royan-co/user-interface'

const useElaborateInvoice = ({ invoiceId }) => {
  const { refresh } = useRoyanTable()
  const { showSuccessFlag } = useFlags()
  const { upsertInvoiceProperties } = useUpdateInvoice(invoiceId)

  return useMutation({
    mutationFn: () => elaborateInvoice(invoiceId),

    onSuccess: (res) => {
      showSuccessFlag(res.message)

      if (refresh) {
        refresh()
      } else upsertInvoiceProperties(res.invoice)
    },
  })
}

export { useElaborateInvoice }
