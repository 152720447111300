import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useMutation } from '@tanstack/react-query'

//Apis
import { createRegistryContact, editRegistryContact } from 'api/registries'

//Utils
import { getFilled } from 'utils/helpers'
import { handleErrorOnSubmit } from 'utils/errors'

//Interfaces
import { DotsIcon, useDisclosure, useFlags } from '@royan-co/user-interface'

// Services
import useRegistry from 'hooks/useRegistry'

// Atlassian
import Button from '@atlaskit/button'
import Select from '@atlaskit/select'
import Lozenge from '@atlaskit/lozenge'
import EmptyState from '@atlaskit/empty-state'
import { DynamicTableStateless } from '@atlaskit/dynamic-table'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'

// Components
import SearchInput from 'components/UI/SearchInput'
import ContactDrawer from '../Create/ContactDrawer'
import Card from 'components/UI/Card'
import RegistryDeleteContactModal from './DeleteContactModal'
import DropdownLink from 'components/UI/DropdownLink'

const rowsPerPageOptions = [
  { label: '10', value: 10 },
  { label: '25', value: 25 },
  { label: '50', value: 50 },
]

const tableHead = {
  cells: [
    { content: 'Name' },
    { content: 'Role' },
    { content: 'Email' },
    { content: 'Phone' },
    { content: 'Extension' },
    { content: 'Online access' },
    { content: '' },
  ],
}

const RegistryContactsSection = ({ className }) => {
  const [page, setPage] = useState(1)
  const { registryId, creatorId } = useParams()
  const [searchTerm, setSearchTerm] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const { showSuccessFlag } = useFlags()
  const [isOpenContactDrawer, contactDrawerHandlers] = useDisclosure('contact')
  const [isOpenDeleteModal, deleteModalHandlers] = useDisclosure('delete-contact')
  const { registry, upsertContactToData, isLoading } = useRegistry(registryId, { creatorId })

  const isEdited = useMemo(() => !!contactDrawerHandlers?.data?.id_auto, [contactDrawerHandlers])

  const branches = useMemo(
    () =>
      (registry?.addresses || []).map((a) => ({
        ...a,
        uuid: a.id_auto,
        cap: getFilled(a, 'cap', ''),
        type: a.type
          .split(';')
          .filter((t) => !isNaN(parseInt(t)))
          .map((t) => +t),
      })),
    [registry]
  )

  const createRegistryContactMutation = useMutation((data) =>
    !isEdited
      ? createRegistryContact(data.id, data.param)
      : editRegistryContact(data.id, data.param)
  )

  const onSubmit = (data, { setError }) => {
    data = {
      ...data,
      cellphone: getFilled(data?.cellphone, 'rawPhone', data?.cellphone),
      cellphone_prefix: getFilled(data?.cellphone, 'countryAbbr', data?.cellphone_prefix),
    }

    const requestId = isEdited
      ? contactDrawerHandlers.data.id_auto // contact ID
      : branches.length === 1
      ? branches[0].uuid // branch ID
      : data.branch_uuid

    createRegistryContactMutation.mutate(
      { id: requestId, param: data },
      {
        onError: (e) => {
          handleErrorOnSubmit(e, setError, data)
          console.error(e)
        },
        onSuccess: (res) => {
          showSuccessFlag(res.message)
          upsertContactToData(res.contact)
          contactDrawerHandlers.close()
        },
      }
    )
  }

  const tableRows = useMemo(
    () =>
      (registry?.contacts || [])
        ?.filter((contact) =>
          contact.full_name?.toLocaleLowerCase()?.includes(searchTerm.toLocaleLowerCase())
        )
        .map((contact, i) => ({
          cells: [
            {
              content: <div className="py-1">{contact.full_name}</div>,
            },
            {
              content: contact.role ? (
                <Lozenge appearance="inprogress"> {contact.role} </Lozenge>
              ) : null,
            },
            {
              content: contact.email,
            },
            {
              content: contact.full_cellphone,
            },
            {
              content: contact.telephone_int,
            },
            {
              content: contact?.users?.[0]?.pivot?.online_access ? (
                <Lozenge appearance="success">Yes</Lozenge>
              ) : (
                <Lozenge appearance="default">No</Lozenge>
              ),
            },
            {
              content: (
                <DropdownMenu
                  placement="bottom-end"
                  trigger={({ triggerRef, ...props }) => (
                    <Button
                      spacing="compact"
                      ref={triggerRef}
                      appearance="subtle"
                      iconBefore={<DotsIcon label="more" />}
                      {...props}
                    />
                  )}
                >
                  <DropdownItemGroup>
                    <DropdownLink
                      children="View"
                      to={`/panel/registries/${registry?.id_auto}${
                        creatorId ? `/${creatorId}` : ''
                      }/contact/${contact.id_auto}`}
                    />
                    <DropdownItem
                      onClick={() => {
                        contactDrawerHandlers.open({
                          ...contact,
                          branch_uuid: contact.registry_address_id,
                          telephone_int: getFilled(contact, 'telephone_int', ''),
                        })
                      }}
                    >
                      Edit
                    </DropdownItem>

                    <DropdownItem onClick={() => deleteModalHandlers.open(contact)}>
                      Delete
                    </DropdownItem>
                  </DropdownItemGroup>
                </DropdownMenu>
              ),
            },
          ],
        })),
    [registry, searchTerm, creatorId, contactDrawerHandlers, deleteModalHandlers]
  )

  return isLoading ? null : (
    <div className={className}>
      <div className="flex items-center">
        <h3>Contacts</h3>

        <Button
          appearance="link"
          spacing="none"
          className="ml-auto"
          isDisabled={branches.length === 0}
          onClick={() => contactDrawerHandlers.open()}
        >
          New contact
        </Button>
      </div>

      <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-2 mt-2">
        <div style={{ maxWidth: '240px' }}>
          <SearchInput onSubmit={setSearchTerm} delay={50} isCompact />
        </div>

        <div className="flex items-center">
          <label className="text-neutral-200 mr-2" htmlFor="row-per-page">
            Rows per page:
          </label>
          <div style={{ width: 70 }}>
            <Select
              placeholder={10}
              spacing="compact"
              inputId="row-per-page"
              options={rowsPerPageOptions}
              onChange={({ value }) => setRowsPerPage(value)}
              value={rowsPerPageOptions?.find((opt) => opt.value === rowsPerPage)}
            />
          </div>
        </div>
      </div>

      <Card className=" mt-4">
        {tableRows.length === 0 ? (
          <EmptyState header="No contacts" />
        ) : (
          <div className="overflow-auto inline-block w-full -mb-6">
            <DynamicTableStateless
              page={page}
              head={tableHead}
              rows={tableRows}
              onSetPage={setPage}
              rowsPerPage={rowsPerPage}
            />
          </div>
        )}
      </Card>

      <ContactDrawer
        branches={branches}
        onSubmit={onSubmit}
        data={contactDrawerHandlers.data}
        isOpen={isOpenContactDrawer}
        onClose={contactDrawerHandlers.close}
        isSubmitting={createRegistryContactMutation.isLoading}
      />

      <RegistryDeleteContactModal
        isOpen={isOpenDeleteModal}
        onClose={deleteModalHandlers.close}
        contact={deleteModalHandlers.data}
      />
    </div>
  )
}

export default RegistryContactsSection
