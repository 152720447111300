import { useEffect } from 'react'

//Atlassian
import TextField from '@atlaskit/textfield'
import { ErrorMessage } from '@atlaskit/form'
import { Checkbox } from '@atlaskit/checkbox'
import { DatePicker } from '@atlaskit/datetime-picker'

//Interfaces
import { Field, useFormContext, useFormValues } from '@royan-co/user-interface'

//Components
import Card from 'components/UI/Card'
import Divider from 'components/UI/Divider'
import TripleFieldsRegistry from 'components/Shipments/View/TripleFieldsRegistry'
import { registries } from './registries'

const shipperFields = registries.shipper_fields
const secondShipperFields = registries.second_shipper_fields
const pickupFields = registries.pickup_fields

const ShipmentViewEditRegistriesShipper = () => {
  const { setValue } = useFormContext()
  const {
    client_id,
    shipper_as_client: shipperAsClient,
    loading_as_shipper: loadingAsShipper,
    second_set: secondSet,
    [shipperFields.names.registry]: shipper,
    [shipperFields.names.address]: shipperAddress,
    [shipperFields.names.contact]: shipperContact,
  } = useFormValues()

  useEffect(() => {
    if (shipperAsClient) {
      setValue(shipperFields.names.registry, client_id)
      setValue(shipperFields.names.address, null)
      setValue(shipperFields.names.contact, null)
    }
  }, [setValue, shipperAsClient, client_id])

  useEffect(() => {
    if (loadingAsShipper) {
      setValue(pickupFields.names.registry, shipper)
      setValue(pickupFields.names.address, shipperAddress)
      setValue(pickupFields.names.contact, shipperContact)
    }
  }, [setValue, loadingAsShipper, shipper, shipperAddress, shipperContact])

  return (
    <Card className="w-full my-6">
      <Field label="Shipper details" type="checkbox" name="shipper_as_client" className="!mt-0">
        {({ fieldProps }) => <Checkbox label="Shipper is the same as client" {...fieldProps} />}
      </Field>

      {!shipperAsClient && (
        <TripleFieldsRegistry
          className="!mt-4"
          names={shipperFields.names}
          labels={shipperFields.labels}
        />
      )}

      {!!secondSet && (
        <TripleFieldsRegistry
          className="!mt-4"
          names={secondShipperFields.names}
          labels={secondShipperFields.labels}
        />
      )}

      <Divider className="mt-8 mb-6" />

      <Field
        label="Loading information"
        type="checkbox"
        name="loading_as_shipper"
        className="!mt-0"
      >
        {({ fieldProps }) => (
          <Checkbox label="Loading address is the same as shipper’s address" {...fieldProps} />
        )}
      </Field>

      {!loadingAsShipper && (
        <TripleFieldsRegistry
          className="!mt-4"
          names={pickupFields.names}
          labels={pickupFields.labels}
        />
      )}

      <Field label="Loading date" name="loading_date" className="!mt-4">
        {({ fieldProps }) => (
          <DatePicker placeholder="2/18/2022" spacing="compact" {...fieldProps} />
        )}
      </Field>

      <Field label="Loading reference" name="loading_reference" className="!mt-4 mb-2">
        {({ fieldProps, error }) => (
          <>
            <TextField {...fieldProps} placeholder="Type the loading reference here" isCompact />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </>
        )}
      </Field>
    </Card>
  )
}

export default ShipmentViewEditRegistriesShipper
