import { useParams } from 'react-router'

//Hooks
import useContact from 'hooks/useContact'

//Atlassian
import Lozenge from '@atlaskit/lozenge'

//Components
import Card from 'components/UI/Card'
import RowTable from 'components/UI/RowTable'

const RegistryContactGeneralPage = () => {
  const { contactId } = useParams()
  const { contact } = useContact(contactId)

  const generalRows = [
    {
      title: 'Related branch',
      content: (
        <>{contact?.registry_address?.address || <Lozenge appearance="default">Not set</Lozenge>}</>
      ),
    },
    {
      title: 'Role',
      content: (
        <>
          {contact?.role ? (
            <Lozenge appearance="inprogress">{contact?.role}</Lozenge>
          ) : (
            <Lozenge appearance="default">Not set</Lozenge>
          )}
        </>
      ),
    },
    {
      title: 'Email',
      content: <>{contact?.email || <Lozenge appearance="default">Not set</Lozenge>}</>,
    },
    {
      title: 'Phone',
      content: (
        <>
          {contact?.full_cellphone ? (
            `+${contact?.full_cellphone}`
          ) : (
            <Lozenge appearance="default">Not set</Lozenge>
          )}
        </>
      ),
    },
    {
      title: 'Extension',
      content: <>{contact?.telephone_int || <Lozenge appearance="default">Not set</Lozenge>}</>,
    },
    {
      title: 'Online access',
      content: (
        <>
          {contact?.online_access ? (
            <Lozenge appearance="success">Yes</Lozenge>
          ) : (
            <Lozenge appearance="moved">No</Lozenge>
          )}
        </>
      ),
    },
  ]

  return (
    <Card className="mt-0">
      <RowTable rows={generalRows} />
    </Card>
  )
}

export default RegistryContactGeneralPage
