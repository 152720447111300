import { useEffect, useState } from 'react'
import set from 'lodash/set'
import cloneDeep from 'lodash/cloneDeep'

// Atlassian
import TextArea from '@atlaskit/textarea'
import Textfield from '@atlaskit/textfield'
import InlineEdit from '@atlaskit/inline-edit'
import { DatePicker } from '@atlaskit/datetime-picker'

// Assets
import './styles.scss'
import cmrLogo from '../../assets/cmr.png'

// Components
import InlineEditableSelect from './InlineEditable/InlineEditableSelect'

const CMRFields = {
  number: '',
  consignor: '',
  consigned: '',
  pod: '',
  pol: '',
  attachmets: '',
  successiveCarriers: '',
  carrierObservation: '',
  class: '',
  number2: '',
  letter: '',
  adr: '',
  senderInstructions: '',
  agreements: '',
  carriagePaid: '',
  carriageForward: '',
  establishedIn: '',
  establishedOn: '',
  cashOnDelivery: '',
  receivedPlace: '',
  receivedOn: '',
  signature: '',
  carrierSignature: '',
  consigneeSignature: '',
  truckNumber: '',
  trailerNumber: '',
  packages: [
    {
      number: '',
      packages: '',
      packing: '',
      goods: '',
      statistical: '',
      gross: '',
      volume: '',
    },
  ],
}

const CMRForm = ({ defaultValues, onChange, previewMode, company }) => {
  const placeHolder = ''
  const [formData, setFormData] = useState({
    ...CMRFields,
    ...cloneDeep(defaultValues),
  })

  function onChangeFormData(key, value) {
    const NewFormData = {
      ...formData,
      ...set(formData, key, value),
    }
    onChange?.(NewFormData)
    setFormData(NewFormData)
  }

  useEffect(() => {
    onChange?.({
      ...CMRFields,
      ...cloneDeep(defaultValues),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="paper-document-wrapper">
      <div className="paper-document">
        <div className="fix w-50 l-0 h-7line border-left border-top">
          <div className="flex px-1mm line-height-1-2 size-0-9">
            <span>1 &nbsp;</span>
            <div>
              Mittente (cognome, nome, stato)
              <br />
              Sender ( name, address, country)
            </div>
          </div>
          <div style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.consignor}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="5"
                  className="overflow-hidden"
                  maxHeight={5 * 1.618 + 'em'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-5line read-view-text-aria">
                  {formData.consignor || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'consignor')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-50 l-50 h-7line border-left border-top border-right">
          <div className="px-2mm pt-1mm line-height-1-2 size-0-9">
            LETTERA DI VETTURA INTERNAZIONALE <br />
            INTERNATIONAL CONSIGNAMENT NOTE
          </div>
        </div>

        <div className="fix w-30 l-50 h-3line top-2line px-2mm">
          <img className="h-3line" src={cmrLogo} alt="" />
        </div>

        <div className="fix w-46 l-50 h-4line top-5line">
          <div className="flex">
            <div className="px-2mm size-0-8 line-height-1 grow">
              This carriage is subject, notwithstanding any clause to the countrary, to the
              Convention on the Contract for the International Carriage of googs by road (CMR).
            </div>
            <div className="px-2mm size-0-8 line-height-1 grow">
              Questo trasporto c sottomesso, nonostante qualunque clausola contraria alla
              convenzione relativa al contratto di trasporto Interna zionale di merci su strada
              (CMR).
            </div>
          </div>
        </div>

        <div className="fix w-20 l-80 top-1line h-1-5line">
          <div className="size-1-4">
            <div style={{ marginTop: '-8px' }}>
              <InlineEdit
                isEditing={previewMode}
                defaultValue={formData.number}
                editView={({ errorMessage, ...fieldProps }, ref) => (
                  <Textfield {...fieldProps} ref={ref} />
                )}
                readView={() => <div className="h-1-5line">{formData.number || placeHolder}</div>}
                onConfirm={onChangeFormData.bind(null, 'number')}
                hideActionButtons
                readViewFitContainerWidth
              />
            </div>
          </div>
        </div>

        <div className="fix w-50 l-0 top-7line h-7line border-left border-top">
          <div className="flex px-1mm line-height-1-2 size-0-9">
            <span>2 &nbsp;</span>
            <div>
              Destinatario (cognome, nome, stato)
              <br />
              Consignee (name, address, country)
            </div>
          </div>
          <div style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.consigned}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="5"
                  className="overflow-hidden"
                  maxHeight={5 * 1.618 + 'em'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-5line read-view-text-aria">
                  {formData.consigned || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'consigned')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-50 l-50 top-7line h-7line border-left border-top border-right">
          <div className="flex px-1mm line-height-1-2 size-0-9">
            <span>16 &nbsp;</span>
            <div>
              Trasportatore (cognome, nome, indirizzo, stato)
              <br />
              Carrier (name, address, country)
            </div>
          </div>
          <div className="px-1mm input-font line-height-n w-65">
            {company?.name}
            <br />
            {company?.address} {company?.postal_code}
            <br />
            Tel: {company?.phone_number}
            <br />
            Fax: {company?.fax_number}
            <br />
            {company?.email}
          </div>
        </div>

        <div className="fix w-20 l-80 text-center top-7line h-5line py-1_2line">
          {company?.logo && <img className="static h-4line" src={company?.logo} alt="" />}
        </div>

        <div className="fix w-50 l-0 top-14line h-4line border-left border-top">
          <div className="flex px-1mm line-height-1-2 size-0-9">
            <span>3 &nbsp;</span>
            <div>
              Luogo previsto per la consegna della merce (locality, stato)
              <br />
              Place of delivery of the goods (place, country)
            </div>
          </div>

          <div style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.pod}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="3"
                  className="overflow-hidden"
                  maxHeight={3 * 1.618 + 'em'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-3line read-view-text-aria">{formData.pod || placeHolder}</div>
              )}
              onConfirm={onChangeFormData.bind(null, 'pod')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-50 l-50 top-13line h-5line border-left border-right">
          <div className="flex px-1mm line-height-1-2 size-0-9">
            <span>17 &nbsp;</span>
            <div>
              Trasportatori successivi (cognome, nome, indirizzo, stato)
              <br />
              Successive carriers (name, address, country)
            </div>
          </div>
          <div style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.successiveCarriers}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="3"
                  className="overflow-hidden"
                  maxHeight={3 * 1.618 + 'em'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-3line read-view-text-aria">
                  {formData.successiveCarriers || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'successiveCarriers')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-50 l-0 top-18line h-4line border-left border-top">
          <div className="flex px-1mm line-height-1-2 size-0-9">
            <span>4 &nbsp;</span>
            <div>
              Luogo e data della presa in carico della merce
              <br />
              Place and date of taking over goods (place, country, date)
            </div>
          </div>
          <div style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.pol}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="3"
                  className="overflow-hidden"
                  maxHeight={3 * 1.618 + 'em'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-3line read-view-text-aria">{formData.pol || placeHolder}</div>
              )}
              onConfirm={onChangeFormData.bind(null, 'pol')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-50 l-0 top-22line h-4line border-left border-top">
          <div className="flex px-1mm line-height-1-2 size-0-9">
            <span>5 &nbsp;</span>
            <div>
              Documenti allegat
              <br />
              Documents attached
            </div>
          </div>
          <div style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.attachmets}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="3"
                  className="overflow-hidden"
                  maxHeight={3 * 1.618 + 'em'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-3line read-view-text-aria">
                  {formData.attachmets || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'attachmets')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-50 l-50 top-18line h-8line border-left border-top border-right">
          <div className="flex px-1mm line-height-1-2 size-0-9">
            <span>18 &nbsp;</span>
            <div>
              Riserve ed osservazioni del trasportatore
              <br />
              Carrier's reservations and observation
            </div>
          </div>
          <div style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.carrierObservation}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="7"
                  className="overflow-hidden"
                  maxHeight={7 * 1.618 + 'em'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-7line read-view-text-aria">
                  {formData.carrierObservation || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'carrierObservation')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-15 l-0 top-26line h-18line border-left border-top">
          <div className="flex px-1mm line-height-1-2 size-0-9">
            <span>6 &nbsp;</span>
            <div>
              Contrassegni e numeri
              <br />
              Marks and Nos.
            </div>
          </div>
          <div style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.packages[0].number}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="19"
                  className="overflow-hidden"
                  maxHeight={19 * 1.618 + 'em'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-19line read-view-text-aria">
                  {formData.packages[0].number || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'packages.0.number')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-15 l-15 top-26line h-18line border-top">
          <div className="flex px-1mm line-height-1-2 size-0-9">
            <span>7 &nbsp;</span>
            <div>
              Numero dei coll
              <br />
              Number of packages
            </div>
          </div>
          <div style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.packages[0].packages}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="19"
                  className="overflow-hidden"
                  maxHeight={19 * 1.618 + 'em'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-19line read-view-text-aria">
                  {formData.packages[0].packages || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'packages.0.packages')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-15 l-30 top-26line h-18line border-top">
          <div className="flex px-1mm line-height-1-2 size-0-9">
            <span>8 &nbsp;</span>
            <div>
              Imballagg
              <br />
              Method of packing
            </div>
          </div>
          <div style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.packages[0].packing}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="19"
                  className="overflow-hidden"
                  maxHeight={19 * 1.618 + 'em'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-19line read-view-text-aria">
                  {formData.packages[0].packing || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'packages.0.packing')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-15 l-45 top-26line h-18line border-top">
          <div className="flex px-1mm line-height-1-2 size-0-9">
            <span>9 &nbsp;</span>
            <div>
              Denominaz. Corrente
              <br />
              Nature of the goods
            </div>
          </div>
          <div style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.packages[0].goods}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="19"
                  className="overflow-hidden"
                  maxHeight={19 * 1.618 + 'em'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-19line read-view-text-aria">
                  {formData.packages[0].goods || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'packages.0.goods')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-13 l-60 top-26line h-20line border-left border-top">
          <div className="flex px-1mm line-height-1-2 size-0-9">
            <span>10 &nbsp;</span>
            <div>
              N. di statistica
              <br />
              Statistical number
            </div>
          </div>
          <div style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.packages[0].statistical}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="19"
                  className="overflow-hidden"
                  maxHeight={19 * 1.618 + 'em'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-19line read-view-text-aria">
                  {formData.packages[0].statistical || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'packages.0.statistical')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-13 l-73 top-26line h-20line border-left border-top">
          <div className="flex px-1mm line-height-1-2 size-0-9">
            <span>11 &nbsp;</span>
            <div>
              Peso lordo kg.
              <br />
              Gross weight in kg
            </div>
          </div>
          <div style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.packages[0].gross}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="19"
                  className="overflow-hidden"
                  maxHeight={19 * 1.618 + 'em'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-19line read-view-text-aria">
                  {formData.packages[0].gross || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'packages.0.gross')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-13 l-87 top-26line h-20line border-left border-top border-right">
          <div className="flex px-1mm line-height-1-2 size-0-9">
            <span>12 &nbsp;</span>
            <div>
              Volume m3
              <br />
              Volume in m3
            </div>
          </div>
          <div style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.packages[0].volume}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="19"
                  className="overflow-hidden"
                  maxHeight={19 * 1.618 + 'em'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-19line read-view-text-aria">
                  {formData.packages[0].volume || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'packages.0.volume')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-15 l-0 top-44line h-2line border-left border-top pt-2">
          <div className="px-1mm flex items-top">
            <div className="line-height-1-2 size-0-9">
              Classe/Klass
              <br />
              Class
            </div>
            <div className="input-1line grow" style={{ marginTop: '-8px' }}>
              <InlineEdit
                isEditing={previewMode}
                defaultValue={formData.class}
                editView={({ errorMessage, ...fieldProps }, ref) => (
                  <Textfield {...fieldProps} ref={ref} />
                )}
                readView={() => (
                  <div className="h-1line read-view-text">{formData.class || placeHolder}</div>
                )}
                onConfirm={onChangeFormData.bind(null, 'class')}
                hideActionButtons
                readViewFitContainerWidth
              />
            </div>
          </div>
        </div>

        <div className="fix w-15 l-15 top-44line h-2line border-top pt-2">
          <div className="px-1mm flex items-top">
            <div className="line-height-1-2 size-0-9">
              Cifra
              <br />
              Number
            </div>
            <div className="input-1line grow" style={{ marginTop: '-8px' }}>
              <InlineEdit
                isEditing={previewMode}
                defaultValue={formData.number2}
                editView={({ errorMessage, ...fieldProps }, ref) => (
                  <Textfield {...fieldProps} ref={ref} />
                )}
                readView={() => (
                  <div className="h-1line read-view-text">{formData.number2 || placeHolder}</div>
                )}
                onConfirm={onChangeFormData.bind(null, 'number2')}
                hideActionButtons
                readViewFitContainerWidth
              />
            </div>
          </div>
        </div>

        <div className="fix w-15 l-30 top-44line h-2line border-top pt-2">
          <div className="px-1mm flex items-top">
            <div className="line-height-1-2 size-0-9">
              Lettera
              <br />
              Letter
            </div>
            <div className="input-1line grow" style={{ marginTop: '-8px' }}>
              <InlineEdit
                isEditing={previewMode}
                defaultValue={formData.letter}
                editView={({ errorMessage, ...fieldProps }, ref) => (
                  <Textfield {...fieldProps} ref={ref} />
                )}
                readView={() => (
                  <div className="h-1line read-view-text">{formData.letter || placeHolder}</div>
                )}
                onConfirm={onChangeFormData.bind(null, 'letter')}
                hideActionButtons
                readViewFitContainerWidth
              />
            </div>
          </div>
        </div>

        <div className="fix w-15 l-45 top-44line h-2line border-top pt-2">
          <div className="px-1mm flex items-top">
            <div className="line-height-1-2 size-0-9">
              (ADR<sup>*</sup>)
            </div>
            <div className="input-1line grow" style={{ marginTop: '-8px' }}>
              <InlineEdit
                isEditing={previewMode}
                defaultValue={formData.adr}
                editView={({ errorMessage, ...fieldProps }, ref) => (
                  <Textfield {...fieldProps} ref={ref} />
                )}
                readView={() => (
                  <div className="h-1line read-view-text">{formData.adr || placeHolder}</div>
                )}
                onConfirm={onChangeFormData.bind(null, 'adr')}
                hideActionButtons
                readViewFitContainerWidth
              />
            </div>
          </div>
        </div>

        <div className="fix w-50 l-0 top-46line h-8line border-left border-top">
          <div className="flex px-1mm line-height-1-2 size-0-9">
            <span>13 &nbsp;</span>
            <div>
              Istruzioni del mittent
              <br />
              Sender's instructions
            </div>
          </div>
          <div style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.senderInstructions}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="7"
                  className="overflow-hidden"
                  maxHeight={7 * 1.618 + 'em'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-7line read-view-text-aria">
                  {formData.senderInstructions || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'senderInstructions')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-50 l-50 top-46line h-4line border-left border-top border-right">
          <div className="flex px-1mm line-height-1-2 size-0-9">
            <span>19 &nbsp;</span>
            <div>
              Convenzioni particolar
              <br />
              Special agreements
            </div>
          </div>
          <div style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.agreements}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="2"
                  className="overflow-hidden"
                  maxHeight={2 * 1.618 + 'em'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-2line read-view-text-aria">
                  {formData.agreements || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'agreements')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-50 l-50 top-50line h-10line border-left border-top border-right">
          <table className="cmr-table">
            <thead>
              <tr>
                <td className="line-height-1-2 size-0-9 flex py-1_2line">
                  <span>20 &nbsp;</span> Prezzo trasp / To be paid by:
                </td>
                <td
                  className="border-left text-center line-height-1-2 size-0-9 py-1_2line"
                  colSpan="2"
                >
                  Mittente / Senders
                </td>
                <td
                  className="border-left text-center line-height-1-2 size-0-9 py-1_2line"
                  colSpan="2"
                >
                  Valuta / Currency
                </td>
                <td
                  className="border-left text-center line-height-1-2 size-0-9 py-1_2line"
                  colSpan="2"
                >
                  Destinatario / Consignee
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border-top line-height-1-2 size-0-9">
                  Prezzo trasp. / Carriage charges:
                </td>
                <td className="border-top border-left"></td>
                <td className="border-top border-left"></td>
                <td className="border-top border-left"></td>
                <td className="border-top border-left"></td>
                <td className="border-top border-left"></td>
                <td className="border-top border-left"></td>
              </tr>
              <tr>
                <td className="size-0-9">Abb. / Deductions:</td>
                <td className="border-top border-left"></td>
                <td className="border-top border-left"></td>
                <td className="border-top border-left"></td>
                <td className="border-top border-left"></td>
                <td className="border-top border-left"></td>
                <td className="border-top border-left"></td>
              </tr>
              <tr>
                <td className="border-top size-0-9">Saldo / Balance:</td>
                <td className="border-top border-left"></td>
                <td className="border-top border-left"></td>
                <td className="border-top border-left"></td>
                <td className="border-top border-left"></td>
                <td className="border-top border-left"></td>
                <td className="border-top border-left"></td>
              </tr>
              <tr>
                <td className="size-0-9">Maggior. / Supplem Charges:</td>
                <td className="border-top border-left"></td>
                <td className="border-top border-left"></td>
                <td className="border-top border-left"></td>
                <td className="border-top border-left"></td>
                <td className="border-top border-left"></td>
                <td className="border-top border-left"></td>
              </tr>
              <tr>
                <td className="size-0-9">Zuschlcge /Other Charges:</td>
                <td className="border-top border-left"></td>
                <td className="border-top border-left"></td>
                <td className="border-top border-left"></td>
                <td className="border-top border-left"></td>
                <td className="border-top border-left"></td>
                <td className="border-top border-left"></td>
              </tr>
              <tr>
                <td className="border-top size-0-9 !py-1">Tot. / Total:</td>
                <td className="border-top border-left"></td>
                <td className="border-top border-left"></td>
                <td className="border-top border-left"></td>
                <td className="border-top border-left"></td>
                <td className="border-top border-left"></td>
                <td className="border-top border-left"></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="fix w-50 l-0 top-54line h-4line border-left border-top">
          <div className="flex px-1mm line-height-1-2 size-0-9">
            <span>14 &nbsp;</span>
            <div>
              Istruzioni per il pagament
              <br />
              Instructions as to payment for carriage
            </div>
          </div>
          <div className="px-1mm mt-3">
            <label>
              <input
                type="checkbox"
                defaultChecked={formData.carriagePaid}
                onChange={(e) => onChangeFormData('carriagePaid', e.target.checked)}
              />{' '}
              Carriage paid
            </label>
            <br />
            <label>
              <input
                type="checkbox"
                defaultChecked={formData.carriageForward}
                onChange={(e) => onChangeFormData('carriageForward', e.target.checked)}
              />{' '}
              Carriage forward
            </label>
          </div>
        </div>

        <div className="fix w-25 l-0 top-58line h-3line border-left border-top">
          <div className="flex px-1mm line-height-1-2 size-0-9">
            <span>21 &nbsp;</span>
            <div>
              Compilato
              <br />
              Established in
            </div>
          </div>
          <div className="input-1line" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.establishedIn}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className="h-1line read-view-text">
                  {formData.establishedIn || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'establishedIn')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-25 l-25 top-58line h-3line border-top">
          <div className="px-1mm line-height-1-2 size-0-9">
            <div>
              il
              <br />
              on
            </div>
          </div>
          <div className="input-1line">
            <InlineEditableSelect
              disabled={previewMode === false}
              value={formData.establishedOn}
              readView={formData.establishedOn || placeHolder}
              onChange={(data) => {
                onChangeFormData('establishedOn', data)
              }}
              selectComponent={<DatePicker placeholder=" " />}
            />
          </div>
        </div>

        <div className="fix w-50 l-50 top-59line h-2line border-left border-top border-right">
          <div className="px-1mm">15 &nbsp;Rimborso / Cash on delivery</div>
          <div className="input-1line" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.cashOnDelivery}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className="h-1line read-view-text">
                  {formData.cashOnDelivery || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'cashOnDelivery')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-33 l-0 top-61line h-9line border-left border-top border-bottom">
          <div className="flex px-1mm line-height-1-2 size-0-9">
            <span>22 &nbsp;</span>
            <div>
              Firma e timbro del mittent
              <br />
              Signature and stamp of the sender
            </div>
          </div>
          <div style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.signature}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="8"
                  className="overflow-hidden"
                  maxHeight={8 * 1.618 + 'em'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-8line read-view-text-aria">
                  {formData.signature || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'signature')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-33 l-33 top-61line h-9line border-left border-top border-bottom">
          <div className="flex px-1mm line-height-1-2 size-0-9">
            <span>23 &nbsp;</span>
            <div>
              Firma e timbro del trasportatore
              <br />
              Signature and stamp of the carrier
            </div>
          </div>
          <div style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.carrierSignature}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="3"
                  className="overflow-hidden"
                  maxHeight={3 * 1.618 + 'em'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-3line read-view-text-aria">
                  {formData.carrierSignature || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'carrierSignature')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>

          <div className="px-1mm flex mt-2">
            <div className="line-height-1-2 size-0-9 pr-2">
              Targa motrice
              <br />
              Truck number
            </div>
            <div className="input-2line grow" style={{ marginTop: '-8px' }}>
              <InlineEdit
                isEditing={previewMode}
                defaultValue={formData.truckNumber}
                editView={({ errorMessage, ...fieldProps }, ref) => (
                  <div style={{ marginBottom: '12px' }}>
                    <Textfield {...fieldProps} ref={ref} />
                  </div>
                )}
                readView={() => (
                  <div className="h-2line read-view-text">
                    {formData.truckNumber || placeHolder}
                  </div>
                )}
                onConfirm={onChangeFormData.bind(null, 'truckNumber')}
                hideActionButtons
                readViewFitContainerWidth
              />
            </div>
          </div>

          <div className="px-1mm flex mt-2">
            <div className="line-height-1-2 size-0-9 pr-2">
              Targa rimorchio
              <br />
              Trailer number
            </div>
            <div className="input-2line grow" style={{ marginTop: '-8px' }}>
              <InlineEdit
                isEditing={previewMode}
                defaultValue={formData.trailerNumber}
                editView={({ errorMessage, ...fieldProps }, ref) => (
                  <Textfield {...fieldProps} ref={ref} />
                )}
                readView={() => (
                  <div className="h-2line read-view-text">
                    {formData.trailerNumber || placeHolder}
                  </div>
                )}
                onConfirm={onChangeFormData.bind(null, 'trailerNumber')}
                hideActionButtons
                readViewFitContainerWidth
              />
            </div>
          </div>
        </div>

        <div className="fix w-33 l-67 top-61line h-7line border-left border-top border-right">
          <div className="flex px-1mm line-height-1-2 size-0-9">
            <span>24 &nbsp;</span>
            <div>
              Merce ricevuta
              <br />
              Goods received place
            </div>
          </div>

          <div className="input-1line" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.receivedPlace}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className="h-1line read-view-text">
                  {formData.receivedPlace || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'receivedPlace')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
          <div className="px-1mm flex mt-2">
            <div className="line-height-1-2 size-0-9 pr-2">
              il
              <br />
              on:
            </div>
            <div className="input-1line grow">
              <InlineEditableSelect
                disabled={previewMode === false}
                value={formData.receivedOn}
                readView={formData.receivedOn || placeHolder}
                onChange={(data) => {
                  onChangeFormData('receivedOn', data)
                }}
                selectComponent={<DatePicker placeholder=" " />}
              />
            </div>
          </div>
        </div>

        <div className="fix w-33 l-67 top-65line h-5line border-top-transparent border-left border-right border-bottom">
          <div style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.consigneeSignature}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="3"
                  className="overflow-hidden"
                  maxHeight={3 * 1.618 + 'em'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-3line read-view-text-aria">
                  {formData.consigneeSignature || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'consigneeSignature')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
          <div className="px-1mm size-0-9">
            Firma e timbro del destinatario
            <br />
            Signature and stamp of the consignee
          </div>
        </div>
      </div>
    </div>
  )
}

export default CMRForm
