import { useCallback, useMemo } from 'react'

//Styles
import styles from './Attachments.module.scss'

//Apis
import { uploadFileAttachment } from 'api/upload'

//Interfaces
import {
  UploadIcon,
  DocIcon,
  CrossIcon,
  useDisclosure,
  UploadModal,
} from '@royan-co/user-interface'

//components
import AttachmentsModal from './AttachmentsModal'

const Attachments = ({
  selectedFiles,
  setSelectedFiles,
  maxFiles = 5,
  fileTitleOptions,
  documents,
  useFileNameAsTitle = true,
  disableUploadButton = false,
}) => {
  const [isOpenUploadModal, uploadModalHandlers] = useDisclosure()
  const useDocumentsList = useMemo(() => documents?.list?.length > 0, [documents])

  const uploadDocuments = (files) => {
    if (files.length === 0) return

    setSelectedFiles([
      ...selectedFiles,
      ...files.map((file) => ({
        title: file.title,
        document_name: file.fileName,
        document_path: file?.uploadResponse?.path,
        size: file.humanizedSize,
        url: file?.url,
        id: file?.id,
      })),
    ])

    uploadModalHandlers.close()
  }

  const removeFile = (index) => setSelectedFiles(selectedFiles.filter((_, i) => i !== index))

  const AttachmentsDocument = useCallback(
    (props) =>
      !useDocumentsList ? (
        <UploadModal {...props} />
      ) : (
        <AttachmentsModal
          {...props}
          documents={{
            ...documents,
            list: documents.list.filter((d) => !selectedFiles.some((file) => file.id === d.id)),
          }}
          selectedFiles={selectedFiles}
        />
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [useDocumentsList, selectedFiles]
  )

  return (
    <>
      <div className={styles.attachmentFiles}>
        {selectedFiles?.map((file, index) => (
          <div className={styles.selectedFile} key={index}>
            <div style={{ width: '24px !important' }}>
              <DocIcon size={24} />
            </div>

            <div className={styles.selectedFile__details}>
              {file.url ? (
                <a
                  target="_blank"
                  href={file.url}
                  rel="noreferrer"
                  className={styles.selectedFile__name}
                >
                  {file.title}
                </a>
              ) : (
                <span className={styles.selectedFile__name}>{file.title}</span>
              )}

              <span className={styles.selectedFile__size}>{file.size}</span>
            </div>

            <div className={styles.selectedFile__actions}>
              {!file?.disable_removing && (
                <button
                  type="button"
                  onClick={() => removeFile(index)}
                  className={styles.selectedFile__removeButton}
                >
                  <CrossIcon size={16} />
                </button>
              )}
            </div>
          </div>
        ))}

        {disableUploadButton ? null : (
          <button
            type="button"
            className={styles.attachmentFiles__Button}
            onClick={() => uploadModalHandlers.open()}
          >
            <UploadIcon />
          </button>
        )}
      </div>

      <AttachmentsDocument
        maxFiles={maxFiles}
        editableFileTitle
        isFileTitleRequired
        useFileNameAsTitle={useFileNameAsTitle}
        fileTitleOptions={fileTitleOptions}
        title="Upload Attachment"
        isOpen={isOpenUploadModal}
        accept={['csv', 'xls', 'pdf', 'jpg', 'jpeg', 'png']}
        uploadRequest={(file, signal) => uploadFileAttachment(file, signal)}
        onSubmit={uploadDocuments}
        onClose={() => {
          uploadModalHandlers.close()
        }}
      />
    </>
  )
}

export default Attachments
