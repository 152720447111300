import axios from 'services/Request'

export function getConsolidations(params) {
  return axios.get('/api/consolidations', { params })
}

export function getConsolidation(consolidationIdAuto) {
  return axios.get(`/api/consolidations/fetch/${consolidationIdAuto}`)
}

export function getConsolidationById(consolidationId) {
  return axios.get(`/api/public/consolidations/data/fetch/${consolidationId}`)
}

export function getConsolidationCargo(consolidationId) {
  return axios.get(`/api/consolidations/cargo/${consolidationId}`)
}

export function deleteShareTrackingContact(contactId) {
  return axios.delete(`/api/consolidations/tracking/contacts/${contactId}`)
}

export function createShareTrackingContact(consolidationId, data) {
  return axios.post(`/api/consolidations/tracking/contacts/create/${consolidationId}`, data)
}

export function editShareTrackingContact(contactId, data) {
  return axios.post(`/api/consolidations/tracking/contacts/edit/${contactId}`, data)
}

export function shareCargoDetails(consolidationId, data) {
  return axios.post(`/api/consolidations/cargo/share/${consolidationId}`, data)
}

export function getCopyCargoDetails(consolidationId) {
  return axios.get(`/api/consolidations/cargo/${consolidationId}?copy=1`)
}

export function getConsolidationShipments(consolidationId) {
  return axios.get(`/api/consolidations/shipments/${consolidationId}`)
}

export function getConsolidationAirwaybills(consolidationId) {
  return axios.get(`/api/consolidations/awb/${consolidationId}`)
}

export function getConsolidationHistory(consolidationId) {
  return axios.get(`/api/consolidations/history/${consolidationId}`)
}

export function getConsolidationNotes(consolidationId) {
  return axios.get(`/api/consolidations/notes/${consolidationId}`)
}

export function deleteConsolidationNote(noteId) {
  return axios.delete(`/api/consolidations/notes/${noteId}`)
}

export function createConsolidationNote(consolidationId, data) {
  return axios.post(`/api/consolidations/notes/create/${consolidationId}`, data)
}

export function editConsolidationNote(noteId, data) {
  return axios.post(`/api/consolidations/notes/edit/${noteId}`, data)
}

export function getConsolidationDocuments(consolidationId) {
  return axios.get(`/api/consolidations/documents/${consolidationId}`)
}

export function uploadDocument(formData, consolidationId) {
  return axios.post(`/api/consolidations/documents/upload/${consolidationId}`, formData)
}

export function deleteConsolidationDocument(documentId) {
  return axios.delete(`/api/consolidations/documents/${documentId}`)
}

export function reportConsolidations({ filters }) {
  return axios.post('/api/reports/accounting/consolidations', { filters })
}

export function downloadConsolidationManifest(consolidationId, type) {
  return axios.get(`/api/consolidations/manifest/${consolidationId}/${type}`, {
    responseType: 'arraybuffer',
  })
}

export function startConsolidationLoading(consolidationId) {
  return axios.get(`/api/consolidations/loading/start/${consolidationId}`)
}

export function getConsolidationLoadingDetails(consolidationId) {
  return axios.get(`/api/consolidations/loading/${consolidationId}`)
}

export function loadingConsolidationEntrance(consolidationId, entranceId) {
  return axios.get(`/api/consolidations/loading/load/${consolidationId}/${entranceId}`)
}

export function finishConsolidationLoadingDetails(consolidationId) {
  return axios.get(`/api/consolidations/loading/finish/${consolidationId}`)
}

export function deleteShipmentFromConsolidation(consolidationId, shipmentId) {
  return axios.delete(`/api/consolidations/shipments/${consolidationId}/${shipmentId}`)
}

export async function createConsolidation(data) {
  return axios.post('/api/consolidations/create', data)
}

export function startConsolidationTrip(consolidationId) {
  return axios.get(`/api/consolidations/start/${consolidationId}`)
}

export function finishConsolidationTrip(consolidationId) {
  return axios.get(`/api/consolidations/finish/${consolidationId}`)
}

export function getConsolidationCargowizPlanner(consolidationId) {
  return axios.get(`/api/consolidations/cargowiz/${consolidationId}`)
}

export function editConsolidationParam(consolidationId, attribute, data) {
  return axios.post(
    `/api/consolidations/update/${consolidationId}${attribute ? `/${attribute}` : ''}`,
    data
  )
}

export function getTrackingRoutes(consolidationId) {
  return axios.get(`api/consolidations/tracking/${consolidationId}`)
}

export function exportTemperatureReport(format, id_auto) {
  return axios.get(
    `/api/consolidations/tracking/temperatures/export/${id_auto}/${format}`,
    format === 'pdf' && {
      responseType: 'blob',
    }
  )
}

export function addLocalisationConsolidation(consolidationId, data) {
  return axios.post(`api/consolidations/tracking/create/${consolidationId}`, data)
}

export function addPublicLocalisationConsolidation(consolidationId, data) {
  return axios.post(`/api/public/consolidations/tracking/create/${consolidationId}`, data)
}

export function getConsolidationShares(consolidationId) {
  return axios.get(`/api/consolidations/shares/${consolidationId}`)
}

export function shareConsolidation(consolidationId, data) {
  return axios.post(`/api/consolidations/shares/create/${consolidationId}`, data)
}

export function removeShareConsolidation(consolidationId, registryId, sharedRegistryId) {
  return axios.delete(
    `/api/consolidations/shares/${consolidationId}/${registryId}/${sharedRegistryId}`
  )
}

export function requestConsolidationTrackingLocalisation(consolidationId) {
  return axios.get(`/api/consolidations/tracking/request-update/${consolidationId}`)
}

export function deleteConsolidation(id) {
  return axios.delete(`/api/consolidations/${id}`)
}

export async function getConsolidationReferenceAutocomplete(term, controller) {
  return axios.post(
    '/api/consolidations/autocomplete',
    { term },
    {
      signal: controller?.signal,
    }
  )
}

export function getFinanceDetails(consolidationId) {
  return axios.get(`/api/consolidations/finance/${consolidationId}`)
}

export function editConsolidationFinance(consolidationId, data) {
  return axios.post(`api/consolidations/finance/edit/${consolidationId}`, data)
}

export function getDefaultsForEditingConsolidationFinance(consolidationId) {
  return axios.get(`/api/consolidations/finance/edit/${consolidationId}`)
}

export function createDocument(consolidationId, data) {
  return axios.post(`/api/consolidations/documents/create/${consolidationId}`, data)
}

export async function getTrackingUnitsAutocomplete(term, controller) {
  return axios.post(
    'api/means/tracking-units/autocomplete',
    { term },
    {
      signal: controller?.signal,
    }
  )
}

export function linkTrackingUnit(meanId, data) {
  return axios.post(`api/means/tracking-units/link/${meanId}`, data)
}

export function unlinkTrackingUnit(meanId, trackingUnitId) {
  return axios.get(`api/means/tracking-units/unlink/${meanId}/${trackingUnitId}`)
}

export async function downloadIataLabels(consolidationId) {
  return axios.get(`api/consolidations/documents/labels/${consolidationId}`, {
    responseType: 'arraybuffer',
  })
}
