import React, { useEffect, useMemo } from 'react'
import { getFilled } from 'utils/helpers'
import { Helmet } from 'react-helmet-async'
import { useMediaQuery } from 'react-responsive'
import { handleErrorOnSubmit } from 'utils/errors'
import { createRegistry, editRegistry } from 'api/registries'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'

//Hooks
import useRegistry from 'hooks/useRegistry'

//Contexts
import { RegistryCreationProvider } from 'contexts/RegistryCreationProvider'
import { useAuth } from 'contexts/AuthProvider'

//Atlassian
import Spinner from '@atlaskit/spinner'
import PageHeader from '@atlaskit/page-header'
import SectionMessage from '@atlaskit/section-message'
import Button, { LoadingButton } from '@atlaskit/button'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'

//Interfaces
import { Form, Grid, useFlags, WarningBannerField } from '@royan-co/user-interface'

//Components
import RegistryCreatorCard from 'components/Registries/View/RegistryCreatorCard'
import RegistryCreationExistenceChecker from 'components/Registries/Create/ExistenceChecker'
import RegistryCreationGeneralInformationFields from 'components/Registries/Create/GeneralInformation'

const RegistryCreationPage = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { currentUser } = useAuth()
  const { registryId, creatorId } = useParams()
  const { showSuccessFlag, showWarningFlag } = useFlags()
  const registryCreationMutation = useMutation(
    !registryId ? createRegistry : editRegistry.bind(null, registryId)
  )
  const isMobile = useMediaQuery({ query: `(max-width: 991.98px)` })

  const pageTitle = registryId ? 'Edit registry' : 'New registry'

  const { registry, isError, error, refetch, isLoading, isFetching, creator, invalidate } =
    useRegistry(registryId, {
      creatorId: creatorId,
      enabled: !!registryId,
    })

  const accessToEditCode = useMemo(
    () =>
      registryId && ['master', 'admin', 'accounting'].includes(currentUser?.user_level_name?.name),
    [currentUser, registryId]
  )

  const defaultValues = useMemo(() => {
    return {
      bank: getFilled(registry, 'bank', null),
      swift: getFilled(registry, 'swift', ''),
      website: getFilled(registry, 'website', ''),
      pec_code: getFilled(registry, 'pec_code', ''),
      iata_code: getFilled(registry, 'iata_code', ''),
      vat_number: getFilled(registry, 'vat_number', ''),
      f_f_number: getFilled(registry, 'f_f_number', ''),
      fiscal_code: getFilled(registry, 'fiscal_code', ''),
      company_name: getFilled(registry, 'company_name', ''),
      entity_type: getFilled(registry, 'entity_type', null),
      pec_invoicing: getFilled(registry, 'pec_invoicing', ''),
      share_capital: getFilled(registry, 'share_capital', ''),
      carrier_r_number: getFilled(registry, 'carrier_r_number', ''),
      freight_forwarder: getFilled(registry, 'freight_forwarder', null),
      
      numerator_code: getFilled(creator, 'numerator_code', ''),
      type: getFilled(creator, 'type', null),
      list_type: getFilled(creator, 'list_type', null),
      client_type: getFilled(creator, 'client_type', null),
      payment_code: getFilled(creator, 'payment_code', null),
    }
  }, [registry, creator])

  const submit = (data, { setError }) => {
    const registryData = {
      ...data,
    }

    if (registryId) {
      registryData.user_id = creator?.user?.auto_id
    }

    if (registryData.freight_forwarder === 0) {
      delete registryData.f_f_number
      delete registryData.iata_code
      delete registryData.carrier_r_number
    }

    if (!accessToEditCode) delete registryData.numerator_code

    registryCreationMutation.mutate(registryData, {
      onError: (e) => {
        handleErrorOnSubmit(e, setError, data)
      },
      onSuccess: (res) => {
        invalidate()
        showSuccessFlag(res?.message)

        if (registryId) {
          navigate(`/panel/registries/${registryId}${creatorId ? `/${creatorId}` : ''}`)
        } else {
          navigate('/panel/registries')
        }
      },
    })
  }

  useEffect(() => {
    if (error?.response?.status === 404) {
      queryClient.cancelQueries({ queryKey: ['registry', registryId], exact: false })
      showWarningFlag('Registry is not found!')
      navigate('/panel/registries', { replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  return (
    <RegistryCreationProvider>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <Grid gutter={24} className="w-full">
        <Grid.Col md={9} lg={6} offsetMd={3}>
          <PageHeader
            breadcrumbs={
              <Breadcrumbs>
                <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
                <BreadcrumbsItem text="Companies" />
                <BreadcrumbsItem text="Registries" to="/panel/registries" component={Link} />
              </Breadcrumbs>
            }
          >
            {pageTitle}
          </PageHeader>
        </Grid.Col>
      </Grid>

      {!!registryId && (isLoading || isFetching) ? (
        <div className="text-center my-8">
          <Spinner />
        </div>
      ) : isError ? (
        <Grid gutter={24} className="w-full">
          <Grid.Col md={6} offsetMd={3}>
            <SectionMessage
              appearance="warning"
              title="Something wrong on loading registry data, please retry"
            >
              <Button onClick={() => refetch()}>Retry</Button>
            </SectionMessage>
          </Grid.Col>
        </Grid>
      ) : (
        <Form defaultValues={defaultValues} onSubmit={submit}>
          {() => (
            <Grid gutter={24} className="w-full" mb={64}>
              {!isMobile && (
                <Grid.Col md={3}>
                  <div
                    className="sticky float-right w-full"
                    style={{ top: '2rem', maxWidth: '320px' }}
                  >
                    {!!registryId && registry?.owners?.length > 1 && (
                      <RegistryCreatorCard className="mb-4" isEditPage={true} />
                    )}

                    <RegistryCreationExistenceChecker />
                  </div>
                </Grid.Col>
              )}

              <Grid.Col md={8} lg={6}>
                <RegistryCreationGeneralInformationFields
                  creator={creator}
                  accessToEditCode={accessToEditCode}
                />

                <WarningBannerField />

                <div className="mt-6 text-right">
                  <LoadingButton
                    type="submit"
                    appearance="primary"
                    isLoading={registryCreationMutation.isLoading}
                  >
                    {registryId ? 'Update' : 'Create'} Registry
                  </LoadingButton>
                </div>
              </Grid.Col>
            </Grid>
          )}
        </Form>
      )}
    </RegistryCreationProvider>
  )
}

export default RegistryCreationPage
