import { useQuery, useQueryClient } from '@tanstack/react-query'

// Apis
import { getShipmentContainer } from 'api/shipments'

const useShipmentContainers = (shipmentId) => {
  const queryKey = ['shipment', 'container', shipmentId]

  return useQuery({
    queryKey,
    queryFn: () => getShipmentContainer(shipmentId),
    retry: 2,
    staleTime: Infinity,
  })
}

export const useUpdateContainers = (shipmentId) => {
  const queryClient = useQueryClient()

  const upsertContainer = (container) => {
    queryClient.setQueryData(['shipment', 'container', shipmentId], (containers = []) => {
      const isExist = containers.some((c) => c?.id === container?.id)
      return isExist
        ? containers.map((c) => (c?.id === container?.id ? container : c))
        : [container, ...containers]
    })
  }

  const deleteContainerFromData = (containerId) => {
    queryClient.setQueryData(['shipment', 'container', shipmentId], (containers = []) =>
      containers.filter((c) => c.id !== containerId)
    )
  }

  return { upsertContainer, deleteContainerFromData }
}

export default useShipmentContainers
