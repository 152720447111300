import { useCallback } from 'react'
import { useMemo } from 'react'

//Hooks
import useContactAutocomplete from 'hooks/useContactAutocomplete'

//Atlassian
import { ErrorMessage } from '@atlaskit/form'

//Interfaces
import { Field, useFormContext } from '@royan-co/user-interface'

//Components
import ContactSelect from './ContactSelect'

const findOption = (options, value) => {
  return options.find(
    (o) => o?.value?.email === value?.email && o?.value?.id_auto === value?.id_auto
  )
}

const ContactFieldTo = ({
  options = [],
  isLoading,
  onValueChange,
  isDisabled,
  className,
  onCreateNewContact,
}) => {
  const { contactAutocompleteData, setTermContactAutocomplete, isLoadingContactAutocomplete } =
    useContactAutocomplete()
  const { getValues } = useFormContext()
  const autocomplete = useMemo(() => !(options?.length > 0), [options])

  const createOptions = useCallback((contacts) => {
    if (!contacts) return []

    return contacts?.map((contact) => ({
      label: contact?.full_name + ' (' + contact?.email + ')',
      value: { id_auto: contact.id_auto, email: contact?.email },
    }))
  }, [])

  const fieldOptions = useMemo(
    () => createOptions(autocomplete ? contactAutocompleteData : options),
    [createOptions, autocomplete, contactAutocompleteData, options]
  )

  const isLoadingOptions = useMemo(
    () => (autocomplete ? isLoadingContactAutocomplete : isLoading),
    [autocomplete, isLoading, isLoadingContactAutocomplete]
  )

  const handleTransformOutput = (opt) => {
    if (opt?.value !== getValues('to')) {
      onValueChange?.(opt?.value)
    }
    return opt?.value !== undefined ? opt.value : null
  }

  return (
    <Field
      isRequired
      name="to"
      label="To"
      type="select"
      transform={{
        input: (v) => findOption(fieldOptions, v),
        output: (opt) => handleTransformOutput(opt),
      }}
      isDisabled={isDisabled}
      className={className}
    >
      {({ fieldProps, error }) => (
        <>
          <ContactSelect
            isClearable={false}
            placeholder="Choose"
            options={fieldOptions}
            isLoading={isLoadingOptions}
            onInputChange={(newValue) => {
              if (autocomplete) setTermContactAutocomplete(newValue)
            }}
            onCreateNewContact={onCreateNewContact}
            {...fieldProps}
          />
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </>
      )}
    </Field>
  )
}

export default ContactFieldTo
