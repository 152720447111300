import { createPortal } from 'react-dom'
import styles from './LeftMobileSidebar.module.scss'

const LeftMobileSidebar = ({ isOpen, children }) => {
  return createPortal(
    <div
      className={[styles.leftMobileSidebar, isOpen ? styles['leftMobileSidebar--isOpen'] : ''].join(
        ' '
      )}
    >
      {children}
    </div>,
    document.body
  )
}

export default LeftMobileSidebar
