import { useState, useEffect, useCallback, useRef } from 'react'
import axios from 'axios'

// Apis
import { getRegistriesAutocomplete } from 'api/registries'

// Interfaces
import { useDebounce } from '@royan-co/user-interface'

const registryTypes = {
  client: 0,
  supplier: 1,
}

const useRegistryAutocomplete = ({ registryType, isImported, isActive }) => {
  const controller = useRef()
  const [registryAutocompleteOptions, setRegistryAutocompleteOptions] = useState([])
  const [term, setTerm] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const debouncedTerm = useDebounce(term)

  const getOptions = useCallback(async () => {
    try {
      setIsLoading(true)
      if (controller.current) {
        controller.current.abort()
      }

      controller.current = new AbortController()
      const data = await getRegistriesAutocomplete(
        debouncedTerm,
        registryTypes?.[registryType],
        controller.current
      )
      const _options = data
        .filter((item) => (isImported ? item.imported : true))
        .filter((item) => (isActive ? !item.deactivated : true))
        .map((item) => ({
          label: item.company_name,
          value: item.id_auto,
          imported: item?.imported,
          logo: item?.logo,
        }))

      setRegistryAutocompleteOptions(_options)
      setIsLoading(false)
    } catch (error) {
      if (!axios.isCancel(error)) {
        setIsLoading(false)
        console.error(error)
      }
    }
  }, [debouncedTerm, registryType])

  useEffect(() => {
    if (debouncedTerm) getOptions()
  }, [debouncedTerm, getOptions])

  return {
    registryAutocompleteOptions,
    setTermRegistryAutocomplete: setTerm,
    isLoadingRegistryAutocomplete: isLoading,
  }
}

export default useRegistryAutocomplete
