import axios from 'services/Request'

export function getCurrencies(params) {
  return axios.get('/api/accounting/currencies?paginate=1', { params })
}

export function getCurrency(currencyCode) {
  return axios.get(`/api/accounting/currencies/${currencyCode}`)
}

export function createCurrency(data) {
  return axios.post('/api/accounting/currencies', data)
}

export function editCurrency(currencyCode, data) {
  return axios.put(`/api/accounting/currencies/${currencyCode}`, data)
}

export function deleteCurrency(currencyCode) {
  return axios.delete(`/api/accounting/currencies/${currencyCode}`)
}
