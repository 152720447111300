import { useMemo } from 'react'
import { modes } from './modes'
import { capitalizeFirstLetter, findOption } from 'utils/helpers'
import useShipmentsEnumerations from 'hooks/useShipmentsEnumerations'

//Atlassian
import Lozenge from '@atlaskit/lozenge'
import { DynamicTableStateless } from '@atlaskit/dynamic-table'

//Interfaces
import { useWizardFormContext } from '@royan-co/user-interface'

const packagesHeads = {
  cells: [
    { key: 'type', content: 'Type' },
    { key: 'quantity', content: 'Quantity' },
    { key: 'reefer', content: 'Temperature control' },
  ],
}

const ShipmentCreationDedicatedSummary = () => {
  const {
    stepsValue: { routing, cargo },
  } = useWizardFormContext()
  const { getEnumerationOptionsByKey } = useShipmentsEnumerations()

  const mode = useMemo(() => modes.find((m) => m.id === routing?.modeType), [routing?.modeType])

  const packagesRows = cargo?.[mode.cargoKey].map((item, i) => ({
    key: `item${i}`,
    cells: [
      {
        key: `type${i}`,
        content: (
          <div className="mt-2">
            {findOption(getEnumerationOptionsByKey(mode.cargoTypeKey), item.box_type)?.label}
          </div>
        ),
      },
      {
        key: `quantity${i}`,
        content: <div className="mt-2">{item?.quantity || 0}</div>,
      },
      {
        key: `reefer${i}`,
        content: (
          <div className="mt-2">
            {item.reefer ? (
              <>
                <Lozenge appearance="moved">yes</Lozenge>
                <small style={{ color: '#6B778C', marginLeft: '.5rem' }}>
                  {item?.reefer_temperature?.map((t) => (t > 0 ? `+${t}` : t)).join(', ')}
                  {' °C'}
                </small>
              </>
            ) : (
              <Lozenge appearance="default">no</Lozenge>
            )}
          </div>
        ),
      },
    ],
  }))

  return (
    <div className="mt-8">
      <div className="flex justify-between items-center">
        <h3>{capitalizeFirstLetter(mode.cargoKey)}</h3>
      </div>

      <div className="shadow rounded p-4 mt-2">
        {packagesRows.length === 0 ? (
          <div className="text-xs text-neutral-200">You don't have any {mode.cargoKey}</div>
        ) : (
          <div className="inline-block w-full -mb-6 removeAtlaskitTableBorder">
            <DynamicTableStateless head={packagesHeads} rows={packagesRows} />
          </div>
        )}
      </div>
    </div>
  )
}

export default ShipmentCreationDedicatedSummary
