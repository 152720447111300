import { useRef } from 'react'

// APIS
import { uploadFileAttachment } from 'api/upload'

// Atlassian
import Button from '@atlaskit/button'
import Textfield from '@atlaskit/textfield'
import Modal, {
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
  ModalBody,
} from '@atlaskit/modal-dialog'

// interfaces
import {
  Field,
  Form,
  UploadProvider,
  UploadFileButton,
  WarningBannerField,
  UploadFilesPreviewGrid,
  useUploadContext,
} from '@royan-co/user-interface'

// Icons
import { PhotoIcon } from '@royan-co/user-interface'

const maxSize = 20
const maxFiles = 10
const accept = ['jpeg', 'jpg', 'png']

const ReportDamageModal = ({ isOpen, cargo, onClose, onSubmit }) => {
  const uploadRef = useRef()

  const defaultValues = {
    deformed_pieces: cargo?.deformed_pieces || '',
  }

  const handleSubmit = (data, { setError }) => {
    const _data = { ...data }
    _data.files = uploadRef.current.getFiles()

    if (_data.files.length === 0) {
      setError('unknown', { type: 'api', message: 'please select at least 1 photo.' })
      return
    }

    onSubmit(_data)
  }

  return (
    <ModalTransition>
      {isOpen && (
        <Modal
          width={'small'}
          onClose={onClose}
          shouldCloseOnEscapePress={false}
          shouldCloseOnOverlayClick={false}
        >
          <ModalHeader>
            <ModalTitle>Report damage</ModalTitle>
          </ModalHeader>

          <Form onSubmit={handleSubmit} defaultValues={defaultValues}>
            {() => (
              <UploadProvider
                ref={uploadRef}
                maxFiles={maxFiles}
                defaultFiles={cargo?.damage_files || []}
                uploadRequest={(file, signal) => uploadFileAttachment(file, signal)}
              >
                <ModalBody>
                  <Field
                    isRequired
                    label="No. of pieces"
                    className="!mt-0 mb-4"
                    name="deformed_pieces"
                  >
                    {({ fieldProps }) => (
                      <Textfield {...fieldProps} placeholder="Type the number of pieces" />
                    )}
                  </Field>

                  <UploadFileButton
                    accept={accept}
                    maxSize={maxSize}
                    shouldFitContainer
                    className="!text-left"
                    iconBefore={<PhotoIcon />}
                  >
                    Upload or take a picture of damage
                  </UploadFileButton>

                  <UploadFilesPreviewGrid className="mt-4" />

                  <WarningBannerField />
                </ModalBody>

                <ReportDamageModalFooter onClose={onClose} />
              </UploadProvider>
            )}
          </Form>
        </Modal>
      )}
    </ModalTransition>
  )
}

const ReportDamageModalFooter = ({ onClose }) => {
  const { uploadState } = useUploadContext()

  return (
    <ModalFooter>
      <Button appearance="subtle" onClick={() => onClose()}>
        Discard
      </Button>

      <Button appearance="primary" type="submit" isDisabled={uploadState !== 'idle'}>
        Submit
      </Button>
    </ModalFooter>
  )
}

export default ReportDamageModal
