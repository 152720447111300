import { useMemo } from 'react'
import { findOption } from 'utils/helpers'
import useUserLevels from 'hooks/useUserLevels'
import { useMediaQuery } from 'react-responsive'

//Atlassian
import { LoadingButton } from '@atlaskit/button'
import Drawer from '@atlaskit/drawer'
import Select from '@atlaskit/select'
import Toggle from '@atlaskit/toggle'
import TextField from '@atlaskit/textfield'
import { ErrorMessage, Label } from '@atlaskit/form'

//Interfaces
import { useGeoInfo, Field, Form, PhoneInput, WarningBannerField } from '@royan-co/user-interface'

const ContactDrawer = ({
  onClose,
  isOpen,
  data,
  onSubmit,
  branches = [],
  isSubmitting = false,
}) => {
  const { geoInfo } = useGeoInfo()
  const isMobile = useMediaQuery({ query: `(max-width: 991.98px)` })
  const { userLevelsOptions, isLoadingUserLevels } = useUserLevels()

  const isEditting = useMemo(() => data?.country_iso !== undefined, [data])

  const branchOptions = useMemo(
    () =>
      branches.map((b, i) => ({
        value: b.uuid,
        label: [b.city, b.cap, b.country_iso].filter((i) => i).join(', '),
      })),
    [branches]
  )

  return (
    <Drawer isOpen={isOpen} onClose={onClose} width={isMobile ? 'full' : 'medium'}>
      <div className="drawer">
        <h3>{isEditting ? 'Edit contact' : 'New contact'}</h3>

        <Form onSubmit={onSubmit} defaultValues={data}>
          {() => (
            <>
              {branches.length > 1 && (
                <Field
                  isRequired
                  type="select"
                  name="branch_uuid"
                  className="!mt-4"
                  label="Related branch"
                  transform={{
                    input: (v) => findOption(branchOptions, v),
                    output: (opt) => (opt?.value !== undefined ? opt.value : null),
                  }}
                >
                  {({ fieldProps, error }) => (
                    <>
                      <Select options={branchOptions} placeholder="Choose one" {...fieldProps} />
                      {error && <ErrorMessage>{error}</ErrorMessage>}
                    </>
                  )}
                </Field>
              )}

              <Field isRequired name="name" label="First name" className="!mt-4" defaultValue="">
                {({ fieldProps, error }) => (
                  <>
                    <TextField
                      {...fieldProps}
                      autoComplete="off"
                      placeholder="Type first name here"
                    />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>

              <Field isRequired name="surname" label="Last name" className="!mt-4" defaultValue="">
                {({ fieldProps, error }) => (
                  <>
                    <TextField
                      {...fieldProps}
                      autoComplete="off"
                      placeholder="Type last name here"
                    />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>

              <Field isRequired name="email" label="Email" className="!mt-4" defaultValue="">
                {({ fieldProps, error }) => (
                  <>
                    <TextField {...fieldProps} type="email" placeholder="Type email here" />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>

              <Field
                isRequired
                label="Role"
                type="select"
                className="!mt-4"
                name="user_level_name_id"
                transform={{
                  input: (v) => findOption(userLevelsOptions, v),
                  output: (opt) => (opt?.value !== undefined ? opt.value : null),
                }}
              >
                {({ fieldProps, error }) => (
                  <>
                    <Select
                      placeholder="Choose one"
                      options={userLevelsOptions}
                      isLoading={isLoadingUserLevels}
                      {...fieldProps}
                    />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>

              <Field
                label="Mobile phone"
                name="cellphone"
                className="!mt-4"
                transform={{
                  input: (v) => ({
                    value: v?.value || `${data?.cellphone_prefix || ''}${data?.cellphone || ''}`,
                  }),
                  output: (opt) => opt,
                }}
              >
                {({ fieldProps: { ref, ...fieldProps }, error }) => (
                  <>
                    <PhoneInput
                      defaultCountry={geoInfo?.countryCode?.toLowerCase()}
                      {...fieldProps}
                    />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>

              <Field name="telephone_int" label="Extension" className="!mt-4" defaultValue="">
                {({ fieldProps, error }) => (
                  <>
                    <TextField
                      {...fieldProps}
                      autoComplete="off"
                      placeholder="Type extension here"
                    />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>

              <div className="mt-6">
                <h5 className="font-semibold">Preferences</h5>

                {Object.entries({
                  reminder: 'Reminders',
                  tracking: 'Tracking',
                  whatapp: 'Whatsapp',
                  unsubscribe: 'Mailing',
                }).map(([key, title]) => (
                  <Field type="checkbox" name={key} defaultValue={true} key={`toggle-${key}`}>
                    {({ fieldProps, error }) => (
                      <>
                        <div className="flex justify-between items-center">
                          <Label htmlFor={`toggle-${key}`} children={title} />
                          <Toggle {...fieldProps} size="large" id={`toggle-${key}`} />
                        </div>
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                      </>
                    )}
                  </Field>
                ))}
              </div>

              <WarningBannerField />

              <LoadingButton
                type="submit"
                className="mt-6"
                shouldFitContainer
                appearance="primary"
                isLoading={isSubmitting}
              >
                save
              </LoadingButton>
            </>
          )}
        </Form>
      </div>
    </Drawer>
  )
}

export default ContactDrawer
