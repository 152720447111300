//Components
import Card from 'components/UI/Card'
import TripleFieldsRegistry from 'components/Shipments/View/TripleFieldsRegistry'
import { registries } from './registries'

const receivingAgentFields = registries.receiving_agent_fields

const ShipmentViewEditRegistriesReceivingAgent = () => {
  return (
    <Card className="w-full my-6">
      <TripleFieldsRegistry
        className="mt-0"
        names={receivingAgentFields.names}
        labels={receivingAgentFields.labels}
      />
    </Card>
  )
}

export default ShipmentViewEditRegistriesReceivingAgent
