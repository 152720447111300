import { useMemo } from 'react'

//Atlassian
import Lozenge from '@atlaskit/lozenge'

const TableItem = ({ condition, children }) => {
  const data = useMemo(() => (children ? children : condition), [children, condition])

  return <>{condition ? data : <Lozenge appearance="default">Not set</Lozenge>}</>
}

export default TableItem
