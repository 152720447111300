import { useCallback, useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useNavigate } from 'react-router-dom'

//Atlassian
import PageHeader from '@atlaskit/page-header'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'
import Button, { ButtonGroup } from '@atlaskit/button'

//Contexts
import { useApp } from 'contexts/AppProvider'

//Interfaces
import { RoyanTable } from '@royan-co/user-interface'

//Api
import { getInvoices, reportInvoices } from 'api/invoices'
import { exportData } from 'api/exports'

//Components
import DenyFromUserLevel from 'layouts/DenyFromUserLevel'
import { invoicesFilters } from 'components/Invoices/List/filters'
import { generateInvoicesColumns } from 'components/Invoices/List/columns'
import InvoicesFilterFields from 'components/Invoices/List/FilterFields'

// Features
import { invoiceTypes } from 'features/invoice'

const InvoicesListPage = ({ type } = { type: 'active' }) => {
  const { setIsExportExcel } = useApp()
  const navigate = useNavigate()
  const _type = invoiceTypes.find((t) => t.name === type)

  const handleDoubleClick = (e, item) => {
    navigate(`${_type.pathList}/${item.id_auto}`)
  }

  const exports = useMemo(() => {
    const _exports = [
      {
        title: 'Export as .xlsx',
        request: (filters, columns) => exportData({ table: 'invoices', filters, columns }),
      },
    ]

    if (_type.name === 'active')
      _exports.push({
        title: 'Export financial balance',
        request: (filters, columns) => reportInvoices({ filters, columns }),
      })

    return _exports
  }, [_type])

  const InvoicesTable = useCallback(
    () => (
      <RoyanTable
        sortOrder="DESC"
        name={_type.key}
        sortKey={'invoice_date'}
        fixFirstColumn={true}
        onRowDoubleClick={handleDoubleClick}
        filters={invoicesFilters}
        columns={generateInvoicesColumns(_type)}
        filterFields={<InvoicesFilterFields type={_type.name} />}
        request={(params) => getInvoices({ ...params, ..._type.requestParams })}
        onExportSuccess={() => setIsExportExcel(true)}
        exports={exports}
      />
    ),
    [_type]
  )

  return (
    <DenyFromUserLevel level="warehouse">
      <Helmet>
        <title>{_type.title}</title>
      </Helmet>

      <PageHeader
        breadcrumbs={
          <Breadcrumbs>
            <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
            <BreadcrumbsItem text="Accounting" />
          </Breadcrumbs>
        }
        actions={
          <ButtonGroup>
            <Button
              appearance="primary"
              component={Link}
              to={`/panel/${_type.name}-invoices/create`}
            >
              New invoice
            </Button>
          </ButtonGroup>
        }
      >
        {_type.title}
      </PageHeader>

      <InvoicesTable />
    </DenyFromUserLevel>
  )
}

export default InvoicesListPage
