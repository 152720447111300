import { useMutation } from '@tanstack/react-query'

// Apis
import { updateBooking } from './stock-requests'

// Interfaces
import { useFlags, useRoyanTable } from '@royan-co/user-interface'

const useBookingStock = (waybillId) => {
  const { updateData } = useRoyanTable()
  const { showSuccessFlag } = useFlags()

  return useMutation({
    mutationFn: () => updateBooking(waybillId),

    onSuccess: (res) => {
      showSuccessFlag(res.message)
      updateData(null, (oldData) => oldData.map((w) => (w.id === res.waybill.id ? res.waybill : w)))
    },

    onError: (e) => {
      console.error(e)
    },
  })
}

export { useBookingStock }
