import { useMemo } from 'react'

import { findOption } from 'utils/helpers'
import { formatNumber, formatMonetaryNumber } from 'utils/numbers'
import useShipmentsEnumerations from 'hooks/useShipmentsEnumerations'

//Atlassian
import Button from '@atlaskit/button'
import Spinner from '@atlaskit/spinner'
import { DynamicTableStateless } from '@atlaskit/dynamic-table'
import Lozenge from '@atlaskit/lozenge'
import Modal, {
  ModalHeader,
  ModalFooter,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog'

//Components
import RateCostFormat from '../CostFormat'
import RatesStatusTag from '../StatusTag'

const RatesRecordsStepsModal = ({ isOpen, onClose, record }) => {
  const calculationMethod = useMemo(() => record?.table_rate?.calculation_method, [record])
  const isContainer = [6, 7, 8, 9, 15, 16].includes(record?.table_rate?.from_to) ? true : false

  const { getEnumerationOptionsByKey, isLoadingShipmentEnumeration } = useShipmentsEnumerations({
    enabled: calculationMethod === 6,
  })

  const tableHead = useMemo(() => {
    let tableHeadItems = []

    if (calculationMethod === 1 || calculationMethod === 2)
      tableHeadItems.push({ content: 'From (Kgs)' }, { content: 'To (Kgs)' })

    if (calculationMethod === 3)
      tableHeadItems.push({ content: 'From (EUR)' }, { content: 'To (EUR)' })

    if (calculationMethod === 5)
      tableHeadItems.push({ content: 'From (Pkgs)' }, { content: 'To (Pkgs)' })

    if (calculationMethod === 6) {
      if (isContainer) tableHeadItems.push({ content: 'Container type' })
      else tableHeadItems.push({ content: 'Truck type' })
    }

    tableHeadItems.push({ content: 'Rate' })

    if (calculationMethod !== 6) {
      tableHeadItems.push({ content: 'Fix' }, { content: 'Fix more(!)' })
    }

    tableHeadItems.push({ content: 'Discount' })

    tableHeadItems.push({ content: 'Valid cargo' })

    return {
      cells: tableHeadItems,
    }
  }, [calculationMethod, isContainer])

  const tableRow = useMemo(
    () =>
      record?.brackets?.map((bracket) => {
        let tableRowItems = []

        if ([1, 2, 3, 5].includes(calculationMethod))
          tableRowItems.push(
            {
              content: bracket?.from ? (
                formatNumber(bracket?.from)
              ) : (
                <Lozenge appearance="default">Not set</Lozenge>
              ),
            },
            {
              content: bracket?.to ? (
                formatNumber(bracket?.to)
              ) : (
                <Lozenge appearance="default">Not set</Lozenge>
              ),
            }
          )

        if (calculationMethod === 6)
          tableRowItems.push({
            content: isLoadingShipmentEnumeration ? (
              <Spinner size="small" />
            ) : (
              findOption(
                getEnumerationOptionsByKey(isContainer ? 'container_types' : 'truck_types'),
                `${bracket?.transportation_unit_id}`
              )?.label
            ),
          })

        tableRowItems.push({
          content: <RateCostFormat value={bracket?.price} />,
        })

        if (calculationMethod !== 6)
          tableRowItems.push(
            {
              content: <RatesStatusTag value={bracket?.fix} />,
            },
            {
              content: bracket?.fix_more ? (
                formatMonetaryNumber(bracket?.fix_more)
              ) : (
                <Lozenge appearance="default">Not set</Lozenge>
              ),
            }
          )

        tableRowItems.push({
          content: bracket?.discount ? (
            <span>
              {formatMonetaryNumber(bracket?.discount)}
              {bracket?.discount_calculation_method === 0
                ? '(%)'
                : bracket?.discount_calculation_method === 1
                ? '(Kgs)'
                : ''}
            </span>
          ) : (
            <Lozenge appearance="default">Not set</Lozenge>
          ),
        })

        tableRowItems.push({
          content:
            bracket?.d_v_n === 0 ? (
              <Lozenge appearance="moved">Only Dense</Lozenge>
            ) : bracket?.d_v_n === 1 ? (
              <Lozenge appearance="moved">Only Voluminous</Lozenge>
            ) : bracket?.d_v_n === 2 ? (
              <Lozenge appearance="moved">Only normal</Lozenge>
            ) : (
              <Lozenge appearance="success">All</Lozenge>
            ),
        })

        return {
          cells: tableRowItems,
        }
      }),
    [
      record?.brackets,
      calculationMethod,
      isLoadingShipmentEnumeration,
      getEnumerationOptionsByKey,
      isContainer,
    ]
  )

  return (
    <>
      <ModalTransition>
        {isOpen && (
          <Modal width="large" onClose={onClose}>
            <ModalHeader>
              <ModalTitle>{record?.description} - Steps </ModalTitle>
            </ModalHeader>

            <div className="px-6">
              {record?.brackets?.length > 0 ? (
                <div className="overflow-x-auto inline-block w-full -mb-6">
                  <DynamicTableStateless head={tableHead} rows={tableRow} />
                </div>
              ) : (
                <div className="text-s text-neutral-200">There are no steps!</div>
              )}
            </div>

            <ModalFooter>
              <Button appearance="subtle" onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </>
  )
}

export default RatesRecordsStepsModal
