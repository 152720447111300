import { findOption } from 'utils/helpers'
import { shipmentTemperatureOptions } from './constants'

//Atlassian
import Lozenge from '@atlaskit/lozenge'

const TemperatureTag = ({ reefer, temperature }) => {
  const option = findOption(shipmentTemperatureOptions, reefer || 0)
  return option ? (
    <>
      <Lozenge appearance={option.appearance}>{option.label}</Lozenge>
      {option.value === 1 && temperature && (
        <small className="ml-2 text-neutral-200">({temperature?.split(',').join(', ')} °C)</small>
      )}
    </>
  ) : null
}

export default TemperatureTag
