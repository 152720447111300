// Interfaces
import { DotsIcon } from '@royan-co/user-interface'

// Atlassian
import Button from '@atlaskit/button'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'
import DropdownLink from 'components/UI/DropdownLink'

// Components
import RegistryTypeTag from '../TypeTag'
import RegistryAccountingArcaTag from '../AccountingArcaTag'
import RegistryFreightForwarderTag from '../FreightForwarderTag'
import CollapsedWrapper from 'components/UI/CollapsedWrapper'
import RegistryDeactivateAction from '../View/DeactivateAction'
import DisabledElementTooltip from 'components/UI/DisabledElementTooltip'

export const generateRegistriesColumns = (openDeleteModal, openInactiveModal) => {
  return [
    {
      key: 'numerator_code',
      title: 'Code',
      isSortable: true,
      show: true,
      content: (item) => item.numerator_code,
    },
    {
      key: 'company_name',
      title: 'Name',
      isSortable: true,
      show: true,
      content: (item) => item.registry.company_name,
    },
    {
      key: 'Type',
      title: 'Type',
      show: true,
      isSortable: false,
      content: (item) => <RegistryTypeTag type={item.type} clientType={item.client_type} />,
    },
    {
      key: 'freight_forwarder',
      title: 'Freight forwarder',
      isSortable: false,
      show: true,
      content: (item) => <RegistryFreightForwarderTag value={item.registry.freight_forwarder} />,
    },
    {
      key: 'user.company_name',
      title: 'Group',
      isSortable: false,
      show: false,
      content: (item) => item.user.registry.company_name,
    },
    {
      key: 'user.full_name',
      title: 'Creator',
      isSortable: false,
      show: true,
      content: (item) => item.user.full_name,
    },
    {
      key: 'vat_number',
      title: 'VAT no.',
      isSortable: false,
      show: false,
      content: (item) => item.registry.vat_number,
    },
    {
      key: 'accounting_arca',
      title: 'Imported in accounting',
      isSortable: false,
      show: true,
      content: (item) => <RegistryAccountingArcaTag value={item.accounting_arca} />,
    },
    {
      key: 'contacts',
      title: 'Contacts',
      isSortable: false,
      show: false,
      content: (item) => (
        <CollapsedWrapper
          items={item.registry.contacts?.filter((c) => c.email)?.map((c) => c.email)}
          maxItems={2}
        />
      ),
    },
    {
      key: 'addresses',
      title: 'Addresses',
      isSortable: false,
      show: true,
      content: (item) => (
        <CollapsedWrapper
          items={item.registry.addresses?.map((a) =>
            [a.address, a.country_iso, a.city].filter((i) => i).join(', ')
          )}
          maxItems={1}
        />
      ),
    },
    {
      key: 'telephone',
      title: 'Telephone',
      isSortable: false,
      show: true,
      content: (item) => (
        <CollapsedWrapper
          items={item.registry.addresses?.filter((a) => a.telephone)?.map((a) => a.telephone)}
          maxItems={3}
        />
      ),
    },
    {
      key: 'website',
      title: 'Website',
      isSortable: false,
      show: false,
      content: (item) =>
        item.registry.website && (
          <a href={item.registry.website} target="_blank" rel="noreferrer">
            <Button appearance="primary" spacing="compact">
              {item.registry.website}
            </Button>
          </a>
        ),
    },
    {
      key: 'quotations_count',
      title: 'Quotations',
      isSortable: false,
      show: false,
      content: (item) => item.quotations_count,
    },
    {
      key: 'shipments_count',
      title: 'Shipments',
      isSortable: false,
      show: false,
      content: (item) => item.shipments_count,
    },
    {
      key: 'creation_date',
      title: 'Creation date',
      isSortable: true,
      show: true,
      content: (item) => item.registry.creation_date,
    },
    {
      key: 'actions',
      title: 'Actions',
      show: true,
      content: (item) => (
        <DropdownMenu
          placement="bottom-end"
          trigger={({ triggerRef, ...props }) => (
            <Button ref={triggerRef} appearance="subtle" children={<DotsIcon />} {...props} />
          )}
        >
          <DropdownItemGroup>
            <DropdownLink
              children="View"
              options={{ state: { registry: item.registry } }}
              to={`/panel/registries/${item.registry.id_auto}/${item.user.auto_id}`}
            />
            <DropdownLink
              children="Edit"
              to={`/panel/registries/${item.registry.id_auto}/${item.user.auto_id}/edit`}
            />

            <RegistryDeactivateAction
              deactivated={item.registry?.deactivated}
              onClick={() => openInactiveModal(item.registry)}
            />

            <DisabledElementTooltip
              isDisabled={!item.registry?.deletable}
              tooltipContent="Registry can not be deleted"
            >
              <DropdownItem
                children="Delete"
                onClick={() => openDeleteModal(item.registry.id_auto)}
                isDisabled={!item.registry?.deletable}
              />
            </DisabledElementTooltip>
          </DropdownItemGroup>
        </DropdownMenu>
      ),
    },
  ]
}
