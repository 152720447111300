import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'

// Hooks
import { useConsolidationStatistics } from 'features/dashboard/api/useConsolidationStatistics'
import { useExportConsolidation } from 'features/dashboard/api/useExportConsolidation'

// Atlassian
import Modal, {
  ModalTitle,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import Lozenge from '@atlaskit/lozenge'
import Button from '@atlaskit/button'

// Interfaces
import { ShortcutIcon } from '@royan-co/user-interface'

// Components
import RemoteControlTable from './RemoteControlTable'
import ConsolidationStatusTag from 'components/Consolidations/StatusTag'
import ConsolidationModeTag from 'components/Consolidations/ModaTag'
import ShipmentFinancialTag from 'components/Shipments/FinancialTag'

const tableHead = {
  cells: [
    { content: 'Reference' },
    { content: 'Status' },
    { content: 'Mode' },
    { content: 'Carrier' },
    { content: 'Creator' },
    { content: 'Margin' },
  ],
}

const ConsolidationListModal = ({ isOpen, onClose, type, params }) => {
  const createRow = useCallback(
    (item, highlightRow) => [
      {
        content: (
          <span className="whitespace-nowrap">
            {item?.consolidation_code ? (
              <Link
                to={`/panel/consolidations/${item.id_auto}`}
                target="_blank"
                onClick={() => highlightRow(item)}
              >
                {item.consolidation_code} <ShortcutIcon size={16} />
              </Link>
            ) : null}
          </span>
        ),
      },
      {
        content: <ConsolidationStatusTag value={item.status} />,
      },
      {
        content: <ConsolidationModeTag item={item} />,
      },
      {
        content: item.carrier_data?.id_auto ? (
          <Link to={`/panel/registries/${item.carrier_data.id_auto}`} target="_blank">
            {item.carrier_data.company_name}
          </Link>
        ) : (
          <Lozenge appearance="default">Not set</Lozenge>
        ),
      },
      {
        content: item.creator?.full_name || <Lozenge>Not set</Lozenge>,
      },
      {
        content: (
          <ShipmentFinancialTag
            cost={item.financial_status?.cost}
            quotation={item.financial_status?.quotation}
            margin={item.financial_status?.margin}
          />
        ),
      },
    ],
    []
  )

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={onClose} width="large" shouldScrollInViewport>
          <ModalHeader>
            <ModalTitle>Consolidations</ModalTitle>
          </ModalHeader>
          <div className="px-6">
            <RemoteControlTable
              key={type}
              type={type}
              params={params}
              exportParams={{ ...params }}
              emptyState="No consolidations"
              tableHead={tableHead}
              onCreateRow={createRow}
              query={useConsolidationStatistics}
              exportMutation={useExportConsolidation}
            />
          </div>
          <ModalFooter>
            <Button appearance="subtle" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  )
}

export default ConsolidationListModal
