// Interfaces
import { DotsIcon } from '@royan-co/user-interface'

// Atlassian
import Lozenge from '@atlaskit/lozenge'
import Button from '@atlaskit/button'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'

export const generateTableColumns = (openBookingModal) => {
  return [
    {
      key: 'code',
      title: 'Code',
      isSortable: true,
      show: true,
      content: (item) => item?.code || <Lozenge>Not set</Lozenge>,
    },
    {
      key: 'book',
      title: 'Booked',
      isSortable: false,
      show: true,
      content: (item) =>
        item.book ? (
          <Lozenge appearance="moved">Booked</Lozenge>
        ) : (
          <Lozenge appearance="success">Free</Lozenge>
        ),
    },
    {
      key: 'used',
      title: 'Used',
      isSortable: false,
      show: true,
      content: (item) =>
        item.used ? (
          <Lozenge appearance="moved">Used</Lozenge>
        ) : (
          <Lozenge appearance="success">Free</Lozenge>
        ),
    },
    {
      key: 'actions',
      title: 'Actions',
      show: true,
      content: (item) =>
        !item.used && (
          <DropdownMenu
            placement="bottom-end"
            trigger={({ triggerRef, shouldRenderToParent, ...props }) => (
              <Button
                {...props}
                appearance="subtle"
                children={<DotsIcon label="more" />}
                ref={triggerRef}
              />
            )}
          >
            <DropdownItemGroup>
              <DropdownItem
                children={item.book ? 'Remove booking' : 'Book'}
                onClick={() => openBookingModal(item)}
              />
            </DropdownItemGroup>
          </DropdownMenu>
        ),
    },
  ]
}
