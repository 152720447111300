import { useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

// Utils
import { getFilled } from 'utils/helpers'
import { handleErrorOnSubmit } from 'utils/errors'

// Apis
import { createServiceCode, editServiceCode } from './../../api/service-codes-requests'

// Hooks
import { useServiceCode } from './../../api/getServiceCode'

// Atlassian
import PageHeader from '@atlaskit/page-header'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'
import Textfield from '@atlaskit/textfield'
import { ErrorMessage } from '@atlaskit/form'
import Button, { LoadingButton } from '@atlaskit/button'
import Spinner from '@atlaskit/spinner'
import SectionMessage from '@atlaskit/section-message'

// Interfaces
import { Grid, Form, Field, WarningBannerField, useFlags } from '@royan-co/user-interface'

// Components
import Card from 'components/UI/Card'

const ServiceCodeCreationPage = () => {
  const navigate = useNavigate()
  const { serviceCode } = useParams()
  const { showSuccessFlag, showWarningFlag } = useFlags()

  const pageTitle = serviceCode ? 'Edit service code' : `New service code`

  const { data, isError, isLoading, refetch } = useServiceCode(serviceCode, {
    enabled: !!serviceCode,
  })

  const createServiceCodeMutation = useMutation((data) =>
    !serviceCode ? createServiceCode(data) : editServiceCode(serviceCode, data)
  )

  const defaultValues = useMemo(() => {
    const values = {
      code: getFilled(data, 'code', ''),
      description: getFilled(data, 'description', ''),
      accounts_id: getFilled(data, 'accounts_id', ''),
      accounts_id_passive: getFilled(data, 'accounts_id_passive', ''),
    }

    return values
  }, [data])

  const onSubmitCreation = (data, { setError }) => {
    createServiceCodeMutation.mutate(data, {
      onError: (e) => {
        showWarningFlag('Something wrong!')
        handleErrorOnSubmit(e, setError, data)
      },
      onSuccess: (res) => {
        showSuccessFlag(res.message)
        navigate('/panel/service-codes', { replace: true })
      },
    })
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <Grid gutter={24} className="w-full">
        <Grid.Col md={10} lg={8} xl={6} offsetMd={1} offsetLg={2} offsetXl={3}>
          <PageHeader
            breadcrumbs={
              <Breadcrumbs>
                <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
                <BreadcrumbsItem text="Configurations" />
                <BreadcrumbsItem text="Service codes" to="/panel/service-codes" component={Link} />
              </Breadcrumbs>
            }
          >
            {pageTitle}
          </PageHeader>

          <div className="my-8">
            {isLoading && serviceCode ? (
              <div className="flex justify-center my-32">
                <Spinner />
              </div>
            ) : isError ? (
              <SectionMessage
                appearance="warning"
                title="Something wrong on loading data, please retry."
              >
                <Button onClick={() => refetch()}>Retry</Button>
              </SectionMessage>
            ) : (
              <Form onSubmit={onSubmitCreation} defaultValues={defaultValues}>
                {() => (
                  <>
                    <Card className="mt-0">
                      <Field isRequired label="Code" name="code" defaultValue="" className="!mt-0">
                        {({ fieldProps, error }) => (
                          <>
                            <Textfield placeholder="Type the code" {...fieldProps} />
                            {error && <ErrorMessage>{error}</ErrorMessage>}
                          </>
                        )}
                      </Field>

                      <Field
                        isRequired
                        label="Name"
                        name="description"
                        defaultValue=""
                        className="!mt-4"
                      >
                        {({ fieldProps, error }) => (
                          <>
                            <Textfield placeholder="Type the name" {...fieldProps} />
                            {error && <ErrorMessage>{error}</ErrorMessage>}
                          </>
                        )}
                      </Field>

                      <Field
                        label="Accounting code - Active"
                        name="accounts_id"
                        defaultValue=""
                        className="!mt-4"
                      >
                        {({ fieldProps, error }) => (
                          <>
                            <Textfield placeholder="Type the accounting code" {...fieldProps} />
                            {error && <ErrorMessage>{error}</ErrorMessage>}
                          </>
                        )}
                      </Field>

                      <Field
                        label="Accounting code - Passive"
                        name="accounts_id_passive"
                        defaultValue=""
                        className="!mt-4"
                      >
                        {({ fieldProps, error }) => (
                          <>
                            <Textfield placeholder="Type the accounting code" {...fieldProps} />
                            {error && <ErrorMessage>{error}</ErrorMessage>}
                          </>
                        )}
                      </Field>
                    </Card>

                    <WarningBannerField />

                    <LoadingButton
                      className="!table ml-auto mt-6"
                      appearance="primary"
                      type="submit"
                      isLoading={createServiceCodeMutation.isLoading}
                    >
                      {serviceCode ? 'Edit' : 'Create'}
                    </LoadingButton>
                  </>
                )}
              </Form>
            )}
          </div>
        </Grid.Col>
      </Grid>
    </>
  )
}

export default ServiceCodeCreationPage
