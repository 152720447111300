import { useMemo } from 'react'
import { canAccess } from '../utils/can-access'

const { useAuth } = require('contexts/AuthProvider')

const useCanAccess = ({ resource, action, userPermissions = [] }) => {
  const { permissions } = useAuth()

  const permitted = useMemo(
    () => canAccess({ resource, action, userPermissions, permissions }),
    [action, permissions, resource, userPermissions]
  )

  return permitted
}

export default useCanAccess
