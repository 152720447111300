import React, { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

// APIS
import {
  getConsolidationLoadingDetails,
  finishConsolidationLoadingDetails,
  loadingConsolidationEntrance,
} from 'api/consolidations'

// Atlassian
import Spinner from '@atlaskit/spinner'
import PageHeader from '@atlaskit/page-header'
import SectionMessage from '@atlaskit/section-message'
import Button, { LoadingButton } from '@atlaskit/button'
import EmptyState from '@atlaskit/empty-state'

// Interfaces
import { Grid, PhotoIcon, useFlags } from '@royan-co/user-interface'

// Components
import ConsolidationViewEntranceCard from 'components/Consolidations/View/EntranceCard'
import ConsolidationScanningEntranceCode from 'components/Consolidations/View/ScanningEntranceCode2'
import GeneralEntranceCard from 'components/Consolidations/View/GeneralEntranceCard'

const ConsolidationViewEntrancesPage = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { consolidationId, entranceId } = useParams()
  const { showSuccessFlag, showWarningFlag } = useFlags()

  const [scanning, setScanning] = useState(false)
  const [openCardId, setOpenCardId] = useState(null)
  const [startLoadingEntrancesData, setStartLoadingEntrancesData] = useState(false)

  const entrancesQueryKey = ['consolidation_entrances', consolidationId]

  const loadingColliesMutation = useMutation({
    mutationFn: () => loadingConsolidationEntrance(consolidationId, entranceId),
    onSuccess: (res) => {
      showSuccessFlag(res?.message)
    },
    onError: (e) => {
      console.error(e)
      showWarningFlag(e?.response?.data?.message || 'Something wrong happened.')
    },
    onSettled: () => {
      setStartLoadingEntrancesData(true)
    },
  })

  const entrancesQuery = useQuery({
    queryKey: entrancesQueryKey,
    queryFn: () => getConsolidationLoadingDetails(consolidationId),
    select: (res) => res?.data,
    enabled: !entranceId ? true : startLoadingEntrancesData,
  })

  const loadingConsolidation = entrancesQuery.data?.consolidation

  const entrances = useMemo(
    () => entrancesQuery.data?.entrances?.entrances || [],
    [entrancesQuery.data]
  )

  const activeEntrance = useMemo(
    () =>
      entrancesQuery.data?.entrances?.active_entrance
        ? entrancesQuery.data.entrances.entrances?.find(
            (e) => e.id_auto === entrancesQuery.data.entrances.active_entrance
          )
        : null,
    [entrancesQuery.data]
  )

  const loadedEntrances = useMemo(
    () =>
      entrances?.filter((e) => e?.total_collies && e?.total_collies === e?.loaded_collies)
        ?.length || 0,
    [entrances]
  )

  const pendingEntrances = useMemo(
    () => entrances?.length - loadedEntrances,
    [entrances, loadedEntrances]
  )

  const updateEntranceData = (loadData) => {
    setScanning(false)

    queryClient.setQueryData(entrancesQueryKey, (oldData) => {
      const affectedEntrance = oldData.data.entrances.entrances.find(
        (e) => e.id_auto === loadData.entranceId
      )

      if (!affectedEntrance) return oldData

      const isAffectedEntranceHasActive =
        affectedEntrance.loaded_collies + 1 < affectedEntrance.total_collies

      return {
        ...oldData,
        data: {
          ...oldData.data,
          entrances: {
            active_entrance: isAffectedEntranceHasActive ? affectedEntrance.id_auto : null,
            entrances: oldData.data.entrances.entrances.map((e, i) =>
              e.id_auto === loadData.entranceId
                ? {
                    ...e,
                    loaded_collies: (e.loaded_collies || 0) + 1,
                  }
                : e
            ),
          },
        },
      }
    })
  }

  const contactCreationMutation = useMutation(finishConsolidationLoadingDetails, {
    onError: (e) => {
      showWarningFlag('Something wrong happened.')
      console.error(e)
    },
    onSuccess: (res) => {
      showSuccessFlag(res?.message)
      queryClient.invalidateQueries({ queryKey: entrancesQueryKey, refetchType: 'none' })
      navigate(`/panel/consolidations/${consolidationId}`)
    },
  })

  useEffect(() => {
    if (entranceId) loadingColliesMutation.mutate()

    if (entranceId) return loadingColliesMutation.reset()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <Helmet>
        <title>Loading management</title>
      </Helmet>

      <Grid gutter={0} gutterMd={24} className="w-full mb-24">
        <Grid.Col lg={6} offsetLg={3}>
          <PageHeader>Loading management</PageHeader>

          {loadingColliesMutation.isLoading ? (
            <div className="text-center my-8">
              <Spinner />
            </div>
          ) : null}

          {!entrancesQuery.isLoading ? (
            <p className="!-mt-5 flex">Consolidation {loadingConsolidation?.reference}</p>
          ) : null}

          {entrancesQuery.isLoading || entrancesQuery.isFetching ? (
            <div className="text-center my-8">
              <Spinner />
            </div>
          ) : entrancesQuery.isError ? (
            <SectionMessage
              appearance="warning"
              title="Something wrong on loading entrances data, please retry"
            >
              <Button onClick={() => entrancesQuery.refetch()}>Retry</Button>
            </SectionMessage>
          ) : entrances?.length > 0 ? (
            <div className="relative w-full">
              {pendingEntrances !== 0 ? (
                <GeneralEntranceCard
                  activeEntrance={activeEntrance}
                  pendingEntrances={pendingEntrances}
                  loadedEntrances={loadedEntrances}
                />
              ) : null}

              {scanning ? (
                <ConsolidationScanningEntranceCode
                  entrances={entrances}
                  onSuccess={updateEntranceData}
                  onCancel={() => setScanning(false)}
                />
              ) : (
                <>
                  {pendingEntrances !== 0 && (
                    <div className="my-6">
                      <SectionMessage>
                        <p>
                          You can use your camera on the phone and directly scan QR codes. You will
                          be redirected to this page automatically.
                        </p>
                      </SectionMessage>
                    </div>
                  )}

                  {entrances?.map((item, i) => (
                    <ConsolidationViewEntranceCard
                      key={item.id_auto}
                      entrance={item}
                      isOpen={openCardId === item.id_auto}
                      handleOpenCard={() =>
                        setOpenCardId((id) => (id !== item.id_auto ? item.id_auto : null))
                      }
                    />
                  ))}
                </>
              )}

              {loadingConsolidation?.loading_status === 2 ? (
                <p className="mt-6">
                  This loading has been finalized at {loadingConsolidation?.loading_finished_at} By{' '}
                  {loadingConsolidation?.loading_operator?.full_name}.
                </p>
              ) : (
                pendingEntrances === 0 && (
                  <LoadingButton
                    className="mt-6"
                    shouldFitContainer
                    appearance="primary"
                    isLoading={contactCreationMutation.isLoading}
                    onClick={() => contactCreationMutation.mutate(consolidationId)}
                  >
                    Finish loading consolidation
                  </LoadingButton>
                )
              )}

              {!scanning && pendingEntrances !== 0 && (
                <div className="hidden fixed bottom-10 right-0 w-full text-center lg:text-center">
                  <button
                    onClick={() => setScanning(true)}
                    className="bg-blue-400 text-white p-3 rounded-full"
                  >
                    <PhotoIcon size={28} />
                  </button>
                </div>
              )}
            </div>
          ) : (
            <EmptyState header="No entrances" />
          )}
        </Grid.Col>
      </Grid>
    </div>
  )
}

export default ConsolidationViewEntrancesPage
