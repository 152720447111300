import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

import {
  createConsolidationNote,
  deleteConsolidationNote,
  editConsolidationNote,
} from 'api/consolidations'
import { handleErrorOnSubmit } from 'utils/errors'

//Hooks
import useConsolidationNotes from 'hooks/useConsolidationNotes'
import useConsolidation from 'hooks/useConsolidation'

//Atlassian
import Button from '@atlaskit/button'
import Spinner from '@atlaskit/spinner'
import EmptyState from '@atlaskit/empty-state'
import SectionMessage from '@atlaskit/section-message'

//Interfaces
import { PlusIcon, useDisclosure, useFlags } from '@royan-co/user-interface'

//Components
import NoteBox from 'components/Shipments/View/NoteBox'
import DeleteNoteModal from 'components/Shipments/View/DeleteNoteModal'
import NoteModal from 'components/Shipments/View/NoteModal'

const ConsolidationViewNotesPage = () => {
  const { showSuccessFlag } = useFlags()
  const { consolidationId } = useParams()
  const { UpdateNotesCount } = useConsolidation(consolidationId)
  const { notes, isLoading, isError, refetch, deleteNoteFromData, upsertNoteToData } =
    useConsolidationNotes(consolidationId)

  const [isOpenNoteModal, noteModalHandlers] = useDisclosure('note')
  const [isOpenDeleteNoteModal, deleteNoteModalHandlers] = useDisclosure('delete')

  const isEditing = useMemo(() => noteModalHandlers?.data?.id, [noteModalHandlers])

  const NoteCreationMutation = useMutation(
    !isEditing
      ? createConsolidationNote.bind(null, consolidationId)
      : editConsolidationNote.bind(null, noteModalHandlers?.data?.id)
  )
  const NoteDeleteMutation = useMutation(deleteConsolidationNote)

  const handleCreateNote = (data, { setError }) => {
    NoteCreationMutation.mutate(data, {
      onError: (e) => {
        handleErrorOnSubmit(e, setError, data)
      },
      onSuccess: (res) => {
        upsertNoteToData(res.note)
        showSuccessFlag(res.message)
        if (!isEditing) UpdateNotesCount('increase')
        noteModalHandlers.close()
      },
    })
  }

  const handleDeleteNote = () => {
    const note = deleteNoteModalHandlers?.data

    NoteDeleteMutation.mutate(note.id, {
      onSuccess: (res) => {
        deleteNoteFromData(note.id)
        showSuccessFlag(res?.message)
        UpdateNotesCount('decrease')
        deleteNoteModalHandlers.close()
      },
    })
  }

  return (
    <>
      {isLoading ? (
        <div className="text-center mt-24">
          <Spinner />
        </div>
      ) : isError ? (
        <SectionMessage
          appearance="warning"
          title="Something wrong on loading notes, please retry."
        >
          <Button onClick={() => refetch()}>Retry</Button>
        </SectionMessage>
      ) : (
        <>
          <Button
            appearance="default"
            iconBefore={<PlusIcon />}
            onClick={() => noteModalHandlers.open()}
          >
            Add a note
          </Button>

          <div className="mt-6">
            {!notes || notes?.length < 1 ? (
              <EmptyState header="There is no note to display" />
            ) : (
              notes?.map((note, i) => (
                <NoteBox
                  key={i}
                  note={note}
                  className="mb-4"
                  onEdit={() => noteModalHandlers.open(note)}
                  onDelete={() => deleteNoteModalHandlers.open(note)}
                />
              ))
            )}
          </div>
        </>
      )}

      <NoteModal
        isOpen={isOpenNoteModal}
        note={noteModalHandlers.data}
        onClose={noteModalHandlers.close}
        onSubmit={handleCreateNote}
        isLoading={NoteCreationMutation.isLoading}
        isError={NoteCreationMutation.isError}
      />

      <DeleteNoteModal
        shipmentId={consolidationId}
        isOpen={isOpenDeleteNoteModal}
        onClose={deleteNoteModalHandlers.close}
        onSubmit={handleDeleteNote}
        isLoading={NoteDeleteMutation.isLoading}
        isError={NoteDeleteMutation.isError}
      />
    </>
  )
}

export default ConsolidationViewNotesPage
