import { findOption, transformSelectField } from 'utils/helpers'

//Hooks
import useShipmentsHubs from 'hooks/useShipmentsHubs'
import useShipmentsOperators from 'hooks/useShipmentsOperators'

import { gpsTrackerOptions, statusOptions } from '../constants'
import {
  containerTypes,
  modeOptions,
  shipmentTemperatureOptions,
  truckTypes,
} from 'components/Shipments/constants'

//Atlassian
import Select, { CountrySelect } from '@atlaskit/select'
import { DatePicker } from '@atlaskit/datetime-picker'
import TextField from '@atlaskit/textfield'
import { allCountries as countries } from '/node_modules/@atlaskit/select/dist/es2019/data/countries.js'

//Interfaces
import { Field, Grid, useFormContext } from '@royan-co/user-interface'

//Components
import RegistryFilterField from 'components/Shipments/List/RegistryFilterField'
import { useEffect } from 'react'

const ConsolidationsFilterFields = ({ appliedFilters }) => {
  const { watch, setValue } = useFormContext()

  const mode = watch('shipment_type')

  const { shipmentsHubsOptions, isLoadingShipmentsHubs } = useShipmentsHubs()
  const { shipmentsOperatorsOptions, isLoadingShipmentsOperators } = useShipmentsOperators()

  useEffect(() => {
    if (![0, 1].includes(mode)) setValue('type_of_collies', null)
  }, [mode, setValue])

  return (
    <>
      <Field
        label="Status"
        type="select"
        name="status"
        transform={transformSelectField(statusOptions)}
      >
        {({ fieldProps }) => (
          <Select
            isClearable={true}
            options={statusOptions}
            placeholder="Choose a status"
            {...fieldProps}
          />
        )}
      </Field>

      <Field
        label="Mode"
        type="select"
        name="shipment_type"
        transform={transformSelectField(modeOptions)}
      >
        {({ fieldProps }) => (
          <Select
            isClearable={true}
            options={modeOptions}
            placeholder="Choose a mode"
            {...fieldProps}
          />
        )}
      </Field>

      {[0, 1].includes(mode) && (
        <Field
          type="select"
          label="Required service"
          name="type_of_collies"
          transform={{
            output: (opt) => (opt?.value !== undefined ? opt.value : null),
            input: (v) => findOption(mode === 1 ? containerTypes : truckTypes, v),
          }}
        >
          {({ fieldProps }) => (
            <Select
              isClearable={true}
              options={mode === 1 ? containerTypes : truckTypes}
              placeholder="Choose a service"
              {...fieldProps}
            />
          )}
        </Field>
      )}

      <Field
        type="select"
        label="Country of delivery"
        name="arrival_country"
        transform={{
          output: (c) => (c?.abbr !== undefined ? c.abbr : null),
          input: (v) => countries?.find((c) => c.abbr === v),
        }}
      >
        {({ fieldProps: { ref, ...fieldProps } }) => (
          <CountrySelect
            isClearable={true}
            className="w-100"
            placeholder="Choose a country"
            {...fieldProps}
          />
        )}
      </Field>

      <Field label="Carrier" name="carrier">
        {({ fieldProps }) => <TextField placeholder="Type the carrier" {...fieldProps} />}
      </Field>

      <Field
        label="Transportation unit"
        name="means_code"
        defaultValue={appliedFilters.means_code || ''}
      >
        {({ fieldProps }) => (
          <TextField placeholder="Type the transportation unit" {...fieldProps} />
        )}
      </Field>

      <Field
        type="select"
        label="GPS tracker"
        name="has_tracking_unit"
        transform={transformSelectField(gpsTrackerOptions)}
      >
        {({ fieldProps }) => (
          <Select
            isClearable={true}
            placeholder="Choose"
            options={gpsTrackerOptions}
            {...fieldProps}
          />
        )}
      </Field>

      <Field
        type="select"
        label="Creator"
        name="creator_user"
        transform={transformSelectField(shipmentsOperatorsOptions)}
      >
        {({ fieldProps }) => (
          <Select
            isClearable={true}
            placeholder="Choose"
            options={shipmentsOperatorsOptions}
            isLoading={isLoadingShipmentsOperators}
            {...fieldProps}
          />
        )}
      </Field>

      <Field
        type="select"
        label="Consolidator hub"
        name="hub_consolidator"
        transform={transformSelectField(shipmentsHubsOptions)}
      >
        {({ fieldProps }) => (
          <Select
            isClearable={true}
            placeholder="Choose"
            options={shipmentsHubsOptions}
            isLoading={isLoadingShipmentsHubs}
            {...fieldProps}
          />
        )}
      </Field>

      <Field
        type="select"
        label="Temperature control"
        name="reefer"
        transform={transformSelectField(shipmentTemperatureOptions)}
      >
        {({ fieldProps }) => (
          <Select
            isClearable={true}
            placeholder="Choose"
            options={shipmentTemperatureOptions}
            {...fieldProps}
          />
        )}
      </Field>

      <Grid>
        <Grid.Col sm={6}>
          <Field label="From" name="created_from" defaultValue={appliedFilters.created_from || ''}>
            {({ fieldProps }) => <DatePicker placeholder="2/18/2022" {...fieldProps} />}
          </Field>
        </Grid.Col>

        <Grid.Col sm={6}>
          <Field label="To" name="created_to" defaultValue={appliedFilters.created_to || ''}>
            {({ fieldProps }) => <DatePicker placeholder="2/18/2022" {...fieldProps} />}
          </Field>
        </Grid.Col>
      </Grid>
    </>
  )
}

export default ConsolidationsFilterFields
