import { useMemo } from 'react'

// Utils
import { formatNumber } from 'utils/numbers'

// Icons
import { EditIcon, TrashIcon } from '@royan-co/user-interface'

// Atlassian
import Button from '@atlaskit/button'
import Lozenge from '@atlaskit/lozenge'
import { DynamicTableStateless } from '@atlaskit/dynamic-table'

const MarginCalculatorBracketsTable = ({ onDelete, onEdit, brackets }) => {
  const tableHead = {
    cells: [
      { content: 'From' },
      { content: 'To' },
      { content: 'Value' },
      { content: 'Fix' },
      { content: '' },
    ],
  }

  const tableRows = useMemo(
    () =>
      brackets.map((bracket, i) => {
        let tableRowItems = [
          {
            content: bracket?.from ? (
              formatNumber(bracket.from)
            ) : (
              <Lozenge appearance="default">Not set</Lozenge>
            ),
          },
          {
            content: bracket?.to ? (
              formatNumber(bracket.to)
            ) : (
              <Lozenge appearance="default">Not set</Lozenge>
            ),
          },
          {
            content: formatNumber(bracket?.value),
          },
          {
            content: bracket?.fix_percent ? (
              <Lozenge appearance="success">Yes</Lozenge>
            ) : (
              <Lozenge>No</Lozenge>
            ),
          },
          {
            content: (
              <div className="flex justify-end">
                <Button
                  spacing="compact"
                  appearance="subtle"
                  iconBefore={<EditIcon />}
                  onClick={() => onEdit(bracket, i)}
                />
                <Button
                  spacing="compact"
                  appearance="subtle"
                  onClick={() => onDelete(bracket, i)}
                  iconBefore={<TrashIcon className="text-red-400" />}
                />
              </div>
            ),
          },
        ]

        return {
          cells: tableRowItems,
        }
      }),
    [brackets, onEdit, onDelete]
  )

  return (
    <div className="inline-block overflow-auto w-full -mb-6">
      <DynamicTableStateless head={tableHead} rows={tableRows} />
    </div>
  )
}

export default MarginCalculatorBracketsTable
