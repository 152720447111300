import { useEffect } from 'react'
import { useMutation } from '@tanstack/react-query'

//Services
import { findOption, getAddressLabel, getContactLabel, getFilled } from 'utils/helpers'

import useRegistryAddress from 'hooks/useRegistryAddress'
import useRegistryContact from 'hooks/useRgeistryContact'
import { createRegistry, createRegistryAddress, createRegistryContact } from 'api/registries'
import { handleErrorOnSubmit } from 'utils/errors'

//Atlassian
import Button from '@atlaskit/button'
import Select from '@atlaskit/select'
import { ErrorMessage } from '@atlaskit/form'

//Interfaces
import {
  Field,
  useFlags,
  useDisclosure,
  useFormValues,
  useFormContext,
  PlusIcon,
} from '@royan-co/user-interface'

//Components
import ContactDrawer from 'components/Registries/Create/ContactDrawer'
import BranchDrawer from 'components/Registries/Create/BranchDrawer'
import RegistrySelect from 'components/Registries/RegistrySelect'
import RegistryDrawer from 'components/Registries/Create/RegistryDrawer'

const TripleFieldsRegistry = ({ names, labels, className }) => {
  const { setValue, watch } = useFormContext()
  const { showSuccessFlag } = useFlags()
  const { [names.registry]: registry, [names.address]: address } = useFormValues()
  
  const selectedAddress = watch(names.address)

  const { registryAddressOptions, generalAddress, upsertAddressToData, isLoadingRegistryAddress } =
    useRegistryAddress(registry?.value)
  const { registryContactOptions, upsertContactToData, isLoadingRegistryContact } =
    useRegistryContact(address?.value)

  const createRegistryMutation = useMutation(createRegistry)
  const createRegistryAddressMutation = useMutation(
    createRegistryAddress.bind(null, registry?.value)
  )
  const createRegistryContactMutation = useMutation(
    createRegistryContact.bind(null, address?.value)
  )

  const [openRegistryDrawer, registryDrawerHandlers] = useDisclosure('registry')
  const [openAddressDrawer, addressDrawerHandlers] = useDisclosure('address')
  const [openContactDrawer, contactDrawerHandlers] = useDisclosure('contact')

  const onSubmitRegistry = (data, { setError }) => {
    data = {
      ...data,
      cellphone: getFilled(data?.cellphone, 'rawPhone', data?.cellphone),
      cellphone_prefix: getFilled(data?.cellphone, 'countryAbbr', data?.cellphone_prefix),
    }

    createRegistryMutation.mutate(data, {
      onError: (e) => {
        handleErrorOnSubmit(e, setError, data)
        console.error(e)
      },
      onSuccess: (res) => {
        showSuccessFlag(res.message)
        setValue(names.registry, {
          label: res.registry.company_name,
          value: res.registry.id_auto,
          imported: res.registry.arca_imported,
        })
        setValue(names.address, null)
        setValue(names.contact, null)
        registryDrawerHandlers.close()
      },
    })
  }

  const onSubmitContact = (data, { setError }) => {
    data = {
      ...data,
      cellphone: getFilled(data?.cellphone, 'rawPhone', data?.cellphone),
      cellphone_prefix: getFilled(data?.cellphone, 'countryAbbr', data?.cellphone_prefix),
    }

    createRegistryContactMutation.mutate(data, {
      onError: (e) => {
        handleErrorOnSubmit(e, setError, data)
        console.error(e)
      },
      onSuccess: (res) => {
        showSuccessFlag(res.message)
        upsertContactToData(res.contact)
        setValue(names.contact, { label: getContactLabel(res.contact), value: res.contact.id_auto })
        contactDrawerHandlers.close()
      },
    })
  }

  const onSubmitAddress = (data, { setError }) => {
    createRegistryAddressMutation.mutate(data, {
      onError: (e) => {
        handleErrorOnSubmit(e, setError, data)
        console.error(e)
      },
      onSuccess: (res) => {
        showSuccessFlag(res.message)
        upsertAddressToData(res.address)
        setValue(names.address, { label: getAddressLabel(res.address), value: res.address.id_auto })
        setValue(names.contact, null)
        addressDrawerHandlers.close()
      },
    })
  }

  useEffect(() => {
    if (generalAddress && !selectedAddress)
      setValue(names.address, findOption(registryAddressOptions, generalAddress))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalAddress])

  return (
    <div className={className}>
      <Field
        type="select"
        className="!mt-0"
        name={names.registry}
        label={labels.registry}
        onValueChange={() => {
          setValue(names.address, null)
          setValue(names.contact, null)
        }}
      >
        {({ fieldProps, error }) => (
          <div className="flex gap-4">
            <div className="flex-grow">
              <RegistrySelect spacing="compact" defaultData={registry} {...fieldProps} />
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </div>

            <div className="basis-36">
              <Button
                type="button"
                shouldFitContainer
                appearance="primary"
                iconBefore={<PlusIcon size={24} />}
                className="!text-left"
                onClick={() => registryDrawerHandlers.open()}
              >
                New registry
              </Button>
            </div>
          </div>
        )}
      </Field>

      {registry && (
        <Field
          type="select"
          className="!mt-4"
          name={names.address}
          label={labels.address}
          onValueChange={() => setValue(names.contact, null)}
        >
          {({ fieldProps, error }) => (
            <div className="flex gap-4">
              <div className="flex-grow">
                <Select
                  spacing="compact"
                  placeholder="Choose"
                  options={registryAddressOptions}
                  isLoading={isLoadingRegistryAddress}
                  {...fieldProps}
                />

                {error && <ErrorMessage>{error}</ErrorMessage>}
              </div>

              <div className="basis-36">
                <Button
                  type="button"
                  shouldFitContainer
                  appearance="primary"
                  iconBefore={<PlusIcon size={24} />}
                  className="!text-left"
                  onClick={() => addressDrawerHandlers.open()}
                >
                  New address
                </Button>
              </div>
            </div>
          )}
        </Field>
      )}

      {address && (
        <Field type="select" className="!mt-4" name={names.contact} label={labels.contact}>
          {({ fieldProps, error }) => (
            <div className="flex gap-4">
              <div className="flex-grow">
                <Select
                  spacing="compact"
                  placeholder="Choose"
                  options={registryContactOptions}
                  isLoading={isLoadingRegistryContact}
                  {...fieldProps}
                />

                {error && <ErrorMessage>{error}</ErrorMessage>}
              </div>

              <div className="basis-36">
                <Button
                  type="button"
                  shouldFitContainer
                  appearance="primary"
                  className="!text-left"
                  iconBefore={<PlusIcon size={24} />}
                  onClick={() => contactDrawerHandlers.open()}
                >
                  New contact
                </Button>
              </div>
            </div>
          )}
        </Field>
      )}

      <RegistryDrawer
        onSubmit={onSubmitRegistry}
        isOpen={openRegistryDrawer}
        onClose={() => registryDrawerHandlers.close()}
        isSubmitting={createRegistryMutation.isLoading}
      />

      <BranchDrawer
        onSubmit={onSubmitAddress}
        isOpen={openAddressDrawer}
        onClose={() => addressDrawerHandlers.close()}
        isSubmitting={createRegistryAddressMutation.isLoading}
      />

      <ContactDrawer
        onSubmit={onSubmitContact}
        isOpen={openContactDrawer}
        onClose={() => contactDrawerHandlers.close()}
        isSubmitting={createRegistryContactMutation.isLoading}
      />
    </div>
  )
}

export default TripleFieldsRegistry
