import { useMemo } from 'react'
import { getRegistry } from 'api/registries'
import { useQuery, useQueryClient } from '@tanstack/react-query'

const useRegistry = (registryId, { creatorId, placeholderData, enabled = true } = {}) => {
  const queryClient = useQueryClient()

  const {
    error,
    isError,
    refetch,
    isLoading,
    isFetching,
    data: registry,
  } = useQuery(['registry', registryId], () => getRegistry(registryId), {
    enabled,
    staleTime: Infinity,
    select: (res) => res?.registry,
    // placeholderData: placeholderData || {},
  })

  const creator = useMemo(() => {
    if (!registry?.owners) return null

    return (
      registry.owners.find((o) => o?.user?.auto_id === parseInt(creatorId)) || registry.owners[0]
    )
  }, [creatorId, registry?.owners])

  const deleteBranchFromData = (deletedId) => {
    queryClient.setQueryData(['registry', registryId], (oldRegistry) => ({
      ...oldRegistry,
      registry: {
        ...oldRegistry.registry,
        addresses: oldRegistry.registry.addresses.filter((a) => a.id_auto !== deletedId),
      },
    }))
  }

  const upsertBranchesToData = (branch) => {
    const isExist = (registry?.addresses || []).some((a) => a.id_auto === branch.id_auto)

    queryClient.setQueryData(['registry', registryId], (oldRegistry = {}) => ({
      ...oldRegistry,
      registry: {
        ...oldRegistry.registry,
        addresses: isExist
          ? (oldRegistry.registry.addresses || []).map((a) =>
              a.id_auto === branch.id_auto ? branch : a
            )
          : [...(oldRegistry.registry.addresses || []), branch],
      },
    }))
  }

  const deleteContactFromData = (deletedId) => {
    queryClient.setQueryData(['registry', registryId], (oldRegistry) => ({
      ...oldRegistry,
      registry: {
        ...oldRegistry.registry,
        contacts: oldRegistry.registry.contacts.filter((c) => c.id_auto !== deletedId),
      },
    }))
  }

  const upsertContactToData = (contact) => {
    const isExist = (registry?.contacts || []).some((c) => c.id_auto === contact.id_auto)

    queryClient.setQueryData(['registry', registryId], (oldRegistry = {}) => ({
      ...oldRegistry,
      registry: {
        ...oldRegistry.registry,
        contacts: isExist
          ? (oldRegistry.registry.contacts || []).map((c) =>
              c.id_auto === contact.id_auto ? contact : c
            )
          : [...(oldRegistry.registry.contacts || []), contact],
      },
    }))
  }

  const changeLogo = (logUrl) => {
    queryClient.setQueryData(['registry', registryId], (oldRegistry) => ({
      ...oldRegistry,
      registry: { ...oldRegistry.registry, logo: logUrl },
    }))
  }

  const changeDeactivated = (deactivated) => {
    queryClient.setQueryData(['registry', registryId], (oldRegistry) => ({
      ...oldRegistry,
      registry: { ...oldRegistry.registry, deactivated },
    }))
  }

  const invalidate = () => {
    queryClient.invalidateQueries({ queryKey: ['registry', registryId], refetchType: 'none' })
  }

  return {
    registry,
    isLoading,
    isFetching,
    isError,
    refetch,
    creator,
    error,
    changeLogo,
    invalidate,
    upsertBranchesToData,
    deleteBranchFromData,
    deleteContactFromData,
    upsertContactToData,
    changeDeactivated,
  }
}

export default useRegistry
