export const CardSubtitle = ({ className = 'pb-3 pt-2', withLine, children }) => {
  return (
    <h4 className={`uppercase font-medium ${className}`}>
      {withLine ? (
        <div className="relative">
          <span
            style={{
              position: 'absolute',
              zIndex: '1',
              width: '100%',
              display: 'inline-block',
              height: '1px',
              background: '#D5D8DE',
              top: '50%',
            }}
          />
          <span
            style={{ background: '#fff', paddingRight: '8px', position: 'relative', zIndex: '2' }}
          >
            {children}
          </span>
        </div>
      ) : (
        children
      )}
    </h4>
  )
}

export const CardTitle = ({ children, className = '' }) => {
  return <h3 className={`${className} font-medium`}>{children}</h3>
}

const Card = ({ title, className = 'mb-6', actions, children }) => {
  return (
    <div className={className}>
      <div className="flex items-center mb-3">
        {title && <CardTitle>{title}</CardTitle>}
        {actions}
      </div>

      <div className="shadow rounded p-4">{children}</div>
    </div>
  )
}

export default Card
