export const temperatureControlOptions = [
  {
    label: 'Frozen (-22, -18)',
    value: { type: 'pre_define', range: { min: -22, max: -18 } },
  },
  {
    label: 'Chilled (+2, +8)',
    value: { type: 'pre_define', range: { min: 2, max: 8 } },
  },
  {
    label: 'Ambient (+15, +25)',
    value: { type: 'pre_define', range: { min: 15, max: 25 } },
  },
  { label: 'Custom range', value: { type: 'custom', range: { min: -10, max: 15 } } },
]
