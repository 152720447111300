import axios, { csrf } from 'services/Request'

export function getRegistries(params) {
  return axios.get('/api/registries', { params })
}

export function getRegistry(id) {
  return axios.get(`/api/registries/fetch/${id}`)
}

export async function fetchSimilars(data, controller) {
  return await axios.post('/api/registries/fetch-similars', data, {
    signal: controller?.signal,
  })
}

export function deleteRegistry(registryId) {
  return axios.delete(`/api/registries/${registryId}`)
}

export async function createRegistry(data) {
  return await axios.post('/api/registries/create', data)
}

export async function editRegistry(registryIdAuto, data) {
  return await axios.post(`/api/registries/edit/${registryIdAuto}`, data)
}

export function getRegistryCreators() {
  return axios.get('/api/registries/fetch-creators')
}

export function getRegistryQuotes(id, params) {
  return axios.get('/api/shipments/quotes', {
    params: {
      ...params,
      registry_id: id,
    },
  })
}

export function getRegistryShipments(id, params) {
  return axios.get('/api/shipments/dossiers', {
    params: {
      ...params,
      registry_id: id,
    },
  })
}

export function getRegistryDocuments(id, params) {
  return axios.get(`/api/registries/documents/${id}`, { params })
}

export async function uploadRegistryDocument(params, registryId) {
  await csrf()
  return await axios.post(`/api/registries/documents/upload/${registryId}`, params)
}

export function deleteRegistryDocument(documentId) {
  return axios.delete(`/api/registries/documents/${documentId}`)
}

export function getRegistryHistory(id, params) {
  return axios.get(`/api/registries/history/${id}`, { params })
}

export function getRegistryBalances(id) {
  return axios.get(`/api/registries/balances/${id}`)
}

export function getRegistryInvoices(id, params) {
  return axios.get(`/api/registries/invoices/${id}`, { params })
}

export async function getRegistriesAutocomplete(term, type, controller) {
  return axios.post(
    '/api/registries/autocomplete',
    { term, type },
    {
      signal: controller?.signal,
    }
  )
}

export function getRegistryParamValue(id, param) {
  return axios.get(`/api/registries/fetch/${id}/${param}`)
}

export function getRegistryAirlines() {
  return axios.get('/api/registries/airlines')
}

export async function getRegistryAddress(registryId) {
  return axios.get(`/api/registries/addresses/${registryId}`)
}

export async function getRegistryContact(addressId) {
  return axios.get(`/api/registries/addresses/contacts/${addressId}`)
}

export function createRegistryAddress(registryId, params) {
  return axios.post(`/api/registries/addresses/create/${registryId}`, params)
}

export function editRegistryAddress(addressId, params) {
  return axios.post(`/api/registries/addresses/edit/${addressId}`, params)
}

export function deleteRegistryAddress(addressId) {
  return axios.get(`/api/registries/addresses/delete/${addressId}`)
}

export function createRegistryContact(addressId, params) {
  return axios.post(
    `/api/registries/addresses/contacts/create${addressId ? `/${addressId}` : ''}`,
    params
  )
}

export function editRegistryContact(contactId, params) {
  return axios.post(`/api/registries/addresses/contacts/edit/${contactId}`, params)
}

export function deleteRegistryContact(contactId) {
  return axios.get(`/api/registries/addresses/contacts/delete/${contactId}`)
}

export function getRegistryContacts(registryId) {
  return axios.get(`/api/registries/contacts${registryId ? `/${registryId}` : ''}`)
}

export function getRegistryContactDetails(contactId) {
  return axios.get(`/api/registries/addresses/contacts/fetch/${contactId}`)
}

export function getRegistryContactMails(contactId, params) {
  return axios.get(`/api/registries/addresses/contacts/mails/${contactId}`, { params })
}

export function getRegistryContactWhatsapp(contactId, params) {
  return axios.get(`/api/registries/addresses/contacts/whatsapp-messages/${contactId}`, { params })
}

export function changeCompanyLogo(registryId, params) {
  return axios.post(`/api/registries/logo/${registryId}`, params)
}

export async function getContactsAutocomplete(term, controller) {
  return axios.post(
    '/api/registries/addresses/contacts/autocomplete',
    { term },
    {
      signal: controller?.signal,
    }
  )
}

export async function getTransportationUnitAutocomplete(term, controller) {
  return axios.post(
    '/api/means/autocomplete',
    { term },
    {
      signal: controller?.signal,
    }
  )
}

export function getMultipleTransportationUnit(param) {
  return axios.post(`/api/means/fetch`, param)
}

export function reportPerformance({ filters }) {
  return axios.post('/api/reports/sales/registries/performance', { filters })
}

export function deactivateRegistry(registryId) {
  return axios.get(`/api/registries/activation/${registryId}`)
}
