import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

// Apis
import { deleteInvoice } from './../invoice-requests'

// Interfaces
import { useFlags, useRoyanTable } from '@royan-co/user-interface'

const useDeleteInvoice = ({ invoiceId, shipmentId, type }) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { refresh } = useRoyanTable()
  const { showSuccessFlag } = useFlags()

  return useMutation({
    mutationFn: () => deleteInvoice(invoiceId),

    onSuccess: (res) => {
      showSuccessFlag(res.message)

      if (shipmentId) {
        queryClient.setQueryData(['shipment', 'invoices', shipmentId], (oldInvoices = []) =>
          oldInvoices?.filter((i) => i.id_auto !== invoiceId)
        )
      } else if (refresh) {
        refresh()
      } else navigate(`/panel/${type === 'active' ? 'active-invoices' : 'passive-invoices'}`)
    },

    onError: (e) => {
      console.error(e)
    },
  })
}

export { useDeleteInvoice }
