//Icons
import {
  TruckIcon,
  PackageIcon,
  TruckLowIcon,
  ContainerIcon,
  AirplaneTakeOffIcon,
} from '@royan-co/user-interface'

//Components
import ShipmentCreationGroupageFields from './GroupageFields'
import ShipmentCreationDedicatedFields from './DedicatedFields'
import ShipmentCreationGroupageSummary from './GroupageSummary'
import ShipmentCreationDedicatedSummary from './DedicatedSummary'

export const modes = [
  {
    id: 1,
    shipmentType: 2,
    typeOfCollies: 0,
    title: 'Air Freight',
    service: 'Air Freight',
    description: 'Service',
    icon: AirplaneTakeOffIcon,
    cargoTypeKey: '',
    cargoKey: 'packages',
    cargoFields: ShipmentCreationGroupageFields,
    cargoSummary: ShipmentCreationGroupageSummary,
  },
  {
    id: 2,
    shipmentType: 1,
    typeOfCollies: 1,
    title: 'Dedicated',
    service: 'Dedicated container',
    description: 'Container',
    icon: ContainerIcon,
    cargoTypeKey: 'container_types',
    cargoKey: 'containers',
    cargoFields: ShipmentCreationDedicatedFields,
    cargoSummary: ShipmentCreationDedicatedSummary,
  },
  {
    id: 3,
    shipmentType: 0,
    typeOfCollies: 1,
    title: 'Dedicated',
    service: 'Dedicated truck',
    description: 'Truck',
    icon: TruckIcon,
    cargoTypeKey: 'truck_types',
    cargoKey: 'trucks',
    cargoFields: ShipmentCreationDedicatedFields,
    cargoSummary: ShipmentCreationDedicatedSummary,
  },
  {
    id: 4,
    shipmentType: 0,
    typeOfCollies: 0,
    title: 'Groupage',
    service: 'Groupage service',
    description: 'Land',
    icon: TruckLowIcon,
    cargoTypeKey: '',
    cargoKey: 'packages',
    cargoFields: ShipmentCreationGroupageFields,
    cargoSummary: ShipmentCreationGroupageSummary,
  },
  {
    id: 5,
    shipmentType: 1,
    typeOfCollies: 0,
    title: 'Groupage',
    service: 'Groupage service',
    description: 'Sea',
    icon: PackageIcon,
    cargoTypeKey: '',
    cargoKey: 'packages',
    cargoFields: ShipmentCreationGroupageFields,
    cargoSummary: ShipmentCreationGroupageSummary,
  },
]
