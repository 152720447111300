import { useNavigate, useParams } from 'react-router-dom'

//Interfaces
import { RoyanTable } from '@royan-co/user-interface'

import { RegistryShipmentColumns } from 'components/Registries/View/shipment-columns'
import { getRegistryShipments } from 'api/registries'

const RegistryViewShipmentsPage = () => {
  const { registryId } = useParams()
  const navigate = useNavigate()

  const handleDoubleClick = (e, item) => {
    navigate(`/panel/shipments/${item.id_auto}`)
  }

  return (
    <RoyanTable
      filters={[]}
      name="registry_shipments"
      columnsControl={false}
      columns={RegistryShipmentColumns}
      request={getRegistryShipments.bind(null, registryId)}
      onRowDoubleClick={handleDoubleClick}
    />
  )
}

export default RegistryViewShipmentsPage
