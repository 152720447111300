// Icons
import LocationIcon from '@atlaskit/icon/glyph/location'
import MarketplaceIcon from '@atlaskit/icon/glyph/marketplace'
import StarIcon from '@atlaskit/icon/glyph/star'
import { AirFreightIcon } from '@royan-co/user-interface'

export const nodeTypes = [
  {
    label: 'Zone',
    value: 0,
    icon: <LocationIcon />,
    type: 'zone',
    departureKey: 'departure_zone',
    arrivalKey: 'arrival_zone',
  },
  {
    label: 'Hub',
    value: 1,
    icon: <MarketplaceIcon />,
    type: 'hub',
    departureKey: 'departure_hub',
    arrivalKey: 'arrival_hub',
  },
  {
    label: 'Airport',
    value: 2,
    icon: <AirFreightIcon />,
    type: 'airport',
    departureKey: 'departure_airport',
    arrivalKey: 'arrival_airport',
  },
  {
    label: 'Sea port',
    value: 3,
    icon: <StarIcon />,
    type: 'port',
    departureKey: 'departure_port',
    arrivalKey: 'arrival_port',
  },
  {
    label: 'Rail statio',
    value: 4,
    icon: <StarIcon />,
    type: 'station',
    departureKey: 'departure_station',
    arrivalKey: 'arrival_station',
  },
]
