import { useEffect } from 'react'

//Atlassian
import { Checkbox } from '@atlaskit/checkbox'
import { DatePicker } from '@atlaskit/datetime-picker'

//Interfaces
import { Field, useFormContext, useFormValues } from '@royan-co/user-interface'

//Components
import Card from 'components/UI/Card'
import Divider from 'components/UI/Divider'
import TripleFieldsRegistry from 'components/Shipments/View/TripleFieldsRegistry'
import { registries } from './registries'

const consigneeFields = registries.consignee_fields
const secondConsigneeFields = registries.second_consignee_fields
const deliveryFields = registries.delivery_fields
const notifyFields = registries.notify_fields

const ShipmentViewEditRegistriesConsignee = () => {
  const { setValue } = useFormContext()

  const {
    notify_as_Consignee: notifyAsConsignee,
    delivery_as_Consignee: deliveryAsConsignee,
    second_set: secondSet,
    [consigneeFields.names.registry]: consignee,
    [consigneeFields.names.address]: consigneeAddress,
    [consigneeFields.names.contact]: consigneeContact,
  } = useFormValues()

  useEffect(() => {
    if (deliveryAsConsignee) {
      setValue(deliveryFields.names.registry, consignee)
      setValue(deliveryFields.names.address, consigneeAddress)
      setValue(deliveryFields.names.contact, consigneeContact)
    }
  }, [setValue, deliveryAsConsignee, consignee, consigneeAddress, consigneeContact])

  useEffect(() => {
    if (notifyAsConsignee) {
      setValue(notifyFields.names.registry, consignee)
      setValue(notifyFields.names.address, consigneeAddress)
      setValue(notifyFields.names.contact, consigneeContact)
    }
  }, [setValue, notifyAsConsignee, consignee, consigneeAddress, consigneeContact])

  return (
    <Card className="w-full my-6">
      <TripleFieldsRegistry names={consigneeFields.names} labels={consigneeFields.labels} />

      {!!secondSet && (
        <TripleFieldsRegistry
          className="!mt-4"
          names={secondConsigneeFields.names}
          labels={secondConsigneeFields.labels}
        />
      )}

      <Divider className="mt-8 mb-6" />

      <Field
        label="Delivery information"
        type="checkbox"
        name="delivery_as_Consignee"
        className="!mt-0"
      >
        {({ fieldProps }) => (
          <Checkbox label="Delivery address is the same as consignee’s address" {...fieldProps} />
        )}
      </Field>

      {!deliveryAsConsignee && (
        <TripleFieldsRegistry
          className="!mt-2"
          names={deliveryFields.names}
          labels={deliveryFields.labels}
        />
      )}

      <Field label="Delivery date" name="delivery_date" className="!mt-4">
        {({ fieldProps }) => (
          <DatePicker placeholder="2/18/2022" spacing="compact" {...fieldProps} />
        )}
      </Field>

      <Divider className="mt-8 mb-6" />

      <Field type="checkbox" className="!mt-0" label="Notify details" name="notify_as_Consignee">
        {({ fieldProps }) => <Checkbox label="Notify is the same as consignee" {...fieldProps} />}
      </Field>

      {!notifyAsConsignee && (
        <TripleFieldsRegistry
          className="!mt-2"
          names={notifyFields.names}
          labels={notifyFields.labels}
        />
      )}
    </Card>
  )
}

export default ShipmentViewEditRegistriesConsignee
