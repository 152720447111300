import { modes } from './modes'
import { createElement, useMemo } from 'react'
import { useWizardFormContext } from '@royan-co/user-interface'

const ShipmentCreationCargoHeader = () => {
  const {
    stepsValue: { routing },
  } = useWizardFormContext()

  const icon = useMemo(() => {
    const mode = modes.find((m) => m.id === routing.modeType)

    return createElement(mode.icon, { size: 32, className: 'text-gray-8' })
  }, [routing?.modeType])

  return (
    <div className="shadow rounded p-4 flex justify-around items-center">
      <div className="text-center">
        <div className="font-semibold">Departure</div>
        <small className="block text-neutral-200 mt-2">
          {[routing?.placeOfLoading?.city, routing?.placeOfLoading?.country]
            .filter((i) => i)
            .join(', ')}
        </small>
      </div>

      <div className="flex-grow text-center flex items-center" style={{ maxWidth: '250px' }}>
        <div className="flex-grow mr-4 border-b border-dashed border-neutral-200" />
        {icon}
        <div className="flex-grow ml-4 border-b border-dashed border-neutral-200" />
      </div>

      <div className="text-center">
        <div className="font-semibold">Departure</div>
        <small className="block text-neutral-200 mt-2">
          {[routing?.placeOfDelivery?.city, routing?.placeOfDelivery?.country]
            .filter((i) => i)
            .join(', ')}
        </small>
      </div>
    </div>
  )
}

export default ShipmentCreationCargoHeader
