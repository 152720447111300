import { useMemo } from 'react'
import { Link } from 'react-router-dom'

// Utils
import { formatNumber, formatMonetaryNumber } from 'utils/numbers'

// Atlassian
import Checkbox from '@atlaskit/checkbox'
import Textfield from '@atlaskit/textfield'
import { DynamicTableStateless } from '@atlaskit/dynamic-table'
import Lozenge from '@atlaskit/lozenge'
import { ErrorMessage } from '@atlaskit/form'

// Interfaces
import {
  Field,
  useFieldArray,
  useFormContext,
  useFormValues,
  ShortcutIcon,
} from '@royan-co/user-interface'

const LinkInvoiceTableFields = ({ isCumulative }) => {
  const formValues = useFormValues()
  const { watch, setValue } = useFormContext()

  const { fields: rowsFields } = useFieldArray({ name: 'rows' })

  const selectedRows = useMemo(() => formValues?.rows?.filter((r) => !!r.checked), [formValues])

  const toggleAll = (checked) => {
    formValues?.rows?.forEach((row, i) => {
      setValue(`rows.${i}.checked`, checked)
    })
  }

  const rowsHead = useMemo(() => {
    return {
      cells: [
        {
          key: 'checkbox',
          content: (
            <>
              <Checkbox
                onChange={(e) => toggleAll(e.target.checked)}
                isChecked={selectedRows?.length > 0}
                isIndeterminate={
                  selectedRows?.length > 0 && formValues.rows?.length > selectedRows?.length
                }
              />
            </>
          ),
        },
        {
          key: 'code',
          content: 'Code',
        },
        !isCumulative
          ? {
              key: 'supplier',
              content: 'Supplier',
            }
          : null,
        {
          key: 'description',
          content: 'Description',
        },
        !isCumulative
          ? {
              key: 'cost',
              content: 'Cost',
            }
          : null,
        !isCumulative
          ? {
              key: 'real_cost',
              content: 'Real cost',
            }
          : null,
        isCumulative
          ? {
              key: 'quantity',
              content: 'Quantity',
            }
          : null,
        {
          key: 'quotation',
          content: 'Quotation',
        },
      ].filter((cell) => cell !== null),
    }
  }, [isCumulative])

  const invoiceRows = useMemo(() => {
    let rowsItems = rowsFields.map((item, i) => ({
      key: item.id,
      cells: [
        {
          content: (
            <Field type="checkbox" name={`rows.${i}.checked`} className="!mt-0 !cursor-pointer">
              {({ fieldProps }) => <Checkbox {...fieldProps} />}
            </Field>
          ),
        },
        {
          content: isCumulative
            ? item?.service_code
            : item?.cost_code || <Lozenge>Not set</Lozenge>,
        },
        !isCumulative
          ? {
              content: item?.supplier?.id_auto ? (
                <Link to={`/panel/registries/${item.supplier.id_auto}`}>
                  {item.supplier?.company_name} <ShortcutIcon size={16} />
                </Link>
              ) : (
                <Lozenge>Not set</Lozenge>
              ),
            }
          : null,
        {
          content: isCumulative ? (
            <small>{item?.description}</small>
          ) : (
            <small>{item?.service}</small> || <Lozenge>Not set</Lozenge>
          ),
        },
        !isCumulative
          ? {
              content: (
                <span className="whitespace-nowrap">
                  {' '}
                  {formatMonetaryNumber(item?.cost) + ' EUR'}{' '}
                </span>
              ),
            }
          : null,
        !isCumulative
          ? {
              content: (
                <div style={{ width: '100px' }}>
                  <Field
                    name={`rows.${i}.real_cost`}
                    className="!mt-0"
                    onClick={() => (!item.checked ? setValue(`rows.${i}.checked`, true) : null)}
                    validate={(cost) =>
                      isNaN(+cost) && !!watch(`rows.${i}.checked`)
                        ? `Type of the real cost should be a number.`
                        : true
                    }
                  >
                    {({ fieldProps, error }) => (
                      <>
                        <Textfield {...fieldProps} />
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                      </>
                    )}
                  </Field>
                </div>
              ),
            }
          : null,
        isCumulative
          ? {
              content: <span className="whitespace-nowrap">{formatNumber(item?.quantity)}</span>,
            }
          : null,
        {
          content: (
            <span className="whitespace-nowrap">
              {isCumulative ? (
                <>{formatMonetaryNumber(item?.sell_price) + ' EUR'} </>
              ) : (
                <>{formatMonetaryNumber(item?.quotation) + ' EUR'} </>
              )}
            </span>
          ),
        },
      ].filter((cell) => cell !== null),
    }))

    return rowsItems
  }, [rowsFields, setValue, watch, isCumulative])

  return (
    <div className="overflow-auto w-full inline-block mt-6 -mb-6">
      <DynamicTableStateless head={rowsHead} rows={invoiceRows} />
    </div>
  )
}

export default LinkInvoiceTableFields
