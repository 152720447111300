import React, { useState } from 'react'

// Hooks
import { useDeleteVehicleType } from '../api/useDeleteVehicleType'

//Atlassian
import Modal, {
  ModalBody,
  ModalTitle,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import Button, { LoadingButton } from '@atlaskit/button'

//Interfaces
import { useFlags, WarningBanner } from '@royan-co/user-interface'

const VehicleTypeDeleteModal = ({ isOpen, onClose, typeId }) => {
  const [error, setError] = useState('')
  const { showSuccessFlag } = useFlags()

  const vehicleTypeDeleteMutation = useDeleteVehicleType(typeId)

  const closeModal = () => {
    onClose()
    vehicleTypeDeleteMutation.reset()
  }

  const removeVehicleType = () => {
    setError('')

    vehicleTypeDeleteMutation.mutate(typeId, {
      onError: (e) => {
        setError(e?.response?.data?.message || 'Something wrong on remove branch.')
      },
      onSuccess: (res) => {
        showSuccessFlag(res.message)
        onClose()
      },
    })
  }

  return (
    <>
      <ModalTransition>
        {isOpen && (
          <Modal onClose={closeModal}>
            <ModalHeader>
              <ModalTitle appearance="danger">Delete vehicle type</ModalTitle>
            </ModalHeader>
            <ModalBody>
              You are about to delete a vehicle type. Are you sure?
              {vehicleTypeDeleteMutation.isError && <WarningBanner text={error} />}
            </ModalBody>
            <ModalFooter>
              <Button appearance="subtle" onClick={closeModal}>
                Cancel
              </Button>
              <LoadingButton
                isLoading={vehicleTypeDeleteMutation.isLoading}
                appearance="danger"
                onClick={removeVehicleType}
                autoFocus
              >
                Delete
              </LoadingButton>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </>
  )
}

export default VehicleTypeDeleteModal
