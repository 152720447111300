export const canAccess = ({ resource, action, permissions, userPermissions }) => {
  const permission = permissions.find((p) => p.resource === resource && p.action === action)

  if (!permission) return false

  if (permission.permissionKey) {
    return userPermissions.includes(permission.permissionKey)
  }

  return true
}
