import styles from './StepLocationIndicator.module.scss'

const StepIndicator = ({ className, children, onLineSelect, hasInvisibleLeg, lastIndex }) => {
  return (
    <div className={styles.progressIndicator__item + ` ${className}`}>
      {children}

      <span
        className={`${styles.progressIndicator__line} ${
          !hasInvisibleLeg && !lastIndex ? 'cursor-pointer' : ''
        }`}
        onClick={onLineSelect}
      />
    </div>
  )
}

export default StepIndicator
