// Utils
import { transformSelectField } from 'utils/helpers'
import { stockAvailabilityOptions } from './../../utils/constants'

// Atlassian
import Select from '@atlaskit/select'
import { DatePicker } from '@atlaskit/datetime-picker'

// Interfaces
import { Field } from '@royan-co/user-interface'

// Hooks
import useRegistryAirlines from 'hooks/useRegistryAirlines'

const AirWaybillsStocksFilterFields = ({ appliedFilters }) => {
  const { registryAirlineOptions, isLoadingRegistryAirlines } = useRegistryAirlines()

  return (
    <>
      <Field
        label="Airline"
        type="select"
        name="airline"
        transform={transformSelectField(registryAirlineOptions)}
      >
        {({ fieldProps }) => (
          <Select
            isClearable={true}
            isLoading={isLoadingRegistryAirlines}
            options={registryAirlineOptions}
            placeholder="Choose an airline"
            {...fieldProps}
          />
        )}
      </Field>

      <Field
        type="select"
        label="Availability"
        name="has_free"
        transform={transformSelectField(stockAvailabilityOptions)}
      >
        {({ fieldProps }) => (
          <Select
            isClearable={true}
            placeholder="Choose"
            options={stockAvailabilityOptions}
            {...fieldProps}
          />
        )}
      </Field>

      <div className="grid md:grid-cols-2 md:gap-4">
        <Field label="Start date" name="start_date" defaultValue={appliedFilters.start_date || ''}>
          {({ fieldProps }) => <DatePicker placeholder=" " {...fieldProps} />}
        </Field>

        <Field label="End date" name="end_date" defaultValue={appliedFilters.end_date || ''}>
          {({ fieldProps }) => <DatePicker placeholder=" " {...fieldProps} />}
        </Field>
      </div>

      <div className="grid md:grid-cols-2 md:gap-4">
        <Field
          label="Created from"
          name="created_from"
          defaultValue={appliedFilters.created_from || ''}
        >
          {({ fieldProps }) => <DatePicker placeholder=" " {...fieldProps} />}
        </Field>

        <Field label="Created to" name="created_to" defaultValue={appliedFilters.created_to || ''}>
          {({ fieldProps }) => <DatePicker placeholder=" " {...fieldProps} />}
        </Field>
      </div>
    </>
  )
}

export default AirWaybillsStocksFilterFields
