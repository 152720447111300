import { useCallback, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  deleteShipmentDocument,
  changeVisibilityShipmentDocument,
  getShipmentDocuments,
} from 'api/shipments'

// Contexts
import { useAuth } from 'contexts/AuthProvider'

// Hooks
import useShipment from 'hooks/useShipment'

// Atlassian
import Button, { ButtonGroup } from '@atlaskit/button'
import Spinner from '@atlaskit/spinner'
import EmptyState from '@atlaskit/empty-state'
import SectionMessage from '@atlaskit/section-message'
import { DynamicTableStateless } from '@atlaskit/dynamic-table'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'
import { Checkbox } from '@atlaskit/checkbox'
import Lozenge from '@atlaskit/lozenge'

// Components
import SearchInput from 'components/UI/SearchInput'
import ShipmentUploadDocument from 'components/Shipments/View/UploadDocument'

//Interfaces
import { DotsIcon, useFlags, useDisclosure } from '@royan-co/user-interface'

// Features
import { useCanAccess } from 'features/auth'

// Components
import DeleteDocumentModal from 'components/Consolidations/View/DeleteDocumentModal'
import { generateShipmentUserPermissions } from 'components/Shipments/Create/shipment-user-permissions'

const ShipmentViewDocumentPage = () => {
  const { shipmentId, quotationId } = useParams()
  const entityId = shipmentId || quotationId
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { showSuccessFlag, showWarningFlag } = useFlags()

  const { currentUser, isWarehouseUser } = useAuth()
  const { shipment } = useShipment()

  const [searchTerm, setSearchTerm] = useState('')
  const [selectedDocuments, setSelectedDocuments] = useState([])
  const [openDeletingDocumentModal, deletingDocumentModalHandlers] = useDisclosure('delete')

  const {
    data = [],
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ['shipment', 'documents', entityId],
    queryFn: () => getShipmentDocuments(entityId),
  })

  const changeVisibilityMutation = useMutation(({ documentId, data }) =>
    changeVisibilityShipmentDocument(documentId, data)
  )

  const canMailDocument = useCanAccess({
    resource: 'shipments.document',
    action: 'mailDocument',
    userPermissions: generateShipmentUserPermissions(shipment, currentUser),
  })

  const canDeleteDocument = useCanAccess({
    resource: 'shipments.document',
    action: 'delete',
    userPermissions: generateShipmentUserPermissions(shipment, currentUser),
  })

  const canEditDocument = useCanAccess({
    resource: 'shipments.document',
    action: 'edit',
    userPermissions: generateShipmentUserPermissions(shipment, currentUser),
  })

  const getUserAccessToDeleteDocument = useCallback(
    (creatorId) => {
      if (!isWarehouseUser) return canDeleteDocument
      else {
        return currentUser.id === creatorId ? true : false
      }
    },
    [canDeleteDocument, currentUser.id, isWarehouseUser]
  )

  const canChangeVisibilityDocument = useCanAccess({
    resource: 'shipments.document',
    action: 'changeVisibility',
    userPermissions: generateShipmentUserPermissions(shipment, currentUser),
  })

  const onChangeSelectedDocuments = (documentId) => {
    setSelectedDocuments((prevSelectedDocuments) => {
      if (prevSelectedDocuments.includes(documentId)) {
        return prevSelectedDocuments.filter((id) => id !== documentId)
      } else {
        return [...prevSelectedDocuments, documentId]
      }
    })
  }

  const DocumentDeleteMutation = useMutation(deleteShipmentDocument)

  const handleDeleteDocument = () => {
    const documentID = deletingDocumentModalHandlers?.data?.id

    DocumentDeleteMutation.mutate(documentID, {
      onSuccess: (res) => {
        queryClient.setQueryData(['shipment', 'documents', entityId], (docs) =>
          docs.filter((d) => d.id !== documentID)
        )
        showSuccessFlag(res?.message)
        deletingDocumentModalHandlers.close()
      },
    })
  }

  const closeDeleteModal = () => {
    DocumentDeleteMutation.reset()
    deletingDocumentModalHandlers.close()
  }

  const handleDownload = (url) => {
    window.open(url, '_blank')
  }

  const navigateToDocumentsPage = useCallback(
    (documentId) => {
      let pickupId = ''
      let _selectedDocuments = selectedDocuments

      if (documentId) {
        _selectedDocuments = [documentId]
      }

      if (_selectedDocuments.length < 1) return

      if (_selectedDocuments?.length === 1) {
        pickupId = data?.find((d) => d.id === _selectedDocuments[0])?.pickup?.carrier
      }

      navigate(`/panel/shipments/${shipmentId}/mail-document`, {
        state: {
          pickupId,
          selectedDocuments: _selectedDocuments,
        },
      })
    },
    [data, navigate, selectedDocuments, shipmentId]
  )

  const handleChangeVisibility = useCallback(
    (documentId, currentVisibility) => {
      const data = {
        visibility: currentVisibility ? 0 : 1,
      }

      changeVisibilityMutation.mutate(
        { data, documentId },
        {
          onError: (e) => {
            showWarningFlag('Something wrong happened.')
            console.error(e)
          },
          onSuccess: (res) => {
            queryClient.setQueryData(['shipment', 'documents', entityId], (docs) =>
              docs.map((d) => {
                if (d.id === documentId) return res.document
                else return d
              })
            )

            showSuccessFlag(res.message)
          },
          onSettled: () => {
            // for close dropdown popup
            const event = new MouseEvent('click')
            document.body.dispatchEvent(event)
          },
        }
      )
    },
    [changeVisibilityMutation, queryClient, entityId, showSuccessFlag, showWarningFlag]
  )

  const getEditTypePath = useCallback((document, shipmentId) => {
    const types = {
      awb: `/panel/shipments/document/airwayBill/${document.awb}/edit`,
      shipment_fbl_id: `/panel/shipments/document/fbl/${document.shipment_fbl_id}/edit`,
      shipment_cmr_id: `/panel/shipments/document/cmr/${document.shipment_cmr_id}/edit`,
      shipment_pick_up_id: `/panel/shipments/${shipmentId}/cargo-collection/${document.shipment_pick_up_id}`,
    }

    const documentKey = ['awb', 'shipment_pick_up_id', 'shipment_fbl_id', 'shipment_cmr_id'].find(
      (key) => (!!document[key] ? true : false)
    )

    return documentKey ? types[documentKey] : null
  }, [])

  const getViewDocumentPath = useCallback((document) => {
    const types = {
      awb: `/panel/shipments/document/airwayBill/${document.awb}`,
      shipment_fbl_id: `/panel/shipments/document/fbl/${document.shipment_fbl_id}`,
      shipment_cmr_id: `/panel/shipments/document/cmr/${document.shipment_cmr_id}`,
      seawaybill_id: `/panel/shipments/document/seawayBill/${document.seawaybill_id}`,
    }

    const documentKey = ['awb', 'shipment_fbl_id', 'shipment_cmr_id', 'seawaybill_id'].find((key) =>
      !!document[key] ? true : false
    )

    return documentKey ? types[documentKey] : null
  }, [])

  const navigateToDocumentPage = useCallback(
    (pathname) => {
      navigate(pathname, { state: { from: `/panel/shipments/${shipmentId}/documents` } })
    },
    [navigate, shipmentId]
  )

  const handleRowClick = useCallback(
    (e, document) => {
      if (e.detail === 2) {
        if (getViewDocumentPath(document)) {
          navigateToDocumentPage(getViewDocumentPath(document))
        } else {
          handleDownload(document?.url)
        }
      }
    },
    [getViewDocumentPath, navigateToDocumentPage]
  )

  const tableHead = useMemo(() => {
    let _tableHead = []
    if (shipmentId && canMailDocument) _tableHead.push({ key: 'checkbox', content: '' })

    return {
      cells: [
        ..._tableHead,
        { key: 'title', content: 'Title' },
        { key: 'visibility', content: 'Visibility for client' },
        { key: 'creator', content: 'Creator' },
        { key: 'creation_date', content: 'Creation date' },
        { key: 'actions', content: 'Actions' },
      ],
    }
  }, [canMailDocument, shipmentId])

  const tableRows = useMemo(() => {
    return data
      .filter((d) => d.name?.toLocaleLowerCase()?.includes(searchTerm.toLocaleLowerCase()))
      .map((d) => {
        let _data = []
        if (shipmentId && canMailDocument)
          _data.push({
            content: (
              <Checkbox
                onChange={() => {
                  onChangeSelectedDocuments(d.id)
                }}
              />
            ),
          })

        _data.push(
          {
            content: d.name,
          },
          {
            content: !!d.tracking_share ? (
              <Lozenge appearance="success">Visible</Lozenge>
            ) : (
              <Lozenge appearance="removed">Hidden</Lozenge>
            ),
          },
          {
            content: d?.creator?.full_name,
          },
          {
            content: d.creation_date || '-',
          },
          {
            content: (
              <DropdownMenu
                placement="bottom-end"
                trigger={({ triggerRef, ...props }) => (
                  <Button
                    ref={triggerRef}
                    appearance="subtle"
                    iconBefore={<DotsIcon />}
                    {...props}
                  />
                )}
              >
                <DropdownItemGroup>
                  <div style={{ minWidth: '160px' }}>
                    {getViewDocumentPath(d) ? (
                      <DropdownItem onClick={() => navigateToDocumentPage(getViewDocumentPath(d))}>
                        View
                      </DropdownItem>
                    ) : (
                      <DropdownItem onClick={() => handleDownload(d?.url)}>Download</DropdownItem>
                    )}

                    {shipmentId && canEditDocument && getEditTypePath(d, shipmentId) ? (
                      <DropdownItem
                        onClick={() => navigateToDocumentPage(getEditTypePath(d, shipmentId))}
                      >
                        Edit
                      </DropdownItem>
                    ) : null}

                    {shipmentId && canMailDocument && (
                      <DropdownItem onClick={() => navigateToDocumentsPage(d.id)}>
                        Mail document
                      </DropdownItem>
                    )}

                    {canChangeVisibilityDocument && (
                      <DropdownItem
                        isDisabled={changeVisibilityMutation.isLoading}
                        onClick={(e) => {
                          e.stopPropagation()
                          handleChangeVisibility(d.id, d.tracking_share)
                        }}
                      >
                        Change visibility
                        {changeVisibilityMutation.isLoading && (
                          <span className="ml-2">
                            <Spinner size="small" />
                          </span>
                        )}
                      </DropdownItem>
                    )}

                    {getUserAccessToDeleteDocument(d.creator_user) && (
                      <DropdownItem
                        children="Delete"
                        onClick={() => deletingDocumentModalHandlers.open(d)}
                      />
                    )}
                  </div>
                </DropdownItemGroup>
              </DropdownMenu>
            ),
          }
        )

        return {
          key: d.id,
          onClick: (e) => handleRowClick(e, d),
          cells: _data,
        }
      })
  }, [
    data,
    searchTerm,
    canMailDocument,
    getViewDocumentPath,
    canEditDocument,
    getEditTypePath,
    shipmentId,
    canChangeVisibilityDocument,
    changeVisibilityMutation.isLoading,
    getUserAccessToDeleteDocument,
    navigateToDocumentPage,
    navigateToDocumentsPage,
    handleChangeVisibility,
    deletingDocumentModalHandlers,
    handleRowClick,
  ])

  return (
    <>
      {isLoading ? (
        <div className="text-center mt-24">
          <Spinner />
        </div>
      ) : isError ? (
        <SectionMessage
          appearance="warning"
          title="Something wrong on loading documents, please retry."
        >
          <Button onClick={() => refetch()}>Retry</Button>
        </SectionMessage>
      ) : (
        <>
          <div className="flex justify-between">
            <div style={{ maxWidth: '240px', marginRight: '5px' }}>
              <SearchInput onSubmit={setSearchTerm} delay={150} />
            </div>

            <ButtonGroup>
              {shipmentId && canMailDocument && (
                <Button
                  onClick={() => navigateToDocumentsPage()}
                  isDisabled={!selectedDocuments.length}
                >
                  Mail documents
                </Button>
              )}
              <ShipmentUploadDocument />
            </ButtonGroup>
          </div>

          <div className="shadow rounded p-4 mt-4">
            {tableRows.length === 0 ? (
              <EmptyState header="No documents" />
            ) : (
              <div className="inline-block w-full -mb-6">
                <DynamicTableStateless head={tableHead} rows={tableRows} />
              </div>
            )}
          </div>

          <DeleteDocumentModal
            isOpen={openDeletingDocumentModal}
            onClose={closeDeleteModal}
            document={deletingDocumentModalHandlers.data}
            onSubmit={handleDeleteDocument}
            isError={DocumentDeleteMutation.isError}
            isLoading={DocumentDeleteMutation.isLoading}
          />
        </>
      )}
    </>
  )
}

export default ShipmentViewDocumentPage
