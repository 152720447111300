import { forwardRef, useCallback, useMemo, useState } from 'react'
import { NumericFormat } from 'react-number-format'

//Atlassian
import Textfield from '@atlaskit/textfield'

const formats = [{ locale: 'en-US', thousandSeparator: ',', decimalSeparator: '.' }]

const NumericInput = forwardRef(
  ({ locale = 'en-US', value, onChange, placeholder, isCompact, ...props }, ref) => {
    const [formattedValue, setFormattedValue] = useState(value)
    const { thousandSeparator, decimalSeparator } = useMemo(
      () => formats.find((f) => f.locale === locale) || {},
      [locale]
    )

    const handleChange = (e) => {
      const inputValue = e.target.value
      setFormattedValue(inputValue)
      onChange(inputValue.replaceAll(thousandSeparator, ''))
    }

    const customInput = useCallback(
      (props) => <Textfield placeholder={placeholder} isCompact={isCompact} {...props} />,
      [isCompact, placeholder]
    )

    return (
      <NumericFormat
        {...props}
        value={formattedValue}
        onChange={handleChange}
        allowNegative={false}
        customInput={customInput}
        thousandSeparator={thousandSeparator}
        decimalSeparator={decimalSeparator}
      />
    )
  }
)

export default NumericInput
