import { useMemo } from 'react'
import { getRecordsRate } from 'api/rates'
import { useQuery, useQueryClient } from '@tanstack/react-query'

const useRateRecords = (rateId, { recordId, enabled = true } = {}) => {
  const queryClient = useQueryClient()

  const {
    data: records = [],
    isLoading,
    isError,
    isFetching,
    refetch,
  } = useQuery(['records', rateId], () => getRecordsRate(rateId), {
    staleTime: Infinity,
    enabled,
  })

  const record = useMemo(() => {
    if (!recordId) return null

    return records?.find((r) => r.id_auto === parseInt(recordId))
  }, [recordId, records])

  const deleteRecordFromData = (deletedId) => {
    queryClient.setQueryData(['records', rateId], (oldRecords) =>
      oldRecords.filter((r) => r.id_auto !== deletedId)
    )
  }

  const invalidate = () => {
    queryClient.invalidateQueries({ queryKey: ['records', rateId], refetchType: 'none' })
  }

  return {
    records,
    record,
    isLoadingRateRecords: isLoading,
    isErrorRateRecords: isError,
    isFetchingRateRecords: isFetching,
    refetchRateRecords: refetch,
    deleteRecordFromData,
    invalidate,
  }
}

export default useRateRecords
