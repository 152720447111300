import { useMutation } from '@tanstack/react-query'
import { adrPackagingGroupOptions } from '../constants'
import { useCallback, useEffect, useMemo, useState } from 'react'

// Apis
import { editShipmentSpecialConditions } from 'api/shipments'

// Hooks
import useShipment from 'hooks/useShipment'
import useShipmentsEnumerations from 'hooks/useShipmentsEnumerations'

// Utils
import { findOption } from 'utils/helpers'
import { handleErrorOnSubmit } from 'utils/errors'
import { formatMonetaryNumber } from 'utils/numbers'

// Atlassian
import Spinner from '@atlaskit/spinner'
import Lozenge from '@atlaskit/lozenge'
import Tooltip from '@atlaskit/tooltip'
import Button from '@atlaskit/button'

// Interfaces
import { CautionIcon, useDisclosure, useFlags } from '@royan-co/user-interface'

//Components
import Card, { CardTitle } from 'components/UI/Card'
import RowTable from 'components/UI/RowTable'
import TemperatureTag from 'components/Shipments/TemperatureTag'
import SpecialConditionsDrawer from './SpecialConditionsDerawer'

const ShipmentViewCargoSpecialConditions = ({ canEditSpecialConditions }) => {
  const { shipment, upsertShipmentData } = useShipment()
  const { showSuccessFlag } = useFlags()
  const { getEnumerationOptionsByKey, isLoadingShipmentEnumeration } = useShipmentsEnumerations()

  const [adrClass, setAdrClass] = useState()
  const [isOpenSpecialConditionsDrawer, specialConditionsDrawerHandler] =
    useDisclosure('special-conditions')

  const editSpecialConditionsMutation = useMutation(
    editShipmentSpecialConditions.bind(null, shipment.id_auto)
  )

  const editSpecialConditions = (data, { setError }) => {
    const formData = {
      ...data,
      reefer_temperature:
        Object.keys(data?.reefer_temperature?.value?.range || {})
          ?.map((key) => data?.reefer_temperature?.value?.range[key])
          ?.toString() || null,
    }

    if (!formData.reefer) {
      delete formData.reefer_temperature
    }

    editSpecialConditionsMutation.mutate(formData, {
      onError: (e) => {
        handleErrorOnSubmit(e, setError, formData)
      },
      onSuccess: (res) => {
        upsertShipmentData(res.shipment)
        showSuccessFlag(res.message)
        specialConditionsDrawerHandler.close()
      },
    })
  }

  const { adr_class } = shipment

  const adrClasses = useCallback(() => {
    const classes = getEnumerationOptionsByKey('adr_classes')
    const classArray = Object.entries(classes).filter((item) => item[1].value === adr_class)[0]
    const classLabel = classArray?.[1].label
    setAdrClass(classLabel)
  }, [adr_class, getEnumerationOptionsByKey])

  useEffect(() => {
    adrClasses()
  }, [adrClasses])

  const specialConditionsData = useMemo(() => {
    let result = [
      {
        title: 'Temperature control',
        content: (
          <TemperatureTag reefer={shipment?.reefer} temperature={shipment?.reefer_temperature} />
        ),
      },
      {
        title: 'Dangerous cargo',
        content: (
          <Lozenge appearance={shipment?.adr_goods ? 'moved' : 'default'}>
            {shipment?.adr_goods ? 'Yes' : 'No'}
          </Lozenge>
        ),
      },
    ]

    if (shipment?.adr_goods === 1) {
      result.push(
        {
          title: 'UN number',
          content: shipment?.adr_number,
        },
        {
          title: 'Class',
          content: <div>{isLoadingShipmentEnumeration ? <Spinner size="xsmall" /> : adrClass}</div>,
        },
        {
          title: 'Packing group',
          content: findOption(adrPackagingGroupOptions, shipment?.adr_packaging_group)?.label,
        }
      )
    }

    result.push(
      {
        title: 'Chemical',
        content: (
          <div className="flex">
            <Lozenge appearance={shipment?.chemical ? 'moved' : 'default'}>
              {shipment?.chemical ? 'Yes' : 'No'}
            </Lozenge>
          </div>
        ),
      },
      {
        title: 'Pharma',
        content: (
          <div className="flex">
            <Lozenge appearance={shipment?.pharma ? 'moved' : 'default'}>
              {shipment?.pharma ? 'Yes' : 'No'}
            </Lozenge>
          </div>
        ),
      },
      {
        title: 'Hydraulic ramp',
        content: (
          <div className="flex">
            <Lozenge appearance={shipment?.hydraulic_ramp ? 'moved' : 'default'}>
              {shipment?.hydraulic_ramp ? 'Yes' : 'No'}
            </Lozenge>
          </div>
        ),
      },
      {
        title: 'Second set of documents',
        content: (
          <div className="flex">
            <Lozenge appearance={shipment?.second_set ? 'moved' : 'default'}>
              {shipment?.second_set ? 'Yes' : 'No'}
            </Lozenge>

            {shipment?.second_set === 1 && (
              <Tooltip content={<span>{shipment?.second_set_arrival}</span>}>
                {(tooltipProps) => (
                  <div className="flex items-end ml-2" {...tooltipProps}>
                    <CautionIcon size={16} />
                  </div>
                )}
              </Tooltip>
            )}
          </div>
        ),
      },
      {
        title: 'Cargo insurance',
        content: (
          <Lozenge appearance={shipment?.insurance ? 'moved' : 'default'}>
            {shipment?.insurance ? 'Yes' : 'No'}
          </Lozenge>
        ),
      }
    )

    if (shipment?.insurance === 1) {
      result.push({
        title: 'Cargo value',
        content: `${formatMonetaryNumber(shipment?.goods_value)} ${shipment?.currency}`,
      })
    }

    result.push({
      title: 'Letter of credit',
      content: (
        <div className="flex">
          <Lozenge appearance={shipment?.letter_of_credit ? 'moved' : 'default'}>
            {shipment?.letter_of_credit ? 'Yes' : 'No'}
          </Lozenge>
        </div>
      ),
    })

    return result
  }, [adrClass, isLoadingShipmentEnumeration, shipment])

  return (
    <>
      {specialConditionsData.length > 0 ? (
        <>
          <div className="flex items-center">
            <CardTitle className="mb-3">Special conditions</CardTitle>

            {canEditSpecialConditions && (
              <Button
                appearance="link"
                spacing="none"
                className="ml-auto"
                onClick={() => specialConditionsDrawerHandler.open()}
              >
                Edit conditions
              </Button>
            )}
          </div>

          <Card>
            <RowTable rows={specialConditionsData} />
          </Card>
        </>
      ) : null}

      <SpecialConditionsDrawer
        isOpen={isOpenSpecialConditionsDrawer}
        onClose={specialConditionsDrawerHandler.close}
        isLoading={editSpecialConditionsMutation.isLoading}
        onSubmit={editSpecialConditions}
        data={shipment}
      />
    </>
  )
}

export default ShipmentViewCargoSpecialConditions
