import { useSearchParams } from 'react-router-dom'
import { createContext, useContext, useMemo, useState } from 'react'

import { useQuery } from '@tanstack/react-query'

// Apis
import { getDefaultsForEditingShipmentFinance } from 'api/shipments'
import { getDefaultsForEditingConsolidationFinance } from 'api/consolidations'
import { legTypes } from 'components/Shipments/EditFinance/legTypes'
import { nodeTypes } from 'components/Shipments/EditFinance/nodeTypes'
import { createFormValues } from '../components/Shipments/EditFinance/createFormValues'

const FinanceContext = createContext()

const useFinanceEditingContext = () => {
  const context = useContext(FinanceContext)

  return context
}

const FinanceEditingProvider = ({ children, entityType, entityId }) => {
  const [searchParams] = useSearchParams()
  const [financeData, setFinanceData] = useState([])
  const [fieldErrors, setFieldErrors] = useState([])

  const financeQuery = useQuery({
    queryKey: [entityType, 'finance', 'edit', entityId],
    queryFn: () =>
      entityType === 'shipment'
        ? getDefaultsForEditingShipmentFinance(entityId)
        : getDefaultsForEditingConsolidationFinance(entityId),
    staleTime: Infinity,
    onSuccess: (res) => {
      setFinanceData(res?.map((leg) => createFormValues(leg)))
    },
    select: (res) => {
      return res.result
    },
  })

  const isEmpty = useMemo(() => !financeData?.length, [financeData])

  const currentLegIndex = useMemo(() => {
    const indexFromUrl = +searchParams.get('leg') || 0

    return indexFromUrl >= financeData.length ? 0 : indexFromUrl
  }, [financeData.length, searchParams])

  const currentLeg = useMemo(() => financeData[currentLegIndex], [financeData, currentLegIndex])

  const currentLegType = useMemo(
    () => legTypes.find((t) => t.value === currentLeg?.leg),
    [currentLeg?.leg]
  )
  const legs = useMemo(() => {
    const _legs = financeData.map((data, i) => {
      const leg = legTypes.find((leg) => leg.value === data.leg)
      const hasError = fieldErrors.some((item) => item.legId === data._id && item.fields.length)

      return {
        id: leg?.value,
        hasError,
        ...leg,
      }
    })

    return _legs.map((leg, index) =>
      index !== _legs.length - 1 && leg.arrival.value !== _legs[index + 1].departure.value
        ? { ...leg, hasInvisibleLeg: true }
        : leg
    )
  }, [fieldErrors, financeData])

  const getTypeLeg = (departure, arrival) =>
    legTypes.find((t) => t.departure.value === departure && t.arrival.value === arrival)

  const addLeg = ({ node }) => {
    const departureNode = legTypes.find(
      (t) => t.value === financeData[financeData.length - 1]?.leg
    )?.arrival
    const type = getTypeLeg(departureNode?.value, node)

    if (type?.value === undefined) {
      const error = new Error()
      error.response = {
        data: {
          message: 'This leg can not be created.',
        },
      }
      throw error
    }

    const legPlaceholder = {
      leg: type.value,
      [departureNode.departureKey]: financeData[financeData.length - 1][departureNode.arrivalKey],
      suppliers: [],
      accessories: [],
    }

    setFinanceData((data) => {
      return [...data, legPlaceholder]
    })
  }

  const createCompleteLeg = (type) => {
    setFinanceData((data) => [...data, { leg: type, suppliers: [], accessories: [] }])
  }

  const EditNode = ({ leg, index, isArrivalNode, node }) => {
    let currentLeg, currentLegType, previousLeg, previousLegType

    const getEmptyKeys = (type) => {
      const emptyKeys = nodeTypes.map((node) =>
        type === 'departure' ? node.departureKey : node.arrivalKey
      )
      return emptyKeys.reduce((acc, key) => {
        acc[key] = undefined
        return acc
      }, {})
    }

    const isFirstNode = !!(index === 0)

    if (isArrivalNode) {
      currentLegType = getTypeLeg(leg.departure.value, node)?.value
      currentLeg = {
        ...financeData[index],
        leg: currentLegType,
        ...getEmptyKeys('arrival'),
      }
    } else if (isFirstNode) {
      currentLegType = getTypeLeg(node, leg.arrival.value)?.value
      currentLeg = {
        ...financeData[index],
        leg: currentLegType,
        ...getEmptyKeys('departure'),
      }
    } else {
      currentLegType = getTypeLeg(node, leg.arrival.value)?.value
      currentLeg = {
        ...financeData[index],
        leg: currentLegType,
        ...getEmptyKeys('departure'),
      }
      previousLegType = getTypeLeg(legs[index - 1].departure.value, node)?.value
      previousLeg = {
        ...financeData[index - 1],
        leg: previousLegType,
        ...getEmptyKeys('arrival'),
      }
    }

    if (
      currentLegType === undefined ||
      (!isFirstNode && !isArrivalNode && previousLegType === undefined)
    ) {
      const error = new Error()
      error.response = {
        data: {
          message: 'This leg can not be created.',
        },
      }
      throw error
    }

    setFinanceData((data) => {
      return data.map((leg, i) => {
        if (i === index) return currentLeg
        else if (i === index - 1 && !isFirstNode && !isArrivalNode) return previousLeg
        else return leg
      })
    })
  }

  const updateFinanceData = (index, newData) => {
    setFinanceData((data) =>
      data.map((item, i) =>
        i === index ? { ...item, ...newData, leg: item.leg ?? newData.leg } : item
      )
    )
  }

  const deleteCurrentLeg = (currentLeg) => {
    setFinanceData((data) => data.filter((item, i) => currentLeg !== i))
  }

  const updateFieldErrors = (newData) => {
    const errors = newData.flatMap((error) =>
      error.path.length > 2
        ? {
            legId: financeData?.[error.path[1]]?._id,
            field: error.path
              .slice(2)
              .filter((i) => i !== 'fromTo')
              .join('.'),
          }
        : []
    )

    const legErrors = errors.reduce((result, obj) => {
      const { legId, field } = obj
      const existingObj = result.find((item) => item.legId === legId)

      if (existingObj) {
        existingObj.fields.push(field)
      } else {
        result.push({ legId, fields: [field] })
      }

      return result
    }, [])

    setFieldErrors(legErrors)
  }

  const removeFieldError = (legId, path) => {
    setFieldErrors((list) =>
      list.map((item) =>
        item.legId === legId ? { ...item, fields: item.fields.filter((f) => f !== path) } : item
      )
    )
  }

  const removeLegErrors = (legId) => {
    setFieldErrors((list) => list.filter((item) => item.legId !== legId))
  }

  const value = {
    entityType,
    entityId,
    financeQuery,
    financeData,
    legs,
    currentLegType,
    currentLeg,
    currentLegIndex,
    isEmpty,
    addLeg,
    createCompleteLeg,
    EditNode,
    deleteCurrentLeg,
    updateFinanceData,
    updateFieldErrors,
    fieldErrors,
    removeFieldError,
    removeLegErrors,
  }

  return <FinanceContext.Provider value={value}>{children}</FinanceContext.Provider>
}

export { useFinanceEditingContext, FinanceEditingProvider }
