import { useParams } from 'react-router-dom'

// Api
import { getStockList } from 'features/AirWaybillStock/api/stock-requests'

//Interfaces
import { RoyanTable, useDisclosure } from '@royan-co/user-interface'

//Components
import Card from 'components/UI/Card'
import TableFilterFields from './FilterFields'
import { generateTableColumns } from './columns'
import { tableFilters } from './filters'
import AirWaybillStockBookingModal from './BookingModal'

const AirWaybillViewTable = () => {
  const { stockId } = useParams()
  const [isOpenBookingModal, bookingModalHandler] = useDisclosure('update-booking')

  const openBookingModal = (item) => {
    bookingModalHandler.open({
      book: item.book,
      id: item.id,
    })
  }

  return (
    <Card className="mt-0">
      <RoyanTable
        sortOrder="ASC"
        sortKey="code"
        name="stock-list"
        request={getStockList.bind(null, stockId)}
        columns={generateTableColumns(openBookingModal)}
        filters={tableFilters}
        filterFields={<TableFilterFields />}
      >
        <AirWaybillStockBookingModal
          isOpen={isOpenBookingModal}
          onClose={bookingModalHandler.close}
          isBooked={bookingModalHandler.data?.book}
          waybillId={bookingModalHandler.data?.id}
        />
      </RoyanTable>
    </Card>
  )
}

export default AirWaybillViewTable
