import { useMemo } from 'react'
import { modes } from './modes'
import { temperatureControlOptions } from './constants'
import { capitalizeFirstLetter, transformSelectField } from 'utils/helpers'

//Services
import useShipmentsEnumerations from 'hooks/useShipmentsEnumerations'

//Atlassian
import Toggle from '@atlaskit/toggle'
import Select from '@atlaskit/select'
import Button from '@atlaskit/button'
import Checkbox from '@atlaskit/checkbox'
import Textfield from '@atlaskit/textfield'
import { RadioGroup } from '@atlaskit/radio'
import { ErrorMessage } from '@atlaskit/form'
import { DynamicTableStateless } from '@atlaskit/dynamic-table'

//Interfaces
import {
  Field,
  InputGroup,
  RangeSlider,
  TrashIcon,
  useFieldArray,
  useWizardFormContext,
} from '@royan-co/user-interface'

const ShipmentCreationGroupageFields = () => {
  const {
    stepsValue: { routing, cargo },
  } = useWizardFormContext()

  const mode = useMemo(() => modes.find((m) => m.id === routing?.modeType), [routing?.modeType])

  const {
    fields: packagesFields,
    append: appendPackage,
    remove: removePackage,
  } = useFieldArray({ name: 'packages' })

  const { getEnumerationOptionsByKey, isLoadingShipmentEnumeration } = useShipmentsEnumerations()

  const addPackage = () => {
    appendPackage({ type: '', quantity: '', width: '', length: '', height: '', is_stackable: true })
  }

  const packagesHeads = {
    cells: [
      { key: 'type', content: 'Type', width: 26 },
      { key: 'quantity', content: 'Quantity' },
      { key: 'dimensions', content: 'Dimensions (cm)' },
      cargo?.type_of_weight === 0 && {
        key: 'weight',
        content: 'Weight (Kgs)',
      },
      { key: 'stackable', content: 'Stackable', width: 9 },
      { key: 'removeIcon', content: '', width: 4 },
    ],
  }

  const packagesRows = useMemo(
    () =>
      packagesFields.map((p, i) => ({
        key: p.id,
        cells: [
          {
            content: (
              <Field
                isRequired
                type="select"
                className="!mt-0"
                name={`packages.${i}.box_type`}
                transform={transformSelectField(getEnumerationOptionsByKey('packaging_types'))}
              >
                {({ fieldProps }) => (
                  <Select
                    placeholder="Choose"
                    isLoading={isLoadingShipmentEnumeration}
                    options={getEnumerationOptionsByKey('packaging_types')}
                    {...fieldProps}
                  />
                )}
              </Field>
            ),
          },
          {
            content: (
              <Field name={`packages.${i}.quantity`} isRequired defaultValue="" className="!mt-0">
                {({ fieldProps }) => <Textfield {...fieldProps} />}
              </Field>
            ),
          },
          {
            content: (
              <InputGroup>
                <Field name={`packages.${i}.width`} isRequired defaultValue="" className="!mt-0">
                  {({ fieldProps }) => <Textfield placeholder="W" {...fieldProps} />}
                </Field>

                <Field name={`packages.${i}.length`} isRequired defaultValue="" className="!mt-0">
                  {({ fieldProps }) => <Textfield placeholder="L" {...fieldProps} />}
                </Field>

                <Field name={`packages.${i}.height`} isRequired defaultValue="" className="!mt-0">
                  {({ fieldProps }) => <Textfield placeholder="H" {...fieldProps} />}
                </Field>
              </InputGroup>
            ),
          },
          cargo?.type_of_weight === 0 && {
            content: (
              <Field name={`packages.${i}.weight`} isRequired defaultValue="" className="!mt-0">
                {({ fieldProps }) => <Textfield {...fieldProps} />}
              </Field>
            ),
          },
          {
            content: (
              <Field
                type="checkbox"
                name={`packages.${i}.is_stackable`}
                className="text-center !mt-0"
              >
                {({ fieldProps }) => <Toggle {...fieldProps} />}
              </Field>
            ),
          },
          {
            content: (
              <Button
                spacing="compact"
                appearance="subtle"
                onClick={() => removePackage(i)}
                iconBefore={<TrashIcon className="text-red-400" />}
              />
            ),
          },
        ],
      })),
    [cargo, getEnumerationOptionsByKey, isLoadingShipmentEnumeration, packagesFields, removePackage]
  )

  return (
    <>
      <div className=" mt-8">
        <div className="shadow rounded p-4">
          <div className="grid md:grid-cols-2 md:gap-4">
            <div>
              <Field
                defaultValue=""
                name="total_collies"
                label="Total packages"
                isRequired="This field is required."
                isDisabled={cargo?.is_checked_packaging_details || false}
                calculate={{
                  field: ['is_checked_packaging_details', 'packages'],
                  callback: ([haveDetails, packages]) => {
                    if (!haveDetails) return
                    return packages?.reduce((p, c) => p + 1 * (c?.quantity || 0), 0)
                  },
                }}
              >
                {({ fieldProps, error }) => (
                  <>
                    <Textfield placeholder="0" {...fieldProps} />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>

              <Field
                name="weight"
                defaultValue=""
                label="Total weight (Kgs)"
                isRequired="This field is required."
                validate={(v) => {
                  if (
                    cargo?.type_of_weight === undefined ||
                    cargo?.is_checked_packaging_details === undefined
                  )
                    return true

                  if (cargo?.type_of_weight !== 1 && cargo?.is_checked_packaging_details)
                    return true

                  return v === 0 || v === '0' ? 'Total weight can not be zero' : true
                }}
                isDisabled={cargo?.type_of_weight !== 1 && cargo?.is_checked_packaging_details}
                calculate={{
                  field: ['is_checked_packaging_details', 'type_of_weight', 'packages'],
                  callback: ([haveDetails, weightType, packages]) => {
                    if (!haveDetails || weightType === 1) return
                    return packages?.reduce((p, c) => p + 1 * (c?.weight || 0), 0)
                  },
                }}
              >
                {({ fieldProps, error }) => (
                  <>
                    <Textfield placeholder="20,000" {...fieldProps} />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>

              <Field
                name="cbm"
                defaultValue=""
                label="Total volume (CBM)"
                isDisabled={cargo?.is_checked_packaging_details}
                isRequired="This field is required."
                calculate={{
                  field: ['is_checked_packaging_details', 'packages'],
                  callback: ([haveDetails, packages]) => {
                    if (!haveDetails) return
                    return packages?.reduce(
                      (p, c) =>
                        p +
                        (((Number(c?.width) || 0) *
                          (Number(c?.length) || 0) *
                          (Number(c?.height) || 0)) /
                          1000000) *
                          (c?.quantity || 0),
                      0
                    )
                  },
                }}
              >
                {({ fieldProps, error }) => (
                  <>
                    <Textfield {...fieldProps} />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>
            </div>

            <div>
              <Field type="checkbox" label="Packaging details" name="is_checked_packaging_details">
                {({ fieldProps }) => (
                  <Checkbox {...fieldProps} label={'I have packaging details.'} />
                )}
              </Field>

              <Field
                label="Packaging weight"
                name="type_of_weight"
                transform={{
                  input: (v) => `${v}`,
                  output: (opt) => parseInt(opt.target.value),
                }}
              >
                {({ fieldProps: { ref, ...fieldProps } }) => (
                  <RadioGroup
                    options={[
                      {
                        value: '0',
                        label: 'Each piece',
                      },
                      {
                        value: '1',
                        label: 'Total weight',
                      },
                    ]}
                    {...fieldProps}
                  />
                )}
              </Field>
            </div>
          </div>
        </div>
      </div>

      {cargo?.is_checked_packaging_details && (
        <div className="mt-8">
          <div className="flex justify-between items-center">
            <h3>{capitalizeFirstLetter(mode.cargoKey)}</h3>

            <Button appearance="link" onClick={addPackage} className="!px-0">
              New {mode.cargoKey.slice(0, -1)}
            </Button>
          </div>

          <div className="shadow rounded p-4 mt-2">
            {packagesRows.length === 0 ? (
              <div className="text-xs text-neutral-200">
                Click on New {mode.cargoKey.slice(0, -1)} to create new {mode.cargoKey.slice(0, -1)}
              </div>
            ) : (
              <div className="inline-block w-full -mb-6 removeAtlaskitTableBorder">
                <DynamicTableStateless head={packagesHeads} rows={packagesRows} />
              </div>
            )}
          </div>
        </div>
      )}

      {/* Special conditions */}
      <div className="mt-8">
        <div className="flex justify-between items-center">
          <h3>Special conditions</h3>
        </div>

        <div className="shadow rounded p-4 mt-2">
          <div className="">
            <Field type="checkbox" name="reefer" label="Temperature control">
              {({ fieldProps }) => (
                <div>
                  <Toggle {...fieldProps} />
                </div>
              )}
            </Field>

            {!!cargo?.reefer && (
              <div className="grid md:grid-cols-2 md:gap-4">
                <Field
                  isRequired
                  type="select"
                  label="Range"
                  name="reefer_temperature"
                  validate={(d) => !!d?.value?.range?.min}
                >
                  {({ fieldProps, errorType }) => (
                    <div>
                      <Select
                        placeholder="Choose a range"
                        options={temperatureControlOptions}
                        {...fieldProps}
                      />
                      {errorType && <ErrorMessage>Please select range</ErrorMessage>}
                    </div>
                  )}
                </Field>
              </div>
            )}

            {!!cargo?.reefer && cargo?.reefer_temperature?.value?.type === 'custom' && (
              <div className="inline-block w-full mt-1">
                <Field
                  name="reefer_temperature"
                  transform={{
                    output: ([min, max]) => ({
                      ...cargo?.reefer_temperature,
                      value: { type: 'custom', range: { min, max } },
                    }),
                  }}
                >
                  {({ fieldProps }) => (
                    <div>
                      <RangeSlider
                        max={30}
                        min={-25}
                        fieldProps={fieldProps}
                        onChange={fieldProps.onChange}
                        defaultValue={[
                          fieldProps?.value?.value?.range?.min || -10,
                          fieldProps?.value?.value?.range?.max || 15,
                        ]}
                      />
                    </div>
                  )}
                </Field>
              </div>
            )}
          </div>

          <div className="mt-4">
            <Field type="checkbox" name="adr_goods" label="Dangerous cargo">
              {({ fieldProps }) => (
                <div>
                  <Toggle {...fieldProps} />
                </div>
              )}
            </Field>

            {!!cargo?.adr_goods && (
              <div className="grid md:grid-cols-2 md:gap-4">
                <Field label="UN Number" name="adr_number" defaultValue="" isRequired>
                  {({ fieldProps }) => (
                    <div>
                      <Textfield placeholder="Type your UN Number" {...fieldProps} />
                    </div>
                  )}
                </Field>

                <Field
                  isRequired
                  type="select"
                  label="Class"
                  name="adr_class"
                  transform={transformSelectField(getEnumerationOptionsByKey('adr_classes'))}
                >
                  {({ fieldProps }) => (
                    <div>
                      <Select
                        placeholder="Choose a class"
                        isLoading={isLoadingShipmentEnumeration}
                        options={getEnumerationOptionsByKey('adr_classes')}
                        {...fieldProps}
                      />
                    </div>
                  )}
                </Field>
              </div>
            )}
          </div>

          <div className="mt-4">
            <Field type="checkbox" label="Cargo insurance" name="insurance">
              {({ fieldProps }) => (
                <div>
                  <Toggle {...fieldProps} />
                </div>
              )}
            </Field>

            {!!cargo?.insurance && (
              <div className="grid md:grid-cols-2 md:gap-4">
                <Field label="Cargo value" name="cargo_value" defaultValue="" isRequired>
                  {({ fieldProps }) => <Textfield placeholder="Type cargo value" {...fieldProps} />}
                </Field>

                <Field
                  isRequired
                  type="select"
                  name="currency"
                  label="Currency"
                  transform={transformSelectField(getEnumerationOptionsByKey('currencies'))}
                >
                  {({ fieldProps }) => (
                    <div>
                      <Select
                        menuPlacement="auto"
                        placeholder="Choose a currency"
                        isLoading={isLoadingShipmentEnumeration}
                        options={getEnumerationOptionsByKey('currencies')}
                        {...fieldProps}
                      />
                    </div>
                  )}
                </Field>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default ShipmentCreationGroupageFields
