import { useMemo, useCallback } from 'react'

// Utils
import { getFilled, transformSelectField } from 'utils/helpers'
import { formatMonetaryNumber } from 'utils/numbers'

// Hooks
import useShipmentsEnumerations from 'hooks/useShipmentsEnumerations'

// Atlassian
import Button from '@atlaskit/button'
import Textfield from '@atlaskit/textfield'
import Select from '@atlaskit/select'
import { ErrorMessage } from '@atlaskit/form'
import { DynamicTableStateless } from '@atlaskit/dynamic-table'

// Interfaces
import { Field, TrashIcon, PlusIcon, useFormValues, useFormContext } from '@royan-co/user-interface'
import { calculationMethodOptions } from 'components/Rates/constants'

const EditFinanceTableFields = ({
  keyName,
  removeRow,
  addSurcharge,
  removeSupplier,
  supplierKeyName,
}) => {
  const { getServiceCodeOptions, isLoadingShipmentEnumeration } = useShipmentsEnumerations()

  const formValues = useFormValues()
  const { setValue, getValues } = useFormContext()

  const setCostValue = useCallback(
    (rate, quantity, key) => {
      const cost = rate * quantity || 0
      setValue(`${key}.cost`, cost.toFixed(2))
    },
    [setValue]
  )

  const _rows = useMemo(
    () =>
      supplierKeyName
        ? [getFilled(formValues, supplierKeyName), ...getFilled(formValues, keyName, [])]
        : getFilled(formValues, keyName, []),
    [formValues, keyName, supplierKeyName]
  )

  const total = useMemo(() => {
    return _rows?.reduce(
      (p, c) => ({
        cost: p.cost + 1 * (c?.cost || 0),
        quotation: p.quotation + 1 * (c?.quotation || 0),
      }),
      { cost: 0, quotation: 0 }
    )
  }, [_rows])

  const tableFieldsHead = {
    cells: [
      {
        key: 'code',
        content: <span style={{ minWidth: '100px', display: 'inline-block' }}>Code</span>,
      },
      {
        key: 'description',
        content: <span style={{ minWidth: '130px', display: 'inline-block' }}>Description</span>,
      },
      {
        key: 'QTY',
        content: <span style={{ minWidth: '40px', display: 'inline-block' }}>QTY</span>,
      },
      {
        key: 'rate',
        content: <span style={{ minWidth: '75px', display: 'inline-block' }}>Rate</span>,
      },
      {
        key: 'calc_method',
        content: <span style={{ minWidth: '75px', display: 'inline-block' }}>Calc. method</span>,
      },
      {
        key: 'cost',
        content: <span style={{ minWidth: '75px', display: 'inline-block' }}>Cost</span>,
      },
      {
        key: 'quote',
        content: <span style={{ minWidth: '75px', display: 'inline-block' }}>Quote</span>,
      },
      {
        key: 'vat',
        content: <span style={{ minWidth: '75px', display: 'inline-block' }}>VAT (%)</span>,
      },
      {
        key: 'action',
        content: '',
      },
    ],
  }

  const tableFieldsRows = useMemo(() => {
    let rowsItems = _rows?.map((row, i) => {
      const namePrefix = supplierKeyName
        ? i === 0
          ? supplierKeyName
          : `${keyName}.${i - 1}`
        : `${keyName}.${i}`

      const handleRemoveRow = () => {
        supplierKeyName ? (i === 0 ? removeSupplier() : removeRow(i - 1)) : removeRow(i)
      }

      return {
        key: row._id,
        cells: [
          {
            content:
              supplierKeyName && i !== 0 ? (
                ''
              ) : (
                <div className="relative">
                  <Field
                    isRequired
                    type="select"
                    name={`${namePrefix}.service_code`}
                    className="!mt-0"
                    transform={transformSelectField(getServiceCodeOptions)}
                  >
                    {({ fieldProps }) => (
                      <div style={{ maxWidth: '150px' }}>
                        <Select
                          menuPlacement="auto"
                          placeholder="Choose"
                          menuPortalTarget={document.body}
                          isLoading={isLoadingShipmentEnumeration}
                          options={getServiceCodeOptions}
                          {...fieldProps}
                        />
                      </div>
                    )}
                  </Field>

                  {supplierKeyName && (
                    <div
                      style={{
                        position: 'absolute',
                        bottom: '-24px',
                        left: '0',
                      }}
                    >
                      <span className="!text-neutral-200 !uppercase text-xs font-semibold">
                        Surcharges
                      </span>
                    </div>
                  )}
                </div>
              ),
          },
          {
            content: (
              <Field isRequired name={`${namePrefix}.name`} defaultValue="" className="!mt-0">
                {({ fieldProps }) => <Textfield {...fieldProps} />}
              </Field>
            ),
          },
          {
            content: (
              <Field isRequired name={`${namePrefix}.quantity`} defaultValue="" className="!mt-0">
                {({ fieldProps }) => (
                  <Textfield
                    {...fieldProps}
                    onChange={(e) => {
                      setCostValue(getValues(`${namePrefix}.rate`), e.target.value, namePrefix)
                      fieldProps.onChange(e)
                    }}
                  />
                )}
              </Field>
            ),
          },
          {
            content: (
              <Field isRequired name={`${namePrefix}.rate`} defaultValue="" className="!mt-0">
                {({ fieldProps }) => (
                  <Textfield
                    {...fieldProps}
                    onChange={(e) => {
                      setCostValue(e.target.value, getValues(`${namePrefix}.quantity`), namePrefix)
                      fieldProps.onChange(e)
                    }}
                  />
                )}
              </Field>
            ),
          },
          {
            content: (
              <Field
                isRequired
                type="select"
                className="!mt-0"
                name={`${namePrefix}.calculation_method`}
                transform={transformSelectField(calculationMethodOptions)}
              >
                {({ fieldProps }) => (
                  <div style={{ width: '150px' }}>
                    <Select
                      isClearable
                      menuPlacement="auto"
                      placeholder="Choose"
                      menuPortalTarget={document.body}
                      options={calculationMethodOptions}
                      {...fieldProps}
                    />
                  </div>
                )}
              </Field>
            ),
          },
          {
            content: (
              <Field isRequired name={`${namePrefix}.cost`} defaultValue="" className="!mt-0">
                {({ fieldProps, error }) => (
                  <>
                    <Textfield {...fieldProps} />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>
            ),
          },
          {
            content: (
              <Field isRequired name={`${namePrefix}.quotation`} defaultValue="" className="!mt-0">
                {({ fieldProps }) => <Textfield {...fieldProps} />}
              </Field>
            ),
          },
          {
            content: (
              <Field name={`${namePrefix}.vat`} defaultValue="" className="!mt-0">
                {({ fieldProps }) => <Textfield {...fieldProps} />}
              </Field>
            ),
          },
          {
            content: (
              <Button spacing="none" appearance="subtle-link" onClick={handleRemoveRow}>
                <TrashIcon className="text-red-400" />
              </Button>
            ),
          },
        ],
      }
    })

    if (supplierKeyName)
      rowsItems.push({
        key: `total`,
        cells: [
          {
            content: '',
          },
          {
            content: (
              <Button onClick={addSurcharge} iconBefore={<PlusIcon />} appearance="link">
                New surcharge
              </Button>
            ),
          },
          {
            content: '',
          },
          {
            content: '',
          },
          {
            content: '',
          },
          {
            content: (
              <div className="text-left pl-1 pb-1" style={{ width: '90px' }}>
                <small>Total cost</small> <br />
                {formatMonetaryNumber(total.cost)}
              </div>
            ),
          },
          {
            content: (
              <div className="text-left pl-1 pb-1" style={{ width: '90px' }}>
                <small>Total quotation</small> <br />
                {formatMonetaryNumber(total.quotation)}
              </div>
            ),
          },
          {
            content: '',
          },
          {
            content: '',
          },
        ],
      })

    return rowsItems
  }, [
    _rows,
    supplierKeyName,
    addSurcharge,
    total.cost,
    total.quotation,
    keyName,
    getServiceCodeOptions,
    removeSupplier,
    removeRow,
    isLoadingShipmentEnumeration,
    setCostValue,
    getValues,
  ])

  return (
    <>
      <DynamicTableStateless head={tableFieldsHead} rows={tableFieldsRows} />
    </>
  )
}

export default EditFinanceTableFields
