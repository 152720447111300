import { useQuery } from '@tanstack/react-query'

// Apis
import { getShipmentDocuments } from 'api/shipments'

/**
 * get invoice default values
 * @param {string} entityId
 * @param {import('@tanstack/react-query').UseQueryOptions} options
 * @returns {import('@tanstack/react-query').UseQueryResult}
 */
export const useShipmentDocuments = (entityId, options = {}) => {
  return useQuery({
    queryKey: ['shipment', 'documents', entityId],
    queryFn: () => getShipmentDocuments(entityId),
    staleTime: Infinity,
    ...options,
  })
}
