// Utils
import { findOption } from 'utils/helpers'
import { airWaybillTypeOptions } from 'features/AirWaybill/utils/constants'

// Hooks
import useShipmentsOperators from 'hooks/useShipmentsOperators'
import useRegistryAirlines from 'hooks/useRegistryAirlines'

// Atlassian
import Spinner from '@atlaskit/spinner'

const CreatorsFilter = ({ value }) => {
  const { shipmentsOperatorsOptions, isLoadingShipmentsOperators } = useShipmentsOperators()

  return (
    <>
      Creator:{' '}
      {isLoadingShipmentsOperators ? (
        <Spinner size="xsmall" />
      ) : (
        findOption(shipmentsOperatorsOptions, value)?.label
      )}
    </>
  )
}

const AirLineFilter = ({ value }) => {
  const { registryAirlineOptions, isLoadingRegistryAirlines } = useRegistryAirlines()

  return (
    <>
      airline:{' '}
      {isLoadingRegistryAirlines ? (
        <Spinner size="xsmall" />
      ) : (
        findOption(registryAirlineOptions, value)?.label
      )}
    </>
  )
}

export const airWaybillFilters = [
  {
    key: 'type',
    content: (v) => `Type: ${findOption(airWaybillTypeOptions, v)?.label}`,
  },
  {
    key: 'airline',
    content: (v) => <AirLineFilter value={v} />,
  },
  {
    key: 'creator_id',
    content: (v) => <CreatorsFilter value={v} />,
  },
  {
    key: 'shipment',
    content: (v) => `Shipment reference: ${v}`,
  },
  {
    key: 'shipper',
    content: (v) => `Shipper: ${v}`,
  },
  {
    key: 'consignee',
    content: (v) => `Consignee: ${v}`,
  },
  {
    key: 'departure_airport',
    content: (v) => `Departure airport: ${v}`,
  },
  {
    key: 'arrival_airport',
    content: (v) => `Arrival airport: ${v}`,
  },
  {
    key: 'flight',
    content: (v) => `Flight number: ${v}`,
  },
  {
    key: 'collies_number',
    content: (v) => `Number of collies: ${v}`,
  },
  {
    key: 'weight',
    content: (v) => `Weight: ${v}`,
  },
  {
    key: 'chargeable_weight',
    content: (v) => `Chargeable weight: ${v}`,
  },
  {
    key: 'flight_date_from',
    content: (v) => `Flight date from: ${v}`,
  },
  {
    key: 'flight_date_to',
    content: (v) => `Flight date to: ${v}`,
  },
  {
    key: 'from',
    content: (v) => `Created from: ${v}`,
  },
  {
    key: 'to',
    content: (v) => `Created to: ${v}`,
  },
]
