import { useMemo } from 'react'

// Utils
import { getFilled } from 'utils/helpers'

// Atlassian
import Button, { LoadingButton } from '@atlaskit/button'
import TextArea from '@atlaskit/textarea'
import { ErrorMessage } from '@atlaskit/form'
import Modal, {
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import { RadioGroup } from '@atlaskit/radio'

// Interfaces
import { Form, Field, WarningBannerField } from '@royan-co/user-interface'

const ShipmentHoldReleaseModal = ({ isOpen, onClose, data, onSubmit, isLoading }) => {
  const defaultValues = useMemo(
    () => ({
      is_on_hold: getFilled(data, 'is_on_hold', 0),
      holding_reason: getFilled(data, 'holding_reason', ''),
    }),
    [data]
  )

  return (
    <>
      <ModalTransition>
        {isOpen && (
          <Modal onClose={onClose} width={'small'}>
            <ModalHeader>
              <ModalTitle>Hold / Release shipment</ModalTitle>
            </ModalHeader>

            <Form onSubmit={onSubmit} defaultValues={defaultValues}>
              {({ watch }) => (
                <>
                  <div className="px-6">
                    <Field
                      name="is_on_hold"
                      transform={{
                        input: (v) => `${v}`,
                        output: (opt) => parseInt(opt.target.value),
                      }}
                      className="!mt-0"
                    >
                      {({ fieldProps: { ref, ...fieldProps } }) => (
                        <RadioGroup
                          options={[
                            {
                              value: '0',
                              label: 'Release shipment',
                            },
                            {
                              value: '1',
                              label: 'Hold shipment',
                            },
                          ]}
                          {...fieldProps}
                        />
                      )}
                    </Field>

                    {watch('is_on_hold') ? (
                      <Field
                        name="holding_reason"
                        label="Reason for holding the shipment"
                        defaultValue=""
                        className="!mt-4"
                      >
                        {({ fieldProps, error }) => (
                          <>
                            <TextArea
                              {...fieldProps}
                              minimumRows={4}
                              placeholder="Specify the reason here."
                            />
                            {error && <ErrorMessage>{error}</ErrorMessage>}
                          </>
                        )}
                      </Field>
                    ) : null}

                    <WarningBannerField />
                  </div>

                  <ModalFooter>
                    <Button appearance="subtle" type="button" onClick={onClose}>
                      Cancel
                    </Button>
                    <LoadingButton appearance="primary" type="submit" isLoading={isLoading}>
                      Confirm
                    </LoadingButton>
                  </ModalFooter>
                </>
              )}
            </Form>
          </Modal>
        )}
      </ModalTransition>
    </>
  )
}

export default ShipmentHoldReleaseModal
