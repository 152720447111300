// Utils
import { findOption } from 'utils/helpers'
import { stockAvailabilityOptions } from './../../utils/constants'

// Hooks
import useRegistryAirlines from 'hooks/useRegistryAirlines'

// Atlassian
import Spinner from '@atlaskit/spinner'

const AirLineFilter = ({ value }) => {
  const { registryAirlineOptions, isLoadingRegistryAirlines } = useRegistryAirlines()

  return (
    <>
      airline:{' '}
      {isLoadingRegistryAirlines ? (
        <Spinner size="xsmall" />
      ) : (
        findOption(registryAirlineOptions, value)?.label
      )}
    </>
  )
}

export const airWaybillStocksFilters = [
  {
    key: 'airline',
    content: (v) => <AirLineFilter value={v} />,
  },
  {
    key: 'has_free',
    content: (v) => `Availability: ${findOption(stockAvailabilityOptions, v)?.label}`,
  },
  {
    key: 'start_date',
    content: (v) => `Start date: ${v}`,
  },
  {
    key: 'end_date',
    content: (v) => `End date: ${v}`,
  },

  {
    key: 'created_from',
    content: (v) => `Created from: ${v}`,
  },
  {
    key: 'created_to',
    content: (v) => `Created to: ${v}`,
  },
]
