// TODO: i know, this is shit, we shout refactor this later

import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

//Apis
import { getShipmentTimeline } from 'api/shipments'

//Atlassian
import Spinner from '@atlaskit/spinner'

//Interfaces
import { EditIcon, Stepper, StepperStep, useDisclosure } from '@royan-co/user-interface'

//Components
import ShipmentEditTimelineModal from './EditTimelineModal'

const statusList = [
  {
    id: 0,
    label: 'Taken in charge',
  },
  {
    id: 1,
    label: 'Shipper contacted',
  },
  {
    id: 2,
    label: 'Collection planned',
  },
  {
    id: 3,
    label: 'Cargo collected',
  },
  {
    id: 4,
    label: 'Arrived in hub',
  },
  {
    id: 6,
    label: 'Custom cleared',
  },
  {
    id: 8,
    label: 'Departure planned',
  },
  {
    id: 9,
    label: 'Departed',
  },
  {
    id: 21,
    label: 'Arrived',
  },
]

const ShipmentStatusTimeline = () => {
  const { shipmentId } = useParams()
  const [isOpenEditTimelineModal, editTimelineModalHandler] = useDisclosure('timeline')

  const {
    data: { data } = {},
    isLoading,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ['shipment', 'timeline', shipmentId],
    queryFn: () => getShipmentTimeline(shipmentId),
  })

  const _status = useMemo(
    () =>
      data?.status === 5
        ? 4
        : data?.status === 7 || (data?.status === 4 && data?.custom_cleared)
        ? 6
        : data?.status > 9 && data?.status < 21
        ? 9
        : data?.status >= 21
        ? 21
        : data?.status,
    [data]
  )

  const items = useMemo(() => {
    return statusList.map((item) => ({
      id: item.id,
      label: item.label,
      tooltip: data?.timeline[item.id],
      status:
        data?.custom_cleared && item.id === 6 && _status < 6
          ? 'current'
          : _status === item.id
          ? 'current'
          : _status > item.id
          ? 'visited'
          : 'unvisited',
    }))
  }, [_status, data])

  return (
    <>
      <div className="shadow rounded">
        {isLoading || isFetching ? (
          <div className="px-4 pt-4 pb-6">
            <div className="text-center  my-7 pt-0.5">
              <Spinner />
            </div>
          </div>
        ) : (
          <div className="lg:flex">
            <div className="grow px-4 pt-4 pb-6">
              <Stepper>
                {items.map((step) => (
                  <StepperStep
                    key={step.id}
                    label={step.label}
                    state={step.status}
                    tooltip={step.tooltip}
                  />
                ))}
              </Stepper>
            </div>

            <button
              className="w-full lg:w-auto p-4 bg-neutral-20 text-neutral-500"
              onClick={() => editTimelineModalHandler.open()}
            >
              <EditIcon />
            </button>

            <ShipmentEditTimelineModal
              isOpen={isOpenEditTimelineModal}
              onClose={editTimelineModalHandler.close}
              statusList={statusList}
              shipmentId={shipmentId}
              statusId={data?.status}
              refetch={refetch}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default ShipmentStatusTimeline
