import React from 'react'
import { useParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

// Apis
import { recalculateLegCosts } from 'api/shipments'

// Utils
import { createFormValues } from './createFormValues'

// Contexts
import { useFinanceEditingContext } from 'contexts/FinanceEditingProvider'

// Atlassian
import Modal, {
  ModalBody,
  ModalTitle,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import Button, { LoadingButton } from '@atlaskit/button'

// Interfaces
import { WarningBanner, useFlags } from '@royan-co/user-interface'

const EditFinanceRecalculateModal = ({ isOpen, onClose, arrivalDepartureData }) => {
  const { shipmentId, quotationId } = useParams()
  const { showWarningFlag, showSuccessFlag } = useFlags()
  const { currentLegIndex, currentLeg, updateFinanceData, removeLegErrors } =
    useFinanceEditingContext()
  const recalculateCostMutation = useMutation(recalculateLegCosts)

  const closeModal = () => {
    recalculateCostMutation.reset()
    onClose()
  }

  const recalculateCosts = () => {
    const { arrivalKey, arrival, departureKey, departure } = arrivalDepartureData
    let data = {
      entity_type: 'shipment',
      entity_id: shipmentId || quotationId,
      from_to: currentLeg.leg,
    }

    if (!arrival || !departure) return

    if (arrivalKey === 'arrival_zone') {
      data.arrival_city = arrival.city
      data.arrival_cap = arrival.zipCode
      data.arrival_country = arrival.countryAbbr
    } else data[arrivalKey] = arrival

    if (departureKey === 'departure_zone') {
      data.departure_city = departure.city
      data.departure_cap = departure.zipCode
      data.departure_country = departure.countryAbbr
    } else data[departureKey] = departure

    recalculateCostMutation.mutate(data, {
      onError: (e) => {
        showWarningFlag('Something went wrong.')
        closeModal()
        console.error(e)
      },
      onSuccess: (res) => {
        showSuccessFlag(res.message)
        const changedData = createFormValues({ ...res?.rates, _id: undefined })
        updateFinanceData(currentLegIndex, changedData)
        removeLegErrors(currentLeg._id)
      },
    })
  }

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={closeModal}>
          <ModalHeader>
            <ModalTitle appearance="warning">Recalculate costs</ModalTitle>
          </ModalHeader>
          <ModalBody>
            You are about to recalculate costs. Are you sure?
            {recalculateCostMutation.isError && <WarningBanner />}
          </ModalBody>
          <ModalFooter>
            <Button appearance="subtle" onClick={closeModal}>
              Cancel
            </Button>
            <LoadingButton
              appearance="warning"
              onClick={recalculateCosts}
              isLoading={recalculateCostMutation.isLoading}
              autoFocus
            >
              Apply
            </LoadingButton>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  )
}

export default EditFinanceRecalculateModal
