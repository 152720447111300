import axios from 'services/Request'

export function getSeawaybillFormDefaultValues(documentId) {
  return axios.get(`/api/shipments/documents/seawaybills/fetch/${documentId}`)
}

export function editSeawaybillDocument(documentId, data) {
  return axios.post(`/api/shipments/documents/seawaybills/edit/${documentId}`, data)
}

export function downloadSeawaybillDocument({ documentId, format }) {
  return axios.get(`/api/shipments/documents/seawaybills/download/${documentId}/${format}`, {
    responseType: 'arraybuffer',
  })
}
