import { findOption } from 'utils/helpers'

import { gpsTrackerOptions, statusOptions } from '../constants'
import {
  containerTypes,
  modeOptions,
  shipmentTemperatureOptions,
  truckTypes,
} from 'components/Shipments/constants'

//Hooks
import useShipmentsHubs from 'hooks/useShipmentsHubs'
import useShipmentsOperators from 'hooks/useShipmentsOperators'

//Atlassian
import Spinner from '@atlaskit/spinner'

const HubsFilter = ({ value }) => {
  const { shipmentsHubsOptions, isLoadingShipmentsHubs } = useShipmentsHubs()

  return (
    <>
      Consolidator hub:{' '}
      {isLoadingShipmentsHubs ? (
        <Spinner size="xsmall" />
      ) : (
        findOption(shipmentsHubsOptions, value)?.label
      )}
    </>
  )
}

const CreatorsFilter = ({ value }) => {
  const { shipmentsOperatorsOptions, isLoadingShipmentsOperators } = useShipmentsOperators()

  return (
    <>
      Creator:{' '}
      {isLoadingShipmentsOperators ? (
        <Spinner size="xsmall" />
      ) : (
        findOption(shipmentsOperatorsOptions, value)?.label
      )}
    </>
  )
}

export const consolidationsFilters = [
  {
    key: 'status',
    content: (v) => `Status: ${findOption(statusOptions, v)?.label}`,
  },
  {
    key: 'shipment_type',
    content: (v) => `Mode: ${findOption(modeOptions, v)?.label}`,
  },
  {
    key: 'type_of_collies',
    content: (v, { shipment_type }) =>
      `Method of loading: ${
        findOption(shipment_type === 1 ? containerTypes : truckTypes, v)?.label
      }`,
  },
  {
    key: 'arrival_country',
    content: (v) => `Country of delivery: ${v}`,
  },
  {
    key: 'carrier',
    content: (v) => `Carrier: ${v}`,
  },
  {
    key: 'means_code',
    content: (v) => `Transportation unit: ${v}`,
  },
  {
    key: 'has_tracking_unit',
    content: (v) => `GPS tracker: ${findOption(gpsTrackerOptions, v)?.label}`,
  },
  {
    key: 'creator_user',
    content: (v) => <CreatorsFilter value={v} />,
  },
  {
    key: 'hub_consolidator',
    content: (v) => <HubsFilter value={v} />,
  },
  {
    key: 'reefer',
    content: (v) => `Temperature control: ${findOption(shipmentTemperatureOptions, v)?.label}`,
  },
  {
    key: 'created_from',
    content: (v) => `From Date: ${v}`,
  },
  {
    key: 'created_to',
    content: (v) => `To Date: ${v}`,
  },
]
