import { useMutation } from '@tanstack/react-query'

// Apis
import { shareConsolidation } from 'api/consolidations'

// Hooks
import useSharesConsolidation from 'hooks/useSharesConsolidation'

// Utils
import { handleErrorOnSubmit } from 'utils/errors'

// Atlassian
import Button, { LoadingButton } from '@atlaskit/button'
import Modal, {
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog'

// Interfaces
import { Form, WarningBannerField, useFlags } from '@royan-co/user-interface'

//Components
import ShareModalFields from 'components/Shipments/View/ShareModalFields'

const ConsolidationsShareModal = ({ isOpen, onClose, consolidationId }) => {
  const { showSuccessFlag } = useFlags()

  const { upsertSharingToData } = useSharesConsolidation(consolidationId)

  const shareConsolidationMutation = useMutation((data) =>
    shareConsolidation(consolidationId, data)
  )

  const handleCloseModal = () => {
    onClose()
    shareConsolidationMutation.reset()
  }

  const handleShareConsolidation = (data, { setError }) => {
    const FormData = {
      ...data,
      registry_id: data?.registry_id?.value,
      type: data?.type === 'null' ? null : data?.type,
      registry_address_id: data?.registry_address_id?.value || null,
    }

    shareConsolidationMutation.mutate(FormData, {
      onError: (e) => {
        handleErrorOnSubmit(e, setError, data)
        console.error(e)
      },
      onSuccess: (res) => {
        upsertSharingToData(res?.share)
        showSuccessFlag(res.message)
        handleCloseModal()
      },
    })
  }

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={handleCloseModal} width="small">
          <ModalHeader>
            <ModalTitle>Share consolidation</ModalTitle>
          </ModalHeader>

          <Form onSubmit={handleShareConsolidation}>
            {() => (
              <>
                <ShareModalFields />

                <div className="px-6">
                  <WarningBannerField />
                </div>

                <ModalFooter>
                  <Button
                    appearance="subtle"
                    spacing="compact"
                    type="button"
                    onClick={handleCloseModal}
                  >
                    Discard
                  </Button>
                  <LoadingButton
                    appearance="primary"
                    spacing="compact"
                    type="submit"
                    isLoading={shareConsolidationMutation.isLoading}
                  >
                    Share
                  </LoadingButton>
                </ModalFooter>
              </>
            )}
          </Form>
        </Modal>
      )}
    </ModalTransition>
  )
}

export default ConsolidationsShareModal
