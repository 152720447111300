import { useMutation } from '@tanstack/react-query'

// Interfaces
import { useRoyanTable } from '@royan-co/user-interface'

// Apis
import { deleteCurrency } from './currencies-requests'

const useDeleteCurrency = (currencyCode) => {
  const { refresh } = useRoyanTable()

  return useMutation({
    mutationFn: () => deleteCurrency(currencyCode),
    onSuccess: (res) => {
      if (refresh) refresh()
    },
    onError: (e) => {
      console.error(e)
    },
  })
}

export { useDeleteCurrency }
