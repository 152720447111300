import { useMutation, useQueryClient } from '@tanstack/react-query'

// Apis
import { createMarginCalculator, editMarginCalculator } from './margin-calculator-requests'

const useCreateMarginCalculator = (marginId) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data) =>
      marginId ? editMarginCalculator(marginId, data) : createMarginCalculator(data),
    onSuccess: (res) => {
      queryClient.setQueryData(['margin-calculator', +res.record.id], (oldData = {}) => {
        return {
          ...oldData,
          ...res.record,
        }
      })
    },
    onError: (e) => {
      console.error(e)
    },
  })
}

export { useCreateMarginCalculator }
