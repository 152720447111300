import { useMutation } from '@tanstack/react-query'

// Interfaces
import { useRoyanTable } from '@royan-co/user-interface'

// Apis
import { deleteVehicleType } from './vehicle-type-requests'

const useDeleteVehicleType = (typeId) => {
  const { refresh } = useRoyanTable()

  return useMutation({
    mutationFn: () => deleteVehicleType(typeId),
    onSuccess: (res) => {
      if (refresh) refresh()
    },
    onError: (e) => {
      console.error(e)
    },
  })
}

export { useDeleteVehicleType }
