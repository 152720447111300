//Atlassian
import Lozenge from '@atlaskit/lozenge'

const RegistrySelectLabelOption = ({ data, tagOption }) => {
  return (
    <span>
      {data.imported && (
        <span className="mr-2">
          <Lozenge appearance="success" isBold>
            ARCA
          </Lozenge>
        </span>
      )}

      {data?.label}
    </span>
  )
}

export default RegistrySelectLabelOption
