import React from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

import RequireAuth from 'layouts/RequireAuth'

//Atlassian
import Button from '@atlaskit/button'
import { Main, Content, PageLayout, LeftPanel, TopNavigation } from '@atlaskit/page-layout'

//Interfaces
import { ArrowLeftIcon } from '@royan-co/user-interface'

const PureLayoutBackButton = ({ back }) => {
  const navigate = useNavigate()
  const isMobile = useMediaQuery({ query: `(max-width: 991.98px)` })

  const goBack = () => {
    if (back) {
      navigate(back)
    } else if (window.history.state) {
      navigate(-1)
    } else {
      navigate(document.head.querySelector("meta[name='backUrl'")?.content || '/')
    }
  }

  const BackButton = (
    <Button
      appearance={isMobile ? 'default' : 'subtle'}
      onClick={() => goBack()}
      className="mt-6 !rounded-full"
      iconBefore={<ArrowLeftIcon />}
    />
  )

  return (
    <>
      {isMobile ? (
        <TopNavigation isFixed={false}>
          <div className="px-6">{BackButton}</div>
        </TopNavigation>
      ) : (
        <LeftPanel id="left-panel" isFixed={true} width={64}>
          <div className="text-center">{BackButton}</div>
        </LeftPanel>
      )}
    </>
  )
}

const PureLayout = ({ back, children }) => {
  const isMobile = useMediaQuery({ query: `(max-width: 991.98px)` })

  return (
    <RequireAuth>
      <PageLayout>
        <PureLayoutBackButton back={back} />

        <Content>
          <Main>
            <div className={isMobile ? 'px-6 pb-6' : ''}>{children ? children : <Outlet />}</div>
          </Main>
        </Content>
      </PageLayout>
    </RequireAuth>
  )
}

export default PureLayout
