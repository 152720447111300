import { useQuery, useQueryClient } from '@tanstack/react-query'

// API
import { getLineRoutes } from '../api/requests'

const useLineRoutes = (lineId, options = {}) => {
  const queryClient = useQueryClient()

  const {
    data = [],
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ['line-routes', lineId],
    queryFn: () => getLineRoutes(lineId),
    retry: 2,
    staleTime: Infinity,
    ...options,
  })

  const invalidate = () => {
    queryClient.invalidateQueries({ queryKey: ['line-routes', lineId] })
  }

  const handleDeletedRoute = (deletedRoute) => {
    queryClient.setQueryData(['line-routes', lineId], (oldRoutes) =>
      oldRoutes.filter((route) => route.id !== deletedRoute)
    )
  }

  return {
    routes: data,
    isLoading,
    isError,
    refetch,
    invalidate,
    handleDeletedRoute,
  }
}

export default useLineRoutes
