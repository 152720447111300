import axios from 'services/Request'

export function getInvoices(params) {
  return axios.get('/api/invoices/list', { params })
}

export function getInvoicePDF(id) {
  return axios.get('/api/invoices/pdf/' + id, { responseType: 'blob' })
}

export async function getInvoiceEnumerations() {
  return axios.get('/api/invoices/enumerations')
}

export function reportInvoices(params) {
  return axios.post('/api/invoices/financial-balance', params)
}

export function getCommercialInvoice(shipmentId, invoiceId) {
  return axios.get(`/api/shipments/${shipmentId}/commercial-invoices/${invoiceId}`)
}

export function createCommercialInvoice(shipmentId, params) {
  return axios.post(`/api/shipments/${shipmentId}/commercial-invoices`, params)
}

export function editCommercialInvoice(shipmentId, invoiceId, params) {
  return axios.put(`/api/shipments/${shipmentId}/commercial-invoices/${invoiceId}`, params)
}

export function deleteCommercialInvoice(shipmentId, invoiceId) {
  return axios.delete(`/api/shipments/${shipmentId}/commercial-invoices/${invoiceId}`)
}
