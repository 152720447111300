import { useCallback, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { getInvoiceEnumerations } from 'api/invoices'

const useInvoiceEnumerations = ({ enabled = true } = {}) => {
  const { isLoading, data = {} } = useQuery(
    ['invoice_enumerations'],
    () => getInvoiceEnumerations(),
    {
      staleTime: Infinity,
      retry: 2,
      enabled,
    }
  )

  const docCodesOptions = useMemo(() => {
    if (!data?.doc_codes) return []

    return data.doc_codes.map((doc) => ({
      label: `${doc?.code} - ${doc?.description}`,
      value: doc?.code,
    }))
  }, [data])

  const bufferInvoiceDocCodesOptions = useMemo(() => {
    if (!data?.doc_codes) return []

    return data.doc_codes
      .filter((doc) => ['FAF', 'NFI'].includes(doc?.code))
      .map((doc) => ({
        label: `${doc.code} - ${doc?.description}`,
        value: doc.code,
      }))
  }, [data])

  const ivaCodeOptions = useMemo(() => {
    if (!data?.iva_codes) return []

    return data?.iva_codes?.map((code) => ({
      label: code?.description,
      value: code?.code,
    }))
  }, [data])

  const getIvaCodeRate = useCallback(
    (code) => {
      const selectedCode = data?.iva_codes?.find((c) => c.code === code)

      return selectedCode?.rate || ''
    },
    [data?.iva_codes]
  )

  return {
    docCodesOptions,
    bufferInvoiceDocCodesOptions,
    ivaCodeOptions,
    getIvaCodeRate,
    isLoadingInvoiceEnumerations: isLoading,
  }
}

export default useInvoiceEnumerations
