import { Link, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

//Api
import { getServices } from '../api/service-codes-requests'

//Atlassian
import PageHeader from '@atlaskit/page-header'
import Button, { ButtonGroup } from '@atlaskit/button'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'

//Interfaces
import { RoyanTable } from '@royan-co/user-interface'

// Components
import { generateServiceCodesColumns } from '../components/List/columns'

const pageTitle = 'Services'

const ServiceCodesListPage = () => {
  const navigate = useNavigate()

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <PageHeader
        breadcrumbs={
          <Breadcrumbs>
            <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
            <BreadcrumbsItem text="Configurations" />
          </Breadcrumbs>
        }
        actions={
          <ButtonGroup>
            <Button appearance="primary" onClick={() => navigate('/panel/service-codes/create')}>
              New service code
            </Button>
          </ButtonGroup>
        }
      >
        {pageTitle}
      </PageHeader>

      <RoyanTable
        sortOrder="DESC"
        name="services-list"
        sortKey="code"
        request={getServices}
        filters={[]}
        columns={generateServiceCodesColumns()}
      />
    </>
  )
}

export default ServiceCodesListPage
