import { useSearchParams } from 'react-router-dom'

// Contexts
import { useFinanceEditingContext } from 'contexts/FinanceEditingProvider'

// Atlassian
import Button from '@atlaskit/button'

// Interfaces
import { ArrowRightIcon, ArrowLeftIcon, Grid, useDisclosure } from '@royan-co/user-interface'

// Components
import EditFinanceLegModal from './legModal'
import StepLocationIndicator from 'components/UI/StepLocationIndicator'

const EditFinanceProgressTracker = () => {
  const [_, setSearchParams] = useSearchParams()
  const [isOpenLegModal, legModalHandler] = useDisclosure('leg-modal')
  const { isEmpty, financeData, currentLegIndex, legs, addLeg, EditNode, createCompleteLeg } =
    useFinanceEditingContext()

  const openCreateLegModal = () => legModalHandler.open()
  const openEditLegModal = (leg, index, isArrivalNode) =>
    legModalHandler.open({ leg, index, isArrivalNode })

  const gotToLeg = (index) => {
    setSearchParams({
      leg: index,
    })
  }

  const createLeg = (data) => {
    if (isEmpty) {
      createCompleteLeg(data.type)
      legModalHandler.close()
    } else {
      addLeg(data)
      gotToLeg(financeData.length)
    }
  }

  const EditLeg = (data) => {
    EditNode(data)
    legModalHandler.close()
  }

  const creationLegHandle = (data) => {
    if (data?.index !== undefined) EditLeg(data)
    else createLeg(data)
  }

  const nextLeg = legs?.[currentLegIndex + 1]
  const prevLeg = legs?.[currentLegIndex - 1]

  return (
    <Grid className="w-full mb-4">
      <Grid.Col lg={9}>
        <StepLocationIndicator
          steps={legs}
          currentStepIndex={currentLegIndex}
          addStep={openCreateLegModal}
          onClickStep={openEditLegModal}
          goToLeg={gotToLeg}
        />
      </Grid.Col>

      <Grid.Col lg={3}>
        <div className="flex gap-2 justify-between items-center h-full">
          {prevLeg?.id !== undefined && (
            <Button
              appearance="subtle-link"
              className="!p-0"
              onClick={() => gotToLeg(currentLegIndex - 1)}
              iconBefore={
                <div className="p-1 !rounded-full bg-neutral-20">
                  <ArrowLeftIcon />
                </div>
              }
              isDisabled={prevLeg?.id === undefined}
            >
              <span style={{ color: '#000', fontWeight: '400' }}>Previous leg</span>
            </Button>
          )}

          {nextLeg?.id !== undefined && (
            <Button
              appearance="subtle-link"
              className="!p-0 !ml-auto"
              onClick={() => gotToLeg(currentLegIndex + 1)}
              iconAfter={
                <div className="p-1 !rounded-full bg-neutral-20">
                  <ArrowRightIcon />
                </div>
              }
              isDisabled={nextLeg?.id === undefined}
            >
              <span style={{ color: '#000', fontWeight: '400' }}>Next leg</span>
            </Button>
          )}
        </div>
      </Grid.Col>

      <EditFinanceLegModal
        onSubmit={creationLegHandle}
        isOpen={isOpenLegModal}
        leg={legModalHandler?.data}
        onClose={legModalHandler.close}
        completeLeg={isEmpty}
      />
    </Grid>
  )
}

export default EditFinanceProgressTracker
