import { useQuery } from '@tanstack/react-query'
import axios from 'services/Request'

export function getWaybill(acronym) {
  return axios.get(`/api/shipments/documents/airwaybills/get-waybill/${acronym}`)
}

export const useGetWaybill = (acronym, { enabled = true } = {}) => {
  const { isInitialLoading, data, isFetching, refetch } = useQuery(
    ['waybill', acronym],
    () => getWaybill(acronym),
    {
      retry: 2,
      staleTime: Infinity,
      enabled,
    }
  )

  return {
    waybill: data,
    isLoadingGetWaybill: isInitialLoading,
    refetchGetWaybill: refetch,
    isFetchingGetWaybill: isFetching,
  }
}
