export const invoiceTypes = [
  {
    name: 'active',
    key: 'invoices-list',
    pathList: '/panel/active-invoices',
    title: 'Active invoices',
    invoiceKeys: {
      active_passive: 0,
      buffer: 0,
    },
    requestParams: {
      type: 0,
    },
  },
  {
    name: 'passive',
    key: 'invoices-list-passive',
    pathList: '/panel/passive-invoices',
    title: 'Passive invoices',
    invoiceKeys: {
      active_passive: 1,
      buffer: 0,
    },
    requestParams: {
      type: 1,
    },
  },
  {
    name: 'buffer',
    key: 'invoices-list-buffer',
    pathList: '/panel/buffer-invoices',
    title: 'Buffer invoices',
    invoiceKeys: {
      active_passive: 1,
      buffer: 1,
    },
    requestParams: {
      type: 1,
      buffer: 1,
    },
  },
]
