import { useMemo } from 'react'

// Atlassian
import Button, { LoadingButton } from '@atlaskit/button'

// Interfaces
import { useFormValues } from '@royan-co/user-interface'

const LinkInvoiceFormButtons = ({ onCancel, isLoadingSubmit }) => {
  const formValues = useFormValues()
  const selectedRows = useMemo(() => formValues?.rows?.filter((r) => !!r.checked), [formValues])

  return (
    <div className="text-right pb-6 mt-6">
      <Button appearance="subtle" onClick={onCancel}>
        Cancel
      </Button>

      <LoadingButton
        type="submit"
        appearance="primary"
        isDisabled={!selectedRows?.length}
        isLoading={isLoadingSubmit}
        autoFocus
      >
        Add rows
      </LoadingButton>
    </div>
  )
}

export default LinkInvoiceFormButtons
