import { useMemo } from 'react'

// Hooks
import useRegistryAddress from 'hooks/useRegistryAddress'

// Utils
import { transformSelectField } from 'utils/helpers'

// Atlassian
import Select from '@atlaskit/select'

import { ErrorMessage } from '@atlaskit/form'
import Toggle from '@atlaskit/toggle'

// Interfaces
import { Field, useFormContext } from '@royan-co/user-interface'

//Components
import RegistrySelect from 'components/Registries/RegistrySelect'

const types = [
  {
    label: 'Normal',
    id: 'null',
  },
  {
    label: 'Warehousing ',
    id: 'warehouse',
  },
]

const ShareModalFields = () => {
  const { watch, setValue } = useFormContext()
  const registry = watch('registry_id')

  const typesOptions = useMemo(() => types?.map((n) => ({ label: n.label, value: n.id })), [])
  const { registryAddressOptions, isLoadingRegistryAddress } = useRegistryAddress(registry?.value)

  return (
    <>
      <div className="px-6">
        <Field
          isRequired
          type="select"
          className="!mt-0"
          label="Company"
          name="registry_id"
          onValueChange={() => {
            setValue('registry_address_id', null)
          }}
        >
          {({ fieldProps, error }) => (
            <>
              <RegistrySelect {...fieldProps} />
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </>
          )}
        </Field>

        {registry?.value && (
          <Field isRequired type="select" name="registry_address_id" label="Address">
            {({ fieldProps, error }) => (
              <>
                <Select
                  placeholder="Choose"
                  options={registryAddressOptions}
                  isLoading={isLoadingRegistryAddress}
                  {...fieldProps}
                />

                {error && <ErrorMessage>{error}</ErrorMessage>}
              </>
            )}
          </Field>
        )}

        <Field
          isRequired
          name="type"
          label="Sharing type"
          transform={transformSelectField(typesOptions)}
          className="!mt-2"
        >
          {({ fieldProps, error }) => (
            <>
              <Select options={typesOptions} {...fieldProps} />
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </>
          )}
        </Field>

        <Field
          type="checkbox"
          name="share_documents"
          label="Share documents"
          defaultValue={false}
          key="share_document"
          className="!mt-2"
        >
          {({ fieldProps, error }) => (
            <>
              <div>
                <Toggle {...fieldProps} id="share_document" />
              </div>
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </>
          )}
        </Field>

        <Field
          type="checkbox"
          name="share_accounting"
          label="Share accounting"
          defaultValue={false}
          key="share_accounting"
          className="mt-2"
        >
          {({ fieldProps, error }) => (
            <>
              <div>
                <Toggle {...fieldProps} id="share_accounting" />
              </div>
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </>
          )}
        </Field>
      </div>
    </>
  )
}

export default ShareModalFields
