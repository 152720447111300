import { Helmet } from 'react-helmet-async'
import { Link, useNavigate } from 'react-router-dom'

// API
import { getLines } from '../api/requests'

// Atlassian
import PageHeader from '@atlaskit/page-header'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'
import Button, { ButtonGroup } from '@atlaskit/button'

import { RoyanTable, useDisclosure } from '@royan-co/user-interface'

// Components
import { generateLineColumns } from '../components/List/columns'
import LinesFilterFields from '../components/List/FilterFields'
import { lineFilters } from '../components/List/filters'
import DeleteLineModal from '../components/View/DeleteLineModal'

const pageTitle = 'Lines'

const LinesListPage = () => {
  const navigate = useNavigate()
  const [isOpenDeleteModal, deleteModalHandler] = useDisclosure('delete')

  const handleDoubleClick = (e, item) => {
    navigate(`/panel/sales-machine/lines/${item.id}`)
  }

  const openDeleteModal = (item) => {
    deleteModalHandler.open(item.id)
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <PageHeader
        breadcrumbs={
          <Breadcrumbs>
            <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
            <BreadcrumbsItem text="Sales machine" />
          </Breadcrumbs>
        }
        actions={
          <ButtonGroup>
            <Button
              appearance="primary"
              onClick={() => navigate('/panel/sales-machine/lines/create')}
            >
              New line
            </Button>
          </ButtonGroup>
        }
      >
        {pageTitle}
      </PageHeader>

      <RoyanTable
        sortOrder="DESC"
        name="lines-list"
        sortKey="id"
        request={getLines}
        onRowDoubleClick={handleDoubleClick}
        filterFields={<LinesFilterFields />}
        filters={lineFilters}
        columns={generateLineColumns(openDeleteModal)}
      >
        <DeleteLineModal
          isOpen={isOpenDeleteModal}
          onClose={deleteModalHandler.close}
          lineId={deleteModalHandler.data}
        />
      </RoyanTable>
    </>
  )
}

export default LinesListPage
