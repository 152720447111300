//Atlassian
import { useNavigate, useParams } from 'react-router-dom'

// Hooks
import useShipment from 'hooks/useShipment'

// Contexts
import { useAuth } from 'contexts/AuthProvider'

// Atlassian
import Button from '@atlaskit/button'

// Features
import { useCanAccess } from 'features/auth'

// Components
import { generateShipmentUserPermissions } from '../Create/shipment-user-permissions'

const ShipmentViewHouseShipmentsActions = () => {
  const navigate = useNavigate()
  const { currentUser } = useAuth()
  const { shipmentId } = useParams()
  const { shipment } = useShipment()

  const userPermissions = generateShipmentUserPermissions(shipment, currentUser)
  const canEdit = useCanAccess({
    resource: 'shipments.houseShipments',
    action: 'edit',
    userPermissions,
  })

  return (
    <>
      <Button
        onClick={() => {
          shipment?.house_shipments?.forEach((item) => {
            window.open(`/panel/shipments/${item.id_auto}`, '_blank')
          })
        }}
      >
        Open all in new tabs
      </Button>

      {canEdit && (
        <Button
          appearance="primary"
          onClick={() => {
            navigate(`/panel/shipments/${shipmentId}/edit-master`)
          }}
        >
          Edit house shipments
        </Button>
      )}
    </>
  )
}

export default ShipmentViewHouseShipmentsActions
