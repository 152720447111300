import React, { useMemo, useState } from 'react'

//Services
import groupBy from 'lodash/groupBy'

//Icons
import LocationIcon from '@atlaskit/icon/glyph/location'
import FinishFlagIcon from 'components/Icons/FinishFlagIcon'

//Atlassian
import Button from '@atlaskit/button'
import { G400, N800, R400 } from '@atlaskit/theme/colors'

//Interfaces
import { Timeline, TimelineItem } from '@royan-co/user-interface'

//Components
import RoutingMap from './RoutingMap'

const ShipmentTrackingRouting = ({
  showAirportsLine,
  tracking,
  dates,
  entityType,
  entityStatus,
  departure,
  arrival,
  arrivalStatus,
  departureStatus,
  arrivalAirport
}) => {
  const [showMap, setShowMap] = useState(false)

  const isArrived = entityStatus === arrivalStatus

  function handleShowMap() {
    setShowMap((c) => !c)
  }

  const groupDates = useMemo(() => {
    let data = groupBy(tracking, (pin) => pin.creation_date.split(' ')[0]) || []
    let result = Object.entries(data)

    result.push([
      'departure',
      [
        {
          creation_date: dates?.[departureStatus],
          address: [departure?.cap, departure?.city, departure?.country]
            .filter((a) => a)
            .join(', '),
        },
      ],
    ])

    if (isArrived) {
      result.unshift([
        'arrival',
        [
          {
            creation_date: dates?.[arrivalStatus],
            address: [arrival?.cap, arrival?.city, arrival?.country].filter((a) => a).join(', '),
          },
        ],
      ])
    }

    return result
  }, [isArrived, arrival, departure, tracking, dates, arrivalStatus, departureStatus])

  return (
    <div className="w-full">
      {showMap && (
        <div style={{ width: '100%', height: '300px', marginBottom: '1rem' }}>
          <RoutingMap
            showAirportsLine={showAirportsLine}
            entityType={entityType}
            entityStatus={entityStatus}
            pins={tracking}
            handleShowMap={() => handleShowMap()}
            departure={departure}
            arrival={arrival}
            arrivalStatus={arrivalStatus}
            arrivalAirport={arrivalAirport}
          />
        </div>
      )}

      <Timeline>
        {groupDates?.map((pin, index) => (
          <TimelineItem
            key={index}
            title={pin[1][0].address}
            moreTitle="More in this date"
            bulletColor={pin[0] === 'departure' ? G400 : N800}
            bullet={
              pin[0] === 'arrival' ? (
                <FinishFlagIcon />
              ) : (
                index === 0 && pin[0] !== 'departure' && <LocationIcon primaryColor={R400} />
              )
            }
            description={
              <>
                {pin[1][0]?.reefer_temperature && (
                  <div>Temperature: {pin[1][0]?.reefer_temperature} °C</div>
                )}

                {pin[0] === 'departure'
                  ? pin[1][0].creation_date
                    ? `Collection planned on: ${pin[1][0].creation_date}`
                    : `Collection not planned yet.`
                  : pin[1][0].creation_date && <div>Recorded at {pin[1][0].creation_date}</div>}
              </>
            }
            info={
              pin[1][0].title ? (
                <>
                  <div>{pin[1][0].title}</div>
                  <div>{pin[1][0].description}</div>
                </>
              ) : null
            }
            rightAction={
              index === 0 && (
                <Button
                  spacing="compact"
                  onClick={handleShowMap}
                  appearance={showMap ? 'warning' : 'primary'}
                >
                  {showMap ? 'Close map' : 'View on map'}
                </Button>
              )
            }
          >
            {pin[1].slice(1).map((child, index2) => (
              <TimelineItem
                key={index2}
                title={child.address}
                description={
                  <>
                    {child.reefer_temperature && (
                      <div>Temperature: {child.reefer_temperature} °C</div>
                    )}
                    <div>Recorded at {child.creation_date}</div>
                  </>
                }
                info={
                  child.title ? (
                    <>
                      <div>{child.title}</div>
                      <div>{child.description}</div>
                    </>
                  ) : null
                }
              />
            ))}
          </TimelineItem>
        ))}
      </Timeline>
    </div>
  )
}

export default ShipmentTrackingRouting
