import Lozenge from '@atlaskit/lozenge'

const CreditLineStatusTag = ({ line, lock }) => {
  return line === null ? (
    <Lozenge appearance="default">NOT SET</Lozenge>
  ) : lock === 0 ? (
    <Lozenge appearance="success">ACTIVE</Lozenge>
  ) : lock === 1 ? (
    <Lozenge appearance="removed">BLOCKED</Lozenge>
  ) : null
}

export default CreditLineStatusTag
