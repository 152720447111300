import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

import { createShareTrackingContact, editShareTrackingContact } from 'api/consolidations'

//Hooks
import useConsolidation from 'hooks/useConsolidation'

//Utils
import { getFilled } from 'utils/helpers'
import { handleErrorOnSubmit } from 'utils/errors'

//Atlassian
import TextField from '@atlaskit/textfield'
import { Checkbox } from '@atlaskit/checkbox'
import { ErrorMessage } from '@atlaskit/form'
import Button, { LoadingButton } from '@atlaskit/button'
import Modal, {
  ModalTitle,
  ModalHeader,
  ModalFooter,
  ModalTransition,
} from '@atlaskit/modal-dialog'

//Interfaces
import {
  Field,
  Form,
  useFlags,
  PhoneInput,
  WarningBannerField,
  useGeoInfo,
} from '@royan-co/user-interface'

const ShareTrackingModal = ({ isOpen, onClose, editingContact }) => {
  const { geoInfo } = useGeoInfo()
  const { consolidationId } = useParams()
  const { showSuccessFlag } = useFlags()

  const { upsertTrackingContactToData } = useConsolidation(consolidationId)

  const isEditing = useMemo(() => editingContact?.id !== undefined, [editingContact])

  const contactCreationMutation = useMutation(
    isEditing
      ? editShareTrackingContact.bind(null, editingContact?.id)
      : createShareTrackingContact.bind(null, consolidationId)
  )

  const handleSubmit = (data, { setError }) => {
    const formData = {
      name: data?.name,
      phone: data?.shareViaWhatsapp ? data?.phone || '' : '',
      contact: data?.shareViaEmail ? data?.contact || '' : '',
    }

    if (!formData.phone && !formData.contact) {
      setError('unknown', { type: 'api', message: 'Please select email or whatsapp' })
      return
    }

    contactCreationMutation.mutate(formData, {
      onError: (e) => {
        handleErrorOnSubmit(e, setError, formData)
        console.error(e)
      },
      onSuccess: (res) => {
        upsertTrackingContactToData(res.contact)
        showSuccessFlag(
          !isEditing
            ? 'Tracking contact created successfully'
            : 'Tracking contact edited successfully'
        )
        handleCloseModal()
      },
    })
  }

  const defaultValues = useMemo(
    () => ({
      contact: getFilled(editingContact, 'contact', ''),
      phone: getFilled(editingContact, 'phone', ''),
      shareViaEmail: !!getFilled(editingContact, 'contact', ''),
      shareViaWhatsapp: !!getFilled(editingContact, 'phone', ''),
    }),
    [editingContact]
  )

  const handleCloseModal = () => {
    onClose()
    contactCreationMutation.reset()
  }

  return (
    <>
      <ModalTransition>
        {isOpen && (
          <Modal width={'medium'} onClose={handleCloseModal}>
            <Form onSubmit={handleSubmit} defaultValues={defaultValues}>
              {({ watch }) => (
                <>
                  <ModalHeader>
                    <ModalTitle>{isEditing ? 'Edit contact' : 'Share tracking'}</ModalTitle>
                  </ModalHeader>

                  <div className="px-6">
                    <p>Note that this consolidation alerts will be shared with this contact.</p>

                    <div className="mt-4">
                      <Field
                        isRequired
                        name="name"
                        label="Name"
                        defaultValue={editingContact?.name || ''}
                      >
                        {({ fieldProps, error }) => (
                          <>
                            <TextField {...fieldProps} placeholder="Type the name" />
                            {error && <ErrorMessage>{error}</ErrorMessage>}
                          </>
                        )}
                      </Field>

                      <div className="mt-3">
                        <Field type="checkbox" name="shareViaEmail" className="!mt-0">
                          {({ fieldProps }) => (
                            <Checkbox label="Notify via email" {...fieldProps} />
                          )}
                        </Field>

                        {watch('shareViaEmail') && (
                          <Field isRequired name="contact">
                            {({ fieldProps, error }) => (
                              <>
                                <TextField
                                  type="email"
                                  placeholder="Type the email"
                                  {...fieldProps}
                                />
                                {error && <ErrorMessage>{error}</ErrorMessage>}
                              </>
                            )}
                          </Field>
                        )}
                      </div>

                      <div className="mt-3">
                        <Field type="checkbox" name="shareViaWhatsapp" className="!mt-0">
                          {({ fieldProps }) => (
                            <Checkbox label="Notify via Whatsapp" {...fieldProps} />
                          )}
                        </Field>

                        {watch('shareViaWhatsapp') && (
                          <Field
                            isRequired
                            name="phone"
                            transform={{
                              input: (v) => ({
                                value: v?.value || v,
                              }),
                              output: (opt) => opt?.value,
                            }}
                          >
                            {({ fieldProps: { ref, ...fieldProps }, error }) => (
                              <>
                                <PhoneInput
                                  defaultCountry={geoInfo?.countryCode?.toLowerCase()}
                                  {...fieldProps}
                                />
                                {error && <ErrorMessage>{error}</ErrorMessage>}
                              </>
                            )}
                          </Field>
                        )}
                      </div>
                    </div>

                    <WarningBannerField />
                  </div>

                  <ModalFooter>
                    <Button appearance="subtle" onClick={handleCloseModal}>
                      Cancel
                    </Button>

                    <LoadingButton
                      type="submit"
                      appearance="primary"
                      isLoading={contactCreationMutation.isLoading}
                      autoFocus
                    >
                      Confirm
                    </LoadingButton>
                  </ModalFooter>
                </>
              )}
            </Form>
          </Modal>
        )}
      </ModalTransition>
    </>
  )
}

export default ShareTrackingModal
