import { useMutation } from '@tanstack/react-query'

// Contexts
import { useApp } from 'contexts/AppProvider'

// Apis
import { getShipmentsExport } from './dashboard-requests'

export const useExportShipments = (type, params) => {
  const { setIsExportExcel } = useApp()

  return useMutation({
    mutationFn: () => getShipmentsExport(type, params),
    onSuccess: () => {
      setIsExportExcel(true)
    },
  })
}
