/**
 * form given number
 * @param {string | number} number
 * @param {number} fractionDigits
 * @returns {string}
 */
export const formatNumber = (number, fractionDigits = 2) => {
  if (!number || number === undefined) {
    return 0
  }

  const decimal = (number + '').split('.')[1]

  if (decimal && parseInt(decimal) !== 0) {
    return (number * 1).toLocaleString('en-US', {
      maximumFractionDigits: fractionDigits || 20,
      minimumFractionDigits: 2,
    })
  } else {
    return (number * 1).toLocaleString('en-US')
  }
}

/**
 * format monetary number to de format
 * @param {string | number} number
 * @returns {string}
 */
export const formatMonetaryNumber = (number) => {
  if (!number || number === undefined) {
    return 0
  }

  return (number * 1).toLocaleString('de-DE', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })
}
