import { useEffect, useRef, useState } from 'react'

// Styles
import styles from './LogoPicker.module.scss'

// Atlassian
import Spinner from '@atlaskit/spinner'

// Interfaces
import { UploadIcon, useFlags, validateSelectedImageFile } from '@royan-co/user-interface'

const ChangeProfile = ({
  imageUrl,
  isLoading,
  placeholder,
  onChangeImage,
  maxSize = 1,
  types = ['png', 'jpg', 'jpeg'],
}) => {
  const logoInputRef = useRef()
  const { showWarningFlag } = useFlags()
  const [preview, setPreview] = useState(imageUrl)

  const handleSelectFiles = (files) => {
    const file = files?.[0]

    if (isLoading || !file) return

    try {
      validateSelectedImageFile(file, { maxSize: maxSize, types: types })

      setPreview(URL.createObjectURL(file))

      onChangeImage(file)
    } catch (e) {
      if (e?.name === 'ValidationError') {
        showWarningFlag(e.message)
      }
    } finally {
      logoInputRef.current.value = null
    }
  }

  const onInputChange = () => {
    handleSelectFiles(logoInputRef.current.files)
  }

  useEffect(() => {
    if (!preview) {
      setPreview(imageUrl)
    }
  }, [imageUrl, preview])

  return (
    <>
      <label
        tabIndex={1}
        className={`${styles.logoPicker} ${preview ? styles['logoPicker--has-image'] : ''}`}
      >
        <input
          type="file"
          ref={logoInputRef}
          disabled={isLoading}
          onChange={onInputChange}
          className={styles.fileInput}
          accept={types.map((a) => `.${a}`).join(', ')}
        />

        <div className={styles.logoPicker__upload}>
          <UploadIcon size={34} />
        </div>

        <div className={styles.logoPicker__image}>
          {preview ? <img alt="" src={preview} /> : placeholder}
        </div>

        {isLoading && (
          <div className={styles.logoPicker__spinner}>
            <Spinner size="medium" appearance="invert" />
          </div>
        )}
      </label>
    </>
  )
}

export default ChangeProfile
