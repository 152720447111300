// Icons
import { CautionIcon, CheckCircleIcon } from '@royan-co/user-interface'

// Atlassian
import Heading from '@atlaskit/heading'

const CardPoint = ({ status, value }) => {
  return (
    <div className="flex flex-col items-center">
      <div className="mb-1">
        {status === 'loaded' ? (
          <CheckCircleIcon className="text-green-400" />
        ) : (
          <CautionIcon className="text-neutral-70" />
        )}
      </div>

      <Heading level="h400">{value}</Heading>

      <small>{status}</small>
    </div>
  )
}

const GeneralEntranceCard = ({ activeEntrance, loadedEntrances, pendingEntrances }) => {
  return (
    <div className="sticky top-8 bg-white z-50 shadow-md rounded mt-4 flex justify-between p-4">
      <CardPoint status="loaded" value={loadedEntrances} />

      <div className="grow text-center overflow-hidden mx-2">
        <div className="w-full border-t border-dashed border-neutral-200  mt-3" />

        <div className="flex flex-col items-center -mt-3">
          <small className="bg-white px-2 pt-1 pb-2">
            {activeEntrance
              ? 'Loading now'
              : pendingEntrances === 0
              ? 'Loading complete'
              : 'Waiting for scans'}
          </small>

          {activeEntrance ? (
            <>
              <Heading level="h400">{activeEntrance.numerator}</Heading>

              <small className="text-neutral-200">
                ({activeEntrance?.loaded_collies || 0}/{activeEntrance?.total_collies || 0})
              </small>
            </>
          ) : null}
        </div>
      </div>

      <CardPoint status="pending" value={pendingEntrances} />
    </div>
  )
}

export default GeneralEntranceCard
