//Interfaces
import {
  DocsIcon,
  TrayIcon,
  CommentIcon,
  RoadMapIcon,
  FrequentIcon,
  ComponentIcon,
  ArrowDownIcon,
  LocationIcon,
  CreditcardIcon,
  ShareIcon,
  ListIcon,
  ContainerIcon,
  BillingOutlineIcon,
} from '@royan-co/user-interface'

//Components
import ShipmentViewCargoActions from 'components/Shipments/View/CargoActions'
import ShipmentViewGeneralInformationActions from 'components/Shipments/View/GeneralInformationActions'
import ShipmentViewPaymentReminderActions from 'components/Shipments/View/PaymentReminderActions'
import ShipmentViewTrackingActions from 'components/Shipments/View/TrackingActions'
import ShipmentViewSharesActions from 'components/Shipments/View/SharesActions'
import ShipmentViewHouseShipmentsActions from 'components/Shipments/View/HouseShipmentsActions'
import ShipmentViewFinanceActions from 'components/Shipments/View/FinanceActions'

const shipmentViewPages = [
  {
    icon: TrayIcon,
    title: 'General Information',
    pathSuffix: '',
    actions: ShipmentViewGeneralInformationActions,
  },
  {
    icon: ListIcon,
    title: 'House Shipments',
    pathSuffix: 'house-shipments',
    showCondition: (shipment, isShipment) => !!(isShipment && shipment?.is_master),
    actions: ShipmentViewHouseShipmentsActions,
  },
  {
    icon: ComponentIcon,
    title: 'Cargo Details',
    pathSuffix: 'cargo-details',
    actions: ShipmentViewCargoActions,
  },
  {
    icon: ContainerIcon,
    title: 'Container Details',
    pathSuffix: 'container-details',
    showCondition: (shipment, isShipment) => !!(isShipment && shipment?.shipment_type === 1),
  },
  {
    icon: BillingOutlineIcon,
    title: 'Finance',
    pathSuffix: 'finance',
    actions: ShipmentViewFinanceActions,
    limitationAccess: { resource: 'shipments.finance', action: 'view' },
  },
  {
    icon: LocationIcon,
    title: 'Tracking',
    pathSuffix: 'tracking',
    showCondition: (shipment, isShipment) => !!isShipment,
    actions: ShipmentViewTrackingActions,
  },
  {
    icon: CreditcardIcon,
    title: 'Active Invoices',
    pathSuffix: 'active-invoices',
    showCondition: (shipment, isShipment) => !!isShipment,
    actions: ShipmentViewPaymentReminderActions,
  },
  {
    icon: CreditcardIcon,
    title: 'Passive Invoices',
    pathSuffix: 'passive-invoices',
    showCondition: (shipment, isShipment) => !!isShipment,
    limitationAccess: { resource: 'shipments.passiveInvoices', action: 'view' },
  },
  {
    icon: ArrowDownIcon,
    title: 'Entrances',
    pathSuffix: 'entrances',
    showCondition: (shipment, isShipment) => !!(shipment?.entrances_count > 0 && isShipment),
  },
  {
    icon: DocsIcon,
    title: 'Documents',
    pathSuffix: 'documents',
  },
  {
    icon: FrequentIcon,
    title: 'History',
    pathSuffix: 'history',
  },
  {
    icon: CommentIcon,
    title: 'Notes',
    pathSuffix: 'notes',
  },
  {
    icon: ShareIcon,
    title: 'Shares',
    pathSuffix: 'shares',
    showCondition: (shipment, isShipment) => !!isShipment,
    actions: ShipmentViewSharesActions,
    limitationAccess: { resource: 'shipments.share', action: 'view' },
  },
  {
    icon: RoadMapIcon,
    title: 'Actions',
    pathSuffix: 'actions',
    showCondition: (shipment, isShipment) => !!isShipment,
  },
]

const getShipmentViewPages = (shipmentId, shipment, isShipment) => {
  return shipmentViewPages.map((i) => ({
    ...i,
    path: `/panel/${isShipment ? 'shipments' : 'quotations'}/${shipmentId}/${i.pathSuffix}`,
    show: typeof i.showCondition === 'function' ? i.showCondition(shipment, isShipment) : true,
  }))
}

export { getShipmentViewPages }
