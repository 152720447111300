import { createContext, useContext, useMemo, useState } from 'react'
import { useAuth } from './AuthProvider'

//Atlassian
import Spinner from '@atlaskit/spinner'

const AppContext = createContext()

export function useApp() {
  return useContext(AppContext)
}

export const AppProvider = ({ children }) => {
  const { isAuthenticated, isLoadingUser } = useAuth()
  const [isExportExcel, setIsExportExcel] = useState(false)
  const [loadingOverlay, setLoadingOverlay] = useState(false)

  const isLoading = useMemo(() => {
    return isAuthenticated && isLoadingUser
  }, [isAuthenticated, isLoadingUser])

  const values = {
    isExportExcel,
    setIsExportExcel,
    loadingOverlay,
    setLoadingOverlay,
  }

  return (
    <AppContext.Provider value={values}>
      {!isLoading ? (
        children
      ) : (
        <div className="flex justify-center items-center h-screen">
          <Spinner size={'large'} />
        </div>
      )}
    </AppContext.Provider>
  )
}
export default AppContext
