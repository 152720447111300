// Utils
import { transformSelectField } from 'utils/helpers'
import { bookedOptions, usedOptions } from 'features/AirWaybillStock/utils/constants'

// Atlassian
import Select from '@atlaskit/select'

// Interfaces
import { Field } from '@royan-co/user-interface'

const TableFilterFields = () => {
  return (
    <>
      <Field
        type="select"
        label="Booked"
        name="book"
        transform={transformSelectField(bookedOptions)}
      >
        {({ fieldProps }) => (
          <Select isClearable={true} placeholder="Choose" options={bookedOptions} {...fieldProps} />
        )}
      </Field>

      <Field type="select" label="Used" name="used" transform={transformSelectField(usedOptions)}>
        {({ fieldProps }) => (
          <Select isClearable={true} placeholder="Choose" options={usedOptions} {...fieldProps} />
        )}
      </Field>
    </>
  )
}

export default TableFilterFields
