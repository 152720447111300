import React from 'react'

//Atlassian
import { DatePicker } from '@atlaskit/datetime-picker'
import Button, { ButtonGroup, LoadingButton } from '@atlaskit/button'
import Modal, { ModalBody, ModalFooter, ModalTransition } from '@atlaskit/modal-dialog'

//Interfaces
import { Field, Form } from '@royan-co/user-interface'

const ShipmentTrackingTemperatureModal = ({ isOpen, onClose, toDate, fromDate, setNewRange }) => {
  async function submitApplyFilters(data) {
    setNewRange(data.from, data.to)
    onClose()
  }

  return (
    <>
      {isOpen && (
        <ModalTransition>
          <Modal autoFocus={false} width={'small'} onClose={onClose}>
            <Form onSubmit={submitApplyFilters} defaultValues={{ from: fromDate, to: toDate }}>
              {() => (
                <>
                  <ModalBody>
                    <div className="mt-4">
                      <Field name="from" label="From">
                        {({ fieldProps }) => (
                          <DatePicker
                            placeholder="2/18/2022"
                            dateFormat="D MMM YYYY"
                            {...fieldProps}
                          />
                        )}
                      </Field>

                      <Field name="to" label="To">
                        {({ fieldProps }) => (
                          <DatePicker
                            placeholder="2/18/2022"
                            dateFormat="D MMM YYYY"
                            {...fieldProps}
                          />
                        )}
                      </Field>
                    </div>
                  </ModalBody>

                  <ModalFooter>
                    <ButtonGroup>
                      <Button onClick={onClose} spacing="compact" appearance="default">
                        Discard
                      </Button>
                      <LoadingButton type="submit" spacing="compact" appearance="primary">
                        Apply
                      </LoadingButton>
                    </ButtonGroup>
                  </ModalFooter>
                </>
              )}
            </Form>
          </Modal>
        </ModalTransition>
      )}
    </>
  )
}

export default ShipmentTrackingTemperatureModal
