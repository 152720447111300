import styles from './StepLocationIndicator.module.scss'

const StepIcon = ({ icon, isActive, hasError, onClick }) => {
  return (
    <button
      onClick={onClick}
      type="button"
      className={
        styles.progressIndicator__icon +
        (isActive ? ` ${styles.active__icon}` : '') +
        (hasError ? ` ${styles.withError__icon}` : '')
      }
    >
      {icon}
    </button>
  )
}

export default StepIcon
