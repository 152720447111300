import { findOption } from 'utils/helpers'
import { shipmentCustomDocumentOptions } from './constants'

//Atlassian
import Lozenge from '@atlaskit/lozenge'

const DocumentTag = ({ flag }) => {
  const option = findOption(shipmentCustomDocumentOptions, flag)
  return (
    <>
      {flag === 0 || flag === 1 || flag === 2 ? (
        <div>{option.label}</div>
      ) : (
        <Lozenge appearance="default">Not set</Lozenge>
      )}
    </>
  )
}

export default DocumentTag
