import { useMemo, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { findOption, getCountry } from 'utils/helpers'
import { registryAddressTypeOptions } from '../constants'

//Atlassian
import { LoadingButton } from '@atlaskit/button'
import Drawer from '@atlaskit/drawer'
import TextField from '@atlaskit/textfield'
import { ErrorMessage } from '@atlaskit/form'
import Select, { CountrySelect } from '@atlaskit/select'

//Interfaces
import {
  useGeoInfo,
  Field,
  Form,
  PhoneInput,
  PlacesAutoComplete,
  WarningBannerField,
} from '@royan-co/user-interface'

const BranchDrawer = ({ onClose, isOpen, data, onSubmit, isSubmitting = false }) => {
  const { geoInfo } = useGeoInfo()
  const [defaultCountry, setDefaultCountry] = useState(null)
  const isMobile = useMediaQuery({ query: `(max-width: 991.98px)` })

  const isEditing = useMemo(() => data?.country_iso !== undefined, [data])

  return (
    <Drawer isOpen={isOpen} onClose={onClose} width={isMobile ? 'full' : 'medium'}>
      <div className="drawer">
        <h3>{isEditing ? 'Edit branch' : 'New branch'}</h3>

        <Form onSubmit={onSubmit} defaultValues={data}>
          {({ setValue, getValues, watch }) => (
            <>
              <Field
                isRequired
                type="select"
                label="Country"
                name="country_iso"
                className="!mt-4"
                transform={{
                  input: (v) => getCountry(v),
                  output: (opt) => (opt?.abbr !== undefined ? opt.abbr : null),
                }}
                onValueChange={(v) => setDefaultCountry(v)}
              >
                {({ fieldProps: { ref, ...fieldProps }, error }) => (
                  <>
                    <CountrySelect isClearable placeholder="Country" {...fieldProps} />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>

              <Field
                isRequired
                name="type"
                type="select"
                className="!mt-4"
                label="Address type"
                transform={{
                  input: (v) => v?.map((o) => findOption(registryAddressTypeOptions, o)),
                  output: (opt) => opt.map((o) => o.value),
                }}
              >
                {({ fieldProps, error }) => (
                  <>
                    <Select {...fieldProps} options={registryAddressTypeOptions} isMulti />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>

              <Field
                isRequired
                type="text"
                name="address"
                label="Address"
                type="text"
                defaultValue=""
                className="!mt-4"
                transform={{
                  output: (p) => {
                    if (!getValues('country_iso') && p?.countryAbbr)
                      setValue('country_iso', p.countryAbbr)
                    if (!getValues('cap') && p?.zipCode) setValue('cap', p.zipCode)
                    if (!getValues('city') && p?.city) setValue('city', p.city)
                    if (!getValues('province') && p?.province) setValue('province', p.province)
                    return p?.label
                  },
                }}
              >
                {({ fieldProps, error }) => (
                  <>
                    <PlacesAutoComplete {...fieldProps} placeholder="Type your address here" />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>

              <Field name="province" label="Province" defaultValue="" className="!mt-4">
                {({ fieldProps, error }) => (
                  <>
                    <TextField {...fieldProps} placeholder="Type the province here" />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>

              <Field isRequired name="city" label="City" className="!mt-4" defaultValue="">
                {({ fieldProps, error }) => (
                  <>
                    <TextField {...fieldProps} placeholder="Type the city here" />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>

              <Field
                isRequired={!!(watch('country_iso') === 'IT')}
                name="cap"
                label="Zip code"
                className="!mt-4"
                defaultValue=""
              >
                {({ fieldProps, error }) => (
                  <>
                    <TextField {...fieldProps} placeholder="Type the zip code here" />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>

              <Field
                isRequired
                label="phone"
                name="telephone"
                className="!mt-4"
                transform={{
                  input: (v) => ({
                    value: v?.value || v,
                  }),
                  output: (opt) => opt?.value,
                }}
              >
                {({ fieldProps: { ref, ...fieldProps }, error }) => (
                  <>
                    <PhoneInput
                      defaultCountry={
                        defaultCountry?.abbr?.toLowerCase() || geoInfo?.countryCode?.toLowerCase()
                      }
                      {...fieldProps}
                    />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>

              <WarningBannerField />

              <LoadingButton
                type="submit"
                className="mt-6"
                shouldFitContainer
                appearance="primary"
                isLoading={isSubmitting}
              >
                save
              </LoadingButton>
            </>
          )}
        </Form>
      </div>
    </Drawer>
  )
}

export default BranchDrawer
