import { useAuth } from 'contexts/AuthProvider'
import RedirectToAccount from 'components/RedirectToAccount'

const RequireAuth = ({ children }) => {
  const { isAuthenticated } = useAuth()

  return isAuthenticated ? children : <RedirectToAccount />
}

export default RequireAuth
