import React, { useState, useCallback, useRef, useEffect } from 'react'

//Apis
import { sendDigitsSignature } from '../api/sendDigitsSignature'
import { verifyDigitsSignature } from '../api/verifyDigitsSignature'

//Atlassian
import Button from '@atlaskit/button'
import SectionMessage from '@atlaskit/section-message'
import ButtonGroup from '@atlaskit/button/button-group'
import LoadingButton from '@atlaskit/button/loading-button'
import Spinner from '@atlaskit/spinner'
import { ModalBody, ModalFooter } from '@atlaskit/modal-dialog'
import { ErrorMessage } from '@atlaskit/form'

//Interfaces
import { Field, Form, useCountDown, OtpInput } from '@royan-co/user-interface'

const DigitalSignatureVerificationStep = ({ onBack, className, onSuccess, otpSignatureId }) => {
  const confirmBtnRef = useRef()
  const [isLoading, setIsLoading] = useState(false)
  const [isVerifying, setIsVerifying] = useState(false)
  const [unknownError, setUnknownError] = useState(false)
  const { timeLeft, reset: resetTimer } = useCountDown(120)

  const getVerificationToken = useCallback(async () => {
    setUnknownError(false)
    try {
      setIsLoading(true)
      await sendDigitsSignature(otpSignatureId)
      resetTimer()
    } catch (error) {
      setUnknownError(true)
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }, [resetTimer, otpSignatureId])

  async function verifyDigits({ otp }, { setError }) {
    if (!otp || otp.length < 4) {
      setError('otp', { type: 'api', message: 'The Otp field is required' })
      return
    }

    const arrayOfOTP = Array.from(String(otp), Number)

    try {
      setIsVerifying(true)

      await verifyDigitsSignature(otpSignatureId, { digits: arrayOfOTP })

      onSuccess(otpSignatureId)
    } catch (e) {
      console.error(e)
      if (e?.response?.data?.success === 403) {
        setError('otp', { type: 'api', message: e.response.data.message })
      } else {
        setError('otp', { type: 'api', message: 'Something went wrong' })
      }
    } finally {
      setIsVerifying(false)
    }
  }

  useEffect(() => {
    resetTimer()
  }, [resetTimer, otpSignatureId])

  return (
    <div className={className}>
      {!isLoading ? (
        !unknownError ? (
          <>
            <Form onSubmit={verifyDigits}>
              {(_, { submit }) => (
                <>
                  <ModalBody>
                    <p style={{ marginBottom: '32px' }}>
                      Please enter the 4-digit code sent to your Whatsapp account and email address
                      to verify and sign the form.
                    </p>

                    <Field name="otp">
                      {({ fieldProps, error }) => (
                        <>
                          <OtpInput
                            autoFocus
                            type="tel"
                            length={4}
                            onComplete={() => {
                              submit()
                              confirmBtnRef.current.focus()
                            }}
                            {...fieldProps}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>

                    <div className="text-center mt-4">
                      <Button
                        appearance="subtle"
                        onClick={getVerificationToken}
                        isDisabled={timeLeft.total > 0}
                      >
                        Resend {timeLeft.total > 0 && `(${timeLeft.minutes}:${timeLeft.seconds})`}
                      </Button>
                    </div>
                  </ModalBody>

                  <ModalFooter>
                    <ButtonGroup>
                      <Button onClick={onBack} appearance="subtle" isDisabled={isVerifying}>
                        Modify information
                      </Button>

                      <LoadingButton
                        type="submit"
                        ref={confirmBtnRef}
                        appearance="primary"
                        isLoading={isVerifying}
                      >
                        Confirm
                      </LoadingButton>
                    </ButtonGroup>
                  </ModalFooter>
                </>
              )}
            </Form>
          </>
        ) : (
          <>
            <ModalBody>
              <SectionMessage
                appearance="warning"
                title="Something went wrong on sending verification code."
              >
                <p>Please try again</p>
                <Button className="mt-2" onClick={getVerificationToken}>
                  Retry
                </Button>
              </SectionMessage>
            </ModalBody>

            <ModalFooter />
          </>
        )
      ) : (
        <Spinner />
      )}
    </div>
  )
}

export default DigitalSignatureVerificationStep
