import React, { useMemo } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'

// Apis
import { getRejectQuotationReasons, rejectQuotation } from 'api/shipments'

// Utils
import { handleErrorOnSubmit } from 'utils/errors'
import { transformSelectField } from 'utils/helpers'

// Atlassian
import Modal, {
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import Select from '@atlaskit/select'
import Button, { LoadingButton } from '@atlaskit/button'
import TextArea from '@atlaskit/textarea'
import { ErrorMessage } from '@atlaskit/form'

// Interfaces
import { Form, Field, WarningBannerField, WarningIcon, useFlags } from '@royan-co/user-interface'
import useShipment from 'hooks/useShipment'

const RejectQuoteModal = ({ isOpen, onClose, quotationId }) => {
  const { showSuccessFlag } = useFlags()
  const { changeQuotationStatus } = useShipment(quotationId)

  const { data, isLoading } = useQuery(['quotation-reasons'], () => getRejectQuotationReasons(), {
    staleTime: Infinity,
    retry: 2,
    enabled: !!isOpen,
  })

  const reasonOptions = useMemo(
    () =>
      data?.quotation_rejection_reasons?.map((item) => ({
        label: item,
        value: item,
      })) || [],
    [data]
  )

  const rejectMutation = useMutation(rejectQuotation.bind(null, quotationId))

  const closeModal = () => {
    rejectMutation.reset()
    onClose()
  }

  const rejectQuotationHandler = (data, { setError }) => {
    const formData = { rejected_reason: data.rejected_reason }
    if (data.rejected_reason === 'Other') formData.rejected_notes = data.rejected_notes

    rejectMutation.mutate(formData, {
      onError: (e) => {
        handleErrorOnSubmit(e, setError, data)
        console.error(e)
      },
      onSuccess: (res) => {
        showSuccessFlag(res?.message)
        changeQuotationStatus(3)
        closeModal()
      },
    })
  }

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={closeModal} shouldScrollInViewport>
          <Form onSubmit={rejectQuotationHandler}>
            {({ watch }) => (
              <>
                <ModalHeader>
                  <ModalTitle>
                    <WarningIcon className="text-yellow-300 -mt-1" />
                    <span className="mx-2">Reject quotation</span>
                  </ModalTitle>
                </ModalHeader>

                <div className="px-6">
                  <span>You are about to reject this inquiry. Are you sure?</span>

                  <div>
                    <Field
                      isRequired
                      type="select"
                      label="Reason"
                      name="rejected_reason"
                      className="!my-4"
                      transform={transformSelectField(reasonOptions)}
                    >
                      {({ fieldProps, error }) => (
                        <>
                          <Select
                            isClearable={true}
                            placeholder="Choose a reason"
                            isLoading={isLoading}
                            options={reasonOptions}
                            {...fieldProps}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>

                    {watch('rejected_reason') === 'Other' && (
                      <Field
                        label="Please provide us with more details:"
                        name="rejected_notes"
                        defaultValue=""
                      >
                        {({ ref, fieldProps, error }) => (
                          <>
                            <TextArea
                              {...fieldProps}
                              resize="auto"
                              maxHeight="20vh"
                              name="area"
                              rows={8}
                              placeholder="Notes on rejection"
                            />
                            {error && <ErrorMessage>{error}</ErrorMessage>}
                          </>
                        )}
                      </Field>
                    )}
                  </div>

                  <WarningBannerField />
                </div>

                <ModalFooter>
                  <Button appearance="subtle" onClick={closeModal}>
                    Cancel
                  </Button>
                  <LoadingButton isLoading={false} type="submit" appearance="warning" autoFocus>
                    Reject
                  </LoadingButton>
                </ModalFooter>
              </>
            )}
          </Form>
        </Modal>
      )}
    </ModalTransition>
  )
}

export default RejectQuoteModal
