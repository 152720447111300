import { Link } from 'react-router-dom'
// Utils
import { formatNumber } from 'utils/numbers'

// Interfaces
import { DotsIcon, ShortcutIcon } from '@royan-co/user-interface'

// Atlassian
import Lozenge from '@atlaskit/lozenge'
import Button from '@atlaskit/button'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'

// Components
import DropdownLink from 'components/UI/DropdownLink'
import ShipmentStatusTag from 'components/Shipments/StatusTag'
import AirWaybillTypeTag from '../TypeTag'

export const generateAirWaybillColumns = (openDeleteModal) => {
  return [
    {
      key: 'waybill',
      title: 'AWB no.',
      isSortable: true,
      show: true,
      content: (item) =>
        item.waybill ? `${item?.airline_data?.prefix}-${item.waybill}` : <Lozenge>Not set</Lozenge>,
    },
    {
      key: 'type',
      title: 'Type',
      isSortable: false,
      show: true,
      content: (item) => <AirWaybillTypeTag value={item.type} />,
    },
    {
      key: 'airline',
      title: 'Airline',
      isSortable: false,
      show: true,
      content: (item) => item?.airline || <Lozenge>Not set</Lozenge>,
    },
    {
      key: 'creator',
      title: 'Creator',
      isSortable: false,
      show: true,
      content: (item) => item?.creator?.full_name || <Lozenge>Not set</Lozenge>,
    },
    {
      key: 'code',
      title: 'Shipment',
      isSortable: false,
      show: true,
      content: (item) => (
        <span className="whitespace-nowrap">
          {item?.shipment?.id_auto ? (
            <Link to={`/panel/shipments/${item.shipment.id_auto}`} target="_blank">
              {item.shipment.reference} <ShortcutIcon size={16} />
            </Link>
          ) : null}
        </span>
      ),
    },
    {
      key: 'shipment_status',
      title: 'Shipment status',
      isSortable: false,
      show: true,
      content: (item) => <ShipmentStatusTag value={item.shipment.status} entity={'shipment'} />,
    },
    {
      key: 'shipper',
      title: 'Shipper',
      isSortable: false,
      show: false,
      content: (item) =>
        item?.shipper?.id_auto ? (
          <a href={`/panel/registries/${item.shipper.id_auto}`} target="_blank" rel="noreferrer">
            {item.shipper.company_name}
          </a>
        ) : (
          <Lozenge>Not set</Lozenge>
        ),
    },
    {
      key: 'consignee',
      title: 'Consignee',
      isSortable: false,
      show: false,
      content: (item) =>
        item?.consignee?.id_auto ? (
          <a href={`/panel/registries/${item.consignee.id_auto}`} target="_blank" rel="noreferrer">
            {item.consignee.company_name}
          </a>
        ) : (
          <Lozenge>Not set</Lozenge>
        ),
    },
    {
      key: 'departure_airport',
      title: 'Departure airport',
      isSortable: false,
      show: true,
      content: (item) => item.departure_airport || <Lozenge>Not set</Lozenge>,
    },
    {
      key: 'arrival_airport',
      title: 'Arrival airport',
      isSortable: false,
      show: true,
      content: (item) => item.arrival_airport || <Lozenge>Not set</Lozenge>,
    },
    {
      key: 'flight',
      title: 'Flight number',
      isSortable: false,
      show: true,
      content: (item) => item.flight || <Lozenge>Not set</Lozenge>,
    },
    {
      key: 'flight_date',
      title: 'Flight date',
      isSortable: true,
      show: true,
      content: (item) => item?.flight_date,
    },
    {
      key: 'collies_number',
      title: 'n° pieces',
      isSortable: false,
      show: true,
      content: (item) => item.collies_number ?? <Lozenge>Not set</Lozenge>,
    },
    {
      key: 'weight',
      title: 'GW',
      isSortable: true,
      show: true,
      content: (item) =>
        item?.weight ? (
          <span className="whitespace-nowrap">{formatNumber(item.weight)}</span>
        ) : (
          <Lozenge>Not set</Lozenge>
        ),
    },
    {
      key: 'chargeable_weight',
      title: 'CW',
      isSortable: false,
      show: true,
      content: (item) =>
        item?.chargeable_weight ? (
          <span className="whitespace-nowrap">{formatNumber(item.chargeable_weight)}</span>
        ) : (
          <Lozenge>Not set</Lozenge>
        ),
    },
    {
      key: 'creation_date',
      title: 'Creation date',
      isSortable: true,
      show: true,
      content: (item) => item?.creation_date,
    },
    {
      key: 'actions',
      title: 'Actions',
      show: true,
      content: (item) => (
        <DropdownMenu
          placement="bottom-end"
          trigger={({ triggerRef, shouldRenderToParent, ...props }) => (
            <Button
              {...props}
              appearance="subtle"
              children={<DotsIcon label="more" />}
              ref={triggerRef}
            />
          )}
        >
          <DropdownItemGroup>
            <DropdownLink children="View" to={`/panel/shipments/document/airwayBill/${item.id}`} />
            <DropdownLink
              children="Edit"
              to={`/panel/shipments/document/airwayBill/${item.id}/edit`}
            />
            <DropdownItem children="Delete" onClick={() => openDeleteModal(item.id)} />
          </DropdownItemGroup>
        </DropdownMenu>
      ),
    },
  ]
}
