import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getConsolidationNotes } from 'api/consolidations'

const useConsolidationNotes = (consolidationId) => {
  const queryClient = useQueryClient()

  const {
    data = [],
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ['consolidation_notes', consolidationId],
    queryFn: () => getConsolidationNotes(consolidationId),
    // retry: 2,
    // staleTime: Infinity,
  })

  const invalidate = () => {
    queryClient.invalidateQueries({ queryKey: ['consolidation_notes', consolidationId] })
  }

  const deleteNoteFromData = (deletedId) => {
    queryClient.setQueryData(['consolidation_notes', consolidationId], (notes) =>
      notes.filter((n) => n.id !== deletedId)
    )
  }

  const upsertNoteToData = (note) => {
    const isExist = data.some((n) => n.id === note.id)

    queryClient.setQueryData(['consolidation_notes', consolidationId], (notes) =>
      isExist ? notes.map((n) => (n.id === note.id ? note : n)) : [note, ...notes]
    )
  }

  return {
    isError,
    refetch,
    isLoading,
    notes: data,
    invalidate,
    upsertNoteToData,
    deleteNoteFromData,
  }
}

export default useConsolidationNotes
