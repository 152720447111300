import Button from '@atlaskit/button'
import Select from '@atlaskit/select'
import { Field, Form } from '@royan-co/user-interface'
import Modal, { ModalFooter, ModalTransition } from '@atlaskit/modal-dialog'

const SelectCreatorModal = ({ isOpenModal, closeModal, creators, selectedCreatorId, onChange }) => {
  const creatorsOptions = creators.map((c) => ({
    label: c.user.full_name,
    value: c.user.auto_id,
  }))

  const selected = creatorsOptions.find((o) => o.value === selectedCreatorId)

  const onSubmit = ({ creator }) => {
    closeModal()
    onChange?.(creator.value)
  }

  return (
    <ModalTransition>
      {isOpenModal && (
        <Modal onClose={closeModal} width="small">
          <Form onSubmit={onSubmit}>
            {() => (
              <>
                <div className="px-6 pt-4">
                  <Field label="Creator" name="creator" defaultValue={selected}>
                    {({ fieldProps }) => <Select options={creatorsOptions} {...fieldProps} />}
                  </Field>
                </div>
                <ModalFooter>
                  <Button appearance="subtle" spacing="compact" type="button" onClick={closeModal}>
                    Cancel
                  </Button>
                  <Button appearance="primary" spacing="compact" type="submit">
                    Apply
                  </Button>
                </ModalFooter>
              </>
            )}
          </Form>
        </Modal>
      )}
    </ModalTransition>
  )
}

export default SelectCreatorModal
