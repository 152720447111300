import React, { useEffect, useState } from 'react'

//Atlassian
import Modal, { ModalBody, ModalFooter, ModalTransition } from '@atlaskit/modal-dialog'
import Button from '@atlaskit/button/standard-button'
import Spinner from '@atlaskit/spinner'

const ShipmentEntranceImageModal = ({ isOpen, closeModal, image, isLoading }) => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setLoaded(false)
  }, [image])

  return (
    <div>
      <ModalTransition>
        {isOpen && (
          <Modal width={'x-large'} onClose={closeModal}>
            <ModalBody>
              {
                <>
                  <div
                    className="text-center py-5 my-5"
                    style={!loaded ? { display: 'block' } : { display: 'none' }}
                  >
                    <Spinner />
                  </div>
                  <div className="text-center">
                    <img
                      style={loaded ? { display: 'inline-block' } : { display: 'none' }}
                      onLoad={() => setLoaded(true)}
                      src={image}
                      alt="Entrance"
                      className="max-w-full object-contain"
                    />
                  </div>
                </>
              }
            </ModalBody>
            <ModalFooter>
              <Button appearance="subtle" onClick={closeModal}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </div>
  )
}

export default ShipmentEntranceImageModal
