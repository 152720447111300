import { useCallback } from 'react'
import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'

import { getShipmentsHubs } from 'api/shipments'
import { getFullAddress } from 'utils/helpers'

const useShipmentsHubs = ({ enabled = true } = {}) => {
  const { isLoading, data } = useQuery(['shipments', 'hubs'], getShipmentsHubs, {
    staleTime: Infinity,
    retry: 2,
    enabled,
  })

  const shipmentsHubsOptions = useMemo(() => {
    if (!data?.hubs) return []

    return data.hubs.map((u) => ({ label: u.name, value: u.id_auto }))
  }, [data])

  const getHubAddress = useCallback(
    (id_auto) => {
      const hub = data?.hubs?.find((h) => h.id_auto === id_auto)

      return getFullAddress(hub)
    },
    [data?.hubs]
  )

  return { shipmentsHubsOptions, isLoadingShipmentsHubs: isLoading, getHubAddress }
}

export default useShipmentsHubs
