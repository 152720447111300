//Hooks
import useConsolidation from 'hooks/useConsolidation'

//Components
import DenyFromUserLevel from 'layouts/DenyFromUserLevel'
import ConsolidationGeneralDetail from 'components/Consolidations/View/GeneralDetails'
import ConsolidationCarrierDetail from 'components/Consolidations/View/CarrierDetail'
import ConsolidationTrackingContactsDetail from 'components/Consolidations/View/TrackingContactsDetails'

const ConsolidationViewGeneralPage = () => {
  const { setLimitationExceptCreator } = useConsolidation()

  return (
    <>
      <ConsolidationGeneralDetail />

      <ConsolidationCarrierDetail />

      <DenyFromUserLevel level={setLimitationExceptCreator('warehouse')} fallback={null}>
        <ConsolidationTrackingContactsDetail />
      </DenyFromUserLevel>
    </>
  )
}

export default ConsolidationViewGeneralPage
