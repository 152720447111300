// Atlassian
import Select from '@atlaskit/select'

// Styles
import './InlineFilter.css'

const SelectStatusInlineFilter = ({ appliedFilters, applyFilter, options }) => {
  return (
    <div className="inlineFilterSelect">
      <Select
        isClearable
        options={options}
        spacing="compact"
        placeholder="Status"
        classNamePrefix="status-filter"
        onChange={(option) => applyFilter('status', option?.value)}
        defaultValue={options.find((o) => o.value === (appliedFilters?.status || null))}
      />
    </div>
  )
}

export default SelectStatusInlineFilter
