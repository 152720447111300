// Utils
import { transformSelectField } from 'utils/helpers'
import { handleErrorOnSubmit } from 'utils/errors'

// Hooks
import useInvoiceEnumerations from 'hooks/useInvoiceEnumerations'
import { useTakeInChargeInvoice } from 'features/invoice/api/takeInChargeInvoice'

// Atlassian
import Button, { LoadingButton } from '@atlaskit/button'
import Modal, {
  ModalHeader,
  ModalFooter,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import Select from '@atlaskit/select'
import { ErrorMessage } from '@atlaskit/form'

// Interfaces
import { Form, Field, WarningBannerField } from '@royan-co/user-interface'

const TakeInChargeModal = ({ isOpen, onClose, invoiceId }) => {
  const { bufferInvoiceDocCodesOptions, isLoadingInvoiceEnumerations } = useInvoiceEnumerations({
    enabled: isOpen,
  })

  const handleCloseModal = () => {
    onClose()
    takeInChargeInvoiceMutation.reset()
  }

  const takeInChargeInvoiceMutation = useTakeInChargeInvoice({ invoiceId })
  const handleSubmit = (data, { setError }) => {
    takeInChargeInvoiceMutation.mutate(data, {
      onSuccess: () => {
        handleCloseModal()
      },
      onError: (e) => {
        handleErrorOnSubmit(e, setError, data)
        console.error(e)
      },
    })
  }

  return (
    <>
      <ModalTransition>
        {isOpen && (
          <Modal width={'small'} onClose={handleCloseModal}>
            <Form onSubmit={handleSubmit}>
              {() => (
                <>
                  <ModalHeader>
                    <ModalTitle>Take in charge</ModalTitle>
                  </ModalHeader>

                  <div className="px-6">
                    <p>Please choose the document type first for the buffer invoice.</p>

                    <Field
                      isRequired
                      label="Document type"
                      type="select"
                      name="do"
                      transform={transformSelectField(bufferInvoiceDocCodesOptions)}
                      className="mt-4"
                    >
                      {({ fieldProps, error }) => (
                        <>
                          <Select
                            isClearable={true}
                            isLoading={isLoadingInvoiceEnumerations}
                            options={bufferInvoiceDocCodesOptions}
                            placeholder="Choose one"
                            {...fieldProps}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>

                    <WarningBannerField />
                  </div>

                  <ModalFooter>
                    <Button appearance="subtle" onClick={handleCloseModal}>
                      Cancel
                    </Button>
                    <LoadingButton
                      autoFocus
                      type="submit"
                      appearance="primary"
                      isLoading={takeInChargeInvoiceMutation.isLoading}
                    >
                      Submit
                    </LoadingButton>
                  </ModalFooter>
                </>
              )}
            </Form>
          </Modal>
        )}
      </ModalTransition>
    </>
  )
}

export default TakeInChargeModal
