import { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { formatNumber } from 'utils/numbers'

//Hooks
import useConsolidationCargo from 'hooks/useConsolidationCargo'

//Atlassian
import Lozenge from '@atlaskit/lozenge'
import { DynamicTableStateless } from '@atlaskit/dynamic-table'
import Button from '@atlaskit/button'
import Spinner from '@atlaskit/spinner'
import SectionMessage from '@atlaskit/section-message'
import EmptyState from '@atlaskit/empty-state'
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down'
import ChevronRightIcon from '@atlaskit/icon/glyph/chevron-right'

//Components
import Card from 'components/UI/Card'

const ConsolidationViewCargoPage = () => {
  const { consolidationId } = useParams()
  const [visibleDetails, setVisibleDetails] = useState([])

  const {
    consolidationCargo,
    isLoadingConsolidationCargo,
    isErrorConsolidationCargo,
    refetchConsolidationCargo,
  } = useConsolidationCargo(consolidationId)

  const packagesHead = {
    cells: [
      { key: 'type', content: '' },
      { key: 'quantity', content: 'Quantity' },
      { key: 'dimensions', content: 'Dimensions (W x L x H)' },
      { key: 'weight', content: 'Weight (Kgs)' },
      { key: 'ldm', content: 'LDM' },
      { key: 'cbm', content: 'CBM' },
      { key: 'Stackable', content: 'Stackable' },
      { key: 'Status', content: 'Status' },
    ],
  }

  const groupCargoes = useMemo(() => {
    if (consolidationCargo) {
      const _groupCargoes =
        Object.groupBy(consolidationCargo, ({ shipment_id }) => shipment_id) || []
      return Object.entries(_groupCargoes).map(([id, cargoes]) => ({
        number: cargoes.reduce((p, c) => p + c?.number * 1, 0),
        weight: cargoes.reduce((p, c) => p + c.weight * 1, 0),
        ldm: cargoes.reduce((p, c) => p + c.ldm * 1, 0),
        cbm: cargoes.reduce((p, c) => p + c.cbm * 1, 0),
        reference: cargoes[0]?.shipment?.reference,
        shipment_id: id,
        cargoes: cargoes,
      }))
    } else return []
  }, [consolidationCargo])

  const handleRowClick = useCallback(
    (rowId) => {
      const isVisible = visibleDetails.includes(rowId)
      const updatedVisibleDetails = isVisible
        ? visibleDetails.filter((id) => id !== rowId)
        : [...visibleDetails, rowId]
      setVisibleDetails(updatedVisibleDetails)
    },
    [visibleDetails]
  )

  const packages = useMemo(() => {
    let _packages = []

    groupCargoes.forEach((item) => {
      const isVisibleCargoes = visibleDetails.includes(item.shipment_id)

      _packages.push({
        isHighlighted: true,
        onClick: () => handleRowClick(item.shipment_id),
        cells: [
          {
            content: (
              <div className="flex items-center py-2">
                <button
                  children={isVisibleCargoes ? <ChevronDownIcon /> : <ChevronRightIcon />}
                  className="!px-1 text-neutral-200"
                  style={{ minWidth: '32px' }}
                />
                {item.reference ? (
                  <span className="whitespace-nowrap">{item.reference}</span>
                ) : (
                  <Lozenge>Not set</Lozenge>
                )}
              </div>
            ),
          },
          {
            content: item.number || 0,
          },
          {
            content: '',
          },
          {
            content: formatNumber(item.weight),
          },
          {
            content: item.ldm.toFixed(3),
          },
          {
            content: item.cbm.toFixed(3),
          },
          {
            content: '',
          },
          {
            content: '',
          },
        ],
      })

      if (isVisibleCargoes)
        item.cargoes.forEach((item, i) => {
          _packages.push({
            cells: [
              {
                id: `type${i}`,
                content: <div className="py-2">{item?.box_type}</div>,
              },
              {
                id: `quantity${i}`,
                content: item.number || <Lozenge appearance="default">Not set</Lozenge>,
              },
              {
                id: `dimensions${i}`,
                content: (
                  <div className="whitespace-nowrap">
                    {item.width || item.height || item.length ? (
                      <span>{`${formatNumber(item.width || '-')} cm x ${formatNumber(
                        item.length || '-'
                      )} cm x ${formatNumber(item.height || '-')} cm `}</span>
                    ) : (
                      <Lozenge appearance="default">Not set</Lozenge>
                    )}
                  </div>
                ),
              },
              {
                id: `weight${i}`,
                content: item.weight ? (
                  formatNumber(item.weight)
                ) : (
                  <Lozenge appearance="default">Not set</Lozenge>
                ),
              },
              {
                id: `ldm${i}`,
                content: item.ldm || <Lozenge appearance="default">Not set</Lozenge>,
              },
              {
                id: `cbm${i}`,
                content: item.cbm || <Lozenge appearance="default">Not set</Lozenge>,
              },
              {
                id: `stackable${i}`,
                content: item.stackable ? (
                  <Lozenge appearance="success">Yes</Lozenge>
                ) : (
                  <Lozenge appearance="moved">No</Lozenge>
                ),
              },
              {
                id: `status${i}`,
                content: !!item?.collies_deformity ? (
                  <Lozenge appearance="removed">Deformity</Lozenge>
                ) : !!item?.arrived ? (
                  <Lozenge appearance="success">Arrived to hub</Lozenge>
                ) : item?.shipment_pick_up_id === undefined ? (
                  <Lozenge appearance="moved">Under collection</Lozenge>
                ) : (
                  <Lozenge appearance="default">To be processed</Lozenge>
                ),
              },
            ],
          })
        })
    })

    _packages.push({
      cells: [
        {
          content: <strong>Total</strong>,
        },
        {
          content: consolidationCargo?.reduce((p, c) => p + c.number * 1, 0) || 0,
        },
        {
          content: '',
        },
        {
          content: formatNumber(
            consolidationCargo?.reduce((p, c) => p + c?.weight * 1, 0),
            3
          ),
        },
        {
          content: formatNumber(
            consolidationCargo?.reduce((p, c) => p + c?.ldm * 1, 0),
            3
          ),
        },
        {
          content: formatNumber(
            consolidationCargo?.reduce((p, c) => p + c?.cbm * 1, 0),
            3
          ),
        },
        {
          content: '',
        },
        {
          content: '',
        },
      ],
    })

    return _packages
  }, [consolidationCargo, groupCargoes, handleRowClick, visibleDetails])

  return (
    <>
      {isLoadingConsolidationCargo ? (
        <div className="text-center mt-24">
          <Spinner />
        </div>
      ) : isErrorConsolidationCargo ? (
        <SectionMessage
          appearance="warning"
          title="Something wrong on loading cargo details, please retry."
        >
          <Button onClick={() => refetchConsolidationCargo()}>Retry</Button>
        </SectionMessage>
      ) : (
        <Card>
          {consolidationCargo?.length < 1 ? (
            <EmptyState header="No cargo details" />
          ) : (
            <div className="overflow-auto inline-block w-full -mb-6 borderOfAtlaskitTableWithTotalRow dynamicTableHighlighted">
              <DynamicTableStateless
                head={packagesHead}
                rows={packages}
                isLoading={isLoadingConsolidationCargo}
              />
            </div>
          )}
        </Card>
      )}
    </>
  )
}

export default ConsolidationViewCargoPage
