import { useQuery } from '@tanstack/react-query'
import { getConsolidationCargo } from 'api/consolidations'

const useConsolidationCargo = (consolidationId) => {
  const {
    data: { cargo } = {},
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ['consolidation', 'cargo', consolidationId],
    queryFn: () => getConsolidationCargo(consolidationId),
    retry: 2,
    staleTime: Infinity,
  })

  return {
    consolidationCargo: cargo,
    isLoadingConsolidationCargo: isLoading,
    isErrorConsolidationCargo: isError,
    refetchConsolidationCargo: refetch,
  }
}

export default useConsolidationCargo
