import React from 'react'

// Contexts
import { useFinanceEditingContext } from 'contexts/FinanceEditingProvider'

// Interfaces
import { Form } from '@royan-co/user-interface'

const EditFinanceFormDetector = React.forwardRef(({ children }, ref) => {
  const { currentLeg, isEmpty } = useFinanceEditingContext()

  return isEmpty ? (
    children
  ) : (
    <Form defaultValues={currentLeg} ref={ref} key={currentLeg?._id}>
      {() => children}
    </Form>
  )
})

export default EditFinanceFormDetector
