export const marginCalculatorTypeOptions = [
  { label: 'Country', value: 0, appearance: 'inprogress' },
  { label: 'Area', value: 1, appearance: 'inprogress' },
]

export const areaTypeOptions = [
  { label: 'National', value: 0 },
  { label: 'EU', value: 1 },
  { label: 'EXC', value: 2 },
]

export const fixMinimumOptions = [
  { label: 'Yes', value: 1, appearance: 'success' },
  { label: 'No', value: 0, appearance: 'moved' },
]

export const mustGoOptions = [
  { label: 'Yes', value: 1, appearance: 'success' },
  { label: 'No', value: 0, appearance: 'moved' },
]
