import { useMutation, useQueryClient } from '@tanstack/react-query'

// Apis
import { createVehicleType, editVehicleType } from './vehicle-type-requests'

const useCreateVehicleType = (typeId) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data) => (typeId ? editVehicleType(typeId, data) : createVehicleType(data)),
    onSuccess: (res) => {
      queryClient.removeQueries({
        queryKey: ['vehicle-type', typeId],
      })
    },
    onError: (e) => {
      console.error(e)
    },
  })
}

export { useCreateVehicleType }
