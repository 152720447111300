import axios from 'services/Request'

export const getUsers = (params) => {
  return axios.get('/api/user/list', { params })
}

export function createUser(data) {
  return axios.post('/api/user', data)
}

export function editUser(userId, data) {
  return axios.post(`/api/user/edit/${userId}`, data)
}

export function getUser(userId) {
  return axios.get(`api/user/fetch/${userId}`)
}

export function deactivateUser(userId, data) {
  return axios.post(`api/user/access/${userId}`, data)
}
