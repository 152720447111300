export const quotationStatusOptions = [
  { label: 'Pending', value: 0, appearance: 'yellow' },
  { label: 'Quoted', value: 1, appearance: 'blue' },
  { label: 'Accepted', value: 2, appearance: 'green' },
  { label: 'Rejected', value: 3, appearance: 'red' },
  { label: 'Expired', value: 4, appearance: 'redLight' },
]

export const shipmentStatusOptions = [
  { group: 'Pre carriage', label: 'Order taken in charge', value: 0 },
  { group: 'Pre carriage', label: 'First contact done', value: 1 },
  { group: 'Pre carriage', label: 'Under collection', value: 2 },
  { group: 'Pre carriage', label: 'Collected', value: 3 },
  { group: 'Pre carriage', label: 'Arrived to hub', value: 4 },
  { group: 'Pre carriage', label: 'Under export custom clearance', value: 5 },
  { group: 'Pre carriage', label: 'Bill of lading issued', value: 6 },
  { group: 'Pre carriage', label: 'Truck planned', value: 7 },
  { group: 'Pre carriage', label: 'Loaded for final destination', value: 8 },
  { group: 'Pre carriage', label: 'On the road', value: 9 },

  { group: 'On carriage', label: 'Loading on ferry', value: 10 },
  { group: 'On carriage', label: 'On ferry', value: 11 },
  { group: 'On carriage', label: 'Unloading from ferry', value: 12 },
  { group: 'On carriage', label: 'Near/On the border', value: 13 },
  { group: 'On carriage', label: 'Waiting transit formalities', value: 14 },
  { group: 'On carriage', label: 'Waiting for issuing warehouse receipt', value: 15 },

  { group: 'Post carriage', label: 'Under import custom clearance', value: 16 },
  { group: 'Post carriage', label: 'Collect by consignee', value: 17 },
  { group: 'Post carriage', label: 'D.O. released', value: 18 },
  { group: 'Post carriage', label: 'D.O. sent to client', value: 19 },
  { group: 'Post carriage', label: 'Finance closed', value: 20 },
  { group: 'Post carriage', label: 'Trip finished', value: 21 },
]

export const shipmentClearanceOptions = [
  { label: 'Required', value: 1, appearance: 'moved' },
  { label: 'Not required', value: 0, appearance: 'default' },
]

export const modeOptions = [
  { label: 'Road Freight', value: 0 },
  { label: 'Sea Freight', value: 1 },
  { label: 'Air Freight', value: 2 },
  { label: 'Intermodal', value: 3 },
  { label: 'Express Courier', value: 4 },
]

export const shipmentTypeOptions = [
  { label: 'Master', value: 1, appearance: 'inprogress' },
  { label: 'House', value: 0, appearance: 'moved' },
]

export const shipmentInvoicedOptions = [
  { label: 'Empty', value: 2, appearance: 'moved' },
  { label: 'Not invoiced', value: 0, appearance: 'removed' },
  { label: 'Invoiced', value: 1, appearance: 'success' },
]

export const shipmentQuotationSentOptions = [
  { label: 'Sent', value: 1, appearance: 'success' },
  { label: 'Not sent', value: 0, appearance: 'moved' },
]

export const shipmentCustomDocumentOptions = [
  { label: 'T1', value: 0 },
  { label: 'EX1', value: 1 },
  { label: 'Import custom declaration', value: 2 },
  { label: 'Not set', value: 'null' },
]

export const shipmentTemperatureOptions = [
  { label: 'Yes', value: 1, appearance: 'moved' },
  { label: 'No', value: 0, appearance: 'default' },
]

export const shipmentHubEnteredOptions = [
  { label: 'Yes', value: 1, appearance: 'success' },
  { label: 'No', value: 0, appearance: 'removed' },
]

export const dangerousCargoOptions = [
  { label: 'Yes', value: 1 },
  { label: 'No', value: 0 },
]

export const shipmentDOOptions = [
  { label: 'Hold', value: 0, appearance: 'removed' },
  { label: 'Release', value: 1, appearance: 'success' },
]

export const shipmentOwnershipOptions = [
  { label: 'Owned', value: 0 },
  { label: 'Shared', value: 1 },
]

export const truckTypes = [
  { label: 'FTL', value: 1 },
  { label: 'LTL', value: 0 },
]

export const containerTypes = [
  { label: 'FCL', value: 1 },
  { label: 'LCL', value: 0 },
]

export const adrPackagingGroupOptions = [
  { label: '|', value: '|' },
  { label: '||', value: '||' },
  { label: '|||', value: '|||' },
]

export const shipmentEntranceImageOptions = [
  { label: 'Yes', value: 1 },
  { label: 'No', value: 0 },
]

export const vgmTypesOptions = [
  { label: 'Weighed', value: 'weighed' },
  { label: 'Calculated', value: 'calculated' },
]

export const vgmIssuersOptions = [
  { label: 'Shipper', value: 'shipper' },
  { label: 'Carrier', value: 'carrier' },
]
