import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

// API
import { getConsolidationAirwaybills } from 'api/consolidations'

// Utils
import { formatNumber } from 'utils/numbers'

// Atlassian
import { DynamicTableStateless } from '@atlaskit/dynamic-table'
import EmptyState from '@atlaskit/empty-state'
import Button from '@atlaskit/button'
import Lozenge from '@atlaskit/lozenge'

// Components
import Card from 'components/UI/Card'

const airwaybillsHead = {
  cells: [
    { content: 'Flight' },
    { content: 'Waybill' },
    { content: 'Airline' },
    { content: 'Weight' },
    { content: 'Pieces' },
    { content: 'Arrival airport' },
    { content: 'Actions' },
  ],
}

const ConsolidationViewAirwaybillsPage = () => {
  const { consolidationId } = useParams()

  const {
    data: consolidationAirwaybills = [],
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useQuery({
    queryKey: ['consolidation', 'awb', consolidationId],
    queryFn: () => getConsolidationAirwaybills(consolidationId),
    retry: 2,
    staleTime: Infinity,
  })

  const airwaybills = useMemo(() => {
    const body = consolidationAirwaybills?.map((item, i) => {
      return {
        key: `awbRow${i}`,
        cells: [
          {
            id: `flight${i}`,
            content: item?.flight,
          },
          {
            id: `waybill${i}`,
            content: (
              <>
                <span className="block">{item?.full_waybill}</span>
                {item?.dgr ? <Lozenge appearance="removed">DGR</Lozenge> : ''}
                {item?.reefer ? <Lozenge appearance="inprogress">REEFER</Lozenge> : ''}
              </>
            ),
          },
          {
            id: `airline${i}`,
            content: item?.airline,
          },
          {
            id: `weight${i}`,
            content: item?.weight,
          },
          {
            id: `pieces${i}`,
            content: item?.collies_number,
          },
          {
            id: `arrival_airport${i}`,
            content: item?.arrival_airport,
          },
          {
            id: `actions${i}`,
            content: (
              <>
                <Button
                  appearance="primary"
                  onClick={() => window.open(`/panel/shipments/document/airwayBill/${item?.id}`)}
                >
                  View
                </Button>
              </>
            ),
          },
        ],
      }
    })

    let totalRow = [
      {
        content: <strong>Total</strong>,
      },
      ...new Array(2).fill({
        content: '',
      }),
      {
        content: formatNumber(consolidationAirwaybills?.reduce((p, c) => p + c.weight * 1, 0) || 0),
      },
      {
        content: formatNumber(
          consolidationAirwaybills?.reduce((p, c) => p + c.collies_number * 1, 0) || 0
        ),
      },
      ...new Array(2).fill({
        content: '',
      }),
    ]

    body.push({
      key: `airwaybills-total`,
      cells: totalRow,
    })

    return body
  }, [consolidationAirwaybills])

  return (
    <>
      <Card className="mt-4">
        {consolidationAirwaybills?.length === 0 ? (
          <EmptyState header="No air waybills" />
        ) : (
          <div className="overflow-auto inline-block w-full -mb-6 borderOfAtlaskitTableWithTotalRow">
            <DynamicTableStateless head={airwaybillsHead} rows={airwaybills} isLoading={false} />
          </div>
        )}
      </Card>
    </>
  )
}

export default ConsolidationViewAirwaybillsPage
