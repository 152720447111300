import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getShipmentNotes } from 'api/shipments'

const useShipmentNotes = (id) => {
  const queryClient = useQueryClient()

  const {
    data = [],
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ['notes', id],
    queryFn: () => getShipmentNotes(id),
    // retry: 2,
    // staleTime: Infinity,
  })

  const invalidate = () => {
    queryClient.invalidateQueries({ queryKey: ['notes', id] })
  }

  const deleteNoteFromData = (deletedId) => {
    queryClient.setQueryData(['notes', id], (notes) => notes.filter((n) => n.id !== deletedId))
  }

  const upsertNoteToData = (note) => {
    const isExist = data.some((n) => n.id === note.id)

    queryClient.setQueryData(['notes', id], (notes) =>
      isExist ? notes.map((n) => (n.id === note.id ? note : n)) : [note, ...notes]
    )
  }

  return {
    isError,
    refetch,
    isLoading,
    notes: data,
    invalidate,
    upsertNoteToData,
    deleteNoteFromData,
  }
}

export default useShipmentNotes
