import { useMemo, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'

import useShipment from 'hooks/useShipment'

//Atlassian
import Button from '@atlaskit/button'
import Lozenge from '@atlaskit/lozenge'
import Modal, { ModalHeader, ModalBody, ModalFooter, ModalTransition } from '@atlaskit/modal-dialog'

//Interfaces
import { Grid, InfoIcon } from '@royan-co/user-interface'
import { ShortcutIcon } from '@royan-co/user-interface'

//Components
import RowTable from 'components/UI/RowTable'
import ShipmentAddressShow from '../AddressShow'
import ShipmentContactShow from '../ContactShow'
import Card, { CardSubtitle } from 'components/UI/Card'

const CardModal = ({ isOpen, onClose, title, rows }) => {
  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={onClose} width="small">
          <ModalHeader>
            <h3 className="font-medium">{title}</h3>
          </ModalHeader>

          <ModalBody>
            <RowTable rows={rows} verticalAlign="align-top" width="w-1/3" />
          </ModalBody>

          <ModalFooter>
            <Button appearance="subtle" spacing="compact" type="button" onClick={onClose}>
              Discard
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  )
}

const ShipmentConsigneeDetail = ({ className }) => {
  const [openNotifyModal, setOpenNotifyModal] = useState(false)
  const [openAgentModal, setOpenAgentModal] = useState(false)

  const isMobile = useMediaQuery({ query: `(max-width: 991.98px)` })

  const { shipment } = useShipment()

  const generalRows = useMemo(() => {
    let rowsItems = [
      {
        title: 'Name',
        content: shipment?.consignee?.id_auto ? (
          <Link to={`/panel/registries/${shipment.consignee.id_auto}`} target="_blank">
            {shipment.consignee.company_name} <ShortcutIcon size={16} />
          </Link>
        ) : (
          <Lozenge appearance="default">Not set</Lozenge>
        ),
      },
      {
        title: 'Address',
        content: <ShipmentAddressShow address={shipment?.consignee_address} />,
      },
      {
        title: 'Contact',
        content: <ShipmentContactShow contact={shipment?.consignee_contact} />,
      },
      {
        title: 'Notify',
        content:
          shipment.notify?.company_name && shipment.notify_id === shipment.consignee_id ? (
            <Lozenge appearance="default">Same as consignee</Lozenge>
          ) : shipment.notify?.company_name ? (
            <button
              className="flex justify-start text-start"
              onClick={() => setOpenNotifyModal(true)}
            >
              <small className="mr-2">{shipment.notify.company_name}</small>
              <InfoIcon size={16} className="text-neutral-500" />
            </button>
          ) : (
            <Lozenge appearance="default">Not set</Lozenge>
          ),
      },
      {
        title: 'Receiving agent',
        content: shipment?.receiving_agent?.company_name ? (
          <button className="flex justify-start text-start" onClick={() => setOpenAgentModal(true)}>
            <small className="mr-2">{shipment.receiving_agent.company_name}</small>
            <InfoIcon size={16} className="text-neutral-500" />
          </button>
        ) : (
          <Lozenge appearance="default">Not set</Lozenge>
        ),
      },
    ]

    return rowsItems
  }, [shipment])

  const secondConsigneeRows = useMemo(() => {
    let rowsItems = [
      {
        title: 'Name',
        content: shipment?.second_consignee?.id_auto ? (
          <Link to={`/panel/registries/${shipment.second_consignee.id_auto}`} target="_blank">
            {shipment.second_consignee.company_name} <ShortcutIcon size={16} />
          </Link>
        ) : (
          <Lozenge appearance="default">Not set</Lozenge>
        ),
      },
      {
        title: 'Address',
        content: <ShipmentAddressShow address={shipment?.second_consignee_address} />,
      },
      {
        title: 'Contact',
        content: <ShipmentContactShow contact={shipment?.ssecond_consignee_contact} />,
      },
    ]

    return rowsItems
  }, [shipment])

  const deliveryRows = useMemo(() => {
    let deliveryRowsItems = []

    if (shipment.delivery_id === shipment.consignee_id) {
      deliveryRowsItems.push({
        title: 'Address',
        content: <Lozenge appearance="default">Same as consignee</Lozenge>,
        key: 'AddressLoading',
      })
    } else {
      deliveryRowsItems.push(
        {
          title: 'Delivery to',
          content: (
            <Link to={`/panel/registries/${shipment.delivery_to.id_auto}`} target="_blank">
              {shipment.delivery_to.company_name} <ShortcutIcon size={16} />
            </Link>
          ),
        },
        {
          title: 'Address',
          content: <ShipmentAddressShow address={shipment?.delivery_address} />,
        },
        {
          title: 'Contact',
          content: <ShipmentContactShow contact={shipment?.delivery_contact} />,
        }
      )
    }

    deliveryRowsItems.push({
      title: 'Date',
      content: shipment.delivery_date || <Lozenge appearance="default">Not set</Lozenge>,
    })

    return deliveryRowsItems
  }, [shipment])

  const notifyModalRows = useMemo(() => {
    if (!shipment.notify?.id_auto) return []

    return [
      {
        title: 'Name',
        content: (
          <>
            {shipment.notify.company_name && (
              <Link to={`/panel/registries/${shipment.notify.id_auto}`} target="_blank">
                {shipment.notify.company_name} <ShortcutIcon size={16} />
              </Link>
            )}
          </>
        ),
      },
      {
        title: 'Address',
        content: <ShipmentAddressShow address={shipment?.notify_address} />,
      },
      {
        title: 'Contact',
        content: <ShipmentContactShow contact={shipment?.notify_contact} />,
      },
    ]
  }, [shipment])

  const agentModalRows = useMemo(() => {
    if (!shipment.receiving_agent?.id_auto) return []

    return [
      {
        title: 'Receiving agent',
        content: (
          <Link to={`/panel/registries/${shipment.receiving_agent.id_auto}`} target="_blank">
            {shipment.receiving_agent.company_name} <ShortcutIcon size={16} />
          </Link>
        ),
      },
      {
        title: 'Address',
        content: <ShipmentAddressShow address={shipment?.receiving_agent_address} />,
      },
      {
        title: 'Contact',
        content: <ShipmentContactShow contact={shipment?.receiving_agent_contact} />,
      },
    ]
  }, [shipment])

  return (
    <>
      <Card title="Consignee">
        <Grid className="w-full" gutterMd="lg">
          <Grid.Col lg={6}>
            <RowTable
              rows={generalRows}
              verticalAlign="align-top"
              width={isMobile ? 'w-1/2' : 'w-1/3'}
            />

            {!!shipment.second_consignee?.id_auto && (
              <>
                <CardSubtitle withLine={true} children="Second consignee" />
                <RowTable
                  rows={secondConsigneeRows}
                  verticalAlign="align-top"
                  width={isMobile ? 'w-1/2' : 'w-1/3'}
                />
              </>
            )}
          </Grid.Col>

          <Grid.Col lg={6}>
            <CardSubtitle>Delivery information</CardSubtitle>

            {shipment.delivery_id ? (
              <RowTable
                rows={deliveryRows}
                verticalAlign="align-top"
                width={isMobile ? 'w-1/2' : 'w-1/3'}
              />
            ) : (
              <Lozenge appearance="moved">NOT PROVIDED</Lozenge>
            )}
          </Grid.Col>
        </Grid>
      </Card>

      <CardModal
        title="Notify details"
        isOpen={openNotifyModal}
        onClose={() => setOpenNotifyModal(false)}
        rows={notifyModalRows}
      />

      <CardModal
        title="Receiving agent details"
        isOpen={openAgentModal}
        onClose={() => setOpenAgentModal(false)}
        rows={agentModalRows}
      />
    </>
  )
}

export default ShipmentConsigneeDetail
