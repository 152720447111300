import { useQuery } from '@tanstack/react-query'

// Apis
import { getStatistics } from './dashboard-requests'

/**
 * get statistics
 * @param {object} params
 * @param {import('@tanstack/react-query').UseQueryOptions} options
 * @returns {import('@tanstack/react-query').UseQueryResult}
 */
export const useStatistics = ({ period, ...params }, options = {}) => {
  return useQuery({
    queryKey: ['statistics', period, params],
    queryFn: () => getStatistics(period, params),
    select: (res) => res?.stats,
    staleTime: Infinity,
    ...options,
  })
}
