import { useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { getFilled, transformSelectField } from 'utils/helpers'
import { getEntranceDefaultValue } from 'api/shipments'

//Services
import { createShipmentEntrance } from 'api/shipments'
import useShipment from 'hooks/useShipment'
import useRegistryAirlines from 'hooks/useRegistryAirlines'
import useShipmentsEnumerations from 'hooks/useShipmentsEnumerations'

//Atlassian
import Select from '@atlaskit/select'
import Lozenge from '@atlaskit/lozenge'
import Spinner from '@atlaskit/spinner'
import TextField from '@atlaskit/textfield'
import { ErrorMessage } from '@atlaskit/form'
import PageHeader from '@atlaskit/page-header'
import SectionMessage from '@atlaskit/section-message'
import Button, { LoadingButton } from '@atlaskit/button'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'

//Interfaces
import { Field, Form, Grid, useFlags, WarningBannerField } from '@royan-co/user-interface'

//Components
import Card from 'components/UI/Card'
import RowTable from 'components/UI/RowTable'
import CountryFlag from 'components/UI/CountryFlag'
import { handleErrorOnSubmit } from 'utils/errors'
import DenyFromUserLevel from 'layouts/DenyFromUserLevel'
import { useAuth } from 'contexts/AuthProvider'
import ShipmentsViewEntranceStatus from 'components/Shipments/View/EntranceStatus'

const ShipmentViewEntranceCreationPage = () => {
  const navigate = useNavigate()
  const { showSuccessFlag } = useFlags()
  const { shipmentId, entranceId } = useParams()
  const { isWarehouseUser } = useAuth()

  // TODO: Load entrance on edit mode
  const entrance = useMemo(() => ({}), [])
  const { refetch, shipment, isError, isLoading } = useShipment(shipmentId)
  const { registryAirlineOptions, isLoadingRegistryAirlines } = useRegistryAirlines()
  const { getUserHubOptions, isLoadingShipmentEnumeration } = useShipmentsEnumerations()

  const entranceDefaultValueQuery = useQuery({
    queryKey: ['entrance-default-value', shipmentId],
    queryFn: () => getEntranceDefaultValue(shipmentId),
    enabled: shipment?.shipment_type === 2,
  })

  const isLoadingInitialData = useMemo(() => {
    return shipment?.shipment_type === 2 && entranceDefaultValueQuery.isLoading
  }, [entranceDefaultValueQuery.isLoading, shipment?.shipment_type])

  const entranceMutation = useMutation(createShipmentEntrance.bind(null, shipmentId))

  const pageTitle = entranceId ? 'Edit entrance' : 'New entrance'

  const submit = (data, { setError }) => {
    let formData = { ...data }

    if (formData.cargo) {
      formData.cargo = formData.cargo.map((c) => ({
        ...c,
        damage_files: c.damage_files.map((f) => f.uploadResponse?.path),
      }))
    }

    if (isWarehouseUser) {
      formData = { ...formData, hub_riferiment: getUserHubOptions()?.[0]?.value }
    }

    entranceMutation.mutate(formData, {
      onError: (e) => {
        handleErrorOnSubmit(e, setError, formData)
      },
      onSuccess: (res) => {
        showSuccessFlag(res.message)
        navigate(`/panel/shipments/${shipmentId}/entrances`, { replace: true })
      },
    })
  }

  const defaultValues = useMemo(
    () => ({
      airline: entranceId
        ? getFilled(entrance, 'airline', null)
        : getFilled(entranceDefaultValueQuery.data?.defaults, 'carrier', null),

      waybill: entranceId
        ? getFilled(entrance, 'waybill', '')
        : getFilled(entranceDefaultValueQuery.data?.defaults, 'waybill', ''),

      hub_riferiment: getFilled(entrance, 'hub_riferiment', null),
      microzone: getFilled(entrance, 'microzone', ''),
    }),
    [entrance, entranceDefaultValueQuery.data, entranceId]
  )

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <Grid className="w-full">
        <Grid.Col md={8} xl={6} offsetMd={2} offsetXl={3}>
          <PageHeader
            breadcrumbs={
              <Breadcrumbs>
                <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
                <BreadcrumbsItem text="Shipments" to="/panel/shipments" component={Link} />
                <BreadcrumbsItem text="View Shipment" />
                <BreadcrumbsItem
                  text={isLoading ? <Spinner size="xsmall" /> : shipment?.shipment_code}
                  to={`/panel/shipments/${shipmentId}`}
                  component={Link}
                />
              </Breadcrumbs>
            }
          >
            {pageTitle}
          </PageHeader>

          {isLoading ? (
            <div className="text-center mt-24">
              <Spinner />
            </div>
          ) : isError ? (
            <SectionMessage
              appearance="warning"
              title="Something wrong on loading data, please retry."
            >
              <Button onClick={() => refetch()}>Retry</Button>
            </SectionMessage>
          ) : (
            <>
              <Card>
                <RowTable
                  rows={[
                    {
                      title: 'Shipment reference',
                      content: shipment.shipment_code,
                    },
                    {
                      title: 'Destination',
                      content: <CountryFlag iso={shipment.arrival_country} />,
                    },
                    {
                      title: 'Origin',
                      content: <CountryFlag iso={shipment.departure_country} />,
                    },
                    {
                      title: 'Shipper',
                      content: shipment?.shipper?.company_name ? (
                        shipment.shipper.company_name
                      ) : (
                        <Lozenge appearance="default">Not set</Lozenge>
                      ),
                    },
                    {
                      title: 'Consignee',
                      content: shipment?.consignee?.company_name ? (
                        shipment.consignee.company_name
                      ) : (
                        <Lozenge appearance="default">Not set</Lozenge>
                      ),
                    },
                    {
                      title: 'Notify',
                      content:
                        shipment.notify?.company_name &&
                        shipment.notify_id === shipment.consignee_id ? (
                          <Lozenge appearance="default">Same as consignee</Lozenge>
                        ) : shipment.notify?.company_name ? (
                          shipment.notify.company_name
                        ) : (
                          <Lozenge appearance="default">Not set</Lozenge>
                        ),
                    },
                  ]}
                />
              </Card>

              {isLoadingInitialData ? (
                <div className="text-center">
                  <Spinner />
                </div>
              ) : (
                <Form onSubmit={submit} defaultValues={defaultValues}>
                  {() => (
                    <>
                      <Card className="mb-0">
                        {shipment?.shipment_type === 2 && (
                          <>
                            <Field
                              type="select"
                              name="airline"
                              label="Carrier"
                              className="!mt-0"
                              transform={transformSelectField(registryAirlineOptions)}
                            >
                              {({ fieldProps, error }) => (
                                <>
                                  <Select
                                    options={registryAirlineOptions}
                                    isLoading={isLoadingRegistryAirlines}
                                    {...fieldProps}
                                  />
                                  {error && <ErrorMessage>{error}</ErrorMessage>}
                                </>
                              )}
                            </Field>

                            <Field name="waybill" label="Waybill no." className="!mt-4">
                              {({ fieldProps, error }) => (
                                <>
                                  <TextField {...fieldProps} />
                                  {error && <ErrorMessage>{error}</ErrorMessage>}
                                </>
                              )}
                            </Field>
                          </>
                        )}

                        <Grid grow>
                          <DenyFromUserLevel level="warehouse" fallback={null}>
                            <Grid.Col sm={6}>
                              <Field
                                isRequired
                                label="Hub"
                                type="select"
                                className="!mt-4"
                                name="hub_riferiment"
                                transform={transformSelectField(getUserHubOptions())}
                              >
                                {({ fieldProps, error }) => (
                                  <>
                                    <Select
                                      menuPlacement="auto"
                                      options={getUserHubOptions()}
                                      isLoading={isLoadingShipmentEnumeration}
                                      {...fieldProps}
                                    />
                                    {error && <ErrorMessage>{error}</ErrorMessage>}
                                  </>
                                )}
                              </Field>
                            </Grid.Col>
                          </DenyFromUserLevel>

                          <Grid.Col sm={6}>
                            <Field name="microzone" label="Warehouse reference" className="!mt-4">
                              {({ fieldProps, error }) => (
                                <>
                                  <TextField {...fieldProps} />
                                  {error && <ErrorMessage>{error}</ErrorMessage>}
                                </>
                              )}
                            </Field>
                          </Grid.Col>
                        </Grid>
                      </Card>

                      {shipment?.type_of_collies === 0 && <ShipmentsViewEntranceStatus />}

                      <WarningBannerField />

                      <div className="text-right mt-6">
                        <LoadingButton
                          type="submit"
                          appearance="primary"
                          isLoading={entranceMutation.isLoading}
                        >
                          {entranceId ? 'Update' : 'Create'} entrance
                        </LoadingButton>
                      </div>
                    </>
                  )}
                </Form>
              )}
            </>
          )}
        </Grid.Col>
      </Grid>
    </>
  )
}

export default ShipmentViewEntranceCreationPage
