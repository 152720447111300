import { findOption } from 'utils/helpers'
import { shipmentHubEnteredOptions } from './constants'

//Atlassian
import Lozenge from '@atlaskit/lozenge'

const ShipmentHubTag = ({ value }) => {
  const option = findOption(shipmentHubEnteredOptions, value)
  return option ? <Lozenge appearance={option.appearance}>{option.label}</Lozenge> : null
}

export default ShipmentHubTag
