import { useCallback, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'

import { getShipmentEnumerations } from 'api/shipments'

//Contexts
import { useAuth } from 'contexts/AuthProvider'

const useShipmentsEnumerations = ({ enabled = true } = {}) => {
  const { currentUser, isWarehouseUser } = useAuth()
  const { isLoading, data } = useQuery(['enumerations', 'shipment'], getShipmentEnumerations, {
    staleTime: Infinity,
    retry: 2,
    enabled,
  })

  const getEnumerationByKey = useCallback(
    (key, defaultValue = {}) => {
      return data?.[key] || defaultValue
    },
    [data]
  )

  const getEnumerationOptionsByKey = useCallback(
    (key) => {
      return Object.entries(getEnumerationByKey(key))?.map(([value, label]) => ({
        value,
        label,
      }))
    },
    [getEnumerationByKey]
  )

  const getSelectOptionsByKey = useCallback(
    (key) => {
      return getEnumerationByKey(key, []).map((i) => ({ value: i.id_auto, label: i.name }))
    },
    [getEnumerationByKey]
  )

  const getUserHubOptions = useCallback(() => {
    return getEnumerationByKey('hubs', [])
      .filter((i) => (isWarehouseUser ? i.registry_id === currentUser.registry_id : true))
      .map((i) => ({ value: i.id_auto, label: i.name }))
  }, [currentUser, getEnumerationByKey, isWarehouseUser])

  const getServiceCodeOptions = useMemo(() => {
    return getEnumerationByKey('service_codes', []).map((i) => ({
      value: i.code,
      label: [i.code, i.description].filter((i) => i).join(' - '),
    }))
  }, [getEnumerationByKey])

  return {
    getSelectOptionsByKey,
    getUserHubOptions,
    getEnumerationByKey,
    getEnumerationOptionsByKey,
    getServiceCodeOptions,
    isLoadingShipmentEnumeration: isLoading,
  }
}

export default useShipmentsEnumerations
