import { Link, useParams } from 'react-router-dom'

//Hooks
import useShipment from 'hooks/useShipment'

//Atlassian
import Button from '@atlaskit/button'
import DenyFromUserLevel from 'layouts/DenyFromUserLevel'

const ShipmentViewGeneralInformationActions = ({ isShipment }) => {
  const { quotationId } = useParams()
  const { setLimitationExceptCreator } = useShipment()

  return (
    <>
      <DenyFromUserLevel level={setLimitationExceptCreator('warehouse')} fallback={null}>
        <Button
          appearance="primary"
          component={Link}
          to={isShipment ? 'edit-registries' : `/panel/quotations/${quotationId}/edit`}
        >
          {isShipment ? 'Edit registries' : 'Edit'}
        </Button>
      </DenyFromUserLevel>
    </>
  )
}

export default ShipmentViewGeneralInformationActions
