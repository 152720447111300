import Lozenge from '@atlaskit/lozenge'

const RegistryFreightForwarderTag = ({ value }) => {
  return value ? (
    <Lozenge appearance="success">Yes</Lozenge>
  ) : (
    <Lozenge appearance="moved">No</Lozenge>
  )
}

export default RegistryFreightForwarderTag
