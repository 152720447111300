// Styles
import styles from './TabButtons.module.scss'

const TabButtons = ({ selectedTab, onChange }) => {
  const onClickTabButton = (key) => {
    onChange(key)
  }

  return (
    <div className={styles.buttonsWrapper}>
      {[
        {
          label: 'Today',
          key: 'day',
        },
        {
          label: 'This week',
          key: 'week',
        },
        {
          label: 'This month',
          key: 'month',
        },
        {
          label: 'This year',
          key: 'year',
        },
        {
          label: 'Custom',
          key: 'custom',
        },
      ].map(({ label, key }) => (
        <button
          key={key}
          className={`${styles.buttonsWrapper__tab} ${selectedTab === key ? styles.selected : ''}`}
          onClick={() => onClickTabButton(key)}
        >
          {label}
        </button>
      ))}
    </div>
  )
}

export default TabButtons
