import { Link, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

//Api
import { getCurrencies } from '../api/currencies-requests'

//Atlassian
import PageHeader from '@atlaskit/page-header'
import Button, { ButtonGroup } from '@atlaskit/button'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'

//Interfaces
import { RoyanTable, useDisclosure } from '@royan-co/user-interface'

//Components
import { generateCurrenciesColumns } from '../components/List/columns'
import CurrenciesDeleteModal from '../components/DeleteModal'

const pageTitle = 'Currencies'

const CurrencyListPage = () => {
  const navigate = useNavigate()

  const [isOpenDeleteModal, deleteModalHandler] = useDisclosure('delete')

  const openDeleteModal = (currencyCode) => {
    deleteModalHandler.open(currencyCode)
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <PageHeader
        breadcrumbs={
          <Breadcrumbs>
            <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
            <BreadcrumbsItem text="Accounting" />
          </Breadcrumbs>
        }
        actions={
          <ButtonGroup>
            <Button appearance="primary" onClick={() => navigate('/panel/currencies/create')}>
              New currency
            </Button>
          </ButtonGroup>
        }
      >
        {pageTitle}
      </PageHeader>

      <RoyanTable
        sortOrder="DESC"
        name="currencies-list"
        sortKey="code"
        request={getCurrencies}
        filters={[]}
        columns={generateCurrenciesColumns(openDeleteModal)}
      >
        <CurrenciesDeleteModal
          isOpen={isOpenDeleteModal}
          onClose={deleteModalHandler.close}
          currencyCode={deleteModalHandler.data}
        />
      </RoyanTable>
    </>
  )
}

export default CurrencyListPage
