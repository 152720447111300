import { Link } from 'react-router-dom'

// Interfaces
import { ShortcutIcon } from '@royan-co/user-interface'

// Components
import ShipmentModeTag from 'components/Shipments/ModeTag'
import ShipmentStatusTag from 'components/Shipments/StatusTag'
import CountryFlag from 'components/UI/CountryFlag'

export const RegistryShipmentColumns = [
  {
    key: 'shipment_code',
    title: 'Reference',
    isSortable: false,
    show: true,
    content: (item) => (
      <Link to={`/panel/shipments/${item.id_auto}`} target="_blank">
        <span className="whitespace-nowrap">
          {item.shipment_code} <ShortcutIcon size={16} />
        </span>
      </Link>
    ),
  },
  {
    key: 'shipment_type',
    title: 'Mode',
    isSortable: false,
    show: true,
    content: (item) => <ShipmentModeTag item={item} />,
  },
  {
    key: 'status',
    title: 'Status',
    isSortable: false,
    show: true,
    content: (item) => <ShipmentStatusTag value={item.status} />,
  },
  {
    key: 'departure_country',
    title: 'Country of loading',
    isSortable: false,
    show: true,
    content: (item) => <CountryFlag iso={item.departure_country} />,
  },
  {
    key: 'arrival_country',
    title: 'Country of delivery',
    isSortable: false,
    show: true,
    content: (item) => <CountryFlag iso={item.arrival_country} />,
  },
  {
    key: 'creation_date',
    title: 'Creation date',
    isSortable: true,
    show: true,
    content: (item) => item.creation_date,
  },
]
