import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

//Apis
import { deleteRegistryAddress } from 'api/registries'

//Hooks
import useRegistry from 'hooks/useRegistry'

//Atlassian
import Modal, {
  ModalBody,
  ModalTitle,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import Button, { LoadingButton } from '@atlaskit/button'

//Interfaces
import { useFlags, WarningBanner } from '@royan-co/user-interface'

const RegistryDeleteBranchModal = ({ branch, isOpen, onClose }) => {
  const { showSuccessFlag } = useFlags()
  const { registryId } = useParams()
  const { deleteBranchFromData } = useRegistry(registryId)
  const [error, setError] = useState('')

  const deleteRegistryAddressMutation = useMutation(deleteRegistryAddress)

  const handleDeleteAddress = () => {
    setError('')

    deleteRegistryAddressMutation.mutate(branch.id_auto, {
      onError: (e) => {
        setError(e?.response?.data?.message || 'Something wrong on remove branch.')
        console.error(e)
      },
      onSuccess: (res) => {
        showSuccessFlag(res.message)
        deleteBranchFromData(branch.id_auto)
        onClose()
      },
    })
  }

  const closeModal = () => {
    deleteRegistryAddressMutation.reset()
    setError('')
    onClose()
  }

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={closeModal}>
          <ModalHeader>
            <ModalTitle appearance="danger">Delete branch</ModalTitle>
          </ModalHeader>

          <ModalBody>
            You are about to delete a branch. Are you sure?
            {deleteRegistryAddressMutation.isError && <WarningBanner text={error} />}
          </ModalBody>

          <ModalFooter>
            <Button appearance="subtle" onClick={closeModal}>
              Cancel
            </Button>
            <LoadingButton
              isLoading={deleteRegistryAddressMutation.isLoading}
              appearance="danger"
              onClick={handleDeleteAddress}
              autoFocus
            >
              Delete
            </LoadingButton>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  )
}

export default RegistryDeleteBranchModal
