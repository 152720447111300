// Interfaces
import { DotsIcon } from '@royan-co/user-interface'

// Atlassian
import Lozenge from '@atlaskit/lozenge'
import Button from '@atlaskit/button'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'

// Components
import DropdownLink from 'components/UI/DropdownLink'

export const generateAirWaybillStocksColumns = (openDeleteModal) => {
  return [
    {
      key: 'airline',
      title: 'Airline',
      isSortable: false,
      show: true,
      content: (item) => item?.airline || <Lozenge>Not set</Lozenge>,
    },
    {
      key: 'from',
      title: 'From',
      isSortable: false,
      show: true,
      content: (item) => item.from || <Lozenge>Not set</Lozenge>,
    },
    {
      key: 'to',
      title: 'To',
      isSortable: false,
      show: true,
      content: (item) => item.to || <Lozenge>Not set</Lozenge>,
    },
    {
      key: 'used',
      title: 'Used',
      isSortable: false,
      show: true,
      content: (item) => <Lozenge appearance="moved">{item.used}</Lozenge>,
    },
    {
      key: 'free',
      title: 'Free',
      isSortable: false,
      show: true,
      content: (item) =>
        item.waybills_count - item.used === 0 ? (
          <Lozenge appearance="removed">Stock finished</Lozenge>
        ) : (
          <Lozenge appearance="success">{item.waybills_count - item.used}</Lozenge>
        ),
    },
    {
      key: 'start_date',
      title: 'Start date',
      isSortable: true,
      show: true,
      content: (item) => item?.start_date,
    },
    {
      key: 'end_date',
      title: 'End date',
      isSortable: true,
      show: true,
      content: (item) => item?.end_date,
    },
    {
      key: 'creation_date',
      title: 'Creation date',
      isSortable: true,
      show: true,
      content: (item) => item?.creation_date,
    },
    {
      key: 'actions',
      title: 'Actions',
      show: true,
      content: (item) => (
        <DropdownMenu
          placement="bottom-end"
          trigger={({ triggerRef, shouldRenderToParent, ...props }) => (
            <Button
              {...props}
              appearance="subtle"
              children={<DotsIcon label="more" />}
              ref={triggerRef}
            />
          )}
        >
          <DropdownItemGroup>
            <DropdownLink children="View" to={`/panel/airwaybill-stock/${item.id}`} />
            <DropdownItem children="Delete" onClick={() => openDeleteModal(item.id)} />
          </DropdownItemGroup>
        </DropdownMenu>
      ),
    },
  ]
}
