import { useMemo } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

// Apis
import { getShipmentCargowizPlanner } from 'api/shipments'

//Hooks
import useShipment from 'hooks/useShipment'

//Icons
import { ArrowRightIcon, ArrowUpIcon, ArrowDownIcon, useDisclosure } from '@royan-co/user-interface'

//Atlassian
import Button from '@atlaskit/button'
import { ButtonItem, MenuGroup, Section } from '@atlaskit/menu'
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog'

//Components
import DenyFromUserLevel from 'layouts/DenyFromUserLevel'
import ActionCard from 'components/UI/ActionCard/ActionCard'
import ShipmentCreateNoticeModal from 'components/Shipments/View/CreateNoticeModal'
import ShipmentResetShipperAction from 'components/Shipments/View/ResetShipperAction'
import CargowizPlannerAction from 'components/Shipments/View/CargowizPlannerAction'
import ShipmentFirstContactAction from 'components/Shipments/View/FirstContactAction'

const ShipmentViewActionsPage = () => {
  const navigate = useNavigate()
  const { shipmentId } = useParams()
  const { shipment } = useShipment()

  const [isOpenWarehouseWarning, warehouseWarningHandlers] = useDisclosure('warehouse-warning')
  const [isOpenShipmentNoticeModal, shipmentNoticeHandlers] = useDisclosure('notice')

  const isDisabledDownloadPlanner = useMemo(() => shipment?.cargo?.length < 1, [shipment])

  const handleWarehouseLabel = () => {
    if (shipment?.entrance?.id) {
      warehouseWarningHandlers.open()
    } else {
      navigate(`/panel/shipments/${shipmentId}/entrances/create`)
    }
  }

  const handleCargoCollection = () => {
    navigate(`/panel/shipments/${shipmentId}/cargo-collection`)
  }

  const handleCargoDelivery = () => {
    navigate(`/panel/shipments/${shipmentId}/cargo-delivery`)
  }

  const handleShipmentNotice = () => {
    shipmentNoticeHandlers.open()
  }

  const handleShipmentAirFreightBooking = () => {
    navigate(`/panel/shipments/${shipmentId}/airfreight-booking`)
  }

  return (
    <div className="grid md:grid-cols-3 items-start gap-4">
      <ActionCard title="Pre-carriage" icon={<ArrowUpIcon />}>
        <MenuGroup>
          <Section>
            <DenyFromUserLevel level="warehouse" fallback={null}>
              <ShipmentFirstContactAction shipment={shipment} />

              <ShipmentResetShipperAction />

              {shipment?.shipment_type === 2 && (
                <ButtonItem onClick={handleShipmentAirFreightBooking} shouldTitleWrap>
                  Air freight booking
                </ButtonItem>
              )}

              <ButtonItem onClick={handleCargoCollection} shouldTitleWrap>
                Cargo collection
              </ButtonItem>
            </DenyFromUserLevel>

            <ButtonItem onClick={handleWarehouseLabel} shouldTitleWrap>
              Warehouse label
            </ButtonItem>

            {shipment?.type_of_collies !== 1 && (
              <CargowizPlannerAction
                endpoint={getShipmentCargowizPlanner}
                entity={{
                  id: shipmentId,
                  reference: shipment?.reference,
                }}
                isDisabled={isDisabledDownloadPlanner}
              />
            )}
          </Section>
        </MenuGroup>
      </ActionCard>

      <ActionCard title="On-carriage" icon={<ArrowRightIcon />}>
        <MenuGroup>
          <Section>
            <DenyFromUserLevel level="warehouse" fallback={null}>
              <ButtonItem onClick={handleShipmentNotice} shouldTitleWrap>
                Shipment notice
              </ButtonItem>
            </DenyFromUserLevel>
          </Section>
        </MenuGroup>
      </ActionCard>

      <ActionCard title="Post-carriage" icon={<ArrowDownIcon />}>
        <MenuGroup>
          <Section>
            <DenyFromUserLevel level="warehouse" fallback={null}>
              <ButtonItem onClick={handleCargoDelivery} shouldTitleWrap>
                Cargo delivery
              </ButtonItem>
            </DenyFromUserLevel>
          </Section>
        </MenuGroup>
      </ActionCard>

      <ShipmentCreateNoticeModal
        isOpen={isOpenShipmentNoticeModal}
        onClose={shipmentNoticeHandlers.close}
        shipmentId={shipmentId}
      />

      <ModalTransition>
        {isOpenWarehouseWarning && (
          <Modal onClose={warehouseWarningHandlers.close} width="small">
            <ModalHeader>
              <ModalTitle appearance="warning">Entrance already created</ModalTitle>
            </ModalHeader>
            <ModalBody>
              There is already an entrance created. Do you want to create a new one?
            </ModalBody>
            <ModalFooter>
              <Button type="button" appearance="subtle" onClick={warehouseWarningHandlers.close}>
                Cancel
              </Button>
              <Button
                type="submit"
                component={Link}
                appearance="warning"
                to={`/panel/shipments/${shipmentId}/entrances/create`}
              >
                Yes
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </div>
  )
}

export default ShipmentViewActionsPage
