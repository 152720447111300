import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { useMutation } from '@tanstack/react-query'

// Utils
import { handleErrorOnSubmit } from 'utils/errors'

// Apis
import { linkTrackingUnit, unlinkTrackingUnit } from 'api/consolidations'

//Hooks
import useConsolidation from 'hooks/useConsolidation'

//Atlassian
import Lozenge from '@atlaskit/lozenge'
import Tag from '@atlaskit/tag'
import Button from '@atlaskit/button'

//Interfaces
import { Grid, ShortcutIcon, useDisclosure, useFlags } from '@royan-co/user-interface'

//Components
import RowTable from 'components/UI/RowTable'
import Card, { CardSubtitle } from 'components/UI/Card'
import ShipmentContactShow from 'components/Shipments/ContactShow'
import TableItem from 'components/UI/TableItem'
import TrackingUnitDrawer from './TrackingUnitDrawer'
import DeleteTrackingUnitModal from './DeleteTrackingUnitModal'
import { useCanAccess } from 'features/auth'
import { useAuth } from 'contexts/AuthProvider'
import { generateConsolidationUserPermissions } from '../Create/conslolidation-user-permissions'

const ConsolidationCarrierDetail = () => {
  const isMobile = useMediaQuery({ query: `(max-width: 991.98px)` })
  const [isOpenTrackingDrawer, trackingDrawerHandler] = useDisclosure('tracking_unit')
  const [isOpenDeleteTrackingModal, deleteTrackingModalHandler] = useDisclosure('delete')
  const { showSuccessFlag } = useFlags()
  const { currentUser } = useAuth()

  const { consolidation, removeTrackingUnitToData, upsertTrackingUnitToData } = useConsolidation()
  const userPermissions = generateConsolidationUserPermissions(consolidation, currentUser)
  const linkTrackingUnitMutation = useMutation(
    linkTrackingUnit.bind(null, trackingDrawerHandler.data?.id)
  )
  const trackingUnitDeleteMutation = useMutation(
    unlinkTrackingUnit.bind(null, deleteTrackingModalHandler.data?.mean_id_auto)
  )

  const canCreateTrackingUnit = useCanAccess({
    resource: 'consolidations.trackingUnit',
    action: 'create',
    userPermissions,
  })

  const canDeleteTrackingUnit = useCanAccess({
    resource: 'consolidations.trackingUnit',
    action: 'delete',
    userPermissions,
  })

  const onCloseDrawer = () => {
    trackingDrawerHandler.close()
    linkTrackingUnitMutation.reset()
  }

  const onCloseModal = () => {
    deleteTrackingModalHandler.close()
    trackingUnitDeleteMutation.reset()
  }

  const onSubmitLinkTrackingUnit = (data, { setError }) => {
    const FormData = data.serial_number?.isNew
      ? {
          positrex: data.positrex,
          serial_number: data.serial_number.value,
        }
      : { tracking_unit_id: data.serial_number.value }

    linkTrackingUnitMutation.mutate(FormData, {
      onError: (e) => {
        handleErrorOnSubmit(e, setError, data)
        console.error(e)
      },
      onSuccess: (res) => {
        upsertTrackingUnitToData(trackingDrawerHandler.data.id, res.tracking_unit)
        showSuccessFlag(res.message)
        onCloseDrawer()
      },
    })
  }

  const onSubmitUnlinkTrackingUnit = () => {
    trackingUnitDeleteMutation.mutate(deleteTrackingModalHandler.data.unit_id_auto, {
      onSuccess: (res) => {
        removeTrackingUnitToData(
          deleteTrackingModalHandler.data.mean_id_auto,
          deleteTrackingModalHandler.data.unit_id_auto
        )
        showSuccessFlag(res?.message)
        onCloseModal()
      },
    })
  }

  const generalRows = useMemo(() => {
    let rowsItems = [
      {
        title: 'Name',
        content: (
          <TableItem condition={consolidation?.carrier_data?.id_auto}>
            <Link to={`/panel/registries/${consolidation?.carrier_data?.id_auto}`} target="_blank">
              {consolidation?.carrier_data?.company_name} <ShortcutIcon size={16} />
            </Link>
          </TableItem>
        ),
      },

      {
        title: 'Contact',
        content: consolidation?.carrier_contacts?.length ? (
          consolidation.carrier_contacts.map((contact, index) => (
            <div
              key={contact.id_auto}
              className={consolidation.carrier_contacts.length !== index + 1 ? 'mb-2' : ''}
            >
              <ShipmentContactShow contact={contact} />
            </div>
          ))
        ) : (
          <Lozenge appearance="default">Not set</Lozenge>
        ),
      },
    ]

    return rowsItems
  }, [consolidation])

  const transportationRows = useMemo(() => {
    let transportationRowsItem = []

    consolidation?.means?.forEach((mean, i) => {
      if (mean?.code)
        transportationRowsItem.push({
          title: `Unit ${consolidation?.means?.length > 1 ? `(${i + 1}) ` : ''}no.`,
          content: (
            <>
              {mean?.code}
              <br />
              {mean?.tracking_unit?.unit_id ? (
                <span className="-ml-1">
                  <Tag
                    color={mean.tracking_unit.is_online ? 'greenLight' : 'redLight'}
                    text={`GPS ${mean.tracking_unit.unit_id}`}
                    onBeforeRemoveAction={() => {
                      deleteTrackingModalHandler.open({
                        mean_id_auto: mean.id_auto,
                        unit_id_auto: mean.tracking_unit.id_auto,
                      })
                      return false
                    }}
                    isRemovable={canDeleteTrackingUnit}
                  />
                </span>
              ) : canCreateTrackingUnit ? (
                <Button
                  spacing="compact"
                  appearance="primary"
                  onClick={() => {
                    trackingDrawerHandler.open({ id: mean.id_auto })
                  }}
                >
                  Link to a GPS
                </Button>
              ) : null}
            </>
          ),
          // <Link to="" target="_blank">
          //   {mean?.code} <ShortcutIcon size={16} />
          // </Link>
        })
    })

    return transportationRowsItem
  }, [
    canCreateTrackingUnit,
    canDeleteTrackingUnit,
    consolidation?.means,
    consolidation?.tracking_mail,
    consolidation?.tracking_whatsapp,
    deleteTrackingModalHandler,
    trackingDrawerHandler,
  ])

  return (
    <>
      <Card className="mt-6" title="Carrier">
        <Grid className="w-full">
          <Grid.Col lg={6}>
            <RowTable
              rows={generalRows}
              verticalAlign="align-top"
              width={isMobile ? 'w-1/2' : 'w-1/3'}
            />
          </Grid.Col>

          <Grid.Col lg={6}>
            <CardSubtitle className="mb-3">Transportation unit</CardSubtitle>

            {consolidation?.means?.length > 0 ? (
              <RowTable
                rows={transportationRows}
                verticalAlign="align-top"
                width={isMobile ? 'w-1/2' : 'w-1/3'}
              />
            ) : (
              <Lozenge appearance="moved">NOT PROVIDED</Lozenge>
            )}

            <TrackingUnitDrawer
              isOpen={isOpenTrackingDrawer}
              onClose={onCloseDrawer}
              onSubmit={onSubmitLinkTrackingUnit}
              isLoading={linkTrackingUnitMutation.isLoading}
            />

            <DeleteTrackingUnitModal
              isOpen={isOpenDeleteTrackingModal}
              onClose={onCloseModal}
              onSubmit={onSubmitUnlinkTrackingUnit}
              isLoading={trackingUnitDeleteMutation.isLoading}
              isError={trackingUnitDeleteMutation.isError}
            />
          </Grid.Col>
        </Grid>
      </Card>
    </>
  )
}

export default ConsolidationCarrierDetail
