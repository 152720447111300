import { Helmet } from 'react-helmet-async'
import EmptyState from '@atlaskit/empty-state'
import Button from '@atlaskit/button/standard-button'

import Image from 'assets/images/404.svg'

const NotFoundPage = ({ entity = 'Page' }) => {
  return (
    <>
      <Helmet>
        <title>Portal - 404</title>
      </Helmet>
      <EmptyState
        header={`${entity} not found`}
        description={`The ${entity} you are looking for isn't found.`}
        primaryAction={
          <Button href="/" appearance="primary">
            Back to Home
          </Button>
        }
        imageUrl={Image}
        maxImageWidth={400}
        maxImageHeight={400}
      />
    </>
  )
}

export default NotFoundPage
