import { useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'

// Utils
import { handleErrorOnSubmit } from 'utils/errors'
import { getFilled } from 'utils/helpers'

// Apis
import { createBank, editBank } from '../../api/banks-requests'
import { useBank } from './../../api/getBank'

// Atlassian
import Button, { LoadingButton } from '@atlaskit/button'
import PageHeader from '@atlaskit/page-header'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'
import Textfield from '@atlaskit/textfield'
import Spinner from '@atlaskit/spinner'
import SectionMessage from '@atlaskit/section-message'
import { ErrorMessage } from '@atlaskit/form'

// Interfaces
import { Grid, Form, Field, WarningBannerField, useFlags } from '@royan-co/user-interface'

// Components
import Card from 'components/UI/Card'
import RegistrySelect from 'components/Registries/RegistrySelect'

const BanksCreationPage = () => {
  const { bankId } = useParams()
  const queryClient = useQueryClient()

  const { showSuccessFlag } = useFlags()

  const navigate = useNavigate()
  const pageTitle = bankId ? 'Edit bank' : `New bank`

  const createBankMutation = useMutation((data) =>
    !bankId ? createBank(data) : editBank(bankId, data)
  )

  const { data: bank, isError, isLoading, refetch } = useBank(bankId, { enabled: !!bankId })

  const defaultValues = useMemo(() => {
    const values = {
      name: getFilled(bank, 'name', ''),
      code: getFilled(bank, 'code', ''),
      subaccount: getFilled(bank, 'subaccount', ''),
      iban: getFilled(bank, 'iban', ''),
      swift: getFilled(bank, 'swift', ''),
      registries: bank?.registries?.map((registry) => ({
        label: registry?.company_name,
        value: registry?.id_auto,
        imported: registry?.arca_imported,
      })),
    }

    return values
  }, [bank])

  const onSubmitCreation = (data, { setError }) => {
    const fromData = {
      ...data,
      registries: data.registries?.map((r) => r.value) || [],
    }

    createBankMutation.mutate(fromData, {
      onError: (e) => {
        console.error(e)
        handleErrorOnSubmit(e, setError, data)
      },
      onSuccess: (res) => {
        showSuccessFlag(res.message)
        queryClient.removeQueries({
          queryKey: ['bank', bankId],
        })
        navigate('/panel/banks', { replace: true })
      },
    })
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <Grid gutter={24} className="w-full">
        <Grid.Col md={10} lg={8} xl={6} offsetMd={1} offsetLg={2} offsetXl={3}>
          <PageHeader
            breadcrumbs={
              <Breadcrumbs>
                <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
                <BreadcrumbsItem text="Accounting" />
                <BreadcrumbsItem text="Banks" to="/panel/banks" component={Link} />
              </Breadcrumbs>
            }
          >
            {pageTitle}
          </PageHeader>

          <div className="my-8">
            {isLoading && bankId ? (
              <div className="flex justify-center my-32">
                <Spinner />
              </div>
            ) : isError ? (
              <SectionMessage
                appearance="warning"
                title="Something wrong on loading data, please retry."
              >
                <Button onClick={() => refetch()}>Retry</Button>
              </SectionMessage>
            ) : (
              <Form onSubmit={onSubmitCreation} defaultValues={defaultValues}>
                {() => (
                  <>
                    <Card className="mt-0">
                      <Field isRequired label="Name" name="name" defaultValue="" className="!mt-0">
                        {({ fieldProps, error }) => (
                          <>
                            <Textfield placeholder="Type the name" {...fieldProps} />
                            {error && <ErrorMessage>{error}</ErrorMessage>}
                          </>
                        )}
                      </Field>

                      <Field isRequired label="Code" name="code" defaultValue="" className="!mt-4">
                        {({ fieldProps, error }) => (
                          <>
                            <Textfield placeholder="Type the code" {...fieldProps} />
                            {error && <ErrorMessage>{error}</ErrorMessage>}
                          </>
                        )}
                      </Field>

                      <Field
                        label="Sub account"
                        name="subaccount"
                        defaultValue=""
                        className="!mt-4"
                      >
                        {({ fieldProps, error }) => (
                          <>
                            <Textfield placeholder="Type the sub account" {...fieldProps} />
                            {error && <ErrorMessage>{error}</ErrorMessage>}
                          </>
                        )}
                      </Field>

                      <Field label="IBAN" name="iban" defaultValue="" className="!mt-4">
                        {({ fieldProps, error }) => (
                          <>
                            <Textfield placeholder="Type the IBAN" {...fieldProps} />
                            {error && <ErrorMessage>{error}</ErrorMessage>}
                          </>
                        )}
                      </Field>

                      <Field label="Swift" name="swift" defaultValue="" className="!mt-4">
                        {({ fieldProps, error }) => (
                          <>
                            <Textfield placeholder="Type the swift" {...fieldProps} />
                            {error && <ErrorMessage>{error}</ErrorMessage>}
                          </>
                        )}
                      </Field>

                      <Field type="select" label="Registries" name="registries" className="!mt-4">
                        {({ fieldProps, error }) => (
                          <>
                            <RegistrySelect
                              isMulti
                              defaultData={defaultValues?.registries}
                              isImported
                              isActive
                              {...fieldProps}
                            />
                            {error && <ErrorMessage>{error}</ErrorMessage>}
                          </>
                        )}
                      </Field>
                    </Card>

                    <WarningBannerField />

                    <LoadingButton
                      className="!table ml-auto mt-6"
                      appearance="primary"
                      type="submit"
                      isLoading={createBankMutation.isLoading}
                    >
                      {bankId ? 'Edit' : 'Create'}
                    </LoadingButton>
                  </>
                )}
              </Form>
            )}
          </div>
        </Grid.Col>
      </Grid>
    </>
  )
}

export default BanksCreationPage
