import { marked } from 'marked'
import PropTypes from 'prop-types'

const markdownStyle = {
  whiteSpace: 'pre-line',
  wordBreak: 'break-all',
}
function Markdown({ source }) {
  const renderer = new marked.Renderer()

  const linkRenderer = renderer.link

  renderer.link = (href, title, text) => {
    const localLink = href.startsWith(`${window.location.protocol}//${window.location.hostname}`)

    const html = linkRenderer.call(renderer, href, title, text)

    return localLink
      ? html
      : html.replace(/^<a /, `<a target="_blank" rel="noreferrer noopener nofollow" `)
  }

  return (
    <div
      style={markdownStyle}
      dangerouslySetInnerHTML={{
        __html: marked(source, { mangle: false, headerIds: false, breaks: true, renderer }),
      }}
    />
  )
}

Markdown.propTypes = {
  source: PropTypes.string,
}

export default Markdown
