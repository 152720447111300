import { useParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

// API
import { deleteLineRoute } from 'features/lines/api/requests'

// Hooks
import useLineRoutes from 'features/lines/hooks/useLineRoutes'

// Atlassian
import Modal, {
  ModalBody,
  ModalTitle,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import Button, { LoadingButton } from '@atlaskit/button'

// User interface
import { useFlags, WarningBanner } from '@royan-co/user-interface'

const DeleteLineRouteModal = ({ routeId, isOpen, onClose }) => {
  const { lineId } = useParams()
  const { showSuccessFlag } = useFlags()

  const deleteRouteMutation = useMutation(deleteLineRoute)

  const { handleDeletedRoute } = useLineRoutes(lineId, { enabled: false })

  const handleSubmit = () => {
    deleteRouteMutation.mutate(routeId, {
      onSuccess: (res) => {
        handleDeletedRoute(routeId)
        showSuccessFlag(res?.message)
        onClose?.()
      },
    })
  }

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={onClose}>
          <ModalHeader>
            <ModalTitle appearance="danger">Delete route</ModalTitle>
          </ModalHeader>
          <ModalBody>
            You are about to delete a route. Are you sure?
            {deleteRouteMutation.isError && <WarningBanner />}
          </ModalBody>
          <ModalFooter>
            <Button appearance="subtle" onClick={onClose}>
              Cancel
            </Button>
            <LoadingButton
              isLoading={deleteRouteMutation.isLoading}
              appearance="danger"
              onClick={handleSubmit}
              autoFocus
            >
              Delete
            </LoadingButton>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  )
}

export default DeleteLineRouteModal
