// Interfaces
import { ArrowRightIcon } from '@royan-co/user-interface'

const MarginCalculatorRouteTag = ({ departureCountry, arrivalCountry }) => {
  return (
    <div className="flex gap-1 items-center">
      {departureCountry}
      <ArrowRightIcon size={16} className="text-neutral-200" />
      {arrivalCountry}
    </div>
  )
}

export default MarginCalculatorRouteTag
