import React, { useMemo, useState } from 'react'

// Hooks
import { useStatistics } from '../api/useStatistics'

// Atlassian
import { DatePicker } from '@atlaskit/datetime-picker'
import { Label } from '@atlaskit/form'
import Spinner from '@atlaskit/spinner'
import SectionMessage from '@atlaskit/section-message'
import Button from '@atlaskit/button'

// Interfaces
import { useDisclosure } from '@royan-co/user-interface'

// Components
import TabButtons from './tabButtons'
import InvoiceCard from '../components/InvoiceCard'
import ShipmentListModal from './modalList/ShipmentListModal'
import StatisticsCard from './StatisticsCard'
import QuotationListModal from './modalList/QuotationListModal'
import ConsolidationListModal from './modalList/ConsolidationListModal'

const ViewDashboardStatistics = () => {
  const [period, setPeriod] = useState('day')
  const [from, setFrom] = useState()
  const [to, setTo] = useState()

  const [isOpenShipmentsModal, shipmentsModalHandler] = useDisclosure('shipments')
  const [isOpenQuotationsModal, quotationsModalHandler] = useDisclosure('quotations')
  const [isOpenConsolidationsModal, consolidationsModalHandler] = useDisclosure('consolidations')
  const isCustom = useMemo(() => period === 'custom', [period])
  const enabled = useMemo(() => (isCustom ? !!from && !!to : true), [from, isCustom, to])

  const { data, isLoading, isError, refetch, isFetching } = useStatistics(
    { period, from, to },
    { enabled }
  )

  const onChangePeriod = (v) => {
    if (v !== 'custom') {
      setFrom()
      setTo()
    }
    setPeriod(v)
  }

  return (
    <>
      <TabButtons selectedTab={period} onChange={onChangePeriod} />

      {isCustom && (
        <div className="grid lg:grid-cols-2 gap-4 mt-4">
          <div>
            <Label htmlFor="from">From</Label>
            <DatePicker id="from" value={from || ''} onChange={(v) => setFrom(v)} />
          </div>

          <div>
            <Label htmlFor="to">To</Label>
            <DatePicker id="to" value={to || ''} onChange={(v) => setTo(v)} />
          </div>
        </div>
      )}

      {(isLoading || isFetching) && enabled ? (
        <div className="w-full text-center my-8">
          <Spinner />
        </div>
      ) : isError ? (
        <div className="my-2">
          <SectionMessage
            appearance="warning"
            title="Something wrong on loading data, please retry"
          >
            <Button onClick={() => refetch()}>Retry</Button>
          </SectionMessage>
        </div>
      ) : data ? (
        <div className="grid lg:grid-cols-2 gap-4 mt-4">
          <StatisticsCard
            headingLabel="Accepted quotations"
            headingValue={data.quotations.accepted}
            onClick={() => quotationsModalHandler.open('accepted')}
            tags={[
              {
                label: 'Pending',
                tagColor: 'yellowLight',
                value: data.quotations.pending,
                onClick: () => quotationsModalHandler.open('pending'),
              },
              {
                label: 'Rejected',
                tagColor: 'redLight',
                value: data.quotations.rejected,
                onClick: () => quotationsModalHandler.open('rejected'),
              },
            ]}
          />

          <StatisticsCard
            headingLabel="Consolidations"
            headingValue={data.consolidations.total_consolidations}
            onClick={() => consolidationsModalHandler.open('all')}
            tags={[
              {
                label: 'Finished shipments',
                tagColor: 'greenLight',
                value: data.consolidations.finished_shipments,
                onClick: () => shipmentsModalHandler.open('finished'),
              },
              {
                label: 'Finished consolidations',
                tagColor: 'greenLight',
                value: data.consolidations.finished_consolidations,
                onClick: () => consolidationsModalHandler.open('finished'),
              },
            ]}
          />

          <InvoiceCard
            activeInvoices={data.finance.active_invoices}
            passiveInvoices={data.finance.passive_invoices}
          />

          <StatisticsCard
            headingSubTitle="anomalies in shipments"
            tags={[
              {
                label: 'In loss',
                tagColor: 'redLight',
                value: data.anomalies.in_loss,
                onClick: () => shipmentsModalHandler.open('in-loss'),
              },
              {
                label: 'No active invoice',
                tagColor: 'yellowLight',
                value: data.anomalies.no_active_invoice,
                onClick: () => shipmentsModalHandler.open('no-active'),
              },
              {
                label: 'No passive invoice',
                tagColor: 'yellowLight',
                value: data.anomalies.no_passive_invoice,
                onClick: () => shipmentsModalHandler.open('no-passive'),
              },
            ]}
          />
        </div>
      ) : null}

      <ShipmentListModal
        isOpen={isOpenShipmentsModal}
        onClose={shipmentsModalHandler.close}
        type={shipmentsModalHandler.data}
        params={{ period, from, to }}
      />

      <QuotationListModal
        isOpen={isOpenQuotationsModal}
        onClose={quotationsModalHandler.close}
        status={quotationsModalHandler.data}
        params={{ period, from, to }}
      />

      <ConsolidationListModal
        isOpen={isOpenConsolidationsModal}
        onClose={consolidationsModalHandler.close}
        type={consolidationsModalHandler.data}
        params={{ period, from, to }}
      />
    </>
  )
}

export default ViewDashboardStatistics
