import { findOption, transformSelectField } from 'utils/helpers'

//Hooks
import useShipmentsEnumerations from 'hooks/useShipmentsEnumerations'

//Atlassian
import TextField from '@atlaskit/textfield'
import { DatePicker } from '@atlaskit/datetime-picker'
import Select from '@atlaskit/select'
import Spinner from '@atlaskit/spinner'

//Interfaces
import { Field } from '@royan-co/user-interface'
import { invoicePaidOptions, invoiceTypeOptions } from 'components/Invoices/constants'

export const RegistriesFinancialBalanceFilterFields = ({ appliedFilters }) => {
  const { getEnumerationOptionsByKey, isLoadingShipmentEnumeration } = useShipmentsEnumerations()

  return (
    <>
      <Field
        label="Type"
        type="select"
        name="do_boolean"
        transform={transformSelectField(invoiceTypeOptions)}
      >
        {({ fieldProps }) => (
          <Select
            isClearable={true}
            options={invoiceTypeOptions}
            placeholder="Choose one"
            {...fieldProps}
          />
        )}
      </Field>

      <Field
        label="Shipment reference"
        name="shipment"
        defaultValue={appliedFilters.shipment || ''}
      >
        {({ fieldProps }) => (
          <TextField placeholder="Type the shipment reference here" {...fieldProps} />
        )}
      </Field>

      <div className="grid md:grid-cols-2 md:gap-4">
        <Field label="Amount" name="total_amount" defaultValue={appliedFilters.total_amount || ''}>
          {({ fieldProps }) => <TextField placeholder="Type amount" {...fieldProps} />}
        </Field>

        <Field
          isClearable={true}
          type="select"
          label="Currency"
          name="currency"
          transform={transformSelectField(getEnumerationOptionsByKey('currencies'))}
        >
          {({ fieldProps }) => (
            <div>
              <Select
                menuPlacement="auto"
                placeholder="Choose"
                isLoading={isLoadingShipmentEnumeration}
                options={getEnumerationOptionsByKey('currencies')}
                {...fieldProps}
              />
            </div>
          )}
        </Field>
      </div>

      <Field
        label="Paid"
        type="select"
        name="is_payd"
        transform={transformSelectField(invoicePaidOptions)}
      >
        {({ fieldProps }) => (
          <Select
            isClearable={true}
            options={invoicePaidOptions}
            placeholder="Choose one"
            {...fieldProps}
          />
        )}
      </Field>

      <div className="grid md:grid-cols-2 md:gap-4">
        <Field
          label="Issued from"
          name="issued_from"
          defaultValue={appliedFilters.issued_from || ''}
        >
          {({ fieldProps }) => <DatePicker placeholder="2/18/2022" {...fieldProps} />}
        </Field>

        <Field label="Issued to" name="issued_to" defaultValue={appliedFilters.issued_to || ''}>
          {({ fieldProps }) => <DatePicker placeholder="2/18/2022" {...fieldProps} />}
        </Field>
      </div>
    </>
  )
}

const CurrencyFilter = ({ value }) => {
  const { getEnumerationOptionsByKey, isLoadingShipmentEnumeration } = useShipmentsEnumerations()
  return (
    <>
      Currency:{' '}
      {isLoadingShipmentEnumeration ? (
        <Spinner size="xsmall" />
      ) : (
        findOption(getEnumerationOptionsByKey('currencies'), value)?.label
      )}
    </>
  )
}

export const RegistriesFinancialBalanceFilters = [
  {
    key: 'do_boolean',
    content: (v) => `Type: ${findOption(invoiceTypeOptions, v)?.label}`,
  },
  {
    key: 'shipment',
    content: (v) => `Shipment reference: ${v}`,
  },
  {
    key: 'total_amount',
    content: (v) => `Amount: ${v}`,
  },
  {
    key: 'currency',
    content: (v) => <CurrencyFilter value={v} />,
  },

  {
    key: 'is_payd',
    content: (v) => `Paid: ${findOption(invoicePaidOptions, v)?.label}`,
  },
  {
    key: 'issued_from',
    content: (v) => `Issued from: ${v}`,
  },
  {
    key: 'issued_to',
    content: (v) => `Issued to: ${v}`,
  },
]
