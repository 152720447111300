import { useEffect } from 'react'

// Utils
import { transformSelectField } from 'utils/helpers'
import {
  areaTypeOptions,
  marginCalculatorTypeOptions,
  mustGoOptions,
} from 'features/marginCalculator/utils/constants'
import { findOption } from 'utils/helpers'

// Components
import { registryClassificationOptions } from 'components/Registries/constants'
import { calculationMethodOptions } from 'components/Rates/constants'
import { modeOptions } from 'components/Shipments/constants'

// Atlassian
import Select, { CountrySelect } from '@atlaskit/select'
import { DatePicker } from '@atlaskit/datetime-picker'
import { allCountries as countries } from '/node_modules/@atlaskit/select/dist/es2019/data/countries.js'

// Interfaces
import { Field, useFormContext } from '@royan-co/user-interface'

const MarginCalculatorFilterFields = ({ appliedFilters }) => {
  const { watch, setValue } = useFormContext()

  const type = watch('country_area')

  useEffect(() => {
    if (type !== 1) {
      setValue('area', null)
    }
    if (type !== 0) {
      setValue('arrival_country', null)
      setValue('departure_country', null)
    }
  }, [setValue, type])

  return (
    <>
      <Field
        label="Mode"
        type="select"
        name="shipment_type"
        transform={transformSelectField(modeOptions)}
        className="!mt-4"
      >
        {({ fieldProps }) => (
          <Select
            isClearable={true}
            options={modeOptions}
            placeholder="Choose a mode"
            {...fieldProps}
          />
        )}
      </Field>

      <Field
        label="Type"
        type="select"
        name="country_area"
        transform={transformSelectField(marginCalculatorTypeOptions)}
        className="!mt-4"
      >
        {({ fieldProps }) => (
          <Select
            isClearable={true}
            options={marginCalculatorTypeOptions}
            placeholder="Choose a type"
            {...fieldProps}
          />
        )}
      </Field>

      {type === 0 && (
        <div className="grid md:grid-cols-2 md:gap-4">
          <Field
            label="Departure country"
            name="departure_country"
            type="select"
            transform={{
              output: (c) => (c?.abbr !== undefined ? c.abbr : null),
              input: (v) => countries?.find((c) => c.abbr === v),
            }}
            className="!mt-4"
          >
            {({ fieldProps: { ref, ...fieldProps } }) => (
              <CountrySelect
                isClearable={true}
                className="w-100"
                placeholder="Choose a country"
                {...fieldProps}
              />
            )}
          </Field>

          <Field
            type="select"
            label="Arrival country"
            name="arrival_country"
            transform={{
              output: (c) => (c?.abbr !== undefined ? c.abbr : null),
              input: (v) => countries?.find((c) => c.abbr === v),
            }}
            className="!mt-4"
          >
            {({ fieldProps: { ref, ...fieldProps } }) => (
              <CountrySelect
                isClearable={true}
                className="w-100"
                placeholder="Choose a country"
                {...fieldProps}
              />
            )}
          </Field>
        </div>
      )}

      {type === 1 && (
        <Field
          label="Area type"
          type="select"
          name="area"
          transform={transformSelectField(areaTypeOptions)}
          className="!mt-4"
        >
          {({ fieldProps }) => (
            <Select
              isClearable={true}
              options={areaTypeOptions}
              placeholder="Choose one"
              {...fieldProps}
            />
          )}
        </Field>
      )}

      <Field
        label="Calculation method"
        type="select"
        name="calculation_method"
        transform={transformSelectField(calculationMethodOptions)}
        className="!mt-4"
      >
        {({ fieldProps }) => (
          <Select
            isClearable={true}
            options={calculationMethodOptions}
            placeholder="Choose one"
            {...fieldProps}
          />
        )}
      </Field>

      <Field
        label="Registry classification"
        name="type"
        type="select"
        transform={transformSelectField(registryClassificationOptions)}
        className="!mt-4"
      >
        {({ fieldProps }) => (
          <Select
            options={registryClassificationOptions}
            placeholder="Choose one"
            {...fieldProps}
          />
        )}
      </Field>

      <Field
        label="Must go"
        type="select"
        name="must_go"
        transform={{
          output: (opt) => (opt?.value !== undefined ? opt.value : null),
          input: (v) => findOption(mustGoOptions, v),
        }}
      >
        {({ fieldProps }) => (
          <Select isClearable={true} options={mustGoOptions} placeholder="Choose" {...fieldProps} />
        )}
      </Field>

      <div className="grid md:grid-cols-2 md:gap-4">
        <Field label="From" name="created_from" defaultValue={appliedFilters.created_from || ''}>
          {({ fieldProps }) => <DatePicker placeholder="2/18/2022" {...fieldProps} />}
        </Field>

        <Field label="To" name="created_to" defaultValue={appliedFilters.created_to || ''}>
          {({ fieldProps }) => <DatePicker placeholder="2/18/2022" {...fieldProps} />}
        </Field>
      </div>
    </>
  )
}

export default MarginCalculatorFilterFields
