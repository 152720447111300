import { useMemo } from 'react'

// Utils
import { formatMonetaryNumber } from 'utils/numbers'

// Atlassian
import Heading from '@atlaskit/heading'

// Components
import Card from 'components/UI/Card'
import { useFinanceEditingContext } from 'contexts/FinanceEditingProvider'

const EditFinanceTotalCostCard = () => {
  const { financeData } = useFinanceEditingContext()

  const total = useMemo(() => {
    const rows = financeData
      .map((data) => [
        ...data.suppliers,
        ...data.accessories,
        ...data.suppliers.map((s) => s.surcharges).flat(1),
      ])
      .flat(1)

    return rows?.reduce(
      (p, c) => ({
        cost: p.cost + 1 * (c?.cost || 0),
        quotation: p.quotation + 1 * (c?.quotation || 0),
      }),
      { cost: 0, quotation: 0 }
    )
  }, [financeData])

  return (
    <Card className="mb-4">
      {[
        {
          title: 'Total cost',
          content: formatMonetaryNumber(total.cost),
        },
        {
          title: 'Total quotation',
          content: formatMonetaryNumber(total.quotation),
        },
        {
          title: 'Total margin',
          content: (
            <span
              className={`${total.quotation - total.cost > 0 ? 'text-green-400' : 'text-red-400'}`}
            >
              {formatMonetaryNumber(total.quotation - total.cost)}
            </span>
          ),
        },
      ].map((item, index) => (
        <div className={index === 0 ? '' : 'mt-2'} key={index}>
          <Heading level="h400">{item.title}</Heading>
          <span>{item.content}</span>
        </div>
      ))}
    </Card>
  )
}

export default EditFinanceTotalCostCard
