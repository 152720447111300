export const getEditedAirwaybillData = (data) => {
  const formData = {
    ...data,
    issuedBy: data?.issuedBy?.value || null,
    airportOfDepartureAndRequestedRouting:
      data.airportOfDepartureAndRequestedRouting?.value || null,
    byFirstCarrier: data.byFirstCarrier?.value || null,
    airportDestination: data.airportDestination?.value || null,
    ppd: !!data.ppd ? 1 : 0,
    coll: !!data.coll ? 1 : 0,
    ppdOther: !!data.ppdOther ? 1 : 0,
    collOther: !!data.collOther ? 1 : 0,
  }

  delete formData.logoUrl

  return formData
}
