import { useCallback } from 'react'
import { useMediaQuery } from 'react-responsive'

import { getFilled, transformSelectField } from 'utils/helpers'

//Hooks
import useShipmentsEnumerations from 'hooks/useShipmentsEnumerations'

//Atlassian
import Button, { LoadingButton } from '@atlaskit/button'
import Drawer from '@atlaskit/drawer'
import { ErrorMessage } from '@atlaskit/form'
import Select from '@atlaskit/select'
import Toggle from '@atlaskit/toggle'
import Textfield from '@atlaskit/textfield'

//Interfaces
import { Field, Form, RangeSlider, WarningBannerField } from '@royan-co/user-interface'
import { temperatureControlOptions } from '../Create/constants'
import { adrPackagingGroupOptions } from '../constants'

const SpecialConditionsDrawer = ({ onClose, isOpen, data, onSubmit, isLoading }) => {
  const isMobile = useMediaQuery({ query: `(max-width: 991.98px)` })

  const { getEnumerationOptionsByKey, isLoadingShipmentEnumeration } = useShipmentsEnumerations()

  const selectedReeferOption = useCallback((defaultRange) => {
    if (!defaultRange) return
    let [min, max] = defaultRange.split(',')

    const selected = temperatureControlOptions.find((o) => {
      return o.value.range.min === min * 1 && o.value.range.max === max * 1
    })

    let selectedCustom = {}

    if (!selected?.value?.type) {
      selectedCustom = temperatureControlOptions.find((o) => o.value.type === 'custom')
      selectedCustom.value.range = { min: min * 1, max: max * 1 }
    }

    return selected || selectedCustom
  }, [])

  const defaultValues = {
    reefer: getFilled(data, 'reefer', 0),
    reefer_temperature: selectedReeferOption(data?.reefer_temperature),
    adr_goods: getFilled(data, 'adr_goods', 0),
    adr_number: getFilled(data, 'adr_number', ''),
    adr_class: getFilled(data, 'adr_class', null),
    insurance: getFilled(data, 'insurance', 0),
    goods_value: getFilled(data, 'goods_value', ''),
    currency: getFilled(data, 'currency', null),
    chemical: getFilled(data, 'chemical', 0),
    pharma: getFilled(data, 'pharma', 0),
    hydraulic_ramp: getFilled(data, 'hydraulic_ramp', 0),
    second_set: getFilled(data, 'second_set', 0),
    letter_of_credit: getFilled(data, 'letter_of_credit', 0),
    adr_packaging_group: getFilled(data, 'adr_packaging_group', null),
  }

  return (
    <Drawer isOpen={isOpen} onClose={onClose} width={isMobile ? 'full' : 'medium'}>
      <div className="drawer">
        <h3>Edit special conditions</h3>

        <Form onSubmit={onSubmit} defaultValues={defaultValues}>
          {({ watch, getValues, setValue }) => (
            <>
              <Field
                type="checkbox"
                name="reefer"
                label="Temperature control"
                onValueChange={() => {
                  if (getValues('reefer')) {
                    setValue('reefer_temperature', '')
                  }
                }}
              >
                {({ fieldProps }) => (
                  <div>
                    <Toggle {...fieldProps} />
                  </div>
                )}
              </Field>

              {!!watch('reefer') && (
                <Field
                  isRequired
                  type="select"
                  label="Range"
                  name="reefer_temperature"
                  validate={(d) => !!d?.value?.range?.min}
                >
                  {({ fieldProps, errorType }) => (
                    <div>
                      <Select
                        placeholder="Choose a range"
                        options={temperatureControlOptions}
                        {...fieldProps}
                      />
                      {errorType && <ErrorMessage>Please select range</ErrorMessage>}
                    </div>
                  )}
                </Field>
              )}

              {!!watch('reefer') && watch('reefer_temperature')?.value?.type === 'custom' && (
                <div className="inline-block w-full mt-1">
                  <Field
                    name="reefer_temperature"
                    transform={{
                      output: ([min, max]) => ({
                        ...watch('reefer_temperature'),
                        value: { type: 'custom', range: { min, max } },
                      }),
                    }}
                  >
                    {({ fieldProps }) => (
                      <div>
                        <RangeSlider
                          max={30}
                          min={-25}
                          fieldProps={fieldProps}
                          onChange={fieldProps.onChange}
                          defaultValue={[
                            fieldProps?.value?.value?.range?.min || -10,
                            fieldProps?.value?.value?.range?.max || 15,
                          ]}
                        />
                      </div>
                    )}
                  </Field>
                </div>
              )}

              <Field
                type="checkbox"
                name="adr_goods"
                label="Dangerous cargo"
                className="mt-4"
                onValueChange={() => {
                  if (getValues('adr_goods')) {
                    setValue('adr_number', '')
                    setValue('adr_class', null)
                    setValue('adr_packaging_group', null)
                  }
                }}
              >
                {({ fieldProps }) => (
                  <div>
                    <Toggle {...fieldProps} />
                  </div>
                )}
              </Field>

              {!!watch('adr_goods') && (
                <div>
                  <Field label="UN Number" name="adr_number" isRequired>
                    {({ fieldProps }) => (
                      <div>
                        <Textfield {...fieldProps} />
                      </div>
                    )}
                  </Field>

                  <Field
                    isRequired
                    type="select"
                    label="Class"
                    name="adr_class"
                    transform={transformSelectField(getEnumerationOptionsByKey('adr_classes'))}
                  >
                    {({ fieldProps }) => (
                      <div>
                        <Select
                          isLoading={isLoadingShipmentEnumeration}
                          options={getEnumerationOptionsByKey('adr_classes')}
                          {...fieldProps}
                        />
                      </div>
                    )}
                  </Field>

                  <Field
                    isRequired
                    type="select"
                    label="Packing group"
                    name="adr_packaging_group"
                    transform={transformSelectField(adrPackagingGroupOptions)}
                  >
                    {({ fieldProps }) => (
                      <div>
                        <Select
                          isLoading={isLoadingShipmentEnumeration}
                          options={adrPackagingGroupOptions}
                          {...fieldProps}
                        />
                      </div>
                    )}
                  </Field>
                </div>
              )}

              <Field
                type="checkbox"
                label="Cargo insurance"
                name="insurance"
                className="mt-4"
                onValueChange={() => {
                  if (getValues('insurance')) {
                    setValue('goods_value', '')
                    setValue('currency', null)
                  }
                }}
              >
                {({ fieldProps }) => (
                  <div>
                    <Toggle {...fieldProps} />
                  </div>
                )}
              </Field>

              {!!watch('insurance') && (
                <div className="grid md:grid-cols-2 md:gap-4">
                  <Field isRequired label="Cargo value" name="goods_value">
                    {({ fieldProps }) => (
                      <Textfield placeholder="Type cargo value" {...fieldProps} />
                    )}
                  </Field>

                  <Field
                    isRequired
                    type="select"
                    name="currency"
                    label="Currency"
                    transform={transformSelectField(getEnumerationOptionsByKey('currencies'))}
                  >
                    {({ fieldProps }) => (
                      <div>
                        <Select
                          menuPlacement="auto"
                          isLoading={isLoadingShipmentEnumeration}
                          options={getEnumerationOptionsByKey('currencies')}
                          {...fieldProps}
                        />
                      </div>
                    )}
                  </Field>
                </div>
              )}

              {[
                { name: 'chemical', label: 'Chemical' },
                { name: 'pharma', label: 'Pharma' },
                { name: 'hydraulic_ramp', label: 'Hydraulic ramp' },
                { name: 'second_set', label: 'Second set of documents' },
                { name: 'letter_of_credit', label: 'Letter of credit' },
              ].map((item) => (
                <Field
                  type="checkbox"
                  name={item.name}
                  label={item.label}
                  className="mt-4"
                  key={item.name}
                >
                  {({ fieldProps }) => (
                    <div>
                      <Toggle {...fieldProps} />
                    </div>
                  )}
                </Field>
              ))}

              <WarningBannerField />

              <LoadingButton
                type="submit"
                className="mt-4"
                shouldFitContainer
                appearance="primary"
                isLoading={isLoading}
              >
                Edit
              </LoadingButton>
              <Button appearance="subtle" className="mt-2" shouldFitContainer onClick={onClose}>
                Cancel
              </Button>
            </>
          )}
        </Form>
      </div>
    </Drawer>
  )
}

export default SpecialConditionsDrawer
