import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import { getShipmentInvoices } from 'api/shipments'
import { formatMonetaryNumber } from 'utils/numbers'

//Atlassian
import Button from '@atlaskit/button'
import Spinner from '@atlaskit/spinner'
import EmptyState from '@atlaskit/empty-state'
import SectionMessage from '@atlaskit/section-message'
import Lozenge from '@atlaskit/lozenge'
import { DynamicTableStateless } from '@atlaskit/dynamic-table'

//Components
import Card from 'components/UI/Card'
import SearchInput from 'components/UI/SearchInput'
import InvoiceRowActions from 'components/Invoices/InvoiceRowActions'
import InvoicePaidTag from 'components/Invoices/PaidTag'
import InvoiceRegistrationTag from 'components/Invoices/RegistrationTag'

const ShipmentViewInvoicesPage = ({ isActive }) => {
  const { shipmentId } = useParams()
  const [searchTerm, setSearchTerm] = useState('')

  const fetchInvoices = async (shipmentId) => {
    try {
      const response = await getShipmentInvoices(shipmentId)
      return response?.data
    } catch (e) {
      throw e
    }
  }

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ['shipment', 'invoices', shipmentId],
    queryFn: () => fetchInvoices(shipmentId),
  })

  const invoiceType = useMemo(() => (isActive ? 'active' : 'passive'), [isActive])
  const invoices = useMemo(
    () =>
      data?.filter((invoice) =>
        isActive ? invoice?.active_passive === 0 : invoice?.active_passive === 1
      ),
    [data, isActive]
  )

  const handleRowClick = useCallback(
    (e, item) => {
      if (e.detail === 2) {
        window.open(
          `/panel/${invoiceType === 'active' ? 'active-invoices' : 'passive-invoices'}/${
            item.id_auto
          }`,
          '_blank'
        )
      }
    },
    [invoiceType]
  )

  const heads = useMemo(
    () => ({
      cells: [
        { key: 'invoice_no.', type: 'active/passive', content: 'Invoice no.' },
        { key: 'type', type: 'active/passive', content: 'Type' },
        { key: 'amount', type: 'active/passive', content: 'Amount' },
        { key: 'paid', type: 'active/passive', content: 'Paid' },
        {
          key: 'arca_status',
          type: 'active',
          content: 'Imported in ARCA',
        },
        { key: 'issue_date', type: 'active/passive', content: 'Issue date' },
        {
          key: 'passive_invoice_date',
          type: 'passive',
          content: 'Passive invoice date',
        },
        { key: 'actions', type: 'active/passive', content: 'Actions' },
      ].filter((column) => column.type.includes(invoiceType)),
    }),
    [invoiceType]
  )

  const rows = useMemo(
    () =>
      invoices
        ?.filter((i) =>
          i?.invoice_number?.toLocaleLowerCase()?.includes(searchTerm.toLocaleLowerCase())
        )
        ?.map((item, index) => ({
          key: item.id_auto,
          onClick: (e) => handleRowClick(e, item, index),
          cells: [
            {
              key: 'invoice_number',
              type: 'active/passive',
              content: item?.invoice_number,
            },
            {
              key: 'do_boolean',
              type: 'active/passive',
              content: item?.do_boolean ? (
                <Lozenge appearance="inprogress">Credit note</Lozenge>
              ) : (
                <Lozenge>Invoice</Lozenge>
              ),
            },
            {
              key: 'total_amount',
              type: 'active/passive',
              content: formatMonetaryNumber(item?.total_amount || 0),
            },
            {
              key: 'is_payd',
              type: 'active/passive',
              content: <InvoicePaidTag value={item?.is_payd} />,
            },
            {
              key: 'arca_status',
              type: 'active',
              content: (
                <InvoiceRegistrationTag
                  value={item?.arca_status}
                  elaborated={item?.elaborated}
                  error={item?.arca_error}
                />
              ),
            },
            {
              key: 'invoice_date',
              type: 'active/passive',
              content: item?.invoice_date,
            },
            {
              key: 'passive_invoice_date',
              type: 'passive',
              content: item?.passive_invoice_date,
            },
            {
              key: 'actions',
              type: 'active/passive',
              content: (
                <InvoiceRowActions invoice={item} type={invoiceType} shipmentId={shipmentId} />
              ),
            },
          ].filter((column) => column.type.includes(invoiceType)),
        })),
    [invoices, searchTerm, invoiceType, shipmentId, handleRowClick]
  )

  useEffect(() => {
    setSearchTerm('')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive])

  return (
    <>
      {isLoading ? (
        <div className="text-center mt-24">
          <Spinner />
        </div>
      ) : isError ? (
        <SectionMessage
          appearance="warning"
          title="Something wrong on loading invoices, please retry."
        >
          <Button onClick={() => refetch()}>Retry</Button>
        </SectionMessage>
      ) : (
        <>
          <div style={{ maxWidth: '240px' }}>
            <SearchInput onSubmit={setSearchTerm} delay={150} key={invoiceType} />
          </div>

          <Card className="mt-4">
            {rows?.length < 1 ? (
              <EmptyState header="No invoices issued." />
            ) : (
              <div className="overflow-auto inline-block w-full -mb-6">
                <DynamicTableStateless rows={rows} head={heads} />
              </div>
            )}
          </Card>
        </>
      )}
    </>
  )
}

export default ShipmentViewInvoicesPage
