import { findOption } from 'utils/helpers'
import { modeOptions } from './constants'

//Atlassian
import Lozenge from '@atlaskit/lozenge'

const lozengeType = (shipmentType, type) => {
  if (shipmentType === 0) {
    return type === 1 ? 'FTL' : 'LTL'
  } else if (shipmentType === 1) {
    return type === 1 ? 'FCL' : 'LCL'
  }
}

const ShipmentModeTag = ({ item }) => {
  const option = findOption(modeOptions, item?.shipment_type)

  return (
    <>
      <div>{option?.label}</div>
      {(item.shipment_type === 0 || item.shipment_type === 1) && (
        <div>
          <Lozenge appearance="default">
            {lozengeType(item.shipment_type, item.type_of_collies)}
          </Lozenge>
        </div>
      )}
    </>
  )
}

export default ShipmentModeTag
