import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

// Api
import { loadingConsolidationEntrance } from 'api/consolidations'

// Contexts
import { useApp } from 'contexts/AppProvider'

// Atlassian
import Button from '@atlaskit/button'

// Interfaces
import { WarningBanner, useFlags } from '@royan-co/user-interface'

//Components
import Html5QrcodePlugin from 'components/UI/Html5QrcodePlugin'

const getScannedIdFromScannedUrl = (href) => {
  const search = href.substring(href.indexOf('?'))
  const urlParams = new URLSearchParams(search)

  // possible parameters: id, e_id, tab, onlk, onlk2, number
  return urlParams.get('e_id')
}

const ConsolidationScanningEntranceCode = ({ entrances, onCancel, onSuccess }) => {
  const { consolidationId } = useParams()
  const [error, setError] = useState(null)
  const { loadingOverlay, setLoadingOverlay } = useApp()
  const { showSuccessFlag, showWarningFlag } = useFlags()

  const loadingColliesMutation = useMutation(
    loadingConsolidationEntrance.bind(null, consolidationId),
    {
      retry: 0,
    }
  )

  const handleScanning = (text) => {
    if (loadingOverlay) return

    const scannedId = getScannedIdFromScannedUrl(text)

    if (!entrances.some((e) => e.id === scannedId)) {
      showWarningFlag('This QR code does not belong to current consolidation.')
      onCancel()
      return
    }

    setLoadingOverlay(true)

    loadingColliesMutation.mutate(scannedId, {
      onSuccess: (res) => {
        onSuccess({
          load: res.loading.load,
          number: res.loading.number,
          entranceId: res.loading.shipment_entrance,
          consolidationId: res.loading.consolidation_id,
        })
        showSuccessFlag(res.message)
      },
      onError: (e) => {
        console.error(e)
        showWarningFlag(e?.response?.data?.message || 'Something wrong happened.')
        onCancel()
      },
      onSettled: () => {
        setLoadingOverlay(false)
      },
    })
  }

  const onNewScanResult = (decodedText, decodedResult) => {
    handleScanning(decodedText)
  }

  return (
    <>
      {!loadingOverlay ? (
        <Html5QrcodePlugin
          fps={10}
          qrbox={250}
          disableFlip={false}
          qrCodeSuccessCallback={onNewScanResult}
        />
      ) : null}

      {error && (
        <WarningBanner
          text={
            error instanceof DOMException && error.name === 'NotAllowedError'
              ? 'Please access to camera'
              : 'Something went wrong'
          }
        />
      )}

      {!loadingOverlay && (
        <div className="text-center mb-4">
          <Button appearance="subtle" onClick={() => onCancel()}>
            Cancel scanning
          </Button>
        </div>
      )}
    </>
  )
}

export default ConsolidationScanningEntranceCode
