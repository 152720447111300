import { createElement, useMemo, useState } from 'react'

//Atlassian
import Select from '@atlaskit/select'
import { Label } from '@atlaskit/form'

// Components
import FBLForm from './forms/FBLForm'
import CMRForm from './forms/CMRForm'
import AirwayBillForm from './forms/AirwayBill'
import SeawayBillForm from './forms/SeawayBill'

const documentTypes = [
  {
    key: 'airwayBill',
    title: 'airway Bill',
    formComponent: AirwayBillForm,
  },
  {
    key: 'seawayBill',
    title: 'Seaway Bill',
    formComponent: SeawayBillForm,
  },
  {
    key: 'fbl',
    title: 'FBL',
    formComponent: FBLForm,
  },
  {
    key: 'cmr',
    title: 'CMR',
    formComponent: CMRForm,
  },
]

const defaultValues = {
  airwayBill: {
    number1: '123',
  },
}

const DocumentsDemo = () => {
  const [selectedDocument, setSelectedDocument] = useState(null)

  const documentOptions = documentTypes.map((type) => ({ label: type.title, value: type.key }))

  const selectedDocumentComponent = useMemo(() => {
    const type = documentTypes.find((type) => type.key === selectedDocument)

    if (!type) return null

    return createElement(type.formComponent, {
      defaultValues: defaultValues[type.key],
      onChange: (data) => console.log(data),
    })
  }, [selectedDocument])

  return (
    <>
      <div className="max-w-xs">
        <Label>Select for preview</Label>
        <Select
          isClearable={true}
          options={documentOptions}
          placeholder="Choose a document type"
          onChange={(v) => setSelectedDocument(v?.value)}
        />
      </div>

      {selectedDocumentComponent && (
        <div className="mt-4">
          <Label>Preview:</Label>

          <div className="mt-2">{selectedDocumentComponent}</div>
        </div>
      )}
    </>
  )
}

export default DocumentsDemo
