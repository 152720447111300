import { v4 as uuidv4 } from 'uuid'

// Utils
import { getFilled } from 'utils/helpers'
import { legTypes } from './legTypes'

export const createFormValues = (currentLegData) => {
  if (!currentLegData) return null

  const currentLegType = legTypes.find((t) => t.value === currentLegData?.leg)
  const data = { ...currentLegData, _id: uuidv4() }

  const makeDefaultValues = !currentLegData?._id

  data.suppliers = (data.suppliers || []).map((supplier) => ({
    _id: supplier.id_auto || uuidv4(),
    supplier: { value: supplier?.supplier_id },
    name: getFilled('supplier', 'parent.service', ''),
    service_code: getFilled('supplier', 'parent.service_code', ''),
    ...supplier,
    rate: supplier?.rate?.toString() || '',
    quantity: supplier?.quantity?.toString() || '',
    cost: supplier?.cost?.toString() || '',
    quotation: supplier?.quotation?.toString() || '',
    vat: supplier?.vat?.toString() || '',
    surcharges: supplier?.surcharges?.map((surcharge) => ({
      ...surcharge,
      rate: surcharge?.rate?.toString() || '',
      quantity: surcharge?.quantity?.toString() || '',
      cost: surcharge?.cost?.toString() || '',
      quotation: surcharge?.quotation?.toString() || '',
      vat: surcharge?.vat?.toString() || '',
    })),
  }))

  data.accessories = (data.accessories || []).map((accessory) => ({
    _id: accessory.id || uuidv4(),
    ...accessory,
    rate: accessory?.rate?.toString() || '',
    quantity: accessory?.quantity?.toString() || '',
    cost: accessory?.cost?.toString() || '',
    quotation: accessory?.quotation?.toString() || '',
    vat: accessory?.vat?.toString() || '',
  }))

  if (currentLegType) {
    if (currentLegType.departure.departureKey === 'departure_zone') {
      data.departure_zone =
        !!data?.departure_zone || !makeDefaultValues
          ? data?.departure_zone
          : {
              lng: getFilled(data, `suppliers.0.parent.departure_lng`),
              lat: getFilled(data, `suppliers.0.parent.departure_lat`),
              city: getFilled(data, `suppliers.0.parent.departure_city`),
              zipCode: getFilled(data, `suppliers.0.parent.departure_cap`),
              countryAbbr: getFilled(data, `suppliers.0.parent.departure_country`),
              label: [
                data?.suppliers?.[0]?.parent?.departure_city,
                data?.suppliers?.[0]?.parent?.departure_country,
                data?.suppliers?.[0]?.parent?.departure_cap,
              ]
                .filter((i) => i)
                .join(', '),
            }
    } else
      data[currentLegType.departure.departureKey] =
        !!data[currentLegType.departure.departureKey] || !makeDefaultValues
          ? data[currentLegType.departure.departureKey]
          : getFilled(data, `suppliers.0.parent.${currentLegType.departure.departureKey}`)

    if (currentLegType.arrival.arrivalKey === 'arrival_zone') {
      data.arrival_zone =
        !!data?.arrival_zone || !makeDefaultValues
          ? data?.arrival_zone
          : {
              lng: getFilled(data, `suppliers.0.parent.arrival_lng`),
              lat: getFilled(data, `suppliers.0.parent.arrival_lat`),
              city: getFilled(data, `suppliers.0.parent.arrival_city`),
              zipCode: getFilled(data, `suppliers.0.parent.arrival_cap`),
              countryAbbr: getFilled(data, `suppliers.0.parent.arrival_country`),
              label: [
                data?.suppliers?.[0]?.parent?.arrival_city,
                data?.suppliers?.[0]?.parent?.arrival_country,
                data?.suppliers?.[0]?.parent?.arrival_cap,
              ]
                .filter((i) => i)
                .join(', '),
            }
    } else
      data[currentLegType.arrival.arrivalKey] =
        !!data[currentLegType.arrival.arrivalKey] || !makeDefaultValues
          ? data[currentLegType.arrival.arrivalKey]
          : getFilled(data, `suppliers.0.parent.${currentLegType.arrival.arrivalKey}`)
  }

  return data
}
