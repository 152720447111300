import React from 'react'

// Atlassian
import Button from '@atlaskit/button'
import Spinner from '@atlaskit/spinner'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'

// Interfaces
import { useFlags } from '@royan-co/user-interface'

const Export = ({ type, params, mutation }) => {
  const { showSuccessFlag, showWarningFlag } = useFlags()
  const exportMutation = mutation(type, params)

  const handleExport = () => {
    exportMutation.mutate('', {
      onSuccess: (res) => {
        showSuccessFlag(res.message)
      },
      onError: () => {
        showWarningFlag('Something is wrong with exporting data, please contact the administrator.')
      },
    })
  }

  return (
    <DropdownMenu
      isOpen={exportMutation.isLoading || undefined}
      placement="bottom-start"
      trigger={({ triggerRef, ...props }) => (
        <Button {...props} ref={triggerRef} appearance="primary">
          Export
        </Button>
      )}
    >
      <DropdownItemGroup>
        <div style={{ minWidth: '130px' }}>
          <DropdownItem
            isDisabled={exportMutation.isLoading}
            onClick={handleExport}
            children={
              <div className="flex items-center justify-between">
                As xlsx
                {exportMutation.isLoading && (
                  <div className="ml-2">
                    <Spinner size="small" />
                  </div>
                )}
              </div>
            }
          />
        </div>
      </DropdownItemGroup>
    </DropdownMenu>
  )
}

export default Export
