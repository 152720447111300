import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth } from 'contexts/AuthProvider'

const allowedRedirectTos = ['login', 'register', 'forget-password']

const RedirectToAccount = ({ redirectTo = 'login' }) => {
  const { isAuthenticated } = useAuth()

  let from = window.location.href

  if (allowedRedirectTos.includes(window.location.pathname.split('/')[1])) {
    from = window.location.origin
  }

  useEffect(() => {
    if (!isAuthenticated) {
      window.location.href = `${
        process.env.REACT_APP_ACCOUNT_URL
      }/${redirectTo}?from=${encodeURIComponent(from)}`
    }
  }, [isAuthenticated, redirectTo, from])

  return isAuthenticated ? <Navigate to={'/panel'} /> : <>Redirecting...</>
}

RedirectToAccount.propTypes = {
  redirectTo: PropTypes.oneOf(allowedRedirectTos),
}

export default RedirectToAccount
