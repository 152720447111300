import { useMemo } from 'react'

// Atlassian
import Button from '@atlaskit/button'
import { Checkbox } from '@atlaskit/checkbox'
import { DynamicTableStateless } from '@atlaskit/dynamic-table'
import EmptyState from '@atlaskit/empty-state'
import Modal, { ModalTitle, ModalHeader, ModalTransition } from '@atlaskit/modal-dialog'
import Spinner from '@atlaskit/spinner'
import SectionMessage from '@atlaskit/section-message'
import { Field, Form } from '@royan-co/user-interface'

//Styles
import styles from './Attachments.module.scss'

const DocumentsListModal = ({
  isOpen,
  onClose,
  onSubmit,
  list = [],
  isLoading,
  isError,
  refetch,
}) => {
  const addSelectedFiles = (files) => {
    onSubmit(files.rows.filter((f) => f.checked))
  }

  const tableHead = {
    cells: [
      { key: 'checkbox', content: '' },
      { key: 'title', content: 'Title' },
      { key: 'creation_date', content: 'Creation date' },
    ],
  }

  const tableRows = useMemo(() => {
    return list.map((d, i) => ({
      key: d.id,
      cells: [
        {
          content: (
            <Field type="checkbox" name={`rows.${i}.checked`} className="!mt-0 !cursor-pointer">
              {({ fieldProps }) => <Checkbox {...fieldProps} />}
            </Field>
          ),
        },
        {
          content: d.title,
        },
        {
          content: d.creation_date || '-',
        },
      ],
    }))
  }, [list])

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={onClose} width="medium" shouldScrollInViewport>
          <ModalHeader>
            <ModalTitle>Upload document</ModalTitle>
          </ModalHeader>

          <div className={styles.uploadForm}>
            {isLoading ? (
              <div className={styles.uploadForm__spinner}>
                <Spinner />
              </div>
            ) : isError ? (
              <SectionMessage
                appearance="warning"
                title="Something wrong on loading data, please retry."
              >
                <Button onClick={() => refetch?.()}>Retry</Button>
              </SectionMessage>
            ) : (
              <Form onSubmit={addSelectedFiles} defaultValues={{ rows: list }}>
                {() => (
                  <>
                    {tableRows.length === 0 ? (
                      <EmptyState header="No documents" />
                    ) : (
                      <div className={styles.uploadForm__table}>
                        <DynamicTableStateless head={tableHead} rows={tableRows} />
                      </div>
                    )}

                    <div className={styles.uploadForm__buttons}>
                      <Button appearance="subtle" type="button" onClick={onClose}>
                        Cancel
                      </Button>
                      <Button
                        appearance="primary"
                        type="submit"
                        isDisabled={tableRows.length === 0}
                      >
                        Insert
                      </Button>
                    </div>
                  </>
                )}
              </Form>
            )}
          </div>
        </Modal>
      )}
    </ModalTransition>
  )
}

export default DocumentsListModal
