import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'

// Apis
import { getRegistryContacts } from 'api/registries'

// Utils
import { findOption, transformSelectField } from 'utils/helpers'

// Atlassian
import Select from '@atlaskit/select'
import { ErrorMessage } from '@atlaskit/form'

// Interfaces
import { Field } from '@royan-co/user-interface'

const RegistrySelectContactField = ({
  name,
  label,
  registryId,
  className,
  isMulti = false,
  ...props
}) => {
  const { isLoading: contactsIsLoading, data: contacts } = useQuery(
    ['contact', registryId],
    () => getRegistryContacts(registryId),
    {
      staleTime: Infinity,
      retry: 2,
      enabled: !!registryId,
    }
  )

  const registryContactsOptions = useMemo(() => {
    if (!contacts) return []

    return contacts.map((contact) => ({
      label: contact.full_name,
      value: contact.id_auto,
    }))
  }, [contacts])

  return (
    <>
      <Field
        type="select"
        name={name}
        label={label}
        className={className}
        transform={
          !isMulti
            ? transformSelectField(registryContactsOptions)
            : {
                input: (v) => v?.map((o) => findOption(registryContactsOptions, o)),
                output: (opt) => opt.map((o) => o.value),
              }
        }
      >
        {({ fieldProps, error }) => (
          <>
            <Select
              isMulti={isMulti}
              options={registryContactsOptions}
              isLoading={contactsIsLoading}
              {...fieldProps}
              {...props}
            />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </>
        )}
      </Field>
    </>
  )
}

export default RegistrySelectContactField
