import { useMemo } from 'react'

// Atlassian
import Modal, {
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import Select from '@atlaskit/select'
import { ErrorMessage } from '@atlaskit/form'
import Button from '@atlaskit/button'

// Interface
import { Form, Field, WarningBannerField } from '@royan-co/user-interface'

// Utils
import { transformSelectField } from 'utils/helpers'
import { handleErrorOnSubmit } from 'utils/errors'

// Components
import { legTypes } from 'components/Shipments/EditFinance/legTypes'
import { nodeTypes } from 'components/Shipments/EditFinance/nodeTypes'

const LineRouteLegModal = ({ isOpen, onClose, onSubmit, leg, hasLegs }) => {
  const isEditing = useMemo(() => leg?.index !== undefined, [leg?.index])
  const options = useMemo(() => (hasLegs ? nodeTypes : legTypes), [hasLegs])

  const handleCloseModal = () => {
    onClose()
  }

  const handleEntity = (data, { setError }) => {
    try {
      onSubmit(isEditing ? { ...data, ...leg } : data)
    } catch (error) {
      handleErrorOnSubmit(error, setError, data)
    }
  }

  return (
    <>
      <ModalTransition>
        {isOpen && (
          <Modal onClose={handleCloseModal} width="medium">
            <ModalHeader>
              <ModalTitle>{!isEditing ? 'New leg' : 'Edit'}</ModalTitle>
            </ModalHeader>

            <Form onSubmit={handleEntity}>
              {() => (
                <>
                  <div className="px-6">
                    <Field
                      isRequired
                      label="Type"
                      name={hasLegs ? 'node' : 'type'}
                      transform={transformSelectField(options)}
                      className="!mt-0"
                    >
                      {({ fieldProps, error }) => (
                        <>
                          <Select options={options} {...fieldProps} />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>

                    <WarningBannerField />
                  </div>

                  <ModalFooter>
                    <Button type="button" appearance="subtle" onClick={handleCloseModal}>
                      Cancel
                    </Button>
                    <Button type="submit" appearance="primary">
                      {!isEditing ? 'Add' : 'Update'}
                    </Button>
                  </ModalFooter>
                </>
              )}
            </Form>
          </Modal>
        )}
      </ModalTransition>
    </>
  )
}

export default LineRouteLegModal
