import { useState } from 'react'

// Utils
import { findOption } from 'utils/helpers'

// Atlaskit
import Lozenge from '@atlaskit/lozenge'
import InlineEdit from '@atlaskit/inline-edit'
import Select from '@atlaskit/select'
import Spinner from '@atlaskit/spinner'

// Interfaces
import { useFlags } from '@royan-co/user-interface'

// Components
import { termsOfPaymentOptions } from './constants'

const ShipmentTermsOfPaymentInlineEdit = ({
  term,
  canEdit,
  changePaymentTermFn,
  changePaymentTermCallback,
}) => {
  const { showSuccessFlag, showWarningFlag } = useFlags()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleChangeTerm = async (data) => {
    if (term === data?.value) return

    try {
      setIsSubmitting(true)
      const res = await changePaymentTermFn(data)

      showSuccessFlag(res.message)
      changePaymentTermCallback(res)
    } catch (e) {
      console.error(e)
      showWarningFlag(e?.response?.data?.message || 'Something went wrong.')
    } finally {
      setIsSubmitting(false)
    }
  }

  const viewTag =
    term !== null ? (
      <>
        {term === 0 ? (
          <Lozenge appearance="inprogress">Collect</Lozenge>
        ) : (
          <Lozenge appearance="inprogress">Prepaid</Lozenge>
        )}
      </>
    ) : (
      <Lozenge appearance="default">Unknown</Lozenge>
    )

  return !canEdit ? (
    viewTag
  ) : (
    <div className="!-mt-2">
      <InlineEdit
        keepEditViewOpenOnBlur={true}
        defaultValue={findOption(termsOfPaymentOptions, term)}
        editView={({ errorMessage, ...fieldProps }) => (
          <Select spacing="compact" options={termsOfPaymentOptions} {...fieldProps} />
        )}
        readView={() => (
          <div className="flex items-center font-normal gap-1 my-1.5">
            {viewTag}
            {isSubmitting && <Spinner size="small" />}
          </div>
        )}
        onConfirm={(value) => handleChangeTerm(value)}
      />
    </div>
  )
}

export default ShipmentTermsOfPaymentInlineEdit
