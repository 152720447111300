import { Helmet } from 'react-helmet-async'
import { Link, useNavigate } from 'react-router-dom'

// Utils
import { handleErrorOnSubmit } from 'utils/errors'
import { transformSelectField } from 'utils/helpers'

// Hooks
import { useCreateAirWaybillStock } from '../api/useCreateAirWaybillStock'
import useRegistryAirlines from 'hooks/useRegistryAirlines'

// Atlassian
import PageHeader from '@atlaskit/page-header'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'
import Select from '@atlaskit/select'
import Textfield from '@atlaskit/textfield'
import { LoadingButton } from '@atlaskit/button'
import { ErrorMessage } from '@atlaskit/form'
import { DatePicker } from '@atlaskit/datetime-picker'

// Interfaces
import { Grid, Form, Field, WarningBannerField } from '@royan-co/user-interface'

// Components
import Card from 'components/UI/Card'

const pageTitle = 'New AWB stock'

const AirWaybillStockCreationPage = () => {
  const navigate = useNavigate()
  const { registryAirlineOptions, isLoadingRegistryAirlines } = useRegistryAirlines()

  const createMutation = useCreateAirWaybillStock()
  const onSubmitCreation = (data, { setError }) => {
    createMutation.mutate(data, {
      onError: (e) => {
        handleErrorOnSubmit(e, setError, data)
      },
      onSuccess: (res) => {
        navigate('/panel/airwaybill-stock', { replace: true })
      },
    })
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <Grid gutter={24} className="w-full">
        <Grid.Col md={10} lg={8} xl={6} offsetMd={1} offsetLg={2} offsetXl={3}>
          <PageHeader
            breadcrumbs={
              <Breadcrumbs>
                <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
                <BreadcrumbsItem text="Operation" />
                <BreadcrumbsItem text="AWB stock" to="/panel/airwaybill-stock" component={Link} />
              </Breadcrumbs>
            }
          >
            {pageTitle}
          </PageHeader>

          <div className="my-8">
            <Form onSubmit={onSubmitCreation} defaultValue={{}}>
              {() => (
                <>
                  <Card className="mt-0">
                    <Field
                      isRequired
                      label="Airline"
                      type="select"
                      name="airline"
                      className="!mt-4"
                      transform={transformSelectField(registryAirlineOptions)}
                    >
                      {({ fieldProps }) => (
                        <Select
                          isClearable={true}
                          isLoading={isLoadingRegistryAirlines}
                          options={registryAirlineOptions}
                          placeholder="Choose an airline"
                          {...fieldProps}
                        />
                      )}
                    </Field>

                    <div className="grid md:grid-cols-2 md:gap-4">
                      <Field
                        isRequired
                        label="Start date"
                        name="start_date"
                        className="!mt-4"
                        defaultValue=""
                      >
                        {({ fieldProps, error }) => (
                          <>
                            <DatePicker placeholder=" " {...fieldProps} />
                            {error && <ErrorMessage>{error}</ErrorMessage>}
                          </>
                        )}
                      </Field>

                      <Field
                        isRequired
                        label="End date"
                        name="end_date"
                        className="!mt-4"
                        defaultValue=""
                      >
                        {({ fieldProps, error }) => (
                          <>
                            <DatePicker placeholder=" " {...fieldProps} />
                            {error && <ErrorMessage>{error}</ErrorMessage>}
                          </>
                        )}
                      </Field>
                    </div>

                    <div className="grid md:grid-cols-2 md:gap-4">
                      <Field
                        isRequired
                        label="Starting number"
                        name="from"
                        defaultValue=""
                        className="!mt-4"
                        validate={(d) => (d * 1 >= 0 ? true : false)}
                      >
                        {({ fieldProps, error }) => (
                          <>
                            <Textfield
                              {...fieldProps}
                              placeholder="Enter a number"
                              maxLength={7}
                              minLength={7}
                            />
                            {error && <ErrorMessage>{error}</ErrorMessage>}
                          </>
                        )}
                      </Field>

                      <Field
                        isRequired
                        label="Ending number"
                        name="to"
                        defaultValue=""
                        className="!mt-4"
                        validate={(d) => (d * 1 >= 0 ? true : false)}
                      >
                        {({ fieldProps, error }) => (
                          <>
                            <Textfield
                              {...fieldProps}
                              placeholder="Enter a number"
                              maxLength={7}
                              minLength={7}
                            />
                            {error && <ErrorMessage>{error}</ErrorMessage>}
                          </>
                        )}
                      </Field>
                    </div>
                  </Card>

                  <WarningBannerField />

                  <LoadingButton
                    className="!table ml-auto mt-6"
                    appearance="primary"
                    type="submit"
                    isLoading={createMutation.isLoading}
                  >
                    Create
                  </LoadingButton>
                </>
              )}
            </Form>
          </div>
        </Grid.Col>
      </Grid>
    </>
  )
}

export default AirWaybillStockCreationPage
