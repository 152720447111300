import { useMemo } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'

// APis
import { editShipmentParam } from 'api/shipments'

// Hooks
import useAirportsAutocomplete from 'hooks/useAirportsAutocomplete'

// Atlassian
import Select from '@atlaskit/select'
import Spinner from '@atlaskit/spinner'
import Lozenge from '@atlaskit/lozenge'
import InlineEdit from '@atlaskit/inline-edit'

// Interfaces
import { useFlags } from '@royan-co/user-interface'

const ShipmentAirportInlineEdit = ({ airport, canEdit, attribute, shipmentId }) => {
  const { showSuccessFlag, showWarningFlag } = useFlags()
  const queryClient = useQueryClient()

  const {
    airportsAutocompleteOptions,
    setTermAirportsAutocomplete,
    isLoadingAirportsAutocomplete,
  } = useAirportsAutocomplete()

  const options = useMemo(() => {
    if (!airport) return airportsAutocompleteOptions

    return airportsAutocompleteOptions
      .filter((i) => i.value !== airport)
      .concat([{ label: airport, value: airport }])
  }, [airport, airportsAutocompleteOptions])

  const changeAirportMutation = useMutation({
    mutationFn: (data) => editShipmentParam(shipmentId, attribute, { [attribute]: data }),

    onSuccess: (res) => {
      showSuccessFlag(res.message)
      queryClient.setQueryData(['shipment', shipmentId], (oldShipment) => ({
        ...oldShipment,
        [attribute]: res.shipment[attribute],
      }))
    },

    onError: (e) => {
      console.error(e)
      showWarningFlag(e?.response?.data?.message || 'Something wrong happened.')
    },
  })

  const handleChangeAirport = (data) => {
    if (airport === data?.value) return

    changeAirportMutation.mutate(data?.value)
  }

  const viewTag = airport || <Lozenge appearance="default">Not set</Lozenge>

  return !canEdit ? (
    viewTag
  ) : (
    <div className="!-mt-2">
      <InlineEdit
        keepEditViewOpenOnBlur={true}
        defaultValue={airport || null}
        editView={({ errorMessage, ...fieldProps }) => (
          <Select
            {...fieldProps}
            isClearable={true}
            value={fieldProps.value || null}
            placeholder="Choose one"
            options={options}
            isLoading={isLoadingAirportsAutocomplete}
            onInputChange={(newValue) => {
              setTermAirportsAutocomplete(newValue)
            }}
          />
        )}
        readView={() => (
          <div className="flex items-center font-normal gap-1 my-1.5">
            {viewTag}
            {changeAirportMutation.isLoading && <Spinner size="small" />}
          </div>
        )}
        onConfirm={(value) => handleChangeAirport(value)}
      />
    </div>
  )
}

export default ShipmentAirportInlineEdit
