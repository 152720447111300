import { useState, useEffect, useCallback, useRef } from 'react'
import axios from 'axios'

import { getAirportsAutocomplete } from 'api/shipments'

//Interfaces
import { useDebounce } from '@royan-co/user-interface'

const useAirportsAutocomplete = ({ country } = {}) => {
  const controller = useRef()
  const [airportsAutocompleteOptions, setAirportsAutocompleteOptions] = useState([])
  const [term, setTerm] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const debouncedTerm = useDebounce(term)

  const getOptions = useCallback(async () => {
    try {
      setIsLoading(true)
      if (controller.current) {
        controller.current.abort()
      }

      controller.current = new AbortController()
      const data = await getAirportsAutocomplete(debouncedTerm, country, controller.current)
      const _options = data.map((item) => ({
        label: item,
        value: item,
      }))

      setAirportsAutocompleteOptions(_options)
      setIsLoading(false)
    } catch (error) {
      if (!axios.isCancel(error)) {
        setIsLoading(false)
        console.error(error)
      }
    }
  }, [country, debouncedTerm])

  useEffect(() => {
    if (debouncedTerm) getOptions()
  }, [debouncedTerm, getOptions])

  return {
    airportsAutocompleteOptions,
    setTermAirportsAutocomplete: setTerm,
    isLoadingAirportsAutocomplete: isLoading,
  }
}

export default useAirportsAutocomplete
