import React, { useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { useQueryClient } from '@tanstack/react-query'
import { Link, useLocation, useParams, Outlet, useNavigate } from 'react-router-dom'

// Atlassian
import Spinner from '@atlaskit/spinner'
import PageHeader from '@atlaskit/page-header'
import Tabs, { Tab, TabList } from '@atlaskit/tabs'
import Button, { ButtonGroup } from '@atlaskit/button'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'

// Interfaces
import {
  Grid,
  Skeleton,
  useFlags,
  DotsIcon,
  useDisclosure,
  useRoyanTableLastFilters,
} from '@royan-co/user-interface'

// Components
import useRegistry from 'hooks/useRegistry'
import RegistryCard from 'components/Registries/View/RegistryCard'
import RegistryCreatorCard from 'components/Registries/View/RegistryCreatorCard'
import RegistryDeleteModal from 'components/Registries/View/DeleteModal'
import DropdownLink from 'components/UI/DropdownLink'
import DisabledElementTooltip from 'components/UI/DisabledElementTooltip'
import RegistryDeactivateModal from 'components/Registries/View/DeactivateModal'
import RegistryDeactivateAction from 'components/Registries/View/DeactivateAction'

// Features
import { ImportToARCAAction, RegistriesFinancialBalanceActions } from 'features/registries'

const tabData = [
  { title: 'General information', path: '' },
  { title: 'Financial details', path: '/financial' },
  { title: 'Financial balance', path: '/financial-balance' },
  { title: 'Quotes', path: '/quotes' },
  { title: 'Shipments', path: '/shipments' },
  { title: 'History', path: '/history' },
  { title: 'Documents', path: '/documents' },
]

const RegistryViewPage = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { showWarningFlag } = useFlags()
  const { state, pathname } = useLocation()
  let { registryId, creatorId } = useParams()
  const { lastUrlSearch } = useRoyanTableLastFilters('registries-list')
  const [isOpenDeleteRegistryModal, deleteRegistryModalHandler] = useDisclosure('delete-registry')
  const [isOpenDeactivateRegistryModal, deactivateRegistryModalHandler] =
    useDisclosure('deactivate-registry')

  const tabs = useMemo(
    () =>
      tabData.map((t) => ({
        ...t,
        path: `/panel/registries/${registryId}${creatorId ? `/${creatorId}` : ''}${t.path}`,
      })),
    [creatorId, registryId]
  )

  const selectedTabIndex = useMemo(
    () => tabs.findIndex((t) => t.path === pathname),
    [pathname, tabs]
  )

  const { registry, error, isFetching, isLoading } = useRegistry(registryId, {
    placeholderData: state,
  })

  const navigateToTab = (index) => {
    const tab = tabs[index]

    navigate(tab.path)
  }

  const tabAction = useMemo(() => {
    if (selectedTabIndex === 2) {
      //Financial balance
      return <RegistriesFinancialBalanceActions registry={registry} />
    } else return null
  }, [registry, selectedTabIndex])

  useEffect(() => {
    if (error?.response?.status === 404) {
      queryClient.cancelQueries({ queryKey: ['registry', registryId], exact: false })
      showWarningFlag('Registry is not found!')
      setTimeout(() => navigate('/panel/registries', { replace: true }), 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  return (
    <>
      <Helmet>
        <title>{registry?.company_name}</title>
      </Helmet>

      <PageHeader
        breadcrumbs={
          <Breadcrumbs>
            <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
            <BreadcrumbsItem text="Companies" />
            <BreadcrumbsItem text="Registries" to="/panel/registries" component={Link} />
          </Breadcrumbs>
        }
        actions={
          <ButtonGroup>
            {tabAction}

            <Button
              component={Link}
              appearance="default"
              to={`/panel/registries${lastUrlSearch ? `?${lastUrlSearch}` : ''}`}
            >
              Back
            </Button>

            <DropdownMenu
              placement="bottom-end"
              trigger={({ triggerRef, ...props }) => (
                <Button {...props} children={<DotsIcon label="more" />} ref={triggerRef} />
              )}
            >
              <DropdownItemGroup>
                <DropdownLink
                  children="Edit"
                  to={`/panel/registries/${registryId}${creatorId ? `/${creatorId}` : ''}/edit`}
                />

                <ImportToARCAAction registry={registry} />

                <RegistryDeactivateAction
                  deactivated={registry?.deactivated}
                  onClick={() =>
                    deactivateRegistryModalHandler.open({
                      deactivated: registry?.deactivated,
                      registryId: registry?.id_auto,
                    })
                  }
                />

                <DisabledElementTooltip
                  isDisabled={!registry?.deletable}
                  tooltipContent="Registry can not be deleted"
                >
                  <DropdownItem
                    children="Delete"
                    onClick={() => deleteRegistryModalHandler.open(registryId)}
                    isDisabled={!registry?.deletable}
                  />
                </DisabledElementTooltip>
              </DropdownItemGroup>
            </DropdownMenu>
          </ButtonGroup>
        }
      >
        {isLoading ? (
          <Skeleton height={32} maw={320} />
        ) : (
          <div className="flex items-center">
            {registry?.company_name}
            {isFetching && (
              <span className="ml-3 text-xs font-light text-neutral-50">updating...</span>
            )}
          </div>
        )}
      </PageHeader>

      <Grid gutter="xl">
        <Grid.Col md={3}>
          <RegistryCard />

          <RegistryCreatorCard className="mt-6" />
        </Grid.Col>

        <Grid.Col md={9}>
          <Tabs onChange={navigateToTab} selected={selectedTabIndex}>
            <TabList>
              {tabs.map((tab, i) => (
                <Tab key={i} on>
                  {tab.title}
                </Tab>
              ))}
            </TabList>
          </Tabs>

          <div className="px-2 py-4">
            {isLoading ? (
              <div className="flex justify-center my-32">
                <Spinner />
              </div>
            ) : (
              <Outlet />
            )}
          </div>
        </Grid.Col>
      </Grid>

      <RegistryDeleteModal
        isOpen={isOpenDeleteRegistryModal}
        onClose={deleteRegistryModalHandler.close}
        rateId={deleteRegistryModalHandler.data}
      />

      <RegistryDeactivateModal
        isOpen={isOpenDeactivateRegistryModal}
        onClose={deactivateRegistryModalHandler.close}
        registryId={deactivateRegistryModalHandler.data?.registryId}
        deactivated={deactivateRegistryModalHandler.data?.deactivated}
      />
    </>
  )
}

export default RegistryViewPage
