import { useNavigate } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'

// Interfaces
import { useRoyanTable } from '@royan-co/user-interface'

// Apis
import { deleteMarginCalculator } from './margin-calculator-requests'

const useDeleteMarginCalculator = (marginId) => {
  const { refresh } = useRoyanTable()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  return useMutation({
    mutationFn: () => deleteMarginCalculator(marginId),
    onSuccess: (res) => {
      queryClient.removeQueries({
        queryKey: ['margin-calculator', +marginId],
      })

      if (refresh) refresh()
      else navigate('/panel/margin-calculator')
    },
    onError: (e) => {
      console.error(e)
    },
  })
}

export { useDeleteMarginCalculator }
