import { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

// Apis
import { createShipmentCargoDetails, editShipmentCargoDetails } from 'api/shipments'

// Hooks
import useShipment from 'hooks/useShipment'
import useShipmentCargo from 'hooks/useShipmentCargo'

// Utils
import { handleErrorOnSubmit } from 'utils/errors'
import { formatNumber } from 'utils/numbers'

// Atlassian
import EmptyState from '@atlaskit/empty-state'
import Button from '@atlaskit/button'
import Spinner from '@atlaskit/spinner'
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down'
import ChevronRightIcon from '@atlaskit/icon/glyph/chevron-right'
import { DynamicTableStateless } from '@atlaskit/dynamic-table'

// Interfaces
import { EditIcon, TrashIcon, useDisclosure, useFlags } from '@royan-co/user-interface'

// Components
import Card, { CardTitle } from 'components/UI/Card'
import TemperatureTag from 'components/Shipments/TemperatureTag'
import TruckDrawer from './TruckDrawer'
import DeleteCargoModal from './DeleteCargoModal'

const ShipmentViewCargoTrucks = ({ canCreateCargo, canEditCargo, canDeleteCargo }) => {
  const { shipment } = useShipment()
  const { shipmentId, quotationId } = useParams()
  const { showSuccessFlag } = useFlags()

  const entityId = shipmentId || quotationId
  const [visibleDetails, setVisibleDetails] = useState([])

  const { shipmentCargo, isLoadingShipmentCargo, upsertCargoToData } = useShipmentCargo(entityId)

  const [isOpenTruckDrawer, truckDrawerHandlers] = useDisclosure('truck')
  const [isOpenDeleteTruckModal, deleteTruckModalHandlers] = useDisclosure('delete-truck')

  const createCargoTruckMutation = useMutation(
    !truckDrawerHandlers?.data?.box_type
      ? createShipmentCargoDetails.bind(null, entityId)
      : editShipmentCargoDetails.bind(null, truckDrawerHandlers?.data?.id)
  )

  const onSubmitCreateTruck = (data, { setError }) => {
    const formData = {
      ...data,
      // I know, this is weird, we use label for truck an container types
      box_type: data.box_type?.label,
      reefer: data?.reefer ? 1 : 0,
      reefer_temperature: data?.reefer_temperature?.toString(),
    }
    delete formData.range

    createCargoTruckMutation.mutate(formData, {
      onError: (e) => {
        handleErrorOnSubmit(e, setError, data)
        console.error(e)
      },
      onSuccess: (res) => {
        upsertCargoToData(res.cargo)
        truckDrawerHandlers.close()
        showSuccessFlag(res?.message)
      },
    })
  }

  const isContainer = useMemo(() => shipment?.shipment_type === 1, [shipment])

  const name = useMemo(
    () =>
      shipment.shipment_type === 0
        ? 'truck'
        : shipment?.shipment_type === 1
        ? 'container'
        : shipment?.shipment_type,
    [shipment]
  )

  const trucksHead = useMemo(() => {
    let _trucksHead = [
      { key: 'type', content: 'Type' },
      { key: 'quantity', content: 'Quantity' },
    ]
    if (isContainer && shipmentId)
      _trucksHead.push(
        { key: 'collies', content: 'Collies' },
        { key: 'gross_weight', content: 'Gross weight' },
        { key: 'net_weight', content: 'Net weight' }
      )
    return {
      cells: [
        ..._trucksHead,
        { key: 'temperature', content: 'Temperature control' },
        { key: 'Actions', content: '' },
      ],
    }
  }, [isContainer, shipmentId])

  const handleRowClick = useCallback(
    (rowId) => {
      // Check if the clicked row's details are already visible
      const isVisible = visibleDetails.includes(rowId)

      // Toggle the visibility of the clicked row's details
      const updatedVisibleDetails = isVisible
        ? visibleDetails.filter((id) => id !== rowId)
        : [...visibleDetails, rowId]

      // Update the state with the new visible details
      setVisibleDetails(updatedVisibleDetails)
    },
    [visibleDetails]
  )

  const trucks = useMemo(() => {
    let _trucks = []

    shipmentCargo?.forEach((item, i) => {
      const itemVisibleDetails = visibleDetails.includes(item?.id)

      let mainRows = [
        {
          content:
            isContainer && shipmentId ? (
              <div className="flex items-center">
                {item?.details?.length ? (
                  <button
                    children={itemVisibleDetails ? <ChevronDownIcon /> : <ChevronRightIcon />}
                    className="!px-1 text-neutral-200"
                    style={{minWidth: '32px'}}
                  />
                ) : null}
                {item.box_type}
              </div>
            ) : (
              item.box_type
            ),
        },
        {
          content: item?.quantity || 1,
        },
      ]

      if (isContainer && shipmentId)
        mainRows.push(
          {
            content: formatNumber(
              item?.details?.reduce((p, c) => p + 1 * (c?.total_collies || 0), 0)
            ),
          },
          {
            content: formatNumber(
              item?.details?.reduce((p, c) => p + 1 * (c?.gross_weight || 0), 0)
            ),
          },
          {
            content: formatNumber(item?.details?.reduce((p, c) => p + 1 * (c?.net_weight || 0), 0)),
          }
        )

      _trucks.push({
        onClick:
          isContainer && shipmentId && item?.details?.length
            ? () => handleRowClick(item?.id)
            : undefined,
        cells: [
          ...mainRows,
          {
            content: <TemperatureTag reefer={item.reefer} temperature={item.reefer_temperature} />,
          },
          {
            content: (
              <div className="flex justify-end my-2">
                {canEditCargo && (
                  <Button
                    spacing="compact"
                    appearance="subtle"
                    iconBefore={<EditIcon />}
                    onClick={() => truckDrawerHandlers.open({ ...item })}
                  />
                )}

                {canDeleteCargo && (
                  <Button
                    spacing="compact"
                    appearance="subtle"
                    onClick={() => deleteTruckModalHandlers.open(item.id)}
                    iconBefore={<TrashIcon className="text-red-400" />}
                  />
                )}
              </div>
            ),
          },
        ],
      })

      if (isContainer && shipmentId && item?.details?.length && itemVisibleDetails) {
        item.details.forEach((detail, index) =>
          _trucks.push({
            cells: [
              {
                content: (
                  <div className="!text-neutral-300 my-2">
                    <span className="mr-6 ml-2">{`#${index + 1}`}</span>
                    {detail.container_number}
                  </div>
                ),
              },
              {
                content: '',
              },
              {
                content: (
                  <div className="!text-neutral-300">{formatNumber(detail.total_collies || 0)}</div>
                ),
              },
              {
                content: (
                  <div className="!text-neutral-300">{formatNumber(detail.gross_weight || 0)}</div>
                ),
              },
              {
                content: (
                  <div className="!text-neutral-300">{formatNumber(detail.net_weight || 0)}</div>
                ),
              },
              {
                content: '',
              },
              {
                content: '',
              },
            ],
          })
        )
      }
    })

    return _trucks
  }, [
    shipmentCargo,
    isContainer,
    shipmentId,
    canEditCargo,
    canDeleteCargo,
    visibleDetails,
    handleRowClick,
    truckDrawerHandlers,
    deleteTruckModalHandlers,
  ])

  return (
    <>
      <div className="flex items-center">
        <CardTitle className="mb-3 capitalize">{name}s</CardTitle>

        {canCreateCargo && (
          <Button
            appearance="link"
            spacing="none"
            className="ml-auto"
            onClick={() => truckDrawerHandlers.open()}
          >
            New {name}
          </Button>
        )}
      </div>

      <Card className="mb-6">
        {isLoadingShipmentCargo ? (
          <div className="w-full text-center my-2">
            <Spinner />
          </div>
        ) : trucks.length === 0 ? (
          <EmptyState header="No details" />
        ) : (
          <div className="inline-block overflow-x-auto w-full -mb-6">
            <DynamicTableStateless head={trucksHead} rows={trucks} />
          </div>
        )}
      </Card>

      <TruckDrawer
        onSubmit={onSubmitCreateTruck}
        data={truckDrawerHandlers.data}
        isOpen={isOpenTruckDrawer}
        onClose={truckDrawerHandlers.close}
        name={name}
        isSubmitting={createCargoTruckMutation.isLoading}
      />

      <DeleteCargoModal
        isOpen={isOpenDeleteTruckModal}
        onClose={deleteTruckModalHandlers.close}
        cargoId={deleteTruckModalHandlers.data}
        shipmentId={entityId}
        name={name}
      />
    </>
  )
}

export default ShipmentViewCargoTrucks
