import ReactTimeAgo from 'react-time-ago'

//Services
import { getExports } from 'api/dashboard'

//Contexts
import { useApp } from 'contexts/AppProvider'

//Icons
import { CheckCircleIcon, CircleIcon, DownloadIcon } from '@royan-co/user-interface'

//Atlassian
import Spinner from '@atlaskit/spinner'
import Button from '@atlaskit/button/standard-button'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'

//UserInterfaces
import { useAsync } from '@royan-co/user-interface'
import NotificationBadge from 'components/UI/NotificationBadge'

const DownloadDropDown = () => {
  const { isExportExcel, setIsExportExcel } = useApp()
  const {
    execute,
    value: exports,
    isLoading,
  } = useAsync(getExports, {
    immediate: false,
  })

  return (
    <div onClick={() => setIsExportExcel(false)}>
      <DropdownMenu
        placement="bottom-end"
        onOpenChange={(e) => {
          if (e.isOpen === true) execute()
        }}
        trigger={({ triggerRef, isSelected, testId, ...providedProps }) => (
          <div className="relative">
            <Button
              appearance="subtle"
              style={{ borderRadius: '50%' }}
              iconAfter={<DownloadIcon />}
              {...providedProps}
              ref={triggerRef}
            />

            {isExportExcel && <NotificationBadge />}
          </div>
        )}
      >
        <DropdownItemGroup title="Downloads" id="downloads-dropdown" style={{ width: '320px' }}>
          {isLoading ? (
            <div className="flex justify-center py-3">
              <Spinner />
            </div>
          ) : (
            <>
              {exports?.length === 0 ? (
                <small className="block px-5 py-4 text-neutral-200">No downloads available</small>
              ) : (
                <>
                  {exports?.map((item) => (
                    <DropdownItem
                      key={item.id}
                      elemBefore={
                        <>
                          {item.status === 1 && <Spinner size={'medium'} />}
                          {item.status === 0 && (
                            <CircleIcon className="text-neutral-500" size={24} />
                          )}
                          {item.status === 2 && (
                            <CheckCircleIcon className="text-neutral-500" size={24} />
                          )}
                        </>
                      }
                      elemAfter={
                        <>
                          {(item.status === 0 || item.status === 1) && (
                            <small className="text-neutral-200">
                              {item.status === 0 && 'Queued'}

                              {item.status === 1 && 'Started'}
                            </small>
                          )}

                          {item.status === 2 && <small className="text-blue-400">{'Ready'}</small>}
                        </>
                      }
                      description={<ReactTimeAgo date={new Date(item.created_at)} locale="en-US" />}
                      onClick={() => {
                        if (item?.download_link) window.open(item.download_link, '_blank')
                      }}
                    >
                      <div className="capitalize">{item.table} Export</div>
                    </DropdownItem>
                  ))}
                </>
              )}
            </>
          )}
        </DropdownItemGroup>
      </DropdownMenu>
    </div>
  )
}

export default DownloadDropDown
