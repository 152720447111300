import { Link } from 'react-router-dom'

// Interfaces
import { ShortcutIcon } from '@royan-co/user-interface'

// Components
import QuoteStatusTag from '../QuoteStatusTag'
import ShipmentModeTag from '../../Shipments/ModeTag'
import CountryFlag from 'components/UI/CountryFlag'

export const RegistryQuoteColumns = [
  {
    key: 'quotation_code',
    title: 'Reference',
    isSortable: false,
    show: true,
    content: (item) => (
      <Link to={`/panel/quotations/${item.id_auto}`} target="_blank">
        <span className="whitespace-nowrap">
          {item.quotation_code} <ShortcutIcon size={16} />
        </span>
      </Link>
    ),
  },
  {
    key: 'shipment_type',
    title: 'Mode',
    isSortable: false,
    show: true,
    content: (item) => <ShipmentModeTag item={item} />,
  },
  {
    key: 'status',
    title: 'Status',
    isSortable: false,
    show: true,
    content: (item) => <QuoteStatusTag value={item.quotation_status} />,
  },
  {
    key: 'departure_country',
    title: 'Country of loading',
    isSortable: false,
    show: true,
    content: (item) => <CountryFlag iso={item.departure_country} />,
  },
  {
    key: 'arrival_country',
    title: 'Country of delivery',
    isSortable: false,
    show: true,
    content: (item) => <CountryFlag iso={item.arrival_country} />,
  },
  {
    key: 'creation_date',
    title: 'Creation date',
    isSortable: true,
    show: true,
    content: (item) => item.creation_date,
  },
]
