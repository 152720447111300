// Utils
import { findOption } from 'utils/helpers'
import { airWaybillTypeOptions } from './../utils/constants'

// Atlassian
import Lozenge from '@atlaskit/lozenge'

const AirWaybillTypeTag = ({ value }) => {
  const opt = findOption(airWaybillTypeOptions, value)

  return opt ? <Lozenge appearance={opt.appearance}>{opt.label}</Lozenge> : null
}

export default AirWaybillTypeTag
