import { useQuery } from '@tanstack/react-query'

// Apis
import { getTrackingLogs } from 'api/shipments'

/**
 * get invoice default values
 * @param {String} parent
 * @param {String|Number} parentId
 * @param {import('@tanstack/react-query').UseQueryOptions} options
 * @returns {import('@tanstack/react-query').UseQueryResult}
 */
const useTrackingLogs = (parent, parentId, options = {}) => {
  return useQuery(['tracking-logs', parent, parentId], () => getTrackingLogs(parent, parentId), {
    staleTime: Infinity,
    retry: 2,
    ...options,
  })
}

export default useTrackingLogs
