import { useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { useQueryClient } from '@tanstack/react-query'

// API
import { createCommercialInvoice, editCommercialInvoice } from 'api/invoices'

// Hooks
import useShipment from 'hooks/useShipment'
import useCommercialInvoice from 'hooks/useCommercialInvoice'

//Interfaces
import { Form, Grid, WarningBannerField, useFlags } from '@royan-co/user-interface'

// Atlaskit
import PageHeader from '@atlaskit/page-header'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'
import Spinner from '@atlaskit/spinner'
import Button, { LoadingButton } from '@atlaskit/button'
import SectionMessage from '@atlaskit/section-message'

// Utils
import { handleErrorOnSubmit } from 'utils/errors'
import { getFilled } from 'utils/helpers'

// Components
import CommercialInvoiceGeneralSection from 'components/Shipments/View/CommercialInvoice/General'
import CommercialInvoiceRowsSection from 'components/Shipments/View/CommercialInvoice/Rows'

const CommercialInvoiceCreateEditPage = () => {
  const { invoiceId, shipmentId } = useParams()
  const { showSuccessFlag, showWarningFlag } = useFlags()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { shipment, refetch, isError, isLoading: isLoadingShipment, isFetching } = useShipment()
  const {
    commercialInvoice,
    refetch: refetchCommercialInvoice,
    isError: isErrorCommercialInvoice,
    isLoading: isLoadingCommercialInvoice,
    isFetching: isFetchingCommercialInvoice,
    invalidate,
  } = useCommercialInvoice(shipmentId, invoiceId, { enabled: !!invoiceId })

  const isLoadingData = useMemo(
    () => (!!invoiceId ? isLoadingCommercialInvoice : isLoadingShipment),
    [invoiceId, isLoadingShipment, isLoadingCommercialInvoice]
  )

  const isFetchingData = useMemo(
    () => (!!invoiceId ? isFetchingCommercialInvoice : isFetching),
    [invoiceId, isFetching, isFetchingCommercialInvoice]
  )

  const isErrorData = useMemo(
    () => (!!invoiceId ? isErrorCommercialInvoice : isError),
    [invoiceId, isError, isErrorCommercialInvoice]
  )

  const refetchData = useMemo(
    () => (!!invoiceId ? refetchCommercialInvoice : refetch),
    [invoiceId, refetch, refetchCommercialInvoice]
  )

  const pageTitle = invoiceId ? 'Edit commercial invoice' : 'New commercial invoice'

  const defaultValues = useMemo(() => {
    return {
      document_see: getFilled(commercialInvoice, 'document_see', 0),
      number: getFilled(commercialInvoice, 'number', ''),
      amount: getFilled(commercialInvoice, 'amount', ''),
      date: getFilled(commercialInvoice, 'date', ''),
      rows: getFilled(commercialInvoice, 'commodities', []),
    }
  }, [commercialInvoice])

  const invoiceCreationMutation = useMutation(
    !!invoiceId
      ? editCommercialInvoice.bind(null, shipment?.id_auto, invoiceId)
      : createCommercialInvoice.bind(null, shipment?.id_auto)
  )

  const onSubmit = (data, { setError }) => {
    invoiceCreationMutation.mutate(data, {
      onError: (e) => {
        console.error(e)
        handleErrorOnSubmit(e, setError, data)
        showWarningFlag(e?.response?.data?.message || 'Something went wrong.')
      },
      onSuccess: (res) => {
        showSuccessFlag(res?.message)
        queryClient.setQueryData(
          ['shipment', 'commercial-invoices', shipmentId],
          (invoices = []) => {
            if (!!invoiceId) {
              if (!invoices.length) return
              return invoices?.map((i) => (i.id === invoiceId ? res.invoice : i))
            } else {
              return [...invoices, res?.invoice]
            }
          }
        )

        invalidate()
        navigate(`/panel/shipments/${shipmentId}/cargo-details`)
      },
    })
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <Grid className="w-full">
        <Grid.Col md={8} xl={6} offsetMd={2} offsetXl={3}>
          <PageHeader
            breadcrumbs={
              <Breadcrumbs>
                <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
                <BreadcrumbsItem text="Operation" />
                <BreadcrumbsItem text="Shipments" to="/panel/shipments" component={Link} />
                <BreadcrumbsItem
                  text={isLoadingShipment ? <Spinner size="xsmall" /> : shipment?.reference}
                  to={`/panel/shipments/${shipment?.id_auto}`}
                  component={Link}
                />
                <BreadcrumbsItem
                  text="Cargo details"
                  to={`/panel/shipments/${shipment?.id_auto}/cargo-details`}
                  component={Link}
                />
              </Breadcrumbs>
            }
          >
            {pageTitle}
          </PageHeader>

          {isLoadingData || isFetchingData ? (
            <div className="text-center my-8">
              <Spinner />
            </div>
          ) : isErrorData ? (
            <Grid gutter={24} className="w-full">
              <Grid.Col md={6} offsetMd={3}>
                <SectionMessage
                  appearance="warning"
                  title="Something wrong on loading record data, please retry"
                >
                  <Button onClick={() => refetchData()}>Retry</Button>
                </SectionMessage>
              </Grid.Col>
            </Grid>
          ) : (
            <Form onSubmit={onSubmit} defaultValues={defaultValues}>
              {() => (
                <>
                  <CommercialInvoiceGeneralSection />

                  <CommercialInvoiceRowsSection />

                  <WarningBannerField />

                  <div className="mt-6 text-right">
                    <LoadingButton
                      type="submit"
                      appearance="primary"
                      isLoading={invoiceCreationMutation?.isLoading}
                    >
                      {invoiceId ? 'Update' : 'Create'} invoice
                    </LoadingButton>
                  </div>
                </>
              )}
            </Form>
          )}
        </Grid.Col>
      </Grid>
    </>
  )
}

export default CommercialInvoiceCreateEditPage
