import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

//Services
import useRegistry from 'hooks/useRegistry'

//Interfaces

//Components
import RowTable from 'components/UI/RowTable'
import NotProvidedTag from 'components/UI/NotProvidedTag'
import CreditLineStatusTag from 'components/Registries/CreditLineStatusTag'
import RegistryAccountingArcaTag from 'components/Registries/AccountingArcaTag'
import Card from 'components/UI/Card'

const RegistryViewFinancialDetailsPage = () => {
  const { registryId, creatorId } = useParams()
  const { registry, creator } = useRegistry(registryId, { creatorId })

  const FinancialDetailsRows = [
    {
      title: 'VAT number',
      content: registry?.vat_number || <NotProvidedTag />,
    },
    {
      title: 'Imported in accounting',
      content: <RegistryAccountingArcaTag value={creator?.accounting_arca} />,
    },
    {
      title: 'Payment condition',
      content: creator?.payment_code_details?.description || <NotProvidedTag />,
    },
    { title: 'Bank', content: registry?.bank_details?.name || <NotProvidedTag /> },
    { title: 'Fiscal code', content: registry?.fiscal_code || <NotProvidedTag /> },
    { title: 'Unique code', content: registry?.pec_code || <NotProvidedTag /> },
    { title: 'PEC', content: registry?.pec_invoicing || <NotProvidedTag /> },
    { title: 'Swift no.', content: registry?.swift || <NotProvidedTag /> },
    { title: 'Share capital', content: registry?.share_capital || <NotProvidedTag /> },
  ]

  const CreditLineRows = useMemo(() => {
    let creditLineItems = [
      {
        title: 'Status',
        content: <CreditLineStatusTag line={creator?.credit_line} lock={creator?.credit_lock} />,
      },
    ]

    if (creator?.credit_line)
      creditLineItems.push(
        {
          title: 'Amount',
          content: creator?.credit_line ? `${creator?.credit_line} EUR` : <NotProvidedTag />,
        },
        {
          title: 'Expiration date',
          content: creator?.expire_credit_line_date || <NotProvidedTag />,
        },
        {
          title: 'Credit line check',
          content: creator?.cl_check_url ? (
            <a href={creator?.cl_check_url}>Download</a>
          ) : (
            <NotProvidedTag />
          ),
        },
        {
          title: 'Credit line document',
          content: creator?.cl_doc_url ? (
            <a href={creator?.cl_doc_url}>Download</a>
          ) : (
            <NotProvidedTag />
          ),
        }
      )

    return creditLineItems
  }, [creator])

  return (
    <>
      <Card className="mb-0">
        <RowTable rows={FinancialDetailsRows} />
      </Card>

      <Card className="mt-6" title="Credit line">
        <RowTable rows={CreditLineRows} />
      </Card>
    </>
  )
}

export default RegistryViewFinancialDetailsPage
