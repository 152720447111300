import { useMemo } from 'react'
import { useMutation } from '@tanstack/react-query'

// Hooks
import useRegistryContacts from 'hooks/useRegistryContacts'

// Api
import { createShareTrackingContact } from 'api/shipments'

// Utils
import { findCountryIsoByCode } from 'utils/CountryCodes'
import { handleErrorOnSubmit } from 'utils/errors'

// Atlassian
import TextField from '@atlaskit/textfield'
import { Checkbox } from '@atlaskit/checkbox'
import { ErrorMessage } from '@atlaskit/form'
import Button, { LoadingButton } from '@atlaskit/button'
import Modal, {
  ModalTitle,
  ModalHeader,
  ModalFooter,
  ModalTransition,
} from '@atlaskit/modal-dialog'

// Interfaces
import {
  Field,
  Form,
  PhoneInput,
  WarningBannerField,
  useFlags,
  useGeoInfo,
} from '@royan-co/user-interface'

// Components
import CreatorContactSelect from './CreatorContactSelect'
import useShipment from 'hooks/useShipment'

const LinkContactModal = ({ isOpen, onClose, shipmentId, registryId, shipmentContacts = [] }) => {
  const { geoInfo } = useGeoInfo()
  const { showSuccessFlag } = useFlags()
  const { data, isLoading } = useRegistryContacts(registryId, {
    enabled: !!registryId && isOpen,
  })
  const { upsertTrackingContactToData, upsertContactToData } = useShipment(shipmentId)

  const options = useMemo(
    () =>
      (data || [])
        .map((c) => ({
          label: c.full_name,
          value: c.id_auto,
          tracking: c.tracking,
          whatapp: c.whatapp,
        }))
        .filter((contact) => !shipmentContacts.some((c) => !!(c.id_auto === contact.value))),
    [shipmentContacts, data]
  )

  const contactCreationMutation = useMutation(createShareTrackingContact.bind(null, shipmentId))

  const handleSubmit = (data, { setError }) => {
    const formData = data.name?.isNew
      ? {
          name: data.name.value,
          phone: data.phone?.rawPhone,
          phone_prefix: findCountryIsoByCode(data.phone?.dialCode),
          contact: data.contact || null,
          store_contact: data.store_contact ? 1 : 0,
        }
      : { contact_id: data.name.value }

    if (!formData.contact_id && !formData.phone && !formData.contact) {
      setError('unknown', { type: 'api', message: 'Please select email or whatsapp' })
      return
    }

    if (!formData.contact_id && !formData.contact && formData.store_contact) {
      setError('unknown', { type: 'api', message: 'Please enter an email' })
      return
    }

    contactCreationMutation.mutate(formData, {
      onError: (e) => {
        handleErrorOnSubmit(e, setError, formData)
        console.error(e)
      },
      onSuccess: (res) => {
        showSuccessFlag('Contact created successfully')
        if (formData.contact_id || formData.store_contact)
          upsertContactToData({
            ...res.contact,
            whatapp: res.contact.phone,
            tracking: res.contact.email,
          })
        else upsertTrackingContactToData(res.contact)
        handleCloseModal()
      },
    })
  }

  const handleCloseModal = () => {
    onClose()
  }

  return (
    <>
      <ModalTransition>
        {isOpen && (
          <Modal width={'medium'} onClose={handleCloseModal}>
            <Form onSubmit={handleSubmit}>
              {({ watch }) => (
                <>
                  <ModalHeader>
                    <ModalTitle>Add contact</ModalTitle>
                  </ModalHeader>

                  <div className="px-6">
                    <p>Note that this shipment alerts will be shared with this contact.</p>

                    <div className="mt-4">
                      <Field isRequired name="name" label="Contact">
                        {({ fieldProps, error }) => (
                          <>
                            <CreatorContactSelect
                              options={options}
                              isLoading={isLoading}
                              {...fieldProps}
                            />
                            {error && <ErrorMessage>{error}</ErrorMessage>}
                          </>
                        )}
                      </Field>

                      {watch('name')?.isNew && (
                        <>
                          <div className="mt-3">
                            <Field type="checkbox" name="shareViaEmail" className="!mt-0">
                              {({ fieldProps }) => (
                                <Checkbox label="Notify via email" {...fieldProps} />
                              )}
                            </Field>

                            {watch('shareViaEmail') && (
                              <Field isRequired name="contact">
                                {({ fieldProps, error }) => (
                                  <>
                                    <TextField
                                      type="email"
                                      placeholder="Type the email"
                                      {...fieldProps}
                                    />
                                    {error && <ErrorMessage>{error}</ErrorMessage>}
                                  </>
                                )}
                              </Field>
                            )}
                          </div>

                          <div className="mt-3">
                            <Field type="checkbox" name="shareViaWhatsapp" className="!mt-0">
                              {({ fieldProps }) => (
                                <Checkbox label="Notify via Whatsapp" {...fieldProps} />
                              )}
                            </Field>

                            {watch('shareViaWhatsapp') && (
                              <Field
                                isRequired
                                name="phone"
                                transform={{
                                  input: (v) => ({
                                    value: v?.value || v,
                                  }),
                                  output: (opt) => opt,
                                }}
                              >
                                {({ fieldProps: { ref, ...fieldProps }, error }) => (
                                  <>
                                    <PhoneInput
                                      defaultCountry={geoInfo?.countryCode?.toLowerCase()}
                                      {...fieldProps}
                                    />
                                    {error && <ErrorMessage>{error}</ErrorMessage>}
                                  </>
                                )}
                              </Field>
                            )}
                          </div>

                          <Field
                            type="checkbox"
                            label="Save contact"
                            name="store_contact"
                            className="!mt-4"
                          >
                            {({ fieldProps }) => (
                              <Checkbox
                                label="Save the contact in client’s registry"
                                {...fieldProps}
                              />
                            )}
                          </Field>
                        </>
                      )}
                    </div>

                    <WarningBannerField />
                  </div>

                  <ModalFooter>
                    <Button appearance="subtle" onClick={handleCloseModal}>
                      Cancel
                    </Button>

                    <LoadingButton
                      isLoading={contactCreationMutation.isLoading}
                      type="submit"
                      appearance="primary"
                      autoFocus
                    >
                      Confirm
                    </LoadingButton>
                  </ModalFooter>
                </>
              )}
            </Form>
          </Modal>
        )}
      </ModalTransition>
    </>
  )
}

export default LinkContactModal
