import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useParams, useNavigate } from 'react-router'
import { useMutation } from '@tanstack/react-query'
import { Helmet } from 'react-helmet-async'

// Apis
import { createInvoice } from '../api/createInvoice.js'

// Hooks
import { useInvoice, useUpdateInvoice } from '../api/getInvoice.js'

// Utils
import { handleErrorOnSubmit } from 'utils/errors'
import { getFilled } from 'utils/helpers.js'

// Atlassian
import PageHeader from '@atlaskit/page-header'
import Spinner from '@atlaskit/spinner'
import SectionMessage from '@atlaskit/section-message'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'
import Button, { LoadingButton } from '@atlaskit/button'
import { ErrorMessage } from '@atlaskit/form'

// Interfaces
import { Grid, Form, useFlags, WarningBannerField } from '@royan-co/user-interface'

// Components
import CreatePassiveInvoiceFormGeneral from '../components/CreatePassiveInvoiceFormGeneral.js'
import Attachments from 'components/UI/ComposingEmail/Attachments'
import Card from 'components/UI/Card.js'
import { editInvoice } from '../api/invoice-requests.js'

const CreatePassiveInvoice = () => {
  const navigate = useNavigate()
  const { showSuccessFlag } = useFlags()
  const { invoiceId } = useParams()

  const [selectedFiles, setSelectedFiles] = useState([])
  const [selectedFilesError, setSelectedFilesError] = useState()

  const isEditing = !!invoiceId
  const pageTitle = isEditing ? 'Edit passive invoice' : 'New passive invoice'

  const { invoice, isLoading, isError, refetch } = useInvoice(invoiceId, {
    enabled: isEditing,
  })
  const { upsertInvoiceProperties } = useUpdateInvoice(invoiceId)

  const createInvoiceMutation = useMutation((data) =>
    !invoiceId ? createInvoice(data) : editInvoice(invoiceId, '', data)
  )

  const defaultValues = useMemo(() => {
    return {
      supplier: invoice?.supplier?.id_auto
        ? {
            label: invoice?.supplier?.company_name,
            value: invoice?.supplier?.id_auto,
            imported: invoice?.supplier?.arca_imported,
          }
        : null,
      invoice_number: getFilled(invoice, 'invoice_number', ''),
      invoice_date: getFilled(invoice, 'invoice_date', ''),
      amount: getFilled(invoice, 'total_amount', ''),
      currency: getFilled(invoice, 'currency', 'EUR'),
      document_type: getFilled(invoice, 'do', null),
      payment_condition: getFilled(invoice, 'payment_condition.code', null),
    }
  }, [invoice])

  const validateSelectedFiles = () => {
    setSelectedFilesError('')

    let fileData = {}
    selectedFiles.forEach((f) => {
      const type = f.title.split('-')
      if (type[0]?.trim() === 'Invoice file') fileData.document = f.document_path
      if (type[0]?.trim() === 'Proof of delivery') fileData.cmr = f.document_path
    })

    if (!fileData?.document) setSelectedFilesError('Upload invoice file is required')
    else if (!fileData?.cmr && selectedFiles.length === 2)
      setSelectedFilesError('Only one invoice file is accepted')
    else return fileData
  }

  const onSubmitIssueInvoice = (data, { setError }) => {
    const fileData = validateSelectedFiles()
        if (!fileData?.document) return

    const formData = {
      ...data,
      ...fileData,
      supplier: data?.supplier?.value,
      active_passive: 1,
    }

    createInvoiceMutation.mutate(formData, {
      onError: (e) => {
        handleErrorOnSubmit(e, setError, data)
      },
      onSuccess: (res) => {
        showSuccessFlag(res?.message)
        if (invoiceId) upsertInvoiceProperties(res.invoice)
        setTimeout(
          () => navigate(`/panel/passive-invoices/${res?.invoice?.id_auto}`, { replace: true }),
          100
        )
      },
    })
  }

  useEffect(() => {
    if (selectedFilesError) setSelectedFilesError('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFiles])

  useEffect(() => {
    if (invoice && invoiceId) {
      let _selectedFiles = []
      if (invoice?.download_url)
        _selectedFiles.push({
          title: 'Invoice file',
          url: invoice.download_url,
          document_path: invoice.download_url,
        })
      if (invoice?.cmr_download_url)
        _selectedFiles.push({
          title: 'Proof of delivery',
          url: invoice.cmr_download_url,
          document_path: invoice.cmr_download_url,
        })

      setSelectedFiles(_selectedFiles)
    }
  }, [invoice, invoiceId])

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <Grid className="w-full">
        <Grid.Col md={10} lg={8} offsetMd={1} offsetLg={2}>
          <PageHeader
            breadcrumbs={
              <Breadcrumbs>
                <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
                <BreadcrumbsItem text="Accounting" to="" component={Link} />
                <BreadcrumbsItem
                  text="Passive invoices"
                  to="/panel/passive-invoices"
                  component={Link}
                />
                {invoiceId && (
                  <BreadcrumbsItem
                    text={
                      isLoading ? <Spinner size="xsmall" /> : `Invoice ${invoice?.invoice_number}`
                    }
                    to={`/panel/passive-invoices/${invoiceId}`}
                    component={Link}
                  />
                )}
              </Breadcrumbs>
            }
          >
            {pageTitle}
          </PageHeader>

          <div className="py-4">
            {isLoading && invoiceId ? (
              <div className="flex justify-center my-32">
                <Spinner />
              </div>
            ) : isError ? (
              <SectionMessage
                appearance="warning"
                title="Something wrong on loading data, please retry."
              >
                <Button onClick={() => refetch()}>Retry</Button>
              </SectionMessage>
            ) : (
              <Form onSubmit={onSubmitIssueInvoice} defaultValues={defaultValues}>
                {() => (
                  <>
                    <Card className="mt-0">
                      <CreatePassiveInvoiceFormGeneral />

                      <div className="mt-4">
                        <Attachments
                          selectedFiles={selectedFiles}
                          setSelectedFiles={setSelectedFiles}
                          maxFiles={2 - (selectedFiles.length || 0)}
                          useFileNameAsTitle={false}
                          disableUploadButton={selectedFiles.length === 2 ? true : false}
                          fileTitleOptions={[
                            {
                              label: 'Invoice file',
                              value: 'Invoice file',
                            },
                            {
                              label: 'Proof of delivery',
                              value: 'Proof of delivery',
                            },
                          ]}
                        />
                        {selectedFilesError && <ErrorMessage>{selectedFilesError}</ErrorMessage>}
                      </div>
                    </Card>

                    <WarningBannerField />

                    <LoadingButton
                      className="!table ml-auto mt-6"
                      appearance="primary"
                      type="submit"
                      isLoading={createInvoiceMutation.isLoading}
                    >
                      {isEditing ? 'Edit invoice' : 'Create invoice'}
                    </LoadingButton>
                  </>
                )}
              </Form>
            )}
          </div>
        </Grid.Col>
      </Grid>
    </>
  )
}

export default CreatePassiveInvoice
