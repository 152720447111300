import axios from 'services/Request'

export function getServices(params) {
  return axios.get('/api/configurations/services', { params })
}

export function getServiceCode(code) {
  return axios.get(`/api/configurations/services/${code}`)
}

export function createServiceCode(data) {
  return axios.post('/api/configurations/services', data)
}

export function editServiceCode(code, data) {
  return axios.put(`/api/configurations/services/${code}`, data)
}
