// Utils
import { transformSelectField } from 'utils/helpers'

// //Hooks
import useUserLevels from 'hooks/useUserLevels'

//Atlassian
import Select from '@atlaskit/select'
import TextField from '@atlaskit/textfield'

// Interfaces
import { Field } from '@royan-co/user-interface'

const UsersFilterFields = () => {
  const { userLevelsOptions, isLoadingUserLevels } = useUserLevels()

  return (
    <>
      <Field label="Registry" name="registry" defaultValue="">
        {({ fieldProps }) => <TextField placeholder="Type a registry" {...fieldProps} />}
      </Field>

      <Field
        label="Role"
        type="select"
        name="role"
        transform={transformSelectField(userLevelsOptions)}
      >
        {({ fieldProps }) => (
          <Select
            placeholder="Choose one"
            options={userLevelsOptions}
            isLoading={isLoadingUserLevels}
            {...fieldProps}
          />
        )}
      </Field>
    </>
  )
}

export default UsersFilterFields
