import { Link } from 'react-router-dom'
import { formatMonetaryNumber } from 'utils/numbers'

//Atlassian
import Lozenge from '@atlaskit/lozenge'

//Components
import TagLink from 'components/UI/TagLink'
import InvoiceRowActions from '../InvoiceRowActions'
import InvoiceRegistrationTag from '../RegistrationTag'
import CollapsedWrapper from 'components/UI/CollapsedWrapper'
import InvoicePaidTag from 'components/Invoices/PaidTag'
import InvoiceTypeTag from '../TypeTag'

export const generateInvoicesColumns = (invoiceType) => {
  return [
    {
      key: 'invoice_number',
      title: 'Invoice no.',
      isSortable: true,
      show: true,
      type: ['active', 'passive', 'buffer'],
      content: (item) => item.invoice_number,
    },
    {
      key: 'do_boolean',
      title: 'Invoice type',
      show: true,
      type: ['active', 'passive'],
      content: (item) => (
        <InvoiceTypeTag value={item.do_boolean} activePassive={item?.active_passive} />
      ),
    },
    {
      key: 'is_proforma',
      title: 'Proforma',
      show: true,
      type: ['active'],
      content: (item) =>
        item?.is_proforma ? <Lozenge appearance="moved">Yes</Lozenge> : <Lozenge>No</Lozenge>,
    },
    {
      key: 'do',
      title: 'Doc type',
      show: true,
      type: ['active'],
      content: (item) => item.do,
    },
    {
      key: 'shipment',
      title: 'Shipment/Consolidation reference',
      show: true,
      type: ['active', 'passive', 'buffer'],
      content: (item) => (
        <CollapsedWrapper
          items={item.parents.map((item, index) => {
            if (item.resource === 'shipment') {
              return (
                <TagLink
                  key={index}
                  target="_blank"
                  isRemovable={false}
                  text={item.reference}
                  to={`/panel/shipments/${item.id}`}
                />
              )
            } else if (item.resource === 'consolidation') {
              return (
                <TagLink
                  key={index}
                  target="_blank"
                  isRemovable={false}
                  text={item.reference}
                  to={`/panel/consolidations/${item.id}`}
                />
              )
            } else return ''
          })}
          maxItems={1}
        />
      ),
    },
    {
      key: 'client_id',
      title: 'Client',
      show: true,
      type: ['active'],
      content: (item) => (
        <Link to={`/panel/registries/${item.client_id}`}>{item.client.company_name}</Link>
      ),
    },
    {
      key: 'supplier_id',
      title: 'Supplier',
      show: true,
      type: ['passive', 'buffer'],
      content: (item) => (
        <Link to={`/panel/registries/${item.supplier_id}`}>{item.supplier.company_name}</Link>
      ),
    },
    {
      key: 'total_taxable',
      title: 'Total taxable',
      show: true,
      type: ['active', 'passive', 'buffer'],
      content: (item) => formatMonetaryNumber(item.total_taxable || 0),
    },
    {
      key: 'tot_iva',
      title: 'Total IVA',
      show: true,
      type: ['active', 'passive'],
      content: (item) => formatMonetaryNumber(item.tot_iva || 0),
    },
    {
      key: 'total_amount',
      title: 'Total amount',
      show: true,
      type: ['active', 'passive', 'buffer'],
      content: (item) => formatMonetaryNumber(item.total_amount || 0),
    },
    {
      key: 'total_paid',
      title: 'Total paid',
      show: true,
      type: ['active'],
      content: (item) => formatMonetaryNumber(item.tot_payd || 0),
    },
    {
      key: 'operator_id',
      title: 'Operator',
      show: true,
      type: ['passive', 'buffer'],
      content: (item) => item?.operator?.full_name,
    },
    {
      key: 'hide_buffer',
      title: 'Visibility for operator',
      show: true,
      type: ['passive'],
      content: (item) =>
        item?.hide_buffer ? (
          <Lozenge appearance="moved">Hidden</Lozenge>
        ) : (
          <Lozenge appearance="success">Visible</Lozenge>
        ),
    },
    {
      key: 'creator',
      title: 'Creator',
      show: true,
      type: ['active', 'passive'],
      content: (item) => item?.creator?.full_name,
    },
    {
      key: 'arca_status',
      title: 'Imported in ARCA',
      show: true,
      type: ['active'],
      content: (item) => (
        <InvoiceRegistrationTag
          value={item?.arca_status}
          elaborated={item?.elaborated}
          error={item?.arca_error}
        />
      ),
    },
    {
      key: 'is_payd',
      title: 'Paid',
      isSortable: true,
      show: true,
      type: ['active', 'passive'],
      content: (item) => <InvoicePaidTag value={item.is_payd} />,
    },
    {
      key: 'arca_status',
      title: 'Registration',
      show: true,
      type: ['passive', 'buffer'],
      content: (item) => (
        <InvoiceRegistrationTag
          value={item.arca_status}
          elaborated={item?.elaborated}
          error={item?.arca_error}
        />
      ),
    },
    {
      key: 'cmr_doc_name',
      title: 'CMR',
      show: true,
      type: ['passive'],
      content: (item) =>
        item?.cmr_doc_name ? (
          <Lozenge appearance="success">YES</Lozenge>
        ) : (
          <Lozenge appearance="moved">NO</Lozenge>
        ),
    },
    {
      key: 'registry_bank_id',
      title: 'Bank',
      show: true,
      type: ['active'],
      content: (item) => item?.bank?.name,
    },
    {
      key: 'multiple',
      title: 'Multiple',
      show: true,
      type: ['active'],
      content: (item) =>
        item.multiple ? (
          <Lozenge appearance="success">Yes</Lozenge>
        ) : (
          <Lozenge appearance="removed">No</Lozenge>
        ),
    },
    {
      key: 'payment_terms',
      title: 'Payment terms',
      show: false,
      type: ['active'],
      content: (item) => item.payment_terms,
    },
    {
      key: 'disputed',
      title: 'Disputed',
      show: true,
      type: ['buffer'],
      content: (item) =>
        item?.disputed ? (
          <Lozenge appearance="success">Yes</Lozenge>
        ) : (
          <Lozenge appearance="removed">No</Lozenge>
        ),
    },
    {
      key: 'invoice_date',
      title: 'Issued at',
      isSortable: true,
      show: true,
      type: ['active', 'passive', 'buffer'],
      content: (item) =>
        invoiceType.name === 'active' ? item?.invoice_date : item?.passive_invoice_date,
    },
    {
      key: 'actions',
      title: 'Actions',
      show: true,
      type: ['active', 'passive', 'buffer'],
      content: (item) => <InvoiceRowActions invoice={item} type={invoiceType.name} />,
    },
  ].filter((column) => column.type.includes(invoiceType.name))
}
