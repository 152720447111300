// Atlassian
import Button from '@atlaskit/button'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'

// Interfaces
import { DotsIcon } from '@royan-co/user-interface'

// Components
import CountryFlag from 'components/UI/CountryFlag'
import DropdownLink from 'components/UI/DropdownLink'

export const generateLineColumns = (openDeleteModal) => {
  return [
    {
      key: 'id',
      title: 'ID',
      isSortable: true,
      show: true,
      content: (item) => item?.id,
    },
    {
      key: 'departure_country',
      title: 'Departure country',
      isSortable: true,
      show: true,
      content: (item) => <CountryFlag iso={item?.departure_country} />,
    },
    {
      key: 'arrival_country',
      title: 'Arrival country',
      isSortable: true,
      show: true,
      content: (item) => <CountryFlag iso={item?.arrival_country} />,
    },
    {
      key: 'actions',
      title: 'Actions',
      show: true,
      content: (item) => (
        <DropdownMenu
          placement="bottom-end"
          trigger={({ triggerRef, ...props }) => (
            <Button
              {...props}
              appearance="subtle"
              children={<DotsIcon label="more" />}
              ref={triggerRef}
            />
          )}
        >
          <DropdownItemGroup>
            {/* <DropdownLink children="View" to={`/panel/margin-calculator/${item.id}`} /> */}
            <DropdownLink children="Edit" to={`/panel/sales-machine/lines/${item.id}/edit`} />
            <DropdownItem children="Delete" onClick={() => openDeleteModal(item)} />
          </DropdownItemGroup>
        </DropdownMenu>
      ),
    },
  ]
}
