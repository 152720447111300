import { useEffect, useMemo } from 'react'

// Hooks
import useShipmentsEnumerations from 'hooks/useShipmentsEnumerations'
import useRegistryAirlines from 'hooks/useRegistryAirlines'

// Utils
import { findOption, getCountry, transformSelectField } from 'utils/helpers'
import {
  allowCalculationOptions,
  calculationMethodOptions,
  formatOptions,
  typeOptions,
} from '../constants'
import { containerTypes, modeOptions, truckTypes } from 'components/Shipments/constants'

// Atlassian
import TextField from '@atlaskit/textfield'
import Select, { CountrySelect } from '@atlaskit/select'
import { DatePicker } from '@atlaskit/datetime-picker'
import { ErrorMessage } from '@atlaskit/form'

// Interfaces
import { Field, Grid, useFormContext } from '@royan-co/user-interface'

// Components
import EnumerationField from './EnumerationField'
import RatesAirportsField from './AirportsField'

const RatesFilterFields = ({ appliedFilters }) => {
  const { watch, setValue } = useFormContext()

  const type = watch('from_to')
  const mode = watch('shipment_type')
  const getAirline = useMemo(() => type === 5, [type])

  const { getServiceCodeOptions, isLoadingShipmentEnumeration } = useShipmentsEnumerations()
  const { registryAirlineOptions, isLoadingRegistryAirlines } = useRegistryAirlines(getAirline)

  useEffect(() => {
    if (![0, 1].includes(mode)) setValue('type_of_collies', null)
  }, [mode, setValue])

  const resetPlacesValue = () =>
    [
      'departure_country',
      'departure_hub',
      'departure_airport',
      'departure_port',
      'departure_station',
      'arrival_country',
      'arrival_hub',
      'arrival_airport',
      'arrival_port',
      'arrival_station',
    ].forEach((item) => {
      setValue(item, null)
    })

  return (
    <>
      <Field
        label="Type"
        type="select"
        name="from_to"
        transform={{
          input: (v) => findOption(typeOptions, v),
          output: (opt) => {
            if (opt.value !== appliedFilters.from_to) {
              resetPlacesValue()
            }
            return opt?.value !== undefined ? opt.value : null
          },
        }}
      >
        {({ fieldProps }) => (
          <Select
            isClearable={true}
            options={typeOptions}
            placeholder="Choose a type"
            {...fieldProps}
          />
        )}
      </Field>

      <Field
        label="Mode"
        type="select"
        name="shipment_type"
        transform={{
          output: (opt) => (opt?.value !== undefined ? opt.value : null),
          input: (v) => findOption(modeOptions, v),
        }}
      >
        {({ fieldProps }) => (
          <Select
            isClearable={true}
            options={modeOptions}
            placeholder="Choose a mode"
            {...fieldProps}
          />
        )}
      </Field>

      {[0, 1].includes(mode) && (
        <Field
          label="Method of loading"
          type="select"
          name="type_of_collies"
          transform={{
            output: (opt) => (opt?.value !== undefined ? opt.value : null),
            input: (v) => findOption(mode === 1 ? containerTypes : truckTypes, v),
          }}
        >
          {({ fieldProps }) => (
            <Select
              isClearable={true}
              options={mode === 1 ? containerTypes : truckTypes}
              placeholder="Choose a method"
              {...fieldProps}
            />
          )}
        </Field>
      )}

      <Field label="Supplier" name="supplier" defaultValue="">
        {({ fieldProps }) => <TextField placeholder="Type the supplier" {...fieldProps} />}
      </Field>

      {getAirline && (
        <Field
          label="Airline"
          type="select"
          name="airline"
          transform={transformSelectField(registryAirlineOptions)}
        >
          {({ fieldProps }) => (
            <Select
              isClearable={true}
              isLoading={isLoadingRegistryAirlines}
              options={registryAirlineOptions}
              placeholder="Choose an airline"
              {...fieldProps}
            />
          )}
        </Field>
      )}

      {[1, 2, 8, 14].includes(type) && (
        <Field
          type="select"
          label="Place of loading"
          name="departure_country"
          transform={{
            input: (v) => getCountry(v),
            output: (opt) => (opt?.abbr !== undefined ? opt.abbr : null),
          }}
        >
          {({ fieldProps: { ref, ...fieldProps }, error }) => (
            <>
              <CountrySelect isClearable placeholder="Country" {...fieldProps} />
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </>
          )}
        </Field>
      )}

      {[0, 3, 7, 11].includes(type) && (
        <EnumerationField label="Place of loading" name="departure_hub" optionsKey="hubs" />
      )}

      {[5].includes(type) && (
        <RatesAirportsField
          label="Place of loading"
          name="departure_airport"
          defaultValue={appliedFilters.departure_airport}
        />
      )}

      {[6, 9, 16].includes(type) && (
        <EnumerationField label="Place of loading" name="departure_port" optionsKey="ports" />
      )}

      {[10, 12, 13, 15].includes(type) && (
        <EnumerationField label="Place of loading" name="departure_station" optionsKey="stations" />
      )}

      {[0, 2, 9, 13].includes(type) && (
        <Field
          type="select"
          label="Place of delivery"
          name="arrival_country"
          transform={{
            input: (v) => getCountry(v),
            output: (opt) => (opt?.abbr !== undefined ? opt.abbr : null),
          }}
        >
          {({ fieldProps: { ref, ...fieldProps }, error }) => (
            <>
              <CountrySelect isClearable placeholder="Country" {...fieldProps} />
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </>
          )}
        </Field>
      )}

      {[1, 3, 12].includes(type) && (
        <EnumerationField label="Place of delivery" name="arrival_hub" optionsKey="hubs" />
      )}

      {[5].includes(type) && (
        <RatesAirportsField
          label="Place of delivery"
          name="arrival_airport"
          defaultValue={appliedFilters.arrival_airport}
        />
      )}

      {[6, 7, 8, 15].includes(type) && (
        <EnumerationField label="Place of delivery" name="arrival_port" optionsKey="ports" />
      )}

      {[10, 11, 14, 16].includes(type) && (
        <EnumerationField label="Place of delivery" name="arrival_station" optionsKey="stations" />
      )}

      <Field
        label="Cost code"
        type="select"
        name="cost_code"
        transform={transformSelectField(getServiceCodeOptions)}
      >
        {({ fieldProps }) => (
          <Select
            isClearable={true}
            isLoading={isLoadingShipmentEnumeration}
            options={getServiceCodeOptions}
            placeholder="Choose a cost code"
            {...fieldProps}
          />
        )}
      </Field>

      <Field
        label="Allow calculation"
        type="select"
        name="allow_calculate"
        transform={transformSelectField(allowCalculationOptions)}
      >
        {({ fieldProps }) => (
          <Select
            isClearable={true}
            options={allowCalculationOptions}
            placeholder="Choose one"
            {...fieldProps}
          />
        )}
      </Field>

      <Field
        label="Calculation method"
        type="select"
        name="calculation_method"
        transform={transformSelectField(calculationMethodOptions)}
      >
        {({ fieldProps }) => (
          <Select
            isClearable={true}
            options={calculationMethodOptions}
            placeholder="Choose one"
            {...fieldProps}
          />
        )}
      </Field>

      <Field
        label="Format"
        type="select"
        name="type"
        transform={transformSelectField(formatOptions)}
      >
        {({ fieldProps }) => (
          <Select
            isClearable={true}
            options={formatOptions}
            placeholder="Choose one"
            {...fieldProps}
          />
        )}
      </Field>

      <Grid>
        <Grid.Col sm={6}>
          <Field label="From" name="created_from" defaultValue={appliedFilters.created_from || ''}>
            {({ fieldProps }) => <DatePicker placeholder="2/18/2022" {...fieldProps} />}
          </Field>
        </Grid.Col>

        <Grid.Col sm={6}>
          <Field label="To" name="created_to" defaultValue={appliedFilters.created_to || ''}>
            {({ fieldProps }) => <DatePicker placeholder="2/18/2022" {...fieldProps} />}
          </Field>
        </Grid.Col>
      </Grid>
    </>
  )
}

export default RatesFilterFields
