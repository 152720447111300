import { useMutation, useQueryClient } from '@tanstack/react-query'

// APIS
import { editShipmentParam } from 'api/shipments'

// Atlassian
import Spinner from '@atlaskit/spinner'
import InlineEdit from '@atlaskit/inline-edit'

// Interfaces
import { useFlags } from '@royan-co/user-interface'

// Components
import NumericInput from 'components/UI/NumericInput'


const ShipmentNumericInlineEdit = ({ value, attribute, shipmentId, canEdit, view, fullUpdate }) => {
  const queryClient = useQueryClient()
  const { showSuccessFlag, showWarningFlag } = useFlags()

  const changeTextMutation = useMutation({
    mutationFn: (data) => editShipmentParam(shipmentId, attribute, data),

    onSuccess: (res) => {
      showSuccessFlag(res.message)

      queryClient.setQueryData(['shipment', shipmentId], (oldShipment) => ({
        ...oldShipment,
        ...(fullUpdate ? res.shipment : { [attribute]: res.shipment[attribute] }),
      }))
    },

    onError: (e) => {
      console.error(e)
      showWarningFlag(e?.response?.data?.message || 'Something wrong happened.')
    },
  })

  const handleChangeText = (data) => {
    if (value === data || !data.trim()) return

    changeTextMutation.mutate({ [attribute]: data })
  }

  const viewTag = view || value

  return !canEdit ? (
    viewTag
  ) : (
    <div className="!-mt-2">
      <InlineEdit
        keepEditViewOpenOnBlur={true}
        defaultValue={value}
        editView={({ errorMessage, ...fieldProps }) => (
          <NumericInput {...fieldProps} placeholder="Type the number" isCompact autoFocus />
        )}
        readView={() => (
          <div className="flex items-center font-normal gap-1 my-1.5">
            {viewTag}
            {changeTextMutation.isLoading && <Spinner size="small" />}
          </div>
        )}
        onConfirm={(value) => handleChangeText(value)}
      />
    </div>
  )
}

export default ShipmentNumericInlineEdit
