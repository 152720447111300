import { Link, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

//Api
import { getPaymentTerms } from '../api/payment-terms-requests'

//Atlassian
import PageHeader from '@atlaskit/page-header'
import Button, { ButtonGroup } from '@atlaskit/button'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'

//Contexts
import { useAuth } from 'contexts/AuthProvider'

//Interfaces
import { RoyanTable, useDisclosure } from '@royan-co/user-interface'

//Components
import { generatePaymentTermsColumns } from '../components/List/columns'
import PaymentTermDeleteModal from '../components/views/DeleteModal'

const pageTitle = 'Payment terms'

const PaymentTermsListPage = () => {
  const navigate = useNavigate()
  const { currentUser } = useAuth()

  const [isOpenDeleteCodeModal, deleteCodeModalHandler] = useDisclosure('delete')

  const openDeleteModal = (codeId) => {
    deleteCodeModalHandler.open({ codeId })
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <PageHeader
        breadcrumbs={
          <Breadcrumbs>
            <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
            <BreadcrumbsItem text="Accounting" />
          </Breadcrumbs>
        }
        actions={
          <ButtonGroup>
            <Button appearance="primary" onClick={() => navigate('/panel/payment-terms/create')}>
              New payment term
            </Button>
          </ButtonGroup>
        }
      >
        {pageTitle}
      </PageHeader>

      <RoyanTable
        sortOrder="DESC"
        name="payment-terms-list"
        sortKey="code"
        request={getPaymentTerms}
        filters={[]}
        columns={generatePaymentTermsColumns(openDeleteModal, currentUser)}
      >
        <PaymentTermDeleteModal
          isOpen={isOpenDeleteCodeModal}
          onClose={deleteCodeModalHandler.close}
          codeId={deleteCodeModalHandler.data?.codeId}
        />
      </RoyanTable>
    </>
  )
}

export default PaymentTermsListPage
