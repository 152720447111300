import { createElement } from 'react'

//Hooks
import useConsolidation from 'hooks/useConsolidation'

// Contexts
import { useAuth } from 'contexts/AuthProvider'

// Features
import { AccessControl } from 'features/auth'

//Atlassian
import {
  Header,
  Section,
  LoadingItems,
  SideNavigation,
  NavigationHeader,
  NavigationContent,
  SkeletonHeadingItem,
} from '@atlaskit/side-navigation'

//Interfaces
import { AirFreightIcon, InfoIcon, LandFreightIcon, SeaFreightIcon } from '@royan-co/user-interface'

//Components
import SidebarLink from 'layouts/SidebarLink'
import ConsolidationStatusTag from 'components/Consolidations/StatusTag'
import { generateConsolidationUserPermissions } from 'components/Consolidations/Create/conslolidation-user-permissions'

const icons = {
  0: LandFreightIcon,
  1: SeaFreightIcon,
  2: AirFreightIcon,
}

const UserAccess = ({ children, limitation, item, consolidation }) => {
  const { currentUser } = useAuth()

  return limitation ? (
    <AccessControl
      resource={item?.limitationAccess?.resource}
      action={item?.limitationAccess?.action}
      userPermissions={generateConsolidationUserPermissions(consolidation, currentUser)}
    >
      {children}
    </AccessControl>
  ) : (
    children
  )
}

const ConsolidationLayoutSidebar = ({ pages }) => {
  const { isLoading, consolidation } = useConsolidation()

  return (
    <SideNavigation label="settings">
      <LoadingItems
        isLoading={isLoading}
        fallback={
          <>
            <NavigationHeader>
              <Header
                description={<SkeletonHeadingItem width="50%" />}
                iconBefore={<SkeletonHeadingItem width="100%" />}
              >
                <div className="py-3">
                  <SkeletonHeadingItem width="100%" />
                </div>
              </Header>
            </NavigationHeader>
          </>
        }
      >
        <NavigationHeader>
          <Header
            iconBefore={createElement(icons[consolidation?.shipment_type] || InfoIcon, {
              size: 26,
              className: 'text-neutral-500',
            })}
            description={
              <div className="removeMarginFromAtlaskitTagWrapper">
                <ConsolidationStatusTag value={consolidation?.status} />
              </div>
            }
          >
            {consolidation?.consolidation_code}
          </Header>
        </NavigationHeader>
      </LoadingItems>

      <NavigationContent>
        <Section>
          {pages.map((p, i) => (
            <UserAccess
              limitation={p?.limitationAccess}
              item={p}
              consolidation={consolidation}
              key={i}
            >
              <SidebarLink
                key={i}
                to={p.path}
                className="font-medium"
                iconBefore={createElement(p.icon)}
              >
                <div className="flex justify-between items-center">
                  {p.title}
                  {p?.notification ? createElement(p.notification, { consolidation }) : null}
                </div>
              </SidebarLink>
            </UserAccess>
          ))}
        </Section>
      </NavigationContent>
    </SideNavigation>
  )
}

export default ConsolidationLayoutSidebar
