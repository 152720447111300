import { useQuery } from '@tanstack/react-query'

// Apis
import { getBank } from './banks-requests'

export const useBank = (bankId, { enabled = true } = {}) => {
  return useQuery(['bank', bankId], () => getBank(bankId), {
    staleTime: Infinity,
    retry: 2,
    enabled,
  })
}
