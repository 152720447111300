// Utils
import { findOption } from 'utils/helpers'
import { calculationMethodOptions } from 'components/Rates/constants'
import { modeOptions } from 'components/Shipments/constants'
import { areaTypeOptions } from 'features/marginCalculator/utils/constants'

// Interfaces
import { DotsIcon } from '@royan-co/user-interface'

// Atlassian
import Button from '@atlaskit/button'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'
import Lozenge from '@atlaskit/lozenge'

// Components
import RegistryClassificationTag from 'components/Registries/ClassificationTag'
import MarginCalculatorTypeTag from '../tags/TypeTag'
import DropdownLink from 'components/UI/DropdownLink'
import MarginCalculatorRouteTag from '../tags/RouteTag'

export const generateMarginCalculatorColumns = (openDeleteModal) => {
  return [
    {
      key: 'shipment_type',
      title: 'Mode',
      isSortable: false,
      show: true,
      content: (item) => findOption(modeOptions, item?.shipment_type)?.label,
    },
    {
      key: 'country_area',
      title: 'Type',
      isSortable: false,
      show: true,
      content: (item) => (
        <>
          {item.must_go ? (
            <div className="mb-1">
              <Lozenge appearance="removed">Must go</Lozenge>
            </div>
          ) : (
            ''
          )}

          <MarginCalculatorTypeTag type={item.country_area} />
          <div className="my-1">
            {item.country_area === 0 ? (
              item.routes.map(({ departure_country, arrival_country }, i) => (
                <MarginCalculatorRouteTag
                  key={i}
                  departureCountry={departure_country}
                  arrivalCountry={arrival_country}
                />
              ))
            ) : (
              <small className="text-neutral-200">
                {item.area !== undefined
                  ? `(${findOption(areaTypeOptions, item.area)?.label})`
                  : ''}
              </small>
            )}
          </div>
        </>
      ),
    },
    {
      key: 'calculation_method',
      title: 'Calculation method',
      isSortable: false,
      show: true,
      content: (item) => findOption(calculationMethodOptions, item.calculation_method)?.label,
    },
    {
      key: 'type',
      title: 'Registry classification',
      isSortable: false,
      show: true,
      content: (item) => <RegistryClassificationTag value={item.type} />,
    },
    {
      key: 'creation_date',
      title: 'Creation date',
      isSortable: true,
      show: true,
      content: (item) => item.creation_date,
    },
    {
      key: 'actions',
      title: 'Actions',
      show: true,
      content: (item) => (
        <DropdownMenu
          placement="bottom-end"
          trigger={({ triggerRef, ...props }) => (
            <Button
              {...props}
              appearance="subtle"
              children={<DotsIcon label="more" />}
              ref={triggerRef}
            />
          )}
        >
          <DropdownItemGroup>
            <DropdownLink children="View" to={`/panel/margin-calculator/${item.id}`} />
            <DropdownLink children="Edit" to={`/panel/margin-calculator/${item.id}/edit`} />
            <DropdownItem children="Delete" onClick={() => openDeleteModal(item)} />
          </DropdownItemGroup>
        </DropdownMenu>
      ),
    },
  ]
}
