import { useMemo } from 'react'
import { Link, useParams } from 'react-router-dom'

// Hooks
import useConsolidationShipments from 'hooks/useConsolidationShipments'
import useConsolidationFinance from 'hooks/useConsolidationFinance'

// Utils
import { formatMonetaryNumber } from 'utils/numbers'

// Atlassian
import Button from '@atlaskit/button'
import Spinner from '@atlaskit/spinner'
import SectionMessage from '@atlaskit/section-message'
import Heading from '@atlaskit/heading'

// Components
import ConsolidationFinanceGroupageTable from 'components/Consolidations/View/FinanceGroupageTable'
import ConsolidationFinanceTable from 'components/Consolidations/View/FinanceTable'

const ConsolidationViewFinancePage = () => {
  const { consolidationId } = useParams()

  const {
    data: consolidationGroupages,
    isLoading: isLoadingGroupage,
    isError: isErrorGroupage,
    refetch: refetchGroupage,
  } = useConsolidationFinance(consolidationId)
  const {
    data: consolidationShipments,
    isLoading: isLoadingShipments,
    isError: isErrorShipments,
    refetch: refetchShipments,
  } = useConsolidationShipments(consolidationId)

  const totalGroupagesCost = useMemo(
    () => consolidationGroupages?.rows?.reduce((p, c) => p + (c?.real_cost || c?.cost) * 1, 0),
    [consolidationGroupages]
  )
  const totalGroupagesQuotation = useMemo(
    () => consolidationGroupages?.rows?.reduce((p, c) => p + c?.quotation * 1, 0),
    [consolidationGroupages]
  )
  const shipments = useMemo(() => {
    const totalShipmentsChargeableWeights = consolidationShipments?.reduce(
      (p, c) => p + (c.chargeable_weight * 1 || 0),
      0
    )

    return (
      consolidationShipments?.map((item) => {
        const rate = totalGroupagesCost / totalShipmentsChargeableWeights
        const shipmentPrecarriage = +item.financial_status.cost
        const shipmentHousecharter = rate * item.chargeable_weight
        const shipmentCost = shipmentPrecarriage + shipmentHousecharter
        const shipmentQuotation = item.financial_status.quotation
        const shipmentMargin = shipmentQuotation - shipmentCost

        return {
          ...item,
          shipmentPrecarriage,
          shipmentHousecharter,
          shipmentCost,
          shipmentQuotation,
          shipmentMargin,
        }
      }) || []
    )
  }, [consolidationShipments, totalGroupagesCost])

  const totalMargin = useMemo(() => {
    return (
      shipments.reduce((p, c) => p + c.shipmentQuotation * 1, 0) +
      totalGroupagesQuotation -
      (shipments.reduce((p, c) => p + c.shipmentCost * 1, 0) + totalGroupagesCost)
    )
  }, [shipments, totalGroupagesCost, totalGroupagesQuotation])

  return (
    <>
      {isLoadingShipments ? (
        <div className="text-center my-8">
          <Spinner />
        </div>
      ) : isErrorShipments ? (
        <SectionMessage appearance="warning" title="Something wrong on loading data, please retry.">
          <Button onClick={() => refetchShipments()}>Retry</Button>
        </SectionMessage>
      ) : (
        <ConsolidationFinanceTable rows={shipments} />
      )}

      <div className="mt-7">
        <div className="flex items-center mb-3">
          <Heading level="h600">Groupage costs</Heading>
          <Button
            component={Link}
            appearance="link"
            className="ml-auto"
            to={`/panel/consolidations/${consolidationId}/finance/edit`}
          >
            Edit costs
          </Button>
        </div>

        {isLoadingGroupage ? (
          <div className="text-center my-8">
            <Spinner />
          </div>
        ) : isErrorGroupage ? (
          <SectionMessage
            appearance="warning"
            title="Something wrong on loading data, please retry."
          >
            <Button onClick={() => refetchGroupage()}>Retry</Button>
          </SectionMessage>
        ) : (
          <div className="mt-5">
            <ConsolidationFinanceGroupageTable
              rows={consolidationGroupages?.rows}
              totalCost={totalGroupagesCost}
              totalQuotation={totalGroupagesQuotation}
            />

            {/* <div
              className={`ml-auto -mt-3 mb-4 text-white p-3 ${
                totalMargin > 0 ? 'bg-green-400' : 'bg-red-300'
              }`}
              style={{ width: '212px' }}
            >
              <small>Total margin</small>
              <br />
              {formatMonetaryNumber(Math.abs(totalMargin))}
            </div> */}
          </div>
        )}
      </div>
    </>
  )
}

export default ConsolidationViewFinancePage
