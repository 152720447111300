import { Link, useParams } from 'react-router-dom'

import { formatNumber } from 'utils/numbers'

//Hooks
import useRateRecords from 'hooks/useRateRecords'

//Atlassian
import Lozenge from '@atlaskit/lozenge'
import Button from '@atlaskit/button'
import Spinner from '@atlaskit/spinner'
import SectionMessage from '@atlaskit/section-message'
import EmptyState from '@atlaskit/empty-state'
import { DynamicTableStateless } from '@atlaskit/dynamic-table'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'

//Interfaces
import { DotsIcon, PlusIcon, useDisclosure } from '@royan-co/user-interface'

//Components
import Card from 'components/UI/Card'
import RateCostFormat from 'components/Rates/CostFormat'
import CountryFlag from 'components/UI/CountryFlag'
import RatesRecordsZonesModal from 'components/Rates/view/RecordsZonesModal'
import RatesRecordsStepsModal from 'components/Rates/view/RecordsStepsModal'
import RatesDeleteRecordModal from 'components/Rates/view/DeleteRecordModal'
import DropdownLink from 'components/UI/DropdownLink'

const RatesRecordsPage = () => {
  let { rateId } = useParams()
  const { records, isLoadingRateRecords, isErrorRateRecords, refetchRateRecords } =
    useRateRecords(rateId)

  const [openZonesModal, zonesModalHandlers] = useDisclosure('zones')
  const [openStepsModal, stepsModalHandlers] = useDisclosure('steps')
  const [openDeleteRecordModal, deleteRecordModalHandlers] = useDisclosure('delete-record')

  const tableHead = {
    cells: [
      { content: 'Description' },
      { content: 'Departure' },
      { content: 'Arrival' },
      { content: 'Transit time' },
      { content: 'Minimum cost' },
      { content: 'Minimum quotation' },
      { content: 'Status' },
      { content: 'Actions' },
    ],
  }

  const tableRow = records?.map((record, index) => {
    const arrivalZones = record?.zones?.filter((z) => !z.type || z.type === 'arrival')
    const departureZones = record?.zones?.filter((z) => !z.type || z.type === 'departure')

    return {
      key: index,
      cells: [
        { content: record?.description },
        {
          content: [1, 2, 8, 14].includes(record?.table_rate?.from_to) ? (
            <div className="flex items-center">
              <CountryFlag iso={record?.departure || record?.table_rate?.departure} />
              {departureZones?.length > 0 && (
                <Button appearance="link" onClick={() => zonesModalHandlers.open(departureZones)}>
                  <small>{`(${departureZones.length} zones)`}</small>
                </Button>
              )}
            </div>
          ) : (
            record?.departure || record?.table_rate?.departure
          ),
        },
        {
          content: [0, 2, 9, 13].includes(record?.table_rate?.from_to) ? (
            <div className="flex items-center">
              <CountryFlag iso={record?.arrival || record?.table_rate?.arrival} />
              {arrivalZones?.length > 0 && (
                <Button appearance="link" onClick={() => zonesModalHandlers.open(arrivalZones)}>
                  <small>{`(${arrivalZones.length} zones)`}</small>
                </Button>
              )}
            </div>
          ) : (
            record?.arrival || record?.table_rate?.arrival
          ),
        },
        {
          content: record?.transit_time ? (
            formatNumber(record?.transit_time) + ' days'
          ) : (
            <Lozenge appearance="default">Not set</Lozenge>
          ),
        },
        { content: <RateCostFormat value={record?.minimum} /> },
        { content: <RateCostFormat value={record?.minimum_sell_price} /> },
        {
          content: record?.allow_calculate ? (
            <Lozenge appearance="success">Active</Lozenge>
          ) : (
            <Lozenge appearance="default">Disabled</Lozenge>
          ),
        },
        {
          content: (
            <DropdownMenu
              placement="bottom-end"
              trigger={({ triggerRef, ...props }) => (
                <Button
                  {...props}
                  appearance="subtle"
                  children={<DotsIcon label="more" />}
                  ref={triggerRef}
                />
              )}
            >
              <DropdownItemGroup>
                <DropdownItem children="Steps" onClick={() => stepsModalHandlers.open(record)} />
                <DropdownLink
                  children="Edit"
                  to={`/panel/rates/${rateId}/records/${record?.id_auto}/edit`}
                />
                <DropdownItem
                  children="Delete"
                  onClick={() => deleteRecordModalHandlers.open(record)}
                />
              </DropdownItemGroup>
            </DropdownMenu>
          ),
        },
      ],
    }
  })

  return (
    <>
      {isLoadingRateRecords ? (
        <div className="text-center mt-24">
          <Spinner />
        </div>
      ) : isErrorRateRecords ? (
        <SectionMessage
          appearance="warning"
          title="Something wrong on loading records, please retry."
        >
          <Button onClick={() => refetchRateRecords()}>Retry</Button>
        </SectionMessage>
      ) : (
        <>
          <Button
            appearance="default"
            iconBefore={<PlusIcon />}
            component={Link}
            to={`/panel/rates/${rateId}/records/create`}
          >
            Add new record
          </Button>

          {!records || records?.length < 1 ? (
            <EmptyState header="No records are available." />
          ) : (
            <Card className=" mt-6">
              <div className="overflow-auto inline-block w-full -mb-6">
                <DynamicTableStateless head={tableHead} rows={tableRow} />
              </div>
            </Card>
          )}

          <RatesRecordsZonesModal
            isOpen={openZonesModal}
            onClose={zonesModalHandlers.close}
            zones={zonesModalHandlers.data}
          />

          <RatesRecordsStepsModal
            isOpen={openStepsModal}
            onClose={stepsModalHandlers.close}
            record={stepsModalHandlers.data}
          />

          <RatesDeleteRecordModal
            isOpen={openDeleteRecordModal}
            onClose={deleteRecordModalHandlers.close}
            record={deleteRecordModalHandlers.data}
          />
        </>
      )}
    </>
  )
}

export default RatesRecordsPage
