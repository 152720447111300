import { useMemo } from 'react'
import { useMutation } from '@tanstack/react-query'
import { useMediaQuery } from 'react-responsive'

// Apis
import { createInvoiceRow, editInvoiceRow } from '../api/row'
import { useUpdateInvoice } from '../api/getInvoice'

// Utils
import { getFilled, transformSelectField } from 'utils/helpers'
import { handleErrorOnSubmit } from 'utils/errors'

// Hooks
import useShipmentsEnumerations from 'hooks/useShipmentsEnumerations'
import useInvoiceEnumerations from 'hooks/useInvoiceEnumerations'

// Atlassian
import { LoadingButton } from '@atlaskit/button'
import Drawer from '@atlaskit/drawer'
import Textfield from '@atlaskit/textfield'
import Select from '@atlaskit/select'
import TextArea from '@atlaskit/textarea'
import { ErrorMessage } from '@atlaskit/form'

// Interfaces
import { Field, Form, WarningBannerField, useFlags } from '@royan-co/user-interface'

const RowDrawer = ({ onClose, isOpen, row, invoiceId }) => {
  const isMobile = useMediaQuery({ query: `(max-width: 991.98px)` })
  const { showSuccessFlag } = useFlags()

  const isEditing = !!row?.id_auto
  const pageTitle = useMemo(() => (isEditing ? 'Edit row' : 'New empty row'), [isEditing])

  const DefaultValues = useMemo(
    () => ({
      service_code: getFilled(row, 'cost_code', null),
      description: getFilled(row, 'service', ''),
      amount: getFilled(row, 'amount', ''),
      quantity: getFilled(row, 'quantity', ''),
      iva_code: getFilled(row, 'iva_code', null),
      iva_amount: getFilled(row, 'iva_amount', ''),
    }),
    [row]
  )

  const { upsertInvoiceProperties } = useUpdateInvoice(invoiceId)

  const { getServiceCodeOptions, isLoadingShipmentEnumeration } = useShipmentsEnumerations()
  const { ivaCodeOptions, getIvaCodeRate, isLoadingInvoiceEnumerations } = useInvoiceEnumerations()

  const getIvaAmountValue = ([totalAmount, ivaCode]) => {
    if (ivaCode && totalAmount * 1) {
      const rate = getIvaCodeRate(ivaCode)
      return (rate * totalAmount) / 100
    } else return 0
  }

  const rowCreationMutation = useMutation(
    isEditing ? editInvoiceRow.bind(null, row.id_auto) : createInvoiceRow.bind(null, invoiceId)
  )

  const handleCloseDrawer = () => {
    rowCreationMutation.reset()
    onClose()
  }

  const handleSubmit = (data, { setError }) => {
    rowCreationMutation.mutate(data, {
      onError: (e) => {
        handleErrorOnSubmit(e, setError, data)
        console.error(e)
      },
      onSuccess: (res) => {
        upsertInvoiceProperties(res.invoice)
        showSuccessFlag(res.message)
        onClose()
      },
    })
  }

  return (
    <Drawer isOpen={isOpen} onClose={handleCloseDrawer} width={isMobile ? 'full' : 'medium'}>
      <div className="drawer">
        <h3>{pageTitle}</h3>

        <Form onSubmit={handleSubmit} defaultValues={DefaultValues}>
          {() => (
            <>
              <Field
                isRequired
                type="select"
                name="service_code"
                label="Code"
                className="!mt-4"
                transform={transformSelectField(getServiceCodeOptions)}
              >
                {({ fieldProps, error }) => (
                  <>
                    <Select
                      menuPlacement="auto"
                      placeholder="Choose a code"
                      isLoading={isLoadingShipmentEnumeration}
                      options={getServiceCodeOptions}
                      {...fieldProps}
                    />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>

              <Field isRequired name="description" label="Description" className="!mt-4">
                {({ fieldProps, error }) => (
                  <>
                    <TextArea
                      placeholder="Type your content here"
                      {...fieldProps}
                      minimumRows={3}
                    />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>

              <div className="grid md:grid-cols-2 md:gap-4">
                <Field
                  isRequired
                  name="amount"
                  label="Total amount"
                  className="!mt-4"
                  placeholder="Type in EUR"
                >
                  {({ fieldProps, error }) => (
                    <>
                      <Textfield {...fieldProps} />
                      {error && <ErrorMessage>{error}</ErrorMessage>}
                    </>
                  )}
                </Field>

                <Field
                  isRequired
                  name="quantity"
                  label="Quantity"
                  className="!mt-4"
                  placeholder="Type quantity"
                >
                  {({ fieldProps, error }) => (
                    <>
                      <Textfield {...fieldProps} />
                      {error && <ErrorMessage>{error}</ErrorMessage>}
                    </>
                  )}
                </Field>
              </div>

              <div className="grid md:grid-cols-2 md:gap-4">
                <Field
                  isRequired
                  type="select"
                  name="iva_code"
                  label="IVA code"
                  className="!mt-4"
                  transform={transformSelectField(ivaCodeOptions)}
                >
                  {({ fieldProps, error }) => (
                    <>
                      <Select
                        menuPlacement="auto"
                        placeholder="Choose"
                        isClearable
                        isLoading={isLoadingInvoiceEnumerations}
                        options={ivaCodeOptions}
                        {...fieldProps}
                      />
                      {error && <ErrorMessage>{error}</ErrorMessage>}
                    </>
                  )}
                </Field>

                <Field
                  isRequired
                  name="iva_amount"
                  label="IVA Amount"
                  isDisabled
                  className="!mt-4"
                  calculate={{
                    field: ['amount', 'iva_code'],
                    callback: getIvaAmountValue,
                  }}
                >
                  {({ fieldProps, error }) => (
                    <>
                      <Textfield {...fieldProps} /> {error && <ErrorMessage>{error}</ErrorMessage>}
                    </>
                  )}
                </Field>
              </div>

              <WarningBannerField />

              <LoadingButton
                type="submit"
                className="mt-6"
                shouldFitContainer
                appearance="primary"
                isLoading={rowCreationMutation.isLoading}
              >
                Apply
              </LoadingButton>
            </>
          )}
        </Form>
      </div>
    </Drawer>
  )
}

export default RowDrawer
