import { Fragment, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { getRegistryHistory } from 'api/registries'
import { useInfiniteQuery } from '@tanstack/react-query'

//Interfaces
import { Timeline, TimelineItem } from '@royan-co/user-interface'

//Atlassian
import Spinner from '@atlaskit/spinner'
import SectionMessage from '@atlaskit/section-message'
import Button, { LoadingButton } from '@atlaskit/button'

const RegistryViewHistoryPage = () => {
  const { registryId } = useParams()

  const historyQuery = useInfiniteQuery(
    ['registry-history', registryId],
    ({ pageParam }) => {
      return getRegistryHistory(registryId, pageParam)
    },
    {
      staleTime: Infinity,
      getNextPageParam: (lastPage) =>
        lastPage?.next_page_url ? { page: lastPage.current_page + 1 } : undefined,
    }
  )

  const remainCount = useMemo(() => {
    let total = historyQuery.data?.pages?.[0]?.total || 0
    let loadedCount = historyQuery.data?.pages?.reduce((pr, cr) => pr + cr?.data?.length || 0, 0)
    return total - loadedCount
  }, [historyQuery.data?.pages])

  return historyQuery.isLoading ? (
    <div className="flex justify-center my-32">
      <Spinner />
    </div>
  ) : historyQuery.error && !historyQuery.data?.pages?.[0] ? (
    <SectionMessage
      appearance="warning"
      title="Something wrong on loading history data, please retry"
    >
      <Button onClick={() => historyQuery.fetchNextPage()}>Retry</Button>
    </SectionMessage>
  ) : (
    <div>
      {!historyQuery.data?.pages?.[0]?.total ? (
        !historyQuery.isError && <>No history</>
      ) : (
        <>
          <Timeline>
            {historyQuery.data.pages.map((group, i) => (
              <Fragment key={i}>
                {group.data.map((history) => (
                  <TimelineItem
                    info={history.text}
                    title={history.title}
                    key={`history-${history.id}`}
                    description={`Recorded at ${history.date} by ${history.creator?.full_name}`}
                  />
                ))}
              </Fragment>
            ))}
            {historyQuery.hasNextPage && (
              <TimelineItem
                title={
                  <LoadingButton
                    appearance="link"
                    spacing="compact"
                    isLoading={historyQuery.isFetchingNextPage}
                    className="mt-1.5 !px-0 !text-xs !font-normal !text-left"
                    onClick={() => historyQuery.fetchNextPage()}
                  >
                    Load more
                    {historyQuery.isError && (
                      <span className="block text-yellow-500 mr-2">
                        Something wrong on loading next data, please retry
                      </span>
                    )}
                  </LoadingButton>
                }
                bullet={
                  <div
                    className="bg-neutral-30 text-neutral-200 rounded-full text-center mt-1"
                    style={{ width: '20px', height: '20px', fontSize: '9px', lineHeight: '20px' }}
                  >
                    {remainCount > 99 ? `+99` : remainCount}
                  </div>
                }
              />
            )}
          </Timeline>
        </>
      )}
    </div>
  )
}

export default RegistryViewHistoryPage
