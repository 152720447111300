// Atlassian
import Modal, { ModalTitle, ModalHeader, ModalTransition } from '@atlaskit/modal-dialog'

// Interfaces
import { Form, WarningBannerField, useFormContext } from '@royan-co/user-interface'

// Components
import LinkInvoiceToShipmentForm from './LinkInvoiceToShipmentForm'
import LinkInvoiceFormButtons from './LinkInvoiceFormButtons'

const ImportRowModal = ({ isOpen, onClose }) => {
  const { getValues, setValue } = useFormContext()

  const handleSubmit = (data) => {
    const existingRows = getValues('rows')
    const rows = data.rows.filter((r) => !!r.checked)

    setValue('rows', [...rows, ...existingRows])
    onClose()
  }

  const handleCloseModal = () => {
    onClose()
  }

  return (
    <>
      <ModalTransition>
        {isOpen && (
          <Modal width={'large'} onClose={handleCloseModal} shouldScrollInViewport>
            <ModalHeader>
              <ModalTitle>Import rows</ModalTitle>
            </ModalHeader>

            <Form onSubmit={handleSubmit}>
              {() => (
                <>
                  <LinkInvoiceToShipmentForm isCumulative />

                  <div className="px-6">
                    <WarningBannerField />

                    <LinkInvoiceFormButtons onCancel={handleCloseModal} isLoadingSubmit={false} />
                  </div>
                </>
              )}
            </Form>
          </Modal>
        )}
      </ModalTransition>
    </>
  )
}

export default ImportRowModal
