import { useParams } from 'react-router-dom'
import { useMemo } from 'react'

// Hooks
import { useLine } from '../hooks/useLine'

// Components
import Card from 'components/UI/Card'
import RowTable from 'components/UI/RowTable'
import CountryFlag from 'components/UI/CountryFlag'

const LineViewGeneralTab = () => {
  const { lineId } = useParams()
  const { data: line } = useLine(lineId)

  const rows = useMemo(() => {
    let items = [
      {
        title: 'Departure country',
        content: <CountryFlag iso={line?.departure_country} />,
      },
      {
        title: 'Arrival country',
        content: <CountryFlag iso={line?.arrival_country} />,
      },
    ]

    return items
  }, [line])
  return (
    <>
      <Card className="mb-6">
        <RowTable rows={rows} />
      </Card>
    </>
  )
}

export default LineViewGeneralTab
