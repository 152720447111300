import axios from 'services/Request'

export function getDocumentTypes(params) {
  return axios.get('/api/accounting/document-codes', { params })
}

export function createDocumentType(data) {
  return axios.post('/api/accounting/document-codes', data)
}

export function editDocumentType(typeId, data) {
  return axios.put(`/api/accounting/document-codes/${typeId}`, data)
}

export function deleteDocumentType(typeId) {
  return axios.delete(`/api/accounting/document-codes/${typeId}`)
}
