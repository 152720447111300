import { useCallback, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { getSurchargeEnumeration } from 'api/rates'

const useSurchargeEnumeration = ({ enabled = true, mode } = {}) => {
  const { isLoading, data } = useQuery(
    ['Surcharge'],
    () => getSurchargeEnumeration({ paginate: 0, mode }),
    {
      staleTime: Infinity,
      retry: 2,
      enabled,
    }
  )

  const surchargeOptions = useMemo(() => {
    if (!data) return []

    return data.map((surcharge) => ({ label: surcharge.name, value: surcharge.id }))
  }, [data])

  const getSurchargeCalculationMethod = useCallback(
    (surchargeId) => {
      const surcharge = data?.find((s) => s.id === surchargeId)
      return surcharge?.calculation_method
    },
    [data]
  )

  const getSurchargeOptionsByShipmentType = useCallback(
    (id) => {
      if (!data) return []

      return data
        .filter((s) => s.shipment_type === id)
        .map((surcharge) => ({ label: surcharge.name, value: surcharge.id }))
    },
    [data]
  )

  const getSurchargeById = useCallback(
    (surchargeId) => data?.find((s) => s.id === surchargeId),
    [data]
  )

  return {
    surchargeOptions,
    getSurchargeById,
    getSurchargeCalculationMethod,
    getSurchargeOptionsByShipmentType,
    isLoadingSurcharge: isLoading,
  }
}

export default useSurchargeEnumeration
