import { getCountry, getFilled } from 'utils/helpers'

export const getCMRDefaultValues = (cmr, currentUser) => {
  let defaultValues = {
    number: cmr?.shipment?.reference || '',
    consignor: getFilled(cmr, 'shipper_text', ''),
    consigned: getFilled(cmr, 'consignee_text', ''),
    pod: getFilled(cmr, 'delivery_place', ''),
    pol: getFilled(cmr, 'receipt_place', '') + ' ' + getFilled(cmr, 'receipt_date', ''),
    attachmets: getFilled(cmr, 'documents', ''),
    successiveCarriers: getFilled(cmr, 'carrier_text', ''),
    carrierObservation: getFilled(cmr, 'transport_reserves', ''),
    class: '',
    number2: '',
    letter: '',
    adr: '',
    senderInstructions: getFilled(cmr, 'agent_text', ''),
    agreements: getFilled(cmr, 'special_agreements', ''),
    carriagePaid: '',
    carriageForward: '',
    establishedIn: getFilled(cmr, 'compiled_at', ''),
    establishedOn: getFilled(cmr, 'compiled_date', ''),
    cashOnDelivery: '',
    receivedPlace: '',
    receivedOn: '',
    signature:
      [cmr?.shipper?.company_name?.toUpperCase(), cmr?.shipper_address?.address]
        ?.filter((i) => !!i)
        .join('\n') +
      '\n' +
      cmr?.shipper_address?.city +
      ' - ' +
      [
        getCountry(cmr?.shipper_address?.country_iso)?.name,
        cmr?.shipper_address?.cap,
        cmr?.shipper_address?.telephone,
        cmr?.shipper_address?.fiscal_code,
      ]
        ?.filter((i) => !!i)
        ?.join(' '),
    carrierSignature: getFilled(cmr, 'carrier?.company_name', '')?.toUpperCase(),
    consigneeSignature: '',
    truckNumber: getFilled(cmr, 'truck_number', ''),
    trailerNumber: getFilled(cmr, 'trailer_number', ''),
    packages: [
      {
        number: getFilled(cmr, 'marks_and_number', ''),
        packages: getFilled(cmr, 'collies_number', ''),
        packing: getFilled(cmr, 'packaging', ''),
        goods: getFilled(cmr, 'current_denomination', ''),
        statistical: getFilled(cmr, 'statistics_number', ''),
        gross: getFilled(cmr, 'weight', ''),
        volume: getFilled(cmr, 'cubic_meter', ''),
      },
    ],
  }

  return defaultValues
}
