import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

//Hooks
import useConsolidation from 'hooks/useConsolidation'

//Atlassian
import Lozenge from '@atlaskit/lozenge'

import { Grid, ShortcutIcon } from '@royan-co/user-interface'

//Components
import RowTable from 'components/UI/RowTable'
import CountryFlag from 'components/UI/CountryFlag'
import Card, { CardSubtitle } from 'components/UI/Card'
import ShipmentContactShow from 'components/Shipments/ContactShow'
import TableItem from 'components/UI/TableItem'

const ConsolidationGeneralDetail = () => {
  const isMobile = useMediaQuery({ query: `(max-width: 991.98px)` })

  const { consolidation } = useConsolidation()

  const generalRows = useMemo(() => {
    let generalRowsItem = [
      {
        title: 'Consolidator hub',
        content: <TableItem condition={consolidation?.hub?.name} />,
      },
      { title: 'Arrival country', content: <CountryFlag iso={consolidation?.arrival_country} /> },
      {
        title: 'Temperature control',
        content: consolidation?.reefer ? (
          <>
            <Lozenge appearance="moved">Yes</Lozenge>
            <small className="text-neutral-200 ml-2">
              {consolidation?.reefer_temperature
                ?.split(',')
                ?.map((t) => (t > 0 ? `+${t}` : t))
                ?.join(', ')}
              {' °C'}
            </small>
          </>
        ) : (
          <Lozenge appearance="default">NO</Lozenge>
        ),
      },
      { title: 'Creator', content: <TableItem condition={consolidation?.creator?.full_name} /> },
      { title: 'Creation date', content: <TableItem condition={consolidation?.creation_date} /> },
    ]

    return generalRowsItem
  }, [consolidation])

  const receivingAgentRows = useMemo(() => {
    let rowsItems = [
      {
        title: 'Name',
        content: (
          <TableItem condition={consolidation?.receiving_agent?.id_auto}>
            <Link
              to={`/panel/registries/${consolidation?.receiving_agent?.id_auto}`}
              target="_blank"
            >
              {consolidation?.receiving_agent?.company_name} <ShortcutIcon size={16} />
            </Link>
          </TableItem>
        ),
      },

      {
        title: 'Contact',
        content: <ShipmentContactShow contact={consolidation?.receiving_agent_contact} />,
      },
    ]

    return rowsItems
  }, [consolidation])

  return (
    <>
      <Card className="mb-0">
        <Grid className="w-full">
          <Grid.Col lg={6}>
            <RowTable
              rows={generalRows}
              verticalAlign="align-top"
              width={isMobile ? 'w-1/2' : 'w-1/3'}
            />
          </Grid.Col>

          <Grid.Col lg={6}>
            <CardSubtitle className="mb-3">Receiving agent</CardSubtitle>

            {consolidation?.receiving_agent ? (
              <RowTable
                rows={receivingAgentRows}
                verticalAlign="align-top"
                width={isMobile ? 'w-1/2' : 'w-1/3'}
              />
            ) : (
              <Lozenge appearance="moved">NOT PROVIDED</Lozenge>
            )}
          </Grid.Col>
        </Grid>
      </Card>
    </>
  )
}

export default ConsolidationGeneralDetail
