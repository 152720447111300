import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

// Apis
import { deleteAirWaybill } from './airWaybill-requests'

// Interfaces
import { useFlags, useRoyanTable } from '@royan-co/user-interface'

const useDeleteAirWaybill = (documentId) => {
  const navigate = useNavigate()
  const { refresh } = useRoyanTable()
  const { showSuccessFlag, showWarningFlag } = useFlags()

  return useMutation({
    mutationFn: () => deleteAirWaybill(documentId),

    onSuccess: (res) => {
      showSuccessFlag(res.message)
      if (refresh) {
        refresh()
      } else navigate('/panel/airwaybills')
    },

    onError: (e) => {
      console.error(e)
      showWarningFlag('Something wrong!')
    },
  })
}

export { useDeleteAirWaybill }
