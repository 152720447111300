import { useEffect, useRef, useState } from 'react'

//Styles
import styles from './Navbar.module.scss'

//Images
import User from './../assets/user.png'

const Account = () => {
  const [dropdownToggle, setDropdownToggle] = useState(false)
  const dropdownRef = useRef()

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!dropdownRef?.current?.contains(event.target)) {
        setDropdownToggle(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
  }, [dropdownRef])

  return (
    <>
      <div className={styles.navbar__dropdown} ref={dropdownRef}>
        <button
          className={styles.dropdown__button}
          id="dropdown__button"
          onClick={() => setDropdownToggle((t) => !t)}
        >
          <img src={User} alt="" />
          <span> My Account</span>
        </button>

        <ul
          className={`${styles.dropdown__menu} ${dropdownToggle ? styles.show__toggle : ''}`}
          id="dropdown__menu"
        >
          <li>
            <a href={`${process.env.REACT_APP_ACCOUNT_URL}/login?from=${window.location.href}`}>
              Login
            </a>
          </li>
        </ul>
      </div>
    </>
  )
}

export default Account
