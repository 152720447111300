import React from 'react'
import { useMutation } from '@tanstack/react-query'

// Apis
import { deletePaymentTerm } from './../../api/payment-terms-requests'

//Atlassian
import Modal, {
  ModalBody,
  ModalTitle,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import Button, { LoadingButton } from '@atlaskit/button'

//Interfaces
import { useFlags, WarningBanner, useRoyanTable } from '@royan-co/user-interface'

const PaymentTermDeleteModal = ({ isOpen, onClose, codeId }) => {
  const { showSuccessFlag, showWarningFlag } = useFlags()
  const { refresh } = useRoyanTable()

  const codeDeleteMutation = useMutation(deletePaymentTerm)

  function closeModal() {
    onClose()
    codeDeleteMutation.reset()
  }

  const removeDocumentType = () => {
    codeDeleteMutation.mutate(codeId, {
      onError: (e) => {
        showWarningFlag('Something wrong!')
        console.error(e)
      },
      onSuccess: (res) => {
        showSuccessFlag(res.message)
        refresh()
        onClose()
      },
    })
  }

  return (
    <>
      <ModalTransition>
        {isOpen && (
          <Modal onClose={closeModal}>
            <ModalHeader>
              <ModalTitle appearance="danger">Delete payment term</ModalTitle>
            </ModalHeader>
            <ModalBody>
              You are about to delete a payment term. Are you sure?
              {codeDeleteMutation.isError && <WarningBanner />}
            </ModalBody>
            <ModalFooter>
              <Button appearance="subtle" onClick={closeModal}>
                Cancel
              </Button>
              <LoadingButton
                isLoading={codeDeleteMutation.isLoading}
                appearance="danger"
                onClick={removeDocumentType}
                autoFocus
              >
                Delete
              </LoadingButton>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </>
  )
}

export default PaymentTermDeleteModal
