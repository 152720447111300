import Lozenge from '@atlaskit/lozenge'
import { findOption } from 'utils/helpers'
import { registryClassificationOptions } from './constants'

const RegistryClassificationTag = ({ value }) => {
  const option = findOption(registryClassificationOptions, value)

  return option ? <Lozenge appearance={option.appearance}>{option.label}</Lozenge> : null
}

export default RegistryClassificationTag
