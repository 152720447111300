import { useQuery } from '@tanstack/react-query'

// API
import { getCommercialInvoices } from 'api/shipments'

const useShipmentCommercialInvoices = (shipmentId) => {
  const queryKey = ['shipment', 'commercial-invoices', shipmentId]

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: () => getCommercialInvoices(shipmentId),
    retry: 2,
    staleTime: Infinity,
  })

  return {
    shipmentCommercialInvoices: data,
    isLoadingCommercialInvoices: isLoading,
  }
}

export default useShipmentCommercialInvoices
