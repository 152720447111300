import { useEffect } from 'react'
import { findOption } from 'utils/helpers'
import useRegistriesCreators from 'hooks/useRegistryCreators'
import {
  registryTypeOptions,
  registryClientTypeOptions,
  registryAccountingArcaOptions,
  registryFreightForwarderOptions,
} from '../constants'

//Atlassian
import TextField from '@atlaskit/textfield'
import { DatePicker } from '@atlaskit/datetime-picker'
import Select, { CountrySelect } from '@atlaskit/select'
import { allCountries as countries } from '/node_modules/@atlaskit/select/dist/es2019/data/countries.js'

//Interfaces
import { Field, Grid, useFormContext } from '@royan-co/user-interface'

const RegistriesFilterFields = () => {
  const { watch, setValue } = useFormContext()
  const { registryCreatorOptions, registryGroupOptions, isLoadingRegistryCreators } =
    useRegistriesCreators()

  const type = watch('type')

  useEffect(() => {
    if (type !== 0) setValue('client_type', undefined)
  }, [type, setValue])

  return (
    <>
      <Field label="Code" name="numerator_code">
        {({ fieldProps }) => <TextField placeholder="Type the code" {...fieldProps} />}
      </Field>

      <Field
        label="Type"
        type="select"
        name="type"
        transform={{
          output: (opt) => (opt?.value !== undefined ? opt.value : null),
          input: (v) => findOption(registryTypeOptions, v),
        }}
      >
        {({ fieldProps }) => (
          <Select
            isClearable={true}
            options={registryTypeOptions}
            placeholder="Choose a type"
            {...fieldProps}
          />
        )}
      </Field>

      {type === 0 && (
        <Field
          label="Client type"
          type="select"
          name="client_type"
          transform={{
            output: (opt) => (opt?.value !== undefined ? opt.value : null),
            input: (v) => findOption(registryClientTypeOptions, v),
          }}
        >
          {({ fieldProps }) => (
            <Select
              isClearable={true}
              options={registryClientTypeOptions}
              placeholder="Choose a clinet type"
              {...fieldProps}
            />
          )}
        </Field>
      )}

      <Field
        label="Freight forwarder"
        type="select"
        name="freight_forwarder"
        transform={{
          output: (opt) => (opt?.value !== undefined ? opt.value : null),
          input: (v) => findOption(registryFreightForwarderOptions, v),
        }}
      >
        {({ fieldProps }) => (
          <Select
            isClearable={true}
            options={registryFreightForwarderOptions}
            placeholder="Choose"
            {...fieldProps}
          />
        )}
      </Field>

      <Field
        type="select"
        label="Creator"
        name="user_id"
        transform={{
          output: (opt) => (opt?.value !== undefined ? opt.value : null),
          input: (v) => findOption(registryCreatorOptions, v),
        }}
      >
        {({ fieldProps }) => (
          <Select
            isClearable={true}
            placeholder="Choose"
            options={registryCreatorOptions}
            isLoading={isLoadingRegistryCreators}
            {...fieldProps}
          />
        )}
      </Field>

      <Field
        type="select"
        label="Group"
        name="group"
        transform={{
          output: (opt) => (opt?.value !== undefined ? opt.value : null),
          input: (v) => findOption(registryGroupOptions, v),
        }}
      >
        {({ fieldProps }) => (
          <Select
            isClearable={true}
            placeholder="Choose"
            options={registryGroupOptions}
            isLoading={isLoadingRegistryCreators}
            {...fieldProps}
          />
        )}
      </Field>

      <Field label="VAT no." name="vat_number">
        {({ fieldProps }) => <TextField placeholder="Type the VAT no." {...fieldProps} />}
      </Field>

      <Field
        label="Imported in accounting"
        type="select"
        name="accounting_arca"
        transform={{
          output: (opt) => (opt?.value !== undefined ? opt.value : null),
          input: (v) => findOption(registryAccountingArcaOptions, v),
        }}
      >
        {({ fieldProps }) => (
          <Select
            isClearable={true}
            options={registryAccountingArcaOptions}
            placeholder="Choose"
            {...fieldProps}
          />
        )}
      </Field>

      <Field label="Contact" name="contact">
        {({ fieldProps }) => <TextField placeholder="Type a contact email" {...fieldProps} />}
      </Field>

      <Field
        type="select"
        label="Country"
        name="country_iso"
        transform={{
          output: (c) => (c?.abbr !== undefined ? c.abbr : null),
          input: (v) => countries?.find((c) => c.abbr === v),
        }}
      >
        {({ fieldProps: { ref, ...fieldProps } }) => (
          <CountrySelect className="w-100" placeholder="Choose a country" {...fieldProps} />
        )}
      </Field>

      <Field label="City" name="city">
        {({ fieldProps }) => <TextField placeholder="Type a city" {...fieldProps} />}
      </Field>

      <Field label="Zipcode" name="cap">
        {({ fieldProps }) => <TextField placeholder="Type a zipcode" {...fieldProps} />}
      </Field>

      <Field label="Telephone" name="telephone">
        {({ fieldProps }) => <TextField placeholder="Type a telephone" {...fieldProps} />}
      </Field>

      <Field label="Website" name="website">
        {({ fieldProps }) => <TextField placeholder="Type a website" {...fieldProps} />}
      </Field>

      <Grid>
        <Grid.Col sm={6}>
          <Field label="Created from" name="created_from">
            {({ fieldProps }) => <DatePicker placeholder="2/18/2022" {...fieldProps} />}
          </Field>
        </Grid.Col>

        <Grid.Col sm={6}>
          <Field label="Created to" name="created_to">
            {({ fieldProps }) => <DatePicker placeholder="2/18/2022" {...fieldProps} />}
          </Field>
        </Grid.Col>
      </Grid>
    </>
  )
}

export default RegistriesFilterFields
