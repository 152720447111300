//Components
import ShipmentViewEditRegistriesReceivingAgent from 'components/Shipments/View/EditRegistries/ReceivingAgent'
import ShipmentViewEditRegistriesShipper from 'components/Shipments/View/EditRegistries/Shipper'
import ShipmentViewEditRegistriesConsignee from 'components/Shipments/View/EditRegistries/Consignee'
import ShipmentViewEditRegistriesClient from 'components/Shipments/View/EditRegistries/Client'
import ShipmentViewEditRegistriesClearance from 'components/Shipments/View/EditRegistries/Clearance'
import { registries } from 'components/Shipments/View/EditRegistries/registries'

export const tabs = [
  {
    key: 'shipper',
    title: 'Shipper',
    component: <ShipmentViewEditRegistriesShipper />,
    fields: [
      ...Object.values(registries.shipper_fields.names),
      ...Object.values(registries.second_shipper_fields.names),
      ...Object.values(registries.pickup_fields.names),
      'loading_date',
      'loading_reference',
    ],
  },
  {
    key: 'consignee',
    title: 'Consignee',
    component: <ShipmentViewEditRegistriesConsignee />,
    fields: [
      ...Object.values(registries.consignee_fields.names),
      ...Object.values(registries.second_consignee_fields.names),
      ...Object.values(registries.delivery_fields.names),
      ...Object.values(registries.notify_fields.names),
      'delivery_date',
    ],
  },
  {
    key: 'receiving-agent',
    title: 'Receiving agent',
    component: <ShipmentViewEditRegistriesReceivingAgent />,
    fields: [...Object.values(registries.receiving_agent_fields.names)],
  },
  {
    key: 'client',
    title: 'Client',
    component: <ShipmentViewEditRegistriesClient />,
    fields: ['client_id', 'contacts'],
  },
  {
    key: 'clearance',
    title: 'Custom clearance',
    component: <ShipmentViewEditRegistriesClearance />,
    fields: [
      ...Object.values(registries.departure_fields.names),
      ...Object.values(registries.destination_fields.names),
      'departure_custom_needed',
      'destination_custom_needed',
      'departure_custom_nn_reason',
      'destination_custom_terminal',
      'destination_custom_nn_reason',
    ],
  },
]
