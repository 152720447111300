export const stockAvailabilityOptions = [
  { label: 'Available', value: 1 },
  { label: 'All used', value: 0 },
]

export const bookedOptions = [
  { label: 'Yes', value: 1 },
  { label: 'No', value: 0 },
]

export const usedOptions = [
  { label: 'Yes', value: 1 },
  { label: 'No', value: 0 },
]
