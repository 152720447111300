import { useQuery } from '@tanstack/react-query'

// Apis
import { getUser } from './users-requests'

/**
 * get user details
 * @param {String|Number} userId
 * @param {import('@tanstack/react-query').UseQueryOptions} options
 * @returns {import('@tanstack/react-query').UseQueryResult}
 */

const useUser = (userId, options = {}) => {
  return useQuery({
    queryKey: ['user', userId],
    queryFn: () => getUser(userId),
    staleTime: Infinity,
    retry: 2,
    ...options,
  })
}

export { useUser }
