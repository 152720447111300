import { useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useNavigate, useParams } from 'react-router-dom'

// Utils
import { handleErrorOnSubmit } from 'utils/errors'
import { getFilled, transformSelectField } from 'utils/helpers'
import { vehicleTypeOptions } from '../utils/constants'

// Hooks
import { useVehicleType } from '../api/useVehicleType'
import { useCreateVehicleType } from '../api/useCreateVehicleType'

// Atlassian
import Button, { LoadingButton } from '@atlaskit/button'
import PageHeader from '@atlaskit/page-header'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'
import Textfield from '@atlaskit/textfield'
import Spinner from '@atlaskit/spinner'
import SectionMessage from '@atlaskit/section-message'
import { ErrorMessage, Label } from '@atlaskit/form'
import Select from '@atlaskit/select'

// Interfaces
import {
  Grid,
  Form,
  Field,
  WarningBannerField,
  useFlags,
  InputGroup,
} from '@royan-co/user-interface'

// Components
import Card from 'components/UI/Card'

const VehicleTypeCreationPage = () => {
  const { typeId } = useParams()

  const { showSuccessFlag } = useFlags()

  const navigate = useNavigate()
  const pageTitle = typeId ? 'Edit vehicle type' : `New vehicle type`

  const createVehicleTypeMutation = useCreateVehicleType(typeId)

  const { data, isError, isLoading, refetch } = useVehicleType(typeId, { enabled: !!typeId })

  const defaultValues = useMemo(() => {
    const values = {
      name: getFilled(data, 'name', ''),
      type: getFilled(data, 'type', null),
      width: getFilled(data, 'width', ''),
      height: getFilled(data, 'height', ''),
      length: getFilled(data, 'length', ''),
    }

    return values
  }, [data])

  const onSubmitCreation = (data, { setError }) => {
    createVehicleTypeMutation.mutate(data, {
      onError: (e) => {
        handleErrorOnSubmit(e, setError, data)
      },
      onSuccess: (res) => {
        showSuccessFlag(res.message)
        navigate('/panel/vehicle-types', { replace: true })
      },
    })
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <Grid gutter={24} className="w-full">
        <Grid.Col md={10} lg={8} xl={6} offsetMd={1} offsetLg={2} offsetXl={3}>
          <PageHeader
            breadcrumbs={
              <Breadcrumbs>
                <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
                <BreadcrumbsItem text="Configurations" />
                <BreadcrumbsItem text="Vehicle types" to="/panel/vehicle-types" component={Link} />
              </Breadcrumbs>
            }
          >
            {pageTitle}
          </PageHeader>

          <div className="my-8">
            {isLoading && typeId ? (
              <div className="flex justify-center my-32">
                <Spinner />
              </div>
            ) : isError ? (
              <SectionMessage
                appearance="warning"
                title="Something wrong on loading data, please retry."
              >
                <Button onClick={() => refetch()}>Retry</Button>
              </SectionMessage>
            ) : (
              <Form onSubmit={onSubmitCreation} defaultValues={defaultValues}>
                {() => (
                  <>
                    <Card className="mt-0">
                      <Field isRequired label="Name" name="name" defaultValue="" className="!mt-0">
                        {({ fieldProps, error }) => (
                          <>
                            <Textfield placeholder="Type the name" {...fieldProps} />
                            {error && <ErrorMessage>{error}</ErrorMessage>}
                          </>
                        )}
                      </Field>

                      <Field
                        isRequired
                        label="Type"
                        name="type"
                        className="!mt-4"
                        transform={transformSelectField(vehicleTypeOptions)}
                      >
                        {({ fieldProps, error }) => (
                          <>
                            <Select
                              isClearable={true}
                              options={vehicleTypeOptions}
                              placeholder="Choose a type"
                              {...fieldProps}
                            />
                            {error && <ErrorMessage>{error}</ErrorMessage>}
                          </>
                        )}
                      </Field>

                      <div className="mt-4">
                        <Label>Dimensions (W x L x H) (m)</Label>
                        <InputGroup>
                          <Field isRequired name="width" className="basis-1/3 !mt-0">
                            {({ fieldProps, error }) => (
                              <>
                                <Textfield placeholder="Width" {...fieldProps} />
                                {error && <ErrorMessage>{error}</ErrorMessage>}
                              </>
                            )}
                          </Field>
                          <Field isRequired name="length" className="basis-1/3 !mt-0">
                            {({ fieldProps, error }) => (
                              <>
                                <Textfield placeholder="Length" {...fieldProps} />
                                {error && <ErrorMessage>{error}</ErrorMessage>}
                              </>
                            )}
                          </Field>
                          <Field isRequired name="height" className="basis-1/3 !mt-0">
                            {({ fieldProps, error }) => (
                              <>
                                <Textfield placeholder="Height" {...fieldProps} />
                                {error && <ErrorMessage>{error}</ErrorMessage>}
                              </>
                            )}
                          </Field>
                        </InputGroup>
                      </div>
                    </Card>

                    <WarningBannerField />

                    <LoadingButton
                      className="!table ml-auto mt-6"
                      appearance="primary"
                      type="submit"
                      isLoading={createVehicleTypeMutation.isLoading}
                    >
                      {typeId ? 'Edit' : 'Create'}
                    </LoadingButton>
                  </>
                )}
              </Form>
            )}
          </div>
        </Grid.Col>
      </Grid>
    </>
  )
}

export default VehicleTypeCreationPage
