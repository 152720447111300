import set from 'lodash/set'
import cloneDeep from 'lodash/cloneDeep'
import { SCIOptions } from '../../constants'
import { useCallback, useEffect, useMemo, useState } from 'react'

// Hooks
import useRegistryAirlines from 'hooks/useRegistryAirlines'
import useAirportsAutocomplete from 'hooks/useAirportsAutocomplete'
import useShipmentsEnumerations from 'hooks/useShipmentsEnumerations'

// Utils
import { findOption } from 'utils/helpers'

// Interfaces
import { RefreshIcon } from '@royan-co/user-interface'

// Atlassian
import TextArea from '@atlaskit/textarea'
import Textfield from '@atlaskit/textfield'
import InlineEdit from '@atlaskit/inline-edit'
import Select from '@atlaskit/select'
import { Radio } from '@atlaskit/radio'
import Spinner from '@atlaskit/spinner'
import { Checkbox } from '@atlaskit/checkbox'

// Assets
import './styles.scss'
import logoUrl from 'assets/images/psxcargo.png'

// Components
// import RegistrySelect from 'components/Registries/RegistrySelect'
import Trapezoid from '../Trapezoid'
import { useGetWaybill } from 'features/documents/api/getWaybill'
import InlineEditableSelect from './InlineEditable/InlineEditableSelect'
import DisabledElementTooltip from 'components/UI/DisabledElementTooltip'

const airwayBillFields = {
  asAgreed: false,
  number1: '',
  number2: '',
  number3: '',
  number4: '',
  number5: '',
  consignor: '',
  consigned: '',
  consignorAccountNumber: '',
  consignedAccountNumber: '',
  issuedBy: '',
  carrierAgentNameAndCity: '',
  accountingInformation: '',
  agentIATACode: '',
  accountNo: '',
  airportOfDepartureAndRequestedRouting: '',
  referenceNumber: '',
  optionalShippingInformation1: '',
  optionalShippingInformation2: '',
  to: '',
  byFirstCarrier: '',
  to1: '',
  by1: '',
  to2: '',
  by2: '',
  currency: '',
  chgsCode: '',
  ppd: '',
  coll: '',
  ppdOther: '',
  collOther: '',
  declaredValueCarriage: '',
  declaredValueCustoms: '',
  airportDestination: '',
  requestedFlightNumber: '',
  requestedFlightDate: '',
  requestedFlightNumber2: '',
  requestedFlightDate2: '',
  amountInsurance: '',
  handlingInformation: '',
  sci: null,
  weightChargePrepaid: '',
  weightChargeCollect: '',
  valuationChargePrepaid: '',
  valuationChargeCollect: '',
  taxPrepaid: '',
  taxCollect: '',
  otherCharges: '',
  totalChargesDueAgentPrepaid: '',
  totalChargesDueAgentCollect: '',
  totalChargesDueCarrierPrepaid: '',
  totalChargesDueCarrierCollect: '',
  totalPrepaid: '',
  totalCollect: '',
  signatureShipperOrAgent: '',
  currencyConversionRates: '',
  ccChargesInDestCurrency: '',
  chargesAtDestination: '',
  totalCollectCharges: '',
  sumOfPackagesNumber: '',
  sumOfPackagesGross: '',
  sumOfPackagesTotal: '',
  executedOn: '',
  executedAt: '',
  signatureIssuingCarrierOrAgent: '',
  packages: [
    {
      number: '',
      gross: '',
      unit: '',
      rateClass: '',
      commodityItemNo: '',
      chargeableWeight: '',
      rateCharge: '',
      total: '',
      goods: '',
    },
  ],
}

const AirwayBillForm = ({ defaultValues, onChange, previewMode, document }) => {
  const placeHolder = ''
  const isHouse = document?.type === 0
  const [formData, setFormData] = useState({
    ...airwayBillFields,
    ...cloneDeep(defaultValues),
  })
  const [gettingWaybill, setGettingWaybill] = useState(false)
  const { registryAirlineOptions, isLoadingRegistryAirlines, getPropertyOfAirline } =
    useRegistryAirlines(!previewMode)
  const { getEnumerationOptionsByKey, isLoadingShipmentEnumeration } = useShipmentsEnumerations()

  const {
    airportsAutocompleteOptions,
    setTermAirportsAutocomplete,
    isLoadingAirportsAutocomplete,
  } = useAirportsAutocomplete({ country: null })
  const { waybill, isLoadingGetWaybill, refetchGetWaybill, isFetchingGetWaybill } = useGetWaybill(
    formData.byFirstCarrier?.value,
    {
      enabled: gettingWaybill,
    }
  )

  const showGettingWaybillButton = useMemo(
    () =>
      !isLoadingGetWaybill &&
      !isFetchingGetWaybill &&
      !isHouse &&
      !formData.number3 &&
      previewMode === undefined,
    [formData.number3, isFetchingGetWaybill, isHouse, isLoadingGetWaybill, previewMode]
  )

  const getWaybillNumber = () => {
    if (getWaybillNumber) refetchGetWaybill()
    else {
      setGettingWaybill(true)
    }
  }

  const onChangeFormData = useCallback(
    (key, value) => {
      const NewFormData = {
        ...formData,
        ...set(formData, key, value),
      }
      onChange?.(NewFormData)
      setFormData(NewFormData)
    },
    [formData, onChange]
  )

  const onChangeWTRadioButton = (fieldName) => {
    onChangeFormData('ppd', fieldName === 'ppd' ? 'X' : '')
    onChangeFormData('coll', fieldName === 'coll' ? 'X' : '')

    if (fieldName === 'ppd') {
      onChangeFormData('taxPrepaid', formData.taxCollect)
      onChangeFormData('weightChargePrepaid', formData.weightChargeCollect)
      onChangeFormData('valuationChargePrepaid', formData.valuationChargeCollect)

      onChangeFormData('taxCollect', '')
      onChangeFormData('weightChargeCollect', '')
      onChangeFormData('valuationChargeCollect', '')
    }

    if (fieldName === 'coll') {
      onChangeFormData('taxCollect', formData.taxPrepaid)
      onChangeFormData('weightChargeCollect', formData.weightChargePrepaid)
      onChangeFormData('valuationChargeCollect', formData.valuationChargePrepaid)

      onChangeFormData('taxPrepaid', '')
      onChangeFormData('weightChargePrepaid', '')
      onChangeFormData('valuationChargePrepaid', '')
    }
  }

  const onChangeOtherRadioButton = (fieldName) => {
    onChangeFormData('ppdOther', fieldName === 'ppdOther' ? 'X' : '')
    onChangeFormData('collOther', fieldName === 'collOther' ? 'X' : '')

    if (fieldName === 'ppdOther') {
      onChangeFormData('totalChargesDueAgentPrepaid', formData.totalChargesDueAgentCollect)
      onChangeFormData('totalChargesDueCarrierPrepaid', formData.totalChargesDueCarrierCollect)

      onChangeFormData('totalChargesDueAgentCollect', '')
      onChangeFormData('totalChargesDueCarrierCollect', '')
    }

    if (fieldName === 'collOther') {
      onChangeFormData('totalChargesDueAgentCollect', formData.totalChargesDueAgentPrepaid)
      onChangeFormData('totalChargesDueCarrierCollect', formData.totalChargesDueCarrierPrepaid)

      onChangeFormData('totalChargesDueAgentPrepaid', '')
      onChangeFormData('totalChargesDueCarrierPrepaid', '')
    }
  }

  useEffect(() => {
    if (!isHouse && !!waybill && typeof waybill !== 'object') onChangeFormData('number3', waybill)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waybill])

  useEffect(() => {
    onChange?.({
      ...airwayBillFields,
      ...cloneDeep(defaultValues),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (formData.number3 !== formData.number4 && !isHouse) {
      onChangeFormData('number4', formData.number3)
      onChangeFormData('number5', 'AWB ' + formData.number1 + ' - ' + formData.number3)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.number3, formData.number1])

  return (
    <div className="paper-document-wrapper">
      <div className="paper-document">
        <div className="fix w-8_74 l-0 h-1line">
          <div className="text-center bold size-1-3">
            <div className="input-1line" style={{ marginTop: '-8px' }}>
              <InlineEdit
                isEditing={false}
                defaultValue={formData.number1}
                editView={({ errorMessage, ...fieldProps }, ref) => (
                  <Textfield {...fieldProps} ref={ref} />
                )}
                readView={() => (
                  <div className="h-1line !border-0">{formData.number1 || placeHolder}</div>
                )}
                onConfirm={onChangeFormData.bind(null, 'number1')}
                hideActionButtons
                readViewFitContainerWidth
              />
            </div>
          </div>
        </div>

        <div className="fix w-8_74 l-8_74 h-1line border-left">
          <div className="text-center bold size-1-3">
            <div className="input-1line" style={{ marginTop: '-8px' }}>
              <InlineEdit
                isEditing={false}
                defaultValue={formData.number2}
                editView={({ errorMessage, ...fieldProps }, ref) => (
                  <Textfield {...fieldProps} ref={ref} />
                )}
                readView={() => <div className="h-1line">{formData.number2 || placeHolder}</div>}
                onConfirm={onChangeFormData.bind(null, 'number2')}
                hideActionButtons
                readViewFitContainerWidth
              />
            </div>
          </div>
        </div>

        <div className="fix w-20_74 l-16_74 h-1line border-left">
          <div className="text-center bold size-1-3">
            <div className="input-1line flex justify-between" style={{ marginTop: '-8px' }}>
              <div className="grow">
                <InlineEdit
                  isEditing={previewMode}
                  defaultValue={formData.number3}
                  editView={({ errorMessage, ...fieldProps }, ref) => (
                    <Textfield {...fieldProps} ref={ref} />
                  )}
                  readView={() => (
                    <div className="h-1line">
                      {formData.number3 || placeHolder}
                      {isLoadingGetWaybill || isFetchingGetWaybill ? (
                        <Spinner size="small" />
                      ) : null}
                    </div>
                  )}
                  onConfirm={onChangeFormData.bind(null, 'number3')}
                  hideActionButtons
                  readViewFitContainerWidth
                />
              </div>
              {showGettingWaybillButton ? (
                <DisabledElementTooltip
                  isDisabled={!formData.byFirstCarrier?.value}
                  tooltipContent={<span>The first carrier should not be empty.</span>}
                >
                  <button
                    type="button"
                    children={<RefreshIcon size={18} />}
                    onClick={getWaybillNumber}
                    className="mx-1 mt-2 text-neutral-400"
                    disabled={!formData.byFirstCarrier?.value}
                  />
                </DisabledElementTooltip>
              ) : null}
            </div>
          </div>
        </div>

        <div className="fix w-20_74 l-36_74 h-1line border-left">
          <div className="text-center bold size-1-3">
            <div className="input-1line" style={{ marginTop: '-8px' }}>
              <InlineEdit
                isEditing={false}
                defaultValue={formData.number4}
                editView={({ errorMessage, ...fieldProps }, ref) => (
                  <Textfield {...fieldProps} ref={ref} />
                )}
                readView={() => <div className="h-1line">{formData.number4 || placeHolder}</div>}
                onConfirm={onChangeFormData.bind(null, 'number4')}
                hideActionButtons
                readViewFitContainerWidth
              />
            </div>
          </div>
        </div>

        <div className="fix w-18_74 l-56_74 h-1line border-left">
          <div className="text-center bold size-1-3">
            <div className="input-1line" style={{ marginTop: '-8px' }}>
              <InlineEdit
                isEditing={false}
                defaultValue={formData.number5}
                editView={({ errorMessage, ...fieldProps }, ref) => (
                  <Textfield {...fieldProps} ref={ref} />
                )}
                readView={() => <div className="h-1line">{formData.number5 || placeHolder}</div>}
                onConfirm={onChangeFormData.bind(null, 'number5')}
                hideActionButtons
                readViewFitContainerWidth
              />
            </div>
          </div>
        </div>

        <div className="fix w-36_74 l-0 top-1line h-7line border-top border-left">
          <div className="px-1mm">Shipper's Name amd Address</div>
          <div style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.consignor}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="6"
                  className="overflow-hidden"
                  maxHeight={6 * 1.618 + 'em'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-6line read-view-text-aria">
                  {formData.consignor || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'consignor')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-18_74 l-18_74 top-1line h-2line border-top border-left border-bottom bg-white">
          <div className="px-1mm text-center">Shipper's Account Number</div>
          <div className="text-center">
            <div className="input-1line" style={{ marginTop: '-8px' }}>
              <InlineEdit
                isEditing={previewMode}
                defaultValue={formData.consignorAccountNumber}
                editView={({ errorMessage, ...fieldProps }, ref) => (
                  <Textfield {...fieldProps} ref={ref} />
                )}
                readView={() => (
                  <div className="h-1line read-view-text">
                    {formData.consignorAccountNumber || placeHolder}
                  </div>
                )}
                onConfirm={onChangeFormData.bind(null, 'consignorAccountNumber')}
                hideActionButtons
                readViewFitContainerWidth
              />
            </div>
          </div>
        </div>

        <div className="fix w-18_74 l-36_74 top-1line h-5-5line border-top border-left">
          <div className="px-1mm mt-0-4line">CLEAN</div>

          <div className="px-1mm bold size-1-6 mt-0-6line">Air Waybill</div>
          <div className="pt-2mm flex">
            <div className="px-2mm">Issued by:</div>
          </div>
        </div>

        <div className="fix w-20_74 l-54_74 top-1line h-5-5line border-top border-right border-left-transparent">
          <div className="h-0-5line" />
          <img
            className="h-4-5line"
            src={formData.logoUrl === 'default-image' ? logoUrl : formData.logoUrl}
            alt=""
          />
        </div>

        <div className="fix w-38_74 l-36_74 h-1-5line top-6-5line border-top border-left border-right">
          <div className="px-1mm py-1_4line size-0-9">
            Copies 1, 2 and 3 of this Air Waybill are originals and have the same validity.
          </div>
        </div>

        <div className="fix w-36_74 l-0 h-7line top-8line border-top border-left">
          <div className="px-1mm">Consignee's Name and Address</div>
          <div style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.consigned}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="6"
                  className="overflow-hidden"
                  maxHeight={6 * 1.618 + 'em'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-6line read-view-text-aria">
                  {formData.consigned || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'consigned')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-18_74 l-18_74 h-2line top-8line border-top border-left border-bottom bg-gray">
          <div className="px-1mm text-center">Consignee's Account Number</div>
          <div className="text-center">
            <div className="input-1line" style={{ marginTop: '-8px' }}>
              <InlineEdit
                isEditing={previewMode}
                defaultValue={formData.consignedAccountNumber}
                editView={({ errorMessage, ...fieldProps }, ref) => (
                  <Textfield {...fieldProps} ref={ref} />
                )}
                readView={() => (
                  <div className="h-1line read-view-text">
                    {formData.consignedAccountNumber || placeHolder}
                  </div>
                )}
                onConfirm={onChangeFormData.bind(null, 'consignedAccountNumber')}
                hideActionButtons
                readViewFitContainerWidth
              />
            </div>
          </div>
        </div>

        <div className="fix w-38_74 l-36_74 h-7line top-8line border-top border-left border-right">
          <div className="px-1mm py-1mm size-0-9 line-height-1-2 text-justify">
            It is agreed that the goods described herein are accepted in apparent good order and
            condition (except as noted) for carriage SUBJECT TO THE CONDITIONS OF CONTRACT ON THE
            REVERSE HEREOF. ALL GOODS MAY BE CARRIED BY ANY OTHER MEANS INCLUDING ROAD OR ANY OTHER
            CARRIER UNLESS SPECIFIC CONTRARY INSTRUCTIONS ARE GIVEN HEREON BY THE SHIPPER, AND
            SHIPPER AGREES THAT THE SHIPMENT MAY BE CARRIED VIA INTERMEDIATE STOPPING PLACES WHICH
            THE CARRIER DEEMS APPROPRIATE. THE SHIPPER'S ATTENTION IS DRAWN TO THE NOTICE CONCERNING
            CARRIER'S LIMITATION OF LIABILITY. Shipper may increase such limitation of liability by
            declaring a higher value for carriage and paying a supplemental charge if required.
          </div>
        </div>

        <div className="fix w-36_74 l-0 h-5line top-15line border-top border-left">
          <div className="px-1mm">
            {isHouse ? 'Also Notify Party' : "Issuing Carrier's Agent Name and City"}
          </div>
          <div style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={isHouse ? formData.notify : formData.carrierAgentNameAndCity}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="4"
                  className="overflow-hidden"
                  maxHeight={4 * 1.618 + 'em'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-4line read-view-text-aria">
                  {isHouse
                    ? formData.notify || 'SAME AS CONSIGNEE'
                    : formData.carrierAgentNameAndCity || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(
                null,
                isHouse ? 'notify' : 'carrierAgentNameAndCity'
              )}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-18_74 l-0 h-2line top-20line border-top border-left">
          <div className="px-1mm">Agent's IATA Code</div>
          <div className="input-1line" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.agentIATACode}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className="h-1line read-view-text">
                  {formData.agentIATACode || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'agentIATACode')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-18_74 l-18_74 h-2line top-20line border-top border-left">
          <div className="px-1mm">Account No.</div>
          <div className="input-1line" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.accountNo}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className="h-1line read-view-text">{formData.accountNo || placeHolder}</div>
              )}
              onConfirm={onChangeFormData.bind(null, 'accountNo')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-38_74 l-36_74 h-7line top-15line border-top border-left border-right">
          <div className="px-1mm">Accounting Information</div>
          <div style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.accountingInformation}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="6"
                  className="overflow-hidden"
                  maxHeight={6 * 1.618 + 'em'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-6line read-view-text-aria">
                  {formData.accountingInformation || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'accountingInformation')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-36_74 l-0 h-2line top-22line border-top border-left">
          <div className="px-1mm">
            Airport of Departure (Addr. of First Carrier) and Requested Routing
          </div>
          <div>
            <InlineEditableSelect
              disabled={previewMode === false}
              value={formData.airportOfDepartureAndRequestedRouting}
              readView={formData.airportOfDepartureAndRequestedRouting?.label || placeHolder}
              onChange={(data) => {
                onChangeFormData('airportOfDepartureAndRequestedRouting', data)
                onChangeFormData('number2', data.value)
              }}
              selectComponent={
                <Select
                  isClearable={true}
                  placeholder="Choose a place"
                  options={airportsAutocompleteOptions}
                  isLoading={isLoadingAirportsAutocomplete}
                  onInputChange={(newValue) => setTermAirportsAutocomplete(newValue)}
                />
              }
            />
          </div>
        </div>

        <div className="fix w-14_74 l-36_74 h-2line top-22line border-top border-left">
          <div className="px-1mm">Reference Number</div>
          <div className="input-1line" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.referenceNumber}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className="h-1line read-view-text">
                  {formData.referenceNumber || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'referenceNumber')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-12_74 l-50_74 h-2line top-22line border-top border-left">
          <div className="px-1mm h-1line"></div>
          <div className="input-1line" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.optionalShippingInformation1}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className="h-1line read-view-text">
                  {formData.optionalShippingInformation1 || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'optionalShippingInformation1')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-12_74 l-62_74 h-2line top-22line border-top border-left border-right">
          <div className="px-1mm h-1line"></div>
          <div className="input-1line" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.optionalShippingInformation2}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className="h-1line read-view-text">
                  {formData.optionalShippingInformation2 || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'optionalShippingInformation2')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix l-56_74 top-22line mt-1 transformX-n50">
          <Trapezoid width="140" />
        </div>

        <div className="fix w-20_74 l-46_74 top-22line text-center">
          Optional Shipping Information
        </div>

        <div className="fix w-4_74 l-0 h-2line top-24line border-top border-left">
          <div className="px-1mm">To</div>
          <div className="input-1line" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.to}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className="h-1line read-view-text">{formData.to || placeHolder}</div>
              )}
              onConfirm={onChangeFormData.bind(null, 'to')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-18_74 l-4_74 h-2line top-24line border-top border-left">
          <div className="px-1mm">By First Carrier</div>
          <div>
            <InlineEditableSelect
              value={formData.byFirstCarrier}
              disabled={previewMode === false}
              readView={formData.byFirstCarrier?.label || placeHolder}
              onChange={(data) => {
                onChangeFormData('byFirstCarrier', data || null)
                // if (isMaster) {
                onChangeFormData('number1', getPropertyOfAirline(data?.value, 'prefix') || '')
                onChangeFormData('logoUrl', getPropertyOfAirline(data?.value, 'logo_url') || '')
                // } else {
                // onChangeFormData('logoUrl', data?.logo || '')
                // }
              }}
              selectComponent={
                <Select
                  isClearable={true}
                  isLoading={isLoadingRegistryAirlines}
                  options={registryAirlineOptions}
                  placeholder="Choose an airline"
                />
              }
            />
          </div>
        </div>

        <div className="fix w-4_74 l-22_74 h-2line top-24line border-top border-left">
          <div className="px-1mm">to</div>
          <div className="input-1line" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.to1}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className="h-1line read-view-text">{formData.to1 || placeHolder}</div>
              )}
              onConfirm={onChangeFormData.bind(null, 'to1')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-3_74 l-26_74 h-2line top-24line border-top border-left">
          <div className="px-1mm">by</div>
          <div className="input-1line" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.by1}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className="h-1line read-view-text">{formData.by1 || placeHolder}</div>
              )}
              onConfirm={onChangeFormData.bind(null, 'by1')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-4_74 l-29_74 h-2line top-24line border-top border-left">
          <div className="px-1mm">to</div>
          <div className="input-1line" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.to2}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className="h-1line read-view-text">{formData.to2 || placeHolder}</div>
              )}
              onConfirm={onChangeFormData.bind(null, 'to2')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-3_74 l-33_74 h-2line top-24line border-top border-left">
          <div className="px-1mm">by</div>
          <div className="input-1line" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.by2}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className="h-1line read-view-text">{formData.by2 || placeHolder}</div>
              )}
              onConfirm={onChangeFormData.bind(null, 'by2')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix l-16_74 top-24line mt-1 transformX-n50">
          <Trapezoid width="77" />
        </div>

        <div className="fix w-10_74 l-12_74 top-24line size-0-8">Routing and Destination</div>

        <div className="fix w-4_74 l-36_74 h-2line top-24line border-top border-left">
          <div className="size-0-9 text-center">Currency</div>
          <div>
            <InlineEditableSelect
              value={formData.currency}
              disabled={previewMode === false}
              readView={formData?.currency || placeHolder}
              onChange={(data) => {
                onChangeFormData('currency', data?.value || '')
              }}
              selectComponent={
                <Select
                  isLoading={isLoadingShipmentEnumeration}
                  options={getEnumerationOptionsByKey('currencies')}
                />
              }
            />
          </div>
        </div>

        <div className="fix w-2_74 l-40_74 h-2line top-24line border-top border-left">
          <div className="size-0-7 text-center line-height-1 pt-1mm">CHGS Code</div>
          <div className="input-1line" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.chgsCode}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className="h-1line read-view-text">{formData.chgsCode || placeHolder}</div>
              )}
              onConfirm={onChangeFormData.bind(null, 'chgsCode')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-4_74 l-42_74 h-0-6line top-24line border-top border-left">
          <div className="size-0-7 text-center line-height-1-2">WT/VAL</div>
        </div>

        <div className="fix w-2_74 l-42_74 h-0-6line top-24-6line border-top border-left">
          <div className="size-0-7 text-center line-height-1-2">PPD</div>
        </div>

        <div className="fix w-2_74 l-44_74 h-0-6line top-24-6line border-top border-left">
          <div className="size-0-7 text-center line-height-1-2">COLL</div>
        </div>

        <div className="fix w-4_74 l-42_74 h-0-8line top-25-2line border-left">
          <div className="size-0-7 text-center line-height-n">
            <div className="input-1line" style={{ marginTop: '-8px' }}>
              <InlineEdit
                isEditing={previewMode}
                editView={({ errorMessage, ...fieldProps }, ref) => (
                  <>
                    <div className="flex">
                      <Radio
                        ref={ref}
                        isChecked={!!formData.ppd}
                        onChange={() => onChangeWTRadioButton('ppd')}
                        name="ppd"
                        value="ppd"
                      />
                      <Radio
                        ref={ref}
                        isChecked={!!formData.coll}
                        onChange={() => onChangeWTRadioButton('coll')}
                        name="coll"
                        value="coll"
                      />
                    </div>
                  </>
                )}
                readView={() => (
                  <div className="flex">
                    <div className="h-1line read-view-text" style={{ width: '32px' }}>
                      {formData.ppd || ''}
                    </div>
                    <div className="border-left"></div>
                    <div className="h-1line read-view-text" style={{ width: '32px' }}>
                      {formData.coll || ''}
                    </div>
                  </div>
                )}
                onConfirm={() => {}}
                hideActionButtons
                readViewFitContainerWidth
              />
            </div>
          </div>
        </div>

        <div className="fix w-4_74 l-46_74 h-0-6line top-24line border-top border-left">
          <div className="size-0-7 text-center line-height-1-2">Other</div>
        </div>

        <div className="fix w-2_74 l-46_74 h-0-6line top-24-6line border-top border-left">
          <div className="size-0-7 text-center line-height-1-2">PPD</div>
        </div>

        <div className="fix w-2_74 l-48_74 h-0-6line top-24-6line border-top border-left">
          <div className="size-0-7 text-center line-height-1-2">COLL</div>
        </div>

        <div className="fix w-4_74 l-46_74 h-0-8line top-25-2line border-left">
          <div className="size-0-7 text-center line-height-n">
            <div className="input-1line" style={{ marginTop: '-8px' }}>
              <InlineEdit
                isEditing={previewMode}
                defaultValue={formData.ppdOther}
                editView={({ errorMessage, ...fieldProps }, ref) => (
                  <>
                    <div className="flex">
                      <Radio
                        ref={ref}
                        isChecked={!!formData.ppdOther}
                        onChange={() => onChangeOtherRadioButton('ppdOther')}
                        name="ppdOther"
                        value="ppdOther"
                      />
                      <Radio
                        ref={ref}
                        isChecked={!!formData.collOther}
                        onChange={() => onChangeOtherRadioButton('collOther')}
                        name="collOther"
                        value="collOther"
                      />
                    </div>
                  </>
                )}
                readView={() => (
                  <div className="flex">
                    <div className="h-1line read-view-text" style={{ width: '33px' }}>
                      {formData.ppdOther || ''}
                    </div>
                    <div className="border-left" style={{ marginLeft: '-1px' }}></div>
                    <div className="h-1line read-view-text" style={{ width: '33px' }}>
                      {formData.collOther || ''}
                    </div>
                  </div>
                )}
                onConfirm={() => {}}
                hideActionButtons
                readViewFitContainerWidth
              />
            </div>
          </div>
        </div>

        <div className="fix w-12_74 l-50_74 h-2line top-24line border-top border-left">
          <div className="size-0-9 text-center">Declared Value for Carriage</div>
          <div className="input-1line text-center" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.declaredValueCarriage}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className="h-1line read-view-text">
                  {formData.declaredValueCarriage || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'declaredValueCarriage')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-12_74 l-62_74 h-2line top-24line border-top border-left border-right">
          <div className="size-0-9 text-center">Declared Value for Customs</div>
          <div className="input-1line text-center" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.declaredValueCustoms}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className="h-1line read-view-text">
                  {formData.declaredValueCustoms || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'declaredValueCustoms')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-18_74 l-0 h-2line top-26line border-top border-left">
          <div className="text-center">Airport of Destination</div>
          <div>
            <InlineEditableSelect
              disabled={previewMode === false}
              value={formData.airportDestination}
              readView={formData.airportDestination?.label || placeHolder}
              onChange={(data) => {
                onChangeFormData('airportDestination', data)
              }}
              selectComponent={
                <Select
                  isClearable={true}
                  placeholder="Choose a place"
                  options={airportsAutocompleteOptions}
                  isLoading={isLoadingAirportsAutocomplete}
                  onInputChange={(newValue) => setTermAirportsAutocomplete(newValue)}
                />
              }
            />
          </div>
        </div>

        <div className="fix w-9_74 l-18_74 h-2line top-26line border-top border-left size-0-7">
          <div className="h-1line"></div>
          <div className="flex">
            <div
              className="input-1line text-center grow"
              style={{ marginTop: '-8px', width: '70px !important' }}
            >
              <InlineEdit
                isEditing={previewMode}
                defaultValue={formData.requestedFlightNumber}
                editView={({ errorMessage, ...fieldProps }, ref) => (
                  <div
                    className="text-field-small-size"
                    style={{
                      width: '70px !important',
                      maxWidth: '70px !important',
                    }}
                  >
                    <Textfield {...fieldProps} ref={ref} />
                  </div>
                )}
                readView={() => (
                  <div className="h-1line read-view-text">
                    {formData.requestedFlightNumber || placeHolder}
                  </div>
                )}
                onConfirm={onChangeFormData.bind(null, 'requestedFlightNumber')}
                hideActionButtons
                readViewFitContainerWidth
              />
            </div>
            /
            <div
              className="input-1line text-center grow"
              style={{ marginTop: '-8px', width: '70px !important' }}
            >
              <InlineEdit
                isEditing={previewMode}
                defaultValue={formData.requestedFlightDate}
                editView={({ errorMessage, ...fieldProps }, ref) => (
                  <div
                    className="text-field-small-size"
                    style={{ width: '70px !important', maxWidth: '70px !important' }}
                  >
                    <Textfield {...fieldProps} ref={ref} />
                  </div>
                )}
                readView={() => (
                  <div className="h-1line read-view-text">
                    {formData.requestedFlightDate || placeHolder}
                  </div>
                )}
                onConfirm={onChangeFormData.bind(null, 'requestedFlightDate')}
                hideActionButtons
                readViewFitContainerWidth
              />
            </div>
          </div>
        </div>

        <div className="fix w-9_74 l-27_74 h-2line top-26line border-top border-left size-0-7">
          <div className="h-1line"></div>
          <div className="flex">
            <div
              className="input-1line text-center grow"
              style={{ marginTop: '-8px', width: '70px !important' }}
            >
              <InlineEdit
                isEditing={previewMode}
                defaultValue={formData.requestedFlightNumber2}
                editView={({ errorMessage, ...fieldProps }, ref) => (
                  <div
                    className="text-field-small-size"
                    style={{
                      width: '70px !important',
                      maxWidth: '70px !important',
                    }}
                  >
                    <Textfield {...fieldProps} ref={ref} />
                  </div>
                )}
                readView={() => (
                  <div className="h-1line read-view-text">
                    {formData.requestedFlightNumber2 || placeHolder}
                  </div>
                )}
                onConfirm={onChangeFormData.bind(null, 'requestedFlightNumber2')}
                hideActionButtons
                readViewFitContainerWidth
              />
            </div>
            /
            <div
              className="input-1line text-center grow"
              style={{ marginTop: '-8px', width: '70px !important' }}
            >
              <InlineEdit
                isEditing={previewMode}
                defaultValue={formData.requestedFlightDate2}
                editView={({ errorMessage, ...fieldProps }, ref) => (
                  <div
                    className="text-field-small-size"
                    style={{ width: '70px !important', maxWidth: '70px !important' }}
                  >
                    <Textfield {...fieldProps} ref={ref} />
                  </div>
                )}
                readView={() => (
                  <div className="h-1line read-view-text">
                    {formData.requestedFlightDate2 || placeHolder}
                  </div>
                )}
                onConfirm={onChangeFormData.bind(null, 'requestedFlightDate2')}
                hideActionButtons
                readViewFitContainerWidth
              />
            </div>
          </div>
        </div>

        <div className="fix l-27_74 top-26line mt-1 transformX-n50">
          <Trapezoid width="94" />
        </div>

        <div className="fix w-18_74 l-18_74 top-26line size-0-8 text-center">
          Requested Flight/Date
        </div>

        <div className="fix w-11_74 l-36_74 h-2line top-26line border-top border-left">
          <div className="text-center"> Amount of Insurance </div>
          <div className="input-1line text-center" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.amountInsurance}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className="h-1line read-view-text">
                  {formData.amountInsurance || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'amountInsurance')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-27_74 l-47_74 h-2line top-26line border-top border-left border-right">
          <div className="px-1mm size-0-9 line-height-3_2">
            INSURANCE – If carrier offers insurance, and such insurance is requested in accordance
            with the conditions thereof, indicate amount to be insured in figures in box marked
            "Amount of Insurance".
          </div>
        </div>

        <div className="fix w-74_74 l-0 h-4line top-28line border-left border-top border-right">
          <div className="px-1mm">Handling Information</div>
          <div style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.handlingInformation}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="3"
                  className="overflow-hidden"
                  maxHeight={3 * 1.618 + 'em'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-3line read-view-text-aria">
                  {formData.handlingInformation || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'handlingInformation')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-12_74 l-62_74 h-2line top-30line border-left border-top bg-white">
          <div className="text-center">SCI</div>
          <div>
            <InlineEditableSelect
              value={formData.sci}
              disabled={previewMode === false}
              readView={findOption(SCIOptions, formData.sci)?.label || placeHolder}
              onChange={(data) => {
                onChangeFormData('sci', data.value)
              }}
              selectComponent={
                <Select isClearable={true} options={SCIOptions} placeholder="Choose one" />
              }
            />
          </div>
        </div>

        <div className="fix w-4_74 l-0 h-2line top-32line border-left border-top">
          <div className="px-1mm line-height-3_2">
            No. of Pieces
            <br />
            RCP
          </div>
        </div>

        <div className="fix w-4_74 l-0 h-12line top-34line border-left border-top">
          <div className="text-center size-0-9" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.packages[0].number}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="12"
                  className="overflow-hidden"
                  maxHeight={12 * 1.618 + 'em'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-12line read-view-text-aria">
                  {formData.packages[0].number || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'packages.0.number')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-4_74 l-0 h-2line top-46line border-left border-top">
          <div className="text-center size-0-9" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.sumOfPackagesNumber}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="2"
                  className="overflow-hidden"
                  maxHeight={2 * 1.618 + 'rem'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-2line read-view-text-aria">
                  {formData.sumOfPackagesNumber || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'packages.sumOfPackagesNumber')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-7_74 l-4_74 h-2line top-32line border-left border-top">
          <div className="px-1mm pt-0-5rem line-height-1rem text-center">
            Gross
            <br />
            weight
          </div>
        </div>

        <div className="fix w-7_74 l-4_74 h-12line top-34line border-left border-top">
          <div className="text-center size-0-9" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.packages[0].gross}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="12"
                  className="overflow-hidden"
                  maxHeight={12 * 1.618 + 'rem'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-12line read-view-text-aria">
                  {formData.packages[0].gross || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'packages.0.gross')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-7_74 l-4_74 h-2line top-46line border-left border-top">
          <div className="text-center size-0-9" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.sumOfPackagesGross}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="2"
                  className="overflow-hidden"
                  maxHeight={2 * 1.618 + 'rem'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-2line read-view-text-aria">
                  {formData.sumOfPackagesGross || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'packages.sumOfPackagesGross')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-1_74 l-11_74 h-2line top-32line border-left border-top">
          <div className="text-center line-height-3_2 size-0-6">
            kg
            <br />
            <br />
            lb
          </div>
        </div>

        <div className="fix w-1_74 l-11_74 h-14line top-34line border-left border-top">
          <div className="text-center size-0-6" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.packages[0].unit}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="14"
                  className="overflow-hidden"
                  maxHeight={14 * 1.618 + 'rem'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-14line px-0 read-view-text-aria">
                  {formData.packages[0].unit || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'packages.0.unit')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-1_74 l-12_74 h-16line top-32line border-left border-top bg-gray"></div>

        <div className="fix w-8_74 l-13_74 h-2line top-32line border-left border-top">
          <div className="px-1mm">Rate Class</div>
        </div>

        <div className="fix w-1_74 l-13_74 h-14line top-34line border-left border-top-transparent">
          <div className="text-center size-0-6" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.packages[0].rateClass}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="14"
                  className="overflow-hidden"
                  maxHeight={14 * 1.618 + 'rem'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-14line px-0 read-view-text-aria">
                  {formData.packages[0].rateClass || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'packages.0.rateClass')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-7_74 l-14_74 h-1line top-33line border-left border-top">
          <div className="text-center size-0-7 line-height-2_1">
            Commodity
            <br />
            Item No.
          </div>
        </div>

        <div className="fix w-7_74 l-14_74 h-14line top-34line border-left border-top">
          <div className="text-center size-0-9" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.packages[0].commodityItemNo}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="14"
                  className="overflow-hidden"
                  maxHeight={14 * 1.618 + 'rem'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-14line read-view-text-aria">
                  {formData.packages[0].commodityItemNo || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'packages.0.commodityItemNo')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-1_74 l-21_74 h-16line top-32line border-left border-top bg-gray"></div>

        <div className="fix w-7_74 l-22_74 h-2line top-32line border-left border-top">
          <div className="px-1mm pt-0-5rem line-height-1rem text-center">
            Chargeable
            <br />
            Weight
          </div>
        </div>

        <div className="fix w-7_74 l-22_74 h-14line top-34line border-left border-top">
          <div className="text-center size-0-9" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.packages[0].chargeableWeight}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="14"
                  className="overflow-hidden"
                  maxHeight={14 * 1.618 + 'rem'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-14line read-view-text-aria">
                  {formData.packages[0].chargeableWeight || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'packages.0.chargeableWeight')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-1_74 l-29_74 h-16line top-32line border-left border-top bg-gray"></div>

        <div className="fix w-8_74 l-30_74 h-2line top-32line border-left border-top">
          <div className="px-1mm">
            &nbsp;Rate
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Charge
          </div>
        </div>

        <div className="fix w-8_74 l-30_74 h-14line top-34line border-left border-top">
          <div className="text-center size-0-9" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.packages[0].rateCharge}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="14"
                  className="overflow-hidden"
                  maxHeight={14 * 1.618 + 'rem'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-14line read-view-text-aria">
                  {formData.packages[0].rateCharge || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'packages.0.rateCharge')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-1_74 l-38_74 h-16line top-32line border-left border-top bg-gray"></div>

        <div className="fix w-12_74 l-39_74 h-2line top-32line border-left border-top">
          <div className="px-1mm pt-2mm text-center">Total</div>
        </div>

        <div className="fix w-12_74 l-39_74 h-12line top-34line border-left border-top">
          <Checkbox
            name="asAgreed"
            label="AS AGREED"
            isChecked={formData.asAgreed}
            onChange={(e) => {
              onChangeFormData('asAgreed', e.target.checked)

              onChangeFormData('totalCollectCharges', e.target.checked ? 'AS AGREED' : '')
              onChangeFormData('totalPrepaid', e.target.checked ? 'AS AGREED' : '')
              onChangeFormData('totalCollect', e.target.checked ? 'AS AGREED' : '')

              onChangeFormData(
                formData.ppdOther ? 'totalChargesDueAgentPrepaid' : 'totalChargesDueAgentCollect',
                e.target.checked ? 'AS AGREED' : ''
              )
              onChangeFormData(
                formData.ppdOther
                  ? 'totalChargesDueCarrierPrepaid'
                  : 'totalChargesDueCarrierCollect',
                e.target.checked ? 'AS AGREED' : ''
              )
            }}
            isDisabled={previewMode === undefined ? false : true}
          />
          <div className="text-center size-0-9" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.packages[0].total}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="14"
                  className="overflow-hidden"
                  maxHeight={14 * 1.618 + 'rem'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-14line read-view-text-aria">
                  {formData.packages[0].total || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'packages.0.total')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-12_74 l-39_74 h-2line top-46line border-left border-top">
          <div className="text-center size-0-9" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.sumOfPackagesTotal}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="2"
                  className="overflow-hidden"
                  maxHeight={2 * 1.618 + 'rem'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-2line read-view-text-aria">
                  {formData.sumOfPackagesTotal || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'packages.sumOfPackagesTotal')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-1_74 l-51_74 h-16line top-32line border-left border-top bg-gray"></div>

        <div className="fix w-22_74 l-52_74 h-2line top-32line border-left border-top border-right">
          <div className="px-1mm pt-0-5rem line-height-1rem text-center">
            Nature and Quantity of Goods
            <br /> (incl. Dimentions or Volume)
          </div>
        </div>

        <div className="fix w-22_74 l-52_74 h-14line overflow-unset top-34line border-left border-top border-right">
          <div className="text-left size-0-9" style={{ marginTop: '-8px' }}>
            <div className="px-1mm pt-2mm text-left">Description</div>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.packages[0].goods}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="13"
                  className="bg-transparent z-50 relative"
                  maxHeight="100vh"
                  resize="auto"
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-12line text-left">
                  {formData.packages[0].goods || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'packages.0.goods')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-14_74 l-0 h-2line top-48line border-top border-left">
          <div className="h-1line"></div>
          <div className="input-1line text-center" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={!!formData.ppd && previewMode}
              defaultValue={formData.weightChargePrepaid}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className={`h-1line read-view-text ${!formData.ppd ? 'bg-gray' : ''}`}>
                  {formData.weightChargePrepaid || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'weightChargePrepaid')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-14_74 l-14_74 h-2line top-48line border-top border-left">
          <div className="h-1line"></div>
          <div className="input-1line text-center" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={!!formData.coll && previewMode}
              defaultValue={formData.weightChargeCollect}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className={`h-1line read-view-text ${!formData.coll ? 'bg-gray' : ''}`}>
                  {formData.weightChargeCollect || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'weightChargeCollect')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix l-4_74 top-48line mt-1 transformX-n50">
          <Trapezoid width="40" />
        </div>

        <div className="fix l-14_74 top-48line mt-1 transformX-n50">
          <Trapezoid width="86" />
        </div>

        <div className="fix l-24_74 top-48line mt-1 transformX-n50">
          <Trapezoid width="40" />
        </div>

        <div className="fix w-7_74 l-1_74 top-48line size-0-8 text-center">Prepaid</div>

        <div className="fix w-14_74 l-7_74 top-48line size-0-8 text-center">Weight Charge</div>

        <div className="fix w-7_74 l-20_74 top-48line size-0-8 text-center">Collect</div>

        <div className="fix w-14_74 l-0 h-2line top-50line border-top border-left">
          <div className="h-1line"></div>
          <div className="input-1line text-center" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={!!formData.ppd && previewMode}
              defaultValue={formData.valuationChargePrepaid}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className={`h-1line read-view-text ${!formData.ppd ? 'bg-gray' : ''}`}>
                  {formData.valuationChargePrepaid || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'valuationChargePrepaid')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-14_74 l-14_74 h-2line top-50line border-top border-left">
          <div className="h-1line"></div>
          <div className="input-1line text-center" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={!!formData.coll && previewMode}
              defaultValue={formData.valuationChargeCollect}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className={`h-1line read-view-text ${!formData.coll ? 'bg-gray' : ''}`}>
                  {formData.valuationChargeCollect || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'valuationChargeCollect')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix l-14_74 top-50line mt-1 transformX-n50">
          <Trapezoid width="100" />
        </div>

        <div className="fix w-14_74 l-7_74 top-50line size-0-8 text-center">Valuation Charge</div>

        <div className="fix w-14_74 l-0 h-2line top-52line border-top border-left">
          <div className="h-1line"></div>
          <div className="input-1line text-center" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={!!formData.ppd && previewMode}
              defaultValue={formData.taxPrepaid}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className={`h-1line read-view-text ${!formData.ppd ? 'bg-gray' : ''}`}>
                  {formData.taxPrepaid || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'taxPrepaid')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-14_74 l-14_74 h-2line top-52line border-top border-left">
          <div className="h-1line"></div>
          <div className="input-1line text-center" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={!!formData.coll && previewMode}
              defaultValue={formData.taxCollect}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className={`h-1line read-view-text ${!formData.coll ? 'bg-gray' : ''}`}>
                  {formData.taxCollect || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'taxCollect')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix l-14_74 top-52line mt-1 transformX-n50">
          <Trapezoid width="50" />
        </div>

        <div className="fix w-14_74 l-7_74 top-52line size-0-8 text-center">Tax</div>

        <div className="fix w-46_74 l-28_74 h-6line top-48line border-left border-top border-right">
          <div className="px-1mm">Other Charges</div>
          <div style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.otherCharges}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="5"
                  className="overflow-hidden"
                  maxHeight={5 * 1.618 + 'em'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-5line read-view-text-aria">
                  {formData.otherCharges || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'otherCharges')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-14_74 l-0 h-2line top-54line border-top border-left">
          <div className="h-1line"></div>
          <div className="input-1line text-center" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={!!formData.ppdOther && previewMode}
              defaultValue={formData.totalChargesDueAgentPrepaid}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className={`h-1line read-view-text ${!formData.ppdOther ? 'bg-gray' : ''}`}>
                  {formData.totalChargesDueAgentPrepaid || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'totalChargesDueAgentPrepaid')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-14_74 l-14_74 h-2line top-54line border-top border-left">
          <div className="h-1line"></div>
          <div className="input-1line text-center" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={!!formData.collOther && previewMode}
              defaultValue={formData.totalChargesDueAgentCollect}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className={`h-1line read-view-text ${!formData.collOther ? 'bg-gray' : ''}`}>
                  {formData.totalChargesDueAgentCollect || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'totalChargesDueAgentCollect')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix l-14_74 top-54line mt-1 transformX-n50">
          <Trapezoid width="160" />
        </div>

        <div className="fix w-14_74 l-7_74 top-54line size-0-8 text-center">
          Total Other Charges Due Agent
        </div>

        <div className="fix w-14_74 l-0 h-2line top-56line border-top border-left">
          <div className="h-1line"></div>
          <div className="input-1line text-center" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={!!formData.ppdOther && previewMode}
              defaultValue={formData.totalChargesDueCarrierPrepaid}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className={`h-1line read-view-text ${!formData.ppdOther ? 'bg-gray' : ''}`}>
                  {formData.totalChargesDueCarrierPrepaid || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'totalChargesDueCarrierPrepaid')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-14_74 l-14_74 h-2line top-56line border-top border-left">
          <div className="h-1line"></div>
          <div className="input-1line text-center" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={!!formData.collOther && previewMode}
              defaultValue={formData.totalChargesDueCarrierCollect}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className={`h-1line read-view-text ${!formData.collOther ? 'bg-gray' : ''}`}>
                  {formData.totalChargesDueCarrierCollect || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'totalChargesDueCarrierCollect')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix l-14_74 top-56line mt-1 transformX-n50">
          <Trapezoid width="160" />
        </div>

        <div className="fix w-14_74 l-7_74 top-56line size-0-8 text-center">
          Total Other Charges Due Carrier
        </div>

        <div className="fix w-14_74 l-0 h-2line top-58line border-top border-left bg-gray"></div>

        <div className="fix w-14_74 l-14_74 h-2line top-58line border-top border-left bg-gray"></div>

        <div className="fix w-46_74 l-28_74 h-6line top-54line border-top border-left border-right">
          <div className="px-1mm line-height-n size-0-8">
            Shipper certifies that the particulars on the face hereof are correct and that{' '}
            <strong>
              insofar as any part of the consignment contains dangerous goods, such part is properly
              described by name and is in proper condition for carriage by air according to the
              applicable Dangerous Goods Regulations.
            </strong>
          </div>
        </div>

        <div className="fix w-7_74 l-29_74 h-2line top-56-5line">
          <div style={{ marginTop: '-8px' }}></div>
        </div>

        <div className="fix w-30_74 l-36_74 h-2line top-56-5line text-center">
          <div style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={false}
              defaultValue={formData.signatureShipperOrAgent}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="2"
                  className="overflow-hidden"
                  minHeight={2 * 1.618 + 'em'}
                  maxHeight={2 * 1.618 + 'em'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-2line read-view-text-aria">
                  {formData.signatureShipperOrAgent || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'signatureShipperOrAgent')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-30_74 l-36_74 h-1line top-59line border-top-dot">
          <div className="text-center size-0-8">Signature of Shipper or his Agent</div>
        </div>

        <div className="fix w-14_74 l-0 h-2line top-60line border-top border-left">
          <div className="h-1line"></div>
          <div className="input-1line text-center" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.totalPrepaid}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className="h-1line read-view-text">{formData.totalPrepaid || placeHolder}</div>
              )}
              onConfirm={onChangeFormData.bind(null, 'totalPrepaid')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-14_74 l-14_74 h-2line top-60line border-top border-left">
          <div className="h-1line"></div>
          <div className="input-1line text-center" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.totalCollect}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className="h-1line read-view-text">{formData.totalCollect || placeHolder}</div>
              )}
              onConfirm={onChangeFormData.bind(null, 'totalCollect')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix l-7_74 top-60line mt-1 transformX-n50">
          <Trapezoid width="90" />
        </div>

        <div className="fix l-21_74 top-60line mt-1 transformX-n50">
          <Trapezoid width="90" />
        </div>

        <div className="fix w-14_74 l-0_74 top-60line size-0-8 text-center">Total Prepaid</div>

        <div className="fix w-14_74 l-14_74 top-60line size-0-8 text-center">Total Collect</div>

        <div className="fix w-14_74 l-0 h-2line top-62line border-top border-left bg-gray">
          <div className="h-1line"></div>
          <div className="input-1line text-center" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.currencyConversionRates}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className="h-1line read-view-text">
                  {formData.currencyConversionRates || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'currencyConversionRates')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-14_74 l-14_74 h-2line top-62line border-top border-left bg-gray">
          <div className="h-1line"></div>
          <div className="input-1line text-center" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.ccChargesInDestCurrency}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className="h-1line read-view-text">
                  {formData.ccChargesInDestCurrency || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'ccChargesInDestCurrency')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix l-7_74 top-62line mt-1 transformX-n50">
          <Trapezoid width="90" background="#ececec" />
        </div>

        <div className="fix l-21_74 top-62line mt-1 transformX-n50">
          <Trapezoid width="90" background="#ececec" />
        </div>

        <div className="fix w-14_74 l-0_74 top-62line size-0-8 text-center">
          Currency Conversion Rates
        </div>

        <div className="fix w-14_74 l-14_74 top-62line size-0-8 text-center">
          CC Charges in Dest. Currency
        </div>

        <div className="fix w-46_74 l-28_74 h-4line top-60line border-top border-left border-right">
          <div style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.signatureIssuingCarrierOrAgent}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="3"
                  className="overflow-hidden"
                  maxHeight={3 * 1.618 + 'rem'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-3line read-view-text-aria text-right">
                  {formData.signatureIssuingCarrierOrAgent || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'signatureIssuingCarrierOrAgent')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix fix w-44_74 l-29_74 h-1line top-63line border-top-dot"></div>

        <div className="fix w-16_74 l-29_74 h-1line top-63line">
          <div className="flex items-start">
            <span className="size-0-8">Executed on (date)</span>
            <div className="input-1line grow" style={{ marginTop: '-8px' }}>
              <InlineEdit
                isEditing={previewMode}
                defaultValue={formData.executedOn}
                editView={({ errorMessage, ...fieldProps }, ref) => (
                  <Textfield {...fieldProps} ref={ref} />
                )}
                readView={() => (
                  <div className="h-1line read-view-text">{formData.executedOn || placeHolder}</div>
                )}
                onConfirm={onChangeFormData.bind(null, 'executedOn')}
                hideActionButtons
                readViewFitContainerWidth
              />
            </div>
          </div>
        </div>

        <div className="fix w-14_74 l-45_74 h-1line top-63line">
          <div className="flex items-start">
            <span className="size-0-8">at (place)</span>
            <div className="input-1line grow" style={{ marginTop: '-8px' }}>
              <InlineEdit
                isEditing={previewMode}
                defaultValue={formData.executedAt}
                editView={({ errorMessage, ...fieldProps }, ref) => (
                  <Textfield {...fieldProps} ref={ref} />
                )}
                readView={() => (
                  <div className="h-1line read-view-text">{formData.executedAt || placeHolder}</div>
                )}
                onConfirm={onChangeFormData.bind(null, 'executedAt')}
                hideActionButtons
                readViewFitContainerWidth
              />
            </div>
          </div>
        </div>

        <div className="fix w-15_74 l-59_74 h-1line top-63line">
          <div className="size-0-8 text-left">Signature of Issuing Carrier or its Agent</div>
        </div>

        <div className="fix w-14_74 l-0 h-2line top-64line border-top border-left border-bottom bg-gray">
          <div className="px-1mm pt-1mm text-center line-height-1-2">
            For Carrier's Use only
            <br />
            at Destination
          </div>
        </div>

        <div className="fix w-14_74 l-14_74 h-2line top-64line border-top border-left border-bottom bg-gray">
          <div className="h-1line"></div>
          <div className="input-1line text-center" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.chargesAtDestination}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className="h-1line read-view-text">
                  {formData.chargesAtDestination || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'chargesAtDestination')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix l-21_74 top-64line mt-1 transformX-n50">
          <Trapezoid width="90" background="#ececec" />
        </div>

        <div className="fix w-14_74 l-14_74 top-64line size-0-8 text-center">
          Charges at Destination
        </div>

        <div className="fix w-14_74 l-28_74 h-2line top-64line border-top border-left border-bottom bg-gray">
          <div className="h-1line"></div>
          <div className="input-1line text-center" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.totalCollectCharges}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className="h-1line read-view-text">
                  {formData.totalCollectCharges || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'totalCollectCharges')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix l-35_74 top-64line mt-1 transformX-n50">
          <Trapezoid width="90" background="#ececec" />
        </div>

        <div className="fix w-14_74 l-28_74 top-64line size-0-8 text-center">
          Total Collect Charges
        </div>

        <div className="fix w-32_74 l-42_74 h-2line top-64line border-top border-left"></div>
      </div>
    </div>
  )
}

export default AirwayBillForm
