import { createElement, useMemo } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { modes } from 'components/Shipments/Create/modes'

//Contexts
import { useShipmentCreationContext } from 'contexts/ShipmentCreationProvider'

//Atlassian
import Button from '@atlaskit/button'

//Interfaces
import { useWizardFormContext, WizardForm } from '@royan-co/user-interface'

//Components
import ShipmentCreationCargoHeader from 'components/Shipments/Create/CargoHeader'

const ShipmentCreationCargoPage = () => {
  const navigate = useNavigate()
  const { nextStep, prevStep } = useShipmentCreationContext()

  const {
    stepsValue: { routing },
  } = useWizardFormContext()

  const cargoFields = useMemo(() => {
    const mode = modes.find((m) => m.id === routing?.modeType)

    return createElement(mode?.cargoFields || '')
  }, [routing?.modeType])

  const handleCargoStep = () => {
    navigate(nextStep.path)
  }

  return !routing?.placeOfLoading ? (
    <Navigate to={prevStep.path} replace />
  ) : (
    <WizardForm name="cargo" onSubmit={handleCargoStep}>
      {() => (
        <>
          <ShipmentCreationCargoHeader />

          {cargoFields}

          <div className="flex justify-end mt-4">
            <Button type="submit" appearance="default" component={Link} to={prevStep.path}>
              Previous step
            </Button>

            <Button type="submit" appearance="primary" className="ml-2">
              Next step
            </Button>
          </div>
        </>
      )}
    </WizardForm>
  )
}

export default ShipmentCreationCargoPage
