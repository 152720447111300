import { Helmet } from 'react-helmet-async'
import { Link, useParams, useNavigate, useLocation, Outlet } from 'react-router-dom'
import { useMemo } from 'react'

// Hooks
import { useLine } from '../hooks/useLine'

// Atlassian
import PageHeader from '@atlaskit/page-header'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'
import Button, { ButtonGroup } from '@atlaskit/button'
import Tabs, { Tab, TabList } from '@atlaskit/tabs'
import Spinner from '@atlaskit/spinner'
import SectionMessage from '@atlaskit/section-message'

// Interfaces
import { useRoyanTableLastFilters, Skeleton } from '@royan-co/user-interface'

const tabItems = [
  { title: 'General information', path: '' },
  { title: 'Routes', path: '/routes' },
]

const LineViewPage = () => {
  const { lineId } = useParams()
  const { lastUrlSearch } = useRoyanTableLastFilters('lines-list')
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const { data: line, isFetching, isLoading, isError, refetch } = useLine(lineId)

  const pageTitle = useMemo(() => {
    return `Line ${line?.departure_country} to ${line?.arrival_country}`
  }, [line])

  const tabs = useMemo(() => {
    return tabItems.map((tab) => ({
      ...tab,
      path: `/panel/sales-machine/lines/${lineId}${tab?.path}`,
    }))
  }, [lineId])

  const selectedTabIndex = useMemo(
    () => tabs.findIndex((t) => t.path === pathname),
    [pathname, tabs]
  )

  const navigateToTab = (index) => {
    const tab = tabs[index]

    navigate(tab.path)
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <PageHeader
        breadcrumbs={
          <Breadcrumbs>
            <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
            <BreadcrumbsItem text="Sales machine" />
            <BreadcrumbsItem text="Lines" to="/panel/sales-machine/lines" component={Link} />
          </Breadcrumbs>
        }
        actions={
          <ButtonGroup>
            <Button
              component={Link}
              appearance="default"
              to={`/panel/sales-machine/lines${lastUrlSearch ? `?${lastUrlSearch}` : ''}`}
            >
              Back
            </Button>
          </ButtonGroup>
        }
      >
        {isLoading ? (
          <Skeleton height={32} maw={320} />
        ) : (
          <div className="flex items-center">{pageTitle}</div>
        )}
      </PageHeader>

      <Tabs onChange={navigateToTab} selected={selectedTabIndex}>
        <TabList>
          {tabs.map((tab, i) => (
            <Tab key={i} on>
              {tab.title}
            </Tab>
          ))}
        </TabList>
      </Tabs>

      <div className="px-2 py-4">
        {isLoading && isFetching ? (
          <div className="flex justify-center my-32">
            <Spinner />
          </div>
        ) : isError ? (
          <SectionMessage
            appearance="warning"
            title="Something wrong on loading data, please retry."
          >
            <Button onClick={() => refetch()}>Retry</Button>
          </SectionMessage>
        ) : (
          selectedTabIndex > -1 && <Outlet />
        )}
      </div>
    </>
  )
}

export default LineViewPage
