import { useMemo } from 'react'

import { getCountry } from 'utils/helpers'

//Atlassian
import { CountrySelect } from '@atlaskit/select'
import Textfield from '@atlaskit/textfield'
import { ErrorMessage } from '@atlaskit/form'
import Toggle from '@atlaskit/toggle'
import Button from '@atlaskit/button'

//Interfaces
import { Field, useDisclosure, useFormContext } from '@royan-co/user-interface'

//Components
import Card from 'components/UI/Card'
import RatesZonesDrawer from './ZoneDrawer'
import EnumerationField from '../List/EnumerationField'
import RatesAirportsField from '../List/AirportsField'

const RatesCreationRecordGeneralSection = ({ rateType }) => {
  const { watch, setValue, getValues } = useFormContext()
  const [openZonesDrawer, zonesDrawerHandlers] = useDisclosure('zones')

  const departureStatus = useMemo(() => !!watch('departure'), [watch])
  const arrivalStatus = useMemo(() => !!watch('arrival'), [watch])

  const arrivalZones = watch('arrival_zones')
  const departureZones = watch('departure_zones')

  const onSubmit = (data) => {
    if (zonesDrawerHandlers.data?.type === 'departure') {
      setValue('departure_zones', [...data?.zones?.map((z) => ({ ...z, type: 'departure' }))])
    } else if (zonesDrawerHandlers.data?.type === 'arrival') {
      setValue('arrival_zones', [...data?.zones?.map((z) => ({ ...z, type: 'arrival' }))])
    }
    zonesDrawerHandlers.close()
  }

  return (
    <Card className="mb-0">
      <Field isRequired label="Description" name="description">
        {({ fieldProps, error }) => (
          <>
            <Textfield placeholder="Type the description" isCompact {...fieldProps} />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </>
        )}
      </Field>

      <div className="grid md:grid-cols-2 md:gap-x-4">
        <div className="flex items-end gap-2">
          <div className="flex-grow">
            {[1, 2, 8, 14].includes(rateType) && (
              <Field
                type="select"
                label="Departure"
                name="departure_country"
                isDisabled={departureStatus}
                transform={{
                  input: (v) => getCountry(v),
                  output: (opt) => (opt?.abbr !== undefined ? opt.abbr : null),
                }}
              >
                {({ fieldProps: { ref, ...fieldProps }, error }) => (
                  <>
                    <CountrySelect
                      isClearable
                      placeholder="Country"
                      spacing="compact"
                      {...fieldProps}
                    />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>
            )}

            {[0, 3, 7, 11].includes(rateType) && (
              <EnumerationField
                label="Departure"
                name="departure_hub"
                optionsKey="hubs"
                spacing="compact"
                isDisabled={departureStatus}
              />
            )}

            {[5].includes(rateType) && (
              <RatesAirportsField
                label="Departure"
                name="departure_airport"
                spacing="compact"
                defaultValue={getValues('departure_airport')}
                isDisabled={departureStatus}
              />
            )}

            {[6, 9, 16].includes(rateType) && (
              <EnumerationField
                label="Departure"
                name="departure_port"
                optionsKey="ports"
                spacing="compact"
                isDisabled={departureStatus}
              />
            )}

            {[10, 12, 13, 15].includes(rateType) && (
              <EnumerationField
                label="Departure"
                name="departure_station"
                optionsKey="stations"
                spacing="compact"
                isDisabled={departureStatus}
              />
            )}
          </div>

          {[1, 2, 8, 14].includes(rateType) && (
            <>
              <Button
                type="button"
                appearance="primary"
                onClick={() =>
                  zonesDrawerHandlers.open({ zones: departureZones, type: 'departure' })
                }
              >
                {`Zones (${departureZones?.length})`}
              </Button>
            </>
          )}
        </div>

        <div className="flex items-end gap-2">
          <div className="flex-grow">
            {[0, 2, 9, 13].includes(rateType) && (
              <Field
                type="select"
                label="Arrival"
                name="arrival_country"
                isDisabled={arrivalStatus}
                transform={{
                  input: (v) => getCountry(v),
                  output: (opt) => (opt?.abbr !== undefined ? opt.abbr : null),
                }}
              >
                {({ fieldProps: { ref, ...fieldProps }, error }) => (
                  <>
                    <CountrySelect
                      isClearable
                      placeholder="Country"
                      spacing="compact"
                      {...fieldProps}
                    />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>
            )}

            {[1, 3, 12].includes(rateType) && (
              <EnumerationField
                label="Arrival"
                name="arrival_hub"
                optionsKey="hubs"
                spacing="compact"
                isDisabled={arrivalStatus}
              />
            )}

            {[5].includes(rateType) && (
              <RatesAirportsField
                label="Arrival"
                name="arrival_airport"
                spacing="compact"
                isDisabled={arrivalStatus}
                defaultValue={getValues('arrival_airport')}
              />
            )}

            {[6, 7, 8, 15].includes(rateType) && (
              <EnumerationField
                label="Arrival"
                name="arrival_port"
                optionsKey="ports"
                spacing="compact"
                isDisabled={arrivalStatus}
              />
            )}

            {[10, 11, 14, 16].includes(rateType) && (
              <EnumerationField
                label="Arrival"
                name="arrival_station"
                optionsKey="stations"
                spacing="compact"
                isDisabled={arrivalStatus}
              />
            )}
          </div>

          {[0, 2, 9, 13].includes(rateType) && (
            <>
              <Button
                type="button"
                appearance="primary"
                onClick={() => zonesDrawerHandlers.open({ zones: arrivalZones, type: 'arrival' })}
              >
                {`Zones (${arrivalZones?.length})`}
              </Button>
            </>
          )}
        </div>
      </div>

      <Field label="Transit time" name="transit_time">
        {({ fieldProps, error }) => (
          <>
            <Textfield placeholder="Type the transit time" isCompact {...fieldProps} />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </>
        )}
      </Field>

      <div className="grid md:grid-cols-2 md:gap-x-4">
        <Field label="Minimum cost" name="minimum">
          {({ fieldProps, error }) => (
            <>
              <Textfield
                placeholder="Type the minimum"
                isCompact
                elemAfterInput={
                  <div className="text-neutral-200 whitespace-nowrap bg-neutral-20 self-stretch flex items-center px-2">
                    Euro (€)
                  </div>
                }
                {...fieldProps}
              />
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </>
          )}
        </Field>

        <Field label="Minimum quotation" name="minimum_sell_price">
          {({ fieldProps, error }) => (
            <>
              <Textfield
                placeholder="Type the maximum"
                isCompact
                elemAfterInput={
                  <div className="text-neutral-200 whitespace-nowrap bg-neutral-20 self-stretch flex items-center px-2">
                    Euro (€)
                  </div>
                }
                {...fieldProps}
              />
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </>
          )}
        </Field>

        <Field
          type="checkbox"
          name="allow_calculate"
          label="Allow calculation"
          defaultValue={true}
          key="status"
        >
          {({ fieldProps, error }) => (
            <>
              <div>
                <Toggle {...fieldProps} id="status" />
              </div>
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </>
          )}
        </Field>
      </div>

      <RatesZonesDrawer
        isOpen={openZonesDrawer}
        onClose={zonesDrawerHandlers.close}
        zones={zonesDrawerHandlers.data?.zones}
        type={zonesDrawerHandlers.data?.type}
        onSubmit={onSubmit}
      />
    </Card>
  )
}

export default RatesCreationRecordGeneralSection
