export const documentsTypes = [
  { label: 'Air waybill', value: 0, key: 'airwayBill' },
  { label: 'Sea waybill', value: 1, key: 'seawayBill' },
  { label: 'FBL', value: 2, key: 'fbl' },
  { label: 'CMR', value: 3, key: 'cmr' },
]

export const awbTypes = [
  { label: 'Master', value: 2 }, //Only if the shipment is master
  { label: 'House', value: 0 },
  { label: 'Direct', value: 1 },
]

export const DownloadAirWaybillDocumentTypes = [
  {
    label: 'Draft',
    value: 'draft',
  },
  {
    label: 'Carrier',
    value: 'carrier',
  },
  {
    label: 'Consignee',
    value: 'consignee',
  },
  {
    label: 'Shipper',
    value: 'shipper',
  },
  {
    label: 'Delivery',
    value: 'delivery',
  },
  {
    label: 'Extra 1',
    value: 'extra_1',
  },

  {
    label: 'Extra 2',
    value: 'extra_2',
  },
  {
    label: 'Extra 3',
    value: 'extra_3',
  },
  {
    label: 'Agent',
    value: 'agent',
  },
  {
    label: 'Full',
    value: 'full',
  },
  {
    label: 'Full + houses',
    value: 'full_houses',
  },
]

export const DownloadFBLDocumentTypes = [
  {
    label: 'With layout',
    value: 'with-layout',
  },
  {
    label: 'Without layout',
    value: 'without-layout',
  },
]

export const DownloadSeaWaybillDocumentTypes = [
  {
    label: 'With layout',
    value: 'with-layout',
  },
  {
    label: 'Without layout',
    value: 'without-layout',
  },
]

export const DownloadCMRDocumentTypes = [
  {
    label: 'With layout',
    value: 'with-layout',
  },
  {
    label: 'Without layout',
    value: 'without-layout',
  },
  {
    label: 'With signature',
    value: 'with-signature',
  },
]

export const SCIOptions = [
  {
    label: 'X',
    value: 0,
  },
  {
    label: 'C',
    value: 1,
  },
  {
    label: 'T1',
    value: 2,
  },
  {
    label: 'T2',
    value: 3,
  },
]
