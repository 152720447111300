import { useMutation } from '@tanstack/react-query'

// Apis
import { createAirWaybillStock } from './stock-requests'

// Interfaces
import { useFlags } from '@royan-co/user-interface'

const useCreateAirWaybillStock = () => {
  const { showSuccessFlag } = useFlags()

  return useMutation({
    mutationFn: (data) => createAirWaybillStock(data),
    onSuccess: (res) => {
      showSuccessFlag(res.message)
    },
    onError: (e) => {
      console.error(e)
    },
  })
}

export { useCreateAirWaybillStock }
