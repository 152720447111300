import { getTableRates } from 'api/rates'

//Atlassian
import PageHeader from '@atlaskit/page-header'
import Button, { ButtonGroup } from '@atlaskit/button'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'

//Components
import { Link, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

//Interfaces
import { RoyanTable, useDisclosure } from '@royan-co/user-interface'

//Components
import { generateRatesColumns } from 'components/Rates/List/columns'
import RatesFilterFields from 'components/Rates/List/FilterFields'
import { ratesFilters } from 'components/Rates/List/filters'
import DenyFromUserLevel from 'layouts/DenyFromUserLevel'
import RatesDeleteRateModal from 'components/Rates/view/DeleteRateModal'

const pageTitle = 'Rates'

const RatesListPage = () => {
  const navigate = useNavigate()

  const [isOpenDeleteRateModal, deleteRateModalHandler] = useDisclosure('delete-rate')

  const handleDoubleClick = (e, item) => {
    navigate(`/panel/rates/${item.id_auto}`)
  }

  const openDeleteModal = (rateId) => {
    deleteRateModalHandler.open(rateId)
  }

  return (
    <DenyFromUserLevel level="warehouse">
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <PageHeader
        breadcrumbs={
          <Breadcrumbs>
            <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
            <BreadcrumbsItem text="Sales machine" />
          </Breadcrumbs>
        }
        actions={
          <ButtonGroup>
            <Button appearance="primary" component={Link} to="/panel/rates/create">
              New Rate
            </Button>
          </ButtonGroup>
        }
      >
        {pageTitle}
      </PageHeader>

      <RoyanTable
        fixFirstColumn={true}
        sortOrder="DESC"
        name="rates-list"
        sortKey="creation_date"
        request={getTableRates}
        filters={ratesFilters}
        onRowDoubleClick={handleDoubleClick}
        filterFields={<RatesFilterFields />}
        columns={generateRatesColumns(openDeleteModal)}
      >
        <RatesDeleteRateModal
          isOpen={isOpenDeleteRateModal}
          onClose={deleteRateModalHandler.close}
          rateId={deleteRateModalHandler.data}
        />
      </RoyanTable>
    </DenyFromUserLevel>
  )
}

export default RatesListPage
