import { createContext, useContext, useMemo } from 'react'
import { matchPath, useLocation, useParams } from 'react-router-dom'

const _steps = [
  {
    id: 1,
    label: 'General',
    pathSuffix: '',
  },
  {
    id: 2,
    label: 'Agents',
    pathSuffix: '/agents',
  },
  {
    id: 3,
    label: 'Shipments',
    pathSuffix: '/shipments',
  },
]

const RegistriesContext = createContext()

const useConsolidationCreationContext = () => {
  const context = useContext(RegistriesContext)

  return context
}

const ConsolidationCreationProvider = ({ children }) => {
  const { pathname } = useLocation()
  const { consolidationId } = useParams()

  const steps = useMemo(
    () =>
      _steps.map((s) => ({
        ...s,
        path: consolidationId
          ? `/panel/consolidations/${consolidationId}/edit${s.pathSuffix}`
          : `/panel/consolidations/create${s.pathSuffix}`,
      })),
    [consolidationId]
  )

  const currentStepIndex = useMemo(
    () => steps.findIndex((s) => matchPath(s.path, pathname)),
    [steps, pathname]
  )

  const currentStep = steps[currentStepIndex]
  const prevStep = steps[currentStepIndex - 1]
  const nextStep = steps[currentStepIndex + 1]

  const value = { steps, currentStep, prevStep, nextStep }

  return <RegistriesContext.Provider value={value}>{children}</RegistriesContext.Provider>
}

export { useConsolidationCreationContext, ConsolidationCreationProvider }
