import axios, { csrf } from 'services/Request'

export function getEnumerations() {
  return axios.get('/api/shipments/enumerations/all')
}

export async function createShipment(data) {
  return axios.post('/api/shipments/create', data)
}

export async function createMasterShipmentAction(shipmentId) {
  return axios.get(`/api/shipments/duplicate-master/${shipmentId}`)
}

export async function createMasterShipment(data) {
  return axios.post('/api/shipments/create/master', data)
}

export function getShipments(entity, params) {
  return axios.get(`/api/shipments/${entity}`, { params })
}

export function getShipment(id) {
  return axios.get(`/api/shipments/fetch/${id}`)
}

export function deleteShipment(id) {
  return axios.delete(`/api/shipments/${id}`)
}

export async function getShipmentsHubs() {
  return axios.get('/api/shipments/enumerations/hubs')
}

export function getShipmentsAgents() {
  return axios.get('/api/shipments/enumerations/agents')
}

export async function getShipmentsOperators() {
  return axios.get('/api/shipments/enumerations/operators')
}

export function getShipmentTimeline(id) {
  return axios.get(`/api/shipments/timeline/${id}`)
}

export async function getShipmentDocuments(id) {
  return axios.get(`/api/shipments/documents/${id}`)
}

export function deleteShipmentDocument(documentId) {
  return axios.delete(`/api/shipments/documents/${documentId}`)
}

export async function uploadDocument(formData, shipmentId) {
  await csrf()
  return await axios.post(`/api/shipments/documents/upload/${shipmentId}`, formData)
}

export async function changeVisibilityShipmentDocument(documentId, data) {
  return axios.post(`/api/shipments/documents/visibility/${documentId}`, data)
}

export async function getShipmentPDF(isShipment, id) {
  const data = await axios.get(
    `/api/shipments/pdf/${isShipment ? 'shipment' : 'quotation'}/${id}`,
    {
      responseType: 'blob',
    }
  )

  const file = new Blob([data], { type: 'application/pdf' })

  return URL.createObjectURL(file)
}

export function getShipmentWarehouseLabel(id) {
  return axios.get(`/api/shipments/actions/warehouse-label/${id}`, { responseType: 'arraybuffer' })
}

export async function deleteShareTrackingContact(id) {
  return await axios.get(`/api/shipments/tracking/contacts/delete/${id}`)
}

export async function createShareTrackingContact(id, data) {
  return await axios.post(`/api/shipments/tracking/contacts/create/${id}`, data)
}

export async function editShareTrackingContact(id, data) {
  await csrf()
  return await axios.post(`/api/shipments/tracking/contacts/edit/${id}`, data)
}

export function getShipmentCargo(id, pickupStatus) {
  return axios.get(
    `/api/shipments/cargo/${id}${pickupStatus !== undefined ? `/${pickupStatus}` : ''}`
  )
}

export function shareCargoDetails(id, data) {
  return axios.post(`/api/shipments/cargo/share/${id}`, data)
}

export function createShipmentCargoDetails(shipmentId, data) {
  return axios.post(`/api/shipments/cargo/create/${shipmentId}`, data)
}

export function editShipmentCargoDetails(cargoId, data) {
  return axios.post(`/api/shipments/cargo/edit/${cargoId}`, data)
}

export function deleteShipmentCargoDetails(cargoId) {
  return axios.delete(`/api/shipments/cargo/${cargoId}`)
}

export async function getCopyCargoDetails(id) {
  return axios.get(`/api/shipments/cargo/${id}?copy=true`)
}

export function getShipmentEnumerations() {
  return axios.get('/api/shipments/enumerations/all')
}

export async function getAirportsAutocomplete(term, country, controller) {
  return axios.post(
    '/api/database/airports/autocomplete',
    { term },
    {
      signal: controller?.signal,
      params: {
        country,
      },
    }
  )
}

export function getShipmentNotes(id) {
  return axios.get(`/api/shipments/notes/${id}`)
}

export async function deleteNote(id) {
  await csrf()
  return await axios.get(`/api/shipments/notes/delete/${id}`)
}

export async function createNote(id, data) {
  return await axios.post(`/api/shipments/notes/create/${id}`, data)
}

export async function editNote(id, data) {
  await csrf()
  return await axios.post(`/api/shipments/notes/edit/${id}`, data)
}

export async function getShipmentHistory(id) {
  return axios.get(`/api/shipments/history/${id}`)
}

export async function getShipmentEntrances(id) {
  return axios.get(`/api/shipments/entrances/${id}`)
}

export async function getShipmentEntranceLabel(entranceId) {
  return axios.get(`/api/shipments/entrances/label/${entranceId}`, { responseType: 'arraybuffer' })
}

export async function getShipmentEntranceImage(id) {
  return axios.post(`/api/public/shipments/documents/fetch/${id}`, {
    name: 'ENTRANCE IMAGE',
  })
}

export async function createShipmentEntrance(id, data) {
  return axios.post(`/api/shipments/entrances/create/${id}`, data)
}

export async function getEntranceDefaultValue(id) {
  return axios.get(`/api/shipments/entrances/create/${id}`)
}

export async function createShipmentPIC(shipmentId, contactId) {
  return axios.get(`/api/shipments/pic/${shipmentId}/${contactId}`)
}

export function editRegistries(shipmentId, params) {
  return axios.post(`/api/shipments/registries/${shipmentId}`, params)
}

export async function getDefaultsForComposingEmail(shipmentId, type, contactId, params) {
  return axios.post(
    `/api/shipments/emails/compose/${shipmentId}/${type}${contactId ? `/${contactId}` : ''}`,
    params
  )
}

export function resetShipmentConfirmation(shipmentId, type) {
  return axios.get(`/api/shipments/confirmations/reset/${shipmentId}/${type}`)
}

export function getTrackingRoutes(shipmentId) {
  return axios.get(`/api/shipments/tracking/${shipmentId}`)
}

export function shareTrackingDetails(shipmentId, data) {
  return axios.post(`/api/shipments/tracking/share/${shipmentId}`, data)
}

export function getShipmentInvoices(shipmentId) {
  return axios.get(`/api/shipments/invoices/${shipmentId}`)
}

export function duplicateShipment(entity, shipmentId) {
  return axios.get(`/api/shipments/duplicate/${entity}/${shipmentId}`)
}

export function cancelShipment(shipmentId) {
  return axios.get(`/api/shipments/cancel/${shipmentId}`)
}

export function createShipmentNotice(shipmentId, data) {
  return axios.post(`/api/shipments/notice/${shipmentId}`, data, { responseType: 'arraybuffer' })
}

export function editShipmentParam(shipmentId, attribute, data) {
  return axios.post(`/api/shipments/update/${shipmentId}${attribute ? `/${attribute}` : ''}`, data)
}

export function getDefaultsForCreatePickup(params) {
  return axios.get('/api/pickups/create', { params })
}

export function getDetailsForEditPickup(documentId) {
  return axios.get(`/api/pickups/${documentId}/edit`)
}

export function editPickup(documentId, data) {
  return axios.put(`api/pickups/${documentId}`, data)
}

export function createPickup(data) {
  return axios.post('/api/pickups', data)
}

export function editShipmentTimeline(shipmentId, data) {
  return axios.post(`/api/shipments/status/${shipmentId}`, data)
}

export function createRemindPayment(shipmentId, data) {
  return axios.post(`/api/shipments/invoices/remind/${shipmentId}`, data)
}

export function exportTemperatureReport(format, id_auto) {
  return axios.get(
    `/api/shipments/tracking/temperatures/export/${id_auto}/${format}`,
    format === 'pdf' && {
      responseType: 'blob',
    }
  )
}

export function getShipmentEntrance(entranceId) {
  return axios.get(`/api/shipments/entrances/fetch/${entranceId}`)
}

export function getShipmentCargowizPlanner(shipmentId) {
  return axios.get(`/api/shipments/cargowiz/${shipmentId}`)
}

export function getShipmentShares(shipmentId) {
  return axios.get(`/api/shipments/shares/${shipmentId}`)
}

export function shareShipment(shipmentId, data) {
  return axios.post(`/api/shipments/shares/create/${shipmentId}`, data)
}

export function removeShareShipment(shipmentId, registryId, sharedRegistryId) {
  return axios.delete(`/api/shipments/shares/${shipmentId}/${registryId}/${sharedRegistryId}`)
}

export function editShipmentSpecialConditions(shipmentId, params) {
  return axios.post(`/api/shipments/special-conditions/${shipmentId}`, params)
}

export function getFinanceDetails(shipmentId) {
  return axios.get(`/api/shipments/finance/${shipmentId}`)
}

export async function getShipmentReferenceAutocomplete(term, controller) {
  return axios.post(
    '/api/shipments/autocomplete',
    { term },
    {
      signal: controller?.signal,
    }
  )
}

export function getRejectQuotationReasons() {
  return axios.get('/api/shipments/enumerations/quotation_rejection_reasons')
}

export function rejectQuotation(id, data) {
  return axios.post(`/api/shipments/reject/${id}`, data)
}

export function acceptQuotation(id) {
  return axios.get(`/api/shipments/accept/${id}`)
}

export function stopFirstContactReminder(shipmentId) {
  return axios.get(`api/shipments/goods-availability/stop-reminders/${shipmentId}`)
}

export function getDefaultsForEditingShipmentFinance(shipmentId) {
  return axios.get(`/api/shipments/finance/edit/${shipmentId}`)
}

export function getAccessories(params) {
  return axios.get('/api/table-rates/accessories', { params })
}

export function recalculateLegCosts(data) {
  return axios.post('/api/table-rates/calculate', data)
}

export function editShipmentFinance(shipmentId, data) {
  return axios.post(`api/shipments/finance/edit/${shipmentId}`, data)
}

export function getShipmentContainer(shipmentId) {
  return axios.get(`/api/shipments/containers/${shipmentId}`)
}

export function createContainer(shipmentId, data) {
  return axios.post(`api/shipments/containers/create/${shipmentId}`, data)
}

export function editContainer(containerId, data) {
  return axios.post(`api/shipments/containers/edit/${containerId}`, data)
}

export function deleteContainer(containerId) {
  return axios.delete(`api/shipments/containers/${containerId}`)
}

export function getAirport(iata) {
  return axios.get(`api/database/airports/${iata}`)
}

export function holdShipment(shipmentId, data) {
  return axios.post(`/api/shipments/hold/${shipmentId}`, data)
}

export function getTrackingLogs(parentType, parentId) {
  return axios.get(`api/tracking-logs/${parentType}/${parentId}`)
}

export function getCommercialInvoices(shipmentId) {
  return axios.get(`api/shipments/${shipmentId}/commercial-invoices`)
}
