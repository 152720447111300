import { useNavigate, useParams } from 'react-router-dom'

//Interfaces
import { RoyanTable } from '@royan-co/user-interface'

import { RegistryQuoteColumns } from 'components/Registries/View/quote-columns'
import { getRegistryQuotes } from 'api/registries'

const RegistryViewQuotesPage = () => {
  const { registryId } = useParams()
  const navigate = useNavigate()

  const handleDoubleClick = (e, item) => {
    navigate(`/panel/quotations/${item.id_auto}`)
  }

  return (
    <RoyanTable
      filters={[]}
      name="registry_routes"
      columnsControl={false}
      columns={RegistryQuoteColumns}
      request={getRegistryQuotes.bind(null, registryId)}
      onRowDoubleClick={handleDoubleClick}
    />
  )
}

export default RegistryViewQuotesPage
