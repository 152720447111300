import { Link, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import { FinanceEditingProvider } from 'contexts/FinanceEditingProvider'

//Atlassian
import PageHeader from '@atlaskit/page-header'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'

// Interfaces
import { Grid } from '@royan-co/user-interface'

// Components
import EditFinance from 'components/Shipments/EditFinance'

const ConsolidationViewEditFinancePage = () => {
  const { consolidationId } = useParams()
  const pageTitle = 'Edit finance'

  return (
    <FinanceEditingProvider entityType={'consolidation'} entityId={consolidationId}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <Grid className="w-full">
        <Grid.Col md={11} offsetMd={0.5}>
          <PageHeader
            breadcrumbs={
              <Breadcrumbs>
                <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
                <BreadcrumbsItem
                  text="Consolidations"
                  to="/panel/consolidations"
                  component={Link}
                />
                <BreadcrumbsItem
                  text="View consolidation"
                  to={`/panel/consolidations/${consolidationId}`}
                  component={Link}
                />
                <BreadcrumbsItem
                  text="Finance"
                  to={`/panel/consolidations/${consolidationId}/finance`}
                  component={Link}
                />
              </Breadcrumbs>
            }
          >
            {pageTitle}
          </PageHeader>

          <EditFinance />
        </Grid.Col>
      </Grid>
    </FinanceEditingProvider>
  )
}

export default ConsolidationViewEditFinancePage
