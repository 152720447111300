import { useCallback, useMemo, useState } from 'react'
import { findOption } from 'utils/helpers'
import { useParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

// Apis
import { createShipmentCargoDetails, editShipmentCargoDetails } from 'api/shipments'

// Hooks
import useShipmentCargo from 'hooks/useShipmentCargo'
import useShipmentsEnumerations from 'hooks/useShipmentsEnumerations'
import useShipment from 'hooks/useShipment'

// Utils
import { formatNumber } from 'utils/numbers'
import { handleErrorOnSubmit } from 'utils/errors'

// Atlassian
import Lozenge from '@atlaskit/lozenge'
import { DynamicTableStateless } from '@atlaskit/dynamic-table'
import Button from '@atlaskit/button'
import EmptyState from '@atlaskit/empty-state'
import Spinner from '@atlaskit/spinner'
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down'
import ChevronRightIcon from '@atlaskit/icon/glyph/chevron-right'

// Interfaces
import { EditIcon, TrashIcon, useDisclosure, useFlags } from '@royan-co/user-interface'

// Components
import Card, { CardTitle } from 'components/UI/Card'
import PackageDrawer from './PackageDrawer'
import DeleteCargoModal from './DeleteCargoModal'

const ShipmentViewCargoPackages = ({ canCreateCargo, canEditCargo, canDeleteCargo, isMaster }) => {
  const { shipmentId, quotationId } = useParams()
  const entityId = shipmentId || quotationId
  const { shipment } = useShipment(entityId)
  const { showSuccessFlag } = useFlags()
  const { getEnumerationOptionsByKey } = useShipmentsEnumerations()

  const [visibleDetails, setVisibleDetails] = useState([])
  const [isOpenPackageDrawer, packageDrawerHandlers] = useDisclosure('package')
  const [isOpenDeletePackageModal, deletePackageModalHandlers] = useDisclosure('delete-package')

  const { shipmentCargo, isLoadingShipmentCargo, upsertCargoToData } = useShipmentCargo(entityId)

  const createCargoPackageMutation = useMutation(
    !packageDrawerHandlers?.data?.box_type
      ? createShipmentCargoDetails.bind(null, entityId)
      : editShipmentCargoDetails.bind(null, packageDrawerHandlers?.data?.id)
  )

  const onSubmitCreatePackage = (data, { setError }) => {
    createCargoPackageMutation.mutate(data, {
      onError: (e) => {
        handleErrorOnSubmit(e, setError, data)
        console.error(e)
      },
      onSuccess: (res) => {
        upsertCargoToData(res.cargo)
        packageDrawerHandlers.close()
        showSuccessFlag(res?.message)
      },
    })
  }

  const packagesHead = {
    cells: [
      { key: 'type', content: '' },
      { key: 'quantity', content: 'Quantity' },
      { key: 'dimensions', content: 'Dimensions (W x L x H)' },
      { key: 'weight', content: 'Weight (Kgs)' },
      { key: 'chargeable_weight', content: 'Chargeable weight (Kgs)' },
      { key: 'ldm', content: 'LDM' },
      { key: 'cbm', content: 'CBM' },
      { key: 'Stackable', content: 'Stackable' },
      { key: 'Actions', content: '' },
    ],
  }

  const groupCargoes = useMemo(() => {
    if (shipmentCargo && isMaster) {
      const _groupCargoes = Object.groupBy(shipmentCargo, ({ shipment_id }) => shipment_id) || []
      return Object.entries(_groupCargoes).map(([id, cargoes]) => ({
        number: cargoes.reduce((p, c) => p + c?.number * 1, 0),
        weight: cargoes.reduce((p, c) => p + c.weight * 1, 0),
        chargeable_weight: cargoes[0].shipment.chargeable_weight,
        ldm: cargoes.reduce((p, c) => p + c.ldm * 1, 0),
        cbm: cargoes.reduce((p, c) => p + c.cbm * 1, 0),
        reference: cargoes[0].shipment.reference,
        shipment_id: id,
        cargoes: cargoes,
      }))
    } else return []
  }, [shipmentCargo, isMaster])

  // To do: Make a custom component to handle clicking on rows of DynamicTableStateless
  const handleRowClick = useCallback(
    (rowId) => {
      const isVisible = visibleDetails.includes(rowId)
      const updatedVisibleDetails = isVisible
        ? visibleDetails.filter((id) => id !== rowId)
        : [...visibleDetails, rowId]
      setVisibleDetails(updatedVisibleDetails)
    },
    [visibleDetails]
  )

  const getRowData = useCallback(
    (item) => [
      {
        content: findOption(getEnumerationOptionsByKey('packaging_types'), item.box_type)?.label,
      },
      {
        content: <div>{item.number || 0}</div>,
      },
      {
        content: (
          <div className="whitespace-nowrap">
            <span>{`${formatNumber(item.width) || '-'}cm x ${formatNumber(
              item.length || 0
            )}cm x ${formatNumber(item.height || 0)}cm `}</span>
          </div>
        ),
      },
      {
        content: item.weight ? formatNumber(item.weight) : <Lozenge>Not set</Lozenge>,
      },
      {
        content: '',
      },
      {
        content: <span>{formatNumber(item.ldm, 3)}</span>,
      },
      {
        content: <span>{formatNumber(item.cbm, 3)}</span>,
      },
      {
        content: item.is_stackable ? (
          <Lozenge appearance="success">Yes</Lozenge>
        ) : (
          <Lozenge appearance="moved">No</Lozenge>
        ),
      },
      {
        content: (
          <div className="flex justify-end">
            {canEditCargo && (
              <Button
                spacing="compact"
                appearance="subtle"
                iconBefore={<EditIcon />}
                onClick={() => packageDrawerHandlers.open(item)}
              />
            )}

            {canDeleteCargo && (
              <Button
                spacing="compact"
                appearance="subtle"
                onClick={() => deletePackageModalHandlers.open(item.id)}
                iconBefore={<TrashIcon className="text-red-400" />}
              />
            )}
          </div>
        ),
      },
    ],
    [
      canDeleteCargo,
      canEditCargo,
      deletePackageModalHandlers,
      getEnumerationOptionsByKey,
      packageDrawerHandlers,
    ]
  )

  const packages = useMemo(() => {
    let _packages = []

    if (isMaster) {
      groupCargoes.forEach((item) => {
        const isVisibleCargoes = visibleDetails.includes(item.shipment_id)

        _packages.push({
          isHighlighted: true,
          onClick: () => handleRowClick(item.shipment_id),
          cells: [
            {
              content: (
                <div className="flex items-center py-2">
                  <button
                    children={isVisibleCargoes ? <ChevronDownIcon /> : <ChevronRightIcon />}
                    className="!px-1 text-neutral-200"
                    style={{ minWidth: '32px' }}
                  />
                  {item.reference ? (
                    <span className="whitespace-nowrap">{item.reference}</span>
                  ) : (
                    <Lozenge>Not set</Lozenge>
                  )}
                </div>
              ),
            },
            {
              content: item.number || 0,
            },
            {
              content: '',
            },
            {
              content: formatNumber(item.weight),
            },
            {
              content: formatNumber(item.chargeable_weight),
            },
            {
              content: item.ldm.toFixed(3),
            },
            {
              content: item.cbm.toFixed(3),
            },
            {
              content: '',
            },
            {
              content: '',
            },
          ],
        })

        if (isVisibleCargoes)
          item.cargoes.forEach((item) => {
            _packages.push({
              cells: getRowData(item),
            })
          })
      })
    } else
      _packages =
        shipmentCargo?.map((item) => ({
          cells: getRowData(item),
        })) || []

    const missingWeight = shipmentCargo?.some((s) => !s.weight)

    _packages.push({
      cells: [
        {
          content: <strong>Total</strong>,
        },
        {
          content: shipmentCargo?.reduce((p, c) => p + c.number * 1, 0) || 0,
        },
        {
          content: '',
        },
        {
          content: formatNumber(
            missingWeight ? shipment?.weight : shipmentCargo?.reduce((p, c) => p + c.weight * 1, 0),
            3
          ),
        },
        {
          content: formatNumber(
            isMaster
              ? groupCargoes?.reduce((p, c) => p + c.chargeable_weight * 1, 0)
              : shipment?.chargeable_weight
          ),
        },
        {
          content: formatNumber(
            shipmentCargo?.reduce((p, c) => p + c?.ldm * 1, 0),
            3
          ),
        },
        {
          content: formatNumber(
            shipmentCargo?.reduce((p, c) => p + c?.cbm * 1, 0),
            3
          ),
        },
        {
          content: '',
        },
        {
          content: '',
        },
      ],
    })

    return _packages
  }, [isMaster, shipmentCargo, shipment, groupCargoes, visibleDetails, handleRowClick, getRowData])

  return (
    <>
      <div className="flex items-center">
        <CardTitle className="mb-3">Packages</CardTitle>

        {canCreateCargo && (
          <Button
            appearance="link"
            spacing="none"
            className="ml-auto"
            onClick={() => packageDrawerHandlers.open()}
          >
            New Package
          </Button>
        )}
      </div>

      <Card className="mb-6">
        {isLoadingShipmentCargo ? (
          <div className="w-full text-center my-2">
            <Spinner />
          </div>
        ) : packages.length === 1 ? (
          <EmptyState header="No details" />
        ) : (
          <div className="overflow-auto inline-block w-full -mb-6 borderOfAtlaskitTableWithTotalRow dynamicTableHighlighted">
            <DynamicTableStateless
              head={packagesHead}
              rows={packages}
              isLoading={isLoadingShipmentCargo}
            />
          </div>
        )}
      </Card>

      <PackageDrawer
        onSubmit={onSubmitCreatePackage}
        data={packageDrawerHandlers.data}
        isOpen={isOpenPackageDrawer}
        onClose={packageDrawerHandlers.close}
        isSubmitting={createCargoPackageMutation.isLoading}
      />

      <DeleteCargoModal
        isOpen={isOpenDeletePackageModal}
        onClose={deletePackageModalHandlers.close}
        cargoId={deletePackageModalHandlers.data}
        shipmentId={entityId}
        name="package"
      />
    </>
  )
}

export default ShipmentViewCargoPackages
