import { forwardRef, useEffect, useState } from 'react'

// Atlassian
import { CreatableSelect } from '@atlaskit/select'

const createOption = (label, id_auto) => ({
  label,
  value: { id_auto, email: label, isNew: true },
})

const ContactSelect = forwardRef(
  (
    { value, onChange, options = [], isMulti, onCreateNewContact, isLoading = false, ...props },
    ref
  ) => {
    const [contactOptions, setContactOption] = useState(options)
    const [contact, setContact] = useState(value)
    const [isLoadingNewContact, setIsLoadingNewContact] = useState(false)

    const createNewContact = async (option) => {
      setIsLoadingNewContact(true)
      try {
        const res = await onCreateNewContact?.(option)
        if (res || typeof onCreateNewContact !== 'function') {
          const newOption = createOption(option, res?.id_auto)
          setContactOption((contacts) => [...contacts, newOption])
          handleChange(isMulti ? [...contact, newOption] : newOption)
        }
      } finally {
        setIsLoadingNewContact(false)
      }
    }

    const handleChange = (option) => {
      setContact(option)
      onChange(option)
    }

    const checkNewOption = (option) => {
      return String(option)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    }

    useEffect(() => {
      setContactOption(options)
    }, [options])

    return (
      <CreatableSelect
        ref={ref}
        onCreateOption={createNewContact}
        options={contactOptions}
        value={contact}
        onChange={handleChange}
        isMulti={isMulti}
        isLoading={isLoadingNewContact || isLoading}
        isValidNewOption={checkNewOption}
        {...props}
      />
    )
  }
)

export default ContactSelect
