import { useQuery } from '@tanstack/react-query'

// Apis
import { getServiceCode } from './service-codes-requests'

export const useServiceCode = (serviceCode, { enabled = true } = {}) => {
  return useQuery(['service-code', serviceCode], () => getServiceCode(serviceCode), {
    staleTime: Infinity,
    retry: 2,
    enabled,
  })
}
