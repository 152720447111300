//Atlassian
import Lozenge from '@atlaskit/lozenge'
import { Link } from 'react-router-dom'

const ShipmentReferenceTag = ({ shipment }) => {
  return shipment?.reference ? (
    <Link target="_blank" to={`/panel/shipments/${shipment.id_auto}`}>
      <Lozenge appearance={shipment?.flag_status === 2 ? 'inprogress' : 'default'}>
        {shipment?.reference}
      </Lozenge>
    </Link>
  ) : (
    <Lozenge appearance="default">Not set</Lozenge>
  )
}

export default ShipmentReferenceTag
