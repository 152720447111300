import { useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useParams, useNavigate } from 'react-router'
import { useMutation } from '@tanstack/react-query'
import { Helmet } from 'react-helmet-async'

// Apis
import { editConsolidationParam } from 'api/consolidations'

// Hooks
import useConsolidation from 'hooks/useConsolidation'

// Utils
import { getFilled } from 'utils/helpers'
import { handleErrorOnSubmit } from 'utils/errors'

// Contexts
import { useAuth } from 'contexts/AuthProvider'

// Features
import { AccessControl } from 'features/auth'

//Atlassian
import PageHeader from '@atlaskit/page-header'
import Spinner from '@atlaskit/spinner'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'
import { LoadingButton } from '@atlaskit/button'

//Interfaces
import { Grid, Form, useFlags, WarningBannerField } from '@royan-co/user-interface'

//Components
import ConsolidationViewEditGeneral from 'components/Consolidations/View/EditGeneral'
import ConsolidationViewEditCarrier from 'components/Consolidations/View/EditCarrier'
import { temperatureControlOptions } from 'components/Shipments/Create/constants'
import { modes } from 'components/Consolidations/Create/modes'
import { generateConsolidationUserPermissions } from 'components/Consolidations/Create/conslolidation-user-permissions'

const ConsolidationViewEditGeneralInformationPage = () => {
  const navigate = useNavigate()
  const { showSuccessFlag } = useFlags()
  const { consolidationId } = useParams()
  const { currentUser } = useAuth()

  const { consolidation, isLoading, invalidate } = useConsolidation(consolidationId)
  const editConsolidationMutation = useMutation((data) =>
    editConsolidationParam(consolidationId, '', data)
  )

  const selectedReeferOption = useCallback((defaultRange) => {
    if (!defaultRange) return
    let [min, max] = defaultRange

    const selected = temperatureControlOptions.find((o) => {
      return o.value.range.min === min * 1 && o.value.range.max === max * 1
    })
    return selected || temperatureControlOptions.find((o) => o.value.type === 'custom')
  }, [])

  const dedicatedMode = modes?.find((m) => m.id === 2)

  const getSelectedModeId = useCallback(
    (typeOfCollies, shipmentType) => {
      let mode = modes.find(
        (m) => m?.shipmentType === shipmentType && m?.typeOfCollies === typeOfCollies
      )

      if (mode?.id) return mode?.id
      else if (typeOfCollies === 1 && [0, 1].includes(shipmentType)) return dedicatedMode.id
      return null
    },
    [dedicatedMode.id]
  )

  const defaultValues = useMemo(() => {
    let values = {
      modeType: getSelectedModeId(consolidation?.type_of_collies, consolidation?.shipment_type),
      vehicle_type:
        getSelectedModeId(consolidation?.type_of_collies, consolidation?.shipment_type) === 2
          ? consolidation?.shipment_type
          : null,
      hub_consolidator: getFilled(consolidation, 'hub_consolidator', null),
      arrival_country: getFilled(consolidation, 'arrival_country', null),
      reefer: getFilled(consolidation, 'reefer', 0),
      range: selectedReeferOption(consolidation?.reefer_temperature?.split(',')) || '',
      reefer_temperature: consolidation?.reefer_temperature?.split(','),
      agent_destination_id: consolidation?.receiving_agent
        ? {
            value: consolidation?.receiving_agent?.id_auto,
            imported: consolidation?.receiving_agent?.arca_imported,
            label: consolidation?.receiving_agent?.company_name,
          }
        : null,
      agent_destination_contact_id: getFilled(consolidation, 'agent_destination_contact_id', null),
      carrier: consolidation?.carrier_data
        ? {
            value: consolidation?.carrier_data?.id_auto,
            imported: consolidation?.carrier_data?.arca_imported,
            label: consolidation?.carrier_data?.company_name,
          }
        : null,
      carrier_contacts: consolidation?.carrier_contacts?.map((c) => c.id_auto),
      means: consolidation?.means?.map((mean) => ({ label: mean?.code, value: mean?.id_auto })),
    }

    if (consolidation?.carrier_contact_id) {
      values.carrier_contacts
        ?.filter((c) => c !== consolidation?.carrier_contact_id)
        ?.push(consolidation.carrier_contact_id)

      values.carrier_contact_id = null
    }

    return values
  }, [consolidation, getSelectedModeId, selectedReeferOption])

  const onSubmitEditConsolidation = (data, { setError }) => {
    let _mode = { ...modes.find((m) => m.id === data?.modeType) }

    if (_mode?.id === 2) {
      _mode = { ..._mode, shipmentType: data?.vehicle_type }
    }

    let formData = {
      ...data,
      agent_destination_id: data?.agent_destination_id?.value,
      carrier: data?.carrier?.value,
      shipment_type: _mode?.shipmentType,
      type_of_collies: _mode?.typeOfCollies,
      means: data?.means?.map((m) => m?.value),
      reefer_temperature: `${data?.reefer_temperature?.[0]},${data?.reefer_temperature?.[1]}`,
    }
    if (!formData?.reefer) delete formData?.reefer_temperature

    delete formData?.modeType
    delete formData?.range
    delete formData?.vehicle_type

    editConsolidationMutation.mutate(formData, {
      onError: (e) => {
        handleErrorOnSubmit(e, setError, data)
      },
      onSuccess: (res) => {
        invalidate()
        showSuccessFlag(res?.message)
        navigate(`/panel/consolidations/${consolidationId}`)
      },
    })
  }

  return (
    <AccessControl
      resource="consolidations"
      action="edit"
      userPermissions={generateConsolidationUserPermissions(consolidation, currentUser)}
    >
      <Helmet>
        <title>Edit consolidation</title>
      </Helmet>

      <Grid className="w-full">
        <Grid.Col md={8} xl={6} offsetMd={2} offsetXl={3}>
          <PageHeader
            breadcrumbs={
              <Breadcrumbs>
                <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
                <BreadcrumbsItem
                  text="Consolidations"
                  to="/panel/consolidations"
                  component={Link}
                />
                <BreadcrumbsItem
                  text={isLoading ? <Spinner size="xsmall" /> : consolidation?.consolidation_code}
                  to={`/panel/consolidations/${consolidationId}`}
                  component={Link}
                />
              </Breadcrumbs>
            }
          >
            Edit consolidation
          </PageHeader>

          <div className="py-4">
            {isLoading ? (
              <div className="flex justify-center my-32">
                <Spinner />
              </div>
            ) : (
              <Form onSubmit={onSubmitEditConsolidation} defaultValues={defaultValues}>
                {() => (
                  <>
                    <ConsolidationViewEditGeneral />

                    <ConsolidationViewEditCarrier />

                    <WarningBannerField />

                    <LoadingButton
                      className="!table ml-auto mr-2"
                      appearance="primary"
                      type="submit"
                      isLoading={editConsolidationMutation.isLoading}
                    >
                      Save changes
                    </LoadingButton>
                  </>
                )}
              </Form>
            )}
          </div>
        </Grid.Col>
      </Grid>
    </AccessControl>
  )
}

export default ConsolidationViewEditGeneralInformationPage
