import { useCallback, useMemo } from 'react'
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

// Apis
import { createShipmentDocumentType } from './../api/createShipmentDocumetnsTypes'

// Constants
import { awbTypes, documentsTypes } from '../constants'

// Utils
import { handleErrorOnSubmit } from 'utils/errors'
import { transformSelectField } from 'utils/helpers'

// Atlassian
import { ErrorMessage } from '@atlaskit/form'
import Button, { LoadingButton } from '@atlaskit/button'
import Modal, {
  ModalTitle,
  ModalHeader,
  ModalFooter,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import Select from '@atlaskit/select'
import Toggle from '@atlaskit/toggle'

// Interfaces
import { Field, Form, WarningBannerField, useFlags } from '@royan-co/user-interface'

const CreateShipmentDocumentModal = ({
  isOpen,
  onClose,
  isMasterShipment,
  shipmentId,
  shipmentType,
}) => {
  const { showSuccessFlag } = useFlags()
  const navigate = useNavigate()

  const documentsTypesOptions = useMemo(() => {
    let documents = [...documentsTypes]
    if (shipmentType !== 1) documents = documents.filter((d) => d.value !== 1) // Remove sea freight option
    if (shipmentType !== 2) documents = documents.filter((d) => d.value !== 0) // Remove air freight option

    return documents
  }, [shipmentType])

  const getTypeOptions = useCallback(
    (document) => {
      if (document === 0) {
        return isMasterShipment
          ? awbTypes?.filter((t) => t.value === 2)
          : awbTypes?.filter((t) => t.value !== 2)
      }
    },
    [isMasterShipment]
  )

  const documentCreationMutation = useMutation(createShipmentDocumentType.bind(null, shipmentId))

  const getDocumentKey = (documentValue) =>
    documentsTypesOptions?.find((d) => d.value === documentValue)?.key

  const handleSubmit = (data, { setError }) => {
    let formData = {
      document: data?.document,
      shipment_id: shipmentId,
    }

    if (data?.document === 0) formData.awb_type = data.type

    documentCreationMutation.mutate(formData, {
      onError: (e) => {
        handleErrorOnSubmit(e, setError, formData)
        console.error(e)
      },
      onSuccess: (res) => {
        showSuccessFlag(res.message)
        navigate(
          `/panel/shipments/document/${getDocumentKey(formData.document)}/${res?.document?.id}${
            data?.create_blank ? `/${'blank'}` : ''
          }`
        )
      },
    })
  }

  const handleCloseModal = () => {
    onClose()
    documentCreationMutation.reset()
  }

  return (
    <>
      <ModalTransition>
        {isOpen && (
          <Modal width={'small'} onClose={handleCloseModal}>
            <Form onSubmit={handleSubmit}>
              {({ watch }) => (
                <>
                  <ModalHeader>
                    <ModalTitle>Create document</ModalTitle>
                  </ModalHeader>

                  <div className="px-6">
                    <Field
                      isRequired
                      label="Document"
                      type="select"
                      name="document"
                      transform={transformSelectField(documentsTypesOptions)}
                    >
                      {({ fieldProps, error }) => (
                        <>
                          <Select
                            options={documentsTypesOptions}
                            placeholder="Choose a document"
                            {...fieldProps}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>

                    {[0]?.includes(watch('document')) && (
                      <Field
                        isRequired
                        label="Type"
                        type="select"
                        name="type"
                        className="!mt-4"
                        transform={transformSelectField(getTypeOptions(watch('document')))}
                      >
                        {({ fieldProps, error }) => (
                          <>
                            <Select
                              isClearable={true}
                              options={getTypeOptions(watch('document'))}
                              placeholder="Choose a type"
                              {...fieldProps}
                            />
                            {error && <ErrorMessage>{error}</ErrorMessage>}
                          </>
                        )}
                      </Field>
                    )}

                    <Field
                      name="create_blank"
                      label="Create blank"
                      type="checkbox"
                      key="toggle-create_blank"
                      className="!mt-4"
                    >
                      {({ fieldProps, error }) => (
                        <>
                          <div>
                            <Toggle {...fieldProps} id="toggle-create_blank" />
                          </div>
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>

                    <WarningBannerField />
                  </div>

                  <ModalFooter>
                    <Button appearance="subtle" onClick={handleCloseModal}>
                      Discard
                    </Button>

                    <LoadingButton
                      type="submit"
                      appearance="primary"
                      isLoading={documentCreationMutation.isLoading}
                      autoFocus
                    >
                      Create
                    </LoadingButton>
                  </ModalFooter>
                </>
              )}
            </Form>
          </Modal>
        )}
      </ModalTransition>
    </>
  )
}

export default CreateShipmentDocumentModal
