import { useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useNavigate, useParams } from 'react-router-dom'

// Utils
import { handleErrorOnSubmit } from 'utils/errors'
import { getFilled } from 'utils/helpers'
import { findCountryIsoByCode } from 'utils/CountryCodes'

// Hooks
import { useUser } from '../api/useUser'
import { useUpsertUser } from '../api/useUpsert'

// Atlassian
import PageHeader from '@atlaskit/page-header'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'
import Button, { LoadingButton } from '@atlaskit/button'
import Spinner from '@atlaskit/spinner'
import SectionMessage from '@atlaskit/section-message'

// Interfaces
import { Grid, Form, WarningBannerField, useFlags } from '@royan-co/user-interface'

// Components
import UserCreationForm from '../components/CreationForm'

const UsersCreationPage = () => {
  const navigate = useNavigate()
  const { userId } = useParams()
  const { showWarningFlag, showSuccessFlag } = useFlags()
  const pageTitle = userId ? 'Edit user' : `New user`

  const { data: user, isLoading, refetch, isError } = useUser(userId, { enabled: !!userId })

  const defaultValues = useMemo(() => {
    const values = {
      username: getFilled(user, 'username', ''),
      user_level_name_id: getFilled(user, 'user_level_name_id', null),
      email: getFilled(user, 'email', ''),
      cellphone: {
        rawPhone: user?.cellphone,
        dialCode: user?.cellphone_prefix,
        value: [user?.cellphone_prefix, user?.cellphone].join(''),
      },
      password: getFilled(user, 'password', ''),
      email_host: getFilled(user, 'email_host', ''),
      email_password: getFilled(user, 'email_password', ''),
      smtp_auth: getFilled(user, 'smtp_auth', 0),
      smtp_secure: getFilled(user, 'smtp_secure', null),
      imap_path: getFilled(user, 'imap_path', ''),
      imap_port: getFilled(user, 'imap_port', ''),
      imap_secure: getFilled(user, 'imap_secure', null),
    }

    if (userId) {
      values.name = getFilled(user, 'name', '')
      values.surname = getFilled(user, 'surname', '')
    }

    return values
  }, [user, userId])

  const creationMutation = useUpsertUser(userId)
  const onSubmitCreation = (data, { setError }) => {
    const formData = {
      ...data,
      cellphone_prefix: data?.cellphone?.rawPhone
        ? findCountryIsoByCode(data.cellphone.dialCode)
        : '',
      cellphone: data?.cellphone?.rawPhone,
    }
    if (!userId) {
      formData.registry_id = data.registry_id.value
      formData.registry_address_id = data.registry_address_id.value
      if (data.contact?.isNew) formData.full_name = data.contact.value
      else formData.contact_id = data.contact.value
    }

    delete formData.contact

    creationMutation.mutate(formData, {
      onError: (e) => {
        showWarningFlag('Something wrong!')
        handleErrorOnSubmit(e, setError, data)
      },
      onSuccess: (res) => {
        showSuccessFlag(userId ? 'User edited successfully' : res.message)
        navigate('/panel/users', { replace: true })
      },
    })
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <Grid gutter={24} className="w-full">
        <Grid.Col md={10} lg={8} xl={6} offsetMd={1} offsetLg={2} offsetXl={3}>
          <PageHeader
            breadcrumbs={
              <Breadcrumbs>
                <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
                <BreadcrumbsItem text="Configurations" />
                <BreadcrumbsItem text="Users" to="/panel/users" component={Link} />
              </Breadcrumbs>
            }
          >
            {pageTitle}
          </PageHeader>

          <div className="my-8">
            {isLoading && userId ? (
              <div className="text-center mt-24">
                <Spinner />
              </div>
            ) : isError ? (
              <SectionMessage
                appearance="warning"
                title="Something wrong on loading data, please retry."
              >
                <Button onClick={() => refetch()}>Retry</Button>
              </SectionMessage>
            ) : (
              <Form onSubmit={onSubmitCreation} defaultValues={defaultValues}>
                {() => (
                  <>
                    <UserCreationForm isEditing={!!userId} />

                    <WarningBannerField />

                    <LoadingButton
                      className="!table ml-auto mt-6"
                      appearance="primary"
                      type="submit"
                      isLoading={creationMutation.isLoading}
                    >
                      {userId ? 'Edit' : 'Create'}
                    </LoadingButton>
                  </>
                )}
              </Form>
            )}
          </div>
        </Grid.Col>
      </Grid>
    </>
  )
}

export default UsersCreationPage
