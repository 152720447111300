import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useParams, useNavigate } from 'react-router'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Helmet } from 'react-helmet-async'

// Apis
import { createPickup, getDefaultsForCreatePickup } from 'api/shipments'

// Contexts
import { useAuth } from 'contexts/AuthProvider'

// Features
import { AccessControl } from 'features/auth'

// Utils
import { getFullAddress } from 'utils/helpers'
import { handleErrorOnSubmit } from 'utils/errors'

// Hooks
import useConsolidationCargo from 'hooks/useConsolidationCargo'

// Atlassian
import PageHeader from '@atlaskit/page-header'
import Spinner from '@atlaskit/spinner'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'
import SectionMessage from '@atlaskit/section-message'
import Button, { LoadingButton } from '@atlaskit/button'

//Interfaces
import { Grid, Form, useFlags, WarningBannerField } from '@royan-co/user-interface'

// Components
import ShipmentPickupFormGeneral from 'components/Shipments/View/PickupFormGeneral'
import ShipmentPickupFormRouting from 'components/Shipments/View/PickupFormRouting'
import ShipmentPickupFormCargo from 'components/Shipments/View/PickupFormCargo'
import ShipmentPickupFormNotes from 'components/Shipments/View/PickupFormNotes'
import { generateConsolidationUserPermissions } from 'components/Consolidations/Create/conslolidation-user-permissions'

const ConsolidationViewCargoPickupPage = ({ isDelivery }) => {
  const navigate = useNavigate()
  const { showSuccessFlag } = useFlags()
  const { consolidationId } = useParams()
  const { currentUser } = useAuth()

  const { data, isLoading, isFetching, isError, refetch } = useQuery({
    queryKey: ['consolidation', 'pickup', consolidationId],
    queryFn: () => getDefaultsForCreatePickup({ resource: 'consolidations', id: consolidationId }),
    select: (res) => res?.consolidation,
  })
  const { consolidationCargo, isLoadingConsolidationCargo } = useConsolidationCargo(consolidationId)
  const userPermissions = generateConsolidationUserPermissions(data, currentUser)

  const pageTitle = isDelivery ? 'Cargo delivery' : 'Cargo collection'

  const createPickupMutation = useMutation(createPickup)

  const defaultValues = useMemo(() => {
    let values = {
      pick_up_address: isDelivery
        ? ''
        : data?.hub?.id_auto
        ? `${currentUser.registry.company_name || ''}\n${getFullAddress(data.hub)}`
        : '',
      type: isDelivery ? 'delivery' : 'collection',
      opening_hours_collection: '',
      opening_hours_delivery: '',
    }

    return values
  }, [currentUser, data, isDelivery])

  const onSubmitCargoPickup = (formData, { setError }) => {
    let _formData = {
      ...formData,
      consolidation_id: consolidationId,
      cargo: consolidationCargo
        ?.filter((_, index) => !!formData?.cargoes?.[index])
        ?.map((cargo) => ({ id: cargo.id })),
    }

    if (_formData.cargo.length === 0)
      _formData = {
        ..._formData,
        weight: data.weight,
        total_collies: data.total_collies,
        cbm: data.cbm,
      }

    delete _formData?.cargoes
    delete _formData?.pickup_hub

    createPickupMutation.mutate(_formData, {
      onError: (e) => {
        handleErrorOnSubmit(e, setError, formData)
      },
      onSuccess: (res) => {
        showSuccessFlag(res?.message)
        navigate(`/panel/consolidations/${consolidationId}/actions`)
      },
    })
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <Grid className="w-full">
        <Grid.Col md={8} xl={6} offsetMd={2} offsetXl={3}>
          <PageHeader
            breadcrumbs={
              <Breadcrumbs>
                <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
                <BreadcrumbsItem
                  text="Consolidations"
                  to="/panel/consolidations"
                  component={Link}
                />

                <BreadcrumbsItem
                  text={isLoading ? <Spinner size="xsmall" /> : data?.reference}
                  to={`/panel/consolidations/${consolidationId}`}
                  component={Link}
                />
              </Breadcrumbs>
            }
          >
            {pageTitle}
          </PageHeader>

          <div className="py-4">
            {isLoading || isFetching ? (
              <div className="flex justify-center my-32">
                <Spinner />
              </div>
            ) : isError ? (
              <SectionMessage
                appearance="warning"
                title="Something wrong on loading data, please retry."
              >
                <Button onClick={() => refetch()}>Retry</Button>
              </SectionMessage>
            ) : (
              <AccessControl
                resource="consolidations"
                action={!isDelivery ? 'createCargoCollection' : 'createCargoDelivery'}
                userPermissions={userPermissions}
              >
                <Form onSubmit={onSubmitCargoPickup} defaultValues={defaultValues}>
                  {() => (
                    <>
                      <ShipmentPickupFormGeneral />

                      <ShipmentPickupFormRouting toggleHubLabel="Delivery to hub" />

                      <ShipmentPickupFormCargo
                        cargoesList={consolidationCargo}
                        isLoading={isLoadingConsolidationCargo}
                      />

                      <ShipmentPickupFormNotes />

                      <WarningBannerField />

                      <LoadingButton
                        className="!table ml-auto mt-6"
                        appearance="primary"
                        type="submit"
                        isLoading={createPickupMutation.isLoading}
                      >
                        {isDelivery ? 'Create delivery order' : 'Create collection order'}
                      </LoadingButton>
                    </>
                  )}
                </Form>
              </AccessControl>
            )}
          </div>
        </Grid.Col>
      </Grid>
    </>
  )
}

export default ConsolidationViewCargoPickupPage
