import { useMemo } from 'react'
import { Link } from 'react-router-dom'

import useShipment from 'hooks/useShipment'

//Atlassian
import Lozenge from '@atlaskit/lozenge'

//Interfaces
import { ShortcutIcon } from '@royan-co/user-interface'

//Components
import Card from 'components/UI/Card'
import ClearanceTag from '../ClearanceTag'
import RowTable from 'components/UI/RowTable'
import ShipmentContactShow from '../ContactShow'
import ShipmentAddressShow from '../AddressShow'

const ShipmentCustomClearance = ({ className }) => {
  const { shipment } = useShipment()

  const clearanceRows = useMemo(() => {
    let rows = [
      {
        title: 'Export custom clearance',
        show: true,
        content: (
          <>
            <ClearanceTag value={shipment?.departure_custom_needed} />
            {shipment?.departure_custom_needed === 0 &&
              [0, 1].includes(shipment?.departure_custom_nn_reason) && (
                <small className="text-neutral-200 ml-3">
                  ({shipment.departure_custom_nn_reason === 0 ? 'Already cleared' : 'By sender'})
                </small>
              )}
          </>
        ),
      },
      {
        title: 'Export custom broker',
        show: shipment?.departure_custom_needed !== 0,
        content: shipment?.departure_custom_broker?.id_auto ? (
          <Link
            to={`/panel/registries/${shipment?.departure_custom_broker?.id_auto}`}
            target="_blank"
          >
            {shipment?.departure_custom_broker?.company_name} <ShortcutIcon size={16} />
          </Link>
        ) : (
          <Lozenge appearance="default">Not set</Lozenge>
        ),
      },
      {
        title: 'Address',
        show: shipment?.departure_custom_needed !== 0,
        content: shipment?.departure_custom_broker_address ? (
          <ShipmentAddressShow address={shipment?.departure_custom_broker_address} />
        ) : (
          <Lozenge appearance="default">Not set</Lozenge>
        ),
      },
      {
        title: 'Contact',
        show: shipment?.departure_custom_needed !== 0,
        content: shipment?.departure_custom_broker_contact ? (
          <ShipmentContactShow contact={shipment?.departure_custom_broker_contact} />
        ) : (
          <Lozenge appearance="default">Not set</Lozenge>
        ),
      },

      {
        title: 'Import custom clearance',
        show: true,
        content: (
          <>
            <ClearanceTag value={shipment?.destination_custom_needed} />
            {shipment?.destination_custom_needed === 0 &&
              shipment?.destination_custom_nn_reason === 0 && (
                <small className="text-neutral-200 ml-3">(Offloading on custom warehouse)</small>
              )}
          </>
        ),
      },
      {
        title: 'Import custom broker',
        show: shipment?.destination_custom_needed !== 0,
        content: shipment?.destination_custom_broker?.id_auto ? (
          <Link
            to={`/panel/registries/${shipment?.destination_custom_broker?.id_auto}`}
            target="_blank"
          >
            {shipment?.destination_custom_broker?.company_name} <ShortcutIcon size={16} />
          </Link>
        ) : (
          <Lozenge appearance="default">Not set</Lozenge>
        ),
      },
      {
        title: 'Address',
        show: shipment?.destination_custom_needed !== 0,
        content: shipment?.destination_custom_broker_address ? (
          <ShipmentAddressShow address={shipment?.destination_custom_broker_address} />
        ) : (
          <Lozenge appearance="default">Not set</Lozenge>
        ),
      },
      {
        title: 'Contact',
        show: shipment?.destination_custom_needed !== 0,
        content: shipment?.destination_custom_broker_contact ? (
          <ShipmentContactShow contact={shipment?.destination_custom_broker_contact} />
        ) : (
          <Lozenge appearance="default">Not set</Lozenge>
        ),
      },
      {
        title: 'Destination terminal',
        show: shipment?.destination_custom_needed === 0 && shipment.destination_terminal?.name,
        content: shipment.destination_terminal?.name,
      },
    ]

    return rows.filter((r) => r.show)
  }, [shipment])

  return (
    <Card title="Custom clearance">
      <RowTable rows={clearanceRows} width="w-1/2" />
    </Card>
  )
}

export default ShipmentCustomClearance
