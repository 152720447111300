import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'

// Hooks
import { getFinanceDetails } from 'api/consolidations'

// Atlassian
import Button from '@atlaskit/button'

import EmptyState from '@atlaskit/empty-state'
import Spinner from '@atlaskit/spinner'
import SectionMessage from '@atlaskit/section-message'

// Interfaces
import { useFormContext } from '@royan-co/user-interface'

// Components
import LinkInvoiceTableFields from './LinkInvoiceTableFields'
import ConsolidationReferenceField from 'components/Consolidations/View/ReferenceField'

const LinkInvoiceToConsolidationForm = ({ isCredit }) => {
  const { watch, setValue } = useFormContext()

  const consolidationId = watch('consolidation_id')

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ['finances', consolidationId],
    queryFn: () => getFinanceDetails(consolidationId),
    enabled: !!consolidationId,
  })

  useEffect(() => {
    if (data?.rows)
      setValue(
        'rows',
        data.rows.flatMap((row) => {
          const _row = { ...row, real_cost: row.cost ?? '' }
          return isCredit && row.passive_invoice
            ? _row
            : !isCredit && row.passive_invoice === null
            ? _row
            : []
        })
      )

    if (!consolidationId) setValue('rows', [])
  }, [data, setValue, consolidationId, isCredit])

  return (
    <div className="px-6">
      <div style={{ maxWidth: '350px' }}>
        <ConsolidationReferenceField
          name="consolidation_id"
          label="Consolidation reference"
          className="!mt-0"
        />
      </div>

      {isLoading && consolidationId ? (
        <div className="text-center mt-6">
          <Spinner />
        </div>
      ) : isError ? (
        <SectionMessage appearance="warning" title="Something wrong on loading data, please retry.">
          <Button onClick={() => refetch()}>Retry</Button>
        </SectionMessage>
      ) : data?.rows?.length === 0 && consolidationId ? (
        <EmptyState header="No Rows" />
      ) : consolidationId ? (
        <LinkInvoiceTableFields />
      ) : null}
    </div>
  )
}

export default LinkInvoiceToConsolidationForm
