import { useMutation } from '@tanstack/react-query'

import { createMasterShipmentAction } from 'api/shipments'

//Atlassian
import Spinner from '@atlaskit/spinner'
import { DropdownItem } from '@atlaskit/dropdown-menu'

//Interfaces
import { useFlags } from '@royan-co/user-interface'

const ShipmentsCreateMasterAction = ({ shipmentId }) => {
  const { showSuccessFlag, showWarningFlag } = useFlags()

  const createMasterShipmentMutation = useMutation(createMasterShipmentAction)

  const handleShipmentsCreateMasterAction = () => {
    createMasterShipmentMutation.mutate(shipmentId, {
      onError: (e) => {
        console.error(e)
        showWarningFlag('Something wrong on creating master shipment.')
      },
      onSuccess: (res) => {
        window.open(`${window.location.origin}/panel/shipments/${res?.master?.id_auto}`, '_blank')

        showSuccessFlag(res?.message)
      },
      onSettled: () => {
        // for close dropdown popup
        const event = new MouseEvent('click')
        document.body.dispatchEvent(event)
      },
    })
  }

  return (
    <>
      <DropdownItem
        isDisabled={createMasterShipmentMutation?.isLoading}
        onClick={(e) => {
          e.stopPropagation()
          handleShipmentsCreateMasterAction()
        }}
      >
        <div className="flex justify-between">
          Create master shipment
          {createMasterShipmentMutation?.isLoading && (
            <span className="ml-2">
              <Spinner size="small" />
            </span>
          )}
        </div>
      </DropdownItem>
    </>
  )
}

export default ShipmentsCreateMasterAction
