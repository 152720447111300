import { useEffect, useMemo, useState } from 'react'
import { Link, Navigate, useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { useQuery, useQueryClient } from '@tanstack/react-query'

//Apis
import {
  createConsolidation,
  editConsolidationParam,
  getConsolidationShipments,
} from 'api/consolidations'

//Contexts
import { useConsolidationCreationContext } from 'contexts/ConsolidationCreationProvider'

//Atlassian
import Button, { LoadingButton } from '@atlaskit/button'
import Spinner from '@atlaskit/spinner'
import SectionMessage from '@atlaskit/section-message'
import { ErrorMessage } from '@atlaskit/form'

//Interfaces
import {
  useFlags,
  WizardForm,
  WarningBanner,
  useWizardFormContext,
  Grid,
} from '@royan-co/user-interface'

//Components
import { modes } from 'components/Consolidations/Create/modes'
import useConsolidation from 'hooks/useConsolidation'
import ConsolidationSelectedShipmentsTotals from 'components/Consolidations/View/SelectedShipmentsTotals'
import SelectShipmentsFromList from 'components/Shipments/View/SelectShipmentsFromList'

const ConsolidationCreationShipmentsPage = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { showSuccessFlag } = useFlags()
  const { consolidationId } = useParams()
  const { steps, prevStep } = useConsolidationCreationContext()
  const isEditMode = !!consolidationId

  const [selectedShipments, dispatchSelectedShipments] = useOutletContext()

  const { invalidate } = useConsolidation(consolidationId, { enabled: isEditMode })

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [unknownError, setUnknownError] = useState(null)
  const [selectedShipmentsError, setSelectedShipmentsError] = useState('')

  const {
    data: consolidationShipments = [],
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ['consolidation', 'shipments', consolidationId],
    queryFn: () => getConsolidationShipments(consolidationId),
    retry: 2,
    enabled: isEditMode,
  })

  const {
    stepsValue: { general, agents },
  } = useWizardFormContext()

  const selectedShipmentsId = useMemo(
    () => selectedShipments.map((s) => s?.id_auto),
    [selectedShipments]
  )

  const mode = useMemo(() => {
    let _mode = { ...modes.find((m) => m.id === general?.modeType) }

    if (_mode?.id === 2) {
      _mode = { ..._mode, shipmentType: general?.vehicle_type }
    }

    return _mode
  }, [general?.modeType, general?.vehicle_type])

  const submitData = useMemo(() => {
    const data = {
      shipment_type: mode?.shipmentType,
      type_of_collies: mode?.typeOfCollies,
      hub_consolidator: general?.hub_consolidator,
      arrival_country: general?.arrival_country,
      reefer: general?.reefer,
      reefer_temperature: general?.reefer
        ? `${general?.reefer_temperature?.[0]},${general?.reefer_temperature?.[1]}`
        : '',

      carrier: agents?.carrier?.value,
      carrier_contact_id: agents?.carrier_contact_id,
      means: agents?.means?.map((m) => m?.value),
      agreed_cost: agents?.agreed_cost,
      tracking_mail: agents?.tracking_mail,
      mail_tracking_date: agents?.mail_tracking_date,
      agent_destination_id: agents?.agent_destination_id?.value,
      agent_destination_contact_id: agents?.agent_destination_contact_id,

      shipments: selectedShipmentsId,
    }

    if (!data?.mail_tracking_date) delete data.mail_tracking_date
    if (!data?.reefer_temperature) delete data.reefer_temperature

    return data
  }, [general, mode, agents, selectedShipmentsId])

  const handleSummaryStep = async () => {
    if (submitData?.shipments?.length < 1 && !isEditMode) {
      setSelectedShipmentsError('Select shipments is required.')
      return
    }

    try {
      setUnknownError(null)
      setIsSubmitting(true)

      const result = isEditMode
        ? await editConsolidationParam(consolidationId, '', submitData)
        : await createConsolidation(submitData)
      showSuccessFlag(result.message)

      if (isEditMode) {
        invalidate()
        queryClient.invalidateQueries({
          queryKey: ['consolidation', 'shipments', consolidationId],
          refetchType: 'none',
        })
      }

      navigate(`/panel/consolidations/${result.consolidation.id_auto}`, { replace: true })
    } catch (e) {
      console.error(e)
      setIsSubmitting(false)

      if ([400].includes(e.response?.status)) {
        setUnknownError('Something wrong in payload!')
      } else {
        setUnknownError(true)
      }
    }
  }

  useEffect(() => {
    if (consolidationShipments.length && !selectedShipments.length)
      consolidationShipments?.map((shipment) =>
        dispatchSelectedShipments({ type: 'add', payload: shipment })
      )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consolidationShipments])

  return !general?.arrival_country ? (
    <Navigate to={steps[0].path} replace />
  ) : (
    <>
      {!!consolidationId && isLoading ? (
        <div className="text-center my-8">
          <Spinner />
        </div>
      ) : isError ? (
        <Grid gutter={24} className="w-full">
          <Grid.Col md={6} offsetMd={3}>
            <SectionMessage
              appearance="warning"
              title={`Something wrong on loading consolidation data, please retry`}
            >
              <Button onClick={() => refetch()}>Retry</Button>
            </SectionMessage>
          </Grid.Col>
        </Grid>
      ) : (
        <>
          <ConsolidationSelectedShipmentsTotals
            selectedShipments={selectedShipments}
            agreedCost={agents.agreed_cost}
          />

          <SelectShipmentsFromList
            selectedShipments={selectedShipments}
            dispatchSelectedShipments={dispatchSelectedShipments}
            selectedShipmentsId={selectedShipmentsId}
            sectionParameter="consolidation"
          />
          {selectedShipmentsError && <ErrorMessage>{selectedShipmentsError}</ErrorMessage>}

          <WizardForm name="shipments" onSubmit={handleSummaryStep}>
            {() => (
              <>
                {unknownError && <WarningBanner text={unknownError} />}

                <div className="flex justify-end mt-6">
                  <Button type="submit" appearance="default" component={Link} to={prevStep.path}>
                    Previous step
                  </Button>

                  <LoadingButton
                    type="submit"
                    className="ml-2"
                    appearance="primary"
                    isLoading={isSubmitting}
                  >
                    {isEditMode ? 'Update' : 'Submit'}
                  </LoadingButton>
                </div>
              </>
            )}
          </WizardForm>
        </>
      )}
    </>
  )
}

export default ConsolidationCreationShipmentsPage
