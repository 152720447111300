import { useEffect } from 'react'

// Utils
import { transformSelectField } from 'utils/helpers'
import { registryClassificationOptions } from 'components/Registries/constants'
import { calculationMethodOptions } from 'components/Rates/constants'
import { modeOptions } from 'components/Shipments/constants'
import {
  areaTypeOptions,
  marginCalculatorTypeOptions,
} from 'features/marginCalculator/utils/constants'

// Atlassian
import Select from '@atlaskit/select'
import Textfield from '@atlaskit/textfield'
import { ErrorMessage } from '@atlaskit/form'
import Toggle from '@atlaskit/toggle'

// Interfaces
import { Field, useFormContext } from '@royan-co/user-interface'

// Components
import Card from 'components/UI/Card'
import CreationRoutesForm from './CreationRoutesForm'

const CreationGeneralForm = () => {
  const { watch, setValue } = useFormContext()
  const type = watch('country_area')

  useEffect(() => {
    if (type !== 1) {
      setValue('area', null)
    }
    if (type !== 0) {
      setValue('routes', null)
    }
  }, [setValue, type])

  return (
    <>
      <Card className="mb-6">
        <div className="grid md:grid-cols-2 md:gap-4">
          <Field
            isRequired
            label="Shipment type"
            name="shipment_type"
            type="select"
            transform={transformSelectField(modeOptions)}
            className="!mt-4"
          >
            {({ fieldProps, error }) => (
              <>
                <Select
                  isClearable={true}
                  options={modeOptions}
                  placeholder="Choose a shipment type"
                  {...fieldProps}
                />
                {error && <ErrorMessage>{error}</ErrorMessage>}
              </>
            )}
          </Field>

          <Field
            isRequired
            label="Type"
            type="select"
            name="country_area"
            transform={transformSelectField(marginCalculatorTypeOptions)}
            className="!mt-4"
          >
            {({ fieldProps, error }) => (
              <>
                <Select
                  isClearable={true}
                  options={marginCalculatorTypeOptions}
                  placeholder="Choose a type"
                  {...fieldProps}
                />
                {error && <ErrorMessage>{error}</ErrorMessage>}
              </>
            )}
          </Field>
        </div>

        {type === 1 && (
          <Field
            isRequired
            label="Area type"
            type="select"
            name="area"
            transform={transformSelectField(areaTypeOptions)}
            className="!mt-4"
          >
            {({ fieldProps, error }) => (
              <>
                <Select
                  isClearable={true}
                  options={areaTypeOptions}
                  placeholder="Choose a type"
                  {...fieldProps}
                />
                {error && <ErrorMessage>{error}</ErrorMessage>}
              </>
            )}
          </Field>
        )}

        <Field
          isRequired
          label="Registry classification"
          name="type"
          type="select"
          transform={transformSelectField(registryClassificationOptions)}
          className="!mt-4"
        >
          {({ fieldProps, error }) => (
            <>
              <Select
                options={registryClassificationOptions}
                placeholder="Choose one"
                {...fieldProps}
              />
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </>
          )}
        </Field>

        <div className="grid md:grid-cols-2 md:gap-4">
          <Field
            isRequired
            label="Calculation method"
            name="calculation_method"
            type="select"
            transform={transformSelectField(calculationMethodOptions)}
            className="!mt-4"
          >
            {({ fieldProps }) => (
              <Select
                isClearable={true}
                options={calculationMethodOptions}
                placeholder="Choose one"
                {...fieldProps}
              />
            )}
          </Field>

          <Field isRequired label="Minimum margin" name="minimum" className="!mt-4">
            {({ fieldProps, error }) => (
              <>
                <Textfield
                  placeholder="Type the minimum margin"
                  {...fieldProps}
                  elemAfterInput={
                    <div className="text-neutral-200 whitespace-nowrap bg-neutral-20 self-stretch flex items-center px-2">
                      Euro (€)
                    </div>
                  }
                />
                {error && <ErrorMessage>{error}</ErrorMessage>}
              </>
            )}
          </Field>
        </div>

        <Field
          label="Fixed minimum"
          name="minimum_fix"
          type="checkbox"
          key="minimum_fix"
          className="!mt-4"
        >
          {({ fieldProps, error }) => (
            <>
              <div>
                <Toggle {...fieldProps} id="minimum_fix" />
              </div>
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </>
          )}
        </Field>

        <Field
          label="Applicable to “Must go”"
          name="must_go"
          type="checkbox"
          key="must_go"
          className="!mt-4"
        >
          {({ fieldProps, error }) => (
            <>
              <div>
                <Toggle {...fieldProps} id="must_go" />
              </div>
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </>
          )}
        </Field>
      </Card>

      {type === 0 && <CreationRoutesForm />}
    </>
  )
}

export default CreationGeneralForm
