import { useMemo } from 'react'

// Utils
import { findOption } from 'utils/helpers'
import { gpsTrackerOptions } from './constants'

//Atlassian
import Lozenge from '@atlaskit/lozenge'

const ConsolidationGPSTrackerTag = ({ consolidation }) => {
  const getValue = useMemo(() => {
    let hasTrackingUnitId = false
    let hasNoTrackingUnitId = false

    consolidation?.means?.forEach((mean) => {
      if (mean?.tracking_unit_id) {
        hasTrackingUnitId = true
      } else {
        hasNoTrackingUnitId = true
      }
    })

    return hasTrackingUnitId && !hasNoTrackingUnitId
      ? 1
      : hasTrackingUnitId && hasNoTrackingUnitId
      ? 2
      : 0
  }, [consolidation?.means])

  const option = findOption(gpsTrackerOptions, getValue)

  return option ? <Lozenge appearance={option.appearance}>{option.label}</Lozenge> : null
}

export default ConsolidationGPSTrackerTag
