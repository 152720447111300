import React, { useEffect, useMemo } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

//Hooks
import useShipment from 'hooks/useShipment'
import useRegistryContacts from 'hooks/useRegistryContacts'

//Atlassian
import Spinner from '@atlaskit/spinner'
import PageHeader from '@atlaskit/page-header'
import SectionMessage from '@atlaskit/section-message'
import Button from '@atlaskit/button'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'

//Interfaces
import { Grid } from '@royan-co/user-interface'

//Components
import ShipmentComposingEmail from './ComposingEmail'

const ShipmentViewFirstContactPage = () => {
  const navigate = useNavigate()
  const { shipmentId } = useParams()

  const { shipment, upsertShipmentData, refetch, isError, isLoading, isFetching } = useShipment()
  const shipperId = useMemo(() => shipment?.shipper?.id_auto, [shipment])

  const { data: contacts, isLoading: isLoadingRegistryContact } = useRegistryContacts(shipperId, {
    enabled: !!shipperId,
  })

  const backToActions = () => navigate(`/panel/shipments/${shipmentId}/actions`)
  const onSendEmail = () => {
    upsertShipmentData({ status: 1 })
    setTimeout(() => {
      backToActions()
    }, 100)
  }

  useEffect(() => {
    if (shipment?.id_auto && (!shipment?.shipper?.id_auto || shipment?.shipper_contact?.length < 1))
      navigate(`/panel/shipments/${shipmentId}`)
  }, [navigate, shipment, shipmentId])

  return (
    <>
      <Helmet>
        <title>First contact with shipper</title>
      </Helmet>

      <Grid gutter={24} className="w-full">
        <Grid.Col md={9} lg={6} offsetMd={3}>
          <PageHeader
            breadcrumbs={
              <Breadcrumbs>
                <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
                <BreadcrumbsItem text="Shipments" to="/panel/shipments" component={Link} />
                <BreadcrumbsItem
                  text="View shipment"
                  to={`/panel/shipments/${shipmentId}`}
                  component={Link}
                />
                <BreadcrumbsItem
                  text={isLoading ? <Spinner size="xsmall" /> : shipment?.shipment_code}
                  to={`/panel/shipments/${shipmentId}`}
                  component={Link}
                />
              </Breadcrumbs>
            }
          >
            First contact with shipper
          </PageHeader>
        </Grid.Col>
      </Grid>

      {isLoading || isFetching ? (
        <div className="text-center my-8">
          <Spinner />
        </div>
      ) : isError ? (
        <Grid gutter={24} className="w-full">
          <Grid.Col md={6} offsetMd={3}>
            <SectionMessage
              appearance="warning"
              title="Something wrong on loading shipment details, please retry"
            >
              <Button onClick={() => refetch()}>Retry</Button>
            </SectionMessage>
          </Grid.Col>
        </Grid>
      ) : (
        <Grid className="w-full">
          <Grid.Col md={6} offsetMd={3}>
            <ShipmentComposingEmail
              shipmentId={shipmentId}
              registryId={shipperId}
              originTemplate={'first-contact'}
              to={{
                options: contacts,
                isLoading: isLoadingRegistryContact,
              }}
              cc={{
                options: contacts,
                isLoading: isLoadingRegistryContact,
              }}
              enableCreateNewContact
              onSuccess={onSendEmail}
              onCancel={backToActions}
            />
          </Grid.Col>
        </Grid>
      )}
    </>
  )
}

export default ShipmentViewFirstContactPage
