import { useMutation } from '@tanstack/react-query'

// Apis
import { disputeInvoice } from './invoice-requests'

// Hooks
import { useUpdateInvoice } from './getInvoice'

// Interfaces
import { useFlags, useRoyanTable } from '@royan-co/user-interface'

const useDisputeInvoice = ({ invoiceId }) => {
  const { refresh } = useRoyanTable()
  const { showSuccessFlag, showWarningFlag } = useFlags()
  const { upsertInvoiceProperties } = useUpdateInvoice(invoiceId)

  return useMutation({
    mutationFn: () => disputeInvoice(invoiceId),

    onSuccess: (res) => {
      showSuccessFlag(res.message)

      if (refresh) {
        refresh()
      } else upsertInvoiceProperties(res.invoice)
    },

    onError: (e) => {
      console.error(e)
      showWarningFlag('Something wrong!')
    },
  })
}

export { useDisputeInvoice }
