import { useQuery } from '@tanstack/react-query'

// Apis
import { getShipmentsStatistics } from './dashboard-requests'

/**
 * get shipments list
 * @param {String} type
 * @param {object} params
 * @param {import('@tanstack/react-query').UseQueryOptions} options
 * @returns {import('@tanstack/react-query').UseQueryResult}
 */
export const useShipmentStatistics = (type, params, options = {}) => {
  return useQuery({
    queryKey: ['statistics-shipments', type, params],
    queryFn: () => getShipmentsStatistics(type, params),
    staleTime: Infinity,
    ...options,
  })
}
