import { useMemo } from 'react'

// Interfaces
import { WarningIcon } from '@royan-co/user-interface'

const ConsolidationViewTrackingNotification = ({ consolidation }) => {
  const hasGPSProblem = useMemo(
    () => consolidation?.means?.some((obj) => !obj?.tracking_unit?.is_online),
    [consolidation]
  )

  return (
    <div className="text-yellow-400">
      {consolidation?.means?.length && consolidation?.status === 1 && hasGPSProblem ? (
        <WarningIcon />
      ) : null}
    </div>
  )
}

export default ConsolidationViewTrackingNotification
