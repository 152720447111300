import { useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'

// Atlassian
import { ButtonItem, MenuGroup, Section } from '@atlaskit/menu'

// Interfaces
import {
  AddIcon,
  UserIcon,
  TrashIcon,
  RefreshIcon,
  useFieldArray,
  useDisclosure,
} from '@royan-co/user-interface'

// Components
import { useFinanceEditingContext } from 'contexts/FinanceEditingProvider'
import EditFinanceAccessoryModal from './AccessoryModal'
import DisabledElementTooltip from 'components/UI/DisabledElementTooltip'
import EditFinanceDeleteLegModal from './DeleteLegModal'
import EditFinanceRecalculateModal from './RecalculateModal'

const EditFinanceActionsCard = () => {
  const {
    entityType,
    currentLegIndex,
    deleteCurrentLeg,
    currentLeg,
    currentLegType,
    removeLegErrors,
  } = useFinanceEditingContext()

  const [isOpenAccessoryModal, accessoryModalHandler] = useDisclosure('accessory')
  const [isOpenDeleteLegModal, deleteLegModalHandler] = useDisclosure('delete')
  const [isOpenRecalculateModal, recalculateModalHandler] = useDisclosure('recalculate')

  const { append: appendAccessory } = useFieldArray({
    name: `accessories`,
  })
  const { append: appendSupplier } = useFieldArray({
    name: `suppliers`,
  })

  const addAccessory = (data) => {
    appendAccessory({
      _id: uuidv4(),
      service_code: null,
      name: '',
      quantity: 1,
      rate: 1,
      calculation_method: 0,
      cost: 0,
      quotation: 0,
      vat: '',
      ...data,
    })
  }

  const addSupplier = () => {
    appendSupplier({
      _id: uuidv4(),
      service_code: null,
      name: '',
      quantity: 1,
      rate: 1,
      calculation_method: 0,
      cost: 0,
      quotation: 0,
      vat: '',
      supplier: null,
      selected: true,
      surcharges: [],
    })
  }

  const arrivalDepartureData = useMemo(() => {
    const arrivalKey = currentLegType.arrival.arrivalKey
    const arrival = currentLeg[arrivalKey]

    const departureKey = currentLegType.departure.departureKey
    const departure = currentLeg[departureKey]

    return { arrivalKey, arrival, departureKey, departure }
  }, [currentLeg, currentLegType.arrival.arrivalKey, currentLegType.departure.departureKey])

  const disabledRecalculateCost = useMemo(() => {
    const { arrivalKey, arrival, departureKey, departure } = arrivalDepartureData

    if (arrivalKey === 'arrival_zone') {
      return !arrival?.city || !arrival?.countryAbbr
    } else if (departureKey === 'departure_zone') return !departure?.city || !departure?.countryAbbr
    else return !arrival || !departure
  }, [arrivalDepartureData])

  const openAccessoryModal = () => {
    accessoryModalHandler.open()
  }
  const openRecalculateCostsModal = () => {
    recalculateModalHandler.open()
  }
  const openDeletingLegModal = () => {
    deleteLegModalHandler.open()
  }
  const deleteLeg = () => {
    removeLegErrors(currentLeg._id)
    deleteCurrentLeg(currentLegIndex)
    deleteLegModalHandler.close()
  }

  return (
    <div className="mb-4 shadow rounded">
      <MenuGroup>
        <Section title="Actions">
          {[
            {
              content: 'Add a new supplier',
              icon: <UserIcon className="text-green-400" size={16} />,
              onClickHandle: addSupplier,
            },
            {
              content: 'Add a new accessory',
              icon: <AddIcon className="text-yellow-400" size={16} />,
              onClickHandle: openAccessoryModal,
            },
            entityType === 'shipment' && {
              content: 'Recalculate the costs',
              icon: <RefreshIcon className="text-blue-400" size={16} />,
              onClickHandle: openRecalculateCostsModal,
              isDisabled: disabledRecalculateCost,
              disabledMessage: 'The value of arrival or departure is not valid.',
            },
            {
              content: 'Delete this leg',
              icon: <TrashIcon className="text-red-400" size={20} />,
              onClickHandle: openDeletingLegModal,
            },
          ].map((item, index) => (
            <DisabledElementTooltip
              key={index}
              isDisabled={item?.isDisabled}
              tooltipContent={item?.disabledMessage}
            >
              <ButtonItem
                iconBefore={item.icon}
                onClick={item.onClickHandle}
                isDisabled={item?.isDisabled}
              >
                {item.content}
              </ButtonItem>
            </DisabledElementTooltip>
          ))}
        </Section>
      </MenuGroup>

      <EditFinanceAccessoryModal
        onSubmit={addAccessory}
        isOpen={isOpenAccessoryModal}
        onClose={accessoryModalHandler.close}
      />

      <EditFinanceDeleteLegModal
        isOpen={isOpenDeleteLegModal}
        onClose={deleteLegModalHandler.close}
        onSubmit={deleteLeg}
      />

      {entityType === 'shipment' && (
        <EditFinanceRecalculateModal
          isOpen={isOpenRecalculateModal}
          onClose={recalculateModalHandler.close}
          arrivalDepartureData={arrivalDepartureData}
        />
      )}
    </div>
  )
}

export default EditFinanceActionsCard
