import { useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

// Utils
import { handleErrorOnSubmit } from 'utils/errors'

// Apis
import { createPaymentTerm, editPaymentTerm } from './../../api/payment-terms-requests'

// Atlassian
import { LoadingButton } from '@atlaskit/button'
import PageHeader from '@atlaskit/page-header'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'
import Textfield from '@atlaskit/textfield'
import { ErrorMessage } from '@atlaskit/form'

// Interfaces
import { Grid, Form, Field, WarningBannerField, useFlags } from '@royan-co/user-interface'

// Components
import Card from 'components/UI/Card'

const PaymentTermsCreationPage = () => {
  const { codeId } = useParams()
  let [searchParams, _] = useSearchParams()

  const { showSuccessFlag, showWarningFlag } = useFlags()

  const navigate = useNavigate()
  const pageTitle = codeId ? 'Edit payment terms' : `New payment terms`

  const createPaymentTermMutation = useMutation((data) =>
    !codeId ? createPaymentTerm(data) : editPaymentTerm(codeId, data)
  )

  const defaultValues = useMemo(() => {
    const values = {
      code: codeId || '',
      description: searchParams.get('description') || '',
    }

    return values
  }, [searchParams,codeId])

  const onSubmitCreation = (data, { setError }) => {
    createPaymentTermMutation.mutate(data, {
      onError: (e) => {
        showWarningFlag('Something wrong!')
        handleErrorOnSubmit(e, setError, data)
      },
      onSuccess: (res) => {
        showSuccessFlag(res.message)
        navigate('/panel/payment-terms', { replace: true })
      },
    })
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <Grid gutter={24} className="w-full">
        <Grid.Col md={10} lg={8} xl={6} offsetMd={1} offsetLg={2} offsetXl={3}>
          <PageHeader
            breadcrumbs={
              <Breadcrumbs>
                <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
                <BreadcrumbsItem text="Accounting" />
                <BreadcrumbsItem text="Payment terms" to="/panel/payment-terms" component={Link} />
              </Breadcrumbs>
            }
          >
            {pageTitle}
          </PageHeader>

          <div className="my-8">
            <Form onSubmit={onSubmitCreation} defaultValues={defaultValues}>
              {() => (
                <>
                  <Card className="mt-0">
                    <Field isRequired label="Code" name="code" defaultValue="" className="!mt-0">
                      {({ fieldProps, error }) => (
                        <>
                          <Textfield placeholder="Type the code" {...fieldProps} />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>

                    <Field
                      isRequired
                      label="Description"
                      name="description"
                      defaultValue=""
                      className="!mt-4"
                    >
                      {({ fieldProps, error }) => (
                        <>
                          <Textfield placeholder="Type the description" {...fieldProps} />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                  </Card>

                  <WarningBannerField />

                  <LoadingButton
                    className="!table ml-auto mt-6"
                    appearance="primary"
                    type="submit"
                    isLoading={createPaymentTermMutation.isLoading}
                  >
                    {codeId ? 'Edit' : 'Create'}
                  </LoadingButton>
                </>
              )}
            </Form>
          </div>
        </Grid.Col>
      </Grid>
    </>
  )
}

export default PaymentTermsCreationPage
