import React from 'react'

// Hooks
import { useDeleteCurrency } from '../api/useDeleteCurrency'

//Atlassian
import Modal, {
  ModalBody,
  ModalTitle,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import Button, { LoadingButton } from '@atlaskit/button'

//Interfaces
import { useFlags, WarningBanner } from '@royan-co/user-interface'

const CurrenciesDeleteModal = ({ isOpen, onClose, currencyCode }) => {
  const { showSuccessFlag, showWarningFlag } = useFlags()

  const deleteMutation = useDeleteCurrency(currencyCode)

  const closeModal = () => {
    onClose()
    deleteMutation.reset()
  }

  const removeCurrency = () => {
    deleteMutation.mutate(currencyCode, {
      onError: (e) => {
        showWarningFlag('Something wrong!')
      },
      onSuccess: (res) => {
        showSuccessFlag(res.message)
        onClose()
      },
    })
  }

  return (
    <>
      <ModalTransition>
        {isOpen && (
          <Modal onClose={closeModal}>
            <ModalHeader>
              <ModalTitle appearance="danger">Delete currency</ModalTitle>
            </ModalHeader>
            <ModalBody>
              You are about to delete a currency. Are you sure?
              {deleteMutation.isError && <WarningBanner />}
            </ModalBody>
            <ModalFooter>
              <Button appearance="subtle" onClick={closeModal}>
                Cancel
              </Button>
              <LoadingButton
                isLoading={deleteMutation.isLoading}
                appearance="danger"
                onClick={removeCurrency}
                autoFocus
              >
                Delete
              </LoadingButton>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </>
  )
}

export default CurrenciesDeleteModal
