import { useState, useEffect, useCallback, useRef } from 'react'
import axios from 'axios'

import { getTransportationUnitAutocomplete } from 'api/registries'

//Interfaces
import { useDebounce } from '@royan-co/user-interface'

const useTransportationAutocomplete = () => {
  const controller = useRef()
  const [transportationAutocompleteOptions, setTransportationAutocompleteOptions] = useState([])
  const [term, setTerm] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const debouncedTerm = useDebounce(term)

  const getOptions = useCallback(async () => {
    try {
      setIsLoading(true)
      if (controller.current) {
        controller.current.abort()
      }

      controller.current = new AbortController()
      const data = await getTransportationUnitAutocomplete(debouncedTerm, controller.current)
      const _options = data.map((item) => ({
        value: item?.id_auto * 1,
        label: item?.code,
      }))

      setTransportationAutocompleteOptions(_options)
      setIsLoading(false)
    } catch (error) {
      if (!axios.isCancel(error)) {
        setIsLoading(false)
        console.error(error)
      }
    }
  }, [debouncedTerm])

  useEffect(() => {
    if (debouncedTerm) getOptions()
  }, [debouncedTerm, getOptions])

  return {
    transportationAutocompleteOptions,
    setTermTransportationAutocomplete: setTerm,
    isLoadingTransportationAutocomplete: isLoading,
  }
}

export default useTransportationAutocomplete
