import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useParams, useNavigate } from 'react-router'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Helmet } from 'react-helmet-async'

import {
  createPickup,
  editPickup,
  getDefaultsForCreatePickup,
  getDetailsForEditPickup,
} from 'api/shipments'

//Utils
import { capitalizeFirstLetter, getFilled, getFullAddress } from 'utils/helpers'
import { handleErrorOnSubmit } from 'utils/errors'

//Hooks
import useShipmentCargo from 'hooks/useShipmentCargo'

//Atlassian

import PageHeader from '@atlaskit/page-header'
import Spinner from '@atlaskit/spinner'
import SectionMessage from '@atlaskit/section-message'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'
import Button, { LoadingButton } from '@atlaskit/button'

//Interfaces
import { Grid, Form, useFlags, WarningBannerField } from '@royan-co/user-interface'

//Components
import ShipmentPickupFormGeneral from 'components/Shipments/View/PickupFormGeneral'
import ShipmentPickupFormRouting from 'components/Shipments/View/PickupFormRouting'
import ShipmentPickupFormCargo from 'components/Shipments/View/PickupFormCargo'
import ShipmentPickupFormNotes from 'components/Shipments/View/PickupFormNotes'

const ShipmentViewCargoPickupPage = ({ isDelivery }) => {
  const navigate = useNavigate()
  const { showSuccessFlag } = useFlags()
  const { shipmentId, documentId } = useParams()

  const { data, isLoading, isFetching, isError, refetch } = useQuery({
    queryKey: documentId ? [] : ['shipment', 'pickup', shipmentId],
    queryFn: () =>
      documentId
        ? getDetailsForEditPickup(documentId)
        : getDefaultsForCreatePickup({ resource: 'shipments', id: shipmentId }),
  })
  const { shipmentCargo, isLoadingShipmentCargo } = useShipmentCargo(shipmentId, {
    pickupStatus: 0,
  })
  const pageTitle = capitalizeFirstLetter(
    `${documentId ? 'Edit ' : ''}${isDelivery ? 'cargo delivery' : 'cargo collection'}`
  )

  const createPickupMutation = useMutation(
    documentId ? editPickup.bind(null, documentId) : createPickup
  )

  const defaultValues = useMemo(() => {
    let values = {}
    if (documentId) {
      values = {
        carrier: data?.carrier
          ? {
              label: data?.carrier_data?.company_name,
              value: data?.carrier,
              imported: data?.carrier_data?.arca_imported,
            }
          : null,
        rate_agreed: getFilled(data, 'rate_agreed', ''),
        cargo_reference: getFilled(data, 'cargo_reference', ''),
        pickup_hub: getFilled(data, 'pickup_hub', 0),
        hub_consolidator: getFilled(data, 'hub_consolidator', null),
        pick_up_address: getFilled(data, 'pick_up_address', ''),
        delivery_address: getFilled(data, 'delivery_address', ''),
        loading_date: getFilled(data, 'loading_date', ''),
        delivery_date: getFilled(data, 'delivery_date', ''),
        opening_hours_collection: getFilled(data, 'opening_hours_collection', ''),
        opening_hours_delivery: getFilled(data, 'opening_hours_delivery', ''),
        notes: getFilled(data, 'notes', ''),
        type: isDelivery ? 'delivery' : 'collection',
      }
    } else {
      values = {
        rate_agreed: '',
        cargo_reference: '',
        loading_date: '',
        opening_hours_collection: '',
        opening_hours_delivery: '',
        pick_up_address: isDelivery
          ? ''
          : data?.shipment?.shipper_address?.id_auto
          ? `${data.shipment.shipper || ''}\n${getFullAddress(data.shipment.shipper_address)}`
          : '',
        delivery_address: data?.shipment?.consignee_address?.id_auto
          ? `${data.shipment.consignee || ''}\n${getFullAddress(data.shipment.consignee_address)}`
          : '',
        type: isDelivery ? 'delivery' : 'collection',
      }

      if (!isDelivery) {
        values.carrier = data?.carrier
          ? {
              label: data?.carrier?.company_name,
              value: data?.carrier.id_auto,
              imported: data?.carrier?.arca_imported,
            }
          : null
        values.rate_agreed = data?.agreed_rate || null
        values.pickup_hub = data?.arrival_hub ? 1 : 0
        values.hub_consolidator = data?.arrival_hub?.id_auto || null
      }
    }

    return values
  }, [documentId, isDelivery, data])

  const onSubmitCargoPickup = (formData, { setError }) => {
    let _formData = {
      ...formData,
      carrier: formData?.carrier?.value || formData.carrier,
      shipment_id: shipmentId,
      cargo: shipmentCargo
        ?.filter((_, index) => !!formData?.cargoes?.[index])
        ?.map((cargo) => ({ id: cargo.id })),
    }

    if (_formData.cargo.length === 0)
      _formData = {
        ..._formData,
        weight: data.shipment.weight,
        total_collies: data.shipment.total_collies,
        cbm: data.shipment.cbm,
      }

    delete _formData?.cargoes
    delete _formData?.pickup_hub

    createPickupMutation.mutate(_formData, {
      onError: (e) => {
        handleErrorOnSubmit(e, setError, formData)
      },
      onSuccess: (res) => {
        showSuccessFlag(res?.message)
        navigate(`/panel/shipments/${shipmentId}/${documentId ? 'documents' : 'actions'}`)
      },
    })
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <Grid className="w-full">
        <Grid.Col md={8} xl={6} offsetMd={2} offsetXl={3}>
          <PageHeader
            breadcrumbs={
              <Breadcrumbs>
                <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
                <BreadcrumbsItem text="Shipments" to="/panel/shipments" component={Link} />
                <BreadcrumbsItem
                  text="View shipment"
                  to={`/panel/shipments/${shipmentId}`}
                  component={Link}
                />
                <BreadcrumbsItem
                  text={isLoading ? <Spinner size="xsmall" /> : data?.shipment?.reference}
                  to={`/panel/shipments/${shipmentId}`}
                  component={Link}
                />
              </Breadcrumbs>
            }
          >
            {pageTitle}
          </PageHeader>

          <div className="py-4">
            {isLoading || isFetching ? (
              <div className="flex justify-center my-32">
                <Spinner />
              </div>
            ) : isError ? (
              <SectionMessage
                appearance="warning"
                title="Something wrong on loading data, please retry."
              >
                <Button onClick={() => refetch()}>Retry</Button>
              </SectionMessage>
            ) : (
              <Form onSubmit={onSubmitCargoPickup} defaultValues={defaultValues}>
                {() => (
                  <>
                    <ShipmentPickupFormGeneral />

                    <ShipmentPickupFormRouting />

                    <ShipmentPickupFormCargo
                      defaultSelectedCargoes={
                        documentId ? data?.cargo?.map((cargo) => cargo.id) : null
                      }
                      cargoesList={shipmentCargo}
                      isLoading={isLoadingShipmentCargo}
                    />

                    <ShipmentPickupFormNotes />

                    <WarningBannerField />

                    <LoadingButton
                      className="!table ml-auto mt-6"
                      appearance="primary"
                      type="submit"
                      isLoading={createPickupMutation.isLoading}
                    >
                      {documentId ? 'Edit ' : 'Create '}
                      {isDelivery ? 'delivery order' : 'collection order'}
                    </LoadingButton>
                  </>
                )}
              </Form>
            )}
          </div>
        </Grid.Col>
      </Grid>
    </>
  )
}

export default ShipmentViewCargoPickupPage
