import { useMemo } from 'react'

// Atlassian
import { DropdownItem } from '@atlaskit/dropdown-menu'

// Components
import DisabledElementTooltip from 'components/UI/DisabledElementTooltip'

const DeleteInvoiceAction = ({ invoice, isPassive, openDeleteModal }) => {
  const accessabilityRemove = useMemo(
    () => (isPassive ? invoice?.arca_status !== 3 : !!invoice?.is_proforma),
    [invoice, isPassive]
  )

  return invoice?.id_auto ? (
    <DisabledElementTooltip
      tooltipContent={isPassive ? 'Invoice is elaborated' : 'Invoice is not proforma'}
      isDisabled={!accessabilityRemove}
    >
      <DropdownItem
        onClick={() => openDeleteModal(invoice?.id_auto)}
        isDisabled={!accessabilityRemove}
      >
        Delete
      </DropdownItem>
    </DisabledElementTooltip>
  ) : null
}

export default DeleteInvoiceAction
