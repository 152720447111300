import React from 'react'

// Apis
import { useDeleteInvoice } from '../../../api/actions/useDeleteInvoice'

//Atlassian
import Modal, {
  ModalBody,
  ModalTitle,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import Button, { LoadingButton } from '@atlaskit/button'

//Interfaces
import { WarningBanner } from '@royan-co/user-interface'

const DeleteInvoiceModal = ({ isOpen, onClose, invoiceId, shipmentId, type }) => {
  const deleteInvoiceMutation = useDeleteInvoice({ invoiceId, shipmentId, type })

  const closeModal = () => {
    onClose()
    deleteInvoiceMutation.reset()
  }

  const removeProformaInvoice = () => {
    deleteInvoiceMutation.mutate('', {
      onError: (e) => {
        console.error(e)
      },
      onSuccess: () => {
        onClose()
      },
    })
  }

  return (
    <>
      <ModalTransition>
        {isOpen && (
          <Modal onClose={closeModal}>
            <ModalHeader>
              <ModalTitle appearance="danger">Delete invoice</ModalTitle>
            </ModalHeader>
            <ModalBody>
              You are about to delete an invoice. Are you sure?
              {deleteInvoiceMutation.isError && <WarningBanner />}
            </ModalBody>
            <ModalFooter>
              <Button appearance="subtle" onClick={closeModal}>
                Cancel
              </Button>
              <LoadingButton
                isLoading={deleteInvoiceMutation.isLoading}
                appearance="danger"
                onClick={removeProformaInvoice}
                autoFocus
              >
                Delete
              </LoadingButton>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </>
  )
}

export default DeleteInvoiceModal
