import axios from 'services/Request'

export function createInvoiceRow(invoiceId, data) {
  return axios.post(`/api/invoices/rows/create/${invoiceId}`, data)
}

export function editInvoiceRow(rowId,data) {
  return axios.put(`/api/invoices/rows/${rowId}`,data)
}

export function deleteInvoiceRow(rowId) {
  return axios.delete(`/api/invoices/rows/${rowId}`)
}
