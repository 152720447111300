import { createContext, useContext, useState } from 'react'

const RegistriesContext = createContext()

const useRegistryCreationContext = () => {
  const context = useContext(RegistriesContext)

  return context
}

const RegistryCreationProvider = ({ children }) => {
  // const [removedBranch, setRemovedBranch] = useState(null)
  const [identicalFields, setIdenticalFields] = useState([])
  // const [registeryCompany, setRegisteryCompany] = useState(null)

  const value = {
    // removedBranch,
    identicalFields,
    // setRemovedBranch,
    // registeryCompany,
    // setRegisteryCompany,
    setIdenticalFields,
  }

  return <RegistriesContext.Provider value={value}>{children}</RegistriesContext.Provider>
}

export { useRegistryCreationContext, RegistryCreationProvider }
