// Interfaces
import { DotsIcon } from '@royan-co/user-interface'

// Atlassian
import Lozenge from '@atlaskit/lozenge'
import Button from '@atlaskit/button'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'

// Components
import DropdownLink from 'components/UI/DropdownLink'

export const generateUsersColumns = (openDeactivateModal) => {
  return [
    {
      key: 'first_name',
      title: 'First name',
      isSortable: false,
      show: true,
      content: (item) => item.name || <Lozenge>Not set</Lozenge>,
    },
    {
      key: 'surname',
      title: 'Surname',
      isSortable: false,
      show: true,
      content: (item) => item.surname || <Lozenge>Not set</Lozenge>,
    },
    {
      key: 'username',
      title: 'Username',
      isSortable: false,
      show: true,
      content: (item) => item.username || <Lozenge>Not set</Lozenge>,
    },
    {
      key: 'role',
      title: 'Role',
      isSortable: false,
      show: true,
      content: (item) =>
        item.role ? (
          <Lozenge appearance="inprogress"> {item.role} </Lozenge>
        ) : (
          <Lozenge>Not set</Lozenge>
        ),
    },
    {
      key: 'email',
      title: 'Email',
      isSortable: false,
      show: true,
      content: (item) => item.email || <Lozenge>Not set</Lozenge>,
    },
    {
      key: 'phone',
      title: 'Phone',
      isSortable: false,
      show: true,
      content: (item) => item.phone || <Lozenge>Not set</Lozenge>,
    },
    {
      key: 'registry',
      title: 'Registry',
      isSortable: false,
      show: true,
      content: (item) =>
        item?.registry?.id_auto ? (
          <a href={`/panel/registries/${item.registry.id_auto}`} target="_blank" rel="noreferrer">
            {item.registry.company_name}
          </a>
        ) : (
          <Lozenge>Not set</Lozenge>
        ),
    },
    {
      key: 'registry_address',
      title: 'Registry address',
      isSortable: false,
      show: true,
      content: (item) => item.registry_address.address || <Lozenge>Not set</Lozenge>,
    },
    {
      key: 'creation_date',
      title: 'Creation date',
      isSortable: true,
      show: true,
      content: (item) => item?.creation_date,
    },
    {
      key: 'actions',
      title: 'Actions',
      show: true,
      content: (item) => (
        <DropdownMenu
          placement="bottom-end"
          trigger={({ triggerRef, shouldRenderToParent, ...props }) => (
            <Button
              {...props}
              appearance="subtle"
              children={<DotsIcon label="more" />}
              ref={triggerRef}
            />
          )}
        >
          <DropdownItemGroup>
            <DropdownLink children="Edit" to={`/panel/users/${item.auto_id}/edit`} />
            <DropdownItem
              children={item.active ? 'Deactivate' : 'Activate'}
              onClick={() => openDeactivateModal(item)}
            />
          </DropdownItemGroup>
        </DropdownMenu>
      ),
    },
  ]
}
