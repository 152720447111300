import { useMemo } from 'react'

// Hooks
import { useRegisteringInvoice } from 'features/invoice/api/registeringInvoice'

// Contexts
import { useAuth } from 'contexts/AuthProvider'

// Components
import ActionDropdownItem from 'components/ActionDropdownItem'
import DisabledElementTooltip from 'components/UI/DisabledElementTooltip'

const RegisteringInvoiceAction = ({ invoice, shipmentId }) => {
  const { currentUser } = useAuth()
  const registeringInvoiceMutation = useRegisteringInvoice({
    invoiceId: invoice?.id_auto,
    shipmentId,
  })

  const accessabilityRegistering = useMemo(() => {
    if (!['master', 'admin', 'accounting'].includes(currentUser?.user_level_name?.name)) {
      return {
        access: false,
        message: 'You do not have access to reset the invoice.',
      }
    } else if (invoice?.arca_status === 9)
      return {
        access: false,
        message: 'Invoice registration should not be in pending status.',
      }
    else
      return {
        access: true,
      }
  }, [currentUser?.user_level_name?.name, invoice?.arca_status])

  return invoice?.id_auto ? (
    <DisabledElementTooltip
      isDisabled={!accessabilityRegistering?.access}
      tooltipContent={accessabilityRegistering?.message}
    >
      <ActionDropdownItem
        label="Register"
        isDisabled={!accessabilityRegistering?.access}
        mutation={registeringInvoiceMutation}
      />
    </DisabledElementTooltip>
  ) : null
}

export default RegisteringInvoiceAction
