import { DocumentsDemo } from "features/documents"

const DocumentsListPage = () => {
  return (
    <div>
      <DocumentsDemo />
    </div>
  )
}

export default DocumentsListPage
