import { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

// Utils
import { findOption } from 'utils/helpers'
import { formatMonetaryNumber, formatNumber } from 'utils/numbers'

// Atlassian
import Lozenge from '@atlaskit/lozenge'
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down'
import EmptyState from '@atlaskit/empty-state'
import { DynamicTableStateless } from '@atlaskit/dynamic-table'

// Interfaces
import { ShortcutIcon } from '@royan-co/user-interface'

// Components
import Card from 'components/UI/Card'
import { calculationMethodShortLabelOptions } from 'components/Rates/constants'

const financeHead = {
  cells: [
    { content: 'Service' },
    { content: 'Supplier' },
    { content: 'Rate' },
    { content: 'Method of calculation' },
    { content: 'Quantity' },
    { content: 'Cost' },
    { content: 'Quotation' },
  ],
}

const ConsolidationFinanceGroupageTable = ({ rows = [], totalCost, totalQuotation }) => {
  const [showRowSurcharges, setShowRowSurcharges] = useState()

  const financeRows = useMemo(() => {
    let body =
      rows.map((item, i) => {
        let shipmentsItems = [
          {
            id: `service${i}`,
            content:
              item.type === 'main' ? (
                item?.service
              ) : (
                <div className="pl-6">
                  <div className="text-neutral-500">{item?.service}</div>
                </div>
              ),
          },
          {
            id: `supplier${i}`,
            content: item.supplier?.id_auto ? (
              <Link to={`/panel/registries/${item.supplier.id_auto}`} target="_blank">
                <small>{item.supplier.company_name}</small> <ShortcutIcon size={14} />
              </Link>
            ) : (
              <Lozenge>Not set</Lozenge>
            ),
          },
          {
            id: `rate${i}`,
            content: item.type === 'main' ? formatMonetaryNumber(item?.rate) : '',
          },
          {
            id: `calculation${i}`,
            content: item?.calculation_method ? (
              <Lozenge appearance="inprogress">
                {findOption(calculationMethodShortLabelOptions, item?.calculation_method)?.label}
              </Lozenge>
            ) : (
              <Lozenge>Not set</Lozenge>
            ),
          },
          {
            id: `quantity${i}`,
            content: formatNumber(item?.quantity) || (
              <Lozenge appearance="default">Not set</Lozenge>
            ),
          },
          {
            id: `cost${i}`,
            content: (
              <div className="text-right" style={{ width: '90px' }}>
                {item?.surcharges?.length ? (
                  <>
                    <button
                      onClick={() => setShowRowSurcharges((index) => (index === i ? '' : i))}
                      className="flex justify-between items-start w-full"
                    >
                      {item?.surcharges?.length ? <ChevronDownIcon /> : null}
                      <span>
                        {formatMonetaryNumber(item?.real_cost) || formatMonetaryNumber(item?.cost)}
                        <br />
                        <small className="opacity-80">
                          {item?.real_cost ? formatMonetaryNumber(item?.cost) : ''}
                        </small>
                      </span>
                    </button>

                    {showRowSurcharges === i
                      ? item?.surcharges?.map((surcharge, index) => (
                          <div key={index}>
                            <small className="opacity-80">
                              {`${
                                surcharge?.data?.iata_code ? surcharge?.data?.iata_code + ': ' : ''
                              }${formatMonetaryNumber(surcharge?.cost)}`}
                            </small>
                          </div>
                        ))
                      : null}
                  </>
                ) : (
                  <span>
                    {formatMonetaryNumber(item?.real_cost) || formatMonetaryNumber(item?.cost)}
                    <br />
                    <small className="opacity-80">
                      {item?.real_cost ? formatMonetaryNumber(item?.cost) : ''}
                    </small>
                  </span>
                )}

                {item?.passive_invoice && (
                  <button
                    className="px-1.5 mt-3 text-center rounded w-full"
                    style={{ backgroundColor: 'rgba(255, 255, 255, 0.30)' }}
                    onClick={() =>
                      window.open(
                        `/panel/passive-invoices/${item.passive_invoice.id_auto}`,
                        '_blank'
                      )
                    }
                  >
                    <small>{item.passive_invoice.invoice_number}</small>
                  </button>
                )}
              </div>
            ),
          },
          {
            id: `quotation${i}`,
            content: (
              <div className="text-right" style={{ width: '90px' }}>
                {formatMonetaryNumber(item?.revenue) || formatMonetaryNumber(item?.quotation)}
                <br />

                {item?.credit && (
                  <div className="text-xs mt-1">{formatMonetaryNumber(item?.credit)}</div>
                )}
                <small className="opacity-80">
                  {item?.revenue ? formatMonetaryNumber(item?.quotation) : ''}
                </small>
                <br />
                {item?.active_invoice && (
                  <button
                    className="px-1.5 mt-3 text-center rounded w-full"
                    style={{ backgroundColor: 'rgba(255, 255, 255, 0.30)' }}
                    onClick={() =>
                      window.open(`/panel/active-invoices/${item.active_invoice.id_auto}`, '_blank')
                    }
                  >
                    <small>{item.active_invoice.invoice_number}</small>
                  </button>
                )}
              </div>
            ),
          },
        ]

        return {
          key: `packagesRows${i}`,
          cells: shipmentsItems,
          isHighlighted: item?.type === 'main',
        }
      }) || []

    body.push({
      key: 'total',
      cells: [
        ...new Array(financeHead.cells?.length - 2 || 0).fill({
          content: '',
        }),
        {
          content: (
            <div className="text-right" style={{ width: '90px' }}>
              <small>Total</small> <br />
              {formatMonetaryNumber(totalCost)}
            </div>
          ),
        },
        {
          content: (
            <div className="text-right" style={{ width: '90px' }}>
              <small>Total</small> <br />
              {formatMonetaryNumber(totalQuotation)}
            </div>
          ),
        },
      ],
    })

    return body
  }, [rows, showRowSurcharges, totalCost, totalQuotation])

  return (
    <>
      {rows.length === 0 ? (
        <Card className="mt-0">
          <EmptyState header="No cargopage details" />
        </Card>
      ) : (
        <div className="overflow-auto inline-block w-full shipmentFinanceAtlaskitTable">
          <DynamicTableStateless head={financeHead} rows={financeRows} />
        </div>
      )}
    </>
  )
}

export default ConsolidationFinanceGroupageTable
