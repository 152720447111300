import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

//Services
import { useDisclosure, useIsMobile } from '@royan-co/user-interface'

//Atlassian
import { Content, Main, PageLayout, LeftSidebar, TopNavigation } from '@atlaskit/page-layout'

//Components
import LeftMobileSidebar from './LeftMobileSidebar'

const AppShell = ({ renderTopNavigation, leftSidebar, children }) => {
  const isMobile = useIsMobile()
  const { pathname } = useLocation()
  const [isOpenMobileSidebar, mobileSidebarHandlers] = useDisclosure()

  useEffect(() => {
    mobileSidebarHandlers.close()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return (
    <PageLayout>
      {renderTopNavigation && (
        <div className="z-50">
          <TopNavigation isFixed skipLinkTitle="Top Navigation">
            {renderTopNavigation({
              isOpenMobileSidebar,
              onClickMobileSidebarTrigger: mobileSidebarHandlers.toggle,
            })}
          </TopNavigation>
        </div>
      )}

      <Content>
        <div className="z-40">
          {leftSidebar &&
            (isMobile ? (
              <LeftMobileSidebar isOpen={isOpenMobileSidebar}>{leftSidebar}</LeftMobileSidebar>
            ) : (
              <LeftSidebar isFixed shouldPersistWidth skipLinkTitle="Navigation">
                {leftSidebar}
              </LeftSidebar>
            ))}
        </div>

        <Main id="main" skipLinkTitle="Main Content">
          <div className="z-20">{children}</div>
        </Main>
      </Content>
    </PageLayout>
  )
}

AppShell.propTypes = {
  leftSidebar: PropTypes.node,
  renderTopNavigation: PropTypes.func,
}

export default AppShell
