import { useCallback, useEffect, useMemo } from 'react'
import { useParams } from 'react-router'

//Utils
import { transformSelectField } from 'utils/helpers'
import { formatNumber } from 'utils/numbers'

//Hooks
import useShipmentCargo from 'hooks/useShipmentCargo'
import useShipmentsEnumerations from 'hooks/useShipmentsEnumerations'

// Atlassian
import Select from '@atlaskit/select'
import Button from '@atlaskit/button'
import Tooltip from '@atlaskit/tooltip'
import Spinner from '@atlaskit/spinner'
import Textfield from '@atlaskit/textfield'
import { DynamicTableStateless } from '@atlaskit/dynamic-table'

// Interfaces
import {
  Field,
  InputGroup,
  useFieldArray,
  useFormContext,
  useDisclosure,
} from '@royan-co/user-interface'

// Icons
import { TrashIcon, CautionIcon } from '@royan-co/user-interface'

//Components
import Card, { CardTitle } from 'components/UI/Card'
import MissingRowButton from './MissingRowButton'
import ReportDamageModal from './ReportDamageModal'

const packagesHeads = {
  cells: [
    {
      key: 'type',
      content: <span style={{ minWidth: '100px', display: 'inline-block' }}>Type</span>,
      width: 22,
    },
    {
      key: 'quantity',
      content: <span style={{ minWidth: '75px', display: 'inline-block' }}>Quantity</span>,
    },
    {
      key: 'dimensions',
      content: <span style={{ minWidth: '130px', display: 'inline-block' }}>Dimensions (cm)</span>,
    },
    {
      key: 'weight',
      content: <span style={{ minWidth: '75px', display: 'inline-block' }}>Weight (Kgs)</span>,
    },
    { key: 'removeIcon', content: '', width: 3 },
    { key: 'missingIcon', content: '', width: 3 },
    { key: 'damageIcon', content: '', width: 3 },
  ],
}

const ShipmentsViewEntranceStatus = () => {
  const { shipmentId } = useParams()
  const [isOpenReportDamageModal, reportDamageModalHandlers] = useDisclosure()
  const { shipmentCargo, isLoadingShipmentCargo } = useShipmentCargo(shipmentId)
  const { getEnumerationOptionsByKey, isLoadingShipmentEnumeration } = useShipmentsEnumerations()

  const { setValue, getValues, watch } = useFormContext()
  const cargo = watch('cargo')

  const {
    update: updatePackage,
    fields: packagesFields,
    append: appendPackage,
    remove: removePackage,
  } = useFieldArray({ name: 'cargo' })

  const addPackage = () => {
    appendPackage({
      box_type: null,
      width: '',
      length: '',
      height: '',
      quantity: '',
      weight: '',
      damage: 0,
      damage_files: [],
      missing_row: 0,
      deformed_pieces: '',
      deleteFunctionality: true,
    })
  }

  const handleMissingRow = useCallback(
    (missed, index) => {
      const data = getValues(`cargo.${index}`)

      updatePackage(index, {
        ...data,
        _weight: data.weight,
        _quantity: data.quantity,
        missing_row: missed ? 1 : 0,
        weight: missed ? 0 : data._weight || '',
        quantity: missed ? 0 : data._quantity || '',
      })
    },
    [getValues, updatePackage]
  )

  const handleReportDamage = (data, index) => {
    updatePackage(index, {
      ...getValues(`cargo.${index}`),
      damage: 1,
      damage_files: data.files,
      deformed_pieces: data.deformed_pieces,
    })

    reportDamageModalHandlers.close()
  }

  useEffect(() => {
    const cargo = shipmentCargo?.map((p) => ({
      id: p.id,
      box_type: p.box_type,
      quantity: p.number,
      width: p.width,
      length: p.length,
      height: p.height,
      weight: p.weight,
      type_of_weight: p.type_of_weight,
      missing_row: p.missing_row || 0,
      damage: p?.damage || 0,
      deformed_pieces: p?.deformed_pieces,
      damage_files: p?.damage_files || [],
    }))

    setValue('cargo', cargo)
  }, [setValue, shipmentCargo])

  const packagesRows = useMemo(() => {
    let packagesRowsItems = packagesFields.map((p, i) => ({
      key: p.id,
      cells: [
        {
          content: (
            <Field
              isRequired
              type="select"
              className="!my-1 !min-w-15"
              name={`cargo.${i}.box_type`}
              transform={transformSelectField(getEnumerationOptionsByKey('packaging_types'))}
            >
              {({ fieldProps }) => (
                <Select
                  placeholder="Choose"
                  menuPlacement="auto"
                  menuPortalTarget={document.body}
                  isLoading={isLoadingShipmentEnumeration}
                  options={getEnumerationOptionsByKey('packaging_types')}
                  {...fieldProps}
                />
              )}
            </Field>
          ),
        },
        {
          content: (
            <Field
              name={`cargo.${i}.quantity`}
              isRequired
              defaultValue=""
              className="!mt-0"
              isDisabled={p.missing_row}
            >
              {({ fieldProps }) => <Textfield {...fieldProps} />}
            </Field>
          ),
        },
        {
          content: (
            <InputGroup>
              <Field name={`cargo.${i}.width`} isRequired defaultValue="" className="!mt-0">
                {({ fieldProps }) => <Textfield placeholder="W" {...fieldProps} />}
              </Field>

              <Field name={`cargo.${i}.length`} isRequired defaultValue="" className="!mt-0">
                {({ fieldProps }) => <Textfield placeholder="L" {...fieldProps} />}
              </Field>

              <Field name={`cargo.${i}.height`} isRequired defaultValue="" className="!mt-0">
                {({ fieldProps }) => <Textfield placeholder="H" {...fieldProps} />}
              </Field>
            </InputGroup>
          ),
        },
        {
          content: (
            <Field
              name={`cargo.${i}.weight`}
              isRequired
              defaultValue=""
              isDisabled={p.missing_row}
              className="!mt-0"
            >
              {({ fieldProps }) => <Textfield {...fieldProps} />}
            </Field>
          ),
        },
        p.deleteFunctionality
          ? {
              content: (
                <Button spacing="none" appearance="subtle-link" onClick={() => removePackage(i)}>
                  <TrashIcon className="text-red-400" />
                </Button>
              ),
            }
          : { content: '' },
        {
          content: (
            <MissingRowButton
              value={p.missing_row}
              onClick={() => handleMissingRow(!p.missing_row, i)}
            />
          ),
        },
        {
          content: (
            <Button
              spacing="none"
              appearance="subtle-link"
              onClick={() => reportDamageModalHandlers.open({ cargo: p, index: i })}
            >
              <Tooltip content="Report damage">
                {(tooltipProps) => (
                  <span {...tooltipProps}>
                    <CautionIcon className={p?.damage ? 'text-red-500' : 'text-neutral-500'} />
                  </span>
                )}
              </Tooltip>
            </Button>
          ),
        },
      ],
    }))

    packagesRowsItems.push({
      key: `packagesRows-total`,
      cells: [
        {
          content: <strong>Total</strong>,
        },
        {
          content: (
            <span className="whitespace-nowrap">
              {cargo?.reduce((p, c) => p + c.quantity * 1, 0) || 0} collies
            </span>
          ),
        },
        {
          content: '',
        },
        {
          content: (
            <span className="whitespace-nowrap">
              {formatNumber(cargo?.reduce((p, c) => p + c.weight * 1, 0) || 0)} Kgs
            </span>
          ),
        },
        {
          content: '',
        },
        {
          content: '',
        },
        {
          content: '',
        },
      ],
    })

    return packagesRowsItems
  }, [
    packagesFields,
    cargo,
    getEnumerationOptionsByKey,
    isLoadingShipmentEnumeration,
    removePackage,
    handleMissingRow,
    reportDamageModalHandlers,
  ])

  return (
    <>
      {isLoadingShipmentCargo ? (
        <div className="flex justify-center items-center my-3">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="flex items-center mb-3 mt-6">
            <CardTitle className="mb-0">Cargo details</CardTitle>
            <Button appearance="link" className="ml-auto !pr-0" onClick={addPackage}>
              Report new package
            </Button>
          </div>

          <Card>
            {packagesRows?.length > 1 ? (
              <div className="overflow-auto w-full inline-block -mb-6 removeAtlaskitTableBorder borderOfAtlaskitTableWithTotalRow">
                <DynamicTableStateless head={packagesHeads} rows={packagesRows} />
              </div>
            ) : (
              <span className="text-neutral-200">There are no packages detail.</span>
            )}
          </Card>
        </>
      )}

      <ReportDamageModal
        isOpen={isOpenReportDamageModal}
        onClose={reportDamageModalHandlers.close}
        cargo={reportDamageModalHandlers.data?.cargo}
        onSubmit={(data) => handleReportDamage(data, reportDamageModalHandlers.data?.index)}
      />
    </>
  )
}

export default ShipmentsViewEntranceStatus
