import { useState } from 'react'

//Styles
import styles from './ComposingEmail.module.scss'

//Atlassian
import TextField from '@atlaskit/textfield'
import { ErrorMessage } from '@atlaskit/form'

//Interfaces
import { Field, Form, WarningBannerField } from '@royan-co/user-interface'

//Components
import Editor from './Editor'
import Attachments from './Attachments'
import ContactFieldTo from './ContactField/To'
import ContactFieldCc from './ContactField/Cc'

const ComposingEmail = ({
  id,
  defaultValues,
  disabledFields,
  to,
  cc,
  content,
  onCreateNewContact,
  documents,
  onSubmit,
}) => {
  const [emailContent, setEmailContent] = useState(defaultValues?.content)
  const [selectedFiles, setSelectedFiles] = useState(defaultValues?.attachments || [])
  const [contentError, setContentError] = useState()

  const onSubmitHandler = (data, { setError }) => {
    setContentError(false)

    if (!emailContent) {
      setContentError('This field is required.')
      return
    }

    onSubmit({ ...data, content: emailContent, attachments: selectedFiles }, setError)
  }

  return (
    <>
      <Form id={id} onSubmit={onSubmitHandler} defaultValues={defaultValues}>
        {({ getValues }) => (
          <>
            <ContactFieldTo
              options={to?.options}
              isLoading={to?.isLoading}
              onValueChange={to?.onValueChange}
              isDisabled={disabledFields?.includes('to')}
              className={styles.fieldWrapper}
              onCreateNewContact={onCreateNewContact}
            />

            <ContactFieldCc
              defaultValue={getValues('cc') || []}
              options={cc?.options}
              isLoading={cc?.isLoading}
              isDisabled={disabledFields?.includes('cc')}
              className={styles.fieldWrapper}
              onCreateNewContact={onCreateNewContact}
            />

            <Field
              isRequired
              name="subject"
              label="Subject"
              isDisabled={disabledFields?.includes('subject')}
              className={styles.fieldWrapper}
            >
              {({ fieldProps, error }) => (
                <>
                  <TextField {...fieldProps} />
                  {error && <ErrorMessage>{error}</ErrorMessage>}
                </>
              )}
            </Field>

            <Editor
              defaultValue={defaultValues.content || emailContent}
              disabled={disabledFields?.includes('content')}
              isLoading={content?.isLoading}
              setEmailContent={setEmailContent}
              className={styles.fieldWrapper}
            />
            {contentError && <ErrorMessage>{contentError}</ErrorMessage>}

            <Attachments
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              documents={documents}
            />

            <WarningBannerField />
          </>
        )}
      </Form>
    </>
  )
}

export default ComposingEmail
