import { useMemo } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'

// API
import { deleteCommercialInvoice } from 'api/invoices'

// Hooks
import useShipmentCommercialInvoices from 'hooks/useShipmentCommercialInvoices'

// Atlaskit
import Button from '@atlaskit/button'
import { DynamicTableStateless } from '@atlaskit/dynamic-table'
import Lozenge from '@atlaskit/lozenge'
import Spinner from '@atlaskit/spinner'

// Utils
import { formatMonetaryNumber } from 'utils/numbers'

// Interfaces
import { useDisclosure, useFlags, EditIcon, TrashIcon } from '@royan-co/user-interface'

// Components
import DeleteCommercialInvoiceModal from './DeleteModal'

const tableHeads = {
  cells: [
    { content: 'Invoice number' },
    { content: 'Date' },
    { content: 'Amount' },
    { content: 'To destination' },
    { content: '' },
  ],
}

const CommercialInvoicesList = () => {
  const { shipmentId } = useParams()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { showSuccessFlag } = useFlags()

  const [openDeletingInvoiceModal, deletingInvoiceModalHandlers] = useDisclosure('delete')

  const { shipmentCommercialInvoices, isLoadingCommercialInvoices } =
    useShipmentCommercialInvoices(shipmentId)

  const tableRows = useMemo(() => {
    return (
      shipmentCommercialInvoices?.map((row, i) => {
        let items = [
          {
            content: row?.number,
          },
          {
            content: row?.date,
          },
          {
            content: formatMonetaryNumber(row?.amount),
          },
          {
            content: row?.document_see ? (
              <Lozenge appearance="inprogress">Yes</Lozenge>
            ) : (
              <Lozenge appearance="default">No</Lozenge>
            ),
          },
          {
            content: (
              <div className="flex justify-end">
                <Button
                  spacing="compact"
                  appearance="subtle"
                  iconBefore={<EditIcon />}
                  onClick={() =>
                    navigate(`/panel/shipments/${shipmentId}/commercial-invoices/${row?.id}/edit`)
                  }
                />

                <Button
                  spacing="compact"
                  appearance="subtle"
                  onClick={() => deletingInvoiceModalHandlers.open(row)}
                  iconBefore={<TrashIcon className="text-red-400" />}
                />
              </div>
            ),
          },
        ]

        return {
          key: i,
          cells: items,
        }
      }) || []
    )
  }, [shipmentCommercialInvoices, deletingInvoiceModalHandlers, navigate, shipmentId])

  const InvoiceDeletionMutation = useMutation(deleteCommercialInvoice.bind(null, shipmentId))

  const handleDeleteInvoice = () => {
    const invoiceId = deletingInvoiceModalHandlers?.data?.id

    console.log(invoiceId)

    InvoiceDeletionMutation.mutate(invoiceId, {
      onSuccess: (res) => {
        queryClient.setQueryData(['shipment', 'commercial-invoices', shipmentId], (invoices) =>
          invoices.filter((i) => i.id !== invoiceId)
        )
        showSuccessFlag(res?.message)
        deletingInvoiceModalHandlers.close()
      },
    })
  }

  const closeDeleteModal = () => {
    InvoiceDeletionMutation.reset()
    deletingInvoiceModalHandlers.close()
  }

  return (
    <>
      {isLoadingCommercialInvoices ? (
        <div className="w-full text-center my-2">
          <Spinner />
        </div>
      ) : (
        <div className="inline-block w-full -mb-6">
          <DynamicTableStateless head={tableHeads} rows={tableRows} />
        </div>
      )}

      <DeleteCommercialInvoiceModal
        isOpen={openDeletingInvoiceModal}
        onClose={closeDeleteModal}
        onSubmit={handleDeleteInvoice}
        isError={InvoiceDeletionMutation.isError}
        isLoading={InvoiceDeletionMutation.isLoading}
      />
    </>
  )
}

export default CommercialInvoicesList
