import { useMemo } from 'react'

// Atlassian
import Lozenge from '@atlaskit/lozenge'

// Components
import RowTable from 'components/UI/RowTable'
import Card from 'components/UI/Card'
import CountryFlag from 'components/UI/CountryFlag'

const ConsolidationAddLocalisationTable = ({ consolidation }) => {
  const generalRows = useMemo(() => {
    let generalRowsItem = [
      {
        title: 'Transportation unit',
        content: consolidation?.means?.map((mean) => mean?.code)?.join(', ') || (
          <Lozenge>Not set</Lozenge>
        ),
      },
      {
        title: 'Consolidation hub',
        content: consolidation?.hub?.name || <Lozenge>Not set</Lozenge>,
      },
      { title: 'Destination', content: <CountryFlag iso={consolidation?.arrival_country} /> },
      {
        title: 'Last location',
        content: consolidation?.tracking?.[0]?.address || <Lozenge>Unknown</Lozenge>,
      },
    ]

    return generalRowsItem
  }, [consolidation])

  return (
    <Card className="mt-0">
      <RowTable rows={generalRows} verticalAlign="align-top" width="w-1/2" />
    </Card>
  )
}

export default ConsolidationAddLocalisationTable
