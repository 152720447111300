import Badge from '@atlaskit/badge'

const ConsolidationViewNoteNotification = ({ consolidation }) => {
  return (
    <>
      {consolidation?.notes_count * 1 > 0 ? (
        <Badge appearance="primary" children={consolidation?.notes_count} />
      ) : null}
    </>
  )
}

export default ConsolidationViewNoteNotification
