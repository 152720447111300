import { Link, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

// Apis
import { getAirWaybillsStock } from '../api/stock-requests'

// Atlassian
import Button from '@atlaskit/button'
import PageHeader from '@atlaskit/page-header'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'

// Interfaces
import { RoyanTable, useDisclosure } from '@royan-co/user-interface'

// Components
import { generateAirWaybillStocksColumns } from '../components/List/columns'
import { airWaybillStocksFilters } from '../components/List/filters'
import AirWaybillsStocksFilterFields from '../components/List/FilterFields'
import AirWaybillStockDeleteModal from '../components/DeleteModal'

const pageTitle = 'AWB stock'

const AirWaybillStockListPage = () => {
  const navigate = useNavigate()
  const [isOpenDeleteModal, handleDeleteModal] = useDisclosure('delete')

  const openDeleteModal = (stockId) => {
    handleDeleteModal.open(stockId)
  }

  const handleDoubleClick = (e, item) => {
    navigate(`/panel/airwaybill-stock/${item.id}`)
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <PageHeader
        breadcrumbs={
          <Breadcrumbs>
            <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
            <BreadcrumbsItem text="Operation " />
          </Breadcrumbs>
        }
        actions={
          <Button appearance="primary" onClick={() => navigate('/panel/airwaybill-stock/create')}>
            Create AWB stock
          </Button>
        }
      >
        {pageTitle}
      </PageHeader>

      <RoyanTable
        sortOrder="DESC"
        name="airwaybill-stock-list"
        sortKey="creation_date"
        onRowDoubleClick={handleDoubleClick}
        request={getAirWaybillsStock}
        columns={generateAirWaybillStocksColumns(openDeleteModal)}
        filters={airWaybillStocksFilters}
        filterFields={<AirWaybillsStocksFilterFields />}
      >
        <AirWaybillStockDeleteModal
          isOpen={isOpenDeleteModal}
          onClose={handleDeleteModal.close}
          stockId={handleDeleteModal.data}
        />
      </RoyanTable>
    </>
  )
}

export default AirWaybillStockListPage
