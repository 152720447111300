import axios from 'services/Request'

export function getVehicleTypes(params) {
  return axios.get('/api/means/units', { params })
}

export function getVehicleType(typeId) {
  return axios.get(`/api/means/units/${typeId}`)
}

export function createVehicleType(data) {
  return axios.post('/api/means/units', data)
}

export function editVehicleType(typeId, data) {
  return axios.put(`/api/means/units/${typeId}`, data)
}

export function deleteVehicleType(typeId) {
  return axios.delete(`/api/means/units/${typeId}`)
}
