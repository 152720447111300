// Hooks
import useShipmentsEnumerations from 'hooks/useShipmentsEnumerations'
import useRegistryAirlines from 'hooks/useRegistryAirlines'

// Utils
import { findOption } from 'utils/helpers'
import {
  calculationMethodOptions,
  formatOptions,
  allowCalculationOptions,
  typeOptions,
} from '../constants'
import { containerTypes, modeOptions, truckTypes } from 'components/Shipments/constants'

// Atlassian
import Spinner from '@atlaskit/spinner'

const EnumerationsFilter = ({ label, value, optionsKey }) => {
  const { getSelectOptionsByKey, isLoadingShipmentEnumeration } = useShipmentsEnumerations()

  return (
    <>
      {`${label}: `}
      {isLoadingShipmentEnumeration ? (
        <Spinner size="xsmall" />
      ) : (
        findOption(getSelectOptionsByKey(optionsKey), value)?.label
      )}
    </>
  )
}

const AirLineFilter = ({ value }) => {
  const { registryAirlineOptions, isLoadingRegistryAirlines } = useRegistryAirlines()

  return (
    <>
      airline:{' '}
      {isLoadingRegistryAirlines ? (
        <Spinner size="xsmall" />
      ) : (
        findOption(registryAirlineOptions, value)?.label
      )}
    </>
  )
}

const ServiceCodeFilter = ({ value }) => {
  const { getServiceCodeOptions, isLoadingShipmentEnumeration } = useShipmentsEnumerations()

  return (
    <>
      cost code:{' '}
      {isLoadingShipmentEnumeration ? (
        <Spinner size="xsmall" />
      ) : (
        findOption(getServiceCodeOptions, value)?.label
      )}
    </>
  )
}

export const ratesFilters = [
  {
    key: 'from_to',
    content: (v) => `Type: ${findOption(typeOptions, v)?.label}`,
  },
  {
    key: 'shipment_type',
    content: (v) => `Mode: ${findOption(modeOptions, v)?.label}`,
  },
  {
    key: 'type_of_collies',
    content: (v, { shipment_type }) =>
      `Method of loading: ${
        findOption(shipment_type === 1 ? containerTypes : truckTypes, v)?.label
      }`,
  },
  {
    key: 'supplier',
    content: (v) => `Supplier: ${v}`,
  },
  {
    key: 'airline',
    content: (v) => <AirLineFilter value={v} />,
  },
  {
    key: 'departure_country',
    content: (v) => `Place of loading: ${v}`,
  },
  {
    key: 'departure_hub',
    content: (v) => <EnumerationsFilter label="Place of loading" optionsKey="hubs" value={v} />,
  },

  {
    key: 'departure_airport',
    content: (v) => `Place of delivery: ${v}`,
  },
  {
    key: 'departure_port',
    content: (v) => <EnumerationsFilter label="Place of loading" optionsKey="ports" value={v} />,
  },
  {
    key: 'departure_station',
    content: (v) => <EnumerationsFilter label="Place of loading" optionsKey="stations" value={v} />,
  },
  {
    key: 'arrival_country',
    content: (v) => `Place of delivery: ${v}`,
  },
  {
    key: 'arrival_hub',
    content: (v) => <EnumerationsFilter label="Place of delivery" optionsKey="hubs" value={v} />,
  },
  {
    key: 'arrival_airport',
    content: (v) => `Place of delivery: ${v}`,
  },
  {
    key: 'arrival_port',
    content: (v) => <EnumerationsFilter label="Place of delivery" optionsKey="ports" value={v} />,
  },
  {
    key: 'arrival_station',
    content: (v) => (
      <EnumerationsFilter label="Place of delivery" optionsKey="stations" value={v} />
    ),
  },
  {
    key: 'cost_code',
    content: (v) => <ServiceCodeFilter value={v} />,
  },
  {
    key: 'allow_calculate',
    content: (v) => `Allow calculation: ${findOption(allowCalculationOptions, v)?.label}`,
  },
  {
    key: 'calculation_method',
    content: (v) => `Calculation method: ${findOption(calculationMethodOptions, v)?.label}`,
  },
  {
    key: 'type',
    content: (v) => `Format: ${findOption(formatOptions, v)?.label}`,
  },
  {
    key: 'created_from',
    content: (v) => `From Date: ${v}`,
  },
  {
    key: 'created_to',
    content: (v) => `To Date: ${v}`,
  },
]
