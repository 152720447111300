import { useParams } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'

// APIS
import { uploadFileAttachment } from 'api/upload'
import { uploadRegistryDocument } from 'api/registries'

// Atlassian
import Button from '@atlaskit/button'

// Interfaces
import { UploadModal, useDisclosure, useFlags } from '@royan-co/user-interface'

const RegistryUploadDocument = () => {
  const { registryId } = useParams()
  const { showSuccessFlag } = useFlags()
  const queryClient = useQueryClient()
  const [isOpenUploadModal, uploadModalHandlers] = useDisclosure()

  const uploadMutation = useMutation((data) => uploadRegistryDocument(data, registryId))

  const upload = (files) => {
    if (files.length < 1) return

    const formData = {
      files: files.map((file) => ({ title: file.title, document_path: file.uploadResponse?.path })),
    }

    uploadMutation.mutate(formData, {
      onSuccess: (res) => {
        queryClient.setQueryData(['registry-documents', registryId], (docs) => [
          ...res.documents.reverse(),
          ...docs,
        ])
        showSuccessFlag(res.message)
        uploadModalHandlers.close()
      },
    })
  }

  return (
    <>
      <Button appearance="primary" onClick={() => uploadModalHandlers.open()}>
        Upload document
      </Button>

      <UploadModal
        maxFiles={5}
        onSubmit={upload}
        editableFileTitle
        useFileNameAsTitle
        isFileTitleRequired
        title="Upload document"
        isOpen={isOpenUploadModal}
        isSubmitting={uploadMutation.isLoading}
        accept={['csv', 'xls', 'pdf', 'jpg', 'jpeg', 'png']}
        uploadRequest={(file, signal) => uploadFileAttachment(file, signal)}
        onClose={() => {
          uploadMutation.reset()
          uploadModalHandlers.close()
        }}
        error={
          uploadMutation.isError && (uploadMutation.error?.response?.data?.errors?.document || true)
        }
      />
    </>
  )
}

export default RegistryUploadDocument
