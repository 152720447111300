import { createElement } from 'react'
import { useNavigate } from 'react-router-dom'

// Contexts
import { useConsolidationCreationContext } from 'contexts/ConsolidationCreationProvider'

// Utils
import { transformSelectField } from 'utils/helpers'

// Hooks
import useShipmentsHubs from 'hooks/useShipmentsHubs'

// Atlassian
import Button from '@atlaskit/button'
import { ErrorMessage } from '@atlaskit/form'
import Select, { CountrySelect } from '@atlaskit/select'
import Toggle from '@atlaskit/toggle'
import { allCountries as countries } from '/node_modules/@atlaskit/select/dist/es2019/data/countries.js'

// Interfaces
import {
  Field,
  ModeCheckbox,
  RangeSlider,
  WizardForm,
  useWizardFormContext,
} from '@royan-co/user-interface'

// Components
import Card from 'components/UI/Card'
import { modes } from 'components/Consolidations/Create/modes'
import { temperatureControlOptions } from 'components/Shipments/Create/constants'
import { vehicleTypeOptions } from 'components/Consolidations/constants'

const ConsolidationCreationGeneralPage = () => {
  const navigate = useNavigate()
  const { nextStep } = useConsolidationCreationContext()
  const { shipmentsHubsOptions, isLoadingShipmentsHubs } = useShipmentsHubs()
  const {
    stepsValue: { general },
  } = useWizardFormContext()

  const handleRoutingStep = () => {
    navigate(nextStep.path)
  }
  
  return (
    <>
      <WizardForm name="general" onSubmit={handleRoutingStep}>
        {({ setValue }) => (
          <>
            <div>
              <Field isRequired type="custom" name="modeType">
                {({ fieldProps }) => (
                  <div className="grid grid-cols-2 md:grid-cols-5 gap-4">
                    {modes.map((mode, index) => (
                      <div key={index} className="grid">
                        <ModeCheckbox
                          value={mode.id}
                          title={mode.title}
                          onChange={fieldProps.onChange}
                          description={mode.description}
                          isChecked={fieldProps.value === mode.id}
                          icon={createElement(mode.icon, { size: 48 })}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </Field>

              <Card className="mt-6">
                {general?.modeType === 2 && (
                  <Field
                    isRequired
                    type="select"
                    label="Vehicle type"
                    name="vehicle_type"
                    className="!mt-0 !mb-4"
                    transform={transformSelectField(vehicleTypeOptions)}
                  >
                    {({ fieldProps, error }) => (
                      <>
                        <Select
                          isClearable={true}
                          placeholder="Choose"
                          options={vehicleTypeOptions}
                          {...fieldProps}
                        />
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                      </>
                    )}
                  </Field>
                )}

                <Field
                  isRequired={!(general?.modeType === 2)}
                  type="select"
                  label="Consolidator hub"
                  name="hub_consolidator"
                  className="!mt-0"
                  transform={transformSelectField(shipmentsHubsOptions)}
                >
                  {({ fieldProps, error }) => (
                    <>
                      <Select
                        isClearable={true}
                        placeholder="Choose"
                        options={shipmentsHubsOptions}
                        isLoading={isLoadingShipmentsHubs}
                        {...fieldProps}
                      />
                      {error && <ErrorMessage>{error}</ErrorMessage>}
                    </>
                  )}
                </Field>

                <Field
                  isRequired
                  type="select"
                  label="Country of delivery"
                  name="arrival_country"
                  className="!mt-4"
                  transform={{
                    output: (c) => (c?.abbr !== undefined ? c.abbr : null),
                    input: (v) => countries?.find((c) => c.abbr === v),
                  }}
                >
                  {({ fieldProps: { ref, error, ...fieldProps } }) => (
                    <>
                      <CountrySelect
                        isClearable={true}
                        className="w-100"
                        placeholder="Choose a country"
                        {...fieldProps}
                      />
                      {error && <ErrorMessage>{error}</ErrorMessage>}
                    </>
                  )}
                </Field>

                <Field type="checkbox" name="reefer" label="Temperature control" className="!mt-4">
                  {({ fieldProps }) => (
                    <div>
                      <Toggle {...fieldProps} />
                    </div>
                  )}
                </Field>

                {!!general?.reefer && (
                  <div className="grid md:grid-cols-2 md:gap-4">
                    <Field type="select" name="range" label="Temperature range">
                      {({ fieldProps: { onChange, ...fieldProps }, errorType }) => (
                        <div>
                          <Select
                            placeholder="Choose a range"
                            onChange={(v) => {
                              onChange(v)
                              setValue('reefer_temperature', [
                                v?.value?.range?.min || general?.reefer_temperature?.[0],
                                v?.value?.range?.max || general?.reefer_temperature?.[1],
                              ])
                            }}
                            options={temperatureControlOptions}
                            validate={(d) => !!d?.value?.range?.min}
                            {...fieldProps}
                          />
                          {errorType && <ErrorMessage>Please select range</ErrorMessage>}
                        </div>
                      )}
                    </Field>
                  </div>
                )}

                {!!general?.reefer && general?.range?.value?.type === 'custom' && (
                  <div className="inline-block w-full mt-1">
                    <div className="mt-3 mb-4">
                      <Field name="reefer_temperature" max={30} min={-25}>
                        {({ fieldProps: { ref, ...fieldProps } }) => (
                          <RangeSlider {...fieldProps} />
                        )}
                      </Field>
                    </div>
                  </div>
                )}
              </Card>
            </div>

            <div className="flex justify-end mt-6">
              <Button type="submit" appearance="primary">
                Next step
              </Button>
            </div>
          </>
        )}
      </WizardForm>
    </>
  )
}

export default ConsolidationCreationGeneralPage
