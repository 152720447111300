import { useMemo } from 'react'
import unionBy from 'lodash/unionBy'
import { useQuery } from '@tanstack/react-query'
import { getRegistryCreators } from 'api/registries'

const useRegistryCreators = () => {
  const { isLoading, data } = useQuery(['registries', 'creators'], getRegistryCreators, {
    staleTime: Infinity,
    retry: 2,
  })

  const registryCreatorOptions = useMemo(() => {
    if (!data?.creators) return []

    return data.creators.map((u) => ({ label: u.full_name, value: u.id }))
  }, [data])

  const registryGroupOptions = useMemo(() => {
    if (!data?.creators) return []

    return unionBy(
      data.creators
        .map((u) => ({ label: u.registry?.company_name, value: u.registry?.id_auto }))
        .filter((o) => !!o.value),
      'value'
    )
  }, [data])

  return { registryCreatorOptions, registryGroupOptions, isLoadingRegistryCreators: isLoading }
}

export default useRegistryCreators
