import { useState } from 'react'

//Styles
import styles from './Attachments.module.scss'

//Atlassian
import Button from '@atlaskit/button'
import Modal, {
  ModalTitle,
  ModalHeader,
  ModalFooter,
  ModalTransition,
} from '@atlaskit/modal-dialog'

//Interfaces
import { DocsIcon, UploadIcon, UploadModal } from '@royan-co/user-interface'

//Components
import DocumentsListModal from './DocumentsListModal'

function AttachmentsModal({ isOpen, onClose, onSubmit, documents, ...props }) {
  const [type, setType] = useState()

  const chooseType = (type) => {
    setType(type)
    onClose()
  }

  const closeTypeModal = () => {
    setTimeout(() => {
      setType()
    }, 100)
  }

  const onSubmitUpload = (files) => {
    onSubmit(files)

    closeTypeModal()
  }

  return (
    <>
      <ModalTransition>
        {isOpen && (
          <Modal onClose={onClose} width="medium">
            <ModalHeader>
              <ModalTitle>Upload document</ModalTitle>
            </ModalHeader>

            <div className={styles.uploadModal}>
              <button
                type="button"
                onClick={() => chooseType('upload')}
                className={styles.uploadButton}
              >
                <div className={styles.uploadButton__icon}>
                  <UploadIcon size={34} />
                </div>
                <div className={styles.uploadButton__desc}>New document</div>
              </button>

              <button
                type="button"
                onClick={() => chooseType('select')}
                className={styles.uploadButton}
              >
                <div className={styles.uploadButton__icon}>
                  <DocsIcon size={34} />
                </div>
                <div className={styles.uploadButton__desc}>Existing document</div>
              </button>
            </div>

            <ModalFooter>
              <Button appearance="subtle" spacing="compact" type="button" onClick={onClose}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>

      <UploadModal
        {...props}
        isOpen={!!(type === 'upload')}
        onSubmit={onSubmitUpload}
        onClose={closeTypeModal}
      />

      <DocumentsListModal
        {...props}
        {...documents}
        isOpen={!!(type === 'select')}
        onSubmit={onSubmitUpload}
        onClose={closeTypeModal}
      />
    </>
  )
}

export default AttachmentsModal
