import { useEffect, useMemo, useState } from 'react'

// Utils
import { transformSelectField } from 'utils/helpers'

// Hooks
import useShipmentsHubs from 'hooks/useShipmentsHubs'

//Atlassian
import Textfield from '@atlaskit/textfield'
import TextArea from '@atlaskit/textarea'
import Select from '@atlaskit/select'
import { DatePicker } from '@atlaskit/datetime-picker'
import { ErrorMessage } from '@atlaskit/form'
import Button from '@atlaskit/button'

//Interfaces
import { Field, PlacesAutoComplete, useFormContext, useGeoInfo } from '@royan-co/user-interface'

//Components
import Card from 'components/UI/Card'
import {
  localisationStatusLoadedOnBoatOptions,
  localisationStatusNotLoadedOnBoatOptions,
} from '../constants'
import PickupLocation from 'components/PickupLoacation'

// Feature
import { useSeaFreightRoutes } from 'features/freightRoutes'

const ConsolidationAddLoacalisationGeneralForm = ({ loadedOnBoat, hasReefer }) => {
  const { geoInfo } = useGeoInfo()
  const center = { lat: +geoInfo?.lat || 41.8719, lng: +geoInfo?.lng || 12.5674 }

  const [showMap, setShowMap] = useState(false)
  const [location, setLocation] = useState(center)

  const { watch, setValue } = useFormContext()
  const status = watch('title')
  const address = watch('address')

  const enableRouteField = useMemo(
    () =>
      !!(['Unloading from ferry boat', 'Loading on ferry boat']?.includes(status) ? true : false),
    [status]
  )
  const enableHubField = useMemo(
    () => (status === 'Stop - Arrived to customs warehouse' ? true : false),
    [status]
  )

  const enableAddressField = useMemo(
    () =>
      !!(['Loading on ferry boat', 'Unloading from ferry boat', 'Trip Finished']?.includes(status)
        ? false
        : true),
    [status]
  )

  const { seaFreightRoutesOptions, isLoadingSeaFreightRoutes } = useSeaFreightRoutes({
    enabled: enableRouteField,
  })
  const { shipmentsHubsOptions, isLoadingShipmentsHubs } = useShipmentsHubs({
    enabled: enableHubField,
  })

  const statusOptions = useMemo(
    () =>
      loadedOnBoat
        ? localisationStatusLoadedOnBoatOptions
        : localisationStatusNotLoadedOnBoatOptions,
    [loadedOnBoat]
  )

  useEffect(() => {
    if (!enableRouteField) setValue('port_port_id', null)
    if (!enableHubField) setValue('custom_terminal', null)
    if (!enableAddressField) {
      ;['address', 'arrival_time', 'description'].forEach((item) => setValue(item, null))
    }
  }, [enableAddressField, enableHubField, enableRouteField, setValue])

  useEffect(() => {
    if (!!showMap && location?.label) setValue('address', location)
  }, [location, setValue, showMap])

  useEffect(() => {
    if (!showMap && address) setLocation(address)
  }, [address, location, setValue, showMap])

  return (
    <Card className="mt-6">
      <Field
        isRequired
        label="Status"
        name="title"
        type="select"
        className="!mt-0"
        transform={transformSelectField(statusOptions)}
      >
        {({ fieldProps, error }) => (
          <>
            <Select
              isClearable={true}
              options={statusOptions}
              placeholder="Choose a status"
              {...fieldProps}
            />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </>
        )}
      </Field>

      <Field isRequired label="Detection date" name="creation_date" className="!mt-4">
        {({ fieldProps, error }) => (
          <>
            <DatePicker placeholder="2/18/2022" {...fieldProps} />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </>
        )}
      </Field>

      {status && (
        <>
          {enableRouteField && (
            <Field
              isRequired
              label="Route"
              name="port_port_id"
              type="select"
              className="!mt-4"
              transform={transformSelectField(seaFreightRoutesOptions)}
            >
              {({ fieldProps, error }) => (
                <>
                  <Select
                    isClearable={true}
                    options={seaFreightRoutesOptions}
                    isLoading={isLoadingSeaFreightRoutes}
                    placeholder="Choose a route"
                    {...fieldProps}
                  />
                  {error && <ErrorMessage>{error}</ErrorMessage>}
                </>
              )}
            </Field>
          )}

          {enableHubField && (
            <Field
              isRequired
              label="Customs warehouse"
              name="custom_terminal"
              type="select"
              className="!mt-4"
              transform={transformSelectField(shipmentsHubsOptions)}
            >
              {({ fieldProps, error }) => (
                <>
                  <Select
                    isClearable={true}
                    options={shipmentsHubsOptions}
                    isLoading={isLoadingShipmentsHubs}
                    placeholder="Choose a customs warehouse"
                    {...fieldProps}
                  />
                  {error && <ErrorMessage>{error}</ErrorMessage>}
                </>
              )}
            </Field>
          )}

          {enableAddressField && (
            <>
              <Field
                isRequired
                name="address"
                label="Location"
                type="text"
                className="!mt-4"
                validate={(p) =>
                  p?.isEuropean && !p?.zipCode ? 'Please enter a more accurate address' : true
                }
              >
                {({ fieldProps, error }) => (
                  <div className="flex gap-4">
                    <div className="flex-grow">
                      <PlacesAutoComplete placeholder="Type the location" {...fieldProps} />
                      {error && <ErrorMessage>{error}</ErrorMessage>}
                    </div>

                    <div className="basis-30">
                      <Button
                        type="button"
                        shouldFitContainer
                        appearance="primary"
                        className="!text-left !h-full !items-center"
                        onClick={() => setShowMap((prevState) => !prevState)}
                      >
                        {showMap ? 'Close map' : 'Choose on map'}
                      </Button>
                    </div>
                  </div>
                )}
              </Field>
              {showMap && (
                <div className="mt-6">
                  <PickupLocation location={address || location} setLocation={setLocation} />
                </div>
              )}
            </>
          )}

          {hasReefer && (
            <Field
              label="Recorded temperature"
              name="reefer_temperature"
              defaultValue=""
              className="!mt-4"
            >
              {({ fieldProps, error }) => (
                <>
                  <Textfield placeholder="Type the recorded temperature" {...fieldProps} />
                  {error && <ErrorMessage>{error}</ErrorMessage>}
                </>
              )}
            </Field>
          )}

          {!!address && (
            <>
              <Field
                label="Estimated arrival date"
                name="arrival_time"
                defaultValue=""
                className="!mt-4"
              >
                {({ fieldProps, error }) => (
                  <>
                    <DatePicker placeholder="2/18/2022" {...fieldProps} />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>

              <Field name="description" label="Remarks" defaultValue="" className="!mt-4">
                {({ fieldProps, error }) => (
                  <>
                    <TextArea {...fieldProps} minimumRows={4} />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>
            </>
          )}
        </>
      )}
    </Card>
  )
}

export default ConsolidationAddLoacalisationGeneralForm
