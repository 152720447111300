import { useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, Outlet, useParams } from 'react-router-dom'
import { modes } from 'components/Shipments/Create/modes'

//Services
import useShipment from 'hooks/useShipment'
import { capitalizeFirstLetter, getFilled } from 'utils/helpers'

//Contexts
import { ShipmentCreationProvider } from 'contexts/ShipmentCreationProvider'

//Atlassian
import Button from '@atlaskit/button'
import Spinner from '@atlaskit/spinner'
import PageHeader from '@atlaskit/page-header'
import SectionMessage from '@atlaskit/section-message'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'

//Interfaces
import { Grid, WizardFormProvider } from '@royan-co/user-interface'

//Components
import ShipmentCreationProgressTracker from 'components/Shipments/Create/ProgressTracker'

const ShipmentCreationPage = ({ entity }) => {
  const { shipmentId, quotationId } = useParams()
  const entityId = shipmentId || quotationId
  const pageTitle = entityId ? `Edit ${entity}` : `New ${entity}`

  const { shipment, isLoading, isFetching, isError, refetch } = useShipment(entityId, {
    enabled: !!entityId,
  })

  const selectedMode = useMemo(
    () =>
      modes.find(
        (m) =>
          m.shipmentType === shipment?.shipment_type &&
          m.typeOfCollies === shipment?.type_of_collies
      ),
    [shipment]
  )

  const defaultStepsValue = useMemo(() => {
    const stepsValue = {
      routing: {
        modeType: selectedMode?.id || 1,
        placeOfLoading: shipment
          ? {
              lng: shipment.departure_lng,
              lat: shipment.departure_lat,
              city: shipment.departure_city,
              zipCode: shipment.departure_cap,
              countryAbbr: shipment.departure_country,
              label: [shipment.departure_city, shipment.departure_country, shipment.departure_cap]
                .filter((i) => i)
                .join(', '),
            }
          : '',
        placeOfDelivery: shipment
          ? {
              lng: shipment.arrival_lng,
              lat: shipment.arrival_lat,
              city: shipment.arrival_city,
              zipCode: shipment.arrival_cap,
              countryAbbr: shipment.arrival_country,
              label: [shipment.arrival_city, shipment.arrival_country, shipment.arrival_cap]
                .filter((i) => i)
                .join(', '),
            }
          : '',
      },
      cargo: {
        cbm: getFilled(shipment, 'cbm', ''),
        reefer: getFilled(shipment, 'reefer', 0),
        weight: getFilled(shipment, 'weight', ''),
        adr_goods: getFilled(shipment, 'adr_goods', 0),
        adr_number: getFilled(shipment, 'adr_number', ''),
        adr_class: getFilled(shipment, 'adr_class', null),
        insurance: getFilled(shipment, 'insurance', 0),
        goods_value: getFilled(shipment, 'goods_value', ''),
        goods_value_currency: getFilled(shipment, 'goods_value_currency', null),
        // reefer_temperature
        total_collies: getFilled(shipment, 'total_collies', ''),
        trucks: [{}],
        packages: [{ stackable: true }],
        containers: [{}],
        type_of_weight:
          selectedMode?.cargoKey === 'packages'
            ? shipment.cargo.some((p) => p.type_of_weight === 0)
              ? 0
              : 1
            : 0,
        is_checked_packaging_details: true,
      },
      summary: {
        client: shipment?.client?.id_auto
          ? {
              label: shipment.client.company_name,
              value: shipment.client.id_auto,
              imported: shipment.client.arca_imported,
            }
          : null,
        contacts: shipment?.contacts.map((c) => ({ label: c.full_name, value: c.id_auto })),
      },
    }

    if (shipment && shipment?.cargo?.length > 0) {
      stepsValue.cargo[selectedMode.cargoKey] = shipment.cargo.map((p) =>
        selectedMode.cargoKey === 'packages'
          ? {
              box_type: p.box_type,
              width: getFilled(p, 'width', ''),
              length: getFilled(p, 'length', ''),
              height: getFilled(p, 'height', ''),
              weight: getFilled(p, 'weight', ''),
              quantity: getFilled(p, 'number', ''),
              type_of_weight: p.type_of_weight,
              is_stackable: p.is_stackable,
            }
          : {
              reefer: p.reefer,
              box_type: p.box_type,
              quantity: getFilled(p, 'quantity', ''),
              reefer_temperature: p?.temperatureValue?.split(','),
            }
      )
    }

    if (entity === 'quotation') {
      stepsValue.routing.departure_airport = getFilled(shipment, 'departure_airport', null)
      stepsValue.routing.arrival_airport = getFilled(shipment, 'arrival_airport', null)
      stepsValue.routing.departure_port = getFilled(shipment, 'departure_port', null)
      stepsValue.routing.arrival_port = getFilled(shipment, 'arrival_port', null)
    }

    return stepsValue
  }, [selectedMode, shipment, entity])

  return (
    <>
      <ShipmentCreationProvider entity={entity}>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>

        <Grid gutter={24} className="w-full">
          <Grid.Col md={10} lg={8} xl={6} offsetMd={1} offsetLg={2} offsetXl={3}>
            <PageHeader
              breadcrumbs={
                <Breadcrumbs>
                  <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
                  <BreadcrumbsItem
                    text={`${capitalizeFirstLetter(entity)}s`}
                    to={`/panel/${entity}s`}
                    component={Link}
                  />
                </Breadcrumbs>
              }
            >
              {pageTitle}
            </PageHeader>

            <ShipmentCreationProgressTracker entity={entity} />

            <div className="my-8">
              {!!entityId && (isLoading || isFetching) ? (
                <div className="text-center my-8">
                  <Spinner />
                </div>
              ) : isError ? (
                <Grid gutter={24} className="w-full">
                  <Grid.Col md={6} offsetMd={3}>
                    <SectionMessage
                      appearance="warning"
                      title={`Something wrong on loading ${entity} data, please retry`}
                    >
                      <Button onClick={() => refetch()}>Retry</Button>
                    </SectionMessage>
                  </Grid.Col>
                </Grid>
              ) : (
                <WizardFormProvider defaultStepsValue={defaultStepsValue}>
                  <Outlet />
                </WizardFormProvider>
              )}
            </div>
          </Grid.Col>
        </Grid>
      </ShipmentCreationProvider>
    </>
  )
}

export default ShipmentCreationPage
