import { useMemo, useCallback } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

import useShipment from 'hooks/useShipment'
import { createShipmentPIC } from 'api/shipments'

//Atlassian
import Button from '@atlaskit/button'
import Lozenge from '@atlaskit/lozenge'
import { DynamicTableStateless } from '@atlaskit/dynamic-table'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'
import Spinner from '@atlaskit/spinner'

//Interfaces
import { ShortcutIcon, DotsIcon, useDisclosure, Grid, useFlags } from '@royan-co/user-interface'

//Components
import Card, { CardTitle } from 'components/UI/Card'
import ShareTrackingModal from './ShareTrackingModal'
import DeleteShareTrackingModal from './DeleteShareTrackingModal'
import RowTable from 'components/UI/RowTable'
import LinkContactModal from './LinkContactModal'

const tableHead = {
  cells: [
    { content: 'Contact name' },
    { content: 'Email' },
    { content: 'Phone' },
    { content: 'Channels' },
    { content: 'Actions' },
  ],
}

const ShipmentClientDetail = () => {
  const { shipmentId, quotationId } = useParams()
  const { showSuccessFlag, showWarningFlag } = useFlags()

  const entityId = shipmentId || quotationId

  const { shipment, upsertContactPICToData } = useShipment(entityId)

  const [isOpenTrackingModal, trackingModalHandlers] = useDisclosure()
  const [isOpenContactModal, contactModalHandlers] = useDisclosure()
  const [isOpenDeletingModal, deletingModalHandlers] = useDisclosure()

  const createPICMutation = useMutation(createShipmentPIC.bind(null, entityId))

  const handleMakePIC = useCallback(
    (contactId) => {
      createPICMutation.mutate(contactId, {
        onSuccess: (res) => {
          upsertContactPICToData(contactId)
          showSuccessFlag(res.message)
        },
        onError: (e) => {
          console.error(e)
          showWarningFlag('Something wrong on making P.I.C')
        },
      })
    },
    [createPICMutation, showSuccessFlag, showWarningFlag, upsertContactPICToData]
  )

  const tableRows = useMemo(() => {
    const mergedContacts = (shipment?.contacts || [])
      .map((c) => ({ ...c, pic: c.pivot?.pic }))
      .concat(
        (shipment?.tracking_contacts || []).map((c) => ({
          ...c,
          full_name: c.name,
          email: c.contact,
          full_cellphone: c.phone,
          type: 'tracking',
          whatapp: !!c.phone,
          tracking: !!c.contact,
          pic: false,
        }))
      )
    return mergedContacts.map((contact, i) => ({
      cells: [
        {
          content: (
            <div className="my-2">
              <div>{contact.full_name}</div>
              {contact.pic ? <Lozenge appearance="inprogress">P.i.c</Lozenge> : null}
              {contact.type === 'tracking' && <Lozenge appearance="default">Tracking</Lozenge>}
            </div>
          ),
        },
        {
          content: contact.email || <Lozenge appearance="default">Not set</Lozenge>,
        },
        {
          content: contact.full_cellphone ? (
            <div>{`+${contact.full_cellphone}`}</div>
          ) : (
            <Lozenge appearance="default">Not set</Lozenge>
          ),
        },
        {
          content: (
            <>
              {contact.whatapp && (
                <span className="mr-1">
                  <Lozenge appearance="success">Whatsapp</Lozenge>
                </span>
              )}
              {contact.tracking && <Lozenge appearance="inprogress">Email</Lozenge>}
            </>
          ),
        },
        {
          content: !contact.pic && (
            <DropdownMenu
              placement="bottom-end"
              trigger={({ triggerRef, ...props }) => (
                <Button ref={triggerRef} appearance="subtle" children={<DotsIcon />} {...props} />
              )}
            >
              {contact.type === 'tracking' ? (
                <DropdownItemGroup>
                  <DropdownItem
                    children="Edit"
                    onClick={() => trackingModalHandlers.open(contact)}
                  />
                  <DropdownItem
                    children="Delete"
                    onClick={() => deletingModalHandlers.open(contact)}
                  />
                </DropdownItemGroup>
              ) : (
                <DropdownItemGroup>
                  <DropdownItem
                    onClick={(e) => {
                      e.stopPropagation()
                      handleMakePIC(contact.id_auto)
                    }}
                  >
                    Make P.I.C
                    {createPICMutation.isLoading && (
                      <span className="ml-2">
                        <Spinner size="small" />
                      </span>
                    )}
                  </DropdownItem>
                </DropdownItemGroup>
              )}
            </DropdownMenu>
          ),
        },
      ],
    }))
  }, [shipment, createPICMutation, trackingModalHandlers, deletingModalHandlers, handleMakePIC])

  return (
    <>
      <div className="flex items-center mb-3">
        <CardTitle className="mb-0">Client</CardTitle>
        <Button
          appearance="link"
          className="ml-auto"
          onClick={() =>
            quotationId
              ? trackingModalHandlers.open()
              : contactModalHandlers.open(shipment.client.id_auto)
          }
        >
          Add contact
        </Button>
      </div>

      <Card>
        <Grid>
          <Grid.Col md={6}>
            <RowTable
              rows={[
                {
                  title: 'Name',
                  content: (
                    <Link to={`/panel/registries/${shipment.client.id_auto}`} target="_blank">
                      {shipment.client.company_name} <ShortcutIcon size={16} />
                    </Link>
                  ),
                },
              ]}
            />
          </Grid.Col>
        </Grid>

        <div className="overflow-hidden w-100 mt-6">
          <div className="overflow-auto">
            <DynamicTableStateless head={tableHead} rows={tableRows} />
          </div>
        </div>

        <ShareTrackingModal
          isOpen={isOpenTrackingModal}
          editingContact={trackingModalHandlers.data}
          onClose={() => trackingModalHandlers.close()}
        />

        <LinkContactModal
          isOpen={isOpenContactModal}
          shipmentContacts={shipment?.contacts}
          registryId={contactModalHandlers.data}
          shipmentId={shipmentId}
          onClose={() => contactModalHandlers.close()}
        />

        <DeleteShareTrackingModal
          isOpen={isOpenDeletingModal}
          deletingContact={deletingModalHandlers.data}
          onClose={() => deletingModalHandlers.close()}
        />
      </Card>
    </>
  )
}

export default ShipmentClientDetail
