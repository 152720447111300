import axios from 'axios'
import pick from 'lodash/pick'
import { Link, useParams } from 'react-router-dom'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

//UserInterfaces
import { useDebounce, useFormContext } from '@royan-co/user-interface'

//Apis
import { fetchSimilars } from 'api/registries'

//Contexts
import { useRegistryCreationContext } from 'contexts/RegistryCreationProvider'

//Atlassian
import Spinner from '@atlaskit/spinner'
import { ButtonItem, MenuGroup, Section } from '@atlaskit/menu'

const identicalFieldKeys = ['company_name', 'type', 'entity_type', 'freight_forwarder', 'vat_number']

const RegistryCreationExistenceChecker = () => {
  const controller = useRef()
  const { registryId } = useParams()
  const { watch, getValues } = useFormContext()
  const [registries, setRegistries] = useState([])
  const [requestCount, setRequestCount] = useState(0)
  const [isSearching, setIsSearching] = useState(false)
  const { setIdenticalFields } = useRegistryCreationContext()
  const [identicalFieldValues, setIdenticalFieldValues] = useState({
    existing_registry_id: registryId ? parseInt(registryId) : undefined,
    ...pick(getValues(), identicalFieldKeys),
  })

  watch((data, { name, type }) => {
    if (type === 'change' && identicalFieldKeys.includes(name)) {
      setIdenticalFieldValues((v) => ({ ...v, [name]: data[name]?.value || data[name] }))
    }
  })

  const debouncedIdenticalFieldValues = useDebounce(identicalFieldValues)

  const isFilledIdenticalFields = useMemo(
    () =>
      Object.keys(debouncedIdenticalFieldValues).some(
        (key) => !!debouncedIdenticalFieldValues[key]
      ),
    [debouncedIdenticalFieldValues]
  )

  const fetchSimilarResults = useCallback(async () => {
    try {
      setIsSearching(true)
      if (controller.current) {
        controller.current.abort()
      }
      // setIdenticalFields([])

      controller.current = new AbortController()
      const { result } = await fetchSimilars(debouncedIdenticalFieldValues, controller.current)

      setIsSearching(false)
      setRequestCount((c) => c + 1)
      setRegistries(result?.registries || [])
      setIdenticalFields(result?.identical_fields || [])
    } catch (error) {
      if (!axios.isCancel(error)) {
        setIsSearching(false)
        console.error(error)
      }
    }
  }, [debouncedIdenticalFieldValues, setIdenticalFields])

  useEffect(() => {
    if (isFilledIdenticalFields) {
      fetchSimilarResults()
    }
  }, [fetchSimilarResults, isFilledIdenticalFields])

  return (
    <div className="shadow rounded">
      <MenuGroup>
        <Section title="existence checker">
          {isSearching ? (
            <div className="flex justify-center items-center my-3">
              <Spinner />
            </div>
          ) : requestCount === 0 ? (
            <div className="text-neutral-200 px-5 pb-2">
              As you progress through filling the form, similar companies which already exist in the
              system will be displayed here.
            </div>
          ) : registries.length === 0 ? (
            <div className="text-neutral-200 px-5 pb-2">No similar registries found.</div>
          ) : (
            registries?.map((item) => {
              return (
                <ButtonItem key={item.id_auto}>
                  <Link to={`/panel/registries/${item.id_auto}`} target="_blank">
                    <div className="text-gray-1">{item?.company_name}</div>
                    <div className="text-neutral-200 text-xs mt-1">
                      Created at {item.creation_date.split(' ')[0]}
                    </div>
                  </Link>
                </ButtonItem>
              )
            })
          )}
        </Section>
      </MenuGroup>
    </div>
  )
}

export default RegistryCreationExistenceChecker
