import { useCallback, useMemo } from 'react'
import { Link, useParams } from 'react-router-dom'

// Apis
import { editInvoice } from '../api/invoice-requests'

// Hooks
import { useInvoice, useUpdateInvoice } from '../api/getInvoice'

// Contexts
import { useAuth } from 'contexts/AuthProvider'

// Utils
import { invoiceTypes } from '../utils/invoice-types'
import { formatMonetaryNumber } from 'utils/numbers'

// Atlassian
import Lozenge from '@atlaskit/lozenge'
import Button from '@atlaskit/button'

// Interfaces
import { Grid, ShortcutIcon } from '@royan-co/user-interface'

// Components
import Card, { CardSubtitle } from 'components/UI/Card'
import RowTable from 'components/UI/RowTable'
import InvoicePaidTag from 'components/Invoices/PaidTag'
import InvoiceTypeTag from 'components/Invoices/TypeTag'
import InvoiceRegistrationTag from 'components/Invoices/RegistrationTag'
import ShipmentOperatorInlineEdit from 'components/Shipments/View/GeneralDetails/Operator'
import InvoiceDocumentTypeInlineEdit from './DocumentTypeInlineEdit'

const ViewInvoiceGeneral = () => {
  let { invoiceId } = useParams()
  const { currentUser } = useAuth()
  const { invoice } = useInvoice(invoiceId)
  const { editInvoiceOperator } = useUpdateInvoice(invoiceId)

  const _type = useMemo(() => {
    return invoiceTypes.find(
      (type) =>
        type.invoiceKeys.active_passive === invoice?.active_passive &&
        type.invoiceKeys.buffer === invoice?.buffer
    )
  }, [invoice])

  const accessabilityChangeOperator = useMemo(() => {
    if (['master', 'admin'].includes(currentUser?.user_level_name?.name)) return true
    else return false
  }, [currentUser?.user_level_name?.name])

  const changeOperatorFn = useCallback(
    (data) =>
      editInvoice(invoiceId, 'take_in_charge_operator', {
        take_in_charge_operator: data.value,
      }),
    [invoiceId]
  )

  const changeOperatorCallback = useCallback(
    (res) => editInvoiceOperator({ ...res.invoice.operator }),
    [editInvoiceOperator]
  )

  const generalRows = useMemo(() => {
    let rows = []

    if (_type.name === 'active')
      rows.push({
        title: 'Invoice type',
        content: (
          <InvoiceTypeTag value={invoice.do_boolean} activePassive={invoice?.active_passive} />
        ),
      })

    if (_type.name === 'buffer')
      rows.push({
        title: 'Document type',
        content: (
          <InvoiceDocumentTypeInlineEdit
            documentType={invoice?.document_type}
            invoiceId={invoiceId}
          />
        ),
      })

    rows.push({
      title: 'Invoice date',
      content: invoice?.invoice_date,
    })

    if (_type.name === 'active')
      rows.push({
        title: 'Creator',
        content: invoice?.creator?.full_name,
      })
    else
      rows.push(
        {
          title: 'Registration date',
          content: invoice?.registration_date || <Lozenge>Not provided</Lozenge>,
        },
        {
          title: 'Operator',
          content: (
            <ShipmentOperatorInlineEdit
              canEdit={accessabilityChangeOperator}
              operator={invoice?.operator}
              changeOperatorFn={changeOperatorFn}
              changeOperatorCallback={changeOperatorCallback}
            />
          ),
        }
      )

    rows = [
      ...rows,
      {
        title: 'ARCA status',
        content: (
          <InvoiceRegistrationTag
            value={invoice.arca_status}
            elaborated={invoice?.elaborated}
            error={invoice?.arca_error}
          />
        ),
      },
      {
        title: 'Paid',
        content: <InvoicePaidTag value={invoice?.is_payd} />,
      },
      {
        title: 'Total taxable',
        content: formatMonetaryNumber(invoice.total_taxable || 0) + ' EUR',
      },
      {
        title: 'Total IVA',
        content: invoice.tot_iva * 1 ? formatMonetaryNumber(invoice.tot_iva) + ' EUR' : 0,
      },
      {
        title: 'Total amount',
        content: (
          <>
            <div>{formatMonetaryNumber(invoice.total_amount || 0) + ' EUR'}</div>
            {_type.name !== 'active' ? (
              <div className="text-red-400 mt-1">
                {formatMonetaryNumber(invoice.unassigned_amount || 0) + ' EUR '}
                left to assign
              </div>
            ) : null}
          </>
        ),
      },
      {
        title: 'Notes',
        content: invoice.notes ? <small>{invoice.notes}</small> : <Lozenge>Not set</Lozenge>,
      },
    ]

    return rows
  }, [
    _type,
    accessabilityChangeOperator,
    changeOperatorCallback,
    changeOperatorFn,
    invoice,
    invoiceId,
  ])

  const clientRows = useMemo(
    () => [
      {
        title: 'Client',
        content: (
          <Link to={`/panel/registries/${invoice?.client_id}`}>
            {invoice?.client?.company_name} <ShortcutIcon size={16} />
          </Link>
        ),
      },
      {
        title: 'Code',
        content: invoice?.client_code,
      },
      {
        title: 'Imported in accounting',
        content: invoice?.client?.arca_imported ? (
          <Lozenge appearance="success">Yes</Lozenge>
        ) : (
          <Lozenge appearance="moved">No</Lozenge>
        ),
      },
      {
        title: 'Payment terms',
        content: invoice?.payment_code?.description || <Lozenge>Not set</Lozenge>,
      },
      {
        title: 'Bank',
        content: invoice?.bank?.name || <Lozenge>Not set</Lozenge>,
      },
    ],
    [invoice]
  )

  const supplierRows = useMemo(
    () => [
      {
        title: 'Supplier',
        content: (
          <Link to={`/panel/registries/${invoice?.supplier_id}`}>
            {invoice?.supplier?.company_name} <ShortcutIcon size={16} />
          </Link>
        ),
      },
      {
        title: 'Imported in accounting',
        content: invoice?.supplier?.arca_imported ? (
          <Lozenge appearance="success">Yes</Lozenge>
        ) : (
          <Lozenge appearance="moved">No</Lozenge>
        ),
      },
      {
        title: 'Payment terms',
        content:
          _type.name === 'passive' && invoice?.payment_condition?.code ? (
            <small>{invoice.payment_condition.description}</small>
          ) : _type.name === 'buffer' &&
            invoice.supplier.owners?.find((s) => !!s.accounting_arca)?.payment_code_details
              ?.code ? (
            <small>
              {
                invoice.supplier.owners.find((s) => !!s.accounting_arca).payment_code_details
                  .description
              }
            </small>
          ) : (
            <Lozenge>Not provided</Lozenge>
          ),
      },
    ],
    [invoice, _type]
  )

  const documentRows = useMemo(() => {
    return _type?.name === 'buffer'
      ? [
          {
            title: 'Attached invoice',
            content: !invoice?.document_url ? (
              <Lozenge>Not provided</Lozenge>
            ) : (
              <Button
                appearance="primary"
                spacing="compact"
                onClick={() => {
                  window.open(invoice.download_url, '_blank')
                }}
              >
                Download
              </Button>
            ),
          },
          {
            title: 'PDF ASSO',
            content: (
              <Button
                appearance="primary"
                spacing="compact"
                onClick={() => {
                  window.open(`/panel/buffer-invoices/${invoiceId}/pdf-asso`, '_blank')
                }}
              >
                Download
              </Button>
            ),
          },
        ]
      : [
          {
            title: 'Proof of delivery',
            content: !invoice?.cmr_download_url ? (
              <Lozenge>Not provided</Lozenge>
            ) : (
              <Button
                appearance="primary"
                spacing="compact"
                onClick={() => {
                  window.open(invoice.cmr_download_url, '_blank')
                }}
              >
                Download
              </Button>
            ),
          },
        ]
  }, [_type?.name, invoice, invoiceId])

  return (
    <Card className="mb-6">
      <Grid className="w-full">
        <Grid.Col lg={6}>
          <RowTable rows={generalRows} width="w-1/2" />
        </Grid.Col>

        <Grid.Col lg={6}>
          {_type?.name === 'active' ? (
            <>
              <CardSubtitle className="pb-3 pt-2">client details</CardSubtitle>
              <RowTable rows={clientRows} width="w-1/2" />
            </>
          ) : (
            <>
              <CardSubtitle className="pb-3 pt-2">Supplier details</CardSubtitle>
              <RowTable rows={supplierRows} width="w-1/2" />

              <CardSubtitle className="pb-3 pt-4 lg:pt-6">Documents</CardSubtitle>
              <RowTable rows={documentRows} width="w-1/2" />
            </>
          )}
        </Grid.Col>
      </Grid>
    </Card>
  )
}

export default ViewInvoiceGeneral
