// Interfaces
import { DotsIcon } from '@royan-co/user-interface'

// Atlassian
import Button from '@atlaskit/button'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'

// Components
import DropdownLink from 'components/UI/DropdownLink'

export const generateBanksColumns = (openDeleteModal) => {
  return [
    {
      key: 'code',
      title: 'Code',
      isSortable: true,
      show: true,
      content: (item) => item.code,
    },
    {
      key: 'name',
      title: 'Name',
      isSortable: true,
      show: true,
      content: (item) => item.name,
    },
    {
      key: 'sub_account',
      title: 'Sub account',
      isSortable: false,
      show: true,
      content: (item) => item.subaccount,
    },
    {
      key: 'iban',
      title: 'IBAN',
      isSortable: false,
      show: true,
      content: (item) => item.iban,
    },
    {
      key: 'swift',
      title: 'Swift',
      isSortable: false,
      show: true,
      content: (item) => item.swift,
    },
    {
      key: 'creation_date',
      title: 'Creation date',
      isSortable: false,
      show: true,
      content: (item) => item.creation_date,
    },
    {
      key: 'actions',
      title: 'Actions',
      show: true,
      content: (item) => (
        <DropdownMenu
          placement="bottom-end"
          trigger={({ triggerRef, ...props }) => (
            <Button
              {...props}
              appearance="subtle"
              children={<DotsIcon label="more" />}
              ref={triggerRef}
            />
          )}
        >
          <DropdownItemGroup>
            <DropdownLink children="Edit" to={`/panel/banks/${item.id_auto}/edit`} />

            <DropdownItem children="Delete" onClick={() => openDeleteModal(item.id_auto)} />
          </DropdownItemGroup>
        </DropdownMenu>
      ),
    },
  ]
}
