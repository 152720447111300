//Contexts
import { useAuth } from 'contexts/AuthProvider'

//Icons
import PersonIcon from '@atlaskit/icon/glyph/person'

//Atlassian
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'
import Button from '@atlaskit/button'
import CalendarFilledIcon from '@atlaskit/icon/glyph/calendar-filled'

//Interfaces
import { DotsIcon } from '@royan-co/user-interface'

//Components
import Card from 'components/UI/Card'

const NoteBox = ({ note, onDelete, onEdit, className }) => {
  const { currentUser } = useAuth()

  return (
    <Card className={className}>
      <div className="flex justify-between w-100">
        <h3 className="font-semibold">{note?.subject}</h3>
        {currentUser?.id === note?.creator_id && (
          <DropdownMenu
            placement="bottom-start"
            trigger={({ triggerRef, ...props }) => (
              <Button {...props} appearance="subtle" children={<DotsIcon />} ref={triggerRef} />
            )}
          >
            <DropdownItemGroup>
              <DropdownItem onClick={() => onEdit()}>Edit</DropdownItem>
              <DropdownItem onClick={() => onDelete()}>Delete</DropdownItem>
            </DropdownItemGroup>
          </DropdownMenu>
        )}
      </div>

      <span>{note?.text}</span>

      <div className="flex align-items-center mt-4">
        <div className="flex align-items-center text-neutral-200">
          <CalendarFilledIcon size="small" primaryColor="text-neutral-200" />
          <small className="ml-1">{note?.creation_date}</small>
        </div>

        <div className="flex align-items-center text-neutral-200 mx-3">
          <PersonIcon size="small" primaryColor="text-neutral-200" />
          <small className="ml-1">{note.creator_name}</small>
        </div>
      </div>
    </Card>
  )
}

export default NoteBox
