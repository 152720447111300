// Utils
import { transformSelectField } from 'utils/helpers'
import { airWaybillTypeOptions } from './../../utils/constants'

//Hooks
import useShipmentsOperators from 'hooks/useShipmentsOperators'

//Atlassian
import Select from '@atlaskit/select'
import TextField from '@atlaskit/textfield'
import { DatePicker } from '@atlaskit/datetime-picker'

//Interfaces
import { Field } from '@royan-co/user-interface'

//Components
import useRegistryAirlines from 'hooks/useRegistryAirlines'
import RatesAirportsField from 'components/Rates/List/AirportsField'
import NumericInput from 'components/UI/NumericInput'

const AirWaybillsFilterFields = ({ appliedFilters }) => {
  const { shipmentsOperatorsOptions, isLoadingShipmentsOperators } = useShipmentsOperators()
  const { registryAirlineOptions, isLoadingRegistryAirlines } = useRegistryAirlines()

  return (
    <>
      <Field
        label="Type"
        type="select"
        name="type"
        transform={transformSelectField(airWaybillTypeOptions)}
      >
        {({ fieldProps }) => (
          <Select
            isClearable={true}
            options={airWaybillTypeOptions}
            placeholder="Choose a type"
            {...fieldProps}
          />
        )}
      </Field>

      <Field
        label="Airline"
        type="select"
        name="airline"
        transform={transformSelectField(registryAirlineOptions)}
      >
        {({ fieldProps }) => (
          <Select
            isClearable={true}
            isLoading={isLoadingRegistryAirlines}
            options={registryAirlineOptions}
            placeholder="Choose an airline"
            {...fieldProps}
          />
        )}
      </Field>

      <Field
        type="select"
        label="Creator"
        name="creator_id"
        transform={transformSelectField(shipmentsOperatorsOptions)}
      >
        {({ fieldProps }) => (
          <Select
            isClearable={true}
            placeholder="Choose"
            options={shipmentsOperatorsOptions}
            isLoading={isLoadingShipmentsOperators}
            {...fieldProps}
          />
        )}
      </Field>

      <Field label="Shipment reference" name="shipment" defaultValue="">
        {({ fieldProps }) => (
          <TextField placeholder="Type the shipment reference" {...fieldProps} />
        )}
      </Field>

      <Field label="Shipper" name="shipper" defaultValue="">
        {({ fieldProps }) => <TextField placeholder="Type the shipper" {...fieldProps} />}
      </Field>

      <Field label="Consignee" name="consignee" defaultValue="">
        {({ fieldProps }) => <TextField placeholder="Type the shipper" {...fieldProps} />}
      </Field>

      <RatesAirportsField
        label="Departure airport"
        name="departure_airport"
        defaultValue={appliedFilters.departure_airport}
      />

      <RatesAirportsField
        label="Arrival airport"
        name="arrival_airport"
        defaultValue={appliedFilters.arrival_airport}
      />

      <Field label="Flight number" name="flight">
        {({ fieldProps }) => <TextField placeholder="Type the flight number" {...fieldProps} />}
      </Field>

      <Field label="Number of collies" name="collies_number">
        {({ fieldProps }) => <TextField placeholder="Type the number of collies" {...fieldProps} />}
      </Field>

      <div className="grid md:grid-cols-2 md:gap-4">
        <Field label="Weight" name="weight" defaultValue="">
          {({ fieldProps }) => <NumericInput {...fieldProps} placeholder="Type the weight" />}
        </Field>

        <Field label="Chargeable weight" name="chargeable_weight" defaultValue="">
          {({ fieldProps }) => (
            <NumericInput {...fieldProps} placeholder="Type the chargeable weight" />
          )}
        </Field>
      </div>

      <div className="grid md:grid-cols-2 md:gap-4">
        <Field
          label="Flight date from"
          name="flight_date_from"
          defaultValue={appliedFilters.flight_date_from || ''}
        >
          {({ fieldProps }) => <DatePicker placeholder=" " {...fieldProps} />}
        </Field>

        <Field
          label="Flight date to"
          name="flight_date_to"
          defaultValue={appliedFilters.flight_date_to || ''}
        >
          {({ fieldProps }) => <DatePicker placeholder=" " {...fieldProps} />}
        </Field>
      </div>

      <div className="grid md:grid-cols-2 md:gap-4">
        <Field label="Created from" name="from" defaultValue={appliedFilters.from || ''}>
          {({ fieldProps }) => <DatePicker placeholder=" " {...fieldProps} />}
        </Field>

        <Field label="Created to" name="to" defaultValue={appliedFilters.to || ''}>
          {({ fieldProps }) => <DatePicker placeholder=" " {...fieldProps} />}
        </Field>
      </div>
    </>
  )
}

export default AirWaybillsFilterFields
