import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

// Apis
import { exportTemperatureReport, getTrackingRoutes } from 'api/shipments'

// Hooks
import useShipment from 'hooks/useShipment'
import useAirport from 'hooks/useAirport'

// Atlassian
import Button from '@atlaskit/button'
import Spinner from '@atlaskit/spinner'
import SectionMessage from '@atlaskit/section-message'
import Tabs, { Tab, TabList, TabPanel } from '@atlaskit/tabs'

// Components
import ShipmentTrackingRouting from 'components/Shipments/View/TrackingRouting'
import ShipmentTrackingTemperatureChart from 'components/Shipments/View/TrackingTemperatureChart'
import ShipmentTrackingLogs from 'components/Shipments/View/TrackingLogs'

const ShipmentViewTrackingPage = () => {
  const { shipment } = useShipment()
  const { shipmentId: id } = useParams()

  const showAirportsLine = useMemo(
    () => shipment?.status === 9 && shipment?.shipment_type === 2,
    [shipment?.shipment_type, shipment?.status]
  )

  const { data: airport } = useAirport(shipment?.arrival_airport, { enabled: showAirportsLine })

  const {
    data: tracking,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ['shipment', 'tracking', id],
    queryFn: () => getTrackingRoutes(id),
  })

  const handleDownloadReport = async (format) => {
    return await exportTemperatureReport(format, id)
  }

  return (
    <>
      {isLoading ? (
        <div className="text-center mt-24">
          <Spinner />
        </div>
      ) : isError ? (
        <SectionMessage
          appearance="warning"
          title="Something wrong on loading tracking details, please retry."
        >
          <Button onClick={() => refetch()}>Retry</Button>
        </SectionMessage>
      ) : (
        <>
          <Tabs id="branch-tabs">
            <div className="pb-3">
              <TabList>
                <Tab>Routing</Tab>

                {shipment?.reefer === 1 && <Tab>Temperature chart</Tab>}

                <Tab>Logs</Tab>
              </TabList>
            </div>

            <TabPanel>
              <ShipmentTrackingRouting
                showAirportsLine={showAirportsLine && airport?.latitude && airport?.longitude}
                tracking={tracking}
                entityType={shipment?.shipment_type}
                entityStatus={shipment?.status}
                departure={{
                  lat: shipment.departure_lat,
                  lng: shipment.departure_lng,
                  cap: shipment.departure_cap,
                  city: shipment.departure_city,
                  country: shipment.departure_country,
                }}
                arrival={{
                  lat: shipment.arrival_lat,
                  lng: shipment.arrival_lng,
                  cap: shipment.arrival_cap,
                  city: shipment.arrival_city,
                  country: shipment.arrival_country,
                }}
                arrivalStatus={21}
                departureStatus={2}
                arrivalAirport={{
                  ...airport,
                  lat: airport?.latitude,
                  lng: airport?.longitude,
                  city: airport?.city,
                  country: airport?.country_iso,
                }}
              />
            </TabPanel>

            {shipment.reefer === 1 && (
              <TabPanel>
                <ShipmentTrackingTemperatureChart
                  maxTemp={shipment?.reefer_temperature?.split(',')[1]}
                  minTemp={shipment?.reefer_temperature?.split(',')[0]}
                  trackingPins={tracking}
                  downloadReport={handleDownloadReport}
                />
              </TabPanel>
            )}

            <TabPanel>
              <ShipmentTrackingLogs parent="shipment" parentId={id} />
            </TabPanel>
          </Tabs>
        </>
      )}
    </>
  )
}

export default ShipmentViewTrackingPage
