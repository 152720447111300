// Utils
import { calculationMethodOptions } from 'components/Rates/constants'
import { registryClassificationOptions } from 'components/Registries/constants'
import { modeOptions } from 'components/Shipments/constants'
import {
  areaTypeOptions,
  marginCalculatorTypeOptions,
  mustGoOptions,
} from 'features/marginCalculator/utils/constants'
import { findOption } from 'utils/helpers'

export const marginCalculatorFilters = [
  {
    key: 'shipment_type',
    content: (v) => `Mode: ${findOption(modeOptions, v)?.label}`,
  },
  {
    key: 'country_area',
    content: (v) => `Type: ${findOption(marginCalculatorTypeOptions, v)?.label}`,
  },
  {
    key: 'departure_country',
    content: (v) => `Departure country: ${v}`,
  },
  {
    key: 'arrival_country',
    content: (v) => `Arrival country: ${v}`,
  },
  {
    key: 'area',
    content: (v) => `Area type: ${findOption(areaTypeOptions, v)?.label}`,
  },
  {
    key: 'calculation_method',
    content: (v) => `Calculation method: ${findOption(calculationMethodOptions, v)?.label}`,
  },
  {
    key: 'type',
    content: (v) =>
      `Registry classification: ${findOption(registryClassificationOptions, v)?.label}`,
  },
  {
    key: 'must_go',
    content: (v) => `Must go: ${findOption(mustGoOptions, v)?.label}`,
  },
  {
    key: 'created_from',
    content: (v) => `From: ${v}`,
  },
  {
    key: 'created_to',
    content: (v) => `To: ${v}`,
  },
]
