//Atlassian
import Textfield from '@atlaskit/textfield'
import { ErrorMessage } from '@atlaskit/form'

//Interfaces
import { Field, useFormContext } from '@royan-co/user-interface'

//Components
import Card from 'components/UI/Card'
import RegistrySelectField from 'components/Registries/RegistrySelectField'

const ShipmentPickupFormGeneral = () => {
  const { getValues } = useFormContext()

  return (
    <Card className="mt-0">
      <RegistrySelectField
        isRequired
        label="Carrier"
        name="carrier"
        registryType="supplier"
        defaultData={getValues('carrier')}
        className="!mt-0"
      />

      <Field label="Agreed rate" name="rate_agreed">
        {({ fieldProps, error }) => (
          <>
            <Textfield
              placeholder="Type the rate"
              {...fieldProps}
              elemAfterInput={
                <div className="text-neutral-200 whitespace-nowrap bg-neutral-20 self-stretch flex items-center px-2">
                  Euro (€)
                </div>
              }
            />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </>
        )}
      </Field>

      <Field label="Cargo reference" name="cargo_reference">
        {({ fieldProps, error }) => (
          <>
            <Textfield placeholder="Type the cargo reference" {...fieldProps} />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </>
        )}
      </Field>
    </Card>
  )
}

export default ShipmentPickupFormGeneral
