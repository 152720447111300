import { useMemo } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

//Hooks
import { useInvoice } from './../api/getInvoice'

// Utils
import { getFilled } from 'utils/helpers'

//Atlassian
import Spinner from '@atlaskit/spinner'
import Button from '@atlaskit/button'
import PageHeader from '@atlaskit/page-header'
import SectionMessage from '@atlaskit/section-message'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'

//Interfaces
import { Grid } from '@royan-co/user-interface'

//Components
import PDFAsso from '../components/PDFAsso'
import PureLayout from 'layouts/PureLayout'

const ViewPDFAsso = () => {
  const { invoiceId } = useParams()

  const { invoice, isLoading, isError, refetch } = useInvoice(invoiceId)

  const invoiceData = useMemo(() => JSON.parse(invoice?.buffer_json || '{}'), [invoice])

  const has_sender_telephone = useMemo(
    () => invoiceData?.FatturaElettronicaHeader?.CedentePrestatore?.Contatti?.Telefono,
    [invoiceData]
  )
  const has_sender_email = useMemo(
    () => invoiceData?.FatturaElettronicaHeader?.CedentePrestatore?.Contatti?.Email,
    [invoiceData]
  )
  const has_recipient_telephone = useMemo(
    () => invoiceData?.FatturaElettronicaHeader?.CedentePrestatore?.Contatti?.Telefono,
    [invoiceData]
  )
  const has_recipient_email = useMemo(
    () => invoiceData?.FatturaElettronicaHeader?.CedentePrestatore?.Contatti?.Email,
    [invoiceData]
  )

  const invoiceDataDocument = useMemo(
    () => ({
      data_ricezione: getFilled(
        invoiceData,
        'FatturaElettronicaBody.DatiGenerali.DatiGeneraliDocumento.Data',
        ''
      ),
      mittente_denominazione: invoiceData?.FatturaElettronicaHeader?.CedentePrestatore
        ?.DatiAnagrafici?.Anagrafica?.Denominazione
        ? getFilled(
            invoiceData,
            'FatturaElettronicaHeader.CedentePrestatore.DatiAnagrafici.Anagrafica.Denominazione',
            ''
          )
        : getFilled(
            invoiceData,
            'FatturaElettronicaHeader.CedentePrestatore.DatiAnagrafici.Anagrafica.Cognome',
            ''
          ) +
          ' ' +
          getFilled(
            invoiceData,
            'FatturaElettronicaHeader.CedentePrestatore.DatiAnagrafici.Anagrafica.Nome',
            ''
          ),
      mittente_identificativo: getFilled(
        invoiceData,
        'FatturaElettronicaHeader.CedentePrestatore.DatiAnagrafici.IdFiscaleIVA.IdCodice',
        ''
      ),
      mittente_codice: getFilled(
        invoiceData,
        'FatturaElettronicaHeader.CedentePrestatore.DatiAnagrafici.CodiceFiscale',
        ''
      ),
      mittente_regime: getFilled(
        invoiceData,
        'FatturaElettronicaHeader.CedentePrestatore.DatiAnagrafici.RegimeFiscale',
        ''
      ),
      mittente_indirizzo: getFilled(
        invoiceData,
        'FatturaElettronicaHeader.CedentePrestatore.Sede.Indirizzo',
        ''
      ),
      mittente_comune:
        getFilled(invoiceData, 'FatturaElettronicaHeader.CedentePrestatore.Sede.Comune', '') +
        ' Prov: ' +
        getFilled(invoiceData, 'FatturaElettronicaHeader.CedentePrestatore.Sede.Provincia', ''),
      mittente_cap:
        getFilled(invoiceData, 'FatturaElettronicaHeader.CedentePrestatore.Sede.CAP', '') +
        ' Nazione: ' +
        getFilled(invoiceData, 'FatturaElettronicaHeader.CedentePrestatore.Sede.Nazione', ''),
      mittente_telefono: getFilled(
        invoiceData,
        'FatturaElettronicaHeader.CedentePrestatore.Contatti.Telefono',
        ''
      ),
      mittente_email: getFilled(
        invoiceData,
        'InvoiceData.FatturaElettronicaHeader.CedentePrestatore.Contatti.Email',
        ''
      ),

      destinatario_denominazione: getFilled(
        invoiceData,
        'FatturaElettronicaHeader.CessionarioCommittente.DatiAnagrafici.Anagrafica.Denominazione',
        ''
      ),
      destinatario_identificativo: getFilled(
        invoiceData,
        'FatturaElettronicaHeader.CessionarioCommittente.DatiAnagrafici.IdFiscaleIVA.IdCodice',
        ''
      ),
      destinatario_codice: getFilled(
        invoiceData,
        'FatturaElettronicaHeader.CessionarioCommittente.DatiAnagrafici.CodiceFiscale',
        ''
      ),
      destinatario_regime: getFilled(
        invoiceData,
        'FatturaElettronicaHeader.CessionarioCommittente.DatiAnagrafici.RegimeFiscale',
        ''
      ),
      destinatario_indirizzo: getFilled(
        invoiceData,
        'FatturaElettronicaHeader.CessionarioCommittente.Sede.Indirizzo',
        ''
      ),
      destinatario_comune:
        getFilled(invoiceData, 'FatturaElettronicaHeader.CessionarioCommittente.Sede.Comune', '') +
        ' Prov: ' +
        getFilled(
          invoiceData,
          'FatturaElettronicaHeader.CessionarioCommittente.Sede.Provincia',
          ''
        ),
      destinatario_cap:
        getFilled(invoiceData, 'FatturaElettronicaHeader.CessionarioCommittente.Sede.CAP', '') +
        ' Nazione: ' +
        getFilled(invoiceData, 'FatturaElettronicaHeader.CessionarioCommittente.Sede.Nazione', ''),
      destinatario_telefono: getFilled(
        invoiceData,
        'FatturaElettronicaHeader.CessionarioCommittente.Contatti.Telefono',
        ''
      ),
      destinatario_email: getFilled(
        invoiceData,
        'FatturaElettronicaHeader.CessionarioCommittente.Contatti.Email',
        ''
      ),

      tipo_doc: getFilled(
        invoiceData,
        'FatturaElettronicaBody.DatiGenerali.DatiGeneraliDocumento.TipoDocumento',
        ''
      ),
      causale: invoiceData?.FatturaElettronicaBody?.DatiGenerali?.DatiGeneraliDocumento?.Causale
        ?.lenght
        ? invoiceData?.FatturaElettronicaBody?.DatiGenerali?.DatiGeneraliDocumento?.Causale?.forEach(
            (element) => element + '/n'
          )
        : getFilled(
            invoiceData,
            'FatturaElettronicaBody.DatiGenerali.DatiGeneraliDocumento.Causale',
            ''
          ),
      art73: '',
      fattura: getFilled(
        invoiceData,
        'FatturaElettronicaBody.DatiGenerali.DatiGeneraliDocumento.Numero',
        ''
      ),
      data: getFilled(
        invoiceData,
        'FatturaElettronicaBody.DatiGenerali.DatiGeneraliDocumento.Data',
        ''
      ),
      codice_dest: getFilled(
        invoiceData,
        'FatturaElettronicaHeader.DatiTrasmissione.CodiceDestinatario',
        ''
      ),

      dettaglio_linee: invoiceData?.FatturaElettronicaBody?.DatiBeniServizi?.DettaglioLinee?.map?.(
        (riga) => ({
          descrizione: getFilled(riga, 'Descrizione', ''),
          qt: getFilled(riga, 'Quantita', ''),
          prezzo: getFilled(riga, 'PrezzoUnitario', ''),
          um: getFilled(riga, 'UnitaMisura', ''),
          sc: '',
          iva: getFilled(riga, 'AliquotaIVA', ''),
          totale: getFilled(riga, 'PrezzoTotale', ''),
        })
      ) || [
        {
          descrizione: getFilled(
            invoiceData,
            'FatturaElettronicaBody.DatiBeniServizi.DettaglioLinee.Descrizione',
            ''
          ),
          qt: '1',
          prezzo: getFilled(
            invoiceData,
            'FatturaElettronicaBody.DatiBeniServizi.DettaglioLinee.PrezzoUnitario',
            ''
          ),
          um: '',
          sc: '',
          iva: getFilled(
            invoiceData,
            'FatturaElettronicaBody.DatiBeniServizi.DettaglioLinee.AliquotaIVA',
            ''
          ),
          totale: getFilled(
            invoiceData,
            'FatturaElettronicaBody.DatiBeniServizi.DettaglioLinee.PrezzoTotale',
            ''
          ),
        },
      ],

      dati_riepilogo: invoiceData?.FatturaElettronicaBody?.DatiBeniServizi?.DatiRiepilogo?.map?.(
        (riep) => ({
          dati: getFilled(riep, 'RiferimentoNormativo', ''),
          iva: getFilled(riep, 'Natura', ''),
          accessorie: '',
          totale_imposta: getFilled(riep, 'Imposta', ''),
          totale_imponibile: getFilled(riep, 'ImponibileImporto', ''),
        })
      ) || [
        {
          dati: getFilled(
            invoiceData,
            'FatturaElettronicaBody.DatiBeniServizi.DatiRiepilogo.RiferimentoNormativo',
            ''
          ),
          iva: getFilled(
            invoiceData,
            'FatturaElettronicaBody.DatiBeniServizi.DatiRiepilogo.Natura',
            ''
          ),
          accessorie: '',
          totale_imposta: getFilled(
            invoiceData,
            'FatturaElettronicaBody.DatiBeniServizi.DatiRiepilogo.Imposta',
            ''
          ),
          totale_imponibile: getFilled(
            invoiceData,
            'FatturaElettronicaBody.DatiBeniServizi.DatiRiepilogo.ImponibileImporto',
            ''
          ),
        },
      ],

      importo_fattura: getFilled(
        invoiceData,
        'FatturaElettronicaBody.DatiGenerali.DatiGeneraliDocumento.ImportoTotaleDocumento',
        ''
      ),
    }),
    [invoiceData]
  )

  return (
    <PureLayout back={`/panel/buffer-invoices/${invoiceId}`}>
      <Helmet>
        <title>PDF ASSO</title>
      </Helmet>

      <Grid className="w-full">
        <Grid.Col md={8} xl={6} offsetMd={2} offsetXl={3}>
          <PageHeader
            breadcrumbs={
              <Breadcrumbs>
                <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
                <BreadcrumbsItem
                  text="Buffer invoices"
                  to="/panel/buffer-invoices"
                  component={Link}
                />
                <BreadcrumbsItem
                  text="View invoice"
                  to={`/panel/buffer-invoices/${invoiceId}`}
                  component={Link}
                />
              </Breadcrumbs>
            }
          >
            PDF ASSO
          </PageHeader>

          <div>
            {isLoading ? (
              <div className="flex justify-center my-32">
                <Spinner />
              </div>
            ) : isError ? (
              <SectionMessage
                appearance="warning"
                title="Something wrong on loading data, please retry."
              >
                <Button onClick={() => refetch()}>Retry</Button>
              </SectionMessage>
            ) : (
              <PDFAsso
                invoiceData={invoiceDataDocument}
                has_sender_telephone={has_sender_telephone}
                has_sender_email={has_sender_email}
                has_recipient_telephone={has_recipient_telephone}
                has_recipient_email={has_recipient_email}
              />
            )}
          </div>
        </Grid.Col>
      </Grid>
    </PureLayout>
  )
}

export default ViewPDFAsso
