import { useMemo } from 'react'
import { Link, useParams } from 'react-router-dom'

import { findOption } from 'utils/helpers'
import { formatNumber } from 'utils/numbers'
import useRate from 'hooks/useRate'

//Atlassian
import Lozenge from '@atlaskit/lozenge'

//Interfaces
import { Grid, ShortcutIcon } from '@royan-co/user-interface'

//Components
import RowTable from 'components/UI/RowTable'
import Card, { CardSubtitle } from 'components/UI/Card'
import {
  calculationMethodOptions,
  calculationOnNotStackableOptions,
  calculationOnOptions,
  calculationUnitOptions,
  roundingOptions,
  serviceTypeOptions,
} from 'components/Rates/constants'
import RatesFormatTag from 'components/Rates/FormatTag'
import CountryFlag from 'components/UI/CountryFlag'
import ShipmentModeTag from 'components/Shipments/ModeTag'
import RatesStatusTag from 'components/Rates/StatusTag'
import RateCostFormat from 'components/Rates/CostFormat'

const RateViewGeneralPage = () => {
  let { rateId } = useParams()
  const { rate } = useRate(rateId)

  const generalRows = useMemo(
    () =>
      [
        {
          title: 'Supplier',
          content: rate?.supplier?.id_auto ? (
            <Link to={`/panel/registries/${rate.supplier.id_auto}`} target="_blank">
              {rate.supplier.company_name} <ShortcutIcon size={16} />
            </Link>
          ) : (
            <Lozenge appearance="default">Not set</Lozenge>
          ),
        },
        {
          title: 'Type',
          content: rate?.from_to_label,
        },
        {
          title: 'Format',
          content: <RatesFormatTag value={rate?.type} />,
        },
        {
          title: 'Service',
          content: rate?.cost_code_data?.description,
        },
        {
          title: 'Airline',
          content: rate?.airline_data?.company_name,
          show: [2].includes(rate?.shipment_type),
        },
        {
          title: 'Validity',
          content: `From ${rate?.start_date} to ${rate?.end_date}`,
          show: !!rate?.start_date && !!rate?.end_date,
        },
        {
          title: 'Place of loading',
          content:
            rate?.departure === null ? (
              <Lozenge appearance="inprogress">Multiple</Lozenge>
            ) : [1, 2, 8, 14].includes(rate?.from_to) ? (
              <CountryFlag iso={rate?.departure_country} />
            ) : (
              rate?.departure
            ),
        },
        {
          title: 'Place of delivery',
          content:
            rate?.arrival === null ? (
              <Lozenge appearance="inprogress">Multiple</Lozenge>
            ) : [0, 2, 9, 13].includes(rate?.from_to) ? (
              <CountryFlag iso={rate?.arrival_country} />
            ) : (
              rate?.arrival
            ),
        },
        {
          title: 'Mode of transport',
          content: <ShipmentModeTag item={rate} />,
        },
        {
          title: 'Type of shipment',
          content: findOption(serviceTypeOptions, rate?.service_type)?.label,
        },
        {
          title: 'Transit time',
          content: rate?.transit_time ? (
            formatNumber(rate?.transit_time) + ' days'
          ) : (
            <Lozenge appearance="default">Not set</Lozenge>
          ),
        },
      ].filter((i) => (i.show !== undefined ? i.show : true)),
    [rate]
  )

  const specialConditionsRows = useMemo(
    () => [
      {
        title: 'Second set of documents',
        content: <RatesStatusTag value={rate?.second_set} />,
      },
      {
        title: 'Insurance',
        content: <RatesStatusTag value={rate?.insurance} />,
      },
      {
        title: 'Letter of credit',
        content: <RatesStatusTag value={rate?.letter_of_credit} />,
      },
      {
        title: 'Must-go',
        content: <RatesStatusTag value={rate?.must_go} />,
      },
      {
        title: 'Consolidation service',
        content: <RatesStatusTag value={rate?.consolidate} />,
      },
      {
        title: 'Promo',
        content: <RatesStatusTag value={rate?.promo} />,
      },
      {
        title: 'Exclude accessories',
        content: <RatesStatusTag value={rate?.exclude_accessories} />,
      },
      {
        title: 'Exclude cost calculation',
        content: <RatesStatusTag value={rate?.no_cost} />,
      },
      {
        title: 'Exclude margin',
        content: <RatesStatusTag value={rate?.exclude_margin} />,
      },
    ],
    [rate]
  )

  const calculationRows = useMemo(() => {
    const calculationItems = [
      {
        title: 'Allow calculation',
        content: <RatesStatusTag value={rate?.allow_calculate} />,
      },
      {
        title: 'Calculation method',
        content: findOption(calculationMethodOptions, rate?.calculation_method)?.label,
      },
    ]

    if (rate?.calculation_method !== 6)
      calculationItems.push({
        title: 'Max dimension',
        content:
          rate?.max_width || rate?.max_length || rate?.max_height ? (
            `${formatNumber(rate?.max_width || '-')}cm x ${formatNumber(
              rate?.max_length || '-'
            )}cm x ${formatNumber(rate?.max_height || '-')}cm `
          ) : (
            <Lozenge appearance="default">Not set</Lozenge>
          ),
      })

    return calculationItems
  }, [rate])

  const calculationChargeableDetailRows = useMemo(() => {
    let calculationRowsItems = [
      {
        title: 'Calculation unit',
        content: findOption(calculationUnitOptions, parseFloat(rate?.each))?.label,
      },
      {
        title: 'Calculation on',
        content: findOption(calculationOnOptions, rate?.cubic_linear)?.label,
      },
    ]

    if ([0, 2].includes(rate?.cubic_linear)) {
      calculationRowsItems.push({
        title: 'CBM calculation value',
        content: rate?.one_cubic_meter ? (
          formatNumber(rate?.one_cubic_meter) + ' Kgs'
        ) : (
          <Lozenge appearance="default">Not set</Lozenge>
        ),
      })
    }

    if ([1, 2].includes(rate?.cubic_linear)) {
      calculationRowsItems.push({
        title: 'LDM calculation value',
        content: rate?.one_linear_meter ? (
          formatNumber(rate?.one_linear_meter) + ' Kgs'
        ) : (
          <Lozenge appearance="default">Not set</Lozenge>
        ),
      })
    }

    calculationRowsItems.push(
      {
        title: 'Rounding',
        content: findOption(roundingOptions, rate?.rounding)?.label,
      },
      {
        title: 'Different calculation for not-stackable goods',
        content: rate?.h_not_s ? (
          <Lozenge appearance="success">Yes</Lozenge>
        ) : (
          <Lozenge appearance="moved">No</Lozenge>
        ),
      }
    )

    if (rate?.h_not_s) {
      calculationRowsItems.push({
        title: 'Calculation on (for not-stackable)',
        content: findOption(calculationOnNotStackableOptions, rate?.h_not_s_c_l)?.label,
      })

      if (rate?.h_not_s_c_l === 0) {
        calculationRowsItems.push({
          title: 'Fixed height',
          content: rate?.one_cubic_meter_h ? (
            formatNumber(rate?.one_cubic_meter_h) + ' cm'
          ) : (
            <Lozenge appearance="default">Not set</Lozenge>
          ),
        })
      } else if (rate?.h_not_s_c_l === 1) {
        calculationRowsItems.push({
          title: 'LDM calculation value (for not-stackable)',
          content: rate?.one_linear_meter_h ? (
            formatNumber(rate?.one_linear_meter_h) + ' Kgs'
          ) : (
            <Lozenge appearance="default">Not set</Lozenge>
          ),
        })
      }
    }

    return calculationRowsItems
  }, [rate])

  const calculationFlatRateDetailRows = useMemo(
    () => [
      {
        title: 'Cost',
        content: <RateCostFormat value={rate?.cost} />,
      },
      {
        title: 'Quote',
        content: <RateCostFormat value={rate?.margin} />,
      },
    ],
    [rate]
  )

  return (
    <>
      <Card className="mb-6">
        <Grid className="w-full">
          <Grid.Col lg={6}>
            <RowTable rows={generalRows} width="w-1/2" />
          </Grid.Col>

          <Grid.Col lg={6}>
            <CardSubtitle className="pb-3 pt-2">Special conditions</CardSubtitle>
            <RowTable rows={specialConditionsRows} width="w-1/2" />
          </Grid.Col>
        </Grid>
      </Card>

      <Card title="Calculation" className="mb-6">
        <Grid grow className="w-full">
          <Grid.Col lg={6}>
            <RowTable rows={calculationRows} width="w-1/2" />
          </Grid.Col>

          {[0, 2].includes(rate?.calculation_method) && (
            <Grid.Col lg={6}>
              <CardSubtitle className="pb-3 pt-2">Calculation details</CardSubtitle>

              {rate?.calculation_method === 0 && (
                <RowTable rows={calculationChargeableDetailRows} width="w-1/2" />
              )}

              {rate?.type === 1 && <RowTable rows={calculationFlatRateDetailRows} width="w-1/2" />}
            </Grid.Col>
          )}
        </Grid>
      </Card>
    </>
  )
}

export default RateViewGeneralPage
