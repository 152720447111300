import { useMemo } from 'react'

// Atlassian
import { DropdownItem } from '@atlaskit/dropdown-menu'

// Interfaces
import { useRoyanTable } from '@royan-co/user-interface'

// Components
import DisabledElementTooltip from 'components/UI/DisabledElementTooltip'

const ElaborateInvoiceAction = ({ invoice, openElaborateModal }) => {
  const { refresh } = useRoyanTable()

  const accessabilityElaborating = useMemo(() => {
    if (invoice?.elaborated)
      return {
        access: false,
        message: 'Invoice Already elaborated.',
      }
    else if (!!refresh)
      return {
        access: true,
      }
    else if (+invoice?.unassigned_amount !== 0)
      return {
        access: false,
        message: 'Left to assign is not 0.',
      }
    else if (invoice?.arca_status !== 2)
      return {
        access: false,
        message: 'Invoice is not processed.',
      }
    else
      return {
        access: true,
      }
  }, [invoice, refresh])

  return invoice?.id_auto ? (
    <>
      <DisabledElementTooltip
        isDisabled={!accessabilityElaborating?.access}
        tooltipContent={accessabilityElaborating?.message}
      >
        <DropdownItem
          onClick={() => {
            openElaborateModal(invoice.id_auto)
          }}
          isDisabled={!accessabilityElaborating?.access}
        >
          Elaborate
        </DropdownItem>
      </DisabledElementTooltip>
    </>
  ) : null
}

export default ElaborateInvoiceAction
