import { useMutation } from '@tanstack/react-query'

// Contexts
import { useApp } from 'contexts/AppProvider'

// Apis
import { getConsolidationsExport } from './dashboard-requests'

export const useExportConsolidation = (type, params) => {
  const { setIsExportExcel } = useApp()

  return useMutation({
    mutationFn: () => getConsolidationsExport(type, params),
    onSuccess: () => {
      setIsExportExcel(true)
    },
  })
}
