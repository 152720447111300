import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'

// Apis
import { createContainer, editContainer } from 'api/shipments'

// Hooks
import useShipmentContainer, { useUpdateContainers } from 'hooks/useShipmentContainer'
import useShipment from 'hooks/useShipment'

// Utils
import { formatNumber } from 'utils/numbers'
import { handleErrorOnSubmit } from 'utils/errors'

// Atlassian
import Button from '@atlaskit/button'
import Spinner from '@atlaskit/spinner'
import SectionMessage from '@atlaskit/section-message'
import Lozenge from '@atlaskit/lozenge'
import { DynamicTableStateless } from '@atlaskit/dynamic-table'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'
import EmptyState from '@atlaskit/empty-state'

// Interfaces
import { DotsIcon, useDisclosure, useFlags } from '@royan-co/user-interface'

// Components
import Card, { CardTitle } from 'components/UI/Card'
import ShipmentViewContainerDrawer from 'components/Shipments/View/ContainerDrawer'
import ShipmentDeleteContainerModal from 'components/Shipments/View/DeleteContainerModal'

const tableHead = {
  cells: [
    { content: 'Number' },
    { content: 'Type' },
    { content: 'Owner' },
    { content: 'Total collies' },
    { content: 'Net weight' },
    { content: 'Gross weight' },
    { content: 'Actions' },
  ],
}

const ShipmentViewContainerPage = () => {
  const { shipmentId, quotationId } = useParams()
  const entityId = quotationId || shipmentId
  const { shipment } = useShipment(entityId)
  const { showSuccessFlag } = useFlags()
  const queryClient = useQueryClient()
  const [isOpenContainerDrawer, containerDrawerHandlers] = useDisclosure('container')
  const [isOpenDeleteModal, deleteModalHandlers] = useDisclosure('delete')

  const { data: containers, isLoading, isError, refetch } = useShipmentContainer(entityId)
  const { upsertContainer } = useUpdateContainers(entityId)
  const createContainerMutation = useMutation(
    containerDrawerHandlers?.data?.container_id
      ? editContainer.bind(null, containerDrawerHandlers.data.container_id)
      : createContainer.bind(null, entityId)
  )

  const tableRows = useMemo(() => {
    return containers?.map((item, i) => ({
      cells: [
        {
          content: item?.container_number || <Lozenge appearance="default">Not set</Lozenge>,
        },
        {
          content: item?.container_type || <Lozenge appearance="default">Not set</Lozenge>,
        },
        {
          content: item?.owner?.company_name || <Lozenge appearance="default">Not set</Lozenge>,
        },
        {
          content: formatNumber(item?.total_collies),
        },
        {
          content: (
            <span className="whitespace-nowrap">{formatNumber(item?.net_weight) + ' KGS'}</span>
          ),
        },
        {
          content: (
            <span className="whitespace-nowrap">{formatNumber(item?.gross_weight) + ' KGS'}</span>
          ),
        },
        {
          content: (
            <DropdownMenu
              placement="bottom-end"
              trigger={({ triggerRef, ...props }) => (
                <Button ref={triggerRef} appearance="subtle" children={<DotsIcon />} {...props} />
              )}
            >
              <DropdownItemGroup>
                <DropdownItem children="View" onClick={() => {}} />
                <DropdownItem
                  children="Edit"
                  onClick={() => {
                    const { owner, ...data } = item
                    containerDrawerHandlers.open({
                      ...data,
                      container_owner: owner
                        ? {
                            label: owner.company_name,
                            value: owner.id_auto,
                          }
                        : null,
                      container_id: item.id,
                    })
                  }}
                />
                <DropdownItem
                  children="Delete"
                  onClick={() => {
                    deleteModalHandlers.open(item.id)
                  }}
                />
              </DropdownItemGroup>
            </DropdownMenu>
          ),
        },
      ],
    }))
  }, [containerDrawerHandlers, containers, deleteModalHandlers])

  const closeCreationDrawer = () => {
    containerDrawerHandlers.close()
    createContainerMutation.reset()
  }

  const onCreationContainer = (data, { setError }) => {
    const formData = {
      ...data,
      container_type: data.container_type?.label,
      shipment_id_auto: entityId,
      container_owner: data.container_owner?.value || data.container_owner,
    }
    createContainerMutation.mutate(formData, {
      onSuccess: (res) => {
        upsertContainer(res.container)
        showSuccessFlag(res.message)
        queryClient.removeQueries({
          queryKey: ['shipment', 'cargo', shipmentId],
        })
        closeCreationDrawer()
      },
      onError: (e) => {
        console.error(e)
        handleErrorOnSubmit(e, setError, data)
      },
    })
  }

  const newContainerWeight = useMemo(() => {
    const weight = shipment.weight - containers?.reduce((p, c) => p + 1 * (c?.gross_weight || 0), 0)
    return weight > 0 ? weight : 0
  }, [containers, shipment.weight])

  return (
    <>
      {isLoading ? (
        <div className="text-center mt-24">
          <Spinner />
        </div>
      ) : isError ? (
        <SectionMessage appearance="warning" title="Something wrong on loading data, please retry.">
          <Button onClick={() => refetch()}>Retry</Button>
        </SectionMessage>
      ) : (
        <>
          <div className="flex items-center mb-3">
            <CardTitle className="mb-0">Containers</CardTitle>
            <Button
              appearance="link"
              className="ml-auto"
              onClick={() => {
                containerDrawerHandlers.open({
                  gross_weight: newContainerWeight,
                })
              }}
            >
              New container
            </Button>
          </div>

          <Card>
            <div className="overflow-hidden w-100">
              {tableRows?.length > 0 ? (
                <div className="overflow-auto">
                  <DynamicTableStateless head={tableHead} rows={tableRows} />
                </div>
              ) : (
                <EmptyState header="There are no containers." />
              )}
            </div>
          </Card>

          <ShipmentViewContainerDrawer
            isOpen={isOpenContainerDrawer}
            onClose={closeCreationDrawer}
            data={containerDrawerHandlers.data}
            onSubmit={onCreationContainer}
            isLoading={createContainerMutation.isLoading}
          />

          <ShipmentDeleteContainerModal
            isOpen={isOpenDeleteModal}
            onClose={deleteModalHandlers.close}
            containerId={deleteModalHandlers.data}
            shipmentId={entityId}
          />
        </>
      )}
    </>
  )
}

export default ShipmentViewContainerPage
