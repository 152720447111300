import Spinner from '@atlaskit/spinner'
import { findOption } from 'utils/helpers'
import useRegistriesCreators from 'hooks/useRegistryCreators'
import {
  registryTypeOptions,
  registryClientTypeOptions,
  registryAccountingArcaOptions,
  registryFreightForwarderOptions,
} from '../constants'

const CreatorFilter = ({ value }) => {
  const { isLoadingRegistryCreators, registryCreatorOptions } = useRegistriesCreators()

  return (
    <>
      Creator:{' '}
      {isLoadingRegistryCreators ? (
        <Spinner size="xsmall" />
      ) : (
        findOption(registryCreatorOptions, value)?.label
      )}
    </>
  )
}

const GroupFilter = ({ value }) => {
  const { isLoadingRegistryCreators, registryGroupOptions } = useRegistriesCreators()

  return (
    <>
      Group:{' '}
      {isLoadingRegistryCreators ? (
        <Spinner size="xsmall" />
      ) : (
        findOption(registryGroupOptions, value)?.label
      )}
    </>
  )
}

export const registriesFilters = [
  {
    key: 'numerator_code',
    content: (v) => `Code: ${v}`,
  },
  {
    key: 'type',
    content: (v) => `Type: ${findOption(registryTypeOptions, v)?.label}`,
  },
  {
    key: 'client_type',
    content: (v) => `Client type: ${findOption(registryClientTypeOptions, v)?.label}`,
  },
  {
    key: 'freight_forwarder',
    content: (v) => `Freight forwarder: ${findOption(registryFreightForwarderOptions, v)?.label}`,
  },
  {
    key: 'vat_number',
    content: (v) => `VAT no. ${v}`,
  },
  {
    key: 'accounting_arca',
    content: (v) =>
      `Imported in accounting: ${findOption(registryAccountingArcaOptions, v)?.label}`,
  },
  {
    key: 'contact',
    content: (v) => `Contact: ${v}`,
  },
  {
    key: 'country_iso',
    content: (v) => `Country: ${v}`,
  },
  {
    key: 'city',
    content: (v) => `City: ${v}`,
  },
  {
    key: 'cap',
    content: (v) => `Zipcode: ${v}`,
  },
  {
    key: 'telephone',
    content: (v) => `Telephone: ${v}`,
  },
  {
    key: 'website',
    content: (v) => `Website: ${v}`,
  },
  {
    key: 'created_from',
    content: (v) => `Created from: ${v}`,
  },
  {
    key: 'created_to',
    content: (v) => `Created to: ${v}`,
  },
  {
    key: 'user_id',
    content: (v) => <CreatorFilter value={v} />,
  },
  {
    key: 'group',
    content: (v) => <GroupFilter value={v} />,
  },
]
