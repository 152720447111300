import { useNavigate, useParams } from 'react-router-dom'

// Contexts
import { useAuth } from 'contexts/AuthProvider'

// Features
import { useCanAccess } from 'features/auth'

//Hooks
import useConsolidation from 'hooks/useConsolidation'
//Atlassian
import Button from '@atlaskit/button'
import { generateConsolidationUserPermissions } from '../Create/conslolidation-user-permissions'

const ConsolidationViewShipmentsAction = () => {
  const navigate = useNavigate()
  const { currentUser } = useAuth()
  const { consolidationId } = useParams()

  const { consolidation } = useConsolidation(consolidationId)

  const userPermissions = generateConsolidationUserPermissions(consolidation, currentUser)

  const canEdit = useCanAccess({
    resource: 'consolidations.shipment',
    action: 'edit',
    userPermissions,
  })

  return (
    <>
      {canEdit && (
        <Button
          appearance="primary"
          onClick={() => {
            navigate(`/panel/consolidations/${consolidationId}/edit/shipments`)
          }}
        >
          Edit shipments
        </Button>
      )}
    </>
  )
}

export default ConsolidationViewShipmentsAction
