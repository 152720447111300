import { Link } from 'react-router-dom'

// Interfaces
import { DotsIcon, Skeleton } from '@royan-co/user-interface'

// Atlassian
import Button from '@atlaskit/button'
import Lozenge from '@atlaskit/lozenge'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'

// Components
import CountryFlag from 'components/UI/CountryFlag'
import ConsolidationStatusTag from '../StatusTag'
import ShipmentFinancialTag from 'components/Shipments/FinancialTag'
import ConsolidationModeTag from '../ModaTag'
import TemperatureTag from 'components/Shipments/TemperatureTag'
import ConsolidationGPSTrackerTag from '../GPSTrackerTag'
import { generateConsolidationUserPermissions } from '../Create/conslolidation-user-permissions'
import DropdownLink from 'components/UI/DropdownLink'

// Features
import { AccessControl } from 'features/auth'

export const generateConsolidationsColumns = (currentUser, isWarehouseUser, openDeleteModal) => {
  return [
    {
      key: 'consolidation_code',
      title: 'Reference',
      isSortable: false,
      show: true,
      content: (item) => (
        <>
          {item.consolidation_code}
          {item?.creator?.registry_id !== currentUser.registry_id && (
            <div className="block">
              <Lozenge appearance="inprogress">By {item?.creator?.registry?.company_name}</Lozenge>
            </div>
          )}
        </>
      ),
    },
    {
      key: 'status',
      title: 'Status',
      isSortable: false,
      show: true,
      content: (item) => <ConsolidationStatusTag value={item.status} />,
    },
    {
      key: 'shipments_count',
      title: 'No. of shipments',
      isSortable: false,
      show: true,
      content: (item) => item.shipments_count,
    },
    {
      key: 'mode',
      title: 'Mode',
      isSortable: false,
      show: true,
      content: (item) => <ConsolidationModeTag item={item} />,
    },
    {
      key: 'arrival_country',
      title: 'Country of delivery',
      isSortable: false,
      show: true,
      content: (item) => <CountryFlag iso={item.arrival_country} />,
    },
    {
      key: 'carrier',
      title: 'Carrier',
      isSortable: false,
      show: true,
      content: (item) =>
        item.carrier_data ? (
          <a
            href={`/panel/registries/${item.carrier_data?.id_auto}`}
            target="_blank"
            rel="noreferrer"
          >
            {item.carrier_data?.company_name}
          </a>
        ) : (
          <Lozenge appearance="default">Not set</Lozenge>
        ),
    },
    {
      key: 'means_code',
      title: 'Transportation unit',
      isSortable: false,
      show: true,
      content: (item) => item.means_code,
    },
    {
      key: 'gps_tracker',
      title: 'GPS Tracker',
      isSortable: false,
      show: true,
      content: (item) => <ConsolidationGPSTrackerTag consolidation={item} />,
    },
    {
      key: 'creator_user',
      title: 'Creator',
      isSortable: false,
      show: true,
      content: (item) => item.creator?.full_name,
    },

    {
      key: 'hub_consolidator',
      title: 'Consolidator hub',
      isSortable: false,
      show: true,
      content: (item) =>
        item.hub ? item.hub?.name : <Lozenge appearance="default">Not set</Lozenge>,
    },
    {
      key: 'reefer',
      title: 'Temperature control',
      isSortable: false,
      show: true,
      content: (item) => (
        <TemperatureTag reefer={item.reefer} temperature={item.reefer_temperature} />
      ),
    },
    {
      key: 'creation_date',
      title: 'Creation date',
      isSortable: true,
      show: true,
      content: (item) => item?.creation_date,
    },
    {
      key: 'financial_status',
      title: 'Financial status',
      isSortable: false,
      show: true,
      showToWarehouseUser: false,
      content: (item) => (
        <ShipmentFinancialTag
          cost={item.financial_status?.cost}
          quotation={item.financial_status?.quotation}
          margin={item.financial_status?.margin}
        />
      ),
    },
    {
      key: 'actions',
      title: 'Actions',
      show: true,
      showToWarehouseUser: true,
      content: (item) => (
        <DropdownMenu
          placement="bottom-end"
          trigger={({ triggerRef, ...props }) => (
            <Button
              {...props}
              appearance="subtle"
              children={<DotsIcon label="more" />}
              ref={triggerRef}
            />
          )}
        >
          <DropdownItemGroup>
            <DropdownLink children="View" to={'/panel/consolidations/' + item.id_auto} />

            <AccessControl
              resource="consolidations"
              action="delete"
              userPermissions={generateConsolidationUserPermissions(item, currentUser)}
            >
              <DropdownItem children="Delete" onClick={() => openDeleteModal(item.id_auto)} />
            </AccessControl>
          </DropdownItemGroup>
        </DropdownMenu>
      ),
    },
  ].map((column) => ({
    ...column,
    content: (item) => {
      const isProtected = item?.protected_keys?.includes(column.key)

      return isProtected ? (
        <Skeleton height={16} className="w-100" animate={false} />
      ) : (
        column.content(item)
      )
    },
  }))
}
