import { useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

// Atlaskit
import PageHeader from '@atlaskit/page-header'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'
import { LoadingButton } from '@atlaskit/button'
import Button from '@atlaskit/button'
import Spinner from '@atlaskit/spinner'
import SectionMessage from '@atlaskit/section-message'

// API
import { createLine, editLine } from '../api/requests'

// Hooks
import { useLine } from '../hooks/useLine'

// Interfaces
import { Grid, Form, WarningBannerField, useFlags } from '@royan-co/user-interface'

// Utils
import { getFilled } from 'utils/helpers'
import { handleErrorOnSubmit } from 'utils/errors'

// Components
import LineCreateEditForm from '../components/Create/Form'

const LineCreateEditPage = () => {
  const { lineId } = useParams()
  const navigate = useNavigate()
  const { showSuccessFlag } = useFlags()

  const pageTitle = `${lineId ? 'Edit' : 'New'} line`

  const { data: line, isLoading, isError, refetch } = useLine(lineId, { enabled: !!lineId })

  const defaultValues = useMemo(() => {
    const values = {
      departure_country: getFilled(line, 'departure_country'),
      arrival_country: getFilled(line, 'arrival_country'),
    }

    return values
  }, [line])

  const lineMutation = useMutation(lineId ? editLine.bind(null, lineId) : createLine)

  const onSubmit = (formData, { setError }) => {
    lineMutation.mutate(formData, {
      onError: (e) => {
        handleErrorOnSubmit(e, setError, formData)
      },
      onSuccess: (res) => {
        showSuccessFlag(res?.message)
        navigate(`/panel/sales-machine/lines/${res?.line?.id}`)
      },
    })
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <Grid gutter={24} className="w-full">
        <Grid.Col md={10} lg={8} xl={6} offsetMd={1} offsetLg={2} offsetXl={3}>
          <PageHeader
            breadcrumbs={
              <Breadcrumbs>
                <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
                <BreadcrumbsItem text="Sales machine" />
                <BreadcrumbsItem text="Lines" to="/panel/sales-machine/lines" component={Link} />
              </Breadcrumbs>
            }
          >
            {pageTitle}
          </PageHeader>

          {isLoading && lineId ? (
            <div className="text-center mt-6">
              <Spinner />
            </div>
          ) : isError ? (
            <SectionMessage
              appearance="warning"
              title="Something wrong on loading data, please retry."
            >
              <Button onClick={() => refetch()}>Retry</Button>
            </SectionMessage>
          ) : (
            <div className="my-8">
              <Form onSubmit={onSubmit} defaultValues={defaultValues}>
                {() => (
                  <>
                    <LineCreateEditForm />

                    <WarningBannerField />

                    <LoadingButton
                      className="!table ml-auto mt-6"
                      appearance="primary"
                      type="submit"
                      isLoading={lineMutation.isLoading}
                    >
                      {lineId ? 'Edit' : 'Create'}
                    </LoadingButton>
                  </>
                )}
              </Form>
            </div>
          )}
        </Grid.Col>
      </Grid>
    </>
  )
}

export default LineCreateEditPage
