import { useMemo } from 'react'

// Utils
import { findOption } from 'utils/helpers'

// Hooks
import useAirportsAutocomplete from 'hooks/useAirportsAutocomplete'

// Atlassian
import Select from '@atlaskit/select'

// Interfaces
import { Field } from '@royan-co/user-interface'

const RatesAirportsField = ({ name, label, defaultValue, isDisabled = false, country,   isRequired = false, }) => {
  const {
    airportsAutocompleteOptions,
    setTermAirportsAutocomplete,
    isLoadingAirportsAutocomplete,
  } = useAirportsAutocomplete({ country })

  const options = useMemo(() => {
    if (!defaultValue) return airportsAutocompleteOptions

    return airportsAutocompleteOptions
      .filter((i) => i.value !== defaultValue)
      .concat([{ label: defaultValue, value: defaultValue }])
  }, [airportsAutocompleteOptions, defaultValue])

  return (
    <Field
      isRequired={isRequired}
      label={label}
      type="select"
      name={name}
      isDisabled={isDisabled}
      transform={{
        output: (opt) => (opt?.value !== undefined ? opt.value : null),
        input: (v) => (findOption(options, v) || v != null ? { value: v, label: v } : null),
      }}
    >
      {({ fieldProps }) => (
        <Select
          {...fieldProps}
          isClearable={true}
          value={fieldProps.value || null}
          placeholder="Choose a place"
          options={options}
          isLoading={isLoadingAirportsAutocomplete}
          onInputChange={(newValue) => setTermAirportsAutocomplete(newValue)}
        />
      )}
    </Field>
  )
}

export default RatesAirportsField
