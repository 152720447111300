export const registryTypeOptions = [
  { label: 'Client', value: 0, appearance: 'success' },
  { label: 'Supplier', value: 1, appearance: 'inprogress' },
]

export const registryClientTypeOptions = [
  { label: 'Prospect', value: 0 },
  { label: 'Direct', value: 1 },
]

export const registryAccountingArcaOptions = [
  { label: 'No', value: 0, appearance: 'moved' },
  { label: 'Yes', value: 1, appearance: 'success' },
]

export const registryFreightForwarderOptions = [
  { label: 'No', value: 0, appearance: 'default' },
  { label: 'Yes', value: 1, appearance: 'success' },
]

export const registryEntityTypeOptions = [
  { label: 'Company', value: 0, appearance: 'default' },
  { label: 'Individual', value: 1, appearance: 'default' },
]

export const registryClassificationOptions = [
  { label: 'Normal', value: 0, appearance: 'inprogress' },
  { label: 'Silver', value: 1, appearance: 'default' },
  { label: 'Gold', value: 2, appearance: 'moved' },
]

export const registryAddressTypeOptions = [
  { label: 'Headquarter', value: 0, appearance: 'success' },
  { label: 'Administrative', value: 1, appearance: 'inprogress' },
  { label: 'Operating', value: 2, appearance: 'moved' },
  { label: 'Commercial', value: 3, appearance: 'new' },
  { label: 'Warehouse', value: 4, appearance: 'default' },
]

export const registryReasonExportClearanceOptions = [
  { label: 'Already cleared', value: 0 },
  { label: 'By sender', value: 1 },
]

export const registryReasonImportClearanceOptions = [
  { label: 'Offloading on custom warehouse', value: 0 },
]
