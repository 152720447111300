import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

// Utils
import { handleErrorOnSubmit } from 'utils/errors'

// Apis
import { holdShipment } from 'api/shipments'

// Hooks
import useShipment from 'hooks/useShipment'
import { useCanAccess } from 'features/auth'
import { useAuth } from 'contexts/AuthProvider'

// Interfaces
import { EditIcon, CautionIcon, useDisclosure, useFlags } from '@royan-co/user-interface'

// Atlassian
import Lozenge from '@atlaskit/lozenge'
import Tooltip from '@atlaskit/tooltip'

// Components
import ShipmentHoldReleaseModal from './HoldReleaseModal'
import { generateShipmentUserPermissions } from '../Create/shipment-user-permissions'

const ShipmentHoldReleaseButton = () => {
  const { shipmentId } = useParams()
  const { showSuccessFlag } = useFlags()
  const { currentUser } = useAuth()
  const { shipment, upsertShipmentData } = useShipment(shipmentId)
  const [isOpenModal, modalHandler] = useDisclosure('status')

  const isHold = useMemo(() => shipment?.is_on_hold, [shipment])
  const holdingReason = useMemo(() => shipment?.holding_reasons.join('\n'), [shipment])
  const userPermissions = generateShipmentUserPermissions(shipment, currentUser)
  const canRelease = useCanAccess({
    resource: 'shipments',
    action: 'release',
    userPermissions,
  })
  const canHold = useCanAccess({
    resource: 'shipments',
    action: 'hold',
    userPermissions,
  })

  const holdReleaseMutation = useMutation(holdShipment.bind(null, shipmentId))

  const onSubmit = (data, { setError }) => {
    const formData = { ...data }
    if (!data.is_on_hold) delete formData.holding_reason

    holdReleaseMutation.mutate(formData, {
      onError: (e) => {
        handleErrorOnSubmit(e, setError, data)
        console.error(e)
      },
      onSuccess: (res) => {
        showSuccessFlag(res.message)
        upsertShipmentData(res.shipment)
        modalHandler.close()
      },
    })
  }

  const onClose = () => {
    holdReleaseMutation.reset()
    modalHandler?.close()
  }

  const isDisable = useMemo(
    () => (isHold && !canRelease) || (!isHold && !canHold),
    [canHold, canRelease, isHold]
  )

  return (
    <>
      <div className="flex gap-1 mt-1">
        {isHold ? (
          <>
            <Lozenge appearance="removed">On hold</Lozenge>
            <Tooltip content={<div className="whitespace-pre-wrap">{holdingReason}</div>}>
              {(tooltipProps) => (
                <span {...tooltipProps}>
                  <CautionIcon size={16} className={'text-neutral-500'} />
                </span>
              )}
            </Tooltip>
          </>
        ) : (
          <Lozenge appearance="success">Releasable</Lozenge>
        )}

        {!isDisable && (
          <button onClick={() => modalHandler.open()} disabled={isDisable}>
            <EditIcon size={18} className={'text-neutral-500'} />
          </button>
        )}
      </div>

      <ShipmentHoldReleaseModal
        isOpen={isOpenModal}
        onClose={onClose}
        data={{
          is_on_hold: isHold ? 1 : 0,
          holding_reason: shipment?.holding_reason,
        }}
        onSubmit={onSubmit}
        isLoading={holdReleaseMutation.isLoading}
      />
    </>
  )
}

export default ShipmentHoldReleaseButton
