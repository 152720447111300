//Icons
import { TruckIcon, PackageIcon, TruckLowIcon, AirplaneTakeOffIcon } from '@royan-co/user-interface'

export const modes = [
  {
    id: 1,
    shipmentType: 2,
    typeOfCollies: 0,
    title: 'Air Freight',
    service: 'Air Freight',
    description: 'Service',
    icon: AirplaneTakeOffIcon,
  },
  {
    id: 2,
    typeOfCollies: 1,
    title: 'Dedicated',
    service: 'Dedicated',
    description: 'Truck/Container',
    icon: TruckIcon,
  },
  {
    id: 3,
    shipmentType: 3,
    typeOfCollies: 1,
    title: 'Intermodal',
    service: 'Intermodal',
    description: 'Service',
    icon: TruckIcon,
  },
  {
    id: 4,
    shipmentType: 0,
    typeOfCollies: 0,
    title: 'Groupage',
    service: 'Groupage service',
    description: 'Land',
    icon: TruckLowIcon,
  },
  {
    id: 5,
    shipmentType: 1,
    typeOfCollies: 0,
    title: 'Groupage',
    service: 'Groupage service',
    description: 'Sea',
    icon: PackageIcon,
  },
]
