//Interfaces
import {
  DocIcon,
  DocsIcon,
  TrayIcon,
  CommentIcon,
  FrequentIcon,
  ComponentIcon,
  ListIcon,
  RoadMapIcon,
  LocationIcon,
  ShareIcon,
  BillingOutlineIcon,
} from '@royan-co/user-interface'

//Components
import ConsolidationViewCargoActions from 'components/Consolidations/View/CargoActions'
import ConsolidationEditGeneralInformationAction from 'components/Consolidations/View/EditGeneralInformationAction'
import ConsolidationViewNoteNotification from 'components/Consolidations/View/NoteNotification'
import ConsolidationViewTrackingAction from 'components/Consolidations/View/TrackingAction'
import ConsolidationViewSharesActions from 'components/Consolidations/View/SharesActions'
import ConsolidationViewShipmentsAction from 'components/Consolidations/View/ShipmentsAction'
import ConsolidationViewTrackingNotification from 'components/Consolidations/View/TrackingNotification'

const consolidationViewPages = [
  {
    icon: TrayIcon,
    title: 'General Information',
    pathSuffix: '',
    actions: ConsolidationEditGeneralInformationAction,
  },
  {
    icon: ListIcon,
    title: 'Shipments',
    pathSuffix: 'shipments',
    actions: ConsolidationViewShipmentsAction,
  },
  {
    icon: DocIcon,
    title: 'Air waybills',
    pathSuffix: 'awb',
    showCondition: (consolidation) => consolidation?.shipment_type === 2,
  },
  {
    icon: ComponentIcon,
    title: 'Cargo Details',
    pathSuffix: 'cargo-details',
    actions: ConsolidationViewCargoActions,
  },
  {
    icon: BillingOutlineIcon,
    title: 'Finance',
    pathSuffix: 'finance',
    limitationAccess: { resource: 'consolidations.finance', action: 'view' },
  },
  {
    icon: LocationIcon,
    title: 'Tracking',
    pathSuffix: 'tracking',
    actions: ConsolidationViewTrackingAction,
    notification: ConsolidationViewTrackingNotification,
  },
  {
    icon: DocsIcon,
    title: 'Documents',
    pathSuffix: 'documents',
  },
  {
    icon: FrequentIcon,
    title: 'History',
    pathSuffix: 'history',
  },
  {
    icon: CommentIcon,
    title: 'Notes',
    pathSuffix: 'notes',
    notification: ConsolidationViewNoteNotification,
  },

  {
    icon: ShareIcon,
    title: 'Shares',
    pathSuffix: 'shares',
    actions: ConsolidationViewSharesActions,
    limitationAccess: { resource: 'consolidations.share', action: 'view' },
  },
  {
    icon: RoadMapIcon,
    title: 'Actions',
    pathSuffix: 'actions',
  },
]

const getConsolidationViewPages = (consolidationId, consolidation) => {
  return consolidationViewPages.map((i) => ({
    ...i,
    path: `/panel/consolidations/${consolidationId}/${i.pathSuffix}`,
    show: typeof i.showCondition === 'function' ? i.showCondition(consolidation) : true,
  }))
}

export { getConsolidationViewPages }
