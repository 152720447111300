// Utils
import { findOption } from 'utils/helpers'
import { bookedOptions, usedOptions } from 'features/AirWaybillStock/utils/constants'

export const tableFilters = [
  {
    key: 'book',
    content: (v) => `Booked: ${findOption(bookedOptions, v)?.label}`,
  },
  {
    key: 'used',
    content: (v) => `Used: ${findOption(usedOptions, v)?.label}`,
  },
]
