import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

// Apis
import { createDocument } from 'api/consolidations'

// Constants
import { documentTypeOptions } from '../constants'

// Utils
import { handleErrorOnSubmit } from 'utils/errors'
import { transformSelectField } from 'utils/helpers'

// Atlassian
import { ErrorMessage } from '@atlaskit/form'
import Button, { LoadingButton } from '@atlaskit/button'
import Modal, {
  ModalTitle,
  ModalHeader,
  ModalFooter,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import Select from '@atlaskit/select'
import Toggle from '@atlaskit/toggle'

// Interfaces
import { Field, Form, WarningBannerField, useFlags } from '@royan-co/user-interface'

const ConsolidationCreateDocumentModal = ({ isOpen, onClose, consolidationId }) => {
  const { showSuccessFlag } = useFlags()
  const navigate = useNavigate()

  const documentCreationMutation = useMutation(createDocument.bind(null, consolidationId))

  const getDocumentKey = (documentValue) =>
    documentTypeOptions?.find((d) => d.value === documentValue)?.key

  const handleSubmit = (data, { setError }) => {
    const formData = {
      ...data,
      consolidation_id: consolidationId,
    }
    documentCreationMutation.mutate(formData, {
      onError: (e) => {
        handleErrorOnSubmit(e, setError, data)
        console.error(e)
      },
      onSuccess: (res) => {
        showSuccessFlag(res.message)
        navigate(
          `/panel/consolidations/document/${getDocumentKey(data.document)}/${res?.document?.id}${
            data?.create_blank ? `/${'blank'}` : ''
          }`
        )
      },
    })
  }

  const handleCloseModal = () => {
    onClose()
    documentCreationMutation.reset()
  }

  return (
    <>
      <ModalTransition>
        {isOpen && (
          <Modal width={'small'} onClose={handleCloseModal}>
            <Form onSubmit={handleSubmit}>
              {({ watch }) => (
                <>
                  <ModalHeader>
                    <ModalTitle>Create document</ModalTitle>
                  </ModalHeader>

                  <div className="px-6">
                    <Field
                      isRequired
                      label="Document"
                      type="select"
                      name="document"
                      transform={transformSelectField(documentTypeOptions)}
                    >
                      {({ fieldProps, error }) => (
                        <>
                          <Select
                            options={documentTypeOptions}
                            placeholder="Choose a document"
                            {...fieldProps}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>

                    <Field
                      name="create_blank"
                      label="Create blank"
                      type="checkbox"
                      key="toggle-create_blank"
                      className="!mt-4"
                    >
                      {({ fieldProps, error }) => (
                        <>
                          <div>
                            <Toggle {...fieldProps} id="toggle-create_blank" />
                          </div>
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>

                    <WarningBannerField />
                  </div>

                  <ModalFooter>
                    <Button appearance="subtle" onClick={handleCloseModal}>
                      Discard
                    </Button>

                    <LoadingButton
                      type="submit"
                      appearance="primary"
                      isLoading={documentCreationMutation.isLoading}
                      autoFocus
                    >
                      Create
                    </LoadingButton>
                  </ModalFooter>
                </>
              )}
            </Form>
          </Modal>
        )}
      </ModalTransition>
    </>
  )
}

export default ConsolidationCreateDocumentModal
