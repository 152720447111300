import { findOption } from 'utils/helpers'
import { shipmentClearanceOptions } from './constants'

//Atlassian
import Lozenge from '@atlaskit/lozenge'

const ClearanceTag = ({ value, reasonId }) => {
  const option = findOption(shipmentClearanceOptions, value)

  return <Lozenge appearance={option.appearance}>{option.label}</Lozenge>
}

export default ClearanceTag
