import React from 'react'
import { useParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

import { deleteRegistryContact } from 'api/registries'

//Hooks
import useRegistry from 'hooks/useRegistry'

//Atlassian
import Modal, {
  ModalBody,
  ModalTitle,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import Button, { LoadingButton } from '@atlaskit/button'

//Interfaces
import { useFlags, WarningBanner } from '@royan-co/user-interface'

const RegistryDeleteContactModal = ({ contact, isOpen, onClose }) => {
  const { showSuccessFlag, showWarningFlag } = useFlags()
  const { registryId } = useParams()
  const { deleteContactFromData } = useRegistry(registryId)

  const deleteRegistryContactMutation = useMutation(deleteRegistryContact)

  const handleDeleteContact = () => {
    deleteRegistryContactMutation.mutate(contact.id_auto, {
      onError: (e) => {
        showWarningFlag('Something wrong on remove contact.')
        console.error(e)
      },
      onSuccess: (res) => {
        showSuccessFlag(res.message)
        deleteContactFromData(contact.id_auto)
        onClose()
      },
    })
  }

  const closeModal = () => {
    deleteRegistryContactMutation.reset()
    onClose()
  }

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={closeModal}>
          <ModalHeader>
            <ModalTitle appearance="danger">Delete contact</ModalTitle>
          </ModalHeader>

          <ModalBody>
            You are about to delete a contact. Are you sure?
            {deleteRegistryContactMutation.isError && <WarningBanner />}
          </ModalBody>

          <ModalFooter>
            <Button appearance="subtle" onClick={closeModal}>
              Cancel
            </Button>
            <LoadingButton
              isLoading={deleteRegistryContactMutation.isLoading}
              appearance="danger"
              onClick={handleDeleteContact}
              autoFocus
            >
              Delete
            </LoadingButton>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  )
}

export default RegistryDeleteContactModal
