import { useQuery } from '@tanstack/react-query'

// Apis
import { getAirport } from 'api/shipments'

const useAirport = (iata, { enabled = true } = {}) => {
  return useQuery(['airport', iata], () => getAirport(iata), {
    staleTime: Infinity,
    retry: 2,
    enabled,
  })
}

export default useAirport
