import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

// Apis
import { removeShareShipment } from 'api/shipments'

// Hooks
import useSharesShipment from 'hooks/useSharesShipment'
import useShipment from 'hooks/useShipment'

// Contexts
import { useAuth } from 'contexts/AuthProvider'

// Features
import { AccessControl } from 'features/auth'

//Atlassian
import Button from '@atlaskit/button'
import Spinner from '@atlaskit/spinner'
import EmptyState from '@atlaskit/empty-state'
import SectionMessage from '@atlaskit/section-message'
import Lozenge from '@atlaskit/lozenge'
import { DynamicTableStateless } from '@atlaskit/dynamic-table'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'

// Interfaces
import { DotsIcon, useDisclosure, useFlags } from '@royan-co/user-interface'

//Components
import Card from 'components/UI/Card'
import RemoveSharingModal from 'components/Shipments/View/RemoveSharingModal'
import CountryFlag from 'components/UI/CountryFlag'
import { generateShipmentUserPermissions } from 'components/Shipments/Create/shipment-user-permissions'

const ShipmentViewSharesPage = () => {
  const { shipmentId } = useParams()
  const { shipment } = useShipment()
  const { currentUser } = useAuth()
  const { showSuccessFlag } = useFlags()

  const [isOpenRemoveSharingModal, removeSharingModalHandler] = useDisclosure('remove-sharing')

  const { sharesList, isError, refetch, isLoading, deleteSharingFromData } =
    useSharesShipment(shipmentId)

  const removeShareMutation = useMutation((params) =>
    removeShareShipment(params.shipmentId, params.registryId, params.sharedRegistryId)
  )

  const handleRemoveSharing = () => {
    removeShareMutation.mutate(
      {
        shipmentId: shipmentId,
        registryId: removeSharingModalHandler?.data?.shared_to?.id_auto,
        sharedRegistryId: removeSharingModalHandler?.data?.shared_to_address?.id_auto,
      },
      {
        onSuccess: (res) => {
          deleteSharingFromData(removeSharingModalHandler?.data)
          showSuccessFlag(res.message)
          removeSharingModalHandler?.close()
        },
      }
    )
  }

  const onCloseModal = () => {
    removeShareMutation.reset()
    removeSharingModalHandler?.close()
  }

  const heads = useMemo(
    () => ({
      cells: [
        { key: 'shared_with', content: 'Shared with' },
        { key: 'branch', content: 'Branch' },
        { key: 'documents', content: 'Documents' },
        { key: 'accounting', content: 'Accounting' },
        {
          key: 'date',
          content: 'Shared at',
        },
        { key: 'actions', content: 'Actions' },
      ],
    }),
    []
  )

  const rows = useMemo(
    () =>
      sharesList?.map((item) => ({
        key: item.id_auto,
        cells: [
          {
            key: 'shared_with',
            content: (
              <>
                {item?.shared_to?.company_name}
                <div>
                  <Lozenge appearance="inprogress">
                    {item?.relation === 'warehouse' ? 'Warehouse' : 'Agent'}
                  </Lozenge>
                </div>
              </>
            ),
          },
          {
            key: 'branch',
            content: (
              <>
                <CountryFlag iso={item?.shared_to_address?.country_iso} />
                {item?.shared_to_address?.country_iso && item?.shared_to_address?.city && (
                  <span>, </span>
                )}
                {item?.shared_to_address?.city}
              </>
            ),
          },
          {
            key: 'documents',
            content: item?.document ? (
              <Lozenge appearance="success">Shared</Lozenge>
            ) : (
              <Lozenge appearance="moved">Not shared</Lozenge>
            ),
          },
          {
            key: 'accounting',
            content: item?.costs ? (
              <Lozenge appearance="success">Shared</Lozenge>
            ) : (
              <Lozenge appearance="moved">Not shared</Lozenge>
            ),
          },
          {
            key: 'date',
            content: item?.share_date || <Lozenge>Not set</Lozenge>,
          },
          {
            key: 'actions',
            content: (
              <DropdownMenu
                placement="bottom-end"
                trigger={({ triggerRef, ...props }) => (
                  <Button
                    {...props}
                    appearance="subtle"
                    children={<DotsIcon label="more" />}
                    ref={triggerRef}
                  />
                )}
              >
                <DropdownItemGroup>
                  <DropdownItem
                    children="Remove"
                    onClick={() => removeSharingModalHandler.open(item)}
                  />
                </DropdownItemGroup>
              </DropdownMenu>
            ),
          },
        ],
      })),
    [sharesList, removeSharingModalHandler]
  )

  return (
    <AccessControl
      resource="shipments.share"
      action="view"
      userPermissions={generateShipmentUserPermissions(shipment, currentUser)}
    >
      {isLoading ? (
        <div className="text-center mt-24">
          <Spinner />
        </div>
      ) : isError ? (
        <SectionMessage appearance="warning" title="Something wrong on loading data, please retry.">
          <Button onClick={() => refetch()}>Retry</Button>
        </SectionMessage>
      ) : (
        <>
          <Card className="mt-0">
            {rows?.length < 1 ? (
              <EmptyState header="No shares" />
            ) : (
              <div className="overflow-auto inline-block w-full -mb-6">
                <DynamicTableStateless rows={rows} head={heads} />
              </div>
            )}
          </Card>

          <RemoveSharingModal
            isOpen={isOpenRemoveSharingModal}
            onClose={onCloseModal}
            onSubmit={handleRemoveSharing}
            isLoading={removeShareMutation.isLoading}
            isError={removeShareMutation.isError}
          />
        </>
      )}
    </AccessControl>
  )
}

export default ShipmentViewSharesPage
