import { useEffect, useMemo } from 'react'

// Hooks
import useRegistryAddress from 'hooks/useRegistryAddress'
import useUserLevels from 'hooks/useUserLevels'
import useRegistryContact from 'hooks/useRgeistryContact'

// Utils
import { transformSelectField } from 'utils/helpers'
import { protocolOptions } from 'features/users/utils/constants'
import { codes } from 'utils/CountryCodes'

// Atlassian
import Select from '@atlaskit/select'
import { ErrorMessage, HelperMessage } from '@atlaskit/form'
import TextField from '@atlaskit/textfield'
import Toggle from '@atlaskit/toggle'

// Interfaces
import { Field, PasswordField, PhoneInput, useFormContext } from '@royan-co/user-interface'

// Components
import RegistrySelect from 'components/Registries/RegistrySelect'
import Card from 'components/UI/Card'
import CreatorContactSelect from 'components/Shipments/View/CreatorContactSelect'
import Divider from 'components/UI/Divider'

const UserCreationForm = ({ isEditing }) => {
  const { watch, setValue } = useFormContext()
  const registry = watch('registry_id')
  const address = watch('registry_address_id')
  const contact = watch('contact')

  const { userLevelsOptions, isLoadingUserLevels } = useUserLevels()
  const { registryAddressOptions, getAddressCountryIso, isLoadingRegistryAddress } =
    useRegistryAddress(registry?.value)
  const { contacts, isLoadingRegistryContact } = useRegistryContact(address?.value)

  const contactsOptions = useMemo(
    () =>
      contacts?.flatMap(({ whatapp, tracking, ...c }) =>
        !c.has_user
          ? {
              ...c,
              label: c.full_name,
              value: c.id_auto,
            }
          : []
      ) || [],
    [contacts]
  )

  useEffect(() => {
    if (!isEditing) {
      if (contact) {
        const prefix =
          contact?.cellphone_prefix || `${+codes[getAddressCountryIso(address?.value)]?.code || ''}`

        setValue('email', contact.email || '')
        setValue('cellphone', {
          rawPhone: contact?.cellphone,
          dialCode: prefix,
          value: [prefix, contact?.cellphone].join(''),
        })
        setValue('user_level_name_id', contact.user_level_name_id || null)
      } else {
        setValue('email', '')
        setValue('cellphone', '')
        setValue('user_level_name_id', null)
      }
    }
  }, [address, contact, getAddressCountryIso, isEditing, setValue])

  return (
    <Card className="mt-0">
      {isEditing ? (
        <div className="grid md:grid-cols-2 md:gap-4">
          <Field label="Name" name="name" defaultValue="" className="!mt-4">
            {({ fieldProps, error }) => (
              <>
                <TextField placeholder="Type the name" {...fieldProps} />
                {error && <ErrorMessage>{error}</ErrorMessage>}
              </>
            )}
          </Field>

          <Field label="Surname" name="surname" defaultValue="" className="!mt-4">
            {({ fieldProps, error }) => (
              <>
                <TextField placeholder="Type the surname" {...fieldProps} />
                {error && <ErrorMessage>{error}</ErrorMessage>}
              </>
            )}
          </Field>
        </div>
      ) : (
        <>
          <Field
            isRequired
            label="Registry"
            type="select"
            className="!mt-0"
            name="registry_id"
            onValueChange={() => {
              setValue('registry_address_id', null)
              setValue('contact', null)
            }}
          >
            {({ fieldProps, error }) => (
              <>
                <RegistrySelect {...fieldProps} />
                {error && <ErrorMessage>{error}</ErrorMessage>}
              </>
            )}
          </Field>

          {registry && (
            <Field
              isRequired
              label="Address"
              type="select"
              className="!mt-4"
              name="registry_address_id"
              onValueChange={() => setValue('contact', null)}
            >
              {({ fieldProps, error }) => (
                <>
                  <Select
                    placeholder="Choose"
                    isClearable
                    options={registryAddressOptions}
                    isLoading={isLoadingRegistryAddress}
                    {...fieldProps}
                  />

                  {error && <ErrorMessage>{error}</ErrorMessage>}
                </>
              )}
            </Field>
          )}

          {address && (
            <Field isRequired label="Full name" type="select" className="!mt-4" name="contact">
              {({ fieldProps, error }) => (
                <>
                  <CreatorContactSelect
                    options={contactsOptions}
                    isLoading={isLoadingRegistryContact}
                    {...fieldProps}
                  />

                  {error && <ErrorMessage>{error}</ErrorMessage>}
                </>
              )}
            </Field>
          )}
        </>
      )}

      {(contact || isEditing) && (
        <>
          <Field isRequired label="Username" name="username" defaultValue="" className="!mt-4">
            {({ fieldProps, error }) => (
              <>
                <TextField
                  placeholder="Type the username"
                  autoComplete="new-password"
                  {...fieldProps}
                />
                {error && <ErrorMessage>{error}</ErrorMessage>}
              </>
            )}
          </Field>

          <Field
            isRequired
            label="Role"
            type="select"
            name="user_level_name_id"
            transform={transformSelectField(userLevelsOptions)}
            className="!mt-4"
          >
            {({ fieldProps, error }) => (
              <>
                <Select
                  placeholder="Choose one"
                  options={userLevelsOptions}
                  isLoading={isLoadingUserLevels}
                  {...fieldProps}
                />
                {error && <ErrorMessage>{error}</ErrorMessage>}
              </>
            )}
          </Field>

          <Field isRequired label="Email" name="email" defaultValue="" className="!mt-4">
            {({ fieldProps, error }) => (
              <>
                <TextField placeholder="Type the email" {...fieldProps} />{' '}
                {error && <ErrorMessage>{error}</ErrorMessage>}
              </>
            )}
          </Field>

          <Field label="Phone" name="cellphone" className="!mt-4">
            {({ fieldProps: { ref, ...fieldProps }, error }) => (
              <>
                <PhoneInput {...fieldProps} />
                {error && <ErrorMessage>{error}</ErrorMessage>}
              </>
            )}
          </Field>

          <PasswordField
            className="!mt-4"
            name="password"
            label="Password"
            isRequired={!isEditing}
            withValidation
          />
          {isEditing && (
            <HelperMessage>
              Enter a new password if you want to change the existing one.
            </HelperMessage>
          )}

          <Divider className="mt-8 mb-2" />

          <div className="grid md:grid-cols-2 md:gap-4">
            <Field label="Email host" name="email_host" defaultValue="" className="!mt-4">
              {({ fieldProps, error }) => (
                <>
                  <TextField placeholder="Type the email host" {...fieldProps} />
                  {error && <ErrorMessage>{error}</ErrorMessage>}
                </>
              )}
            </Field>

            <Field label="Email password" name="email_password" defaultValue="" className="!mt-4">
              {({ fieldProps, error }) => (
                <>
                  <TextField placeholder="Type the email password" {...fieldProps} />
                  {error && <ErrorMessage>{error}</ErrorMessage>}
                </>
              )}
            </Field>
          </div>

          <Field
            name="smtp_auth"
            label="SMTP authentication"
            type="checkbox"
            key="smtp_auth"
            className="!mt-4"
          >
            {({ fieldProps, error }) => (
              <>
                <div>
                  <Toggle {...fieldProps} id="smtp_auth" />
                </div>
                {error && <ErrorMessage>{error}</ErrorMessage>}
              </>
            )}
          </Field>

          <Field
            label="SMTP protocol"
            type="select"
            name="smtp_secure"
            transform={transformSelectField(protocolOptions)}
            className="!mt-4"
          >
            {({ fieldProps, error }) => (
              <>
                <Select placeholder="Choose one" options={protocolOptions} {...fieldProps} />
                {error && <ErrorMessage>{error}</ErrorMessage>}
              </>
            )}
          </Field>

          <div className="grid md:grid-cols-2 md:gap-4">
            <Field label="IMAP path" name="imap_path" defaultValue="" className="!mt-4">
              {({ fieldProps, error }) => (
                <>
                  <TextField placeholder="Type the IMAP path" {...fieldProps} />
                  {error && <ErrorMessage>{error}</ErrorMessage>}
                </>
              )}
            </Field>

            <Field label="IMAP port" name="imap_port" defaultValue="" className="!mt-4">
              {({ fieldProps, error }) => (
                <>
                  <TextField type="number" placeholder="Type the IMAP port" {...fieldProps} />
                  {error && <ErrorMessage>{error}</ErrorMessage>}
                </>
              )}
            </Field>
          </div>

          <Field
            label="IMAP protocol"
            type="select"
            name="imap_secure"
            transform={transformSelectField(protocolOptions)}
            className="!mt-4"
          >
            {({ fieldProps, error }) => (
              <>
                <Select placeholder="Choose one" options={protocolOptions} {...fieldProps} />
                {error && <ErrorMessage>{error}</ErrorMessage>}
              </>
            )}
          </Field>
        </>
      )}
    </Card>
  )
}

export default UserCreationForm
