import { getFilled } from 'utils/helpers'

export const getFBLDefaultValues = (fbl, currentUser) => {
  let defaultValues = {
    notNegotiable: true,
    number: getFilled(fbl, 'shipment.reference', ''),
    consignor: getFilled(fbl, 'shipper_text', ''),
    consigned: getFilled(fbl, 'consignee_text', ''),
    notifyAddress: getFilled(fbl, 'notify_text', ''),
    por: getFilled(fbl, 'receipt_place', ''),
    pol: getFilled(fbl, 'port_loading', ''),
    pod: getFilled(fbl, 'delivery_place', ''),
    podis: getFilled(fbl, 'port_discharge', ''),
    vessel: getFilled(fbl, 'ocean_vessel', ''),
    description:
      (fbl?.agent_destination_id === fbl?.shipment?.agent_destination_id
        ? 'Representative agent to destination:'
        : getFilled(fbl, 'agent.company_name', '')) +
      '\n' +
      getFilled(fbl, 'agent_destination_text', ''),
    description2: '',
    declarationConsignor: 'XXXXXXXXXXXXXXXXX',
    declaredValorem: 'XXXXXXXXXXXXXXXXX',
    freightAmount: getFilled(fbl, 'freight_amount', ''),
    freightPayableAt: getFilled(fbl, 'freight_payable', ''),
    placeAndDateOfIssue:
      getFilled(fbl, 'compiled_at', '') + ' ' + getFilled(fbl, 'compiled_date', ''),
    noOfOriginals: getFilled(fbl, 'number_of_original', ''),
    signature: '',
    applyTo: getFilled(fbl, 'agent_destination_text', ''),
    notCovered: false,
    covered: false,
    packages: [
      {
        number: getFilled(fbl, 'marks_and_number', ''),
        packages: getFilled(fbl, 'collies_number', ''),
        goods: getFilled(fbl, 'description', ''),
        gross: getFilled(fbl, 'weight', ''),
        measurement: getFilled(fbl, 'measurement', ''),
      },
    ],
  }

  return defaultValues
}
