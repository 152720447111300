import { z } from 'zod'
import { legTypes } from './legTypes'
import { calculationMethodOptions } from 'components/Rates/constants'

export const validateSchema = (data, getServiceCodeOptions) => {
  // Define the validation schema
  const tableFieldSchema = z.object({
    name: z.string().nonempty(),
    quantity: z
      .union([z.string(), z.number()])
      .transform((x) => (!x?.trim?.() && typeof x !== 'number' ? NaN : Number(x)))
      .pipe(z.number()),
    rate: z
      .union([z.string(), z.number()])
      .transform((x) => (!x?.trim?.() && typeof x !== 'number' ? NaN : Number(x)))
      .pipe(z.number()),
    calculation_method: z
      .number()
      .refine((val) => calculationMethodOptions.map((o) => o.value).includes(val), {
        message: '',
      }),
    cost: z
      .union([z.string(), z.number()])
      .transform((x) => (!x?.trim?.() && typeof x !== 'number' ? NaN : Number(x)))
      .pipe(z.number()),
    quotation: z
      .union([z.string(), z.number()])
      .transform((x) => (!x?.trim?.() && typeof x !== 'number' ? NaN : Number(x)))
      .pipe(z.number()),
    vat: z.nullable(z.unknown()),
  })

  const accessorySchema = z.object({
    service_code: z
      .string()
      .refine((val) => getServiceCodeOptions.map((o) => o.value).includes(val), {
        message: '',
      }),
    ...tableFieldSchema.shape,
  })

  const surchargeSchema = z.object({
    ...tableFieldSchema.shape,
  })

  const supplierSchema = z.object({
    supplier: z.number(),
    service_code: z
      .string()
      .refine((val) => getServiceCodeOptions.map((o) => o.value).includes(val), {
        message: '',
      }),
    ...tableFieldSchema.shape,
    surcharges: z.array(surchargeSchema).optional(),
  })

  const rowSchema = z.object({
    accessories: z.array(accessorySchema),
    suppliers: z.array(supplierSchema),
    fromTo: z
      .object({
        leg: z.number(),
        departure_hub: z.number().optional(),
        arrival_hub: z.number().optional(),
        departure_airport: z.string().optional(),
        arrival_airport: z.string().optional(),
        departure_port: z.number().optional(),
        arrival_port: z.number().optional(),
        departure_station: z.number().optional(),
        arrival_station: z.number().optional(),
        departure_zone: z
          .object({
            city: z.any().optional(),
            countryAbbr: z.any().optional(),
          })
          .optional(),
        arrival_zone: z
          .object({
            city: z.any().optional(),
            countryAbbr: z.any().optional(),
          })
          .optional(),
      })
      .partial()
      .superRefine((props, ctx) => {
        const type = legTypes.find((t) => t.value === props.leg)
        const departureKey = type.departure.departureKey
        const arrivalKey = type.arrival.arrivalKey

        if (departureKey === 'departure_zone') {
          if (!props.departure_zone?.city || !props.departure_zone?.countryAbbr) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              fatal: true,
              path: ['departure_zone'],
            })
          }
        } else {
          if (!props[departureKey]) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              fatal: true,
              path: [departureKey],
            })
          }
        }

        if (arrivalKey === 'arrival_zone') {
          if (!props.arrival_zone?.city || !props.arrival_zone?.countryAbbr) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              fatal: true,
              path: ['arrival_zone'],
            })
          }
        } else {
          if (!props[arrivalKey]) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              fatal: true,
              path: [arrivalKey],
            })
          }
        }
      }),
  })

  const dataSchema = z.object({
    rows: z.array(rowSchema),
  })

  return dataSchema
}
