import { Link, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

//Api
import { getBanks } from '../api/banks-requests'

//Atlassian
import PageHeader from '@atlaskit/page-header'
import Button, { ButtonGroup } from '@atlaskit/button'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'

//Contexts
import { useAuth } from 'contexts/AuthProvider'

//Interfaces
import { RoyanTable, useDisclosure } from '@royan-co/user-interface'

//Components
import { generateBanksColumns } from '../components/List/columns'
import PaymentTermDeleteModal from '../components/views/DeleteModal'

const pageTitle = 'Banks'

const BanksListPage = () => {
  const navigate = useNavigate()
  const { currentUser } = useAuth()

  const [isOpenDeleteBankModal, deleteBankModalHandler] = useDisclosure('delete')

  const openDeleteModal = (bankId) => {
    deleteBankModalHandler.open({ bankId })
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <PageHeader
        breadcrumbs={
          <Breadcrumbs>
            <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
            <BreadcrumbsItem text="Accounting" />
          </Breadcrumbs>
        }
        actions={
          <ButtonGroup>
            <Button appearance="primary" onClick={() => navigate('/panel/banks/create')}>
              New bank
            </Button>
          </ButtonGroup>
        }
      >
        {pageTitle}
      </PageHeader>

      <RoyanTable
        sortOrder="DESC"
        name="banks-list"
        sortKey="code"
        request={getBanks}
        filters={[]}
        columns={generateBanksColumns(openDeleteModal, currentUser)}
      >
        <PaymentTermDeleteModal
          isOpen={isOpenDeleteBankModal}
          onClose={deleteBankModalHandler.close}
          bankId={deleteBankModalHandler.data?.bankId}
        />
      </RoyanTable>
    </>
  )
}

export default BanksListPage
