import { useMemo, useEffect } from 'react'

//Atlassian
import { ErrorMessage } from '@atlaskit/form'
import { IconButton } from '@atlaskit/button/new'

// Interface
import { Field, PlacesAutoComplete, useFormContext, TrashIcon } from '@royan-co/user-interface'

// Components
import EnumerationField from 'components/Rates/List/EnumerationField'
import RatesAirportsField from 'components/Rates/List/AirportsField'

const fieldPerType = {
  airport: ({ name, label }) => <RatesAirportsField isRequired label={label} name={name} />,
  station: ({ name, label }) => (
    <EnumerationField isRequired label={label} name={name} optionsKey="stations" />
  ),
  port: ({ name, label }) => (
    <EnumerationField isRequired label={label} name={name} optionsKey="ports" />
  ),
  hub: ({ name, label }) => (
    <EnumerationField isRequired label={label} name={name} optionsKey="hubs" />
  ),
  zone: ({ name, label }) => (
    <Field
      isRequired
      name={name}
      label={label}
      type="text"
      // validate={(data) => (!data.countryAbbr ? `Address is not valid.` : true)}
    >
      {({ fieldProps, error }) => (
        <>
          <PlacesAutoComplete {...fieldProps} />
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </>
      )}
    </Field>
  ),
}

const LineRouteLegForm = ({ currentLegIndex, currentLeg, deleteLeg }) => {
  const { setValue, getValues } = useFormContext()

  useEffect(() => {
    const legs = getValues('legs') || []
    const target = legs[currentLegIndex] || {}

    if (currentLegIndex > 0 && !target.departure) {
      target.departure = legs[currentLegIndex - 1]?.arrival
    }

    setValue(`legs.${currentLegIndex}`, {
      ...target,
      type: currentLeg?.type,
    })
  }, [currentLegIndex, setValue, getValues])

  const arrivalField = useMemo(() => {
    return fieldPerType[currentLeg?.arrival?.type]?.({
      name: `legs.${currentLegIndex}.arrival`,
      label: 'To',
    })
  }, [currentLeg, currentLegIndex])

  const departureField = useMemo(() => {
    return fieldPerType[currentLeg?.departure?.type]?.({
      name: `legs.${currentLegIndex}.departure`,
      label: 'From',
    })
  }, [currentLeg, currentLegIndex])

  return (
    <>
      <div className="flex justify-end">
        <IconButton
          icon={(iconProps) => <TrashIcon {...iconProps} className="text-red-400" size={20} />}
          label="Delete"
          onClick={() => deleteLeg(currentLegIndex)}
        />
      </div>

      <div className="grid md:grid-cols-2 md:gap-4">
        <div>{departureField}</div>

        <div>{arrivalField}</div>
      </div>
    </>
  )
}

export default LineRouteLegForm
