// Atlassian
import Spinner from '@atlaskit/spinner'
import { DropdownItem } from '@atlaskit/dropdown-menu'

const ActionDropdownItem = ({ isDisabled, label, mutation, mutationParams = {} }) => {
  const onClick = (e) => {
    e.stopPropagation()

    mutation.mutate(mutationParams, {
      onSettled: () => {
        setTimeout(() => {
          const event = new MouseEvent('click')
          document.body.dispatchEvent(event)
        }, 100)
      },
    })
  }

  return (
    <div style={{ minWidth: '150px' }}>
      <DropdownItem onClick={onClick} isDisabled={mutation.isLoading || isDisabled}>
        <div className="flex justify-between">
          {label}
          {mutation.isLoading && (
            <span className="ml-2">
              <Spinner size="small" />
            </span>
          )}
        </div>
      </DropdownItem>
    </div>
  )
}

export default ActionDropdownItem
