import { useMutation, useQueryClient } from '@tanstack/react-query'

// Apis
import { deleteMarginCalculatorBracket } from './margin-calculator-requests'

const useDeleteBracket = (marginId) => {
  const queryClient = useQueryClient()
  const _marginId = +marginId

  return useMutation({
    mutationFn: (bracketId) => deleteMarginCalculatorBracket(marginId, bracketId),
    onSuccess: (res, bracketId) => {
      queryClient.setQueryData(['margin-calculator', 'brackets', _marginId], (oldBrackets) => {
        return oldBrackets.filter((b) => b.id !== bracketId)
      })
    },
    onError: (e) => {
      console.error(e)
    },
  })
}

export { useDeleteBracket }
