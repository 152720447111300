import { nodeTypes } from './nodeTypes'

export const legTypes = [
  {
    label: 'Hub to zone',
    value: 0,
    departure: nodeTypes[1],
    arrival: nodeTypes[0],
  },
  {
    label: 'Zone to hub',
    value: 1,
    departure: nodeTypes[0],
    arrival: nodeTypes[1],
  },
  {
    label: 'Zone to zone',
    value: 2,

    departure: nodeTypes[0],
    arrival: nodeTypes[0],
  },
  {
    label: 'Hub to hub',
    value: 3,
    departure: nodeTypes[1],
    arrival: nodeTypes[1],
  },
  {
    label: 'Airport to airport',
    value: 5,
    departure: nodeTypes[2],
    arrival: nodeTypes[2],
  },
  {
    label: 'Port to port',
    value: 6,
    departure: nodeTypes[3],
    arrival: nodeTypes[3],
  },
  {
    label: 'Hub to port',
    value: 7,
    departure: nodeTypes[1],
    arrival: nodeTypes[3],
  },
  {
    label: 'Zone to port',
    value: 8,
    departure: nodeTypes[0],
    arrival: nodeTypes[3],
  },
  {
    label: 'Port to zone',
    value: 9,
    departure: nodeTypes[3],
    arrival: nodeTypes[0],
  },
  {
    label: 'Rail to rail',
    value: 10,
    departure: nodeTypes[4],
    arrival: nodeTypes[4],
  },
  {
    label: 'Hub to rail',
    value: 11,
    departure: nodeTypes[1],
    arrival: nodeTypes[4],
  },
  {
    label: 'Rail to hub',
    value: 12,
    departure: nodeTypes[4],
    arrival: nodeTypes[1],
  },
  {
    label: 'Rail to zone',
    value: 13,
    departure: nodeTypes[4],
    arrival: nodeTypes[0],
  },
  {
    label: 'Zone to rail',
    value: 14,
    departure: nodeTypes[0],
    arrival: nodeTypes[4],
  },
  {
    label: 'Rail to port',
    value: 15,
    departure: nodeTypes[4],
    arrival: nodeTypes[3],
  },
  {
    label: 'Port to rail',
    value: 16,
    departure: nodeTypes[3],
    arrival: nodeTypes[4],
  },
  {
    label: 'Hub to airport',
    value: 17,
    departure: nodeTypes[1],
    arrival: nodeTypes[2],
  }, // To be checked later
  {
    label: 'Hub to airport',
    value: 23,
    departure: nodeTypes[1],
    arrival: nodeTypes[2],
  },
  {
    label: 'Airport to zone',
    value: 21,
    departure: nodeTypes[2],
    arrival: nodeTypes[0]
  }
]
