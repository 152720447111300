import React, { useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useLocation, useParams, Outlet, useNavigate } from 'react-router-dom'

// Hooks
import { useMarginCalculator } from 'features/marginCalculator/api/useMarginCalculator'

// Atlassian
import Tabs, { Tab, TabList } from '@atlaskit/tabs'
import Spinner from '@atlaskit/spinner'
import PageHeader from '@atlaskit/page-header'
import Button, { ButtonGroup } from '@atlaskit/button'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'
import SectionMessage from '@atlaskit/section-message'

// Interfaces
import {
  useRoyanTableLastFilters,
  DotsIcon,
  Skeleton,
  useDisclosure,
} from '@royan-co/user-interface'

// Components
import DropdownLink from 'components/UI/DropdownLink'
import MarginCalculatorDeleteModal from 'features/marginCalculator/components/view/DeleteModal'

const tabData = [
  { title: 'General information', path: '' },
  { title: 'Brackets', path: '/brackets' },
]

const MarginCalculatorViewPage = () => {
  const { marginId } = useParams()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { lastUrlSearch } = useRoyanTableLastFilters('margin-calculator-list')
  const [isOpenDeleteModal, deleteModalHandler] = useDisclosure('delete')
  const { data: margin, isFetching, isLoading, isError, refetch } = useMarginCalculator(marginId)

  const tabs = useMemo(
    () =>
      tabData.map((t) => ({
        ...t,
        path: `/panel/margin-calculator/${marginId}${t.path}`,
      })),
    [marginId]
  )

  const selectedTabIndex = useMemo(
    () => tabs.findIndex((t) => t.path === pathname),
    [pathname, tabs]
  )

  const navigateToTab = (index) => {
    const tab = tabs[index]

    navigate(tab.path)
  }

  return (
    <>
      <Helmet>
        <title>{margin?.name}</title>
      </Helmet>

      <PageHeader
        breadcrumbs={
          <Breadcrumbs>
            <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
            <BreadcrumbsItem text="Sales machine" />
            <BreadcrumbsItem
              text="Margin calculator"
              to="/panel/margin-calculator"
              component={Link}
            />
          </Breadcrumbs>
        }
        actions={
          <ButtonGroup>
            <Button
              component={Link}
              appearance="default"
              to={`/panel/margin-calculator${lastUrlSearch ? `?${lastUrlSearch}` : ''}`}
            >
              Back
            </Button>

            <DropdownMenu
              placement="bottom-end"
              trigger={({ triggerRef, ...props }) => (
                <Button
                  {...props}
                  appearance="default"
                  children={<DotsIcon label="more" />}
                  ref={triggerRef}
                />
              )}
            >
              <DropdownItemGroup>
                <DropdownLink children="Edit" to={`/panel/margin-calculator/${marginId}/edit`} />
                <DropdownItem children="Delete" onClick={() => deleteModalHandler.open(marginId)} />
              </DropdownItemGroup>
            </DropdownMenu>
          </ButtonGroup>
        }
      >
        {isLoading ? (
          <Skeleton height={32} maw={320} />
        ) : (
          <div className="flex items-center">Margin calculator {margin?.id}</div>
        )}
      </PageHeader>

      <>
        <Tabs onChange={navigateToTab} selected={selectedTabIndex}>
          <TabList>
            {tabs.map((tab, i) => (
              <Tab key={i} on>
                {tab.title}
              </Tab>
            ))}
          </TabList>
        </Tabs>

        <div className="px-2 py-4">
          {isLoading && isFetching ? (
            <div className="flex justify-center my-32">
              <Spinner />
            </div>
          ) : isError ? (
            <SectionMessage
              appearance="warning"
              title="Something wrong on loading data, please retry."
            >
              <Button onClick={() => refetch()}>Retry</Button>
            </SectionMessage>
          ) : (
            selectedTabIndex > -1 && <Outlet />
          )}

          <MarginCalculatorDeleteModal
            isOpen={isOpenDeleteModal}
            onClose={deleteModalHandler.close}
            marginId={deleteModalHandler.data}
          />
        </div>
      </>
    </>
  )
}

export default MarginCalculatorViewPage
