import { useMemo } from 'react'

// Contexts
import { useAuth } from 'contexts/AuthProvider'

// Atlassian
import { DropdownItem } from '@atlaskit/dropdown-menu'

// Components
import DisabledElementTooltip from 'components/UI/DisabledElementTooltip'

const RegistryDeactivateAction = ({ deactivated, onClick }) => {
  const { currentUser } = useAuth()

  const accessabilityInactive = useMemo(() => {
    if (!['admin', 'accounting'].includes(currentUser?.user_level_name?.name)) {
      return {
        access: false,
        message: 'You do not have access',
      }
    } else
      return {
        access: true,
      }
  }, [currentUser])

  return (
    <>
      <DisabledElementTooltip
        isDisabled={!accessabilityInactive?.access}
        tooltipContent={accessabilityInactive?.message}
      >
        <DropdownItem onClick={onClick} isDisabled={!accessabilityInactive?.access}>
          {deactivated ? 'Activate' : 'Deactivate'}
        </DropdownItem>
      </DisabledElementTooltip>
    </>
  )
}

export default RegistryDeactivateAction
