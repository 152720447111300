import { useMemo, useState, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

// Apis
import { deleteConsolidationDocument, getConsolidationDocuments } from 'api/consolidations'

// Hooks
import useConsolidation from 'hooks/useConsolidation'
import { useCanAccess } from 'features/auth'

// Contexts
import { useAuth } from 'contexts/AuthProvider'

// Atlassian
import Button from '@atlaskit/button'
import Spinner from '@atlaskit/spinner'
import ButtonGroup from '@atlaskit/button/button-group'
import EmptyState from '@atlaskit/empty-state'
import SectionMessage from '@atlaskit/section-message'
import { DynamicTableStateless } from '@atlaskit/dynamic-table'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'

// Interfaces
import { DotsIcon, useDisclosure, useFlags } from '@royan-co/user-interface'

// Components
import SearchInput from 'components/UI/SearchInput'
import DeleteDocumentModal from 'components/Consolidations/View/DeleteDocumentModal'
import ConsolidationUploadDocument from 'components/Consolidations/View/UploadDocument'
import ConsolidationDownloadManifest from 'components/Consolidations/View/DownloadManifest'
import ConsolidationDownloadIataLabels from 'components/Consolidations/View/DownloadIataLabels'
import { generateConsolidationUserPermissions } from 'components/Consolidations/Create/conslolidation-user-permissions'

const tableHead = {
  cells: [
    { key: 'title', content: 'Title' },
    { key: 'creation_date', content: 'Creation date' },
    { key: 'actions', content: 'Actions' },
  ],
}

const ConsolidationViewDocumentPage = () => {
  const navigate = useNavigate()
  const { consolidationId } = useParams()
  const queryClient = useQueryClient()
  const { showSuccessFlag } = useFlags()

  const { currentUser, isWarehouseUser } = useAuth()
  const { consolidation } = useConsolidation()

  const [searchTerm, setSearchTerm] = useState('')
  const [openDeletingDocumentModal, deletingDocumentModalHandlers] = useDisclosure('delete')

  const {
    data = [],
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ['consolidation_documents', consolidationId],
    queryFn: () => getConsolidationDocuments(consolidationId),
  })

  const canEditDocument = useCanAccess({
    resource: 'consolidations.document',
    action: 'edit',
    userPermissions: generateConsolidationUserPermissions(consolidation, currentUser),
  })
  const canDeleteDocument = useCanAccess({
    resource: 'consolidations.document',
    action: 'delete',
    userPermissions: generateConsolidationUserPermissions(consolidation, currentUser),
  })

  const DocumentDeleteMutation = useMutation(deleteConsolidationDocument)

  const handleDeleteDocument = () => {
    const documentID = deletingDocumentModalHandlers?.data?.id

    DocumentDeleteMutation.mutate(documentID, {
      onSuccess: (res) => {
        queryClient.setQueryData(['consolidation_documents', consolidationId], (docs) =>
          docs.filter((d) => d.id !== documentID)
        )
        showSuccessFlag(res?.message)
        deletingDocumentModalHandlers.close()
      },
    })
  }

  const closeDeleteModal = () => {
    DocumentDeleteMutation.reset()
    deletingDocumentModalHandlers.close()
  }

  const handleDownload = (url) => {
    window.open(url, '_blank')
  }

  const getEditTypePath = useCallback((document, consolidationId) => {
    const types = {
      consolidation_cmr_id: `/panel/consolidations/document/cmr/${document.consolidation_cmr_id}/edit`,
    }
    const documentKey = ['consolidation_cmr_id'].find((key) => (!!document[key] ? true : false))
    return documentKey ? types[documentKey] : null
  }, [])

  const getViewDocumentPath = useCallback((document) => {
    const types = {
      consolidation_cmr_id: `/panel/consolidations/document/cmr/${document.consolidation_cmr_id}`,
    }
    const documentKey = ['consolidation_cmr_id'].find((key) => (!!document[key] ? true : false))
    return documentKey ? types[documentKey] : null
  }, [])

  const navigateToDocumentPage = useCallback(
    (pathname) => {
      navigate(pathname, { state: { from: `/panel/consolidations/${consolidationId}/documents` } })
    },
    [consolidationId, navigate]
  )

  const handleRowClick = useCallback(
    (e, document) => {
      if (e.detail === 2) {
        if (getViewDocumentPath(document)) {
          navigateToDocumentPage(getViewDocumentPath(document))
        } else {
          handleDownload(document?.url)
        }
      }
    },
    [getViewDocumentPath, navigateToDocumentPage]
  )

  const tableRows = useMemo(
    () =>
      data
        .filter((d) => d.name?.toLocaleLowerCase()?.includes(searchTerm.toLocaleLowerCase()))
        .map((d) => ({
          key: d.id,
          onClick: (e) => handleRowClick(e, d),
          cells: [
            {
              content: d.name,
            },
            {
              content: d.creation_date || '-',
            },
            {
              content: (
                <DropdownMenu
                  trigger={({ triggerRef, ...props }) => (
                    <Button
                      ref={triggerRef}
                      appearance="subtle"
                      iconBefore={<DotsIcon />}
                      {...props}
                    />
                  )}
                >
                  <DropdownItemGroup>
                    {getViewDocumentPath(d) ? (
                      <DropdownItem onClick={() => navigateToDocumentPage(getViewDocumentPath(d))}>
                        View
                      </DropdownItem>
                    ) : (
                      <DropdownItem onClick={() => handleDownload(d?.url)}>Download</DropdownItem>
                    )}
                    {consolidationId && canEditDocument && getEditTypePath(d, consolidationId) ? (
                      <DropdownItem
                        onClick={() => navigateToDocumentPage(getEditTypePath(d, consolidationId))}
                      >
                        Edit
                      </DropdownItem>
                    ) : null}

                    {canDeleteDocument && !isWarehouseUser && (
                      <DropdownItem
                        children="Delete"
                        onClick={() => deletingDocumentModalHandlers.open(d)}
                      />
                    )}
                  </DropdownItemGroup>
                </DropdownMenu>
              ),
            },
          ],
        })),
    [
      data,
      searchTerm,
      getViewDocumentPath,
      consolidationId,
      canEditDocument,
      getEditTypePath,
      canDeleteDocument,
      isWarehouseUser,
      handleRowClick,
      navigateToDocumentPage,
      deletingDocumentModalHandlers,
    ]
  )

  return (
    <>
      {isLoading ? (
        <div className="text-center mt-24">
          <Spinner />
        </div>
      ) : isError ? (
        <SectionMessage
          appearance="warning"
          title="Something wrong on loading documents, please retry."
        >
          <Button onClick={() => refetch()}>Retry</Button>
        </SectionMessage>
      ) : (
        <>
          <div className="flex justify-between">
            <div style={{ maxWidth: '240px' }}>
              <SearchInput onSubmit={setSearchTerm} delay={150} />
            </div>

            <ButtonGroup>
              <ConsolidationDownloadIataLabels />
              <ConsolidationDownloadManifest />
              <ConsolidationUploadDocument />
            </ButtonGroup>
          </div>

          <div className="shadow rounded p-4 mt-4">
            {tableRows.length === 0 ? (
              <EmptyState header="No documents" />
            ) : (
              <div className="inline-block w-full -mb-6">
                <DynamicTableStateless head={tableHead} rows={tableRows} />
              </div>
            )}
          </div>

          <DeleteDocumentModal
            isOpen={openDeletingDocumentModal}
            onClose={closeDeleteModal}
            document={deletingDocumentModalHandlers.data}
            onSubmit={handleDeleteDocument}
            isError={DocumentDeleteMutation.isError}
            isLoading={DocumentDeleteMutation.isLoading}
          />
        </>
      )}
    </>
  )
}

export default ConsolidationViewDocumentPage
