import { useState } from 'react'
import { saveAs } from 'file-saver'

// Apis
import { getInvoicePDF } from 'api/invoices'

// Atlassian
import Button from '@atlaskit/button'
import Spinner from '@atlaskit/spinner'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'

// Interfaces
import { DotsIcon, useFlags, useDisclosure } from '@royan-co/user-interface'

// Features
import {
  invoiceTypes,
  DeleteInvoiceModal,
  ActiveInvoiceActions,
  PassiveInvoiceActions,
  BufferInvoiceActions,
  ElaborateInvoiceModal,
  TakeInChargeModal,
} from 'features/invoice'
import DropdownLink from 'components/UI/DropdownLink'

const InvoiceRowActions = ({ invoice, type, shipmentId }) => {
  const [isLoading, setISLoading] = useState(false)
  const { showWarningFlag } = useFlags()
  const _type = invoiceTypes.find((t) => t.name === type)

  const [isOpenDeleteModal, deleteModalHandler] = useDisclosure('delete')
  const [isOpenElaborateModal, elaborateModalHandler] = useDisclosure('elaborate')
  const [isOpenTakeInChargeModal, takeInChargeModalHandler] = useDisclosure('take-in-charge')

  const openDeleteModal = (invoiceId) => {
    deleteModalHandler.open(invoiceId)
  }
  const openElaborateModal = (invoiceId) => {
    elaborateModalHandler.open(invoiceId)
  }
  const openTakeInChargeModal = (invoiceId) => {
    takeInChargeModalHandler.open(invoiceId)
  }

  const openActiveInvoiceFile = async () => {
    const parents = invoice?.parents.map((parent) => parent.reference)
    const invoicePDF = await getInvoicePDF(invoice.id_auto)

    saveAs(
      new Blob([invoicePDF]),
      (invoice?.is_proforma ? `PROFORMA` : `Invoice no. ${invoice.invoice_number}`) +
        ` - ${parents.join('-')}.pdf`
    )
  }

  const openPassiveInvoiceFile = async () => {
    if (invoice.download_url) return window.open(invoice.download_url)
    else showWarningFlag('There is no file')
  }

  const showInvoicePDF = async () => {
    try {
      if (isLoading) return

      setISLoading(true)

      if (type === 'active') {
        await openActiveInvoiceFile()
      } else if (type === 'passive' || type === 'buffer') {
        await openPassiveInvoiceFile()
      }
    } catch (error) {
      console.error(error)
      showWarningFlag('Something wrong on download PDF')
    } finally {
      setISLoading(false)
    }
  }

  return (
    <>
      <DropdownMenu
        isOpen={isLoading || undefined}
        placement="bottom-end"
        trigger={({ triggerRef, ...props }) => (
          <Button
            {...props}
            appearance="subtle"
            children={<DotsIcon label="more" />}
            ref={triggerRef}
          />
        )}
      >
        <DropdownItemGroup>
          <DropdownLink
            children={'View'}
            to={`${_type.pathList}/${invoice.id_auto}`}
            target={shipmentId ? '_blank' : '_self'}
          />

          {type === 'buffer' && !invoice?.download_url ? null : (
            <DropdownItem isDisabled={isLoading} onClick={showInvoicePDF}>
              Download
              {isLoading && (
                <span className="ml-2">
                  <Spinner size="small" />
                </span>
              )}
            </DropdownItem>
          )}

          {type === 'active' && (
            <ActiveInvoiceActions
              invoice={invoice}
              shipmentId={shipmentId}
              openDeleteModal={openDeleteModal}
            />
          )}

          {type === 'passive' && (
            <PassiveInvoiceActions
              invoice={invoice}
              shipmentId={shipmentId}
              openDeleteModal={openDeleteModal}
            />
          )}

          {type === 'buffer' && (
            <BufferInvoiceActions
              invoice={invoice}
              openElaborateModal={openElaborateModal}
              openTakeInChargeModal={openTakeInChargeModal}
            />
          )}
        </DropdownItemGroup>
      </DropdownMenu>

      <DeleteInvoiceModal
        isOpen={isOpenDeleteModal}
        onClose={deleteModalHandler.close}
        invoiceId={deleteModalHandler.data}
        shipmentId={shipmentId}
        type={type}
      />

      <ElaborateInvoiceModal
        isOpen={isOpenElaborateModal}
        onClose={elaborateModalHandler.close}
        invoiceId={elaborateModalHandler.data}
      />

      <TakeInChargeModal
        isOpen={isOpenTakeInChargeModal}
        onClose={takeInChargeModalHandler.close}
        invoiceId={takeInChargeModalHandler.data}
      />
    </>
  )
}

export default InvoiceRowActions
