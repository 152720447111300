import { useMemo } from 'react'

// Utils
import { transformSelectField } from 'utils/helpers'
import {
  invoiceTypeOptions,
  invoiceProformaOptions,
  invoiceImportedARCAOptions,
  invoiceMultipleOptions,
  registrationTypeOptions,
  invoiceCMROptions,
  invoiceVisibilityOperatorOptions,
  invoiceDisputedOptions,
} from './../constants'

// Hooks
import useShipmentsOperators from 'hooks/useShipmentsOperators'
import useInvoiceEnumerations from 'hooks/useInvoiceEnumerations'
import usePaymentBanks from 'hooks/usePaymentBanks'
import useShipmentsEnumerations from 'hooks/useShipmentsEnumerations'

// Atlassian
import Select from '@atlaskit/select'
import TextField from '@atlaskit/textfield'
import { DatePicker } from '@atlaskit/datetime-picker'

// Interfaces
import { Field } from '@royan-co/user-interface'

// Components
import InvoicePaidSelect from '../PaidSelect'

const InvoicesFilterFields = ({ appliedFilters, type }) => {
  const active = useMemo(() => type === 'active', [type])
  const passive = useMemo(() => type === 'passive', [type])
  const buffer = useMemo(() => type === 'buffer', [type])

  const { getEnumerationOptionsByKey, isLoadingShipmentEnumeration } = useShipmentsEnumerations()
  const { shipmentsOperatorsOptions, isLoadingShipmentsOperators } = useShipmentsOperators()
  const { docCodesOptions, isLoadingInvoiceEnumerations } = useInvoiceEnumerations({
    enabled: active,
  })
  const { paymentBankOptions, isLoadingPaymentBanks } = usePaymentBanks({ enabled: active })

  const registrationOptions = useMemo(
    () =>
      buffer ? registrationTypeOptions.filter((o) => o.value !== 10) : registrationTypeOptions,
    [buffer]
  )

  return (
    <>
      {!buffer && (
        <Field
          label="Invoice type"
          type="select"
          name="do_boolean"
          transform={transformSelectField(invoiceTypeOptions)}
        >
          {({ fieldProps }) => (
            <Select
              isClearable={true}
              options={invoiceTypeOptions}
              placeholder="Choose one"
              {...fieldProps}
            />
          )}
        </Field>
      )}

      {active ? (
        <>
          <Field
            label="Proforma"
            type="select"
            name="is_proforma"
            transform={transformSelectField(invoiceProformaOptions)}
          >
            {({ fieldProps }) => (
              <Select
                isClearable={true}
                options={invoiceProformaOptions}
                placeholder="Choose one"
                {...fieldProps}
              />
            )}
          </Field>

          <Field
            label="Doc type"
            type="select"
            name="do"
            transform={transformSelectField(docCodesOptions)}
          >
            {({ fieldProps }) => (
              <Select
                isClearable={true}
                isLoading={isLoadingInvoiceEnumerations}
                options={docCodesOptions}
                placeholder="Choose doc type"
                {...fieldProps}
              />
            )}
          </Field>
        </>
      ) : null}

      <Field
        label="Shipment reference"
        name="shipment"
        defaultValue={appliedFilters.shipment || ''}
      >
        {({ fieldProps }) => (
          <TextField placeholder="Type the shipment reference here" {...fieldProps} />
        )}
      </Field>

      <Field
        label="Consolidation reference"
        name="consolidation"
        defaultValue={appliedFilters.consolidation || ''}
      >
        {({ fieldProps }) => (
          <TextField placeholder="Type the consolidation reference here" {...fieldProps} />
        )}
      </Field>

      {active ? (
        <Field label="Client" name="client" defaultValue="">
          {({ fieldProps }) => <TextField placeholder="Type the client" {...fieldProps} />}
        </Field>
      ) : null}

      {passive || buffer ? (
        <Field label="Supplier" name="supplier" defaultValue="">
          {({ fieldProps }) => <TextField placeholder="Type the supplier" {...fieldProps} />}
        </Field>
      ) : null}

      <div className="grid md:grid-cols-2 md:gap-4">
        <Field label="Amount" name="total_amount" defaultValue={appliedFilters.total_amount || ''}>
          {({ fieldProps }) => <TextField placeholder="Type amount" {...fieldProps} />}
        </Field>

        <Field
          isClearable={true}
          type="select"
          label="Currency"
          name="currency"
          transform={transformSelectField(getEnumerationOptionsByKey('currencies'))}
        >
          {({ fieldProps }) => (
            <div>
              <Select
                menuPlacement="auto"
                placeholder="Choose"
                isLoading={isLoadingShipmentEnumeration}
                options={getEnumerationOptionsByKey('currencies')}
                {...fieldProps}
              />
            </div>
          )}
        </Field>
      </div>

      {passive || buffer ? (
        <>
          <Field
            type="select"
            label="Operator"
            name="operator_id"
            transform={transformSelectField(shipmentsOperatorsOptions)}
          >
            {({ fieldProps }) => (
              <Select
                isClearable={true}
                placeholder="Choose"
                options={shipmentsOperatorsOptions}
                isLoading={isLoadingShipmentsOperators}
                {...fieldProps}
              />
            )}
          </Field>

          {!buffer && (
            <Field
              label="Visibility for operator"
              type="select"
              name="hide_buffer"
              transform={transformSelectField(invoiceVisibilityOperatorOptions)}
            >
              {({ fieldProps }) => (
                <Select
                  isClearable={true}
                  options={invoiceVisibilityOperatorOptions}
                  placeholder="Choose one"
                  {...fieldProps}
                />
              )}
            </Field>
          )}
        </>
      ) : null}

      {!buffer && (
        <Field
          type="select"
          label="Creator"
          name="creator"
          transform={transformSelectField(shipmentsOperatorsOptions)}
        >
          {({ fieldProps }) => (
            <Select
              isClearable={true}
              placeholder="Choose"
              options={shipmentsOperatorsOptions}
              isLoading={isLoadingShipmentsOperators}
              {...fieldProps}
            />
          )}
        </Field>
      )}

      {active ? (
        <Field
          label="Imported in accounting"
          type="select"
          name="imported"
          transform={transformSelectField(invoiceImportedARCAOptions)}
        >
          {({ fieldProps }) => (
            <Select
              isClearable={true}
              options={invoiceImportedARCAOptions}
              placeholder="Choose one"
              {...fieldProps}
            />
          )}
        </Field>
      ) : null}

      {!buffer && (
        <Field label="Paid" type="select" name="is_payd">
          {({ fieldProps }) => (
            <InvoicePaidSelect defaultValue={appliedFilters.is_payd} {...fieldProps} />
          )}
        </Field>
      )}

      {passive || buffer ? (
        <>
          <Field
            label="Registration"
            type="select"
            name="arca_status"
            transform={transformSelectField(registrationOptions)}
          >
            {({ fieldProps }) => (
              <Select
                isClearable={true}
                options={registrationOptions}
                placeholder="Choose one"
                {...fieldProps}
              />
            )}
          </Field>

          {!buffer && (
            <Field
              label="CMR"
              type="select"
              name="has_cmr"
              transform={transformSelectField(invoiceCMROptions)}
            >
              {({ fieldProps }) => (
                <Select
                  isClearable={true}
                  options={invoiceCMROptions}
                  placeholder="Choose one"
                  {...fieldProps}
                />
              )}
            </Field>
          )}
        </>
      ) : null}

      {active ? (
        <>
          <Field
            type="select"
            label="Banks "
            name="registry_bank_id"
            transform={transformSelectField(paymentBankOptions)}
          >
            {({ fieldProps }) => (
              <Select
                isClearable={true}
                placeholder="Choose"
                options={paymentBankOptions}
                isLoading={isLoadingPaymentBanks}
                {...fieldProps}
              />
            )}
          </Field>

          <Field
            label="Multiple"
            type="select"
            name="multiple"
            transform={transformSelectField(invoiceMultipleOptions)}
          >
            {({ fieldProps }) => (
              <Select
                isClearable={true}
                options={invoiceMultipleOptions}
                placeholder="Choose one"
                {...fieldProps}
              />
            )}
          </Field>
        </>
      ) : null}

      {buffer && (
        <Field
          label="Disputed"
          type="select"
          name="disputed"
          transform={transformSelectField(invoiceDisputedOptions)}
        >
          {({ fieldProps }) => (
            <Select
              isClearable={true}
              options={invoiceDisputedOptions}
              placeholder="Choose one"
              {...fieldProps}
            />
          )}
        </Field>
      )}

      <div className="grid md:grid-cols-2 md:gap-4">
        <Field
          label="Issued from"
          name="issued_from"
          defaultValue={appliedFilters.issued_from || ''}
        >
          {({ fieldProps }) => <DatePicker placeholder="2/18/2022" {...fieldProps} />}
        </Field>

        <Field label="Issued to" name="issued_to" defaultValue={appliedFilters.issued_to || ''}>
          {({ fieldProps }) => <DatePicker placeholder="2/18/2022" {...fieldProps} />}
        </Field>
      </div>
    </>
  )
}

export default InvoicesFilterFields
