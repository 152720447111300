export const handleErrorOnSubmit = (error, setError, data) => {
  let unknown = false
  let fieldErrors = error?.response?.data?.data || error?.response?.data?.errors

  if ([400, 422].includes(error?.response?.status) && fieldErrors) {
    for (let [fieldName, fieldMessage] of Object.entries(fieldErrors)) {
      data[fieldName] === undefined
        ? (unknown = true)
        : setError(fieldName, { type: 'api', message: fieldMessage })
    }
  } else {
    unknown = true
  }

  if (unknown) {
    setError('unknown', {
      type: 'api',
      message: error?.response?.data?.message || 'Something went wrong.',
    })
    console.error('error in form submission:', error)
  }
}
