export const termsOfDeliveryOptions = [
  { label: 'CPT', value: 'CPT' },
  { label: 'DAP', value: 'DAP' },
  { label: 'DAT', value: 'DAT' },
  { label: 'EXW', value: 'EXW' },
  { label: 'FCA', value: 'FCA' },
  { label: 'FOB', value: 'FOB' },
]

export const termsOfPaymentOptions = [
  { label: 'Collect', value: 0 },
  { label: 'Prepaid', value: 1 },
]
