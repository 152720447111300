// Atlassian
import Button from '@atlaskit/button'
import Lozenge from '@atlaskit/lozenge'
import DropdownMenu, { DropdownItemGroup, DropdownItem } from '@atlaskit/dropdown-menu'

import YesNoTag from 'components/UI/YesNoTag'
import { DotsIcon } from '@royan-co/user-interface'
import ShipmentReferenceTag from 'components/Registries/ShipmentReferenceTag'

export const generateContactWhatsappHistoryColumns = ({ onOpenDetails }) => [
  {
    show: true,
    key: `shipment`,
    title: 'Shipment reference',
    content: (item) => <ShipmentReferenceTag shipment={item?.shipment} />,
  },
  {
    show: true,
    key: `creation`,
    title: 'Creation date',
    content: (item) => item?.created_at || <Lozenge appearance="default">Not set</Lozenge>,
  },
  {
    show: true,
    key: `sent`,
    title: 'Sent',
    content: (item) => <YesNoTag value={item?.sent} />,
  },
  {
    key: `delivered`,
    title: 'Delivered',
    content: (item) => <YesNoTag value={item?.delivered} />,
  },
  {
    show: true,
    key: `viewed`,
    title: 'Viewed',
    content: (item) => <YesNoTag value={item?.viewed} />,
  },
  {
    show: true,
    key: `actions`,
    title: 'Actions',
    content: (item) => (
      <DropdownMenu
        placement="bottom-end"
        trigger={({ triggerRef, ...props }) => (
          <Button ref={triggerRef} appearance="subtle" children={<DotsIcon />} {...props} />
        )}
      >
        <DropdownItemGroup>
          <DropdownItem children="View" onClick={() => onOpenDetails(item)} />
        </DropdownItemGroup>
      </DropdownMenu>
    ),
  },
]
