import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { getRegistryDocuments } from 'api/registries'

//Interfaces
import { DotsIcon, useDisclosure } from '@royan-co/user-interface'

//Atlassian
import Button from '@atlaskit/button'
import Spinner from '@atlaskit/spinner'
import SectionMessage from '@atlaskit/section-message'
import { DynamicTableStateless } from '@atlaskit/dynamic-table'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'
import EmptyState from '@atlaskit/empty-state'

//Components
import SearchInput from 'components/UI/SearchInput'
import RegistryUploadDocument from 'components/Registries/View/UploadDocument'
import RegistriesDeleteDocumentModal from 'components/Registries/View/DeleteDocumentModal'

const tableHead = {
  cells: [{ content: 'Title' }, { content: 'Creation date' }, { content: 'Actions' }],
}

const RegistryViewDocumentsPage = () => {
  const { registryId } = useParams()
  const [searchTerm, setSearchTerm] = useState('')
  const [openDeletingDocumentModal, deletingDocumentModalHandlers] = useDisclosure('delete')

  const {
    data = [],
    isLoading,
    isError,
    refetch,
  } = useQuery(['registry-documents', registryId], () => getRegistryDocuments(registryId), {
    staleTime: Infinity,
  })

  const tableRows = useMemo(
    () =>
      (data || [])
        .filter((d) => d.name?.includes(searchTerm))
        .map((document, i) => ({
          cells: [
            {
              content: <div className="py-1">{document.name}</div>,
            },
            {
              content: document.creation_date,
            },
            {
              content: (
                <DropdownMenu
                  placement="bottom-end"
                  trigger={({ triggerRef, ...props }) => (
                    <Button
                      ref={triggerRef}
                      appearance="subtle"
                      children={<DotsIcon />}
                      {...props}
                    />
                  )}
                >
                  <DropdownItemGroup>
                    <DropdownItem
                      component={'a'}
                      target="_blank"
                      href={document.url}
                      children="Download"
                    />
                    <DropdownItem
                      children="Delete"
                      onClick={() => deletingDocumentModalHandlers.open(document)}
                    />
                  </DropdownItemGroup>
                </DropdownMenu>
              ),
            },
          ],
        })),
    [data, deletingDocumentModalHandlers, searchTerm]
  )

  return (
    <>
      {isLoading ? (
        <div className="flex justify-center my-32">
          <Spinner />
        </div>
      ) : isError ? (
        <SectionMessage
          appearance="warning"
          title="Something wrong on loading documents data, please retry"
        >
          <Button onClick={() => refetch()}>Retry</Button>
        </SectionMessage>
      ) : (
        <>
          <div className="flex justify-between">
            <div style={{ maxWidth: '240px', marginRight: '10px' }}>
              <SearchInput onSubmit={setSearchTerm} delay={150} />
            </div>

            <RegistryUploadDocument />
          </div>

          <div className="shadow rounded p-4 mt-4">
            {tableRows.length === 0 ? (
              <EmptyState header="No documents" />
            ) : (
              <div className="inline-block w-full -mb-6">
                <DynamicTableStateless head={tableHead} rows={tableRows} />
              </div>
            )}
          </div>

          <RegistriesDeleteDocumentModal
            isOpen={openDeletingDocumentModal}
            onClose={deletingDocumentModalHandlers.close}
            document={deletingDocumentModalHandlers.data}
            registryId={registryId}
          />
        </>
      )}
    </>
  )
}

export default RegistryViewDocumentsPage
