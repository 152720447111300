export const Items = [
  {
    title: 'Home',
    url: 'https://www.parsimpex.com',
    submenu: [
      {
        title: 'Our Certificates',
        url: 'https://www.parsimpex.com/certificates',
      },
      {
        title: 'Privacy Policy',
        url: 'https://www.parsimpex.com/privacy-policy',
      },
      {
        title: 'Downloads',
        url: 'https://www.parsimpex.com/download',
      },
    ],
  },
  {
    title: 'Freight',
    url: 'https://www.parsimpex.com/freight-2-2/',
    submenu: [
      {
        title: 'Road Freight',
        url: 'https://www.parsimpex.com/freight-2-2/road-freight',
        submenu: [
          {
            title: 'Full Truck Load (FTL)',
            url: 'https://www.parsimpex.com/freight-2-2/full-truck-load-ftl',
          },
          {
            title: 'Groupage Services',
            url: 'https://www.parsimpex.com/freight-2-2/groupage-services',
          },
          {
            title: 'Reefer Trucks',
            url: 'https://www.parsimpex.com/freight-2-2/reefer-truck',
          },
          {
            title: 'Dangerous Goods Shipments (ADR)',
            url: 'https://www.parsimpex.com/freight-2-2/dangerous-goods-shipments',
          },
          {
            title: 'Project Cargo',
            url: 'https://www.parsimpex.com/freight-2-2/oversize',
          },
        ],
      },
      {
        title: 'Air Freight',
        url: 'https://www.parsimpex.com/freight-2-2/air-freight',
        submenu: [
          {
            title: 'Air Freight Shipments',
            url: 'https://www.parsimpex.com/freight-2-2/air-freight/airfreight-shipments',
          },
          {
            title: 'Air Chartering',
            url: 'https://www.parsimpex.com/freight-2-2/air-freight/air-chartering',
          },
          {
            title: 'Operating Airports',
            url: 'https://www.parsimpex.com/freight-2-2/air-freight/operating-airports',
          },
        ],
      },
      {
        title: 'Sea Freight',
        url: 'https://www.parsimpex.com/freight-2-2/sea-freight',
      },
      {
        title: 'Rail Freight',
        url: 'https://www.parsimpex.com/freight-2-2/rail-freight-2',
      },
      {
        title: 'Project cargo',
        url: 'https://www.parsimpex.com/freight-2-2/oversize',
      },
      {
        title: 'Our Freight Destinations',
        url: 'https://www.parsimpex.com/freight-2-2/our-destinations',
        submenu: [
          {
            title: 'Caucasus',
            url: 'https://www.parsimpex.com/freight-2-2/our-destinations/caucasus',
          },
          {
            title: 'Middle East',
            url: 'https://www.parsimpex.com/freight-2-2/our-destinations/middle-east',
          },
          {
            title: 'Central Asia',
            url: 'https://www.parsimpex.com/freight-2-2/our-destinations/central-asia',
          },
          {
            title: 'Russia, Belarus and Ukraine',
            url: 'https://www.parsimpex.com/freight-2-2/our-destinations/russia-belarus-ukraine',
          },
          {
            title: 'Europe',
            url: 'https://www.parsimpex.com/freight-2-2/our-destinations/europe/',
          },
          {
            title: 'All Destinations',
            url: 'https://www.parsimpex.com/freight-2-2/our-destinations/all-destinations',
          },
        ],
      },
    ],
  },
  {
    title: 'Logistics',
    url: 'https://www.parsimpex.com/logistics',
    submenu: [
      {
        title: 'Cargo Handling',
        url: 'https://www.parsimpex.com/logistics/cargo-handling',
      },
      {
        title: 'Consolidation / Deconsolidation',
        url: 'https://www.parsimpex.com/consolidation-deconsolidation/',
      },
      {
        title: 'Cross Stuffing',
        url: 'https://www.parsimpex.com/logistics/crosstuffing',
      },
    ],
  },
  {
    title: 'Custom clearance',
    url: '#',
    submenu: [
      {
        title: 'Import / Export Custom Clearance',
        url: 'https://www.parsimpex.com/import-export-customs-clearance',
      },
      {
        title: 'VAT Deposit',
        url: 'https://www.parsimpex.com/vat-deposit',
      },
      {
        title: 'Custom Warehouse',
        url: 'https://www.parsimpex.com/custom-clearance/custom-warehouse',
      },
      {
        title: 'Our Custom Offices',
        url: 'https://www.parsimpex.com/our-custom-offices',
      },
    ],
  },
  {
    title: 'Contact us',
    url: '#',
    submenu: [
      {
        title: 'Our Offices',
        url: 'https://www.parsimpex.com/our-offices',
      },
      {
        title: 'Free Consulting Request',
        url: 'https://www.parsimpex.com/free-consulting-request',
      },
      {
        title: 'Request a Quote now',
        url: 'https://www.parsimpex.com/request-a-quote-now',
      },
    ],
  },
]
