import { useCallback, useEffect, useState } from 'react'
import { useMemo } from 'react'

//Hooks
import useContactAutocomplete from 'hooks/useContactAutocomplete'

//Atlassian
import { Label } from '@atlaskit/form'

//Interfaces
import { useFormContext } from '@royan-co/user-interface'

//Components
import ContactSelect from './ContactSelect'

const ContactFieldCc = ({
  options = [],
  isLoading,
  isDisabled,
  defaultValue,
  onCreateNewContact,
  className,
}) => {
  const [selectedOptions, setSelectedOptions] = useState(defaultValue)
  const autocomplete = useMemo(() => !(options?.length > 0), [options])

  const { contactAutocompleteData, setTermContactAutocomplete, isLoadingContactAutocomplete } =
    useContactAutocomplete()
  const { watch, setValue } = useFormContext()

  const toValue = watch('to')

  const createOptions = useCallback(
    (contacts) => {
      if (!contacts) return []

      const mergedContacts = contacts
        ?.filter((c) => toValue?.id_auto !== c?.id_auto)
        ?.map((contact) => ({
          label: contact?.full_name + ' (' + contact?.email + ')',
          value: { id_auto: contact.id_auto, email: contact?.email },
        }))

      return mergedContacts
    },
    [toValue?.id_auto]
  )

  const fieldOptions = useMemo(() => {
    return createOptions(autocomplete ? contactAutocompleteData : options)
  }, [autocomplete, contactAutocompleteData, createOptions, options])

  const isLoadingOptions = useMemo(
    () => (autocomplete ? isLoadingContactAutocomplete : isLoading),
    [autocomplete, isLoading, isLoadingContactAutocomplete]
  )

  useEffect(() => {
    setValue(
      'cc',
      selectedOptions?.map((c) => c?.value)
    )
  }, [selectedOptions, setValue])

  return (
    <div className={className}>
      <Label htmlFor="Cc">Cc</Label>
      <ContactSelect
        isClearable
        isMulti
        inputId="Cc"
        placeholder="Choose"
        options={fieldOptions}
        value={selectedOptions}
        isLoading={isLoadingOptions}
        onInputChange={(newValue) => {
          if (autocomplete) setTermContactAutocomplete(newValue)
        }}
        onChange={(options) => {
          setSelectedOptions(options)
        }}
        onCreateNewContact={onCreateNewContact}
        isDisabled={isDisabled}
      />
    </div>
  )
}

export default ContactFieldCc
