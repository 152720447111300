// Components
import ImportToARCAAction from './ImportToARCAAction'
import RegisteringInvoiceAction from './RegisteringInvoiceAction'
import ResetInvoiceAction from './ResetInvoiceAction'
import EditInvoiceDropdownItem from '../EditInvoiceDropdownItem'
import IssueCreditNoteDropdownItem from '../IssueCreditNoteDropdownItem'
import DeleteInvoiceAction from './delete'

const ActiveInvoiceActions = ({ invoice, shipmentId, openDeleteModal }) => {
  return (
    <>
      <EditInvoiceDropdownItem invoice={invoice} />

      <IssueCreditNoteDropdownItem invoice={invoice} />

      <ImportToARCAAction invoice={invoice} shipmentId={shipmentId} />

      <ResetInvoiceAction invoice={invoice} isActive shipmentId={shipmentId} />

      <RegisteringInvoiceAction invoice={invoice} shipmentId={shipmentId} />

      <DeleteInvoiceAction invoice={invoice} openDeleteModal={openDeleteModal} />
    </>
  )
}

export default ActiveInvoiceActions
