// Utils
import { findOption } from 'utils/helpers'
import {
  invoiceTypeOptions,
  invoiceProformaOptions,
  invoiceImportedARCAOptions,
  invoicePaidOptions,
  invoiceMultipleOptions,
  invoiceVisibilityOperatorOptions,
  registrationTypeOptions,
  invoiceCMROptions,
  invoiceDisputedOptions,
} from '../constants'

// Hooks
import useShipmentsEnumerations from 'hooks/useShipmentsEnumerations'
import useInvoiceEnumerations from 'hooks/useInvoiceEnumerations'
import usePaymentBanks from 'hooks/usePaymentBanks'
import useShipmentsOperators from 'hooks/useShipmentsOperators'

// Atlassian
import Spinner from '@atlaskit/spinner'

const DocTypeFilter = ({ value }) => {
  const { docCodesOptions, isLoadingInvoiceEnumerations } = useInvoiceEnumerations()
  return (
    <>
      Doc type:{' '}
      {isLoadingInvoiceEnumerations ? (
        <Spinner size="xsmall" />
      ) : (
        findOption(docCodesOptions, value)?.label
      )}
    </>
  )
}

const CurrencyFilter = ({ value }) => {
  const { getEnumerationOptionsByKey, isLoadingShipmentEnumeration } = useShipmentsEnumerations()
  return (
    <>
      Currency:{' '}
      {isLoadingShipmentEnumeration ? (
        <Spinner size="xsmall" />
      ) : (
        findOption(getEnumerationOptionsByKey('currencies'), value)?.label
      )}
    </>
  )
}

const OperatorsFilter = ({ value, label }) => {
  const { shipmentsOperatorsOptions, isLoadingShipmentsOperators } = useShipmentsOperators()

  return (
    <>
      {`${label}: `}
      {isLoadingShipmentsOperators ? (
        <Spinner size="xsmall" />
      ) : (
        findOption(shipmentsOperatorsOptions, value)?.label
      )}
    </>
  )
}

const BanksFilter = ({ value }) => {
  const { paymentBankOptions, isLoadingPaymentBanks } = usePaymentBanks()
  return (
    <>
      Banks:{' '}
      {isLoadingPaymentBanks ? (
        <Spinner size="xsmall" />
      ) : (
        findOption(paymentBankOptions, value)?.label
      )}
    </>
  )
}

export const invoicesFilters = [
  {
    key: 'do_boolean',
    content: (v) => `Invoice type: ${findOption(invoiceTypeOptions, v)?.label}`,
  },
  {
    key: 'is_proforma',
    content: (v) => `Proforma: ${findOption(invoiceProformaOptions, v)?.label}`,
  },
  {
    key: 'do',
    content: (v) => <DocTypeFilter value={v} />,
  },
  {
    key: 'shipment',
    content: (v) => `Shipment reference: ${v}`,
  },
  {
    key: 'consolidation',
    content: (v) => `Consolidation reference: ${v}`,
  },
  {
    key: 'client',
    content: (v) => `Client: ${v}`,
  },
  {
    key: 'supplier',
    content: (v) => `Supplier: ${v}`,
  },
  {
    key: 'total_amount',
    content: (v) => `Amount: ${v}`,
  },
  {
    key: 'currency',
    content: (v) => <CurrencyFilter value={v} />,
  },
  {
    key: 'operator_id',
    content: (v) => <OperatorsFilter value={v} label="Operator" />,
  },
  {
    key: 'hide_buffer',
    content: (v) =>
      `Visibility for operator: ${findOption(invoiceVisibilityOperatorOptions, v)?.label}`,
  },
  {
    key: 'creator',
    content: (v) => <OperatorsFilter value={v} label="Creator" />,
  },
  {
    key: 'imported',
    content: (v) => `Imported in accounting: ${findOption(invoiceImportedARCAOptions, v)?.label}`,
  },
  {
    key: 'is_payd',
    content: (v) =>
      `Paid: ${v
        .toString()
        .split(',')
        .map((i) => findOption(invoicePaidOptions, +i)?.label)
        .join(', ')}`,
  },
  {
    key: 'arca_status',
    content: (v) => `Registration: ${findOption(registrationTypeOptions, v)?.label}`,
  },
  {
    key: 'has_cmr',
    content: (v) => `CMR: ${findOption(invoiceCMROptions, v)?.label}`,
  },
  {
    key: 'registry_bank_id',
    content: (v) => <BanksFilter value={v} />,
  },
  {
    key: 'multiple',
    content: (v) => `Multiple: ${findOption(invoiceMultipleOptions, v)?.label}`,
  },
  {
    key: 'disputed',
    content: (v) => `Disputed: ${findOption(invoiceDisputedOptions, v)?.label}`,
  },
  {
    key: 'issued_from',
    content: (v) => `Issued from: ${v}`,
  },
  {
    key: 'issued_to',
    content: (v) => `Issued to: ${v}`,
  },
]
