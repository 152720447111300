import { useQuery } from '@tanstack/react-query'

// Apis
import { getFinanceDetails } from 'api/consolidations'

const useConsolidationFinance = (consolidationId) => {
  const queryKey = ['consolidation', 'groupage', consolidationId]

  return useQuery({
    queryKey,
    queryFn: () => getFinanceDetails(consolidationId),
    retry: 2,
    staleTime: Infinity,
  })
}

export default useConsolidationFinance
