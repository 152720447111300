import { findOption } from 'utils/helpers'
import { quotationStatusOptions, shipmentStatusOptions } from './constants'

//Atlassian
import Tag from '@atlaskit/tag'

const ShipmentStatusTag = ({ value, entity }) => {
  const opt = findOption(
    entity === 'shipment' ? shipmentStatusOptions : quotationStatusOptions,
    value
  )

  return opt ? (
    <div className="removeWidthLimitFromAtlaskitTag">
      <Tag
        isRemovable={false}
        color={entity === 'shipment' ? 'standard' : opt.appearance}
        text={opt.label}
      />
    </div>
  ) : (
    ''
  )
}

export default ShipmentStatusTag
