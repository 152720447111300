import React, { useState } from 'react'

// Hooks
import { useChart } from '../api/useChart'

// Atlassian
import Spinner from '@atlaskit/spinner'
import SectionMessage from '@atlaskit/section-message'
import Button from '@atlaskit/button'
import Select from '@atlaskit/select'
import { Label } from '@atlaskit/form'

// Components
import Card, { CardTitle } from 'components/UI/Card'
import ShipmentsChart from './ShipmentsChart'

const yearsOptions = Array.from({ length: 5 }, (_, i) => new Date().getFullYear() - i).map(
  (year) => ({
    label: year,
    value: year,
  })
)

const ViewDashboardShipments = () => {
  const [year, setYear] = useState(yearsOptions[0])

  const { data, isLoading, isError, refetch, isFetching } = useChart({
    type: 'shipments',
    year: year.value,
  })

  return (
    <Card>
      <div className="flex justify-between mb-4">
        <CardTitle className="mb-2 text-base">Shipments</CardTitle>

        <div className="flex items-center gap-1">
          <Label htmlFor="to">Year:</Label>
          <Select
            options={yearsOptions}
            value={year}
            onChange={(v) => setYear(v)}
            spacing="compact"
          />
        </div>
      </div>

      {isLoading || isFetching ? (
        <div className="w-full text-center my-20">
          <Spinner />
        </div>
      ) : isError ? (
        <div className="my-2">
          <SectionMessage
            appearance="warning"
            title="Something wrong on loading data, please retry"
          >
            <Button onClick={() => refetch()}>Retry</Button>
          </SectionMessage>
        </div>
      ) : (
        <ShipmentsChart data={data} />
      )}
    </Card>
  )
}

export default ViewDashboardShipments
