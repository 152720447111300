import { useEffect, useState } from 'react'
import set from 'lodash/set'
import cloneDeep from 'lodash/cloneDeep'

// Atlassian
import TextArea from '@atlaskit/textarea'
import Textfield from '@atlaskit/textfield'
import InlineEdit from '@atlaskit/inline-edit'

// Assets
import './styles.scss'
import fbl100 from '../../assets/fbl-100.png'
import logoUrl from 'assets/images/psxcargo.png'

const FBLFields = {
  notNegotiable: true,
  number: '',
  consignor: '',
  consigned: '',
  notifyAddress: '',
  por: '',
  pol: '',
  pod: '',
  podis: '',
  vessel: '',
  description: '',
  description2: '',
  declarationConsignor: '',
  declaredValorem: '',
  freightAmount: '',
  freightPayableAt: '',
  placeAndDateOfIssue: '',
  noOfOriginals: '',
  signature: '',
  applyTo: '',
  notCovered: false,
  covered: false,
  packages: [{ number: '', packages: '', goods: '', gross: '', measurement: '' }],
}

const FBLForm = ({ defaultValues, onChange, companyLogo, previewMode }) => {
  const placeHolder = ''

  const [formData, setFormData] = useState({
    ...FBLFields,
    ...cloneDeep(defaultValues),
  })

  function onChangeFormData(key, value) {
    const NewFormData = {
      ...formData,
      ...set(formData, key, value),
    }
    onChange?.(NewFormData)
    setFormData(NewFormData)
  }

  useEffect(() => {
    onChange?.({
      ...FBLFields,
      ...cloneDeep(defaultValues),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="paper-document-wrapper">
      <div className="paper-document">
        <div className="fix w-50 h-7line border-bottom border-right l-0">
          <div>Consignor</div>
          <div style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.consignor}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="6"
                  className="overflow-hidden"
                  maxHeight={6 * 1.618 + 'em'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-6line read-view-text-aria">
                  {formData.consignor || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'consignor')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-50 h-7line border-bottom l-50"></div>

        <div className="fix w-10 h-5line l-52">
          {companyLogo && <img src={companyLogo} alt="" />}
        </div>

        <label
          className="fix w-10 h-2line top-5line l-52 pt-0-5rem text-center line-height-1-2"
          style={{ opacity: formData.notNegotiable ? 1 : 0.5 }}
        >
          <input
            type="checkbox"
            defaultChecked={formData.notNegotiable}
            onChange={(e) => onChangeFormData('notNegotiable', e.target.checked)}
            disabled={previewMode === undefined ? false : true}
            readOnly={previewMode === undefined ? false : true}
          />{' '}
          non negotiable
          <br />
          Copy
        </label>

        <div className="fix w-25 l-75 border h-1line py-1mm z-10">
          <div className="px-2mm input-1line" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.number}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className="h-1line read-view-text">{formData.number || placeHolder}</div>
              )}
              onConfirm={onChangeFormData.bind(null, 'number')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-35 h-7line l-65 pt-2mm line-height-1">
          <div className="bold size-2">FBL</div>
          <br />
          <div className="size-1-1 line-height-1-2">
            NEGOTIABLE FIATA
            <br />
            MULTIMODAL TRANSPORT
            <br />
            BILL OF LADING
          </div>

          <div className="line-height-n">
            issued subject to UNCTAD / ICC Rules for
            <br />
            Multimodal Transport Documents (ICC Publication 481)
          </div>
        </div>

        <div className="fix w-10 l-90 top-2line pt-2mm">
          <img src={fbl100} alt="" />
        </div>

        <div className="fix w-50 h-14line top-7line l-50">
          <div style={{ marginTop: '-6px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.description}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="20"
                  className="overflow-hidden"
                  maxHeight={20 * 1.618 + 'em'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-20line px-2mm read-view-text-aria">
                  {formData.description || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'description')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-50 l-50 top-21line h-6line border-bottom">
          <div className="h-0-5line" />
          <img className="h-4-5line" src={logoUrl} alt="" />
        </div>

        <div className="fix w-50 h-7line border-bottom border-right top-7line l-0">
          <div>Consigned to order of</div>
          <div style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.consigned}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="6"
                  className="overflow-hidden"
                  maxHeight={6 * 1.618 + 'em'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-6line read-view-text-aria">
                  {formData.consigned || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'consigned')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-50 h-7line border-bottom border-right top-14line l-0">
          <div>Notify address</div>
          <div style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.notifyAddress}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="6"
                  className="overflow-hidden"
                  maxHeight={6 * 1.618 + 'em'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-6line read-view-text-aria">
                  {formData.notifyAddress || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'notifyAddress')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-25 h-2line border-bottom top-21line l-0"></div>

        <div className="fix w-25 h-2line border-bottom border-right top-21line l-25">
          <div className="px-2mm">Place of receipt</div>
          <div className="px-2mm input-1line" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.por}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className="h-1line read-view-text">{formData.por || placeHolder}</div>
              )}
              onConfirm={onChangeFormData.bind(null, 'por')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-25 h-2line border-bottom border-right top-23line l-0">
          <div>Ocean vessel</div>
          <div className="input-1line" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.vessel}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className="h-1line read-view-text">{formData.vessel || placeHolder}</div>
              )}
              onConfirm={onChangeFormData.bind(null, 'vessel')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-25 h-2line border-bottom border-right top-23line l-25">
          <div className="px-2mm">Port of loading</div>
          <div className="px-2mm input-1line" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.pol}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className="h-1line read-view-text">{formData.pol || placeHolder}</div>
              )}
              onConfirm={onChangeFormData.bind(null, 'pol')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-25 h-2line border-bottom border-right top-25line l-0">
          <div>Port of discharge</div>
          <div className="input-1line" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.podis}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className="h-1line read-view-text">{formData.podis || placeHolder}</div>
              )}
              onConfirm={onChangeFormData.bind(null, 'podis')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-25 h-2line border-bottom border-right top-25line l-25">
          <div className="px-2mm">Place of delivery</div>
          <div className="px-2mm input-1line" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.pod}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className="h-1line read-view-text">{formData.pod || placeHolder}</div>
              )}
              onConfirm={onChangeFormData.bind(null, 'pod')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix top-27line w-25 l-0">
          <div>Marks and numbers</div>
          <div className="input-1line" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.packages[0].number}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className="h-1line read-view-text">
                  {formData.packages[0].number || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'packages.0.number')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix top-27line w-25 l-25">
          <div>Number and kind of packages</div>
          <div className="input-1line" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.packages[0].packages}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className="h-1line read-view-text">
                  {formData.packages[0].packages || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'packages.0.packages')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix top-27line w-20 l-50">
          <div>Description of goods</div>
          <div className="input-2line" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.packages[0].goods}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="2"
                  className="overflow-hidden"
                  maxHeight={2 * 1.618 + 'em'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-2line read-view-text">
                  {formData.packages[0].goods || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'packages.0.goods')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix top-27line w-15 l-70">
          <div>Gross weight</div>
          <div className="input-1line" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.packages[0].gross}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className="h-1line read-view-text">
                  {formData.packages[0].gross || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'packages.0.gross')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix top-27line w-15 l-85">
          <div>Measurement</div>
          <div className="input-1line" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.packages[0].measurement}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className="h-1line read-view-text">
                  {formData.packages[0].measurement || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'packages.0.measurement')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-100 h-12line top-31line">
          <div style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.description2}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="6"
                  className="overflow-hidden"
                  maxHeight={6 * 1.618 + 'em'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-12line read-view-text-aria">
                  {formData.description2 || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'description2')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-100 top-43line border-bottom text-center">
          according to the declaration of the consignor
        </div>

        <div className="fix w-33 l-0 top-45line">
          Declaration of Interest of the consignor
          <br />
          in timely delivery (Clause 6.2.)
        </div>

        <div className="fix w-33 l-0 top-47line">
          <div style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.declarationConsignor}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="2"
                  className="overflow-hidden"
                  maxHeight={2 * 1.618 + 'em'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div
                  className="px-2mm h-2line border read-view-text-aria"
                  style={{
                    minHeight: 2 * 1.618 + 'em',
                  }}
                >
                  {formData.declarationConsignor || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'declarationConsignor')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-33 l-67 top-45line">
          Declared value for ad valorem rate according to
          <br />
          the declaration of the consignor (Clauses 7 and 8).
        </div>

        <div className="fix w-33 l-67 top-47line">
          <div style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.declaredValorem}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="2"
                  className="overflow-hidden"
                  maxHeight={2 * 1.618 + 'em'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div
                  className="px-2mm h-2line border read-view-text-aria"
                  style={{
                    minHeight: 2 * 1.618 + 'em',
                  }}
                >
                  {formData.declaredValorem || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'declaredValorem')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-100 l-0 top-50line h-5line">
          The goods and instructions are accepted and dealt with subject to the Standard Conditions
          printed overleaf.
          <br />
          Taken in charge in apparent good order and condition, unless otherwise noted herein, at
          the place of receipt for transport and delivery as mentioned above.
          <br />
          One of these Multimodal Transport Bills of Lading must be surrendered duly endorsed in
          exchange for the goods. In Witness whereof the original Multimodal Transport Bills of
          Lading all of this tenor and date have been signed in the number stated below, one of
          which being accomplished the other(s) to be void.
        </div>

        <div className="fix w-40 l-0 top-55line h-2line border-left border-top">
          <div className="px-2mm">Freight amount</div>
          <div className="px-2mm input-1line" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.freightAmount}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className="h-1line read-view-text">
                  {formData.freightAmount || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'freightAmount')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-25 l-40 top-55line h-2line border-left border-top">
          <div className="px-2mm">Freight payable at</div>
          <div className="px-2mm input-1line" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.freightPayableAt}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className="h-1line read-view-text">
                  {formData.freightPayableAt || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'freightPayableAt')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-35 l-65 top-55line h-2line border-left border-top">
          <div className="px-2mm">Place and date of issue</div>
          <div className="px-2mm input-1line" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.placeAndDateOfIssue}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className="h-1line read-view-text">
                  {formData.placeAndDateOfIssue || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'placeAndDateOfIssue')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-40 l-0 top-57line h-2line border-left border-top">
          <div className="px-2mm">Cargo Insurance through the undersigned</div>
          <div className="px-2mm">
            <label>
              <input
                type="checkbox"
                defaultChecked={formData.notCovered}
                onChange={(e) => onChangeFormData('notCovered', e.target.checked)}
                disabled={previewMode === undefined ? false : true}
                readOnly={previewMode === undefined ? false : true}
              />{' '}
              not covered
            </label>
            &nbsp;&nbsp;&nbsp;
            <label>
              <input
                type="checkbox"
                defaultChecked={formData.covered}
                onChange={(e) => onChangeFormData('covered', e.target.checked)}
                disabled={previewMode === undefined ? false : true}
                readOnly={previewMode === undefined ? false : true}
              />{' '}
              Covered according to attached Policy
            </label>
          </div>
        </div>

        <div className="fix w-25 l-40 top-57line h-2line border-left border-top">
          <div className="px-2mm">Number of Original FBL’s</div>
          <div className="px-2mm input-1line" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.noOfOriginals}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className="h-1line read-view-text">
                  {formData.noOfOriginals || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'noOfOriginals')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-35 l-65 top-57line border-left border-top">
          <div className="px-2mm">Stamp and signature</div>
          <div style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.signature}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="4"
                  className="overflow-hidden"
                  maxHeight={4 * 1.618 + 'em'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div
                  className="px-2mm h-4line read-view-text-aria"
                  style={{
                    minHeight: 4 * 1.618 + 'em',
                  }}
                >
                  {formData.signature || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'signature')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-65 l-0 top-59line border-left border-top">
          <div className="px-2mm">For delivery of goods please apply to:</div>
          <div style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.applyTo}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="3"
                  className="overflow-hidden"
                  maxHeight={3 * 1.618 + 'em'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div
                  className="px-2mm h-3line read-view-text-aria"
                  style={{
                    minHeight: 3 * 1.618 + 'em',
                  }}
                >
                  {formData.applyTo || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'applyTo')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default FBLForm
