import axios from 'services/Request'

export function getPaymentTerms(params) {
  return axios.get('/api/accounting/payment-codes?paginate=1', { params })
}

export function createPaymentTerm(data) {
  return axios.post('/api/accounting/payment-codes', data)
}

export function editPaymentTerm(codeId, data) {
  return axios.put(`/api/accounting/payment-codes/${codeId}`, data)
}

export function deletePaymentTerm(codeId) {
  return axios.delete(`/api/accounting/payment-codes/${codeId}`)
}
