import { useParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

import useShipment from 'hooks/useShipment'
import { deleteShareTrackingContact } from 'api/shipments'

//Atlassian
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import Button from '@atlaskit/button'
import { LoadingButton } from '@atlaskit/button'

//Interfaces
import { useFlags, WarningBanner } from '@royan-co/user-interface'

const DeleteShareTrackingModal = ({ isOpen, onClose, deletingContact }) => {
  const { shipmentId, quotationId } = useParams()
  const entityId = shipmentId || quotationId
  const { showSuccessFlag } = useFlags()
  const { deleteTrackingContactFromData } = useShipment(entityId)

  const deleteContactMutation = useMutation(deleteShareTrackingContact)

  function closeModal() {
    onClose()
    deleteContactMutation.reset()
  }

  const handleSubmit = () => {
    deleteContactMutation.mutate(deletingContact.id, {
      onSuccess: (res) => {
        deleteTrackingContactFromData(deletingContact.id)
        showSuccessFlag(res.message)
        closeModal()
      },
    })
  }

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={closeModal}>
          <ModalHeader>
            <ModalTitle appearance="danger">Delete share tracking contact</ModalTitle>
          </ModalHeader>

          <ModalBody>
            <p>Do you want to delete this contact?</p>
            {deleteContactMutation.isError && <WarningBanner />}
          </ModalBody>

          <ModalFooter>
            <Button appearance="subtle" onClick={closeModal}>
              Cancel
            </Button>
            <LoadingButton
              appearance="danger"
              onClick={handleSubmit}
              isLoading={deleteContactMutation.isLoading}
              autoFocus
            >
              Delete
            </LoadingButton>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  )
}

export default DeleteShareTrackingModal
