import React from 'react'
import { useMutation } from '@tanstack/react-query'

// Apis
import { deleteShipmentCargoDetails } from 'api/shipments'

// Hooks
import useShipmentCargo from 'hooks/useShipmentCargo'

// Atlassian
import Modal, {
  ModalBody,
  ModalTitle,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import Button, { LoadingButton } from '@atlaskit/button'

// Interfaces
import { WarningBanner, useFlags } from '@royan-co/user-interface'

const DeleteCargoModal = ({ isOpen, onClose, name, cargoId, shipmentId }) => {
  const { showSuccessFlag } = useFlags()
  const { deleteCargoFromData } = useShipmentCargo(shipmentId)

  const deleteCargoTruckMutation = useMutation(deleteShipmentCargoDetails)

  const onDeleteCargo = () => {
    deleteCargoTruckMutation.mutate(cargoId, {
      onSuccess: (res) => {
        deleteCargoFromData(cargoId)
        onClose()
        showSuccessFlag(res?.message)
      },
    })
  }

  const onCloseModal = () => {
    onClose()
    deleteCargoTruckMutation.reset()
  }

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={onCloseModal}>
          <ModalHeader>
            <ModalTitle appearance="danger">Delete {name}</ModalTitle>
          </ModalHeader>
          <ModalBody>
            You are about to delete a {name}. Are you sure?
            {deleteCargoTruckMutation.isError && <WarningBanner />}
          </ModalBody>
          <ModalFooter>
            <Button appearance="subtle" onClick={onCloseModal}>
              Cancel
            </Button>
            <LoadingButton
              isLoading={deleteCargoTruckMutation.isLoading}
              appearance="danger"
              onClick={onDeleteCargo}
              autoFocus
            >
              Delete
            </LoadingButton>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  )
}

export default DeleteCargoModal
