import { useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

//Apis
import { getShipmentEntrance } from 'api/shipments'

//Utils
import { getCountry } from 'utils/helpers'
import { formatNumber } from 'utils/numbers'

//Atlassian
import Button from '@atlaskit/button'
import Lozenge from '@atlaskit/lozenge'
import Spinner from '@atlaskit/spinner'
import Tooltip from '@atlaskit/tooltip'
import PageHeader from '@atlaskit/page-header'
import EmptyState from '@atlaskit/empty-state'
import SectionMessage from '@atlaskit/section-message'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'

import { DynamicTableStateless } from '@atlaskit/dynamic-table'

//Interfaces
import { Grid, Skeleton, useDisclosure, InfoIcon } from '@royan-co/user-interface'

//Components
import Card from 'components/UI/Card'
import RowTable from 'components/UI/RowTable'
import EntranceActions from 'components/Shipments/View/EntranceActions'
import ShipmentDeformedCargoModal from 'components/Shipments/View/DeformedCargoModal'

const ShipmentViewEntrancesViewPage = () => {
  const { entranceId } = useParams()
  const [isOpenDeformedImagesModal, deformedImagesModalHandle] = useDisclosure('deformed')

  const {
    data: entrance,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ['entrance', entranceId],
    queryFn: () => getShipmentEntrance(entranceId),
  })

  const generalRows = useMemo(
    () =>
      [
        {
          title: 'Status',
          content: entrance?.partial ? (
            <Lozenge appearance="moved">Partial</Lozenge>
          ) : entrance?.collies_deformity ? (
            <Lozenge appearance="removed">Deformed</Lozenge>
          ) : (
            <Lozenge appearance="success">Complete</Lozenge>
          ),
        },
        {
          title: 'Creator',
          content: entrance?.creator?.full_name || <Lozenge>Not set</Lozenge>,
        },
        {
          title: 'Carrier',
          content: entrance?.carrier?.company_name ? (
            entrance?.carrier?.company_name
          ) : (
            <Lozenge appearance="default">Not set</Lozenge>
          ),
          show: entrance?.shipment?.shipment_type === 2,
        },
        {
          title: 'Waybill no.',
          content: entrance?.waybill || <Lozenge appearance="default">Not set</Lozenge>,
          show: entrance?.shipment?.shipment_type === 2,
        },
        {
          title: 'Total collies',
          content: entrance?.total_collies ? (
            <span>{entrance?.total_collies} collies</span>
          ) : (
            <Lozenge appearance="default">Not set</Lozenge>
          ),
        },
        {
          title: 'Total weight of consignment',
          content: entrance?.weight ? (
            <span>{formatNumber(entrance?.weight)} kgs</span>
          ) : (
            <Lozenge appearance="default">Not set</Lozenge>
          ),
        },
        {
          title: 'Hub',
          content: entrance?.hub?.name || <Lozenge appearance="default">Not set</Lozenge>,
        },
        {
          title: 'Warehouse reference',
          content: entrance?.microzone || <Lozenge appearance="default">Not set</Lozenge>,
        },
      ].filter((r) => r.show !== false),
    [entrance]
  )

  const shipmentRows = useMemo(
    () => [
      {
        title: 'Shipment reference',
        content: entrance?.shipment?.shipment_code,
      },
      {
        title: 'Origin',
        content: (
          <span>
            {getCountry(entrance?.shipment?.departure_country)?.icon}
            {entrance?.shipment?.departure_city ? ` ${entrance?.shipment.departure_city}, ` : ''}
            {getCountry(entrance?.shipment?.departure_country)?.name}
          </span>
        ),
      },
      {
        title: 'Destination',
        content: (
          <span>
            {getCountry(entrance?.shipment?.arrival_country)?.icon}
            {entrance?.shipment?.arrival_city ? ` ${entrance?.shipment.arrival_city}, ` : ''}
            {getCountry(entrance?.shipment?.arrival_country)?.name}
          </span>
        ),
      },
      {
        title: 'Shipper',
        content: entrance?.shipment?.shipper?.company_name ? (
          entrance?.shipment?.shipper?.company_name
        ) : (
          <Lozenge appearance="default">Not set</Lozenge>
        ),
      },
      {
        title: 'Consignee',
        content: entrance?.shipment?.consignee?.company_name ? (
          entrance?.shipment?.consignee.company_name
        ) : (
          <Lozenge appearance="default">Not set</Lozenge>
        ),
      },

      {
        title: 'Notify',
        content:
          entrance?.shipment?.notify?.id_auto &&
          entrance?.shipment?.notify?.id_auto === entrance?.shipment?.consignee?.id_auto ? (
            <Lozenge>Same as consignee</Lozenge>
          ) : entrance?.shipment?.notify?.id_auto ? (
            entrance?.shipment?.notify?.company_name
          ) : (
            <Lozenge appearance="default">Not set</Lozenge>
          ),
      },
    ],
    [entrance]
  )

  const packagesHeads = {
    cells: [
      { key: 'type', content: 'Type' },
      { key: 'quantity', content: 'Quantity' },
      { key: 'dimensions', content: 'Dimensions (W x L x H)' },
      { key: 'weight', content: 'Weight' },
      { key: 'status', content: 'Status' },
    ],
  }

  const packagesRows = useMemo(
    () =>
      entrance?.cargo?.map((item, i) => {
        // const demormedDimentions =
        return {
          key: `item${i}`,
          cells: [
            {
              key: `type${i}`,
              content: <div className="my-1">{item?.parent?.box_type}</div>,
            },
            {
              key: `quantity${i}`,
              content: [item?.number || 0, item?.parent?.number || 0].join('/'),
            },
            {
              key: `dimensions${i}`,
              content: (
                <div className="whitespace-nowrap flex items-center gap-1">
                  {item.width || item.height || item.length ? (
                    <>
                      {['width', 'length', 'height'].map((key, i) => (
                        <span key={key}>
                          {item[key] ? (
                            <span
                              className={item[key] !== item.parent?.[key] ? 'text-red-400' : ''}
                            >
                              {item[key]}m
                            </span>
                          ) : (
                            '-'
                          )}
                          {i !== 2 && ' x '}
                        </span>
                      ))}
                    </>
                  ) : (
                    <Lozenge appearance="default">Not set</Lozenge>
                  )}

                  {['width', 'length', 'height'].some(
                    (key) => item[key] !== item.parent?.[key]
                  ) && (
                    <Tooltip
                      content={
                        <>
                          {['width', 'length', 'height']
                            .map((key) => (item.parent?.[key] ? `${item.parent[key]}m` : '-'))
                            .join(' x ')}
                        </>
                      }
                    >
                      {(tooltipProps) => (
                        <div className="text-neutral-500" {...tooltipProps}>
                          <InfoIcon size={18} />
                        </div>
                      )}
                    </Tooltip>
                  )}
                </div>
              ),
            },
            {
              key: `weight${i}`,
              content: item.weight ? (
                <span className="whitespace-nowrap">{formatNumber(item.weight)} Kgs</span>
              ) : (
                <Lozenge appearance="default">Not set</Lozenge>
              ),
            },
            {
              key: `status${i}`,
              content: (
                <div>
                  {item?.not_arrived === 1 && <Lozenge appearance="moved">Missing</Lozenge>}

                  {item?.not_arrived !== 1 && item?.parent?.number > item?.number && (
                    <Lozenge appearance="moved">Partial</Lozenge>
                  )}

                  {item?.not_arrived !== 1 && item?.deformity === 1 && (
                    <span>
                      <Lozenge appearance="removed">Deformed</Lozenge>
                      {item?.deformity_images?.length > 0 ? (
                        <button
                          onClick={() => deformedImagesModalHandle.open(item?.deformity_images)}
                        >
                          <InfoIcon size={16} className="text-neutral-500 ml-2" />
                        </button>
                      ) : null}
                    </span>
                  )}

                  {item?.not_arrived !== 1 &&
                    item?.parent?.number === item?.number &&
                    item?.deformity !== 1 && <Lozenge appearance="success">Complete</Lozenge>}
                </div>
              ),
            },
          ],
        }
      }),
    [deformedImagesModalHandle, entrance]
  )

  return (
    <>
      <Helmet>
        <title>{`Entrance ${entrance?.numerator}`}</title>
        <meta name='backUrl' content={`/panel/shipments/${entrance?.shipment?.id_auto || ''}`} />
      </Helmet>

      <Grid className="w-full">
        <Grid.Col md={8} xl={6} offsetMd={2} offsetXl={3}>
          <PageHeader
            breadcrumbs={
              <Breadcrumbs>
                <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
                <BreadcrumbsItem text="Shipments" to="/panel/shipments" component={Link} />
                <BreadcrumbsItem text="View Shipment" />
                <BreadcrumbsItem
                  text={isLoading ? <Spinner size="xsmall" /> : entrance?.shipment?.shipment_code}
                  to={`/panel/shipments/${entrance?.shipment_id}`}
                  component={Link}
                />
              </Breadcrumbs>
            }
            actions={<EntranceActions entrance={entrance} />}
          >
            {isLoading ? <Skeleton height={25} maw={220} /> : <>Entrance {entrance?.numerator}</>}
          </PageHeader>

          {isLoading ? (
            <div className="text-center mt-24">
              <Spinner />
            </div>
          ) : isError ? (
            <SectionMessage
              appearance="warning"
              title="Something wrong on loading data, please retry."
            >
              <Button onClick={() => refetch()}>Retry</Button>
            </SectionMessage>
          ) : (
            <>
              <Card className="mb-6">
                <RowTable rows={generalRows} />
              </Card>

              <Card title="Shipment details" className="mb-6">
                <RowTable rows={shipmentRows} />
              </Card>

              <Card title="Cargo details">
                {entrance?.cargo?.length > 0 ? (
                  <div className="overflow-auto inline-block w-full -mb-6">
                    <DynamicTableStateless head={packagesHeads} rows={packagesRows} />
                  </div>
                ) : (
                  <EmptyState header="No cargoes" />
                )}
              </Card>

              <ShipmentDeformedCargoModal
                isOpen={isOpenDeformedImagesModal}
                onClose={deformedImagesModalHandle.close}
                images={deformedImagesModalHandle.data}
              />
            </>
          )}
        </Grid.Col>
      </Grid>
    </>
  )
}

export default ShipmentViewEntrancesViewPage
