//Atlassian
import { ErrorMessage } from '@atlaskit/form'
import TextArea from '@atlaskit/textarea'

//Interfaces
import { Field } from '@royan-co/user-interface'

//Components
import Card from 'components/UI/Card'

const ShipmentPickupFormNotes = () => {
  return (
    <Card className="mt-6" title="Notes">
      <Field name="notes" label="Additional remarks" className="!mt-0">
        {({ fieldProps, error }) => (
          <>
            <TextArea placeholder="Type your content here" {...fieldProps} minimumRows={7} />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </>
        )}
      </Field>
    </Card>
  )
}

export default ShipmentPickupFormNotes
