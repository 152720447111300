import { useMemo, useState, useEffect, Fragment } from 'react'

// Styles
import styles from 'components/UI/StepLocationIndicator/StepLocationIndicator.module.scss'

// Interface
import {
  QuestionCircleIcon,
  AddIcon,
  useDisclosure,
  useFormContext,
} from '@royan-co/user-interface'

// Components
import Card from 'components/UI/Card'
import StepIcon from 'components/UI/StepLocationIndicator/StepIcon'
import StepIndicator from 'components/UI/StepLocationIndicator/StepIndicator'
import { legTypes } from 'components/Shipments/EditFinance/legTypes'
import LineRouteLegModal from './LegModal'
import LineRouteLegForm from './LegForm'

const LineRouteCreateEditLegs = () => {
  const { getValues, setValue } = useFormContext()
  const [currentLegIndex, setCurrentLegIndex] = useState(0)
  const [legs, setLegs] = useState([])
  const [isOpenLegModal, legModalHandler] = useDisclosure('leg-modal')

  const hasLegs = useMemo(() => !!legs?.length, [legs])

  useEffect(() => {
    const formLegs = getValues('legs') || []

    const mappedLegs = formLegs.map((leg) => {
      const legType = legTypes.find((type) => type.value === leg?.type)

      return {
        leg: {
          value: leg?.from_to,
          departure: legType?.departure,
          arrival: legType?.arrival,
          type: leg?.type,
        },
      }
    })

    setLegs(mappedLegs)
  }, [getValues])

  const getLegType = (departure, arrival) =>
    legTypes.find((t) => t.departure.value === departure && t.arrival.value === arrival)

  const createLeg = (data) => {
    if (hasLegs) {
      const departureNode = legTypes?.find(
        (t) => t.value === legs[legs.length - 1]?.leg?.value
      )?.arrival
      const type = getLegType(departureNode?.value, data?.node)

      if (type?.value === undefined) {
        const error = new Error()
        error.response = {
          data: {
            message: 'This leg can not be created.',
          },
        }
        throw error
      }

      setLegs((prev) => [...prev, { leg: { ...type, type: type?.value } }])
    } else {
      const leg = legTypes?.find((t) => t.value === data?.type)

      if (leg?.value !== undefined) {
        setLegs((prev) => [...prev, { leg: { ...leg, type: leg?.value } }])
      }
    }

    const index = legs.length
    setCurrentLegIndex(index)
    setValue(`legs.${index}.departure`, undefined)
    setValue(`legs.${index}.arrival`, undefined)
  }

  const editLeg = (data) => {}

  const handleLeg = (data) => {
    if (data?.index !== undefined) editLeg(data)
    else createLeg(data)

    legModalHandler.close()
  }

  const deleteLeg = (currentLeg) => {
    setLegs((data) => data.filter((item, i) => currentLeg !== i))
    setCurrentLegIndex(legs?.length - 2)
  }

  const currentLeg = useMemo(() => {
    return legs?.[currentLegIndex]?.leg
  }, [legs, currentLegIndex])

  return (
    <>
      <div className="mt-6">
        <div className={styles.progressIndicator}>
          {legs?.length > 0 ? (
            <>
              {legs?.map((step, i) => (
                <Fragment key={i}>
                  <StepIndicator
                    onLineSelect={() => setCurrentLegIndex(i)}
                    className={`${i === currentLegIndex ? styles.active : ''}`}
                  >
                    <StepIcon
                      icon={step?.leg?.departure?.icon}
                      isActive={[currentLegIndex, currentLegIndex + 1].includes(i)}
                    />
                  </StepIndicator>
                </Fragment>
              ))}

              <StepIndicator lastIndex>
                <StepIcon
                  icon={legs[legs.length - 1]?.leg?.arrival?.icon}
                  isActive={currentLegIndex === legs.length - 1}
                />
              </StepIndicator>
            </>
          ) : (
            <StepIndicator>
              <div children={<QuestionCircleIcon />} className={styles.progressIndicator__icon} />
            </StepIndicator>
          )}

          <StepIcon icon={<AddIcon />} onClick={() => legModalHandler.open()} />
        </div>
      </div>

      <Card>
        {!legs.length ? (
          <>Create your first leg.</>
        ) : (
          <>
            <LineRouteLegForm
              currentLegIndex={currentLegIndex}
              currentLeg={currentLeg}
              deleteLeg={deleteLeg}
            />
          </>
        )}
      </Card>

      <LineRouteLegModal
        isOpen={isOpenLegModal}
        onClose={legModalHandler.close}
        onSubmit={handleLeg}
        leg={legModalHandler?.data}
        hasLegs={hasLegs}
      />
    </>
  )
}

export default LineRouteCreateEditLegs
