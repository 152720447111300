import React, { useState, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

//Chart
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import dayjs from 'dayjs'
import zoomPlugin from 'chartjs-plugin-zoom'

//Contexts
import { useApp } from 'contexts/AppProvider'

//Atlassian
import Button, { ButtonGroup } from '@atlaskit/button'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'
import Spinner from '@atlaskit/spinner'

//Interfaces
import { useDisclosure, useFlags } from '@royan-co/user-interface'

//Components
import ShipmentTrackingTemperatureModal from './TrackingTemperatureModal'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin,
  Filler
)

const ShipmentTrackingTemperatureChart = ({ trackingPins, maxTemp, minTemp, downloadReport }) => {
  const { setIsExportExcel } = useApp()
  const { pathname } = useLocation()
  const { showSuccessFlag, showWarningFlag } = useFlags()

  const [toDate, setToDate] = useState(
    dayjs(trackingPins?.[0]?.creation_date).add(1, 'day').startOf('day').toISOString()
  )
  const [fromDate, setFromDate] = useState(dayjs(toDate).subtract(16, 'day').toISOString())
  const [isLoadingPdf, setIsLoadingPdf] = useState(false)
  const [isLoadingExcel, setIsLoadingExcel] = useState(false)

  const [isOpenTemperatureModal, temperatureModalHandler] = useDisclosure('temperature')

  const temperaturePins = useMemo(
    () => trackingPins.filter((p) => p.reefer_temperature !== null),
    [trackingPins]
  )

  const handleDownloadReport = async (format) => {
    try {
      const data = await downloadReport(format)
      if (format === 'pdf') {
        const file = new Blob([data], { type: 'application/pdf' })
        const fileURL = URL.createObjectURL(file)
        return window.open(fileURL)
      } else {
        showSuccessFlag(data.message)
        setIsExportExcel(true)
      }
    } catch (error) {
      showWarningFlag('Something wrong happened.')
      console.error(error)
    }
  }

  const handleShowPdf = async (format) => {
    if (isLoadingPdf) return
    format === 'pdf' ? setIsLoadingPdf(true) : setIsLoadingExcel(true)
    await handleDownloadReport(format)

    setIsLoadingPdf(false)
    setIsLoadingExcel(false)
  }

  const chartData = useMemo(
    () =>
      temperaturePins
        ?.filter((p) => {
          let _createDate = dayjs(p.creation_date)

          return _createDate.isAfter(fromDate, 'day') && _createDate.isBefore(toDate, 'day')
        })
        .reverse(),
    [temperaturePins, toDate, fromDate]
  )

  const setNewRange = (from, to) => {
    from && setFromDate(from)
    to && setToDate(to)
  }

  const max_temp = maxTemp
  const min_temp = minTemp

  const options = {
    scales: {
      y: {
        grid: {
          color: 'rgba(9, 30, 66, 0.04)',
        },
      },
      x: {
        grid: {
          color: 'rgba(9, 30, 66, 0.04)',
        },
        ticks: {
          callback: function (value, index, ticks) {
            return dayjs(this.getLabelForValue(value)).format('D MMM')
          },
        },
      },
    },
    responsive: true,
    elements: {
      point: {
        radius: 2,
        backgroundColor: '#0052CC',
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: 'Reefer temperature',
      },
      tooltip: {
        intersect: false,
        mode: 'index',
        // cornerRadius: 0,
        filter: function ({ datasetIndex }) {
          return datasetIndex === 1
        },
      },
    },
  }

  const data = {
    labels: chartData?.map((item) => item.creation_date),
    datasets: [
      {
        label: 'maximum_temperature',
        data: chartData?.map((item) => max_temp),
        fill: false,
        borderWidth: 0,
        radius: 0,
        pointHoverRadius: 0,
      },
      {
        label: 'Temperature',
        data: chartData?.map((item) => item.reefer_temperature * 1),
        borderColor: '#0052CC',
        borderWidth: 1,
      },
      {
        label: 'minimum_temperature',
        data: chartData?.map((item) => min_temp),
        backgroundColor: 'rgba(9, 30, 66, 0.04)',
        fill: '-2', // 3. Set the fill options
        borderWidth: 0,
        radius: 0,
        pointHoverRadius: 0,
      },
    ],
  }

  return temperaturePins?.length === 0 ? (
    <div className="flex justify-center items-center mt-5">
      <h5>No records to display</h5>
    </div>
  ) : (
    <div className="w-full">
      <div className="flex justify-between items-center w-full">
        <div className="mt-3 mb-5">
          <div style={{ fontWeight: '600' }}>
            Last temperature recorded: {temperaturePins?.[0]?.reefer_temperature} °C
          </div>
          <small className="text-neutral-200">
            {dayjs(fromDate).format('D MMM YYYY')} - {dayjs(toDate).format('D MMM YYYY')}
          </small>
        </div>

        {pathname.includes('panel') && (
          <ButtonGroup>
            <Button
              onClick={() => {
                temperatureModalHandler.open()
              }}
              spacing="compact"
              appearance="default"
            >
              Filter
            </Button>

            <DropdownMenu
              isOpen={isLoadingPdf || isLoadingExcel || undefined}
              placement="bottom-end"
              trigger={({ triggerRef, ...props }) => (
                <Button {...props} ref={triggerRef} spacing="compact" appearance="primary">
                  Download report
                </Button>
              )}
            >
              <DropdownItemGroup>
                <div style={{ minWidth: '130px' }}>
                  <DropdownItem
                    isDisabled={isLoadingPdf}
                    onClick={() => {
                      handleShowPdf('pdf')
                    }}
                    children={
                      <div className="flex items-center justify-between">
                        As PDF
                        {isLoadingPdf && (
                          <div className="ml-2">
                            <Spinner size="small" />
                          </div>
                        )}
                      </div>
                    }
                  />

                  <DropdownItem
                    isDisabled={isLoadingExcel}
                    onClick={() => {
                      handleShowPdf('xlsx')
                    }}
                    children={
                      <div className="flex items-center justify-between">
                        As xlsx
                        {isLoadingExcel && (
                          <div className="ml-2">
                            <Spinner size="small" />
                          </div>
                        )}
                      </div>
                    }
                  />
                </div>
              </DropdownItemGroup>
            </DropdownMenu>
          </ButtonGroup>
        )}
      </div>

      <div>
        <Line id="myChart" options={options} data={data} />
      </div>

      <ShipmentTrackingTemperatureModal
        isOpen={isOpenTemperatureModal}
        onClose={temperatureModalHandler.close}
        toDate={toDate}
        fromDate={fromDate}
        setNewRange={setNewRange}
      />
    </div>
  )
}

export default ShipmentTrackingTemperatureChart
