import { useParams, useNavigate } from 'react-router-dom'

// Atlassian
import Button from '@atlaskit/button'
import Spinner from '@atlaskit/spinner'
import SectionMessage from '@atlaskit/section-message'
import EmptyState from '@atlaskit/empty-state'

// Hooks
import useLineRoutes from '../hooks/useLineRoutes'

// Interfaces
import { PlusIcon, useDisclosure } from '@royan-co/user-interface'

// Components
import RouteCard from '../components/View/Routes/Card'
import DeleteLineRouteModal from '../components/View/Routes/DeleteRouteModal'

const LineViewRoutesTab = () => {
  const { lineId } = useParams()
  const navigate = useNavigate()

  const { routes, isLoading, isError, refetch } = useLineRoutes(lineId)

  const [openDeleteRouteModal, deleteRouteModalHandlers] = useDisclosure('delete-route')

  const deleteRouteCallback = (deletedRoute) => {
    deleteRouteModalHandlers.open(deletedRoute)
  }

  return (
    <>
      {isLoading ? (
        <div className="text-center mt-24">
          <Spinner />
        </div>
      ) : isError ? (
        <SectionMessage
          appearance="warning"
          title="Something went wrong on loading routes. please retry."
        >
          <Button onClick={() => refetch()}>Retry</Button>
        </SectionMessage>
      ) : (
        <>
          <Button
            appearance="default"
            iconBefore={<PlusIcon />}
            onClick={() => navigate(`/panel/sales-machine/lines/${lineId}/routes/create`)}
          >
            Add a route
          </Button>

          <div className="mt-6">
            {!routes || routes?.length === 0 ? (
              <EmptyState header="There is no routes to display." />
            ) : (
              <>
                {routes?.map((route, i) => (
                  <RouteCard key={i} route={route} deleteCallback={deleteRouteCallback} />
                ))}

                <DeleteLineRouteModal
                  routeId={deleteRouteModalHandlers.data}
                  isOpen={openDeleteRouteModal}
                  onClose={deleteRouteModalHandlers.close}
                />
              </>
            )}
          </div>
        </>
      )}
    </>
  )
}

export default LineViewRoutesTab
