import { useParams } from 'react-router-dom'

// Contexts
import { useAuth } from 'contexts/AuthProvider'

// Hooks
import useConsolidation from 'hooks/useConsolidation'

// Features
import { useCanAccess } from 'features/auth'

// Atlassian
import Button from '@atlaskit/button'

// Interfaces
import { useDisclosure } from '@royan-co/user-interface'

// Components
// import ShipmentsShareModal from './ShareModal'
import { generateConsolidationUserPermissions } from '../Create/conslolidation-user-permissions'
import ConsolidationsShareModal from './ShareModal'

const ConsolidationViewSharesActions = () => {
  const { consolidationId } = useParams()
  const { currentUser } = useAuth()
  const { consolidation } = useConsolidation()
  const userPermissions = generateConsolidationUserPermissions(consolidation, currentUser)

  const canCreateSharing = useCanAccess({
    resource: 'consolidations.share',
    action: 'create',
    userPermissions,
  })
  const [isOpenShareModal, shareModalHandler] = useDisclosure('share')

  return (
    <>
      {canCreateSharing && (
        <Button
          appearance="primary"
          onClick={() => {
            shareModalHandler.open()
          }}
        >
          Share consolidation
        </Button>
      )}

      <ConsolidationsShareModal
        isOpen={isOpenShareModal}
        onClose={shareModalHandler.close}
        consolidationId={consolidationId}
      />
    </>
  )
}

export default ConsolidationViewSharesActions
