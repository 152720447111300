import { createElement, useEffect, useMemo, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Helmet } from 'react-helmet-async'
import { useMediaQuery } from 'react-responsive'

// Contexts
import { useAuth } from 'contexts/AuthProvider'

// Utils
import { capitalizeFirstLetter } from 'utils/helpers'
import { documentTypes } from '../utils/document-types'

//Atlassian
import Button, { ButtonGroup, LoadingButton } from '@atlaskit/button'
import PageHeader from '@atlaskit/page-header'
import Spinner from '@atlaskit/spinner'
import SectionMessage from '@atlaskit/section-message'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'

//Interfaces
import { Grid, useFlags } from '@royan-co/user-interface'

// Components
import DownloadDocuments from '../components/Download'

const closeInlineEdits = () => {
  var openInlineEdits = document.getElementsByTagName('form')
  for (var i = 0; i < openInlineEdits.length; i++) {
    if (openInlineEdits[i].getAttribute('role') === 'presentation') {
      openInlineEdits[i].querySelector('button[type="submit"]')?.click()
    }
  }
}

const ViewDocument = ({ isEditing, entity }) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [fieldErrors, setFieldErrors] = useState(null)
  const isMobile = useMediaQuery({ query: `(max-width: 991.98px)` })

  const { currentUser } = useAuth()
  const { documentId, documentKey, isBlank } = useParams()
  const { state } = useLocation()

  const { showSuccessFlag, showWarningFlag } = useFlags()
  const [formData, setFormData] = useState()
  const [triggerSubmit, setTriggerSubmit] = useState(false)

  const document = useMemo(
    () => documentTypes.find((type) => type.key === documentKey),
    [documentKey]
  )
  const defaultValuesRequest = useMemo(() => document?.defaultValuesRequest, [document])
  const editDocumentRequest = useMemo(() => document?.editDocumentRequest, [document])

  const pageTitle = useMemo(
    () => `${isEditing ? 'Edit' : 'View'} ${document?.title}`,
    [document, isEditing]
  )

  const {
    data: defaultValues,
    isError,
    isLoading,
    isFetching,
    refetch,
  } = useQuery(['document', documentId], () => defaultValuesRequest(documentId, entity), {
    staleTime: Infinity,
    retry: 2,
    enabled: !!defaultValuesRequest,
  })
  const editDocumentMutation = useMutation((data) =>
    editDocumentRequest ? editDocumentRequest(documentId, data, entity) : () => {}
  )

  const selectedDocumentComponent = useMemo(() => {
    if (!document) return null

    return createElement(document.formComponent, {
      document: defaultValues,
      defaultValues:
        !defaultValues || !!isBlank || typeof document.defaultValuesMapper !== 'function'
          ? {}
          : document.defaultValuesMapper(defaultValues, currentUser),
      onChange: (data) => {
        setFieldErrors(null)
        setFormData(data)
      },
      previewMode: !isEditing ? false : undefined,
      isHouse: defaultValues?.type === 0,
      isMaster: defaultValues?.type === 2,
    })
  }, [document, isBlank, defaultValues, currentUser, isEditing])

  const handleEditDocument = () => {
    if (!editDocumentRequest) {
      setTriggerSubmit(false)
      return
    }

    setFieldErrors(null)

    const editedFromData =
      typeof document.submitValuesMapper !== 'function'
        ? formData
        : document.submitValuesMapper(formData)

    const validationResult = document.getSchema
      ? document.getSchema(defaultValues).safeParse(editedFromData)
      : { success: true }

    if (!validationResult.success) {
      setFieldErrors(validationResult.error.flatten().fieldErrors)
      setTriggerSubmit(false)
      return
    }

    editDocumentMutation.mutate(editedFromData, {
      onError: (e) => {
        console.error(e)
        showWarningFlag('Something wrong happened.')
      },
      onSuccess: (res) => {
        showSuccessFlag(res.message)
        queryClient.invalidateQueries({ queryKey: ['document', documentId], refetchType: 'none' })
        
        navigate(
          state?.from ? state.from : `/panel/${entity}s/document/${documentKey}/${documentId}`
        )
      },
      onSettled: () => {
        setTriggerSubmit(false)
      },
    })
  }

  const goToEditDocument = () => {
    navigate(`/panel/${entity}s/document/${documentKey}/${documentId}/edit`)
  }

  const documentDownloadTypes = useMemo(() => {
    if (document?.key === 'airwayBill' && defaultValues?.type !== 2)
      return document?.downloadTypes?.filter((d) => d.value !== 'full_houses')
    else return document?.downloadTypes
  }, [defaultValues?.type, document])

  useEffect(() => {
    if (triggerSubmit) handleEditDocument()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerSubmit])

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <Grid style={{ maxWidth: '1230px', margin: 'auto', paddingRight: isMobile ? '0' : '20px' }}>
        <Grid.Col style={{ padding: '0' }}>
          <PageHeader
            breadcrumbs={
              <Breadcrumbs>
                <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
                <BreadcrumbsItem
                  text={capitalizeFirstLetter(document?.title)}
                  to=""
                  component={Link}
                />
              </Breadcrumbs>
            }
            actions={
              <ButtonGroup>
                {!isEditing ? (
                  <>
                    <Button appearance="primary" onClick={goToEditDocument}>
                      Edit
                    </Button>

                    <DownloadDocuments
                      request={document.downloadRequest}
                      downloadTypes={documentDownloadTypes}
                      parent={entity}
                      documentType={document.key}
                    />
                  </>
                ) : (
                  <LoadingButton
                    appearance="primary"
                    onClick={() => {
                      closeInlineEdits()
                      setTriggerSubmit(true)
                    }}
                    isLoading={editDocumentMutation.isLoading}
                  >
                    Submit
                  </LoadingButton>
                )}
              </ButtonGroup>
            }
          >
            {capitalizeFirstLetter(pageTitle)}
          </PageHeader>

          {fieldErrors && (
            <SectionMessage appearance="warning">
              {Object.keys(fieldErrors).map((key) => (
                <div key={key}>{fieldErrors[key].join(' ')}</div>
              ))}
            </SectionMessage>
          )}

          <div className="py-4">
            {defaultValuesRequest && (isLoading || isFetching) ? (
              <div className="flex justify-center my-32">
                <Spinner />
              </div>
            ) : isError ? (
              <Grid gutter={24} className="w-full">
                <Grid.Col md={6} offsetMd={3}>
                  <SectionMessage
                    appearance="warning"
                    title="Something wrong on loading data, please retry"
                  >
                    <Button onClick={() => refetch()}>Retry</Button>
                  </SectionMessage>
                </Grid.Col>
              </Grid>
            ) : (
              <>
                {selectedDocumentComponent && (
                  <div className="mt-6">{selectedDocumentComponent}</div>
                )}
              </>
            )}
          </div>
        </Grid.Col>
      </Grid>
    </>
  )
}

export default ViewDocument
