import { useState } from 'react'
import { useParams } from 'react-router'
import { useMutation } from '@tanstack/react-query'

//Apis
import { resetShipmentConfirmation } from 'api/shipments'

//Atlassian
import Spinner from '@atlaskit/spinner'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'
import { ButtonItem } from '@atlaskit/menu'

//Interfaces
import { useFlags } from '@royan-co/user-interface'

const ActionDropdownItem = ({ type, label, setIsOpenMenu }) => {
  const { shipmentId } = useParams()
  const { showSuccessFlag, showWarningFlag } = useFlags()

  const actionMutation = useMutation(() => resetShipmentConfirmation(shipmentId, type), {
    onSuccess: (res) => {
      showSuccessFlag(res?.message)
      setIsOpenMenu(false)
    },
    onError: (e) => {
      console.error(e)
      showWarningFlag('Something wrong on reset ')
      setIsOpenMenu(false)
    },
  })

  return (
    <div style={{ minWidth: '175px' }}>
      <DropdownItem
        onClick={() => {
          setIsOpenMenu(true)
          actionMutation.mutate()
        }}
      >
        <div className="flex justify-between">
          {label}
          {actionMutation.isLoading && (
            <span className="ml-2">
              <Spinner size="small" />
            </span>
          )}
        </div>
      </DropdownItem>
    </div>
  )
}

const ShipmentResetShipperAction = () => {
  const [isOpenMenu, setIsOpenMenu] = useState()

  return (
    <>
      <DropdownMenu
        isOpen={isOpenMenu || undefined}
        placement="bottom-end"
        trigger={({ triggerRef, ...props }) => (
          <ButtonItem
            ref={triggerRef}
            {...props}
            children={'Reset shipper confirmations'}
            shouldTitleWrap
          />
        )}
      >
        <DropdownItemGroup>
          <ActionDropdownItem label="Pickup" type="pickup" setIsOpenMenu={setIsOpenMenu} />

          <ActionDropdownItem
            label="Power of attorney"
            type="power-of-attorney"
            setIsOpenMenu={setIsOpenMenu}
          />

          <ActionDropdownItem label="Dual use" type="dual-use" setIsOpenMenu={setIsOpenMenu} />
        </DropdownItemGroup>
      </DropdownMenu>
    </>
  )
}

export default ShipmentResetShipperAction
