import Tag from '@atlaskit/tag'
import { Link } from 'react-router-dom'

const TagLink = ({ text, to, target = '_self', ...props }) => {
  return (
    <Tag
      href={to}
      text={text}
      isRemovable={false}
      linkComponent={({ href, children, className }) => (
        <Link to={href} target={target} className={`no-decoration ${className}`}>
          {children}
        </Link>
      )}
      {...props}
    />
  )
}

export default TagLink
