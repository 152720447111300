import { useMemo } from 'react'
import { useMutation } from '@tanstack/react-query'

import { editShipmentTimeline } from 'api/shipments'

// Utils
import { handleErrorOnSubmit } from 'utils/errors'
import { transformSelectField } from 'utils/helpers'

// Atlassian
import Button, { LoadingButton } from '@atlaskit/button'
import Select from '@atlaskit/select'
import Modal, {
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import { ErrorMessage } from '@atlaskit/form'
import { DatePicker } from '@atlaskit/datetime-picker'
import Toggle from '@atlaskit/toggle'

// Interfaces
import { Field, Form, useFlags, WarningBannerField } from '@royan-co/user-interface'

const ShipmentEditTimelineModal = ({
  isOpen,
  onClose,
  statusList,
  shipmentId,
  statusId,
  refetch,
}) => {
  const { showSuccessFlag } = useFlags()

  const statusListOptions = useMemo(
    () => statusList?.map((s) => ({ label: s.label, value: s.id })),
    [statusList]
  )

  const changeTimelineMutation = useMutation((data) => editShipmentTimeline(shipmentId, data))

  const handleChangeTimeline = (data, { setError }) => {
    const formData = {
      ...data,
      date_only: data.status > statusId ? 0 : data?.date_only ? 1 : 0,
    }

    changeTimelineMutation.mutate(formData, {
      onError: (e) => {
        handleErrorOnSubmit(e, setError, data)
        console.error(e)
      },
      onSuccess: (res) => {
        showSuccessFlag(res.message)
        refetch()
        handleCloseModal()
      },
    })
  }

  const handleCloseModal = () => {
    onClose()
    changeTimelineMutation.reset()
  }

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={handleCloseModal} width="small">
          <ModalHeader>
            <ModalTitle>Change status</ModalTitle>
          </ModalHeader>

          <Form onSubmit={handleChangeTimeline}>
            {({ watch }) => (
              <>
                <div className="px-6">
                  <Field
                    isRequired
                    label="Status"
                    name="status"
                    transform={transformSelectField(statusListOptions)}
                    className="!mt-0"
                    defaultValue={statusId}
                  >
                    {({ fieldProps, error }) => (
                      <>
                        <Select options={statusListOptions} {...fieldProps} />
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                      </>
                    )}
                  </Field>

                  <Field isRequired label="Date" name="date" defaultValue="">
                    {({ fieldProps, error }) => (
                      <>
                        <DatePicker placeholder="2/18/2022" {...fieldProps} />
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                      </>
                    )}
                  </Field>

                  {(watch('status') || statusId) <= statusId && (
                    <Field
                      label="Change date only"
                      name="date_only"
                      type="checkbox"
                      key="toggle-date_only"
                    >
                      {({ fieldProps, error }) => (
                        <>
                          <div>
                            <Toggle {...fieldProps} id="date_only" />
                          </div>
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                  )}

                  <WarningBannerField />
                </div>

                <ModalFooter>
                  <Button
                    appearance="subtle"
                    spacing="compact"
                    type="button"
                    onClick={handleCloseModal}
                  >
                    Discard
                  </Button>
                  <LoadingButton
                    appearance="primary"
                    spacing="compact"
                    type="submit"
                    isLoading={changeTimelineMutation.isLoading}
                  >
                    Save
                  </LoadingButton>
                </ModalFooter>
              </>
            )}
          </Form>
        </Modal>
      )}
    </ModalTransition>
  )
}

export default ShipmentEditTimelineModal
