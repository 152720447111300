import { useQuery } from '@tanstack/react-query'

// Apis
import { getCurrency } from './currencies-requests'

/**
 * get currency detail
 * @param {String|Number} currencyCode
 * @param {import('@tanstack/react-query').UseQueryOptions} options
 * @returns {import('@tanstack/react-query').UseQueryResult}
 */

const useCurrency = (currencyCode, options = {}) => {
  return useQuery(['currency', currencyCode], () => getCurrency(currencyCode), {
    staleTime: Infinity,
    retry: 2,
    ...options,
  })
}

export { useCurrency }
