import { useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useNavigate, useParams } from 'react-router-dom'

// Utils
import { handleErrorOnSubmit } from 'utils/errors'
import { getFilled } from 'utils/helpers'

// Hooks
import { useCurrency } from '../api/useCurrency'
import { useUpsertCurrency } from '../api/useUpsertCurrency'

// Atlassian
import Button, { LoadingButton } from '@atlaskit/button'
import PageHeader from '@atlaskit/page-header'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'
import Textfield from '@atlaskit/textfield'
import Spinner from '@atlaskit/spinner'
import SectionMessage from '@atlaskit/section-message'
import { ErrorMessage } from '@atlaskit/form'

// Interfaces
import { Grid, Form, Field, WarningBannerField, useFlags } from '@royan-co/user-interface'

// Components
import Card from 'components/UI/Card'

const BanksCreationPage = () => {
  const { currencyCode } = useParams()

  const { showSuccessFlag } = useFlags()

  const navigate = useNavigate()
  const pageTitle = currencyCode ? 'Edit currency' : `New currency`

  const upsertCurrencyMutation = useUpsertCurrency(currencyCode)

  const {
    data: currency,
    isError,
    isLoading,
    refetch,
  } = useCurrency(currencyCode, { enabled: !!currencyCode })

  const defaultValues = useMemo(() => {
    const values = {
      name: getFilled(currency, 'name', ''),
      code: getFilled(currency, 'code', ''),
      symbol: getFilled(currency, 'symbol', ''),
    }

    return values
  }, [currency])

  const onSubmitCreation = (data, { setError }) => {
    upsertCurrencyMutation.mutate(data, {
      onError: (e) => {
        handleErrorOnSubmit(e, setError, data)
      },
      onSuccess: (res) => {
        showSuccessFlag(res.message)
        navigate('/panel/currencies', { replace: true })
      },
    })
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <Grid gutter={24} className="w-full">
        <Grid.Col md={10} lg={8} xl={6} offsetMd={1} offsetLg={2} offsetXl={3}>
          <PageHeader
            breadcrumbs={
              <Breadcrumbs>
                <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
                <BreadcrumbsItem text="Accounting" />
                <BreadcrumbsItem text="Currencies" to="/panel/currencies" component={Link} />
              </Breadcrumbs>
            }
          >
            {pageTitle}
          </PageHeader>

          <div className="my-8">
            {isLoading && currencyCode ? (
              <div className="flex justify-center my-32">
                <Spinner />
              </div>
            ) : isError ? (
              <SectionMessage
                appearance="warning"
                title="Something wrong on loading data, please retry."
              >
                <Button onClick={() => refetch()}>Retry</Button>
              </SectionMessage>
            ) : (
              <Form onSubmit={onSubmitCreation} defaultValues={defaultValues}>
                {() => (
                  <>
                    <Card className="mt-0">
                      <Field label="Name" name="name" defaultValue="" className="!mt-0">
                        {({ fieldProps, error }) => (
                          <>
                            <Textfield placeholder="Type the name" {...fieldProps} />
                            {error && <ErrorMessage>{error}</ErrorMessage>}
                          </>
                        )}
                      </Field>

                      <Field isRequired label="Code" name="code" defaultValue="" className="!mt-4">
                        {({ fieldProps, error }) => (
                          <>
                            <Textfield placeholder="Type the code" maxLength={3} {...fieldProps} />
                            {error && <ErrorMessage>{error}</ErrorMessage>}
                          </>
                        )}
                      </Field>

                      <Field label="Symbol" name="symbol" defaultValue="" className="!mt-4">
                        {({ fieldProps, error }) => (
                          <>
                            <Textfield placeholder="Type the symbol" {...fieldProps} />
                            {error && <ErrorMessage>{error}</ErrorMessage>}
                          </>
                        )}
                      </Field>
                    </Card>

                    <WarningBannerField />

                    <LoadingButton
                      className="!table ml-auto mt-6"
                      appearance="primary"
                      type="submit"
                      isLoading={upsertCurrencyMutation.isLoading}
                    >
                      {currencyCode ? 'Edit' : 'Create'}
                    </LoadingButton>
                  </>
                )}
              </Form>
            )}
          </div>
        </Grid.Col>
      </Grid>
    </>
  )
}

export default BanksCreationPage
