import { formatMonetaryNumber } from 'utils/numbers'

//Atlassian
import Lozenge from '@atlaskit/lozenge'

const ShipmentFinancialTag = ({ cost, quotation, margin }) => {
  return (
    <>
      <div className="flex gap-2">
        <div>
          <small className="whitespace-nowrap mr-4">Total cost</small>
          <div className="text-red-400">{formatMonetaryNumber(cost)}</div>
        </div>
        <div>
          <small className="whitespace-nowrap">Total quotation</small>
          <div className="text-green-400">{formatMonetaryNumber(quotation)}</div>
        </div>
      </div>

      <Lozenge appearance={margin > 0 ? 'success' : 'removed'}>{`Margin: ${formatMonetaryNumber(
        margin
      )} €`}</Lozenge>
    </>
  )
}

export default ShipmentFinancialTag
