import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

import { shareTrackingDetails } from 'api/shipments'
import { handleErrorOnSubmit } from 'utils/errors'

//Hooks
import useShipment from 'hooks/useShipment'

//Atlassian
import Textfield from '@atlaskit/textfield'
import { Checkbox } from '@atlaskit/checkbox'
import { ErrorMessage } from '@atlaskit/form'
import Button, { LoadingButton } from '@atlaskit/button'
import Modal, {
  ModalHeader,
  ModalFooter,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog'

//Interfaces
import {
  Form,
  Field,
  useFlags,
  PhoneInput,
  useGeoInfo,
  WarningBannerField,
} from '@royan-co/user-interface'

const ShipmentTrackingModal = ({ isOpen, onClose }) => {
  const { geoInfo } = useGeoInfo()
  const { shipmentId } = useParams()
  const { showSuccessFlag, showWarningFlag } = useFlags()

  const { shipment } = useShipment()
  const [copyLoading, setCopyLoading] = useState(false)

  const copyTrackingDetails = async () => {
    try {
      setCopyLoading(true)
      await navigator.clipboard.writeText(shipment?.tracking_link)

      showSuccessFlag('Copied to clipboard')
    } catch (error) {
      console.error(error)
      showWarningFlag('Something wrong on copy')
    } finally {
      setCopyLoading(false)
    }
  }

  const shareTrackingDetailsMutation = useMutation(shareTrackingDetails.bind(null, shipmentId))

  const handleShareTracking = (data, { setError }) => {
    const formData = {
      email: data.shareViaEmail ? data.email : undefined,
      phone: data.shareViaWhatsapp ? data.phone : undefined,
    }

    if (!formData.email && !formData.phone) {
      setError('unknown', { type: 'api', message: 'Please select email or whatsapp' })
      return
    }

    shareTrackingDetailsMutation.mutate(formData, {
      onError: (e) => {
        handleErrorOnSubmit(e, setError, formData)
        console.error(e)
      },
      onSuccess: (res) => {
        showSuccessFlag(res.message)
        handleCloseModal()
      },
    })
  }

  const handleCloseModal = () => {
    onClose()
    shareTrackingDetailsMutation.reset()
  }

  return (
    <>
      <ModalTransition>
        {isOpen && (
          <Modal width={'medium'} onClose={handleCloseModal}>
            <Form onSubmit={handleShareTracking}>
              {({ watch }) => (
                <>
                  <ModalHeader>
                    <ModalTitle>Share tracking details</ModalTitle>
                  </ModalHeader>

                  <div className="px-6">
                    <div>
                      <Field
                        type="checkbox"
                        name="shareViaEmail"
                        defaultValue={false}
                        className="!mt-0"
                      >
                        {({ fieldProps }) => <Checkbox label="Share via email" {...fieldProps} />}
                      </Field>

                      {watch('shareViaEmail') && (
                        <Field isRequired label="" name="email" defaultValue={''}>
                          {({ fieldProps, error }) => (
                            <>
                              <Textfield
                                type="email"
                                placeholder="Type the email"
                                {...fieldProps}
                              />
                              {error && <ErrorMessage>{error}</ErrorMessage>}
                            </>
                          )}
                        </Field>
                      )}
                    </div>

                    <div className="mt-4">
                      <Field
                        type="checkbox"
                        name="shareViaWhatsapp"
                        defaultValue={false}
                        className="!mt-0"
                      >
                        {({ fieldProps }) => (
                          <Checkbox label="Share via whatsapp" {...fieldProps} />
                        )}
                      </Field>

                      {watch('shareViaWhatsapp') && (
                        <Field
                          isRequired
                          name="phone"
                          transform={{
                            input: (v) => ({
                              value: v?.value || v,
                            }),
                            output: (opt) => opt?.value,
                          }}
                        >
                          {({ fieldProps: { ref, ...fieldProps }, error }) => (
                            <>
                              <PhoneInput
                                defaultCountry={geoInfo?.countryCode?.toLowerCase()}
                                {...fieldProps}
                              />
                              {error && <ErrorMessage>{error}</ErrorMessage>}
                            </>
                          )}
                        </Field>
                      )}
                    </div>

                    <WarningBannerField />
                  </div>

                  <ModalFooter>
                    <Button appearance="subtle" onClick={handleCloseModal}>
                      Cancel
                    </Button>
                    <LoadingButton
                      appearance="primary"
                      isLoading={copyLoading}
                      onClick={() => copyTrackingDetails()}
                    >
                      Copy to clipboard
                    </LoadingButton>
                    <LoadingButton
                      autoFocus
                      type="submit"
                      appearance="primary"
                      isLoading={shareTrackingDetailsMutation.isLoading}
                    >
                      Confirm
                    </LoadingButton>
                  </ModalFooter>
                </>
              )}
            </Form>
          </Modal>
        )}
      </ModalTransition>
    </>
  )
}

export default ShipmentTrackingModal
