import { useMutation, useQueryClient } from '@tanstack/react-query'

// Apis
import { createUser, editUser } from './users-requests'

const useUpsertUser = (userId) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data) => (userId ? editUser(userId, data) : createUser(data)),
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ['user', userId],
      })
    },
    onError: (e) => {
      console.error(e)
    },
  })
}

export { useUpsertUser }
