import { useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'

// Contexts
import { useFinanceEditingContext } from 'contexts/FinanceEditingProvider'

// Atlassian
import Button from '@atlaskit/button'
import Spinner from '@atlaskit/spinner'
import SectionMessage from '@atlaskit/section-message'

// Components
import EditFinanceLegForm from './LegForm'
import EditFinanceProgressTracker from './ProgressTracker'

const EditFinance = () => {
  const { entityType, entityId, financeQuery, currentLegIndex, financeData } =
    useFinanceEditingContext()
  const queryClient = useQueryClient()

  useEffect(() => {
    return () => {
      queryClient.removeQueries({
        queryKey: [entityType, 'finance', 'edit', entityId],
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return financeQuery.isLoading ? (
    <div className="text-center mt-24">
      <Spinner />
    </div>
  ) : financeQuery.isError ? (
    <SectionMessage appearance="warning" title="Something wrong on loading data, please retry.">
      <Button onClick={() => financeQuery.refetch()}>Retry</Button>
    </SectionMessage>
  ) : (
    <>
      <EditFinanceProgressTracker />

      <EditFinanceLegForm key={`${currentLegIndex}_${financeData.length}`} />
    </>
  )
}

export default EditFinance
