import { createElement } from 'react'

// Utils
import { findOption } from 'utils/helpers'

// Hooks
import useShipment from 'hooks/useShipment'

// Contexts
import { useAuth } from 'contexts/AuthProvider'

// Features
import { AccessControl } from 'features/auth'

// Atlassian
import {
  Header,
  Section,
  LoadingItems,
  SideNavigation,
  NavigationHeader,
  NavigationContent,
  SkeletonHeadingItem,
} from '@atlaskit/side-navigation'

// Interfaces
import { AirFreightIcon, InfoIcon, LandFreightIcon, SeaFreightIcon } from '@royan-co/user-interface'

// Components
import SidebarLink from 'layouts/SidebarLink'
import { generateShipmentUserPermissions } from 'components/Shipments/Create/shipment-user-permissions'
import { quotationStatusOptions, shipmentStatusOptions } from 'components/Shipments/constants'
import ShipmentHoldReleaseButton from 'components/Shipments/View/HoldReleaseButton'

const icons = {
  0: LandFreightIcon,
  1: SeaFreightIcon,
  2: AirFreightIcon,
}

const UserAccess = ({ children, limitation, item, shipment }) => {
  const { currentUser } = useAuth()

  return limitation ? (
    <AccessControl
      resource={item?.limitationAccess?.resource}
      action={item?.limitationAccess?.action}
      userPermissions={generateShipmentUserPermissions(shipment, currentUser)}
    >
      {children}
    </AccessControl>
  ) : (
    children
  )
}

const ShipmentLayoutSidebar = ({ pages, isShipment }) => {
  const { isLoading, shipment } = useShipment()

  const statusText = isShipment
    ? findOption(shipmentStatusOptions, shipment?.status)?.label
    : findOption(quotationStatusOptions, shipment?.quotation_status)?.label

  return (
    <SideNavigation label="settings">
      <LoadingItems
        isLoading={isLoading}
        fallback={
          <>
            <NavigationHeader>
              <Header
                description={<SkeletonHeadingItem width="80%" />}
                iconBefore={<SkeletonHeadingItem width="100%" />}
              >
                <div className="py-3">
                  <SkeletonHeadingItem width="100%" />
                </div>
              </Header>
            </NavigationHeader>
          </>
        }
      >
        <NavigationHeader>
          <Header
            iconBefore={createElement(icons[shipment?.shipment_type] || InfoIcon, {
              size: 26,
              className: 'text-neutral-500',
            })}
            description={
              <>
                <div className="text-neutral-500 text-xs rounded inline-block whitespace-normal">
                  {statusText}
                </div>
                {isShipment && <ShipmentHoldReleaseButton />}
              </>
            }
          >
            {isShipment ? shipment?.shipment_code : shipment?.quotation_code}
          </Header>
        </NavigationHeader>
      </LoadingItems>

      <NavigationContent>
        <Section>
          {pages.map((p, i) => (
            <UserAccess limitation={p?.limitationAccess} item={p} shipment={shipment} key={i}>
              <SidebarLink to={p.path} className="font-medium" iconBefore={createElement(p.icon)}>
                {p.title}
              </SidebarLink>
            </UserAccess>
          ))}
        </Section>
      </NavigationContent>
    </SideNavigation>
  )
}

export default ShipmentLayoutSidebar
