import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

import { createSurcharge, editSurcharge } from 'api/rates'

import { getFilled } from 'utils/helpers'
import { handleErrorOnSubmit } from 'utils/errors'

//Hooks
import useRateSurcharges from 'hooks/useRateSurcharges'

//Atlassian
import Button from '@atlaskit/button'
import LoadingButton from '@atlaskit/button/loading-button'
import Modal, {
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog'

//Interfaces
import { Form, WarningBannerField, useFlags } from '@royan-co/user-interface'

//Components
import RatesCreateSurchargeBrackets from './CreateSurchargeBrackets'
import RatesCreateSurchargeAgencyFee from './CreateSurchargeAgencyFee'

const RateCreateSurchargeModal = ({ isOpen, onClose, data, shipmentType }) => {
  const { rateId } = useParams()
  const { showSuccessFlag } = useFlags()

  const isEditing = useMemo(() => !!data?.id, [data])
  const editingSurchargeId = useMemo(() => data?.id, [data])

  const { upsertSurchargeToData } = useRateSurcharges(rateId)

  const SurchargeCreationMutation = useMutation(
    !isEditing ? createSurcharge.bind(null, rateId) : editSurcharge.bind(null, editingSurchargeId)
  )

  const defaultValues = useMemo(
    () => ({
      surcharge_id: getFilled(data, 'surcharge_id', null),
      rate: getFilled(data, 'rate', ''),
      minimum: getFilled(data, 'minimum', ''),
      maximum: getFilled(data, 'maximum', ''),
      minimum_f_minimum: getFilled(data, 'minimum_f_minimum', ''),
      awb_use: getFilled(data, 'awb_use', false),

      brackets: (data?.brackets || []).map((bracket) => ({
        ...bracket,
        from: getFilled(bracket, 'from', ''),
        to: getFilled(bracket, 'to', ''),
        transportation_unit: getFilled(bracket, 'transportation_unit', null),
        price: getFilled(bracket, 'price', ''),
        fix: getFilled(bracket, 'fix', false),
      })),
    }),
    [data]
  )

  const onSubmit = (data, { setError }) => {
    SurchargeCreationMutation.mutate(data, {
      onError: (e) => {
        handleErrorOnSubmit(e, setError, data)
      },
      onSuccess: (res) => {
        upsertSurchargeToData(res.item)
        showSuccessFlag(res.message)
        onClose()
      },
    })
  }

  return (
    <ModalTransition>
      {isOpen && (
        <Modal
          onClose={onClose}
          width="large"
          shouldScrollInViewport
          shouldCloseOnOverlayClick={false}
        >
          <ModalHeader>
            <ModalTitle>{isEditing ? 'Edit surcharge' : 'Add new surcharge'}</ModalTitle>
          </ModalHeader>

          <Form onSubmit={onSubmit} defaultValues={defaultValues}>
            {() => (
              <>
                <div className="px-6">
                  <RatesCreateSurchargeAgencyFee shipmentType={shipmentType} />

                  <RatesCreateSurchargeBrackets shipmentType={shipmentType} />

                  <WarningBannerField />
                </div>

                <ModalFooter>
                  <Button appearance="subtle" onClick={onClose}>
                    Cancel
                  </Button>
                  <LoadingButton
                    appearance="primary"
                    type="submit"
                    isLoading={SurchargeCreationMutation.isLoading}
                  >
                    {isEditing ? 'Edit' : 'Add'}
                  </LoadingButton>
                </ModalFooter>
              </>
            )}
          </Form>
        </Modal>
      )}
    </ModalTransition>
  )
}

export default RateCreateSurchargeModal
