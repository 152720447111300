import axios from 'services/Request'

export const getInvoice = (invoiceId, params) => {
  return axios.get(`/api/invoices/fetch/${invoiceId}`, { params })
}

export function createInvoice(params) {
  return axios.post('/api/invoices/create', params)
}

export function editInvoice(invoiceId, attribute, data) {
  return axios.post(`/api/invoices/edit/${invoiceId}${attribute ? `/${attribute}` : ''}`, data)
}

export function importInvoiceToARCA(invoiceId) {
  return axios.get(`/api/invoices/arca/import/${invoiceId}`)
}

export function getDefaultsForCreatingInvoice(params) {
  return axios.get('/api/invoices/create', { params })
}

export function elaborateInvoice(invoiceId) {
  return axios.get(`/api/invoices/arca/elaborate/${invoiceId}`)
}

export function takeInvoiceInCharge(invoiceId, data) {
  return axios.post(`/api/invoices/take-in-charge/${invoiceId}`, data)
}

export function disputeInvoice(invoiceId) {
  return axios.get(`/api/invoices/dispute/${invoiceId}`)
}

export function registeringInvoice(invoiceId) {
  return axios.get(`/api/invoices/register/${invoiceId}`)
}

export function resetInvoice(invoiceId, params) {
  return axios.get(`/api/invoices/reset/${invoiceId}`, { params })
}

export function deleteInvoice(invoiceId) {
  return axios.delete(`/api/invoices/${invoiceId}`)
}
