import { useQuery } from '@tanstack/react-query'

// Apis
import { getConsolidationShipments } from 'api/consolidations'

const useConsolidationShipments = (consolidationId) => {
  const queryKey = ['consolidation', 'shipments', consolidationId]

  return useQuery({
    queryKey,
    queryFn: () => getConsolidationShipments(consolidationId),
    retry: 2,
    staleTime: Infinity,
  })
}

export default useConsolidationShipments
