//Atlassian
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import Button from '@atlaskit/button'
import { LoadingButton } from '@atlaskit/button'

//Interfaces
import { WarningBanner } from '@royan-co/user-interface'

const ResetLoadingModal = ({ isOpen, onClose, isError, onSubmit, isLoading, consolidation }) => {
  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={onClose}>
          <ModalHeader>
            <ModalTitle appearance="warning">Reset the loading</ModalTitle>
          </ModalHeader>

          <ModalBody>
            <p>
              This loading has been finalized on {consolidation?.loading_finished_at}. Do you want
              to reset the loading?{' '}
            </p>
            {isError && <WarningBanner />}
          </ModalBody>

          <ModalFooter>
            <Button appearance="subtle" onClick={onClose}>
              Cancel
            </Button>
            <LoadingButton appearance="warning" onClick={onSubmit} isLoading={isLoading} autoFocus>
              Reset
            </LoadingButton>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  )
}

export default ResetLoadingModal
