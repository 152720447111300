import { useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'services/Request'

export const getInvoiceHistory = (invoiceId) => {
  return axios.get(`/api/invoices/history/${invoiceId}`)
}

export const useInvoiceHistory = (invoiceId, { enabled = true } = {}) => {
  const queryClient = useQueryClient()

  const {
    data: history,
    isFetching,
    isLoading,
    isError,
    refetch,
  } = useQuery(['invoice-history', invoiceId], () => getInvoiceHistory(invoiceId), {
    staleTime: Infinity,
    enabled,
  })

  const invalidate = () => {
    queryClient.invalidateQueries({ queryKey: ['invoice-history', invoiceId], refetchType: 'none' })
  }

  return { history, isLoading, isFetching, isError, refetch, invalidate }
}
