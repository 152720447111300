import { useState } from 'react'

import ShareCargoModal from './CargoShareModal'

//Atlassian
import Button from '@atlaskit/button'

const ShipmentViewCargoActions = () => {
  const [isOpenShareModal, setIsOpenShareModal] = useState(false)

  return (
    <>
      <Button
        appearance="primary"
        onClick={() => {
          setIsOpenShareModal(true)
        }}
      >
        Share cargo details
      </Button>

      <ShareCargoModal isOpen={isOpenShareModal} onClose={() => setIsOpenShareModal(false)} />
    </>
  )
}

export default ShipmentViewCargoActions
