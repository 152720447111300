import { useQuery } from '@tanstack/react-query'
import { getRegistryParamValue } from 'api/registries'

const useGetRegistryParam = (id, param) => {
  const paramQuery = useQuery({
    queryKey: ['registries-param-value', id, param],
    queryFn: () => getRegistryParamValue(id, param),
    select: (res) => res?.registry,
    enabled: !!id,
    // initialData: '',
    staleTime: Infinity,
  })

  return { autocompleteValue: paramQuery.data, isLoadingAutocompleteValue: paramQuery.isFetching }
}

export default useGetRegistryParam
