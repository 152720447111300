import axios from 'services/Request'

export function getBanks(params) {
  return axios.get('/api/accounting/banks?paginate=1', { params })
}

export function getBank(bankId) {
  return axios.get(`/api/accounting/banks/${bankId}`)
}

export function createBank(data) {
  return axios.post('/api/accounting/banks', data)
}

export function editBank(bankId, data) {
  return axios.put(`/api/accounting/banks/${bankId}`, data)
}

export function deleteBank(bankId) {
  return axios.delete(`/api/accounting/banks/${bankId}`)
}
