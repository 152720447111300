import { useParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'

import { getRegistryContactMails } from 'api/registries'

// Atlassian
import Button from '@atlaskit/button'
import Modal, {
  ModalBody,
  ModalTitle,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from '@atlaskit/modal-dialog'

// Interfaces
import { RoyanTable, useDisclosure } from '@royan-co/user-interface'

// Components
import Card from 'components/UI/Card'
import { generateContactMailHistoryColumns } from './mail-columns'

const RegistryContactMailsPage = () => {
  const { contactId } = useParams()
  const queryClient = useQueryClient()
  const [isOpenMailDetails, mailDetailsHandlers] = useDisclosure()

  const request = (params) => {
    return queryClient.fetchQuery({
      staleTime: Infinity,
      queryKey: ['contact-mails', contactId, params],
      queryFn: () => getRegistryContactMails(contactId, params),
    })
  }

  return (
    <>
      <Card className="mt-0">
        <RoyanTable
          filters={[]}
          request={request}
          searchable={false}
          columnsControl={false}
          name="registry-contact-mail-history"
          columns={generateContactMailHistoryColumns({
            onOpenDetails: mailDetailsHandlers.open,
          })}
        />
      </Card>

      <ModalTransition>
        {isOpenMailDetails && (
          <Modal onClose={() => mailDetailsHandlers.close()} width="medium">
            <ModalHeader>
              <ModalTitle>{mailDetailsHandlers.data?.subject}</ModalTitle>
            </ModalHeader>

            <ModalBody>
              <div
                className="break-words"
                dangerouslySetInnerHTML={{ __html: mailDetailsHandlers.data?.body }}
              />
            </ModalBody>

            <ModalFooter>
              <Button appearance="subtle" onClick={() => mailDetailsHandlers.close()}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </>
  )
}

export default RegistryContactMailsPage
