import { useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useNavigate, useParams } from 'react-router-dom'

// Utils
import { handleErrorOnSubmit } from 'utils/errors'
import { getFilled } from 'utils/helpers'

// Hooks
import { useCreateMarginCalculator } from '../api/useCreateMarginCalculator'
import { useMarginCalculator } from '../api/useMarginCalculator'

// Atlassian
import { LoadingButton } from '@atlaskit/button'
import PageHeader from '@atlaskit/page-header'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'
import Button from '@atlaskit/button'
import Spinner from '@atlaskit/spinner'
import SectionMessage from '@atlaskit/section-message'

// Interfaces
import { Grid, Form, WarningBannerField, useFlags } from '@royan-co/user-interface'

// Components
import CreationBracketsForm from '../components/create/CreationBracketsForm'
import CreationGeneralForm from '../components/create/CreationGeneralForm'

const MarginCalculatorCreationPage = () => {
  const { marginId } = useParams()
  const { showSuccessFlag } = useFlags()

  const {
    data: margin,
    isLoading,
    isError,
    refetch,
  } = useMarginCalculator(marginId, { enabled: !!marginId })

  const navigate = useNavigate()
  const pageTitle = `${marginId ? 'Edit' : 'New'} margin calculator`

  const defaultValues = useMemo(() => {
    const values = {
      shipment_type: getFilled(margin, 'shipment_type', null),
      country_area: getFilled(margin, 'country_area', null),
      area: getFilled(margin, 'area', null),
      type: getFilled(margin, 'type', null),
      calculation_method: getFilled(margin, 'calculation_method', null),
      minimum: getFilled(margin, 'minimun', ''),
      minimum_fix: getFilled(margin, 'minimun_fix', 0),
      must_go: getFilled(margin, 'must_go', 0),
      brackets: getFilled(margin, 'brackets', []),
      routes: getFilled(margin, 'routes', []),
    }

    return values
  }, [margin])

  const createMutation = useCreateMarginCalculator(marginId)
  const onSubmitCreation = (data, { setError }) => {
    let formData = {
      ...data,
      routes: data.routes || [],
    }

    if (!marginId)
      formData.brackets = data.brackets.map(({ index, _id, ...bracket }) => ({ ...bracket }))
    else delete formData.brackets

    createMutation.mutate(formData, {
      onError: (e) => {
        handleErrorOnSubmit(e, setError, formData)
      },
      onSuccess: (res) => {
        showSuccessFlag(res.message)
        setTimeout(() => {
          navigate(`/panel/margin-calculator/${res.record.id}`, { replace: true })
        }, 100)
      },
    })
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <Grid gutter={24} className="w-full">
        <Grid.Col md={10} lg={8} xl={6} offsetMd={1} offsetLg={2} offsetXl={3}>
          <PageHeader
            breadcrumbs={
              <Breadcrumbs>
                <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
                <BreadcrumbsItem text="Sales machine" />
                <BreadcrumbsItem
                  text="Margin calculator"
                  to="/panel/margin-calculator"
                  component={Link}
                />
              </Breadcrumbs>
            }
          >
            {pageTitle}
          </PageHeader>

          {isLoading && marginId ? (
            <div className="text-center mt-6">
              <Spinner />
            </div>
          ) : isError ? (
            <SectionMessage
              appearance="warning"
              title="Something wrong on loading data, please retry."
            >
              <Button onClick={() => refetch()}>Retry</Button>
            </SectionMessage>
          ) : (
            <div className="my-8">
              <Form onSubmit={onSubmitCreation} defaultValues={defaultValues}>
                {() => (
                  <>
                    <CreationGeneralForm />

                    {!marginId && <CreationBracketsForm />}

                    <WarningBannerField />

                    <LoadingButton
                      className="!table ml-auto mt-6"
                      appearance="primary"
                      type="submit"
                      isLoading={createMutation.isLoading}
                    >
                      {marginId ? 'Edit' : 'Create'}
                    </LoadingButton>
                  </>
                )}
              </Form>
            </div>
          )}
        </Grid.Col>
      </Grid>
    </>
  )
}

export default MarginCalculatorCreationPage
