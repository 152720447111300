// Components
import ImportToARCAAction from './ImportToARCAAction'
import RegisteringInvoiceAction from './RegisteringInvoiceAction'
import ResetInvoiceAction from './ResetInvoiceAction'
import EditInvoiceDropdownItem from '../EditInvoiceDropdownItem'
import TakeInChargeInvoiceAction from './TakeInChargeInvoiceAction'
import DeleteInvoiceAction from './delete'

const PassiveInvoiceActions = ({ invoice, shipmentId, openDeleteModal }) => {
  return (
    <>
      <EditInvoiceDropdownItem invoice={invoice} />

      <TakeInChargeInvoiceAction invoice={invoice} shipmentId={shipmentId} />

      <ImportToARCAAction invoice={invoice} isPassive shipmentId={shipmentId} />

      <ResetInvoiceAction invoice={invoice} shipmentId={shipmentId} />

      <RegisteringInvoiceAction invoice={invoice} shipmentId={shipmentId} />

      <DeleteInvoiceAction invoice={invoice} isPassive openDeleteModal={openDeleteModal} />
    </>
  )
}

export default PassiveInvoiceActions
