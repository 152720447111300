// Lodash
import toNumber from 'lodash/toNumber'
import isNumber from 'lodash/isNumber'
import toString from 'lodash/toString'

// Icons
import { useDisclosure, useFormContext } from '@royan-co/user-interface'

// Atlassian
import Button from '@atlaskit/button'
import { ErrorMessage } from '@atlaskit/form'

// Interfaces
import { useFieldArray } from '@royan-co/user-interface'

// Components
import Card from 'components/UI/Card'
import BracketDrawer from './BracketDrawer'
import MarginCalculatorBracketsTable from '../view/BracketsTable'

const CreationBracketsForm = () => {
  const { watch, formState } = useFormContext()
  const [isOpenBracketDrawer, bracketDrawerHandler] = useDisclosure('brackets')
  const {
    fields: brackets,
    append: appendBracket,
    remove: removeBracket,
    update: updateBracket,
  } = useFieldArray({
    name: 'brackets',
    shouldUnregister: false,
    keyName: '_id',
    rules: { validate: (data) => (data.length === 0 ? `Brackets are required` : true) },
  })

  const addNewBracket = () => {
    const lastIntervalValue = toNumber(watch(`brackets.${brackets?.length - 1}.to`))
    const from =
      lastIntervalValue && isNumber(lastIntervalValue) && lastIntervalValue >= 0
        ? toString(lastIntervalValue + 1)
        : ''

    bracketDrawerHandler.open({ from })
  }

  const onSubmit = (data) => {
    data?.index !== undefined
      ? updateBracket(data.index, data)
      : appendBracket({
          ...data,
          fix_percent: data.fix_percent || 0,
        })
    bracketDrawerHandler.close()
  }

  return (
    <>
      <div className="mt-6">
        <div className="flex items-center">
          <h3>Brackets</h3>

          <Button appearance="link" spacing="none" className="ml-auto" onClick={addNewBracket}>
            New bracket
          </Button>
        </div>

        <Card className="mt-2">
          {brackets.length === 0 ? (
            <div className="text-xs text-neutral-200">Click on New bracket to create one.</div>
          ) : (
            <MarginCalculatorBracketsTable
              brackets={brackets}
              onDelete={(_, index) => {
                removeBracket(index)
              }}
              onEdit={(bracket, index) => bracketDrawerHandler.open({ ...bracket, index })}
            />
          )}
        </Card>

        <BracketDrawer
          isOpen={isOpenBracketDrawer}
          onClose={bracketDrawerHandler.close}
          onSubmit={onSubmit}
          data={bracketDrawerHandler.data}
        />
      </div>

      {formState.errors?.brackets && (
        <ErrorMessage>{formState.errors.brackets.root.message}</ErrorMessage>
      )}
    </>
  )
}

export default CreationBracketsForm
