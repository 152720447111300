import { useMemo, useRef, useState } from 'react'
import { isEqual } from 'lodash'

// Utils
import { handleErrorOnSubmit } from 'utils/errors'
import { findCountryIsoByCode } from 'utils/CountryCodes'

// Apis
import { createDigitalSignature } from './../api/createDigitalSignature'

// Atlassian
import Button, { ButtonGroup, LoadingButton } from '@atlaskit/button'
import { ErrorMessage } from '@atlaskit/form'
import { ModalBody, ModalFooter } from '@atlaskit/modal-dialog'
import Textfield from '@atlaskit/textfield'

// Interfaces
import { Field, Form, PhoneInput, WarningBannerField, useGeoInfo } from '@royan-co/user-interface'

const DigitalSignatureOTPStep = ({
  className,
  entityId,
  onCancel,
  contact,
  onSuccess,
  type,
  purpose,
}) => {
  const { geoInfo } = useGeoInfo()
  const otpSignatureId = useRef()
  const lastSubmitData = useRef()
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (data, { setError }) => {
    const formData = {
      entity_id: entityId,
      name: data.name,
      email: data.email,
      cellphone: data.cellphone?.rawPhone,
      cellphone_prefix: findCountryIsoByCode(data?.cellphone?.dialCode),
    }

    if (isEqual(formData, lastSubmitData.current)) {
      onSuccess(otpSignatureId.current)
      return
    }

    try {
      setIsLoading(true)

      const result = await createDigitalSignature(type, purpose, formData)

      otpSignatureId.current = result.signature_id

      onSuccess(result.signature_id)

      lastSubmitData.current = formData
    } catch (error) {
      handleErrorOnSubmit(error, setError, data)
    } finally {
      setIsLoading(false)
    }
  }

  const defaultValues = useMemo(() => {
    return {
      name: contact?.full_name || '',
      email: contact?.email || '',
      cellphone: {
        rawPhone: contact?.cellphone,
        dialCode: contact?.cellphone_prefix,
        value: [contact?.cellphone_prefix, contact?.cellphone].join(''),
      },
    }
  }, [contact])

  return (
    <Form onSubmit={handleSubmit} defaultValues={defaultValues} className={className}>
      {() => (
        <>
          <ModalBody>
            <div>
              You are about to sign the data form. Please confirm your information. You will receive
              a one-time password to verify your identity.
            </div>

            <Field isRequired name="name" label="Name">
              {({ fieldProps, error }) => (
                <>
                  <Textfield placeholder="Type your name" autoComplete="off" {...fieldProps} />
                  {error && <ErrorMessage>{error}</ErrorMessage>}
                </>
              )}
            </Field>

            <Field isRequired name="email" label="Email">
              {({ fieldProps, error }) => (
                <>
                  <Textfield placeholder="Type your email" type="email" {...fieldProps} />
                  {error && <ErrorMessage>{error}</ErrorMessage>}
                </>
              )}
            </Field>

            <Field
              isRequired
              name="cellphone"
              label="Phone number"
              validate={(v) => (!v?.value ? 'The phone field is required.' : true)}
            >
              {({ fieldProps: { ref, ...fieldProps }, error }) => (
                <>
                  <PhoneInput
                    defaultCountry={geoInfo?.countryCode?.toLowerCase()}
                    {...fieldProps}
                  />
                  {error && <ErrorMessage>{error}</ErrorMessage>}
                </>
              )}
            </Field>

            <WarningBannerField />
          </ModalBody>

          <ModalFooter>
            <ButtonGroup>
              <Button onClick={onCancel} appearance="subtle" isDisabled={isLoading}>
                Cancel
              </Button>

              <LoadingButton isLoading={isLoading} type="submit" appearance="primary">
                Confirm
              </LoadingButton>
            </ButtonGroup>
          </ModalFooter>
        </>
      )}
    </Form>
  )
}

export default DigitalSignatureOTPStep
