import { useParams } from 'react-router-dom'

// Utils
import { handleErrorOnSubmit } from 'utils/errors'

// Hooks
import { useBrackets } from 'features/marginCalculator/api/useBrackets'
import { useCreateBracket } from 'features/marginCalculator/api/useCreateBracket'

// Interfaces
import { PlusIcon, useDisclosure, useFlags } from '@royan-co/user-interface'

// Atlassian
import EmptyState from '@atlaskit/empty-state'
import Button from '@atlaskit/button'
import Spinner from '@atlaskit/spinner'
import SectionMessage from '@atlaskit/section-message'

// Components
import Card from 'components/UI/Card'
import MarginCalculatorBracketsTable from 'features/marginCalculator/components/view/BracketsTable'
import BracketDrawer from 'features/marginCalculator/components/create/BracketDrawer'
import DeleteBracketModal from 'features/marginCalculator/components/view/DeleteBracketModal'

const MarginCalculatorViewBracketsPage = () => {
  const { marginId } = useParams()
  const { showSuccessFlag } = useFlags()
  const { data: brackets = [], isLoading, isError, refetch } = useBrackets(marginId)

  const [isOpenBracketDrawer, bracketDrawerHandler] = useDisclosure('brackets')
  const [isOpenDeleteModal, deleteModalHandler] = useDisclosure('delete')

  const createBracketMutation = useCreateBracket(marginId, bracketDrawerHandler.data?.id)

  const onCreateBracket = (data, { setError }) => {
    createBracketMutation.mutate(data, {
      onSuccess: (res) => {
        showSuccessFlag(res.message)
        bracketDrawerHandler.close()
      },
      onError: (e) => {
        handleErrorOnSubmit(e, setError, data)
        console.error(e)
      },
    })
  }

  return (
    <>
      <Button
        appearance="default"
        iconBefore={<PlusIcon />}
        onClick={() => bracketDrawerHandler.open()}
      >
        Add new bracket
      </Button>

      {isLoading ? (
        <div className="flex justify-center my-32">
          <Spinner />
        </div>
      ) : isError ? (
        <SectionMessage appearance="warning" title="Something wrong on loading data, please retry.">
          <Button onClick={() => refetch()}>Retry</Button>
        </SectionMessage>
      ) : brackets?.length < 1 ? (
        <EmptyState header="No brackets" />
      ) : (
        <Card className="mt-6">
          <div className=" inline-block w-full -mb-6">
            <MarginCalculatorBracketsTable
              brackets={brackets}
              onEdit={(bracket) => bracketDrawerHandler.open(bracket)}
              onDelete={(bracket) => deleteModalHandler.open(bracket.id)}
            />
          </div>
        </Card>
      )}

      <BracketDrawer
        isOpen={isOpenBracketDrawer}
        onClose={bracketDrawerHandler.close}
        onSubmit={onCreateBracket}
        data={bracketDrawerHandler.data}
        isLoading={createBracketMutation.isLoading}
      />

      <DeleteBracketModal
        isOpen={isOpenDeleteModal}
        onClose={deleteModalHandler.close}
        bracketId={deleteModalHandler.data}
        marginId={marginId}
      />
    </>
  )
}

export default MarginCalculatorViewBracketsPage
