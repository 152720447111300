import { useState } from 'react'
import { saveAs } from 'file-saver'
import { useParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

//Atlassian
import Button from '@atlaskit/button'
import Spinner from '@atlaskit/spinner'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'

//Interfaces
import { useFlags } from '@royan-co/user-interface'

const DownloadDropdownItem = ({ format, label, setIsOpenMenu, request, documentType, parent }) => {
  const { documentId, isBlank } = useParams()
  const { showSuccessFlag, showWarningFlag } = useFlags()

  const actionMutation = useMutation(request, {
    onSuccess: (res) => {
      showSuccessFlag('Download successfully')
      setIsOpenMenu(false)

      const file = new Blob([res], { type: 'application/pdf' })
      if (documentType === 'airwayBill') window.open(URL.createObjectURL(file))
      else saveAs(file, `${parent}-${documentId}-${format}.pdf`)
    },
    onError: (e) => {
      console.error(e)
      showWarningFlag('Something wrong on download ')
      setIsOpenMenu(false)
    },
  })

  return (
    <div style={{ minWidth: '150px' }}>
      <DropdownItem
        onClick={() => {
          setIsOpenMenu(true)
          actionMutation.mutate({ documentId, format, parent, data: {} })
        }}
      >
        <div className="flex justify-between">
          {label}
          {actionMutation.isLoading && (
            <span className="ml-2">
              <Spinner size="small" />
            </span>
          )}
        </div>
      </DropdownItem>
    </div>
  )
}

const DownloadDocuments = ({ request, downloadTypes, documentType, parent }) => {
  const [isOpenMenu, setIsOpenMenu] = useState()

  return (
    <>
      <DropdownMenu
        isOpen={isOpenMenu || undefined}
        placement="bottom-end"
        trigger={({ triggerRef, ...props }) => (
          <Button ref={triggerRef} {...props} children={'Download'} />
        )}
      >
        <DropdownItemGroup>
          {downloadTypes?.map((item) => (
            <DownloadDropdownItem
              key={item.value}
              label={item.label}
              format={item.value}
              request={request}
              setIsOpenMenu={setIsOpenMenu}
              parent={parent}
              documentType={documentType}
            />
          ))}
        </DropdownItemGroup>
      </DropdownMenu>
    </>
  )
}

export default DownloadDocuments
