import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

import useShipment from 'hooks/useShipment'

//Atlassian
import Lozenge from '@atlaskit/lozenge'

//Interfaces
import { Grid } from '@royan-co/user-interface'
import { ShortcutIcon } from '@royan-co/user-interface'

//Components
import RowTable from 'components/UI/RowTable'
import ShipmentAddressShow from '../AddressShow'
import ShipmentContactShow from '../ContactShow'
import Card, { CardSubtitle } from 'components/UI/Card'

const ShipmentShipperDetail = () => {
  const { shipment } = useShipment()

  const isMobile = useMediaQuery({ query: `(max-width: 991.98px)` })

  const generalRows = useMemo(() => {
    let rowsItems = [
      {
        title: 'Name',
        content: shipment?.shipper?.id_auto ? (
          <Link to={`/panel/registries/${shipment.shipper.id_auto}`} target="_blank">
            {shipment.shipper.company_name} <ShortcutIcon size={16} />
          </Link>
        ) : (
          <Lozenge appearance="default">Not set</Lozenge>
        ),
      },
      {
        title: 'Address',
        content: <ShipmentAddressShow address={shipment?.shipper_address} />,
      },
      {
        title: 'Contact',
        content: <ShipmentContactShow contact={shipment?.shipper_contact} />,
      },
    ]

    return rowsItems
  }, [shipment])

  const secondShipperRows = useMemo(() => {
    let rowsItems = [
      {
        title: 'Name',
        content: shipment?.second_shipper?.id_auto ? (
          <Link to={`/panel/registries/${shipment.second_shipper.id_auto}`} target="_blank">
            {shipment.second_shipper.company_name} <ShortcutIcon size={16} />
          </Link>
        ) : (
          <Lozenge appearance="default">Not set</Lozenge>
        ),
      },
      {
        title: 'Address',
        content: <ShipmentAddressShow address={shipment?.second_shipper_address} />,
      },
      {
        title: 'Contact',
        content: <ShipmentContactShow contact={shipment?.second_shipper_contact} />,
      },
    ]

    return rowsItems
  }, [shipment])

  const loadingRows = useMemo(() => {
    let loadingRowsItems = []

    if (shipment.pick_up_id === shipment.shipper_id) {
      loadingRowsItems.push({
        title: 'Address',
        content: <Lozenge appearance="default">same as shipper</Lozenge>,
        key: 'AddressLoading',
      })
    } else {
      loadingRowsItems.push(
        {
          title: 'Pickup at',
          content: (
            <Link to={`/panel/registries/${shipment?.pickup_at?.id_auto}`} target="_blank">
              {shipment?.pickup_at?.company_name} <ShortcutIcon size={16} />
            </Link>
          ),
        },
        {
          title: 'Address',
          content: <ShipmentAddressShow address={shipment?.pickup_address} />,
        },
        {
          title: 'Contact',
          content: <ShipmentContactShow contact={shipment?.pickup_contact} />,
        }
      )
    }

    if (shipment?.pickups.length > 0) {
      let pickups = shipment.pickups.map((pickup, index) => {
        return {
          title: <>Pickup by {index > 0 && <small>(Pickup {index + 1})</small>}</>,
          content: (
            <Link to={`/panel/registries/${pickup.carrier_data?.id_auto}`} target="_blank">
              {pickup.carrier_data?.company_name} <ShortcutIcon size={16} />
            </Link>
          ),
        }
      })

      loadingRowsItems.push(...pickups)
    }

    loadingRowsItems.push(
      {
        title: 'Date',
        content: shipment.loading_date || <Lozenge appearance="default">Not set</Lozenge>,
      },
      {
        title: 'Reference',
        content: shipment.loading_reference || <Lozenge appearance="default">Not set</Lozenge>,
      }
    )

    return loadingRowsItems
  }, [shipment])

  return (
    <Card title="Shipper">
      <Grid className="w-full" gutterMd="lg">
        <Grid.Col md={6}>
          <RowTable
            rows={generalRows}
            verticalAlign="align-top"
            width={isMobile ? 'w-1/2' : 'w-1/3'}
          />

          {!!shipment.second_shipper?.id_auto && (
            <>
              <CardSubtitle withLine={true} children="Second shipper" />
              <RowTable
                rows={secondShipperRows}
                verticalAlign="align-top"
                width={isMobile ? 'w-1/2' : 'w-1/3'}
              />
            </>
          )}
        </Grid.Col>

        <Grid.Col md={6}>
          <CardSubtitle>Loading information</CardSubtitle>

          {shipment.pick_up_id ? (
            <RowTable
              rows={loadingRows}
              verticalAlign="align-top"
              width={isMobile ? 'w-1/2' : 'w-1/3'}
            />
          ) : (
            <Lozenge appearance="moved">NOT PROVIDED</Lozenge>
          )}
        </Grid.Col>
      </Grid>
    </Card>
  )
}

export default ShipmentShipperDetail
