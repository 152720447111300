import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

// Utils
import { findOption } from 'utils/helpers'
import { formatMonetaryNumber } from 'utils/numbers'
import { modeOptions } from 'components/Shipments/constants'
import { calculationMethodOptions } from 'components/Rates/constants'
import { areaTypeOptions } from 'features/marginCalculator/utils/constants'

// Hooks
import { useMarginCalculator } from 'features/marginCalculator/api/useMarginCalculator'

// Interfaces
import { Grid } from '@royan-co/user-interface'

// Components
import RowTable from 'components/UI/RowTable'
import Card, { CardSubtitle } from 'components/UI/Card'
import FixMinimumTag from 'features/marginCalculator/components/tags/FixMinimumTag'
import MarginCalculatorTypeTag from 'features/marginCalculator/components/tags/TypeTag'
import RegistryClassificationTag from 'components/Registries/ClassificationTag'
import MustGoTag from 'features/marginCalculator/components/tags/MustGoTag'
import MarginCalculatorRouteTag from 'features/marginCalculator/components/tags/RouteTag'

const MarginCalculatorViewGeneralPage = () => {
  const { marginId } = useParams()
  const { data: margin } = useMarginCalculator(marginId)

  const generalRows = useMemo(() => {
    let rows = [
      {
        title: 'Shipment type',
        content: findOption(modeOptions, margin?.shipment_type)?.label,
      },
      {
        title: 'Type',
        content: (
          <div className="flex gap-1 items-center">
            <MarginCalculatorTypeTag type={margin?.country_area} />
            {margin?.country_area === 1 ? (
              <small className="text-neutral-200">
                {margin.area !== undefined ? `(${findOption(areaTypeOptions, margin.area)?.label})` : ''}
              </small>
            ) : (
              ''
            )}
          </div>
        ),
      },
    ]

    if (margin.country_area === 0) {
      margin?.routes.forEach((element, index) => {
        rows.push({
          title: `Route (${index + 1})`,
          content: (
            <MarginCalculatorRouteTag
              key={index}
              departureCountry={element.departure_country}
              arrivalCountry={element.arrival_country}
            />
          ),
        })
      })
    }

    return [
      ...rows,
      {
        title: 'Registry classification',
        content: <RegistryClassificationTag value={margin?.type} />,
      },
      {
        title: 'Applicable to “Must go”',
        content: <MustGoTag value={margin?.must_go} />,
      },
    ]
  }, [margin])

  const calculationRows = useMemo(
    () => [
      {
        title: 'Method',
        content: findOption(calculationMethodOptions, margin?.calculation_method)?.label,
      },
      {
        title: 'Minimum margin',
        content: formatMonetaryNumber(margin?.minimun || 0) + ' EUR',
      },
      {
        title: 'Fixed minimum',
        content: <FixMinimumTag value={margin?.minimun_fix} />,
      },
    ],
    [margin]
  )

  return (
    <>
      <Card className="mb-6">
        <Grid className="w-full">
          <Grid.Col lg={6}>
            <RowTable rows={generalRows} width="w-1/2" />
          </Grid.Col>

          <Grid.Col lg={6}>
            <CardSubtitle className="pb-3 pt-2">Calculation</CardSubtitle>
            <RowTable rows={calculationRows} width="w-1/2" />
          </Grid.Col>
        </Grid>
      </Card>
    </>
  )
}

export default MarginCalculatorViewGeneralPage
