import { useMutation } from '@tanstack/react-query'

import { duplicateShipment } from 'api/shipments'

//Atlassian
import Spinner from '@atlaskit/spinner'
import { DropdownItem } from '@atlaskit/dropdown-menu'

//Interfaces
import { useFlags, useRoyanTable } from '@royan-co/user-interface'

const DuplicateShipment = ({ shipmentId, entity }) => {
  const { refresh } = useRoyanTable()
  const { showSuccessFlag, showWarningFlag } = useFlags()

  const shipmentDuplicateMutation = useMutation(duplicateShipment.bind(null, entity))

  const handleDuplicateShipment = () => {
    shipmentDuplicateMutation.mutate(shipmentId, {
      onError: (e) => {
        console.error(e)
        showWarningFlag(`Something wrong on duplicate ${entity}.`)
      },
      onSuccess: (res) => {
        window.open(`${window.location.origin}/panel/${entity}s/${res.shipment_id}`, '_blank')

        if (refresh) {
          refresh()
        }

        showSuccessFlag(res?.message)
      },
      onSettled: () => {
        // for close dropdown popup
        const event = new MouseEvent('click')
        document.body.dispatchEvent(event)
      },
    })
  }

  return (
    <>
      <DropdownItem
        isDisabled={shipmentDuplicateMutation?.isLoading}
        onClick={(e) => {
          e.stopPropagation()
          handleDuplicateShipment()
        }}
      >
        <div className="flex justify-between">
          Duplicate
          {shipmentDuplicateMutation?.isLoading && (
            <span className="ml-2">
              <Spinner size="small" />
            </span>
          )}
        </div>
      </DropdownItem>
    </>
  )
}

export default DuplicateShipment
