import { useMemo } from 'react'
import { useMutation } from '@tanstack/react-query'

// Apis
import { createInvoiceRow } from '../api/row'

//Hooks
import { useInvoice, useUpdateInvoice } from '../api/getInvoice'

// Utils
import { handleErrorOnSubmit } from 'utils/errors'

// Atlassian
import Modal, { ModalTitle, ModalHeader, ModalTransition } from '@atlaskit/modal-dialog'

// Interfaces
import { Form, useFlags, WarningBannerField } from '@royan-co/user-interface'

// Components
import LinkInvoiceToShipmentForm from './LinkInvoiceToShipmentForm'
import LinkInvoiceFormButtons from './LinkInvoiceFormButtons'
import LinkInvoiceToConsolidationForm from './LinkInvoiceToConsolidationForm'

const LinkInvoiceModal = ({ isOpen, onClose, invoiceId, entity }) => {
  const { showSuccessFlag } = useFlags()

  const { upsertInvoiceProperties } = useUpdateInvoice(invoiceId)
  const { invoice } = useInvoice(invoiceId)
  const isCredit = useMemo(() => ['NFX', 'NFI', 'NFE'].includes(invoice?.do), [invoice])

  const rowCreationMutation = useMutation(createInvoiceRow.bind(null, invoiceId))

  const handleSubmit = (data, { setError }) => {
    const formData = {
      rows: data.rows
        .filter((r) => !!r.checked)
        .map((r) => ({
          id: r.id,
          real_cost: r.real_cost,
          type: r.type,
        })),
    }

    if (entity === 'shipment') formData.shipment_id = data.shipment_id
    else formData.consolidation_id = data.consolidation_id

    rowCreationMutation.mutate(formData, {
      onError: (e) => {
        handleErrorOnSubmit(e, setError, formData)
        console.error(e)
      },
      onSuccess: (res) => {
        upsertInvoiceProperties(res.invoice)
        showSuccessFlag(res.message)
        handleCloseModal()
      },
    })
  }

  const handleCloseModal = () => {
    onClose()
    rowCreationMutation.reset()
  }

  return (
    <>
      <ModalTransition>
        {isOpen && (
          <Modal width={'large'} onClose={handleCloseModal} shouldScrollInViewport>
            <ModalHeader>
              <ModalTitle>Link to a {entity}</ModalTitle>
            </ModalHeader>
            <Form onSubmit={handleSubmit}>
              {() => (
                <>
                  {entity === 'shipment' ? (
                    <LinkInvoiceToShipmentForm isCredit={isCredit} />
                  ) : (
                    <LinkInvoiceToConsolidationForm isCredit={isCredit} />
                  )}

                  <div className="px-6">
                    <WarningBannerField />

                    <LinkInvoiceFormButtons
                      onCancel={handleCloseModal}
                      isLoadingSubmit={rowCreationMutation.isLoading}
                    />
                  </div>
                </>
              )}
            </Form>
          </Modal>
        )}
      </ModalTransition>
    </>
  )
}

export default LinkInvoiceModal
