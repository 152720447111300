import { useQuery } from '@tanstack/react-query'

// Apis
import { getMarginCalculatorBrackets } from './margin-calculator-requests'

/**
 * get brackets of margin calculator value
 * @param {String|Number} marginId
 * @param {import('@tanstack/react-query').UseQueryOptions} options
 * @returns {import('@tanstack/react-query').UseQueryResult}
 */
export const useBrackets = (marginId, options = {}) => {
  const _marginId = +marginId

  return useQuery({
    queryKey: ['margin-calculator', 'brackets', _marginId],
    queryFn: () => getMarginCalculatorBrackets(_marginId),
    staleTime: Infinity,
    ...options,
  })
}
