import { getSurchargesRate } from 'api/rates'
import { useQuery, useQueryClient } from '@tanstack/react-query'

const useRateSurcharges = (rateId) => {
  const queryClient = useQueryClient()

  const {
    data: { surcharges } = [],
    isLoading,
    isError,
    refetch,
  } = useQuery(['surcharges', rateId], () => getSurchargesRate(rateId), {
    staleTime: Infinity,
  })

  const deleteSurchargeFromData = (deletedId) => {
    queryClient.setQueryData(['surcharges', rateId], (oldSurcharges) => ({
      ...oldSurcharges,
      surcharges: oldSurcharges.surcharges.filter((s) => s.id !== deletedId),
    }))
  }

  const upsertSurchargeToData = (surcharge) => {
    const isExist = (surcharges || []).some((s) => s.id === surcharge.id)

    queryClient.setQueryData(['surcharges', rateId], (oldSurcharges = {}) => ({
      ...oldSurcharges,
      surcharges: isExist
        ? (oldSurcharges.surcharges || []).map((s) => (s.id === surcharge.id ? surcharge : s))
        : [surcharge, ...(oldSurcharges.surcharges || [])],
    }))
  }

  const invalidate = () => {
    queryClient.invalidateQueries({ queryKey: ['surcharges', rateId], refetchType: 'none' })
  }

  return {
    surcharges,
    isLoadingRateSurcharges: isLoading,
    isErrorRateSurcharges: isError,
    refetchRateSurcharges: refetch,
    deleteSurchargeFromData,
    upsertSurchargeToData,
    invalidate,
  }
}

export default useRateSurcharges
