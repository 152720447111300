import { useMutation, useQueryClient } from '@tanstack/react-query'

// Apis
import { createCurrency, editCurrency } from './currencies-requests'

const useUpsertCurrency = (currencyCode) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data) => (currencyCode ? editCurrency(currencyCode, data) : createCurrency(data)),
    onSuccess: (res) => {
      queryClient.removeQueries({
        queryKey: ['currency', currencyCode],
      })
    },
    onError: (e) => {
      console.error(e)
    },
  })
}

export { useUpsertCurrency }
