import { useState, useEffect, useCallback, useRef } from 'react'
import axios from 'axios'

// Apis
import { getTrackingUnitsAutocomplete } from 'api/consolidations'

// Interfaces
import { useDebounce } from '@royan-co/user-interface'

const useTrackingUnitAutocomplete = () => {
  const controller = useRef()
  const [trackingAutocompleteOptions, setTrackingAutocompleteOptions] = useState([])
  const [term, setTerm] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const debouncedTerm = useDebounce(term)

  const getOptions = useCallback(async () => {
    try {
      setIsLoading(true)
      if (controller.current) {
        controller.current.abort()
      }

      controller.current = new AbortController()
      const data = await getTrackingUnitsAutocomplete(debouncedTerm, controller.current)
      const _options = data.map((item) => ({
        label: item.serial_number,
        value: item.id_auto,
      }))

      setTrackingAutocompleteOptions(_options)
      setIsLoading(false)
    } catch (error) {
      if (!axios.isCancel(error)) {
        setIsLoading(false)
        console.error(error)
      }
    }
  }, [debouncedTerm])

  const addTrackingUnitOption = useCallback((newOption) => {
    setTrackingAutocompleteOptions((options) => [...options, newOption])
  }, [])

  useEffect(() => {
    if (debouncedTerm) getOptions()
  }, [debouncedTerm, getOptions])

  return {
    trackingAutocompleteOptions,
    addTrackingUnitOption,
    setTermTrackingAutocomplete: setTerm,
    isLoadingTrackingAutocomplete: isLoading,
  }
}

export default useTrackingUnitAutocomplete
