import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import useShipment from 'hooks/useShipment'
import { getShipmentEntranceImage, getShipmentHistory } from 'api/shipments'

//Atlassian
import Button from '@atlaskit/button'
import Spinner from '@atlaskit/spinner'
import EmptyState from '@atlaskit/empty-state'
import { LoadingButton } from '@atlaskit/button'
import SectionMessage from '@atlaskit/section-message'

//Interfaces
import { Timeline, TimelineItem, useFlags } from '@royan-co/user-interface'

//Components
import ShipmentEntranceImageModal from 'components/Shipments/View/EntranceImageModal'

const ShipmentViewHistoryPage = () => {
  const { shipment } = useShipment()
  const { shipmentId, quotationId } = useParams()
  const id = shipmentId || quotationId

  const {
    data: history,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ['shipment', 'history', id],
    queryFn: () => getShipmentHistory(id),
    // retry: 2,
    // staleTime: Infinity,
  })

  const [entranceImage, setEntranceImage] = useState(null)
  const [isLoadingEntranceImage, setIsLoadingEntranceImage] = useState(false)
  const [clickedEntranceButton, setClickedEntranceButton] = useState(null)
  const [isShowingEntranceModal, setIsShowingEntranceModal] = useState(false)
  const [signer, setSigner] = useState(null)

  const { showWarningFlag } = useFlags()

  useEffect(() => {
    setSigner(shipment?.signatures?.[0]?.signer_data)
  }, [shipment])

  function closeModal() {
    setIsShowingEntranceModal(false)
  }

  const loadEntranceImage = async (pinIndex) => {
    try {
      setClickedEntranceButton(pinIndex)
      setIsLoadingEntranceImage(true)

      const { document } = await getShipmentEntranceImage(shipment?.id)

      setEntranceImage(document?.url)
      setIsShowingEntranceModal(true)
    } catch (error) {
      showWarningFlag('We could not load entrance image.')
      console.error(error)
    } finally {
      setIsLoadingEntranceImage(false)
    }
  }

  return (
    <>
      {isLoading ? (
        <div className="text-center mt-24">
          <Spinner />
        </div>
      ) : isError ? (
        <SectionMessage
          appearance="warning"
          title="Something wrong on loading history, please retry."
        >
          <Button onClick={() => refetch()}>Retry</Button>
        </SectionMessage>
      ) : !history || history?.length < 1 ? (
        <EmptyState header="No history available." />
      ) : (
        <div>
          <Timeline>
            {history?.map((pin, index) => (
              <TimelineItem
                key={index}
                title={pin.title}
                description={
                  pin?.status === 102 ? (
                    <>
                      {signer !== null && (
                        <>
                          Signed by {signer?.full_name} on {pin?.creation_date} with email{' '}
                          {signer?.email} and phone number {signer?.full_cellphone}.
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      Recorded at {pin?.creation_date} by {pin?.creator?.full_name}
                    </>
                  )
                }
                action={
                  pin?.status === 4 && (
                    <LoadingButton
                      onClick={() => loadEntranceImage(index)}
                      appearance={'primary'}
                      spacing={'compact'}
                      isLoading={clickedEntranceButton === index && isLoadingEntranceImage}
                    >
                      View entrance image
                    </LoadingButton>
                  )
                }
              />
            ))}
          </Timeline>

          <ShipmentEntranceImageModal
            isOpen={isShowingEntranceModal}
            closeModal={closeModal}
            image={entranceImage}
          />
        </div>
      )}
    </>
  )
}

export default ShipmentViewHistoryPage
