import { useParams } from 'react-router-dom'

// Contexts
import { useAuth } from 'contexts/AuthProvider'

// Hooks
import useShipment from 'hooks/useShipment'

// Features
import { useCanAccess } from 'features/auth'

// Atlassian
import Button from '@atlaskit/button'

// Interfaces
import { useDisclosure } from '@royan-co/user-interface'

// Components
import ShipmentsShareModal from './ShareModal'
import { generateShipmentUserPermissions } from 'components/Shipments/Create/shipment-user-permissions'

const ShipmentViewSharesActions = () => {
  const { shipmentId } = useParams()
  const { currentUser } = useAuth()
  const { shipment } = useShipment()
  const userPermissions = generateShipmentUserPermissions(shipment, currentUser)

  const canCreateSharing = useCanAccess({
    resource: 'shipments.share',
    action: 'create',
    userPermissions,
  })
  const [isOpenShareModal, shareModalHandler] = useDisclosure('share')

  return (
    <>
      {canCreateSharing && (
        <Button
          appearance="primary"
          onClick={() => {
            shareModalHandler.open()
          }}
        >
          Share shipment
        </Button>
      )}

      <ShipmentsShareModal
        isOpen={isOpenShareModal}
        onClose={shareModalHandler.close}
        shipmentId={shipmentId}
      />
    </>
  )
}

export default ShipmentViewSharesActions
