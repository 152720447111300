import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { saveAs } from 'file-saver'
import { useMutation } from '@tanstack/react-query'

// Api
import { downloadConsolidationManifest } from 'api/consolidations'

// Hooks
import useConsolidation from 'hooks/useConsolidation'

// Atlassian
import Button from '@atlaskit/button'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'
import Spinner from '@atlaskit/spinner'

// Interfaces
import { useFlags } from '@royan-co/user-interface'

//Components
import { manifestTypes } from '../constants'

const ConsolidationDownloadManifest = () => {
  const { consolidationId } = useParams()
  const { showWarningFlag } = useFlags()

  const [downloadManifestType, setDownloadManifestType] = useState('')
  const { consolidation } = useConsolidation(consolidationId, {
    enabled: !!consolidationId,
  })

  const manifestMutation = useMutation(downloadConsolidationManifest.bind(null, consolidationId))

  const downloadManifest = (type) => {
    setDownloadManifestType(type)

    manifestMutation.mutate(type, {
      onError: (e) => {
        showWarningFlag('Something went wrong.')
        console.error(e)
      },
      onSuccess: (res) => {
        saveAs(
          new Blob([res]),
          `Consolidation ${consolidation.consolidation_code} - ${type} manifest.pdf`
        )
      },
      onSettled: () => {
        setDownloadManifestType('')
        // for close dropdown popup
        const event = new MouseEvent('click')
        document.body.dispatchEvent(event)
      },
    })
  }

  return (
    <>
      <DropdownMenu
        placement="bottom-end"
        trigger={({ triggerRef, ...props }) => (
          <Button ref={triggerRef} {...props} appearance="default" children={'Download manifest'} />
        )}
      >
        <DropdownItemGroup>
          <div style={{ minWidth: '180px' }}>
            {manifestTypes.map(({ key, label }) => {
              if (key === 'air' && consolidation?.shipment_type !== 2) return null
              else
                return (
                  <DropdownItem
                    onClick={(e) => {
                      e.stopPropagation()
                      downloadManifest(key)
                    }}
                    key={key}
                  >
                    <div className="flex justify-between">
                      {label}
                      {manifestMutation.isLoading && downloadManifestType === key && (
                        <span className="ml-2">
                          <Spinner size="small" />
                        </span>
                      )}
                    </div>
                  </DropdownItem>
                )
            })}
          </div>
        </DropdownItemGroup>
      </DropdownMenu>
    </>
  )
}

export default ConsolidationDownloadManifest
