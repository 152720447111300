import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getShipmentShares } from 'api/shipments'

const useSharesShipment = (shipmentId) => {
  const queryClient = useQueryClient()

  const {
    data: sharesList = [],
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ['shipment', 'shares', shipmentId],
    queryFn: () => getShipmentShares(shipmentId),
    enabled: !!shipmentId,
  })

  const invalidate = () => {
    queryClient.invalidateQueries({ queryKey: ['shipment', 'shares', shipmentId] })
  }

  const deleteSharingFromData = (sharing) => {
    queryClient.setQueryData(['shipment', 'shares', shipmentId], (sharesList) =>
      sharesList.filter((s) => {
        return (
          sharing?.shared_to?.id_auto !== s?.shared_to?.id_auto &&
          sharing?.shared_to_address?.id_auto !== s?.shared_to_address?.id_auto
        )
      })
    )
  }

  const upsertSharingToData = (sharing) => {
    queryClient.setQueryData(['shipment', 'shares', shipmentId], (sharesList) => [
      ...sharesList,
      sharing,
    ])
  }

  return {
    isError,
    refetch,
    isLoading,
    sharesList,
    invalidate,
    upsertSharingToData,
    deleteSharingFromData,
  }
}

export default useSharesShipment
