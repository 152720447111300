import { useEffect, useMemo, useRef } from 'react'

// Utils
import { findOption, transformSelectField } from 'utils/helpers'

// Hooks
import useShipmentsEnumerations from 'hooks/useShipmentsEnumerations'
import usePaymentConditions from 'hooks/usePaymentConditions'
import useInvoiceEnumerations from 'hooks/useInvoiceEnumerations'
import { useInvoiceDefaultValues } from '../api/getIvoiceDefaultValues'

// Atlassian
import Select from '@atlaskit/select'
import Textfield from '@atlaskit/textfield'
import { ErrorMessage } from '@atlaskit/form'
import { DatePicker } from '@atlaskit/datetime-picker'

// Interfaces
import { Field } from '@royan-co/user-interface'
import { useFormContext } from '@royan-co/user-interface'

// Components
import RegistrySelect from 'components/Registries/RegistrySelect'

const CreatePassiveInvoiceFormGeneral = () => {
  const changedSupplierRef = useRef()
  const { watch, setValue } = useFormContext()
  const { getEnumerationOptionsByKey, isLoadingShipmentEnumeration } = useShipmentsEnumerations()

  const supplier = watch('supplier')?.value

  const { paymentConditionOptions, isLoadingPaymentConditions } = usePaymentConditions()
  const { docCodesOptions, isLoadingInvoiceEnumerations } = useInvoiceEnumerations()
  const { data: registryData, isInitialLoading: isLoadingRegistriesData } = useInvoiceDefaultValues(
    { registry_id: supplier },
    {
      enabled: !!supplier && !!changedSupplierRef.current,
    }
  )

  const documentTypesOptions = useMemo(() => {
    if (registryData?.document_types) {
      const _options = docCodesOptions?.filter((doc) =>
        registryData.document_types.includes(doc.value)
      )
      return _options.length > 0 ? _options : docCodesOptions
    } else return docCodesOptions
  }, [docCodesOptions, registryData?.document_types])

  const handleChangeSupplier = () => {
    if (!changedSupplierRef.current) changedSupplierRef.current = true
    setValue('payment_condition', null)
    setValue('document_type', null)
  }

  useEffect(() => {
    if (findOption(paymentConditionOptions, registryData?.payment_term))
      setValue('payment_condition', registryData.payment_term)
  }, [setValue, registryData, paymentConditionOptions])

  return (
    <>
      <div className="grid md:grid-cols-2 md:gap-4">
        <Field
          isRequired
          type="select"
          name="supplier"
          label="Supplier"
          className="!mt-0"
          onValueChange={handleChangeSupplier}
        >
          {({ fieldProps, error }) => (
            <>
              <RegistrySelect {...fieldProps} isImported isActive registryType="supplier" />
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </>
          )}
        </Field>

        <Field isRequired label="Invoice number" name="invoice_number" className="!mt-0">
          {({ fieldProps, error }) => (
            <>
              <Textfield placeholder=" " {...fieldProps} />
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </>
          )}
        </Field>
      </div>

      <Field isRequired label="Invoice date" name="invoice_date" className="!mt-4">
        {({ fieldProps, error }) => (
          <>
            <DatePicker placeholder=" " {...fieldProps} />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </>
        )}
      </Field>

      <div className="grid md:grid-cols-2 md:gap-4">
        <Field isRequired label="Total amount" name="amount" className="!mt-4">
          {({ fieldProps, error }) => (
            <>
              <Textfield placeholder=" " {...fieldProps} />
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </>
          )}
        </Field>

        <Field
          isRequired
          type="select"
          name="currency"
          label="Currency"
          transform={transformSelectField(getEnumerationOptionsByKey('currencies'))}
          className="!mt-4"
        >
          {({ fieldProps, error }) => (
            <>
              <Select
                menuPlacement="auto"
                placeholder="Choose a currency"
                isLoading={isLoadingShipmentEnumeration}
                options={getEnumerationOptionsByKey('currencies')}
                {...fieldProps}
              />
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </>
          )}
        </Field>
      </div>

      <div className="grid md:grid-cols-2 md:gap-4">
        <Field
          isRequired
          type="select"
          name="document_type"
          label="Document type"
          transform={transformSelectField(documentTypesOptions)}
          className="!mt-4"
        >
          {({ fieldProps, error }) => (
            <>
              <Select
                isDisabled={
                  isLoadingRegistriesData ||
                  isLoadingInvoiceEnumerations ||
                  !changedSupplierRef.current
                }
                options={documentTypesOptions}
                isLoading={isLoadingRegistriesData || isLoadingInvoiceEnumerations}
                {...fieldProps}
              />
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </>
          )}
        </Field>

        <Field
          isRequired
          type="select"
          name="payment_condition"
          label="Terms of payment"
          transform={transformSelectField(paymentConditionOptions)}
          className="!mt-4"
        >
          {({ fieldProps, error }) => (
            <>
              <Select
                isClearable
                placeholder="Choose"
                options={paymentConditionOptions}
                isLoading={isLoadingPaymentConditions || isLoadingRegistriesData}
                {...fieldProps}
              />
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </>
          )}
        </Field>
      </div>
    </>
  )
}

export default CreatePassiveInvoiceFormGeneral
