import React from 'react'

// Hooks
import { useDeleteMarginCalculator } from './../../api/useDeleteMarginCalculator'

// Atlassian
import Modal, {
  ModalBody,
  ModalTitle,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import Button, { LoadingButton } from '@atlaskit/button'

// Interfaces
import { useFlags, WarningBanner } from '@royan-co/user-interface'

const MarginCalculatorDeleteModal = ({ isOpen, onClose, marginId }) => {
  const { showSuccessFlag } = useFlags()

  const marginCalculatorDeleteMutation = useDeleteMarginCalculator(marginId)

  const closeModal = () => {
    onClose()
    marginCalculatorDeleteMutation.reset()
  }

  const removeMarginCalculator = () => {
    marginCalculatorDeleteMutation.mutate(marginId, {
      onSuccess: (res) => {
        showSuccessFlag(res.message)
        onClose()
      },
    })
  }

  return (
    <>
      <ModalTransition>
        {isOpen && (
          <Modal onClose={closeModal}>
            <ModalHeader>
              <ModalTitle appearance="danger">Delete margin calculator</ModalTitle>
            </ModalHeader>
            <ModalBody>
              You are about to delete a margin calculator. Are you sure?
              {marginCalculatorDeleteMutation.isError && <WarningBanner />}
            </ModalBody>
            <ModalFooter>
              <Button appearance="subtle" onClick={closeModal}>
                Cancel
              </Button>
              <LoadingButton
                isLoading={marginCalculatorDeleteMutation.isLoading}
                appearance="danger"
                onClick={removeMarginCalculator}
                autoFocus
              >
                Delete
              </LoadingButton>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </>
  )
}

export default MarginCalculatorDeleteModal
