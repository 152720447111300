import { useEffect, useMemo } from 'react'

import { findOption, transformSelectField } from 'utils/helpers'

import {
  shipmentOwnershipOptions,
  shipmentTypeOptions,
  quotationStatusOptions,
  shipmentStatusOptions,
  shipmentInvoicedOptions,
  shipmentQuotationSentOptions,
  shipmentTemperatureOptions,
  modeOptions,
  containerTypes,
  truckTypes,
  shipmentCustomDocumentOptions,
  shipmentDOOptions,
  shipmentHubEnteredOptions,
  dangerousCargoOptions,
  shipmentEntranceImageOptions,
} from '../constants'

// Hooks
import useShipmentsHubs from 'hooks/useShipmentsHubs'
import useShipmentsAgents from 'hooks/useShipmentsAgents'
import useShipmentsOperators from 'hooks/useShipmentsOperators'

// Atlassian
import TextField from '@atlaskit/textfield'
import Select, { CountrySelect } from '@atlaskit/select'
import { DatePicker } from '@atlaskit/datetime-picker'
import { allCountries as countries } from '/node_modules/@atlaskit/select/dist/es2019/data/countries.js'

// Interfaces
import { Field, Grid, useFormContext } from '@royan-co/user-interface'

const ShipmentsFilterFields = ({ appliedFilters, entity }) => {
  const { watch, setValue } = useFormContext()
  const { shipmentsHubsOptions, isLoadingShipmentsHubs } = useShipmentsHubs()
  const { shipmentsAgentsOptions, isLoadingShipmentsAgents } = useShipmentsAgents()
  const { shipmentsOperatorsOptions, isLoadingShipmentsOperators } = useShipmentsOperators()

  const isShipment = useMemo(() => !!(entity === 'shipment'), [entity])

  const mode = watch('shipment_type')
  const hasEntrance = watch('has_entrance')
  const status = watch('status')

  useEffect(() => {
    if (![0, 1].includes(mode)) setValue('type_of_collies', null)
    if (![2].includes(mode)) setValue('waybill', null)
    else if (![0, 1].includes(mode)) setValue('means_code', null)
  }, [mode, setValue])

  useEffect(() => {
    if (!hasEntrance) setValue('hub', null)
  }, [hasEntrance, setValue])

  useEffect(() => {
    if (status !== 4 && isShipment) setValue('has_entrance_image', null)
  }, [isShipment, setValue, status])

  const groupStatusOptions = useMemo(() => {
    let _object = {}
    shipmentStatusOptions.forEach((o) => {
      _object[o.group] = [...(_object[o.group] || []), { label: o.label, value: o.value }]
    })

    return Object.keys(_object).map((g) => ({ label: g, options: _object[g] }))
  }, [])

  return (
    <>
      {isShipment && (
        <Field
          label="Ownership"
          type="select"
          name="shared"
          transform={{
            output: (opt) => (opt?.value !== undefined ? opt.value : null),
            input: (v) => findOption(shipmentOwnershipOptions, v),
          }}
        >
          {({ fieldProps }) => (
            <Select
              isClearable={true}
              options={shipmentOwnershipOptions}
              placeholder="Choose"
              {...fieldProps}
            />
          )}
        </Field>
      )}

      <Field
        label="Mode"
        type="select"
        name="shipment_type"
        transform={{
          output: (opt) => (opt?.value !== undefined ? opt.value : null),
          input: (v) => findOption(modeOptions, v),
        }}
      >
        {({ fieldProps }) => (
          <Select
            isClearable={true}
            options={modeOptions}
            placeholder="Choose a mode"
            {...fieldProps}
          />
        )}
      </Field>

      {[0, 1].includes(mode) && (
        <Field
          type="select"
          label="Required service"
          name="type_of_collies"
          transform={{
            output: (opt) => (opt?.value !== undefined ? opt.value : null),
            input: (v) => findOption(mode === 1 ? containerTypes : truckTypes, v),
          }}
        >
          {({ fieldProps }) => (
            <Select
              isClearable={true}
              options={mode === 1 ? containerTypes : truckTypes}
              placeholder="Choose a service"
              {...fieldProps}
            />
          )}
        </Field>
      )}

      {isShipment && (
        <Field
          label="Type"
          type="select"
          name="is_master"
          transform={{
            output: (opt) => (opt?.value !== undefined ? opt.value : null),
            input: (v) => findOption(shipmentTypeOptions, v),
          }}
        >
          {({ fieldProps }) => (
            <Select
              isClearable={true}
              options={shipmentTypeOptions}
              placeholder="Choose"
              {...fieldProps}
            />
          )}
        </Field>
      )}

      <Field
        label="Status"
        type="select"
        name="status"
        transform={{
          output: (opt) => (opt?.value !== undefined ? opt.value : null),
          input: (v) => findOption(isShipment ? shipmentStatusOptions : quotationStatusOptions, v),
        }}
      >
        {({ fieldProps }) => (
          <Select
            isClearable={true}
            options={isShipment ? groupStatusOptions : quotationStatusOptions}
            placeholder="Choose a status"
            {...fieldProps}
          />
        )}
      </Field>

      {isShipment && status === 4 && (
        <Field
          label="Entrance image"
          type="select"
          name="has_entrance_image"
          transform={transformSelectField(shipmentEntranceImageOptions)}
        >
          {({ fieldProps }) => (
            <Select
              isClearable={true}
              options={shipmentEntranceImageOptions}
              placeholder="Choose one"
              {...fieldProps}
            />
          )}
        </Field>
      )}

      <Grid>
        <Grid.Col sm={6}>
          <Field label="From" name="created_from" defaultValue="">
            {({ fieldProps }) => <DatePicker placeholder="2/18/2022" {...fieldProps} />}
          </Field>
        </Grid.Col>

        <Grid.Col sm={6}>
          <Field label="To" name="created_to" defaultValue="">
            {({ fieldProps }) => <DatePicker placeholder="2/18/2022" {...fieldProps} />}
          </Field>
        </Grid.Col>
      </Grid>

      <Field
        type="select"
        label="Country of loading"
        name="departure_country"
        transform={{
          output: (c) => (c?.abbr !== undefined ? c.abbr : null),
          input: (v) => countries?.find((c) => c.abbr === v),
        }}
      >
        {({ fieldProps: { ref, ...fieldProps } }) => (
          <CountrySelect
            isClearable={true}
            className="w-100"
            placeholder="Choose a country"
            {...fieldProps}
          />
        )}
      </Field>

      <Field label="Departure City" name="departure_city" defaultValue="">
        {({ fieldProps }) => <TextField placeholder="Type the city here" {...fieldProps} />}
      </Field>

      <Field label="Departure Zip code" name="departure_cap" defaultValue="">
        {({ fieldProps }) => <TextField placeholder="Type the zip code here" {...fieldProps} />}
      </Field>

      <Field
        type="select"
        label="Country of delivery"
        name="arrival_country"
        transform={{
          output: (c) => (c?.abbr !== undefined ? c.abbr : null),
          input: (v) => countries?.find((c) => c.abbr === v),
        }}
      >
        {({ fieldProps: { ref, ...fieldProps } }) => (
          <CountrySelect
            isClearable={true}
            className="w-100"
            placeholder="Choose a country"
            {...fieldProps}
          />
        )}
      </Field>

      <Field label="Arrival City" name="arrival_city" defaultValue="">
        {({ fieldProps }) => <TextField placeholder="Type the city here" {...fieldProps} />}
      </Field>

      <Field label="Arrival Zip code" name="arrival_cap" defaultValue="">
        {({ fieldProps }) => <TextField placeholder="Type the zip code here" {...fieldProps} />}
      </Field>

      <Field label="Client" name="client" defaultValue="">
        {({ fieldProps }) => <TextField placeholder="Type the client" {...fieldProps} />}
      </Field>

      {isShipment ? (
        <Field
          type="select"
          label="Operator"
          name="operator"
          transform={{
            output: (opt) => (opt?.value !== undefined ? opt.value : null),
            input: (v) => findOption(shipmentsOperatorsOptions, v),
          }}
        >
          {({ fieldProps }) => (
            <Select
              isClearable={true}
              placeholder="Choose"
              options={shipmentsOperatorsOptions}
              isLoading={isLoadingShipmentsOperators}
              {...fieldProps}
            />
          )}
        </Field>
      ) : (
        <Field
          type="select"
          label="Creator"
          name="creator"
          transform={{
            output: (opt) => (opt?.value !== undefined ? opt.value : null),
            input: (v) => findOption(shipmentsOperatorsOptions, v),
          }}
        >
          {({ fieldProps }) => (
            <Select
              isClearable={true}
              placeholder="Choose"
              options={shipmentsOperatorsOptions}
              isLoading={isLoadingShipmentsOperators}
              {...fieldProps}
            />
          )}
        </Field>
      )}

      {isShipment ? (
        <Field
          label="Invoice"
          type="select"
          name="invoiced_a"
          transform={{
            output: (opt) => (opt?.value !== undefined ? opt.value : null),
            input: (v) => findOption(shipmentInvoicedOptions, v),
          }}
        >
          {({ fieldProps }) => (
            <Select
              isClearable={true}
              options={shipmentInvoicedOptions}
              placeholder="Choose"
              {...fieldProps}
            />
          )}
        </Field>
      ) : (
        <Field
          label="Quotation sent"
          type="select"
          name="quotation_sent"
          transform={{
            output: (opt) => (opt?.value !== undefined ? opt.value : null),
            input: (v) => findOption(shipmentQuotationSentOptions, v),
          }}
        >
          {({ fieldProps }) => (
            <Select
              isClearable={true}
              options={shipmentQuotationSentOptions}
              placeholder="Choose"
              {...fieldProps}
            />
          )}
        </Field>
      )}

      <Field
        type="select"
        label="Agent"
        name="agent"
        transform={{
          output: (opt) => (opt?.value !== undefined ? opt.value : null),
          input: (v) => findOption(shipmentsAgentsOptions, v),
        }}
      >
        {({ fieldProps }) => (
          <Select
            isClearable={true}
            placeholder="Choose"
            options={shipmentsAgentsOptions}
            isLoading={isLoadingShipmentsAgents}
            {...fieldProps}
          />
        )}
      </Field>

      {entity === 'shipment' && (
        <>
          <Field label="Shipper" name="shipper" defaultValue="">
            {({ fieldProps }) => <TextField placeholder="Type the shipper" {...fieldProps} />}
          </Field>

          <Field label="Consignee" name="consignee" defaultValue="">
            {({ fieldProps }) => <TextField placeholder="Type the consignee" {...fieldProps} />}
          </Field>

          {mode === 0 && (
            <Field label="Plate number" name="means_code" defaultValue="">
              {({ fieldProps }) => (
                <TextField placeholder="Type the plate number here" {...fieldProps} />
              )}
            </Field>
          )}

          {mode === 1 && (
            <Field label="Container number" name="means_code" defaultValue="">
              {({ fieldProps }) => (
                <TextField placeholder="Type the container number here" {...fieldProps} />
              )}
            </Field>
          )}

          {mode === 2 && (
            <Field label="AWB" name="waybill" defaultValue="">
              {({ fieldProps }) => <TextField placeholder="Type the AWB here" {...fieldProps} />}
            </Field>
          )}

          <Field
            label="Customs document"
            type="select"
            name="custom_document_flag"
            transform={{
              output: (opt) => (opt?.value !== undefined ? opt.value : null),
              input: (v) => findOption(shipmentCustomDocumentOptions, v),
            }}
          >
            {({ fieldProps }) => (
              <Select
                isClearable={true}
                options={shipmentCustomDocumentOptions}
                placeholder="Choose"
                {...fieldProps}
              />
            )}
          </Field>
        </>
      )}

      <Field label="No. of collies " name="collies" defaultValue="">
        {({ fieldProps }) => (
          <TextField placeholder="Type the number of collies here" {...fieldProps} />
        )}
      </Field>

      <Field label="Weight (Kgs)" name="weight" defaultValue="">
        {({ fieldProps }) => <TextField placeholder="Type the weight here" {...fieldProps} />}
      </Field>

      {isShipment && (
        <>
          <Field label="Entrance number" name="entrance_number" defaultValue="">
            {({ fieldProps }) => (
              <TextField placeholder="Type the entrance number here" {...fieldProps} />
            )}
          </Field>

          <Field label=" Entrance date" name="entrance_date" defaultValue="">
            {({ fieldProps }) => <DatePicker placeholder="2/18/2022" {...fieldProps} />}
          </Field>
        </>
      )}

      <Field
        type="select"
        label="Temperature control"
        name="reefer"
        transform={{
          output: (opt) => (opt?.value !== undefined ? opt.value : null),
          input: (v) => findOption(shipmentTemperatureOptions, v),
        }}
      >
        {({ fieldProps }) => (
          <Select
            isClearable={true}
            placeholder="Choose"
            options={shipmentTemperatureOptions}
            {...fieldProps}
          />
        )}
      </Field>

      <Field
        label="Dangerous cargo"
        type="select"
        name="adr_goods"
        transform={{
          output: (opt) => (opt?.value !== undefined ? opt.value : null),
          input: (v) => findOption(dangerousCargoOptions, v),
        }}
      >
        {({ fieldProps }) => (
          <Select
            isClearable={true}
            placeholder="Choose"
            options={dangerousCargoOptions}
            {...fieldProps}
          />
        )}
      </Field>

      {isShipment && (
        <Field
          label="D/O status"
          type="select"
          name="released"
          transform={{
            output: (opt) => (opt?.value !== undefined ? opt.value : null),
            input: (v) => findOption(shipmentDOOptions, v),
          }}
        >
          {({ fieldProps }) => (
            <Select
              isClearable={true}
              options={shipmentDOOptions}
              placeholder="Choose"
              {...fieldProps}
            />
          )}
        </Field>
      )}

      {isShipment && (
        <>
          <Field
            label="Entered in hub"
            type="select"
            name="has_entrance"
            transform={{
              output: (opt) => (opt?.value !== undefined ? opt.value : null),
              input: (v) => findOption(shipmentHubEnteredOptions, v),
            }}
          >
            {({ fieldProps }) => (
              <Select
                isClearable={true}
                options={shipmentHubEnteredOptions}
                placeholder="Choose"
                {...fieldProps}
              />
            )}
          </Field>

          {hasEntrance && (
            <Field
              type="select"
              label="Hub"
              name="hub"
              transform={{
                output: (opt) => (opt?.value !== undefined ? opt.value : null),
                input: (v) => findOption(shipmentsHubsOptions, v),
              }}
            >
              {({ fieldProps }) => (
                <Select
                  isClearable={true}
                  placeholder="Choose"
                  options={shipmentsHubsOptions}
                  isLoading={isLoadingShipmentsHubs}
                  {...fieldProps}
                />
              )}
            </Field>
          )}
        </>
      )}
    </>
  )
}

export default ShipmentsFilterFields
