import React, { useState } from 'react'

// Hooks
import { useBookingStock } from 'features/AirWaybillStock/api/useBookingStock'

//Atlassian
import Modal, {
  ModalBody,
  ModalTitle,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import Button, { LoadingButton } from '@atlaskit/button'

//Interfaces
import { WarningBanner } from '@royan-co/user-interface'

const AirWaybillStockBookingModal = ({ isOpen, onClose, waybillId, isBooked }) => {
  const [error, setError] = useState()
  const deleteStockMutation = useBookingStock(waybillId)

  const closeModal = () => {
    setError()
    onClose()
    deleteStockMutation.reset()
  }

  const deleteDocument = () => {
    setError()

    deleteStockMutation.mutate(isBooked, {
      onError: (e) => {
        setError(e?.response?.data?.message || 'Something wrong')
        console.error(e)
      },
      onSuccess: () => {
        onClose()
      },
    })
  }

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={closeModal}>
          <ModalHeader>
            <ModalTitle appearance="warning">
              {isBooked ? 'Remove booking' : 'Book waybill'}
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            You are about to {isBooked ? 'remove booking' : 'book a waybill'}. Are you sure?
            {deleteStockMutation.isError && <WarningBanner text={error} />}
          </ModalBody>
          <ModalFooter>
            <Button appearance="subtle" onClick={closeModal}>
              Cancel
            </Button>
            <LoadingButton
              isLoading={deleteStockMutation.isLoading}
              appearance="warning"
              onClick={deleteDocument}
              autoFocus
            >
              Confirm
            </LoadingButton>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  )
}

export default AirWaybillStockBookingModal
