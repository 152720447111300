// Utils
import { transformSelectField } from 'utils/helpers'

// Hooks
import useShipmentsHubs from 'hooks/useShipmentsHubs'

//Atlassian
import { Field, RangeSlider, useFormContext } from '@royan-co/user-interface'
import { ErrorMessage } from '@atlaskit/form'
import Select, { CountrySelect } from '@atlaskit/select'
import Toggle from '@atlaskit/toggle'
import { allCountries as countries } from '/node_modules/@atlaskit/select/dist/es2019/data/countries.js'

//Components
import Card from 'components/UI/Card'
import RegistrySelect from 'components/Registries/RegistrySelect'
import Divider from 'components/UI/Divider'
import RegistrySelectContactField from 'components/Registries/View/SelectContactsField'
import { vehicleTypeOptions } from '../constants'
import { temperatureControlOptions } from 'components/Shipments/Create/constants'
import { modes } from '../Create/modes'

const ConsolidationViewEditGeneral = () => {
  const { watch, setValue, getValues } = useFormContext()
  const { shipmentsHubsOptions, isLoadingShipmentsHubs } = useShipmentsHubs()

  const agent = watch('agent_destination_id')

  const transportationModeOptions = modes.map((mode) => ({
    label: mode?.title + ' ' + mode?.description,
    value: mode?.id,
  }))

  return (
    <Card className="w-full mb-6">
      <Field
        isRequired
        type="select"
        label="Transportation mode"
        name="modeType"
        className="!mt-0 !mb-4"
        transform={transformSelectField(transportationModeOptions)}
      >
        {({ fieldProps, error }) => (
          <>
            <Select
              isClearable={true}
              placeholder="Choose"
              options={transportationModeOptions}
              {...fieldProps}
            />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </>
        )}
      </Field>

      {watch('modeType') === 2 && (
        <Field
          isRequired
          type="select"
          label="Vehicle type"
          name="vehicle_type"
          className="!mt-0 !mb-4"
          transform={transformSelectField(vehicleTypeOptions)}
        >
          {({ fieldProps, error }) => (
            <>
              <Select
                isClearable={true}
                placeholder="Choose"
                options={vehicleTypeOptions}
                {...fieldProps}
              />
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </>
          )}
        </Field>
      )}

      <Field
        isRequired
        type="select"
        label="Consolidator hub"
        name="hub_consolidator"
        className="!mt-0"
        transform={transformSelectField(shipmentsHubsOptions)}
      >
        {({ fieldProps, error }) => (
          <>
            <Select
              isClearable={true}
              placeholder="Choose"
              options={shipmentsHubsOptions}
              isLoading={isLoadingShipmentsHubs}
              {...fieldProps}
            />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </>
        )}
      </Field>

      <Field
        isRequired
        type="select"
        label="Country of delivery"
        name="arrival_country"
        className="!mt-4"
        transform={{
          output: (c) => (c?.abbr !== undefined ? c.abbr : null),
          input: (v) => countries?.find((c) => c.abbr === v),
        }}
      >
        {({ fieldProps: { ref, error, ...fieldProps } }) => (
          <>
            <CountrySelect
              isClearable={true}
              className="w-100"
              placeholder="Choose a country"
              {...fieldProps}
            />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </>
        )}
      </Field>

      <Field type="checkbox" name="reefer" label="Temperature control" className="!mt-4">
        {({ fieldProps }) => (
          <div>
            <Toggle {...fieldProps} />
          </div>
        )}
      </Field>

      {!!watch('reefer') && (
        <div className="grid md:grid-cols-2 md:gap-4">
          <Field type="select" name="range" label="Temperature range">
            {({ fieldProps: { onChange, ...fieldProps }, errorType }) => (
              <div>
                <Select
                  placeholder="Choose a range"
                  onChange={(v) => {
                    onChange(v)
                    setValue('reefer_temperature', [
                      v?.value?.range?.min || getValues('reefer_temperature')?.[0],
                      v?.value?.range?.max || getValues('reefer_temperature')?.[1],
                    ])
                  }}
                  options={temperatureControlOptions}
                  validate={(d) => !!d?.value?.range?.min}
                  {...fieldProps}
                />
                {errorType && <ErrorMessage>Please select range</ErrorMessage>}
              </div>
            )}
          </Field>
        </div>
      )}

      {!!watch('reefer') && watch('range')?.value?.type === 'custom' && (
        <div className="inline-block w-full mt-1">
          <div className="mt-3 mb-4">
            <Field name="reefer_temperature" max={30} min={-25}>
              {({ fieldProps: { ref, ...fieldProps } }) => <RangeSlider {...fieldProps} />}
            </Field>
          </div>
        </div>
      )}

      <Divider className="mt-6 mb-4" />

      <Field
        type="select"
        label="Receiving agent"
        name="agent_destination_id"
        className="!mt-0"
        onValueChange={() => setValue('agent_destination_contact_id', null)}
      >
        {({ fieldProps, error }) => (
          <>
            <RegistrySelect defaultData={getValues('agent_destination_id')} {...fieldProps} />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </>
        )}
      </Field>

      {agent?.value && (
        <RegistrySelectContactField
          name="agent_destination_contact_id"
          label="Contact"
          registryId={agent?.value}
          className="!mt-4"
        />
      )}
    </Card>
  )
}

export default ConsolidationViewEditGeneral
