import { useMemo } from 'react'
import { Line } from 'react-chartjs-2'

// Utils
import { formatNumber } from 'utils/numbers'

const ShipmentsChart = ({ data = {} }) => {
  const chartData = useMemo(
    () => ({
      labels: Object.entries(data)?.map(([key, value]) => key) || [],
      datasets: [
        {
          label: 'Accepted',
          listValues: Object.entries(data)?.map(([key, value]) => value.accepted) || [],
          borderColor: '#36B37E',
          backgroundColor: '#57d9a380',
        },
        {
          label: 'Rejected',
          listValues: Object.entries(data)?.map(([key, value]) => value.rejected) || [],
          borderColor: '#DE350B',
          backgroundColor: '#ff563080',
        },
      ],
    }),
    [data]
  )

  return (
    <>
      <Line
        data={{
          labels: chartData.labels,
          datasets: chartData.datasets.map(
            ({ label, borderColor, backgroundColor, listValues }) => ({
              label: label,
              data: listValues,
              borderColor: borderColor,
              backgroundColor: backgroundColor,
              borderWidth: 1,
              fill: true,
            })
          ),
        }}
        options={{
          responsive: true,
          scaleShowLabels: false,
          tension: 0.4,
          interaction: {
            mode: 'index',
          },
          scales: {
            y: {
              min: 0,
            },
          },
          tooltips: {
            mode: 'index',
            intersect: true,
            callbacks: {
              label: (tooltipItem, data) => {
                const datasetLabel = data.datasets[tooltipItem.datasetIndex].label || ''
                return `${datasetLabel}: ${formatNumber(tooltipItem.yLabel)}`
              },
            },
          },
        }}
      />
    </>
  )
}

export default ShipmentsChart
