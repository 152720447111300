import { forwardRef, useEffect, useState } from 'react'

// Atlassian
import Lozenge from '@atlaskit/lozenge'
import { CreatableSelect } from '@atlaskit/select'

const createOption = (label) => ({
  label,
  value: label,
  isNew: true,
})

const ContactLabel = ({ contact }) => {
  return (
    <div className="flex justify-between">
      <span>{contact?.label}</span>

      <span className="inline-flex items-center pr-2">
        {contact?.whatapp && (
          <span className="mr-1">
            <Lozenge appearance="success">Whatsapp</Lozenge>
          </span>
        )}
        {contact?.tracking && <Lozenge appearance="inprogress">Email</Lozenge>}
      </span>
    </div>
  )
}

const CreatorContactSelect = forwardRef(({ onChange, options, value, ...props }, ref) => {
  const [contactOptions, setContactOptions] = useState(value)
  const [contact, setContact] = useState()

  const onCreateNewOption = (option) => {
    const newOption = createOption(option)
    setContactOptions([...contactOptions, newOption])
    handleChange(newOption)
  }

  const handleChange = (option) => {
    setContact(option)
    onChange(option)
  }

  useEffect(() => setContactOptions(options), [options])
  useEffect(() => {
    if (value !== contact) setContact(value)
  }, [contact, options, value])

  return (
    <CreatableSelect
      ref={ref}
      isClearable
      value={contact}
      onCreateOption={onCreateNewOption}
      options={contactOptions}
      onChange={handleChange}
      formatOptionLabel={(data) => <ContactLabel contact={data} />}
      {...props}
    />
  )
})

export default CreatorContactSelect
