import PropTypes from 'prop-types'

const RowTable = ({ rows = [], width = 'w-1/3', verticalAlign = 'align-middle' }) => {
  return (
    <table>
      <tbody className="border-none">
        {rows.map((row, i) => (
          <tr className="py-2.5" key={i}>
            <td
              className={`text-xs text-neutral-200 font-semibold ${verticalAlign} ${width} ${
                verticalAlign === 'align-top' ? 'py-3' : 'py-2.5'
              }`}
            >
              {row.title}
            </td>
            <td className="py-2.5  align-top text-neutral-800">{row.content}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

RowTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.node.isRequired,
      content: PropTypes.node,
    })
  ).isRequired,
}

export default RowTable
