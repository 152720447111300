import { useMemo } from 'react'
import { useMediaQuery } from 'react-responsive'

// Utils
import { transformSelectField } from 'utils/helpers'
import { vgmIssuersOptions, vgmTypesOptions } from '../constants'

// Hooks
import useShipmentsEnumerations from 'hooks/useShipmentsEnumerations'

// Atlassian
import { LoadingButton } from '@atlaskit/button'
import Drawer from '@atlaskit/drawer'
import TextField from '@atlaskit/textfield'
import { ErrorMessage } from '@atlaskit/form'
import Select from '@atlaskit/select'

// Interfaces
import { Field, Form, WarningBannerField } from '@royan-co/user-interface'

// Components
import RegistrySelectField from 'components/Registries/RegistrySelectField'
import Divider from 'components/UI/Divider'
import NumericInput from 'components/UI/NumericInput'

const ShipmentViewContainerDrawer = ({ onClose, isOpen, data, onSubmit, isLoading = false }) => {
  const isMobile = useMediaQuery({ query: `(max-width: 991.98px)` })
  const isEditing = useMemo(() => data?.container_id, [data])

  const { getEnumerationOptionsByKey, isLoadingShipmentEnumeration } = useShipmentsEnumerations()

  const defaultValues = useMemo(
    () => ({
      ...data,
      container_type: data?.container_type
        ? getEnumerationOptionsByKey('container_types').find(
            (o) => o.label === data.container_type
          ) || null
        : null,
    }),
    [data, getEnumerationOptionsByKey]
  )

  return (
    <Drawer isOpen={isOpen} onClose={onClose} width={isMobile ? 'full' : 'medium'}>
      <div className="drawer">
        <h3>{isEditing ? 'Edit container' : 'New container'}</h3>

        <Form onSubmit={onSubmit} defaultValues={defaultValues}>
          {() => (
            <>
              <Field
                isRequired
                label="Number"
                name="container_number"
                defaultValue=""
                className="!mt-4"
              >
                {({ fieldProps, error }) => (
                  <>
                    <TextField {...fieldProps} placeholder="Type the container number here" />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>

              <Field
                isRequired
                type="select"
                label="Type"
                name="container_type"
                className="!mt-4"
                defaultValue={null}
              >
                {({ fieldProps, error }) => (
                  <>
                    <Select
                      options={getEnumerationOptionsByKey('container_types')}
                      isLoading={isLoadingShipmentEnumeration}
                      placeholder="Choose type"
                      {...fieldProps}
                    />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>

              <RegistrySelectField
                label="Owner"
                name="container_owner"
                registryType="supplier"
                defaultData={data?.container_owner}
                className="!mt-4"
              />

              <Field label="First seal number" name="seal" defaultValue="" className="!mt-4">
                {({ fieldProps, error }) => (
                  <>
                    <TextField {...fieldProps} placeholder="Type the seal number" />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>

              <Field label="Second seal number" name="seal2" defaultValue="" className="!mt-4">
                {({ fieldProps, error }) => (
                  <>
                    <TextField {...fieldProps} placeholder="Type the second seal number" />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>

              <Divider className="mt-6 mb-4" />

              <Field label="Total collies" name="total_collies" defaultValue="" className="!mt-0">
                {({ fieldProps, error }) => (
                  <>
                    <NumericInput
                      {...fieldProps}
                      decimalScale={0}
                      placeholder="Type the number of collies"
                    />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>

              <div className="grid md:grid-cols-2 md:gap-4">
                <Field label="Gross weight" name="gross_weight" defaultValue="" className="!mt-4">
                  {({ fieldProps, error }) => (
                    <>
                      <NumericInput {...fieldProps} placeholder="Type the gross weight" />
                      {error && <ErrorMessage>{error}</ErrorMessage>}
                    </>
                  )}
                </Field>

                <Field label="Net weight" name="net_weight" defaultValue="" className="!mt-4">
                  {({ fieldProps, error }) => (
                    <>
                      <NumericInput {...fieldProps} placeholder="Type the net weight" />
                      {error && <ErrorMessage>{error}</ErrorMessage>}
                    </>
                  )}
                </Field>
              </div>

              <Divider className="mt-6 mb-4" />

              <Field label="VGM" name="vgm" defaultValue="" className="!mt-0">
                {({ fieldProps, error }) => (
                  <>
                    <NumericInput {...fieldProps} decimalScale={2} placeholder="Type the VGM" />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>

              <Field
                type="select"
                label="VGM Type"
                name="vgm_type"
                className="!mt-4"
                defaultValue={null}
                transform={transformSelectField(vgmTypesOptions)}
              >
                {({ fieldProps, error }) => (
                  <>
                    <Select options={vgmTypesOptions} placeholder="Choose a type" {...fieldProps} />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>

              <Field
                type="select"
                label="VGM Issuer"
                name="vgm_issuer"
                className="!mt-4"
                defaultValue={null}
                transform={transformSelectField(vgmIssuersOptions)}
              >
                {({ fieldProps, error }) => (
                  <>
                    <Select
                      options={vgmIssuersOptions}
                      placeholder="Choose the issuer"
                      {...fieldProps}
                    />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>

              <WarningBannerField />

              <LoadingButton
                type="submit"
                className="mt-4 mb-10"
                shouldFitContainer
                appearance="primary"
                isLoading={isLoading}
              >
                {isEditing ? 'Edit' : 'Add'}
              </LoadingButton>
            </>
          )}
        </Form>
      </div>
    </Drawer>
  )
}

export default ShipmentViewContainerDrawer
