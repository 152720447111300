import { useMemo } from 'react'
import { Link } from 'react-router-dom'

// Contexts
import { useAuth } from 'contexts/AuthProvider'

// Atlassian
import PageHeader from '@atlaskit/page-header'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'

// Interfaces
import { Grid } from '@royan-co/user-interface'

// Components
import ViewDashboardStatistics from '../components/ViewDashboardStatistics'
import ViewDashboardShipments from '../components/ViewDashboardShipments'

const pageTitle = 'Dashboard'

const ViewDashboardPage = () => {
  const { currentUser } = useAuth()

  const accessToStatistics = useMemo(
    () => ['master', 'admin'].includes(currentUser?.user_level_name?.name),
    [currentUser]
  )

  return (
    <>
      <PageHeader
        breadcrumbs={
          <Breadcrumbs>
            <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
          </Breadcrumbs>
        }
      >
        {pageTitle}
      </PageHeader>

      {accessToStatistics ? (
        <Grid>
          <Grid.Col lg={6}>
            <ViewDashboardStatistics />
          </Grid.Col>

          <Grid.Col lg={6}>
            <ViewDashboardShipments />
          </Grid.Col>
        </Grid>
      ) : (
        <div className="dashboard">
          <h3>Welcome {currentUser.name}!</h3>
        </div>
      )}
    </>
  )
}

export default ViewDashboardPage
