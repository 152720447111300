import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

// Apis
import { stopFirstContactReminder } from 'api/shipments'

// Atlassian
import Spinner from '@atlaskit/spinner'
import { ButtonItem } from '@atlaskit/menu'

// Interfaces
import { useFlags } from '@royan-co/user-interface'

// Components
import DisabledElementTooltip from 'components/UI/DisabledElementTooltip'

const ShipmentFirstContactAction = ({ shipment }) => {
  const navigate = useNavigate()
  const { showSuccessFlag, showWarningFlag } = useFlags()

  const stopReminderMutation = useMutation(stopFirstContactReminder)

  const stopFirstContact = useMemo(() => !!(shipment?.status >= 1), [shipment])

  const accessabilityFirstContact = useMemo(() => {
    if (
      !stopFirstContact &&
      (!shipment?.shipper?.id_auto || shipment?.shipper_contact?.length < 1)
    ) {
      return {
        access: false,
        message: 'Shipper is not defined or has no contacts',
      }
    } else
      return {
        access: true,
      }
  }, [shipment, stopFirstContact])

  const handleFirstContact = () => {
    if (stopFirstContact) {
      stopReminderMutation.mutate(shipment?.id_auto, {
        onError: (e) => {
          showWarningFlag('Something went wrong.')
          console.error(e)
        },
        onSuccess: (res) => {
          showSuccessFlag(res.message)
        },
      })
    } else navigate(`/panel/shipments/${shipment?.id_auto}/first-contact`)
  }

  return (
    <DisabledElementTooltip
      isDisabled={!accessabilityFirstContact?.access}
      tooltipContent={accessabilityFirstContact?.message}
    >
      <ButtonItem
        onClick={handleFirstContact}
        isDisabled={!accessabilityFirstContact?.access}
        shouldTitleWrap
      >
        <div className="flex justify-between items-center">
          {stopFirstContact ? 'Stop first contact reminder' : 'First contact with shipper'}
          {stopReminderMutation.isLoading && <Spinner size="small" />}
        </div>
      </ButtonItem>
    </DisabledElementTooltip>
  )
}

export default ShipmentFirstContactAction
