const generateShipmentUserPermissions = (shipment, currentUser) => {
  const permissions = []

  const isOwner =
    currentUser?.registry_id === shipment?.creator?.registry_id &&
    currentUser?.registry_address_id === shipment?.creator?.registry_address_id

  if (isOwner) {
    permissions.push(
      'canDuplicate',
      'canDelete',
      'canCancel',
      'canShare',
      'canEdit',
      'canCreate.cargo',
      'canEdit.cargo',
      'canDelete.cargo',
      'canView.share',
      'canCreate.share',
      'canMailDocument.document',
      'canDelete.document',
      'canChangeVisibility.document',
      'canCreateMaster',
      'canEdit.houseShipments',
      'canView.finance',
      'canEdit.document',
      'canView.passiveInvoices',
      'canAccept',
      'canReject'
    )
  }

  const isWarehouseUser =
    currentUser?.user_level_name_id === '372d30dd2849813ef6748552539006795YTgCUrQQobv'
  const isSharedCosts = !!shipment?.shares?.[0]?.costs
  const isSharedDocument = !!shipment?.shares?.[0]?.document

  if (!isWarehouseUser) permissions.push('canRemindPayment')

  if (isSharedCosts && !isOwner) permissions.push('canView.finance', 'canView.passiveInvoices')
  if (
    isOwner &&
    (['master', 'admin'].includes(currentUser?.user_level_name?.name) ||
      currentUser?.id === shipment?.take_in_charge_operator)
  )
    permissions.push('canRelease', 'canHold')

  if (isSharedDocument && !isOwner)
    permissions.push(
      'canEdit.document',
      'canDelete.document',
      'canMailDocument.document',
      'canChangeVisibility.document'
    )

  return permissions
}

export { generateShipmentUserPermissions }
