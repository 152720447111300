import { useEffect, useState } from 'react'

//Interfaces
import { CrossIcon, SearchIcon, useDebounce } from '@royan-co/user-interface'

//Atlassian
import Button from '@atlaskit/button'
import Textfield from '@atlaskit/textfield'

const SearchInput = ({ onSubmit, delay = 350, isCompact = true, ...props }) => {
  const [value, setValue] = useState('')

  const debouncedValue = useDebounce(value, delay)

  useEffect(() => {
    onSubmit?.(debouncedValue)
  }, [debouncedValue, onSubmit])

  return (
    <Textfield
      {...props}
      value={value}
      isCompact={isCompact}
      placeholder="Search here"
      onChange={(e) => setValue(e.target.value)}
      elemAfterInput={
        <div className="flex items-center" style={{ marginRight: '0.5rem' }}>
          {value ? (
            <Button type="button" spacing="none" appearance="subtle" onClick={() => setValue('')}>
              <CrossIcon size={16} className="text-neutral-500" />
            </Button>
          ) : (
            <SearchIcon size={16} className="text-neutral-500" />
          )}
        </div>
      }
    />
  )
}

export default SearchInput
