import { useMemo } from 'react'
import { useMutation } from '@tanstack/react-query'

import { createShipmentNotice } from 'api/shipments'

// Utils
import { handleErrorOnSubmit } from 'utils/errors'
import { transformSelectField } from 'utils/helpers'

// Atlassian
import Button, { LoadingButton } from '@atlaskit/button'
import Select from '@atlaskit/select'
import Modal, {
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import { ErrorMessage } from '@atlaskit/form'
import TextArea from '@atlaskit/textarea'

// Interfaces
import { Field, Form, WarningBannerField } from '@royan-co/user-interface'

const notices = [
  {
    label: 'Departure notice',
    id: 1,
  },
  {
    label: 'Passage notice',
    id: 2,
  },
  {
    label: 'Shipment advice',
    id: 3,
  },
  {
    label: 'Arrival notice',
    id: 4,
  },
]

const ShipmentCreateNoticeModal = ({ isOpen, onClose, shipmentId }) => {
  const noticesListOptions = useMemo(
    () => notices?.map((n) => ({ label: n.label, value: n.id })),
    []
  )

  const shipmentNoticeMutation = useMutation((data) => createShipmentNotice(shipmentId, data))

  const handleCloseModal = () => {
    onClose()
    shipmentNoticeMutation.reset()
  }

  const handleShipmentNotice = (data, { setError }) => {
    shipmentNoticeMutation.mutate(data, {
      onError: (e) => {
        handleErrorOnSubmit(e, setError, data)
        console.error(e)
      },
      onSuccess: (res) => {
        const file = new Blob([res], { type: 'application/pdf' })
        const fileURL = URL.createObjectURL(file)

        handleCloseModal()
        return window.open(fileURL)
      },
    })
  }

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={handleCloseModal} width="medium">
          <ModalHeader>
            <ModalTitle>Shipment notice</ModalTitle>
          </ModalHeader>

          <Form onSubmit={handleShipmentNotice}>
            {() => (
              <>
                <div className="px-6">
                  <Field
                    isRequired
                    label="Notice"
                    name="type"
                    transform={transformSelectField(noticesListOptions)}
                    className="!mt-0"
                  >
                    {({ fieldProps, error }) => (
                      <>
                        <Select options={noticesListOptions} {...fieldProps} />
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                      </>
                    )}
                  </Field>

                  <Field name="note" label="Additional notes">
                    {({ fieldProps, error }) => (
                      <>
                        <TextArea
                          placeholder="Type your content here"
                          {...fieldProps}
                          minimumRows={7}
                        />
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                      </>
                    )}
                  </Field>

                  <WarningBannerField />
                </div>

                <ModalFooter>
                  <Button type="button" appearance="subtle" onClick={handleCloseModal}>
                    Discard
                  </Button>
                  <LoadingButton
                    type="submit"
                    appearance="primary"
                    isLoading={shipmentNoticeMutation.isLoading}
                  >
                    Generate
                  </LoadingButton>
                </ModalFooter>
              </>
            )}
          </Form>
        </Modal>
      )}
    </ModalTransition>
  )
}

export default ShipmentCreateNoticeModal
