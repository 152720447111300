import Button from '@atlaskit/button'
import Modal, {
  ModalTitle,
  ModalHeader,
  ModalFooter,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import { Link } from 'react-router-dom'

function ShipmentCreationSelectTypeModal({ isOpen, onClose }) {
  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={onClose} width="small">
          <ModalHeader>
            <ModalTitle>New Shipment</ModalTitle>
          </ModalHeader>

          <div className="px-6 grid grid-cols-2 gap-4">
            <Link
              to="/panel/shipments/create?type=house"
              className="shadow-md rounded p-4 text-center hover:shadow-lg hover:bg-neutral-10 transition-all"
            >
              <div className="text-base text-blue-400">House</div>
              <div className="text-xs text-neutral-100 mt-1">shipment</div>
            </Link>

            <Link
              to="/panel/shipments/create-master"
              className="shadow-md rounded p-4 text-center hover:shadow-lg hover:bg-neutral-10 transition-all"
            >
              <div className="text-base text-blue-400">Master</div>
              <div className="text-xs text-neutral-100 mt-1">shipment</div>
            </Link>
          </div>

          <ModalFooter>
            <Button appearance="subtle" spacing="compact" type="button" onClick={onClose}>
              Discard
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  )
}

export default ShipmentCreationSelectTypeModal
