import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

import { duplicateRate } from 'api/rates'

//Atlassian
import { DropdownItem } from '@atlaskit/dropdown-menu'
import Spinner from '@atlaskit/spinner'

//Interfaces
import { useFlags, useRoyanTable } from '@royan-co/user-interface'

const RatesDuplicateRate = ({ rateId }) => {
  const navigate = useNavigate()
  const { refresh } = useRoyanTable()
  const { showSuccessFlag, showWarningFlag } = useFlags()

  const rateDuplicateMutation = useMutation(duplicateRate)

  const handleDuplicateRate = () => {
    rateDuplicateMutation.mutate(rateId, {
      onError: (e) => {
        console.error(e)
        showWarningFlag('Something wrong on duplicate rate.')
      },
      onSuccess: (res) => {
        if (refresh) {
          refresh()
        } else {
          navigate('/panel/rates')
        }
        showSuccessFlag(res?.message)
      },
    })
  }

  return (
    <DropdownItem
      isDisabled={rateDuplicateMutation?.isLoading}
      onClick={(e) => {
        e.stopPropagation()
        handleDuplicateRate()
      }}
    >
      Duplicate
      {rateDuplicateMutation?.isLoading && (
        <span className="ml-2">
          <Spinner size="small" />
        </span>
      )}
    </DropdownItem>
  )
}

export default RatesDuplicateRate
