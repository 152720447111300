import axios from 'services/Request'

export function getMarginCalculators(params) {
  return axios.get('/api/margin-calculator', { params })
}

export function createMarginCalculator(data) {
  return axios.post('/api/margin-calculator', data)
}

export function editMarginCalculator(marginId, data) {
  return axios.put(`/api/margin-calculator/${marginId}`, data)
}

export function deleteMarginCalculator(marginId) {
  return axios.delete(`/api/margin-calculator/${marginId}`)
}

export function getMarginCalculator(marginId) {
  return axios.get(`/api/margin-calculator/${marginId}`)
}

export function getMarginCalculatorBrackets(marginId) {
  return axios.get(`/api/margin-calculator/${marginId}/brackets`)
}

export function createMarginCalculatorBracket(marginId, data) {
  return axios.post(`/api/margin-calculator/${marginId}/brackets`, data)
}

export function editMarginCalculatorBracket(marginId, bracketId, data) {
  return axios.put(`/api/margin-calculator/${marginId}/brackets/${bracketId}`, data)
}

export function deleteMarginCalculatorBracket(marginId, bracketId) {
  return axios.delete(`/api/margin-calculator/${marginId}/brackets/${bracketId}`)
}
