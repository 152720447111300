import { saveAs } from 'file-saver'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

//Services
import { getShipmentEntranceLabel } from 'api/shipments'

//Icons
import { DotsIcon } from '@royan-co/user-interface'

//Atlassian
import Button from '@atlaskit/button'
import Spinner from '@atlaskit/spinner'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'

//Interfaces
import { useFlags } from '@royan-co/user-interface'

const EntranceActions = ({ entrance }) => {
  const navigate = useNavigate()
  const { entranceId } = useParams()
  const { showWarningFlag } = useFlags()
  const [isOpenActions, setIsOpenActions] = useState(false)

  const downloadLabelMutation = useMutation(getShipmentEntranceLabel, {
    onSuccess: (res) => {
      setIsOpenActions(false)
      saveAs(new Blob([res]), `shipment-entrance-${entrance.id_auto}-label.pdf`)
    },
    onError: (e) => {
      showWarningFlag('Something wrong on download entrance label, please retry')
    },
  })

  const viewEntrance = () => {
    navigate(`/panel/shipments/entrances/${entrance.id_auto}`)
  }

  return (
    <DropdownMenu
      placement="bottom-end"
      isOpen={isOpenActions}
      trigger={({ triggerRef, ...props }) => (
        <Button
          ref={triggerRef}
          className="self-center"
          iconBefore={<DotsIcon />}
          spacing={entranceId ? 'default' : 'compact'}
          appearance={entranceId ? 'default' : 'subtle'}
          {...props}
        />
      )}
      onOpenChange={(attrs) => setIsOpenActions(attrs.isOpen)}
    >
      <DropdownItemGroup>
        <div style={{ minWidth: '150px' }}>
          {!entranceId ? <DropdownItem onClick={viewEntrance}>View</DropdownItem> : null}

          <DropdownItem
            isDisabled={downloadLabelMutation.isLoading}
            onClick={(e) => {
              e.stopPropagation()
              downloadLabelMutation.mutate(entrance.id_auto)
            }}
          >
            {downloadLabelMutation.isLoading ? (
              <div className="text-center">
                <Spinner size="small" />
              </div>
            ) : (
              'Download label'
            )}
          </DropdownItem>

          {!entranceId ? (
            <DropdownItem isDisabled onClick={() => {}}>
              Download entrance
            </DropdownItem>
          ) : null}

          <DropdownItem isDisabled onClick={() => {}}>
            Edit
          </DropdownItem>
        </div>
      </DropdownItemGroup>
    </DropdownMenu>
  )
}

export default EntranceActions
