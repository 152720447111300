import React from 'react'

//Atlassian
import Modal, {
  ModalBody,
  ModalTitle,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import Button from '@atlaskit/button'

const ShipmentDeformedCargoModal = ({ isOpen, onClose, images }) => {
  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={onClose} width={'small'}>
          <ModalHeader>
            <ModalTitle>Deformity images</ModalTitle>
          </ModalHeader>

          <ModalBody>
            <div className="grid grid-cols-2 gap-4">
              {images.map((image, index) => (
                <a
                  key={index}
                  target="_blank"
                  href={image.url}
                  rel="noreferrer"
                  className="relative bg-neutral-20 w-full h-full rounded"
                >
                  <div className="block pb-[100%]" />
                  <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full">
                    <img src={image.url} alt="" className="object-contain max-w-full max-h-full" />
                  </div>
                </a>
              ))}
            </div>
          </ModalBody>

          <ModalFooter>
            <Button appearance="subtle" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  )
}

export default ShipmentDeformedCargoModal
