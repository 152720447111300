import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'

// Hooks
import { getFinanceDetails } from 'api/shipments'
import { getDefaultsForCreatingInvoice } from './../api/invoice-requests'

// Atlassian
import Button from '@atlaskit/button'
import EmptyState from '@atlaskit/empty-state'
import Spinner from '@atlaskit/spinner'
import SectionMessage from '@atlaskit/section-message'

// Interfaces
import { useFormContext } from '@royan-co/user-interface'

// Components
import ShipmentReferenceField from 'components/Shipments/View/ReferenceField'
import LinkInvoiceTableFields from './LinkInvoiceTableFields'

const LinkInvoiceToShipmentForm = ({ isCredit, isCumulative }) => {
  const { watch, setValue } = useFormContext()

  const shipmentId = watch('shipment_id')

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ['finances', shipmentId],
    queryFn: () =>
      isCumulative
        ? getDefaultsForCreatingInvoice({
          resource: 'shipments',
          id: shipmentId,
        })
        : getFinanceDetails(shipmentId),
    enabled: !!shipmentId,
  })

  useEffect(() => {
    if (data?.rows) {
      setValue(
        'rows',
        data.rows.flatMap((row) => {
          const surchargesTotal = row?.surcharges?.reduce((acc, surcharge) => acc + parseFloat(surcharge.cost || 0), 0) || 0;
          const realCost = parseFloat(row.cost || 0) + surchargesTotal;
          const _row = { ...row, cost: realCost.toFixed(2), real_cost: realCost.toFixed(2) };
          if (isCumulative) return _row
          return isCredit && row.passive_invoices?.length
            ? _row
            : !isCredit && row.passive_invoices === null
              ? _row
              : []
        })
      )
    }

    if (!shipmentId) setValue('rows', [])
  }, [data, setValue, shipmentId, isCredit, isCumulative])

  return (
    <div className="px-6">
      <div style={{ maxWidth: '350px' }}>
        <ShipmentReferenceField name="shipment_id" label="Shipment reference" className="!mt-0" />
      </div>

      {isLoading && shipmentId ? (
        <div className="text-center mt-6">
          <Spinner />
        </div>
      ) : isError ? (
        <SectionMessage appearance="warning" title="Something wrong on loading data, please retry.">
          <Button onClick={() => refetch()}>Retry</Button>
        </SectionMessage>
      ) : data?.rows?.length === 0 && shipmentId ? (
        <EmptyState header="No Rows" />
      ) : shipmentId ? (
        <LinkInvoiceTableFields isCumulative={isCumulative} />
      ) : null}
    </div>
  )
}

export default LinkInvoiceToShipmentForm
