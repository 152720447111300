const NotificationBadge = () => {
  return (
    <span
      style={{
        width: '7px',
        height: '7px',
        borderRadius: '50%',
        backgroundColor: 'red',
        position: 'absolute',
        top: '2px',
        right: '5px',
      }}
    />
  )
}

export default NotificationBadge
