import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

// Utils
import { invoiceTypes } from 'features/invoice/utils/invoice-types'

// Contexts
import { useAuth } from 'contexts/AuthProvider'

// Atlassian
import { DropdownItem } from '@atlaskit/dropdown-menu'

// Components
import DisabledElementTooltip from 'components/UI/DisabledElementTooltip'

const EditInvoiceDropdownItem = ({ invoice }) => {
  const navigate = useNavigate()
  const { currentUser } = useAuth()
  const _type = useMemo(() => {
    return invoiceTypes.find(
      (type) =>
        type.invoiceKeys.active_passive === invoice?.active_passive &&
        type.invoiceKeys.buffer === invoice?.buffer
    )
  }, [invoice])

  const accessabilityEdit = useMemo(
    () =>
      _type?.name === 'active' &&
      !invoice?.is_proforma &&
      !['master', 'admin', 'accounting'].includes(currentUser?.user_level_name?.name)
        ? {
            access: false,
            message: 'Invoice is not PROFORMA.',
          }
        : _type?.name === 'passive' && invoice?.elaborated
        ? {
            access: false,
            message: 'Invoice is elaborated.',
          }
        : {
            access: true,
          },

    [_type, invoice, currentUser]
  )

  return invoice?.id_auto ? (
    <DisabledElementTooltip
      tooltipContent={accessabilityEdit?.message}
      isDisabled={!accessabilityEdit?.access}
    >
      <DropdownItem
        onClick={() => navigate(`${_type.pathList}/${invoice.id_auto}/edit`)}
        children="Edit"
        isDisabled={!accessabilityEdit?.access}
      ></DropdownItem>
    </DisabledElementTooltip>
  ) : null
}

export default EditInvoiceDropdownItem
