import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'

// Hooks
import { useShipmentStatistics } from 'features/dashboard/api/useShipmentStatistics'
import { useExportShipments } from 'features/dashboard/api/useExportShipments'

// Atlassian
import Modal, {
  ModalTitle,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import Lozenge from '@atlaskit/lozenge'
import Button from '@atlaskit/button'

// Interfaces
import { ShortcutIcon } from '@royan-co/user-interface'

// Components
import ShipmentTypeTag from 'components/Shipments/TypeTag'
import ShipmentStatusTag from 'components/Shipments/StatusTag'
import ShipmentFinancialTag from 'components/Shipments/FinancialTag'
import LocalControlTable from './LocalControlTable'
import RemoteControlTable from './RemoteControlTable'

const tableHead = {
  cells: [
    { content: 'Reference' },
    { content: 'Shipment type' },
    { content: 'Status' },
    { content: 'Client' },
    { content: 'Operator' },
    { content: 'Financial status' },
  ],
}

const ShipmentListModal = ({ isOpen, onClose, type, params }) => {
  const createRow = useCallback(
    (item, highlightRow) => [
      {
        content: (
          <span className="whitespace-nowrap">
            {item?.reference ? (
              <Link
                to={`/panel/shipments/${item.id_auto}`}
                target="_blank"
                onClick={() => highlightRow(item)}
              >
                {item.reference} <ShortcutIcon size={16} />
              </Link>
            ) : null}
          </span>
        ),
      },
      {
        content: <ShipmentTypeTag type={item.is_master} />,
      },
      {
        content: <ShipmentStatusTag value={item.status} entity={'shipment'} />,
      },
      {
        content: item?.client?.id_auto ? (
          <Link to={`/panel/registries/${item.client.id_auto}`} target="_blank">
            {item.client.company_name}
          </Link>
        ) : (
          <Lozenge>Not set</Lozenge>
        ),
      },
      {
        content: item.operator?.full_name || <Lozenge>Not set</Lozenge>,
      },
      {
        content: (
          <ShipmentFinancialTag
            cost={item.financial_status.cost}
            quotation={item.financial_status.quotation}
            margin={item.financial_status.margin}
          />
        ),
      },
    ],
    []
  )

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={onClose} width="large" shouldScrollInViewport>
          <ModalHeader>
            <ModalTitle>Shipments</ModalTitle>
          </ModalHeader>

          <div className="px-6">
            {type === 'in-loss' ? (
              <LocalControlTable
                key={type}
                type={type}
                params={params}
                searchKeys={['reference', 'client.company_name']}
                exportParams={params}
                emptyState="No shipments"
                tableHead={tableHead}
                onCreateRow={createRow}
                query={useShipmentStatistics}
                exportMutation={useExportShipments}
              />
            ) : (
              <RemoteControlTable
                key={type}
                type={type}
                params={params}
                exportParams={params}
                emptyState="No shipments"
                tableHead={tableHead}
                onCreateRow={createRow}
                query={useShipmentStatistics}
                exportMutation={useExportShipments}
              />
            )}
          </div>

          <ModalFooter>
            <Button appearance="subtle" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  )
}

export default ShipmentListModal
