import React from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'

// Apis
import { deleteContainer } from 'api/shipments'

// Hooks
import { useUpdateContainers } from 'hooks/useShipmentContainer'

// Atlassian
import Modal, {
  ModalBody,
  ModalTitle,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import Button, { LoadingButton } from '@atlaskit/button'

// Interfaces
import { WarningBanner, useFlags } from '@royan-co/user-interface'

const ShipmentDeleteContainerModal = ({ isOpen, onClose, containerId, shipmentId }) => {
  const { showSuccessFlag } = useFlags()
  const queryClient = useQueryClient()
  const { deleteContainerFromData } = useUpdateContainers(shipmentId)

  const deleteContainerMutation = useMutation(deleteContainer)

  const onDeleteContainer = () => {
    deleteContainerMutation.mutate(containerId, {
      onSuccess: (res) => {
        deleteContainerFromData(containerId)
        showSuccessFlag(res?.message)
        queryClient.removeQueries({
          queryKey: ['shipment', 'cargo', shipmentId],
        })
        onClose()
      },
    })
  }

  const onCloseModal = () => {
    onClose()
    deleteContainerMutation.reset()
  }

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={onCloseModal}>
          <ModalHeader>
            <ModalTitle appearance="danger">Delete container</ModalTitle>
          </ModalHeader>
          <ModalBody>
            You are about to delete a container. Are you sure?
            {deleteContainerMutation.isError && <WarningBanner />}
          </ModalBody>
          <ModalFooter>
            <Button appearance="subtle" onClick={onCloseModal}>
              Cancel
            </Button>
            <LoadingButton
              isLoading={deleteContainerMutation.isLoading}
              appearance="danger"
              onClick={onDeleteContainer}
              autoFocus
            >
              Delete
            </LoadingButton>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  )
}

export default ShipmentDeleteContainerModal
