import React, { useMemo } from 'react'
import { useMutation } from '@tanstack/react-query'

// Api
import { deactivateRegistry } from 'api/registries'

// Hooks
import useRegistry from 'hooks/useRegistry'

// Utils
import { capitalizeFirstLetter } from 'utils/helpers'

// Atlassian
import Modal, {
  ModalBody,
  ModalTitle,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import Button, { LoadingButton } from '@atlaskit/button'

// Interfaces
import { useFlags, useRoyanTable, WarningBanner } from '@royan-co/user-interface'

const RegistryDeactivateModal = ({ isOpen, onClose, registryId, deactivated }) => {
  const { updateData } = useRoyanTable()
  const { showSuccessFlag } = useFlags()
  const status = useMemo(() => (deactivated ? 'activate' : 'deactivate'), [deactivated])

  const { changeDeactivated } = useRegistry(String(registryId), { enabled: isOpen && !updateData })
  const registryDeactivateMutation = useMutation(deactivateRegistry)

  const closeModal = () => {
    onClose()
    registryDeactivateMutation.reset()
  }

  const handleDeactivateRegistry = () => {
    registryDeactivateMutation.mutate(registryId, {
      onSuccess: (res) => {
        showSuccessFlag(res.message)
        if (updateData) {
          updateData(null, (data) =>
            data.map((item) =>
              item.registry.id_auto === registryId ? { ...item, registry: res.registry } : item
            )
          )
        } else changeDeactivated(!deactivated)

        closeModal()
      },
      onError: (error) => {
        console.error(error)
      },
    })
  }
  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={closeModal}>
          <ModalHeader>
            <ModalTitle appearance="warning">{capitalizeFirstLetter(status)} registry</ModalTitle>
          </ModalHeader>
          <ModalBody>
            You are about to {status} a registry. Are you sure?
            {registryDeactivateMutation.isError && <WarningBanner />}
          </ModalBody>
          <ModalFooter>
            <Button appearance="subtle" onClick={closeModal}>
              Cancel
            </Button>
            <LoadingButton
              isLoading={registryDeactivateMutation.isLoading}
              appearance="warning"
              onClick={handleDeactivateRegistry}
              autoFocus
            >
              {capitalizeFirstLetter(status)}
            </LoadingButton>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  )
}

export default RegistryDeactivateModal
