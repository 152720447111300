import { useMemo } from 'react'

// Atlassian
import Button from '@atlaskit/button'
import { DynamicTableStateless } from '@atlaskit/dynamic-table'

// Interfaces
import { useFieldArray, useHistoryModal } from '@royan-co/user-interface'

// Icons
import { EditIcon, TrashIcon } from '@royan-co/user-interface'

// Helpers
import { formatNumber, formatMonetaryNumber } from 'utils/numbers'

// Components
import Card from 'components/UI/Card'
import CommercialInvoiceCreateEditRowDrawer from './RowDrawer'

const tableHead = {
  cells: [
    { content: 'HS code' },
    { content: 'Number of collies' },
    { content: 'Goods value' },
    { content: 'Gross weight' },
    { content: 'Net weight' },
    { content: '' },
  ],
}

const CommercialInvoiceRowsSection = () => {
  const {
    modalData: RowsDrawerData,
    openModel: openRowsDrawer,
    closeModel: closeRowsDrawer,
    isOpenModel: isOpenRowsDrawer,
  } = useHistoryModal('rows')

  const {
    fields: rows,
    append: appendRow,
    remove: removeRow,
    update: updateRow,
  } = useFieldArray({ name: 'rows', shouldUnregister: false, keyName: '_id' })

  const tableRows = useMemo(() => {
    return rows.map((row, i) => {
      let items = [
        {
          content: row?.type_of_goods,
        },
        {
          content: formatNumber(row?.number_collies),
        },
        {
          content: formatMonetaryNumber(row?.single_amount),
        },
        {
          content: formatNumber(row?.gross_weight),
        },
        {
          content: formatNumber(row?.weight),
        },
        {
          content: (
            <div className="flex justify-end">
              <Button
                spacing="compact"
                appearance="subtle"
                iconBefore={<EditIcon />}
                onClick={openRowsDrawer.bind(null, { ...row, index: i })}
              />
              <Button
                spacing="compact"
                appearance="subtle"
                onClick={() => removeRow(i)}
                iconBefore={<TrashIcon className="text-red-400" />}
              />
            </div>
          ),
        },
      ]

      return {
        key: i,
        cells: items,
      }
    })
  }, [rows, openRowsDrawer, removeRow])

  const onSubmit = (data) => {
    data?.index !== undefined ? updateRow(data.index, data) : appendRow(data)

    closeRowsDrawer()
  }

  return (
    <>
      <div className="mt-6">
        <div className="flex items-center">
          <h3>HS codes</h3>

          <Button appearance="link" className="ml-auto" onClick={() => openRowsDrawer()}>
            New row
          </Button>
        </div>

        <Card className="mt-2">
          {tableRows.length === 0 ? (
            <div className="text-xs text-neutral-200">Click on New row to create one.</div>
          ) : (
            <div className="inline-block w-full -mb-6">
              <DynamicTableStateless head={tableHead} rows={tableRows} />
            </div>
          )}
        </Card>
      </div>

      <CommercialInvoiceCreateEditRowDrawer
        isOpen={isOpenRowsDrawer}
        onClose={closeRowsDrawer}
        onSubmit={onSubmit}
        data={RowsDrawerData}
      />
    </>
  )
}

export default CommercialInvoiceRowsSection
