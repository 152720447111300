import { useEffect } from 'react'

// Utils
import { findOption } from 'utils/helpers'

// Hooks
import useShipmentsHubs from 'hooks/useShipmentsHubs'

// Contexts
import { useAuth } from 'contexts/AuthProvider'

// Atlassian
import Select from '@atlaskit/select'
import Textfield from '@atlaskit/textfield'
import Toggle from '@atlaskit/toggle'
import TextArea from '@atlaskit/textarea'
import { DatePicker } from '@atlaskit/datetime-picker'
import { ErrorMessage } from '@atlaskit/form'

// Interfaces
import { Field, useFormContext } from '@royan-co/user-interface'

// Components
import Card from 'components/UI/Card'

const ShipmentPickupFormRouting = ({ toggleHubLabel }) => {
  const { currentUser } = useAuth()
  const { watch, setValue } = useFormContext()

  const PickupHub = watch('pickup_hub')
  const hubConsolidator = watch('hub_consolidator')
  const isDelivery = watch('type') === 'delivery'
  const pickupAddress = isDelivery ? 'pick_up_address' : 'delivery_address'

  const { shipmentsHubsOptions, isLoadingShipmentsHubs, getHubAddress } = useShipmentsHubs({
    enabled: !!PickupHub,
  })

  useEffect(() => {
    if (hubConsolidator)
      setValue(
        pickupAddress,
        `${currentUser.registry.company_name}\n${getHubAddress(hubConsolidator)}`
      )
  }, [setValue, hubConsolidator, getHubAddress, pickupAddress, currentUser])

  return (
    <Card className="mt-6" title="Routing">
      <Field
        name="pickup_hub"
        label={
          toggleHubLabel ? toggleHubLabel : isDelivery ? 'Delivery from hub' : 'Precarriage to hub'
        }
        type="checkbox"
        className="!mt-0"
        onValueChange={() => {
          if (PickupHub) {
            setValue(pickupAddress, '')
            setValue('hub_consolidator', null)
          }
        }}
      >
        {({ fieldProps, error }) => (
          <>
            <div>
              <Toggle {...fieldProps} id="toggle-allow_calculate" />
            </div>
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </>
        )}
      </Field>

      {!!PickupHub && (
        <>
          <Field
            type="select"
            label="Consolidation hub"
            name="hub_consolidator"
            transform={{
              input: (v) => findOption(shipmentsHubsOptions, v),
              output: (opt) => {
                if (!opt) setValue(pickupAddress, '')
                return opt?.value !== undefined ? opt.value : null
              },
            }}
          >
            {({ fieldProps }) => (
              <Select
                isClearable={true}
                placeholder="Choose"
                options={shipmentsHubsOptions}
                isLoading={isLoadingShipmentsHubs}
                {...fieldProps}
              />
            )}
          </Field>
        </>
      )}

      <div className="grid md:grid-cols-2 md:gap-4">
        <Field isRequired name="pick_up_address" label="Pickup address">
          {({ fieldProps, error }) => (
            <>
              <TextArea placeholder="Type your content here" {...fieldProps} minimumRows={7} />
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </>
          )}
        </Field>

        <Field isRequired name="delivery_address" label="Delivery address">
          {({ fieldProps, error }) => (
            <>
              <TextArea placeholder="Type your content here" {...fieldProps} minimumRows={7} />
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </>
          )}
        </Field>
      </div>

      <div className="grid md:grid-cols-2 md:gap-4">
        <Field isRequired={!isDelivery} label="Pickup date" name="loading_date">
          {({ fieldProps, error }) => (
            <>
              <DatePicker placeholder=" " {...fieldProps} />
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </>
          )}
        </Field>

        <Field isRequired={isDelivery} label="Delivery date" name="delivery_date">
          {({ fieldProps, error }) => (
            <>
              <DatePicker placeholder=" " {...fieldProps} />
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </>
          )}
        </Field>
      </div>

      <div className="grid md:grid-cols-2 md:gap-4">
        <Field
          isRequired={!isDelivery}
          label="Opening hours for pickup"
          name="opening_hours_collection"
        >
          {({ fieldProps, error }) => (
            <>
              <Textfield placeholder="09.00 - 13.00 | 14.00 - 18.00" {...fieldProps} />
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </>
          )}
        </Field>

        <Field
          isRequired={!isDelivery}
          label="Opening hours for delivery"
          name="opening_hours_delivery"
        >
          {({ fieldProps, error }) => (
            <>
              <Textfield placeholder="09.00 - 13.00 | 14.00 - 18.00" {...fieldProps} />
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </>
          )}
        </Field>
      </div>
    </Card>
  )
}

export default ShipmentPickupFormRouting
