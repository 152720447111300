import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getConsolidationShares } from 'api/consolidations'

const useSharesConsolidation = (consolidationId) => {
  const queryClient = useQueryClient()

  const {
    data: sharesList = [],
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ['consolidation', 'shares', consolidationId],
    queryFn: () => getConsolidationShares(consolidationId),
    enabled: !!consolidationId,
  })

  const invalidate = () => {
    queryClient.invalidateQueries({ queryKey: ['consolidation', 'shares', consolidationId] })
  }

  const deleteSharingFromData = (sharing) => {
    queryClient.setQueryData(['consolidation', 'shares', consolidationId], (sharesList) =>
      sharesList.filter((s) => {
        return (
          sharing?.shared_to?.id_auto !== s?.shared_to?.id_auto &&
          sharing?.shared_to_address?.id_auto !== s?.shared_to_address?.id_auto
        )
      })
    )
  }

  const upsertSharingToData = (sharing) => {
    queryClient.setQueryData(['consolidation', 'shares', consolidationId], (sharesList) => [
      ...sharesList,
      sharing,
    ])
  }

  return {
    isError,
    refetch,
    isLoading,
    sharesList,
    invalidate,
    upsertSharingToData,
    deleteSharingFromData,
  }
}

export default useSharesConsolidation
