import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

// Utils
import { transformSelectField } from 'utils/helpers'
import { euCountries } from 'utils/CountryCodes'

//Contexts
import { useApp } from 'contexts/AppProvider.js'

// Hooks
import useShipmentsEnumerations from 'hooks/useShipmentsEnumerations'
import useRegistryAddress from 'hooks/useRegistryAddress'
import usePaymentBanks from 'hooks/usePaymentBanks'
import usePaymentConditions from 'hooks/usePaymentConditions'
import useInvoiceEnumerations from 'hooks/useInvoiceEnumerations'

// Atlassian
import Select from '@atlaskit/select'
import TextArea from '@atlaskit/textarea'
import Textfield from '@atlaskit/textfield'
import { ErrorMessage } from '@atlaskit/form'
import { DatePicker } from '@atlaskit/datetime-picker'

// Interfaces
import { Field, useFormContext } from '@royan-co/user-interface'

// Components
import Card from 'components/UI/Card'
import Divider from 'components/UI/Divider'
import RegistrySelect from 'components/Registries/RegistrySelect'

const CreateInvoiceFormGeneral = ({ isCreditNote, isEditableNumber }) => {
  const { invoiceId } = useParams()
  const { setLoadingOverlay } = useApp()
  const { getValues, watch, setValue } = useFormContext()
  const { getEnumerationOptionsByKey, isLoadingShipmentEnumeration } = useShipmentsEnumerations()

  const client = watch('client')?.value
  const clientAddress = watch('client_address_id')

  const { registryAddressOptions, generalAddress, getAddressCountryIso, isLoadingRegistryAddress } =
    useRegistryAddress(client)
  const { paymentBankOptions, isLoadingPaymentBanks } = usePaymentBanks()
  const { paymentConditionOptions, isLoadingPaymentConditions } = usePaymentConditions()
  const { docCodesOptions, isLoadingInvoiceEnumerations } = useInvoiceEnumerations()

  useEffect(() => {
    if (!client || !clientAddress) setValue('client_address_id', null)

    if (!!clientAddress) {
      const countryIso = getAddressCountryIso(clientAddress)
      countryIso === 'IT'
        ? setValue('document_type', isCreditNote ? 'NCI' : 'FTI')
        : euCountries.includes(countryIso)
        ? setValue('document_type', isCreditNote ? 'NCC' : 'FTC')
        : setValue('document_type', isCreditNote ? 'NCE' : 'FTE')
    }
  }, [client, setValue, clientAddress, getAddressCountryIso, isCreditNote])

  useEffect(() => {
    setValue('client_address_id', generalAddress)
  }, [generalAddress, setValue])

  return (
    <Card className="mt-0">
      {isEditableNumber && (
        <Field label="Invoice number" name="invoice_number" className="!mt-0">
          {({ fieldProps, error }) => (
            <>
              <Textfield placeholder="Type the invoice number" {...fieldProps} />
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </>
          )}
        </Field>
      )}

      {!!invoiceId && (
        <Field label="Invoice date" name="invoice_date">
          {({ fieldProps, error }) => (
            <>
              <DatePicker placeholder=" " {...fieldProps} />
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </>
          )}
        </Field>
      )}

      <div className="grid md:grid-cols-2 md:gap-4">
        <Field
          type="select"
          name="document_type"
          label="Document type"
          isLoading={isLoadingInvoiceEnumerations}
          transform={transformSelectField(docCodesOptions)}
        >
          {({ fieldProps, error }) => (
            <>
              <Select isDisabled options={docCodesOptions} {...fieldProps} />
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </>
          )}
        </Field>

        <Field
          type="select"
          name="currency"
          label="Currency"
          transform={transformSelectField(getEnumerationOptionsByKey('currencies'))}
          isRequired
        >
          {({ fieldProps, error }) => (
            <>
              <Select
                menuPlacement="auto"
                placeholder="Choose a currency"
                isLoading={isLoadingShipmentEnumeration}
                options={getEnumerationOptionsByKey('currencies')}
                {...fieldProps}
              />
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </>
          )}
        </Field>
      </div>

      <Divider className="mb-4 mt-8" />

      <Field
        type="select"
        name="client"
        label="Client"
        onValueChange={() => {
          if (!invoiceId) setLoadingOverlay(true)
        }}
      >
        {({ fieldProps, error }) => (
          <>
            <RegistrySelect
              {...fieldProps}
              defaultData={getValues('client')}
              registryType="client"
              isClearable={false}
              isImported
              isActive
            />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </>
        )}
      </Field>

      {client && (
        <Field
          type="select"
          className="!mt-4"
          name="client_address_id"
          label="Client address"
          transform={transformSelectField(registryAddressOptions)}
        >
          {({ fieldProps, error }) => (
            <>
              <Select
                isClearable
                placeholder="Choose"
                options={registryAddressOptions}
                isLoading={isLoadingRegistryAddress}
                {...fieldProps}
              />

              {error && <ErrorMessage>{error}</ErrorMessage>}
            </>
          )}
        </Field>
      )}

      <div className="grid md:grid-cols-2 md:gap-4">
        <Field
          type="select"
          name="payment_condition"
          label="Terms of payment"
          transform={transformSelectField(paymentConditionOptions)}
        >
          {({ fieldProps, error }) => (
            <>
              <Select
                isClearable
                placeholder="Choose"
                options={paymentConditionOptions}
                isLoading={isLoadingPaymentConditions}
                {...fieldProps}
              />
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </>
          )}
        </Field>

        <Field
          type="select"
          label="Banks "
          name="bank"
          transform={transformSelectField(paymentBankOptions)}
        >
          {({ fieldProps, error }) => (
            <>
              <Select
                isClearable
                placeholder="Choose"
                options={paymentBankOptions}
                isLoading={isLoadingPaymentBanks}
                {...fieldProps}
              />
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </>
          )}
        </Field>
      </div>

      <Divider className="mb-4 mt-8" />

      <Field name="notes" label="Notes">
        {({ fieldProps, error }) => (
          <>
            <TextArea placeholder="Type your content here" {...fieldProps} minimumRows={7} />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </>
        )}
      </Field>
    </Card>
  )
}

export default CreateInvoiceFormGeneral
