import { useQuery } from '@tanstack/react-query'

// Apis
import { getVehicleType } from './vehicle-type-requests'

/**
 * get vehicle type detail
 * @param {String|Number} typeId
 * @param {import('@tanstack/react-query').UseQueryOptions} options
 * @returns {import('@tanstack/react-query').UseQueryResult}
 */

const useVehicleType = (typeId, options = {}) => {
  return useQuery({
    queryKey: ['vehicle-type', typeId],
    queryFn: () => getVehicleType(typeId),
    staleTime: Infinity,
    retry: 2,
    ...options,
  })
}

export { useVehicleType }
