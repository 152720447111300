import { Link } from 'react-router-dom'
import logoUrl from 'assets/images/logo.png'

//Icons
import { CrossIcon, MenuIcon, useIsMobile } from '@royan-co/user-interface'

//Atlassian
import Button from '@atlaskit/button'
import {
  PrimaryButton,
  CustomProductHome,
  AtlassianNavigation,
} from '@atlaskit/atlassian-navigation'

//Components
import ProfilePopup from './ProfilePopup'
import DownloadDropDown from './DownloadDropDown'

const TopNavigation = ({ isOpenMobileSidebar, onClickMobileSidebarTrigger }) => {
  const isMobile = useIsMobile()

  return (
    <AtlassianNavigation
      label="Expesoft"
      renderAppSwitcher={() =>
        isMobile && (
          <Button
            className="mr-4"
            appearance="subtle"
            iconBefore={isOpenMobileSidebar ? <CrossIcon /> : <MenuIcon />}
            onClick={onClickMobileSidebarTrigger}
          />
        )
      }
      renderProfile={ProfilePopup}
      renderSearch={DownloadDropDown}
      renderProductHome={() => (
        <CustomProductHome href="/" logoUrl={logoUrl} iconUrl={logoUrl} logoMaxWidth={102} />
      )}
      primaryItems={[
        <PrimaryButton to="/panel" component={Link}>
          Dashboard
        </PrimaryButton>,
      ]}
    />
  )
}

export default TopNavigation
