import { Link, Navigate, useNavigate } from 'react-router-dom'

//Contexts
import { useConsolidationCreationContext } from 'contexts/ConsolidationCreationProvider'

//Atlassian
import Button from '@atlaskit/button'
import Toggle from '@atlaskit/toggle'
import { ErrorMessage } from '@atlaskit/form'
import { DatePicker } from '@atlaskit/datetime-picker'
import Textfield from '@atlaskit/textfield'

//Interfaces
import { Field, useWizardFormContext, WizardForm } from '@royan-co/user-interface'

//Components
import Card from 'components/UI/Card'
import RegistrySelect from 'components/Registries/RegistrySelect'
import Divider from 'components/UI/Divider'
import RegistryTransportationAutocompleteField from 'components/Registries/View/TransportationAutocompleteField'
import RegistrySelectContactField from 'components/Registries/View/SelectContactsField'

const ConsolidationCreationAgentsPage = () => {
  const navigate = useNavigate()
  const { nextStep, prevStep } = useConsolidationCreationContext()

  const {
    stepsValue: { general, agents },
  } = useWizardFormContext()

  const handleCargoStep = () => {
    navigate(nextStep.path)
  }

  return (
    <>
      {!general?.arrival_country ? (
        <Navigate to={prevStep.path} replace />
      ) : (
        <WizardForm name="agents" onSubmit={handleCargoStep}>
          {({ setValue }) => (
            <>
              <Card className="mt-6">
                <Field
                  isRequired
                  type="select"
                  name="carrier"
                  label="Carrier"
                  className="!mt-0"
                  onValueChange={() => setValue('carrier_contact_id', null)}
                >
                  {({ fieldProps, error }) => (
                    <>
                      <RegistrySelect
                        {...fieldProps}
                        defaultData={agents?.carrier}
                        registryType="supplier"
                      />
                      {error && <ErrorMessage>{error}</ErrorMessage>}
                    </>
                  )}
                </Field>

                {agents?.carrier?.value && (
                  <RegistrySelectContactField
                    name="carrier_contact_id"
                    label="Contact"
                    registryId={agents?.carrier?.value}
                    className="!mt-4"
                  />
                )}

                <RegistryTransportationAutocompleteField
                  label="Transportation units"
                  name="means"
                  defaultValue={agents?.means}
                  className="!mt-4"
                />

                <Field
                  isRequired
                  label="Agreed cost"
                  name="agreed_cost"
                  className="!mt-4"
                  validate={(d) => (d * 1 >= 0 ? true : false)}
                >
                  {({ fieldProps, error }) => (
                    <>
                      <Textfield
                        placeholder="Type the amount"
                        {...fieldProps}
                        elemAfterInput={
                          <div className="text-neutral-200 whitespace-nowrap bg-neutral-20 self-stretch flex items-center px-2">
                            Euro (€)
                          </div>
                        }
                      />
                      {error && <ErrorMessage>{error}</ErrorMessage>}
                    </>
                  )}
                </Field>

                <Field
                  type="checkbox"
                  name="tracking_mail"
                  label="Tracking email"
                  className="!mt-4"
                  onValueChange={() =>
                    !!agents?.tracking_mail ? setValue('mail_tracking_date', '') : null
                  }
                >
                  {({ fieldProps }) => (
                    <div>
                      <Toggle {...fieldProps} />
                    </div>
                  )}
                </Field>

                {!!agents?.tracking_mail && (
                  <>
                    <Field
                      isRequired
                      label="Email date"
                      name="mail_tracking_date"
                      className="!mt-2"
                    >
                      {({ fieldProps, error }) => (
                        <>
                          <DatePicker placeholder="2/18/2022" {...fieldProps} />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                  </>
                )}

                <Divider className="mt-6 mb-4" />

                <Field
                  type="select"
                  label="Receiving agent"
                  name="agent_destination_id"
                  className="!mt-0"
                  onValueChange={() => setValue('agent_destination_contact_id', null)}
                >
                  {({ fieldProps, error }) => (
                    <>
                      <RegistrySelect {...fieldProps} defaultData={agents?.agent_destination_id} />
                      {error && <ErrorMessage>{error}</ErrorMessage>}
                    </>
                  )}
                </Field>

                {agents?.agent_destination_id?.value && (
                  <RegistrySelectContactField
                    name="agent_destination_contact_id"
                    label="Contact"
                    registryId={agents?.agent_destination_id?.value}
                    className="!mt-4"
                  />
                )}
              </Card>

              <div className="flex justify-end mt-6">
                <Button type="submit" appearance="default" component={Link} to={prevStep.path}>
                  Previous step
                </Button>

                <Button type="submit" appearance="primary" className="ml-2">
                  Next step
                </Button>
              </div>
            </>
          )}
        </WizardForm>
      )}
    </>
  )
}

export default ConsolidationCreationAgentsPage
