import { Helmet } from 'react-helmet-async'
import { useState, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router'

//Api
import { getShipment, uploadDocument } from 'api/shipments'

//Atlassian
import PageHeader from '@atlaskit/page-header'
import Spinner from '@atlaskit/spinner'
import { LoadingButton } from '@atlaskit/button'

//Interfaces
import { Grid, useFlags } from '@royan-co/user-interface'

const ShipmentEntrancePage = () => {
  const pageTitle = 'Shipment entrance image'

  const { shipmentId } = useParams()
  const navigate = useNavigate()
  const { showSuccessFlag } = useFlags()
  const inputRef = useRef()

  const [shipment, setShipment] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isUploading, setIsUploadig] = useState(false)

  const triggerUpload = (e) => {
    inputRef.current.click(e)
  }

  const handleUpload = async (e) => {
    const file = e.target?.files?.[0]

    if (!file) return

    try {
      setIsUploadig(true)

      //TODO: I don't like it!
      const formData = new FormData()
      formData.append('title', 'ENTRANCE IMAGE')
      formData.append('document', file)
      formData.append('flag_status', 2)
      formData.append('tracking_share', 1)

      await uploadDocument(formData, shipmentId)

      showSuccessFlag('Image uploaded successfully.')
    } catch (e) {
      console.log(e)
      //TODO: Showing errors to the user
    } finally {
      setIsUploadig(false)
    }
  }

  useEffect(() => {
    async function fetchShipment() {
      try {
        setIsLoading(true)
        const response = await getShipment(shipmentId)
        setShipment(response)
      } catch (e) {
        console.log(e)
        navigate('/not-found')
      } finally {
        setIsLoading(false)
      }
    }

    fetchShipment()
  }, [])

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <Grid gutter={24} className="w-full">
        <Grid.Col md={4} lg={4} offsetMd={4}>
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              <PageHeader>
                {pageTitle} - {shipment?.shipment_code}
              </PageHeader>
              <p style={{ marginBottom: '1rem' }}>
                Please upload the entrance image via the below button.
              </p>
              <LoadingButton
                shouldFitContainer
                appearance="primary"
                onClick={triggerUpload}
                isLoading={isUploading}
              >
                Upload image
              </LoadingButton>
              <input
                type="file"
                ref={inputRef}
                style={{ display: 'none' }}
                onChange={handleUpload}
              />
            </>
          )}
        </Grid.Col>
      </Grid>
    </>
  )
}

export default ShipmentEntrancePage
