// Interfaces
import { DotsIcon } from '@royan-co/user-interface'

// Atlassian
import Lozenge from '@atlaskit/lozenge'
import Button from '@atlaskit/button'
import DropdownMenu, { DropdownItemGroup } from '@atlaskit/dropdown-menu'

// Components
import DropdownLink from 'components/UI/DropdownLink'

export const generateServiceCodesColumns = () => {
  return [
    {
      key: 'code',
      title: 'Code',
      isSortable: true,
      show: true,
      content: (item) => item.code,
    },
    {
      key: 'description',
      title: 'Name',
      isSortable: true,
      show: true,
      content: (item) => item.description,
    },
    {
      key: 'accounts_id',
      title: 'Accounting code - Active',
      isSortable: false,
      show: true,
      content: (item) => item?.accounts_id || <Lozenge>Not set</Lozenge>,
    },
    {
      key: 'accounts_id_passive',
      title: 'Accounting code - Passive',
      isSortable: false,
      show: true,
      content: (item) => item?.accounts_id_passive || <Lozenge>Not set</Lozenge>,
    },
    {
      key: 'actions',
      title: 'Actions',
      show: true,
      content: (item) => (
        <DropdownMenu
          placement="bottom-end"
          trigger={({ triggerRef, shouldRenderToParent, ...props }) => (
            <Button
              {...props}
              appearance="subtle"
              children={<DotsIcon label="more" />}
              ref={triggerRef}
            />
          )}
        >
          <DropdownItemGroup>
            <DropdownLink children="Edit" to={`/panel/service-codes/${item.code}/edit`} />
          </DropdownItemGroup>
        </DropdownMenu>
      ),
    },
  ]
}
