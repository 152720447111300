import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { getSeaFreightRoutes } from '../api/getSeaFriehgtRoutes'

const useSeaFreightRoutes = ({ enabled = true } = {}) => {
  const { isLoading, data } = useQuery(['sea-freight-routes'], getSeaFreightRoutes, {
    retry: 2,
    staleTime: Infinity,
    enabled,
  })

  const seaFreightRoutesOptions = useMemo(() => {
    if (!data) return []

    return data.map((route) => ({
      label: route?.departure?.name + ' -> ' + route?.arrival?.name,
      value: route.id,
    }))
  }, [data])

  return { seaFreightRoutesOptions, isLoadingSeaFreightRoutes: isLoading }
}

export default useSeaFreightRoutes
