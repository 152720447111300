import { useQuery } from '@tanstack/react-query'

// Apis
import { getChartsData } from './dashboard-requests'

/**
 * get chart data
 * @param {object} params
 * @param {import('@tanstack/react-query').UseQueryOptions} options
 * @returns {import('@tanstack/react-query').UseQueryResult}
 */
export const useChart = ({ type, ...params }, options = {}) => {
  return useQuery({
    queryKey: ['chart', type, params],
    queryFn: () => getChartsData(type, params),
    staleTime: Infinity,
    ...options,
  })
}
