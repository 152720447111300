import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, useQueryClient } from '@tanstack/react-query'

//Apis
import { getConsolidation } from 'api/consolidations'

//Contexts
import { useAuth } from 'contexts/AuthProvider'

const useConsolidation = (consolidationId, { enabled = true } = {}) => {
  const { currentUser } = useAuth()
  const queryClient = useQueryClient()
  const { consolidationId: consolidationIdInUrl } = useParams()

  const _consolidationId = consolidationId || consolidationIdInUrl

  if (enabled && !_consolidationId) {
    throw new Error('useConsolidation: consolidation id is required')
  }

  const {
    error,
    refetch,
    isError,
    isLoading,
    isFetching,
    data: consolidation,
  } = useQuery({
    queryKey: ['consolidation', _consolidationId],
    queryFn: () => getConsolidation(_consolidationId),
    enabled,
    staleTime: Infinity,
    // placeholderData: placeholderData || {},
  })

  const setLimitationExceptCreator = useCallback(
    (limitationLevel) => {
      if (
        currentUser?.registry_id === consolidation?.creator?.registry_id &&
        currentUser?.registry_address_id === consolidation?.creator?.registry_address_id
      )
        return null
      else return limitationLevel
    },
    [currentUser, consolidation]
  )

  const deleteTrackingContactFromData = (deletedId) => {
    queryClient.setQueryData(['consolidation', _consolidationId], (oldConsolidation) => ({
      ...oldConsolidation,
      tracking_contacts: oldConsolidation.tracking_contacts.filter((c) => c.id !== deletedId),
    }))
  }

  const upsertTrackingContactToData = (contact) => {
    const isExist = (consolidation?.tracking_contacts || []).some((n) => n.id === contact.id)

    queryClient.setQueryData(['consolidation', _consolidationId], (oldConsolidation = {}) => ({
      ...oldConsolidation,
      tracking_contacts: isExist
        ? (oldConsolidation.tracking_contacts || []).map((c) => (c.id === contact.id ? contact : c))
        : [...(oldConsolidation.tracking_contacts || []), contact],
    }))
  }

  const UpdateNotesCount = (type) => {
    queryClient.setQueryData(['consolidation', _consolidationId], (oldConsolidation = {}) => {
      const notesCount = oldConsolidation?.notes_count * 1 || 0

      return {
        ...oldConsolidation,
        notes_count:
          type === 'increase'
            ? `${notesCount + 1}`
            : type === 'decrease' && notesCount > 0
            ? `${notesCount - 1}`
            : `${notesCount}`,
      }
    })
  }

  const startLoadingConsolidation = () => {
    queryClient.setQueryData(['consolidation', _consolidationId], (oldConsolidation = {}) => ({
      ...oldConsolidation,
      loading: 1,
    }))
  }

  const startTripConsolidation = () => {
    queryClient.setQueryData(['consolidation', _consolidationId], (oldConsolidation = {}) => ({
      ...oldConsolidation,
      trip_started: 1,
      status: 1,
    }))
  }

  const finishTripConsolidation = () => {
    queryClient.setQueryData(['consolidation', _consolidationId], (oldConsolidation = {}) => ({
      ...oldConsolidation,
      status: 2,
      trip_finished: 1,
    }))
  }

  const updateTakeInChargeDate = (date) => {
    queryClient.setQueryData(['consolidation', _consolidationId], (oldConsolidation = {}) => ({
      ...oldConsolidation,
      creation_date: date,
    }))
  }

  const upsertTrackingUnitToData = (meanId, trackingUnit) => {
    queryClient.setQueryData(['consolidation', _consolidationId], (oldConsolidation = {}) => ({
      ...oldConsolidation,
      means: oldConsolidation.means?.map((m) =>
        +m.id_auto === +meanId ? { ...m, tracking_unit: trackingUnit } : m
      ),
    }))
  }

  const removeTrackingUnitToData = (meanId, trackingUnitId) => {
    queryClient.setQueryData(['consolidation', _consolidationId], (oldConsolidation = {}) => ({
      ...oldConsolidation,
      means: oldConsolidation.means?.map((m) =>
        +m.id_auto === +meanId && m.tracking_unit.id_auto === trackingUnitId
          ? { ...m, tracking_unit: {} }
          : m
      ),
    }))
  }

  const invalidate = () => {
    queryClient.invalidateQueries({
      queryKey: ['consolidation', _consolidationId],
      refetchType: 'none',
    })
  }

  return {
    error,
    refetch,
    consolidation,
    isError,
    isLoading,
    isFetching,
    deleteTrackingContactFromData,
    upsertTrackingContactToData,
    UpdateNotesCount,
    startLoadingConsolidation,
    startTripConsolidation,
    finishTripConsolidation,
    updateTakeInChargeDate,
    setLimitationExceptCreator,
    upsertTrackingUnitToData,
    removeTrackingUnitToData,
    invalidate,
  }
}

export default useConsolidation
