import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { useParams, useNavigate } from 'react-router'
import { Helmet } from 'react-helmet-async'

// Apis
import { createMasterShipment, editShipmentParam } from 'api/shipments'

// Contexts
import { useAuth } from 'contexts/AuthProvider'

// Features
import { useCanAccess } from 'features/auth'

// Atlassian
import PageHeader from '@atlaskit/page-header'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'
import Button, { LoadingButton } from '@atlaskit/button'
import Spinner from '@atlaskit/spinner'
import SectionMessage from '@atlaskit/section-message'
import { ErrorMessage } from '@atlaskit/form'

// Interfaces
import { Grid, WarningBanner, useFlags, useStateList } from '@royan-co/user-interface'

// Components
import useShipment from 'hooks/useShipment'
import { generateShipmentUserPermissions } from 'components/Shipments/Create/shipment-user-permissions'
import SelectShipmentsFromList from 'components/Shipments/View/SelectShipmentsFromList'

const ShipmentCreationMasterPage = () => {
  const navigate = useNavigate()
  const { shipmentId } = useParams()
  const { currentUser } = useAuth()
  const { showSuccessFlag } = useFlags()
  const [selectedShipmentsError, setSelectedShipmentsError] = useState(false)
  const pageTitle = shipmentId ? 'Edit master dossier' : 'Create master dossier'

  const { shipment, invalidate, isLoading, isFetching, isError, refetch } = useShipment(
    shipmentId,
    {
      enabled: !!shipmentId,
    }
  )

  const userPermissions = generateShipmentUserPermissions(shipment, currentUser)
  const canEdit = useCanAccess({
    resource: 'shipments.houseShipments',
    action: 'edit',
    userPermissions,
  })

  const [selectedShipments, dispatchSelectedShipments] = useStateList([], { key: 'id_auto' })

  const selectedShipmentsId = useMemo(
    () => selectedShipments.map((s) => s?.id_auto),
    [selectedShipments]
  )

  const creationMasterShipmentMutation = useMutation((data) =>
    shipmentId ? editShipmentParam(shipmentId, '', data) : createMasterShipment(data)
  )

  const creationMasterShipment = () => {
    if (selectedShipmentsId?.length < 1 && !shipmentId) {
      setSelectedShipmentsError('Select shipments is required.')
      return
    }

    const data = {
      shipments: selectedShipmentsId,
    }

    creationMasterShipmentMutation.mutate(data, {
      onError: (e) => {
        console.error(e)
      },
      onSuccess: (res) => {
        showSuccessFlag(res?.message)
        if (shipmentId) {
          invalidate()
        }
        navigate(
          shipmentId
            ? `/panel/shipments/${shipmentId}/house-shipments`
            : `/panel/shipments/${res?.master}`,
          { replace: true }
        )
      },
    })
  }

  const onCancelHandler = () => {
    if (shipmentId) {
      navigate(`/panel/shipments/${shipmentId}/house-shipments`, { replace: true })
    } else {
      navigate('/panel/shipments', { replace: true })
    }
  }

  useEffect(() => {
    if (shipmentId && !canEdit && shipment?.id_auto) navigate('/panel/shipments')
  }, [canEdit, navigate, shipment?.id_auto, shipmentId])

  useEffect(() => {
    if (selectedShipmentsId?.length > 0) {
      setSelectedShipmentsError('')
    }
  }, [selectedShipmentsId?.length])

  useEffect(() => {
    shipment?.house_shipments?.map((shipment) =>
      dispatchSelectedShipments({ type: 'add', payload: shipment })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipment])

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <Grid gutter={24} className="w-full">
        <Grid.Col md={10} lg={10} offsetMd={1} offsetLg={1}>
          <PageHeader
            breadcrumbs={
              <Breadcrumbs>
                <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
                <BreadcrumbsItem text="Shipments" to="/panel/shipments" component={Link} />
                {shipmentId && (
                  <BreadcrumbsItem
                    text={isLoading ? <Spinner size="xsmall" /> : shipment?.shipment_code}
                    to={`/panel/shipments/${shipmentId}`}
                    component={Link}
                  />
                )}
              </Breadcrumbs>
            }
          >
            {pageTitle}
          </PageHeader>

          {!!shipmentId && (isLoading || isFetching) ? (
            <div className="text-center my-8">
              <Spinner />
            </div>
          ) : isError ? (
            <SectionMessage
              appearance="warning"
              title={`Something wrong on loading shipment data, please retry`}
            >
              <Button onClick={() => refetch()}>Retry</Button>
            </SectionMessage>
          ) : (
            <div className="pb-4">
              <>
                <SelectShipmentsFromList
                  selectedShipments={selectedShipments}
                  dispatchSelectedShipments={dispatchSelectedShipments}
                  selectedShipmentsId={selectedShipmentsId}
                  sectionParameter="master"
                />
                {selectedShipmentsError && <ErrorMessage>{selectedShipmentsError}</ErrorMessage>}

                {creationMasterShipmentMutation.isError && <WarningBanner />}

                <div className="text-right w-full mt-8">
                  <Button appearance="subtle" onClick={onCancelHandler}>
                    Cancel
                  </Button>

                  <LoadingButton
                    className="ml-2"
                    appearance="primary"
                    onClick={creationMasterShipment}
                    isLoading={creationMasterShipmentMutation.isLoading}
                  >
                    {shipmentId ? 'Edit' : 'Create'}
                  </LoadingButton>
                </div>
              </>
            </div>
          )}
        </Grid.Col>
      </Grid>
    </>
  )
}

export default ShipmentCreationMasterPage
