export const registries = {
  shipper_fields: {
    names: {
      registry: 'shipper_id',
      address: 'shipper_address_id',
      contact: 'shipper_contact_id',
    },
    labels: {
      registry: 'Shipper',
      address: 'Address',
      contact: 'Contact',
    },
    default_value_keys: {
      registry: 'shipper',
      address: 'shipper_address',
      contact: 'shipper_contact',
    },
  },
  second_shipper_fields: {
    names: {
      registry: 'second_shipper_id',
      address: 'second_shipper_address_id',
      contact: 'second_shipper_contact_id',
    },
    labels: {
      registry: 'Second shipper',
      address: 'Second shipper address',
      contact: 'Second shipper contact',
    },
    default_value_keys: {
      registry: 'second_shipper',
      address: 'second_shipper_address',
      contact: 'second_shipper_contact',
    },
  },
  pickup_fields: {
    names: {
      registry: 'pick_up_id',
      address: 'pick_up_address_id',
      contact: 'pick_up_contact_id',
    },
    labels: {
      registry: 'Pickup at',
      address: 'Pickup address',
      contact: 'Pickup contact',
    },
    default_value_keys: {
      registry: 'pickup_at',
      address: 'pickup_address',
      contact: 'pickup_contact',
    },
  },
  consignee_fields: {
    names: {
      registry: 'consignee_id',
      address: 'consignee_address_id',
      contact: 'consignee_contact_id',
    },
    labels: {
      registry: 'Consignee',
      address: 'Address',
      contact: 'Contact',
    },
    default_value_keys: {
      registry: 'consignee',
      address: 'consignee_address',
      contact: 'consignee_contact',
    },
  },
  second_consignee_fields: {
    names: {
      registry: 'second_consignee_id',
      address: 'second_consignee_address_id',
      contact: 'second_consignee_contact_id',
    },
    labels: {
      registry: 'Second consignee',
      address: 'Second consignee address',
      contact: 'Second consignee contact',
    },
    default_value_keys: {
      registry: 'second_consignee',
      address: 'second_consignee_address',
      contact: 'second_consignee_contact',
    },
  },
  delivery_fields: {
    names: {
      registry: 'delivery_id',
      address: 'delivery_address_id',
      contact: 'delivery_contact_id',
    },
    labels: {
      registry: 'Delivery to',
      address: 'Delivery address',
      contact: 'Delivery contact',
    },
    default_value_keys: {
      registry: 'delivery_to',
      address: 'delivery_address',
      contact: 'delivery_contact',
    },
  },
  notify_fields: {
    names: {
      registry: 'notify_id',
      address: 'notify_address_id',
      contact: 'notify_contact_id',
    },
    labels: {
      registry: 'Notify',
      address: 'Notify address',
      contact: 'Notify contact',
    },
    default_value_keys: {
      registry: 'notify',
      address: 'notify_address',
      contact: 'notify_contact',
    },
  },
  receiving_agent_fields: {
    names: {
      registry: 'agent_destination_id',
      address: 'agent_destination_address_id',
      contact: 'agent_destination_contact_id',
    },
    labels: {
      registry: 'Receiving agent',
      address: 'Receiving agent address',
      contact: 'Receiving agent contact',
    },
    default_value_keys: {
      registry: 'receiving_agent',
      address: 'receiving_agent_address',
      contact: 'receiving_agent_contact',
    },
  },
  departure_fields: {
    names: {
      registry: 'custom_broker_id',
      address: 'custom_broker_address_id',
      contact: 'custom_broker_contact_id',
    },
    labels: {
      registry: 'Export custom broker',
      address: 'Address',
      contact: 'Contact',
    },
    default_value_keys: {
      registry: 'departure_custom_broker',
      address: 'departure_custom_broker_address',
      contact: 'departure_custom_broker_contact',
    },
  },
  destination_fields: {
    names: {
      registry: 'custom_broker_destination_id',
      address: 'custom_broker_destination_address_id',
      contact: 'custom_broker_destination_contact_id',
    },
    labels: {
      registry: 'Import custom broker',
      address: 'Address',
      contact: 'Contact',
    },
    default_value_keys: {
      registry: 'destination_custom_broker',
      address: 'destination_custom_broker_address',
      contact: 'destination_custom_broker_contact',
    },
  },
}
