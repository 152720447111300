import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { getPaymentBanks } from 'api/accounting'

const usePaymentBanks = ({ enabled = true } = {}) => {
  const { isLoading, data } = useQuery(['payment-banks'], getPaymentBanks, {
    retry: 2,
    staleTime: Infinity,
    enabled,
  })

  const paymentBankOptions = useMemo(() => {
    if (!data) return []

    return data.map((b) => ({ label: b.name, value: b.id_auto }))
  }, [data])

  return { paymentBankOptions, isLoadingPaymentBanks: isLoading }
}

export default usePaymentBanks
