import Routes from 'routes'
import { Helmet } from 'react-helmet-async'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

//Contexts
import { AppProvider } from 'contexts/AppProvider'
import { AuthProvider } from 'contexts/AuthProvider'

//Atlassian
import { FlagsProvider } from '@atlaskit/flag'
import LoadingOverlayContainer from 'components/LoadingOverlay/index'

const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: false, refetchOnWindowFocus: false } },
})

function App() {
  return (
    <FlagsProvider>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <AppProvider>
            <Helmet titleTemplate="%s | Expesoft - core" />

            <LoadingOverlayContainer>
              <Routes />
            </LoadingOverlayContainer>
          </AppProvider>
        </AuthProvider>

        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </FlagsProvider>
  )
}

export default App
