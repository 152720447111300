import { useState } from 'react'
import { saveAs } from 'file-saver'

// Apis
import { getInvoicePDF } from 'api/invoices'

// Atlassian
import { LoadingButton } from '@atlaskit/button'

// Interfaces
import { useFlags } from '@royan-co/user-interface'

const DownloadInvoiceDocument = ({ invoice, type }) => {
  const [isLoading, setISLoading] = useState(false)
  const { showWarningFlag } = useFlags()

  const openActiveInvoiceFile = async () => {
    const parents = invoice?.parents.map((parent) => parent.reference)
    const invoicePDF = await getInvoicePDF(invoice.id_auto)

    saveAs(
      new Blob([invoicePDF]),
      (invoice?.is_proforma ? `PROFORMA` : `Invoice no. ${invoice.invoice_number}`) +
        ` - ${parents.join('-')}.pdf`
    )
  }

  const openPassiveInvoiceFile = async () => {
    if (invoice.download_url) return window.open(invoice.download_url)
    else showWarningFlag('There is no file')
  }

  const showInvoicePDF = async () => {
    try {
      if (isLoading) return

      setISLoading(true)

      if (type === 'active') {
        await openActiveInvoiceFile()
      } else if (type === 'passive') {
        await openPassiveInvoiceFile()
      }
    } catch (error) {
      console.error(error)
      showWarningFlag('Something wrong on download PDF')
    } finally {
      setISLoading(false)
    }
  }

  return (
    <LoadingButton isLoading={isLoading} appearance="primary" onClick={showInvoicePDF}>
      Download PDF
    </LoadingButton>
  )
}

export default DownloadInvoiceDocument
