import { forwardRef, useState } from 'react'

// Hooks
import useTrackingUnitAutocomplete from 'hooks/useTrackingUnitAutocomplete'

// Atlassian
import { CreatableSelect } from '@atlaskit/select'

const createOption = (label) => ({
  label,
  value: label,
  isNew: true,
})

const TrackingUnitSelect = forwardRef(({ value, onChange, ...props }, ref) => {
  const [trackingValue, setTrackingValue] = useState(value)

  const {
    trackingAutocompleteOptions,
    setTermTrackingAutocomplete,
    isLoadingTrackingAutocomplete,
    addTrackingUnitOption,
  } = useTrackingUnitAutocomplete()

  const onCreateNewTrackingOption = (option) => {
    const newOption = createOption(option)
    addTrackingUnitOption(newOption)
    handleChange(newOption)
  }

  const handleChange = (option) => {
    setTrackingValue(option)
    onChange(option)
  }

  return (
    <CreatableSelect
      ref={ref}
      isClearable
      onCreateOption={onCreateNewTrackingOption}
      isLoading={isLoadingTrackingAutocomplete}
      options={trackingAutocompleteOptions}
      onInputChange={(e) => setTermTrackingAutocomplete(e)}
      value={trackingValue}
      onChange={handleChange}
      {...props}
    />
  )
})

export default TrackingUnitSelect
