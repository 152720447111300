import { useMediaQuery } from 'react-responsive'

// Utils
import { transformSelectField } from 'utils/helpers'
import { trackingUnitTypeOptions } from '../constants'

// Atlassian
import { LoadingButton } from '@atlaskit/button'
import Drawer from '@atlaskit/drawer'
import TextField from '@atlaskit/textfield'
import { ErrorMessage } from '@atlaskit/form'
import Select from '@atlaskit/select'

// Interfaces
import { Field, Form, WarningBannerField } from '@royan-co/user-interface'

// Components
import TrackingUnitSelect from './TrackingUnitSelect'

const TrackingUnitDrawer = ({ onClose, isOpen, data, onSubmit, isLoading = false }) => {
  const isMobile = useMediaQuery({ query: `(max-width: 991.98px)` })

  return (
    <Drawer isOpen={isOpen} onClose={onClose} width={isMobile ? 'full' : 'medium'}>
      <div className="drawer">
        <h3>Link to a GPS</h3>

        <Form onSubmit={onSubmit} defaultValues={data}>
          {({ watch }) => (
            <>
              <Field
                isRequired
                type="select"
                label="Serial number"
                name="serial_number"
                className="!mt-4"
              >
                {({ fieldProps, error }) => (
                  <>
                    <TrackingUnitSelect placeholder="Search something" {...fieldProps} />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>

              {watch('serial_number')?.isNew && (
                <>
                  <Field
                    isRequired
                    label="Type"
                    name="positrex"
                    transform={transformSelectField(trackingUnitTypeOptions)}
                    className="!mt-4"
                  >
                    {({ fieldProps, error }) => (
                      <>
                        <Select
                          options={trackingUnitTypeOptions}
                          placeholder="Choose a type"
                          {...fieldProps}
                        />
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                      </>
                    )}
                  </Field>
                </>
              )}

              <WarningBannerField />

              <LoadingButton
                isLoading={isLoading}
                type="submit"
                className="mt-4"
                shouldFitContainer
                appearance="primary"
              >
                Save
              </LoadingButton>
            </>
          )}
        </Form>
      </div>
    </Drawer>
  )
}

export default TrackingUnitDrawer
