//Atlassian
import { ErrorMessage } from '@atlaskit/form'
import Toggle from '@atlaskit/toggle'

//Interfaces
import { Field } from '@royan-co/user-interface'

//Components
import Card from 'components/UI/Card'

const conditionFields = {
  second_set: 'Second set of documents',
  promo: 'Promo',
  insurance: 'Insurance',
  exclude_accessories: 'Exclude accessories',
  letter_of_credit: 'Letter of credit',
  no_cost: 'Exclude cost calculation',
  must_go: 'Must-go',
  exclude_margin: 'Exclude margin',
  consolidate: 'Consolidation service',
}

const RateCreationSpecialConditionsFields = () => {
  return (
    <Card className="mb-6" title="Special conditions">
      <div className="grid grid-cols-2 gap-x-4 gap-y-1">
        {Object.entries(conditionFields).map(([key, title]) => (
          <Field
            key={`toggle-${key}`}
            name={key}
            label={title}
            type="checkbox"
            defaultValue={false}
          >
            {({ fieldProps, error }) => (
              <>
                <div>
                  <Toggle {...fieldProps} id={`toggle-${key}`} />
                </div>
                {error && <ErrorMessage>{error}</ErrorMessage>}
              </>
            )}
          </Field>
        ))}
      </div>
    </Card>
  )
}

export default RateCreationSpecialConditionsFields
