import Lozenge from '@atlaskit/lozenge'
import { findOption } from 'utils/helpers'
import { registryEntityTypeOptions } from './constants'

const RegistryEntityTypeTag = ({ value }) => {
  const option = findOption(registryEntityTypeOptions, value)

  return option ? <Lozenge appearance={option.appearance}>{option.label}</Lozenge> : null
}

export default RegistryEntityTypeTag
