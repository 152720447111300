import { useQuery } from '@tanstack/react-query'

// Apis
import { getRegistryContacts } from 'api/registries'

/**
 * get registry's contacts
 * @param {string} contactId
 * @param {import('@tanstack/react-query').UseQueryOptions} options
 * @returns {import('@tanstack/react-query').UseQueryResult}
 */
const useRegistryContacts = (contactId, options = {}) => {
  return useQuery({
    queryKey: ['registry', 'contact', contactId],
    queryFn: () => getRegistryContacts(contactId),
    ...options,
  })
}

export default useRegistryContacts
