import { Link, useParams } from 'react-router-dom'

//Atlassian
import Button from '@atlaskit/button'

const ShipmentViewFinanceActions = ({ isShipment }) => {
  const { shipmentId, quotationId } = useParams()
  return (
    <>
      {isShipment && (
        <Button
          component={Link}
          appearance="primary"
          to={`/panel/shipments/${shipmentId}/active-invoices/create`}
        >
          Issue an invoice
        </Button>
      )}

      <Button
        component={Link}
        appearance="primary"
        to={
          isShipment
            ? `/panel/shipments/${shipmentId}/finance/edit`
            : `/panel/quotations/${quotationId}/finance/edit`
        }
      >
        Edit cost and suppliers
      </Button>
    </>
  )
}

export default ShipmentViewFinanceActions
