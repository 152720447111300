import { useMemo } from 'react'

//Hooks
import useConsolidation from 'hooks/useConsolidation'

//Atlassian
import Button from '@atlaskit/button'
import Lozenge from '@atlaskit/lozenge'
import { DynamicTableStateless } from '@atlaskit/dynamic-table'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'

//Interfaces
import { DotsIcon, useDisclosure } from '@royan-co/user-interface'

//Components
import Card, { CardTitle } from 'components/UI/Card'
import ShareTrackingModal from './ShareTrackingModal'
import DeleteShareTrackingModal from './DeleteShareTrackingModal'

const tableHead = {
  cells: [{ content: 'Contact name' }, { content: 'Channels' }, { content: '' }],
}

const ConsolidationTrackingContactsDetail = () => {
  const [isOpenTrackingModal, trackingModalHandlers] = useDisclosure()
  const [isOpenDeletingModal, deletingModalHandlers] = useDisclosure()

  const { consolidation } = useConsolidation()

  const tableRows = useMemo(() => {
    return consolidation?.tracking_contacts?.map((contact, i) => ({
      cells: [
        {
          content: contact?.name || <Lozenge appearance="default">Not set</Lozenge>,
        },
        {
          content: (
            <>
              {!!contact?.contact ? (
                <span className="mr-1">
                  <Lozenge appearance="inprogress">Email</Lozenge>
                </span>
              ) : null}

              {!!contact?.phone ? <Lozenge appearance="success">Whatsapp</Lozenge> : null}

              {!contact?.contact && !contact?.phone ? (
                <Lozenge appearance="moved">Not set</Lozenge>
              ) : null}
            </>
          ),
        },

        {
          content: (
            <DropdownMenu
              placement="bottom-end"
              trigger={({ triggerRef, ...props }) => (
                <Button ref={triggerRef} appearance="subtle" children={<DotsIcon />} {...props} />
              )}
            >
              <DropdownItemGroup>
                <DropdownItem children="Edit" onClick={() => trackingModalHandlers.open(contact)} />
                <DropdownItem
                  children="Delete"
                  onClick={() => deletingModalHandlers.open(contact)}
                />
              </DropdownItemGroup>
            </DropdownMenu>
          ),
        },
      ],
    }))
  }, [consolidation, deletingModalHandlers, trackingModalHandlers])

  return (
    <>
      <div className="flex items-center mb-3 mt-6">
        <CardTitle className="mb-0">Tracking contacts</CardTitle>
        <Button appearance="link" className="ml-auto" onClick={() => trackingModalHandlers.open()}>
          Add tracking contact
        </Button>
      </div>

      <Card>
        <div className="overflow-hidden w-100">
          {tableRows?.length > 0 ? (
            <div className="overflow-auto">
              <DynamicTableStateless head={tableHead} rows={tableRows} />
            </div>
          ) : (
            <div className="text-s text-neutral-200">There are no contacts.</div>
          )}
        </div>

        <ShareTrackingModal
          isOpen={isOpenTrackingModal}
          editingContact={trackingModalHandlers.data}
          onClose={() => trackingModalHandlers.close()}
        />

        <DeleteShareTrackingModal
          isOpen={isOpenDeletingModal}
          deletingContact={deletingModalHandlers.data}
          onClose={() => deletingModalHandlers.close()}
        />
      </Card>
    </>
  )
}

export default ConsolidationTrackingContactsDetail
