import { Link, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

// Api
import { getUsers } from './../api/users-requests'

// Atlassian
import PageHeader from '@atlaskit/page-header'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'
import Button, { ButtonGroup } from '@atlaskit/button'

// Interfaces
import { RoyanTable, useDisclosure } from '@royan-co/user-interface'

// Components
import UsersFilterFields from '../components/list/FilterFields'
import { generateUsersColumns } from '../components/list/columns'
import { usersFilters } from '../components/list/filters'
import UserDeactivateModal from '../components/DeactivateModal'

const pageTitle = 'Users'

const UsersListPage = () => {
  const navigate = useNavigate()
  const [isOpenDeactivateModal, handleDeactivateModal] = useDisclosure('deactivate')

  const openDeactivateModal = (user) => {
    handleDeactivateModal.open(user)
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <PageHeader
        breadcrumbs={
          <Breadcrumbs>
            <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
            <BreadcrumbsItem text="Configurations" />
          </Breadcrumbs>
        }
        actions={
          <ButtonGroup>
            <Button appearance="primary" onClick={() => navigate('/panel/users/create')}>
              New user
            </Button>
          </ButtonGroup>
        }
      >
        {pageTitle}
      </PageHeader>

      <RoyanTable
        sortOrder="DESC"
        name="users-list"
        sortKey="creation_date"
        request={getUsers}
        columns={generateUsersColumns(openDeactivateModal)}
        filters={usersFilters}
        filterFields={<UsersFilterFields />}
      >
        <UserDeactivateModal
          isOpen={isOpenDeactivateModal}
          onClose={handleDeactivateModal.close}
          user={handleDeactivateModal.data}
        />
      </RoyanTable>
    </>
  )
}

export default UsersListPage
