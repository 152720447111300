import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { getShipmentsAgents } from 'api/shipments'

const useShipmentsAgents = ({ enabled = true } = {}) => {
  const { isLoading, data } = useQuery(['shipments', 'agents'], getShipmentsAgents, {
    staleTime: Infinity,
    retry: 2,
    enabled,
  })

  const shipmentsAgentsOptions = useMemo(() => {
    if (!data?.agents) return []

    return data.agents.map((u) => ({ label: u.full_name, value: u.auto_id }))
  }, [data])

  const shipmentsAgents = useMemo(() => {
    if (!data?.agents) return []

    return data.agents
  }, [data])

  return { shipmentsAgents, shipmentsAgentsOptions, isLoadingShipmentsAgents: isLoading }
}

export default useShipmentsAgents
