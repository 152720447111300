import { useMemo } from 'react'

// Hooks
import { useResetInvoice } from 'features/invoice/api/resetInvoice'

// Contexts
import { useAuth } from 'contexts/AuthProvider'

// Components
import ActionDropdownItem from 'components/ActionDropdownItem'
import DisabledElementTooltip from 'components/UI/DisabledElementTooltip'

const ResetInvoiceAction = ({ invoice, isActive, shipmentId }) => {
  const { currentUser } = useAuth()
  const resetInvoiceMutation = useResetInvoice({ invoiceId: invoice?.id_auto, shipmentId })

  const accessabilityReset = useMemo(() => {
    if (!['master', 'admin', 'accounting'].includes(currentUser?.user_level_name?.name)) {
      return {
        access: false,
        message: 'You do not have access to reset the invoice.',
      }
    } else if (!isActive && !(invoice?.arca_status >= 2))
      return {
        access: false,
        message: 'Invoice is not processed.',
      }
    else
      return {
        access: true,
      }
  }, [currentUser?.user_level_name?.name, invoice?.arca_status, isActive])

  return invoice?.id_auto ? (
    <DisabledElementTooltip
      isDisabled={!accessabilityReset?.access}
      tooltipContent={accessabilityReset?.message}
    >
      <ActionDropdownItem
        label="Reset"
        isDisabled={!accessabilityReset?.access}
        mutation={resetInvoiceMutation}
        mutationParams={{ status: isActive ? 0 : 2 }}
      />
    </DisabledElementTooltip>
  ) : null
}

export default ResetInvoiceAction
