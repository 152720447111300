// Hooks
import useTransportationAutocomplete from 'hooks/useTransportationAutocomplete'

//Atlassian
import Select from '@atlaskit/select'

//Interfaces
import { Field } from '@royan-co/user-interface'

const RegistryTransportationAutocompleteField = ({
  name,
  label,
  isDisabled = false,
  className,
  defaultMeansValues,
}) => {
  const {
    transportationAutocompleteOptions,
    setTermTransportationAutocomplete,
    isLoadingTransportationAutocomplete,
  } = useTransportationAutocomplete()

  return (
    <Field label={label} type="select" name={name} isDisabled={isDisabled} className={className}>
      {({ fieldProps }) => (
        <Select
          isClearable={true}
          isMulti
          value={defaultMeansValues}
          options={transportationAutocompleteOptions}
          isLoading={isLoadingTransportationAutocomplete}
          onInputChange={(newValue) => setTermTransportationAutocomplete(newValue)}
          {...fieldProps}
        />
      )}
    </Field>
  )
}

export default RegistryTransportationAutocompleteField
