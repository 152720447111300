import { findOption } from 'utils/helpers'
import { statusOptions } from './constants'

//Atlassian
import Tag from '@atlaskit/tag'

const ConsolidationStatusTag = ({ value }) => {
  const opt = findOption(statusOptions, value)

  return opt ? (
    <div className="removeWidthLimitFromAtlaskitTag">
      <Tag isRemovable={false} color={opt.color} text={opt.label} />
    </div>
  ) : (
    ''
  )
}

export default ConsolidationStatusTag
