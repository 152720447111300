import { useMemo } from 'react'
import { transformSelectField } from 'utils/helpers'
import useShipmentsEnumerations from 'hooks/useShipmentsEnumerations'

//Atlassian
import Select from '@atlaskit/select'
import { ErrorMessage } from '@atlaskit/form'

//Interfaces
import { Field } from '@royan-co/user-interface'

const EnumerationField = ({
  label,
  name,
  optionsKey,
  filterOptionsKey,
  filterOptionsValue,
  spacing = 'default',
  isDisabled = false,
  isRequired = false,
}) => {
  const { getEnumerationByKey, getSelectOptionsByKey, isLoadingShipmentEnumeration } =
    useShipmentsEnumerations()

  const enumerationOptions = useMemo(() => {
    if (filterOptionsKey && filterOptionsValue)
      return getEnumerationByKey(optionsKey, [])
        ?.filter((item) => item?.[filterOptionsKey] === filterOptionsValue)
        ?.map((i) => ({ value: i.id_auto, label: i.name }))
    else return getSelectOptionsByKey(optionsKey)
  }, [filterOptionsKey, filterOptionsValue, getEnumerationByKey, getSelectOptionsByKey, optionsKey])

  return (
    <Field
      isRequired={isRequired}
      label={label}
      type="select"
      name={name}
      transform={transformSelectField(enumerationOptions)}
    >
      {({ fieldProps, error }) => (
        <>
          <Select
            spacing={spacing}
            isClearable={true}
            isDisabled={isDisabled}
            isLoading={isLoadingShipmentEnumeration}
            options={enumerationOptions}
            placeholder="Choose a place"
            {...fieldProps}
          />
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </>
      )}
    </Field>
  )
}

export default EnumerationField
