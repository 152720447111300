import { useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

// Apis
import { getFinanceDetails } from 'api/shipments'

// Utils
import { findOption } from 'utils/helpers'
import { formatMonetaryNumber, formatNumber } from 'utils/numbers'

//Atlassian
import Button from '@atlaskit/button'
import Lozenge from '@atlaskit/lozenge'
import EmptyState from '@atlaskit/empty-state'
import Spinner from '@atlaskit/spinner'
import SectionMessage from '@atlaskit/section-message'
import { DynamicTableStateless } from '@atlaskit/dynamic-table'
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down'

//Interfaces
import { ShortcutIcon } from '@royan-co/user-interface'

import { calculationMethodShortLabelOptions } from 'components/Rates/constants'

const ShipmentViewFinancePage = () => {
  const { shipmentId, quotationId } = useParams()
  const entityId = shipmentId || quotationId
  const [showRowSurcharges, setShowRowSurcharges] = useState()

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ['finances', entityId],
    queryFn: () => getFinanceDetails(entityId),
  })

  const financeHead = useMemo(() => {
    let headItems = [
      { content: 'Service' },
      { content: 'Supplier' },
      { content: 'Rate' },
      { content: 'Method of calculation' },
      { content: 'Quantity' },
      { content: 'Cost' },
      { content: 'Quotation' },
    ]

    return {
      cells: headItems,
    }
  }, [])

  const estimatedCost = useMemo(() => data?.rows?.reduce((p, c) => p + c?.cost * 1, 0), [data])
  const estimatedQuotation = useMemo(
    () => data?.rows?.reduce((p, c) => p + c?.quotation * 1, 0),
    [data]
  )
  const finalCost = useMemo(
    () =>
      data?.rows
        ?.filter((i) => !!i.passive_invoices?.length)
        .reduce((p, c) => p + (c?.real_cost || c?.cost) * 1 + (c?.passive_credit * 1 || 0), 0),
    [data]
  )
  const finalQuotation = useMemo(
    () =>
      data?.rows
        ?.filter((i) => !!i.active_invoices?.length)
        .reduce((p, c) => p + ((c?.revenue || c?.quotation) * 1 + (c?.credit * 1 || 0)), 0),
    [data]
  )
  const totalEstimated = useMemo(
    () => estimatedQuotation - estimatedCost,
    [estimatedCost, estimatedQuotation]
  )
  const totalFinal = useMemo(() => finalQuotation - finalCost, [finalCost, finalQuotation])

  const financeRows = useMemo(() => {
    let body =
      data?.rows?.map((item, i) => {
        let shipmentsItems = [
          {
            id: `service${i}`,
            content:
              item.type === 'main' ? (
                item?.service
              ) : (
                <div className="pl-6">
                  <div className="text-neutral-500">{item?.service}</div>
                </div>
              ),
          },
          {
            id: `supplier${i}`,
            content: item.supplier?.id_auto ? (
              <Link to={`/panel/registries/${item?.supplier?.id_auto}`} target="_blank">
                <small>{item?.supplier?.company_name}</small> <ShortcutIcon size={14} />
              </Link>
            ) : (
              <Lozenge>Not set</Lozenge>
            ),
          },
          {
            id: `rate${i}`,
            content: item.type === 'main' ? formatMonetaryNumber(item?.rate) : '',
          },
          {
            id: `calculation${i}`,
            content: item?.calculation_method ? (
              <Lozenge appearance="inprogress">
                {findOption(calculationMethodShortLabelOptions, item?.calculation_method)?.label}
              </Lozenge>
            ) : (
              <Lozenge>Not set</Lozenge>
            ),
          },
          {
            id: `quantity${i}`,
            content: formatNumber(item?.quantity) || (
              <Lozenge appearance="default">Not set</Lozenge>
            ),
          },
          {
            id: `cost${i}`,
            content: (
              <div className="text-right" style={{ width: '90px' }}>
                {item?.surcharges?.length ? (
                  <>
                    <button
                      onClick={() => setShowRowSurcharges((index) => (index === i ? '' : i))}
                      className="flex justify-between items-start w-full"
                    >
                      {item?.surcharges?.length ? <ChevronDownIcon /> : null}
                      <span className="text-right">
                        {formatMonetaryNumber(item?.real_cost) || formatMonetaryNumber(item?.cost)}
                        <br />

                        {item?.passive_credit && (
                          <div className="text-xs mt-1">
                            {formatMonetaryNumber(item?.passive_credit)}
                          </div>
                        )}

                        <small className="opacity-80">
                          {item?.real_cost ? formatMonetaryNumber(item?.cost) : ''}
                        </small>
                      </span>
                    </button>

                    {showRowSurcharges === i
                      ? item?.surcharges?.map((surcharge, index) => (
                          <div key={index}>
                            <small className="opacity-80">
                              {`${
                                surcharge?.data?.iata_code ? surcharge?.data?.iata_code + ': ' : ''
                              }${formatMonetaryNumber(surcharge?.cost)}`}
                            </small>
                          </div>
                        ))
                      : null}
                  </>
                ) : (
                  <span className="text-right">
                    {formatMonetaryNumber(item?.real_cost) || formatMonetaryNumber(item?.cost)}
                    <br />

                    {item?.passive_credit && (
                      <div className="text-xs mt-1">
                        {formatMonetaryNumber(item?.passive_credit)}
                      </div>
                    )}

                    <small className="opacity-80">
                      {item?.real_cost ? formatMonetaryNumber(item?.cost) : ''}
                    </small>
                  </span>
                )}

                {(item?.passive_invoices || []).map((invoice) => (
                  <button
                    key={invoice.id_auto}
                    className="px-1.5 mt-2 text-center rounded w-full"
                    style={{ backgroundColor: 'rgba(255, 255, 255, 0.30)' }}
                    onClick={() =>
                      window.open(`/panel/passive-invoices/${invoice.id_auto}`, '_blank')
                    }
                  >
                    <small>{invoice.invoice_number}</small>
                  </button>
                ))}

                {(item?.passive_credit_notes || []).map((invoice) => (
                  <button
                    key={invoice.id_auto}
                    className="px-1.5 mt-2 text-center rounded w-full"
                    style={{ backgroundColor: 'rgba(255, 255, 255, 0.30)' }}
                    onClick={() =>
                      window.open(`/panel/passive-invoices/${invoice.id_auto}`, '_blank')
                    }
                  >
                    <small className="whitespace-nowrap">CN {invoice.invoice_number}</small>
                  </button>
                ))}
              </div>
            ),
          },
          {
            id: `quotation${i}`,
            content: (
              <div className="text-right" style={{ width: '95px' }}>
                {formatMonetaryNumber(item?.revenue) || formatMonetaryNumber(item?.quotation)}
                <br />

                {item?.credit && (
                  <div className="text-xs mt-1">{formatMonetaryNumber(item?.credit)}</div>
                )}

                <small className="opacity-80">
                  {item?.revenue ? formatMonetaryNumber(item?.quotation) : ''}
                </small>
                <br />

                {(item?.active_invoices || []).map((invoice) => (
                  <button
                    key={invoice.id_auto}
                    className="px-1.5 mt-2 text-center rounded w-full"
                    style={{ backgroundColor: 'rgba(255, 255, 255, 0.30)' }}
                    onClick={() =>
                      window.open(`/panel/active-invoices/${invoice.id_auto}`, '_blank')
                    }
                  >
                    <small className="whitespace-nowrap">INV {invoice.invoice_number}</small>
                  </button>
                ))}

                {(item?.credit_notes || []).map((invoice) => (
                  <button
                    key={invoice.id_auto}
                    className="px-1.5 mt-2 text-center rounded w-full"
                    style={{ backgroundColor: 'rgba(255, 255, 255, 0.30)' }}
                    onClick={() =>
                      window.open(`/panel/active-invoices/${invoice.id_auto}`, '_blank')
                    }
                  >
                    <small className="whitespace-nowrap">CN {invoice.invoice_number}</small>
                  </button>
                ))}
              </div>
            ),
          },
        ]

        return {
          key: `packagesRows${i}`,
          cells: shipmentsItems,
          isHighlighted: item?.type === 'main',
        }
      }) || []

    body.push(
      {
        key: `estimated`,
        cells: [
          ...new Array(financeHead.cells?.length - 2 || 0).fill({
            content: '',
          }),
          {
            content: (
              <div className="text-right" style={{ width: '95px' }}>
                <small>Estimated</small> <br />
                {formatMonetaryNumber(estimatedCost)}
              </div>
            ),
          },
          {
            content: (
              <div className="text-right" style={{ width: '95px' }}>
                <small>Estimated</small> <br />
                {formatMonetaryNumber(estimatedQuotation)}
              </div>
            ),
          },
        ],
      },
      {
        key: `final`,
        cells: [
          ...new Array(financeHead.cells?.length - 2 || 0).fill({
            content: '',
          }),
          {
            content: (
              <div className="text-right" style={{ width: '95px' }}>
                <small>Final</small> <br />
                {formatMonetaryNumber(finalCost)}
              </div>
            ),
          },
          {
            content: (
              <div className="text-right" style={{ width: '95px' }}>
                <small>Final</small> <br />
                {formatMonetaryNumber(finalQuotation)}
              </div>
            ),
          },
        ],
      }
    )

    return body
  }, [
    data?.rows,
    estimatedCost,
    estimatedQuotation,
    finalCost,
    finalQuotation,
    financeHead.cells?.length,
    showRowSurcharges,
  ])

  return (
    <>
      {isLoading ? (
        <div className="text-center mt-24">
          <Spinner />
        </div>
      ) : isError ? (
        <SectionMessage appearance="warning" title="Something wrong on loading data, please retry.">
          <Button onClick={() => refetch()}>Retry</Button>
        </SectionMessage>
      ) : financeRows?.length === 0 ? (
        <EmptyState header="No finance details" />
      ) : (
        <div className="overflow-auto inline-block w-full shipmentFinanceAtlaskitTable">
          <DynamicTableStateless head={financeHead} rows={financeRows} isLoading={false} />

          <div className="ml-auto -mt-3 mb-4 text-white" style={{ width: '222px' }}>
            <div className={`p-3 ${+totalEstimated > 0 ? 'bg-green-400' : 'bg-red-300'}`}>
              <small>Total estimated margin</small>
              <br />
              {formatMonetaryNumber(Math.abs(totalEstimated))}
            </div>

            <div className={`p-3 ${totalFinal > 0 ? 'bg-green-400' : 'bg-red-300'}`}>
              <small>Total final margin</small>
              <br />
              {formatMonetaryNumber(Math.abs(totalFinal))}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ShipmentViewFinancePage
