import { useState } from 'react'

// Atlassian
import Modal, { ModalHeader, ModalTitle, ModalTransition } from '@atlaskit/modal-dialog'

// Components
import DigitalSignatureOTPStep from './DigitalSignatureOTPStep'
import DigitalSignatureVerificationStep from './DigitalSignatureVerificationStep'

const DigitalSignatureModal = ({ isOpen, onClose, entityId, contact, onSign, type, purpose }) => {
  const [step, setStep] = useState('otp')
  const [otpSignatureId, setOTPSignatureId] = useState(null)

  const goToVerificationStep = (id) => {
    setOTPSignatureId(id)
    setStep('verification')
  }

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={onClose} shouldCloseOnOverlayClick={false} shouldCloseOnEscapePress={false}>
          <ModalHeader>
            <ModalTitle>Digital signature</ModalTitle>
          </ModalHeader>

          <DigitalSignatureOTPStep
            onCancel={onClose}
            entityId={entityId}
            onSuccess={goToVerificationStep}
            contact={contact}
            className={step !== 'otp' ? 'hidden' : ''}
            type={type}
            purpose={purpose}
          />

          <DigitalSignatureVerificationStep
            onSuccess={onSign}
            onBack={() => setStep('otp')}
            entityId={entityId}
            otpSignatureId={otpSignatureId}
            className={step !== 'verification' ? 'hidden' : ''}
          />
        </Modal>
      )}
    </ModalTransition>
  )
}

export default DigitalSignatureModal
