import { useCallback, useMemo } from 'react'
import { useMediaQuery } from 'react-responsive'

//Atlassian
import Button from '@atlaskit/button'
import { DynamicTableStateless } from '@atlaskit/dynamic-table'
import Drawer from '@atlaskit/drawer'
import Textfield from '@atlaskit/textfield'

//Interfaces
import { Field, useFieldArray, TrashIcon, PlusIcon, Form } from '@royan-co/user-interface'

const ZoneTable = ({ type }) => {
  const {
    fields: zoneFields,
    append: appendZone,
    remove: removeZone,
  } = useFieldArray({ name: `zones` })

  const addZone = useCallback(() => {
    const zone = {
      cap_from: '',
      cap_to: '',
      type: type,
    }

    appendZone(zone)
  }, [appendZone, type])

  const tableRows = useMemo(
    () =>
      zoneFields?.map((zone, index) => ({
        key: zone?.id,
        cells: [
          {
            content: (
              <Field isRequired name={`zones.${index}.cap_from`} className="!mt-0">
                {({ fieldProps }) => <Textfield {...fieldProps} />}
              </Field>
            ),
          },
          {
            content: (
              <Field isRequired name={`zones.${index}.cap_to`} className="!mt-0">
                {({ fieldProps }) => <Textfield {...fieldProps} />}
              </Field>
            ),
          },
          {
            content: (
              <Button spacing="none" appearance="subtle-link" onClick={() => removeZone(index)}>
                <TrashIcon className="text-red-400" />
              </Button>
            ),
          },
        ],
      })),

    [removeZone, zoneFields]
  )

  const tableHead = {
    cells: [{ content: 'Starting zip code' }, { content: 'Ending zip code' }, { content: '' }],
  }

  return (
    <>
      {zoneFields.length > 0 && (
        <div className="overflow-auto inline-block w-full removeAtlaskitTableBorder mt-6 -mb-6">
          <DynamicTableStateless head={tableHead} rows={tableRows} />
        </div>
      )}

      <div className="mt-4">
        <Button appearance="link" iconBefore={<PlusIcon />} onClick={addZone}>
          New zone
        </Button>
      </div>
    </>
  )
}

const RatesZonesDrawer = ({ onClose, isOpen, zones, type, onSubmit }) => {
  const isMobile = useMediaQuery({ query: `(max-width: 767.99px)` })

  return (
    <Drawer isOpen={isOpen} onClose={onClose} width={isMobile ? 'full' : 'medium'}>
      <div className="drawer">
        <h3>
          <span className="capitalize">{type}</span> zones
        </h3>

        <Form onSubmit={onSubmit} defaultValues={{ zones: zones }}>
          {() => (
            <>
              <ZoneTable type={type} />

              <Button type="submit" className="mt-6" shouldFitContainer appearance="primary">
                Save zones
              </Button>
              <Button className="mt-2" shouldFitContainer appearance="subtle" onClick={onClose}>
                Cancel
              </Button>
            </>
          )}
        </Form>
      </div>
    </Drawer>
  )
}

export default RatesZonesDrawer
