import React from 'react'

// Atlassian
import Button from '@atlaskit/button'

// Components
import { AccessControl } from 'features/auth'
import { generateShipmentUserPermissions } from '../Create/shipment-user-permissions'

const AcceptRejectButtons = ({ currentUser, shipment, openAcceptModal, openRejectModal }) => {
  return (
    <>
      <AccessControl
        resource="shipments"
        action="accept"
        userPermissions={generateShipmentUserPermissions(shipment, currentUser)}
      >
        <Button onClick={openAcceptModal} className="!bg-green-50">
          <span className="text-green-500">Accept</span>
        </Button>
      </AccessControl>

      <AccessControl
        resource="shipments"
        action="reject"
        userPermissions={generateShipmentUserPermissions(shipment, currentUser)}
      >
        <Button onClick={openRejectModal} className="!bg-red-50">
          <span className="text-red-500">Reject</span>
        </Button>
      </AccessControl>
    </>
  )
}

export default AcceptRejectButtons
