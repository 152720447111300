import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

// Apis
import { getAccessories } from 'api/shipments'

// Utils
import { findOption, transformSelectField } from 'utils/helpers'
import { handleErrorOnSubmit } from 'utils/errors'

// Context
import { useFinanceEditingContext } from 'contexts/FinanceEditingProvider'

// Hooks
import useShipment from 'hooks/useShipment'
import useConsolidation from 'hooks/useConsolidation'

// Atlassian
import Button from '@atlaskit/button'
import Select from '@atlaskit/select'
import Modal, {
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import { ErrorMessage } from '@atlaskit/form'

// Interfaces
import { Field, Form, WarningBannerField } from '@royan-co/user-interface'
import { calculationMethodOptions } from 'components/Rates/constants'

const EditFinanceAccessoryModal = ({ isOpen, onClose, onSubmit }) => {
  const { entityType, entityId } = useFinanceEditingContext()
  const { shipment, isLoading: isLoadingShipment } = useShipment(entityId, { enabled: isOpen })
  const { consolidation, isLoading: isLoadingConsolidation } = useConsolidation(entityId, {
    enabled: isOpen,
  })
  const entity = entityType === 'shipment' ? shipment : consolidation
  const isLoading = isLoadingShipment || isLoadingConsolidation

  const { isLoading: accessoriesIsLoading, data } = useQuery(
    ['accessories', entityType, entityId],
    () => getAccessories({ mode: entity?.shipment_type, collies: entity?.type_of_collies }),
    {
      staleTime: Infinity,
      retry: 2,
      enabled: !!entity?.id_auto && isOpen,
    }
  )

  const accessoriesOptions = useMemo(() => {
    const options = [
      {
        label: 'Empty row',
        value: 0,
      },
    ]

    data?.accessories?.forEach((accessory, index) => {
      options.push({
        label: `${accessory?.name} - ${
          findOption(calculationMethodOptions, accessory?.calculation_method)?.label
        }`,
        value: index + 1,
      })
    })

    return options
  }, [data])

  const handleCloseModal = () => {
    onClose()
  }

  const handleEntity = ({ accessory }, { setError }) => {
    try {
      let accessoryData = {}

      if (accessory !== 0) {
        const selectedAccessory = data.accessories[accessory - 1]
        const calculationMethod = selectedAccessory.calculation_method
        const quantity =
          calculationMethod === 0
            ? 1
            : calculationMethod === 1
            ? entity?.weight || 1
            : calculationMethod === 2
            ? entity?.chargeable_weight || 1
            : calculationMethod === 5
            ? entity?.total_collies || 1
            : ''
        const price = selectedAccessory.tariff * quantity

        accessoryData = {
          service_code: selectedAccessory.cost_code,
          name: selectedAccessory.name,
          quantity: quantity,
          rate: selectedAccessory.tariff,
          calculation_method: calculationMethod,
          cost: selectedAccessory.no_cost ? 0 : quantity * selectedAccessory.cost || 0,
          quotation:
            price && price < selectedAccessory.minimum
              ? selectedAccessory.minimum
              : price && price > selectedAccessory.maximum
              ? selectedAccessory.maximum
              : price || '',
          vat: '',
        }
      }

      onSubmit(accessoryData)
      handleCloseModal()
    } catch (error) {
      handleErrorOnSubmit(error, setError, data)
    }
  }

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={handleCloseModal} width="small">
          <ModalHeader>
            <ModalTitle>New accessory</ModalTitle>
          </ModalHeader>

          <Form onSubmit={handleEntity}>
            {() => (
              <>
                <div className="px-6">
                  <Field
                    isRequired
                    label="Accessory"
                    name="accessory"
                    transform={transformSelectField(accessoriesOptions)}
                    className="!mt-0"
                  >
                    {({ fieldProps, error }) => (
                      <>
                        <Select
                          options={accessoriesOptions}
                          isLoading={isLoading || accessoriesIsLoading}
                          {...fieldProps}
                        />
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                      </>
                    )}
                  </Field>

                  <WarningBannerField />
                </div>

                <ModalFooter>
                  <Button type="button" appearance="subtle" onClick={handleCloseModal}>
                    Close
                  </Button>
                  <Button type="submit" appearance="primary">
                    Add
                  </Button>
                </ModalFooter>
              </>
            )}
          </Form>
        </Modal>
      )}
    </ModalTransition>
  )
}

export default EditFinanceAccessoryModal
