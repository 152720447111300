import { cloneElement, useMemo, useState } from 'react'

import './InlineEditable.scss'

const InlineEditableSelect = ({
  selectComponent,
  value,
  onChange,
  readView = null,
  disabled = false,
}) => {
  const [isEditing, setIsEditing] = useState(false)

  const SelectComponent = useMemo(() => {
    return cloneElement(selectComponent, {
      value,
      autoFocus: true,
      spacing: 'compact',
      openMenuOnFocus: true,
      menuPortalTarget: document.body,
      classNamePrefix: 'inline-editable',
      onChange: (v) => {
        onChange?.(v)
        setIsEditing(false)
      },
      onBlur: () => {
        setIsEditing(false)
      },
    })
  }, [selectComponent, onChange, value])

  return (
    <div>
      {isEditing ? (
        <div className="inline-editable__edit-view">{SelectComponent}</div>
      ) : (
        <div
          className={`inline-editable__read-view ${disabled ? '--disabled' : ''}`}
          onClick={() => disabled !== true && setIsEditing(true)}
        >
          {readView}
        </div>
      )}
    </div>
  )
}

export default InlineEditableSelect
