import axios from 'services/Request'

export function getTableRates(params) {
  return axios.get('/api/table-rates', { params })
}

export function getRate(id) {
  return axios.get(`/api/table-rates/fetch/${id}`)
}

export function deleteRate(rateId) {
  return axios.delete(`/api/table-rates/${rateId}`)
}

export function duplicateRate(rateId) {
  return axios.get(`/api/table-rates/duplicate/${rateId}`)
}

export function getRecordsRate(rateId) {
  return axios.get(`/api/table-rates/rows/${rateId}`)
}

export function deleteRecordRate(recordId) {
  return axios.delete(`/api/table-rates/rows/${recordId}`)
}

export function createRecordRate(rateId, params) {
  return axios.post(`/api/table-rates/rows/create/${rateId}`, params)
}

export function editRecordRate(recordId, params) {
  return axios.post(`/api/table-rates/rows/edit/${recordId}`, params)
}

export function getSurchargesRate(rateId) {
  return axios.get(`/api/table-rates/surcharges/${rateId}`)
}

export function getSurchargeEnumeration(params) {
  return axios.get('/api/surcharges', { params })
}

export function createSurcharge(rateId, params) {
  return axios.post(`/api/table-rates/surcharges/create/${rateId}`, params)
}

export function editSurcharge(surchargeId, params) {
  return axios.post(`/api/table-rates/surcharges/edit/${surchargeId}`, params)
}

export function deleteSurcharge(surchargeId) {
  return axios.get(`/api/table-rates/surcharges/delete/${surchargeId}`)
}

export async function createRate(data) {
  return await axios.post('/api/table-rates/create', data)
}

export async function editRate(id, data) {
  return await axios.post(`/api/table-rates/edit/${id}`, data)
}

export async function exportRate(id) {
  return await axios.get(`/api/table-rates/export/${id}`)
}
