export const statusOptions = [
  { label: 'To be proceeded', value: 0, color: 'standard' },
  { label: 'In transit', value: 1, color: 'standard' },
  { label: 'Trip finished', value: 2, color: 'green' },
]

export const vehicleTypeOptions = [
  { label: 'Full truck', value: 0 },
  { label: 'Full container', value: 1 },
]

export const localisationStatusLoadedOnBoatOptions = [
  { label: 'The vehicle is on ferry boat', value: 'Goods on ferry boat' },
  { label: 'Vehicle is disembarking from ferry boat', value: 'Unloading from ferry boat' },
]

export const localisationStatusNotLoadedOnBoatOptions = [
  {
    label: 'The vehicle is about to be loaded onto the ferry boat',
    value: 'Loading on ferry boat',
  },
  { label: 'On the road', value: 'On the road' },
  { label: 'Stopped - Waiting for Custom clearance', value: 'Stop - Waiting for Custom clearance' },
  { label: 'Stopped - Arrived to customs warehouse', value: 'Stop - Arrived to customs warehouse' },
  { label: 'Stop - Offloading at customs', value: 'Stop - Offloading at customs' },
  { label: 'Stopped - Other Reason', value: 'Stop - Other Reason' },
  { label: 'Cargo delivered, Arrived to final destination', value: 'Trip Finished' },
]

export const manifestTypes = [
  { key: 'loading', label: 'Loading manifest' },
  {
    key: 'cargo',
    label: 'Cargo manifest',
  },
  {
    key: 'air',
    label: 'Air manifest',
  },
]

export const gpsTrackerOptions = [
  { label: 'Yes', value: 1, appearance: 'success' },
  { label: 'No', value: 0, appearance: 'removed' },
]

export const documentTypeOptions = [
  { label: 'CMR', value: 3, key: 'cmr' },
  // { label: 'Manifest', value: 4, key: 'manifest' },
]

export const trackingUnitTypeOptions = [
  { label: 'Tzone', value: 3 },
  { label: 'Arvento', value: 2 },
]
