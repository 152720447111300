import Lozenge from '@atlaskit/lozenge'
import { findOption } from 'utils/helpers'
import { registryTypeOptions, registryClientTypeOptions } from './constants'

const RegistryTypeTag = ({ type, clientType }) => {
  const option = findOption(registryTypeOptions, type)
  const clientOption = findOption(registryClientTypeOptions, clientType)

  return option ? (
    <Lozenge appearance={option.appearance}>
      {option.label}
      {type === 0 && clientOption && ` - ${clientOption.label}`}
    </Lozenge>
  ) : null
}

export default RegistryTypeTag
