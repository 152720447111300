import InvoicePaidTag from 'components/Invoices/PaidTag'
import InvoiceTypeTag from 'components/Invoices/TypeTag'
import CollapsedWrapper from 'components/UI/CollapsedWrapper'
import TagLink from 'components/UI/TagLink'

import { formatMonetaryNumber } from 'utils/numbers'

export const registryInvoicesColumns = [
  {
    key: 'invoice_number',
    title: 'Incoice no',
    isSortable: false,
    show: true,
    content: (item) => item.invoice_number,
  },
  {
    key: 'do_boolean',
    title: 'Type',
    isSortable: false,
    show: true,
    content: (item) => (
      <InvoiceTypeTag value={item.do_boolean} activePassive={item?.active_passive} />
    ),
  },
  {
    key: 'shipments',
    title: 'Shipment/Consolidation reference',
    isSortable: false,
    show: true,
    content: (item) => (
      <CollapsedWrapper
        items={item.parents.map((item, index) => {
          if (item.resource === 'shipment') {
            return (
              <TagLink
                key={index}
                target="_blank"
                isRemovable={false}
                text={item.reference}
                to={`/panel/shipments/${item.id}`}
              />
            )
          } else if (item.resource === 'consolidation') {
            return (
              <TagLink
                key={index}
                target="_blank"
                isRemovable={false}
                text={item.reference}
                to={`/panel/consolidations/${item.id}`}
              />
            )
          } else return ''
        })}
        maxItems={1}
      />
    ),
  },
  {
    key: 'total_amount',
    title: 'Amount',
    isSortable: false,
    show: true,
    content: (item) => formatMonetaryNumber(item.total_amount || 0),
  },
  {
    key: 'total_paid',
    title: 'Total paid',
    isSortable: false,
    show: true,
    content: (item) => formatMonetaryNumber(item.tot_payd || 0)
  },
  {
    key: 'is_payd',
    title: 'Paid',
    isSortable: false,
    show: true,
    content: (item) => <InvoicePaidTag value={item.is_payd} />,
  },
  {
    key: 'invoice_date',
    title: 'Issue date',
    isSortable: true,
    show: true,
    content: (item) => item.invoice_date,
  },
]
