import { findOption } from 'utils/helpers'
import { shipmentTypeOptions } from './constants'

//Atlassian
import Lozenge from '@atlaskit/lozenge'

const ShipmentTypeTag = ({ type }) => {
  const option = findOption(shipmentTypeOptions, type)
  return option ? <Lozenge appearance={option.appearance}>{option.label}</Lozenge> : null
}

export default ShipmentTypeTag
