import { forwardRef, useState } from 'react'

// Utils
import { invoicePaidOptions } from './constants'
import { findOption } from 'utils/helpers'

// Atlassian
import Select from '@atlaskit/select'

const InvoicePaidSelect = forwardRef(({ defaultValue, onChange, value, ...fieldProps }, ref) => {
  const [uncontrolledValue, setUncontrolledValue] = useState(
    defaultValue
      ?.toString()
      ?.split(',')
      ?.map((i) => findOption(invoicePaidOptions, +i)) || []
  )

  const handleOnChange = (e) => {
    setUncontrolledValue(e)
    onChange(e.map((i) => i.value).join(','))
  }

  return (
    <Select
      ref={ref}
      isMulti
      value={uncontrolledValue}
      isClearable={true}
      options={invoicePaidOptions}
      placeholder="Choose one"
      onChange={handleOnChange}
      {...fieldProps}
    />
  )
})

export default InvoicePaidSelect
