import { Fragment } from 'react'

// Atlassian
import QuestionIcon from '@atlaskit/icon/glyph/question'

// Interfaces
import { AddIcon } from '@royan-co/user-interface'

// Styles
import styles from './StepLocationIndicator.module.scss'

// Components
import StepIcon from './StepIcon'
import StepIndicator from './StepIndicator'

const StepLocationIndicator = ({ steps, currentStepIndex, addStep, onClickStep, goToLeg }) => {
  return (
    <div className={styles.progressIndicator}>
      {steps?.length > 0 ? (
        <>
          {steps.map((step, i) => (
            <Fragment key={i}>
              <StepIndicator
                onLineSelect={() => goToLeg(i)}
                className={`${i === currentStepIndex ? styles.active : ''} ${
                  step.hasError ? styles.withError : ''
                }`}
              >
                <StepIcon
                  icon={step.departure.icon}
                  isActive={[
                    currentStepIndex,
                    !steps?.[i - 1]?.hasInvisibleLeg ? currentStepIndex + 1 : null,
                  ].includes(i)}
                  hasError={
                    step.hasError || (steps?.[i - 1]?.hasError && !steps?.[i - 1]?.hasInvisibleLeg)
                  }
                  onClick={() => onClickStep(step, i)}
                />
              </StepIndicator>

              {step?.hasInvisibleLeg && (
                <StepIndicator className={styles.invisibleLeg} hasInvisibleLeg>
                  <StepIcon
                    icon={step.arrival.icon}
                    isActive={currentStepIndex === i}
                    hasError={step.hasError}
                    onClick={() => onClickStep(step, i, 'arrivalNode')}
                  />
                </StepIndicator>
              )}
            </Fragment>
          ))}

          <StepIndicator lastIndex>
            <StepIcon
              hasError={steps[steps.length - 1].hasError}
              icon={steps[steps.length - 1].arrival.icon}
              isActive={currentStepIndex === steps.length - 1}
              onClick={() => onClickStep(steps[steps.length - 1], steps.length - 1, 'arrivalNode')}
            />
          </StepIndicator>
        </>
      ) : (
        <StepIndicator>
          <div children={<QuestionIcon />} className={styles.progressIndicator__icon} />
        </StepIndicator>
      )}

      {addStep && (
        <>
          <StepIcon icon={<AddIcon />} onClick={addStep} />
        </>
      )}
    </div>
  )
}

export default StepLocationIndicator
