import { useState, useEffect, useCallback, useMemo } from 'react'

// Utils
import { formatNumber } from 'utils/numbers'

// Atlassian
import Lozenge from '@atlaskit/lozenge'
import { DynamicTableStateless } from '@atlaskit/dynamic-table'
import { Checkbox } from '@atlaskit/checkbox'

// Interfaces
import { Field } from '@royan-co/user-interface'

// Components
import Card from 'components/UI/Card'

const ShipmentPickupFormCargo = ({ defaultSelectedCargoes, cargoesList, isLoading }) => {
  const [cargoes, setCargoes] = useState([])

  const onChangeCheckBox = useCallback(
    (idOfChangedCargo) => {
      setCargoes(
        cargoes?.map((cargo) =>
          idOfChangedCargo === cargo.id ? { ...cargo, checked: !cargo.checked } : cargo
        )
      )
    },
    [cargoes]
  )

  const selectedCargoes = useMemo(() => {
    if (cargoes?.length > 0) return cargoes?.filter((c) => c?.checked === true)
    else return cargoesList
  }, [cargoes, cargoesList])

  useEffect(() => {
    setCargoes(
      cargoesList?.map((cargo) => {
        if (defaultSelectedCargoes) {
          return { ...cargo, checked: defaultSelectedCargoes?.includes(cargo.id) }
        } else return { ...cargo, checked: true }
      })
    )
  }, [defaultSelectedCargoes, cargoesList])

  const packagesHead = {
    cells: [
      { key: 'check', content: '', width: '2' },
      { key: 'type', content: 'Type' },
      { key: 'quantity', content: 'Quantity' },
      { key: 'dimensions', content: 'Dimensions (W x L x H)' },
      { key: 'weight', content: 'Weight (Kgs)' },
      { key: 'ldm', content: 'LDM' },
      { key: 'cbm', content: 'CBM' },
      { key: 'Stackable', content: 'Stackable' },
    ],
  }

  const packages = useMemo(() => {
    const body =
      cargoesList?.map((item, i) => ({
        key: `packagesRows${i}`,
        cells: [
          {
            id: `check${i}`,
            content: (
              <Field
                type="checkbox"
                name={`cargoes[${i}]`}
                defaultValue={
                  defaultSelectedCargoes ? defaultSelectedCargoes?.includes(item?.id) : true
                }
                className="!mt-0 !cursor-pointer"
                onValueChange={() => onChangeCheckBox(item.id)}
              >
                {({ fieldProps }) => <Checkbox {...fieldProps} />}
              </Field>
            ),
          },
          {
            id: `type${i}`,
            content: item?.box_type || <Lozenge appearance="default">Not set</Lozenge>,
          },
          {
            id: `quantity${i}`,
            content: item?.number ? item?.number : <Lozenge appearance="default">Not set</Lozenge>,
          },
          {
            id: `dimensions${i}`,
            content: (
              <div className="whitespace-nowrap">
                {item.width || item.height || item.length ? (
                  <span>{`${formatNumber(item.width) || '-'} cm x ${
                    formatNumber(item.length) || '-'
                  } cm x ${formatNumber(item.height) || '-'} cm `}</span>
                ) : (
                  <Lozenge appearance="default">Not set</Lozenge>
                )}
              </div>
            ),
          },
          {
            id: `weight${i}`,
            content: item.weight ? (
              <span className="whitespace-nowrap">{formatNumber(item.weight)}</span>
            ) : (
              <Lozenge appearance="default">Not set</Lozenge>
            ),
          },
          {
            id: `ldm${i}`,
            content: item.ldm ? (
              <span>{formatNumber(item.ldm, 3)}</span>
            ) : (
              <Lozenge appearance="default">Not set</Lozenge>
            ),
          },
          {
            id: `cbm${i}`,
            content: item.cbm ? (
              <span>{formatNumber(item.cbm, 3)}</span>
            ) : (
              <Lozenge appearance="default">Not set</Lozenge>
            ),
          },
          {
            id: `stackable${i}`,
            content: item.stackable ? (
              <Lozenge appearance="success">Yes</Lozenge>
            ) : (
              <Lozenge appearance="moved">No</Lozenge>
            ),
          },
        ],
      })) || []

    body.push({
      key: `packagesRows-total`,
      cells: [
        {
          content: '',
        },
        {
          content: <strong>Total</strong>,
        },
        {
          content: selectedCargoes?.reduce((p, c) => p + c.number * 1, 0) || 0,
        },
        {
          content: '',
        },
        {
          content: formatNumber(
            selectedCargoes?.reduce((p, c) => p + parseFloat(c?.weight || 0), 0)
          ),
        },
        {
          content: formatNumber(
            selectedCargoes?.reduce((p, c) => p + parseFloat(c?.ldm || 0), 0),
            3
          ),
        },
        {
          content: formatNumber(
            selectedCargoes?.reduce((p, c) => p + parseFloat(c?.cbm || 0), 0),
            3
          ),
        },
        {
          content: '',
        },
      ],
    })

    return body
  }, [defaultSelectedCargoes, onChangeCheckBox, selectedCargoes, cargoesList])

  return (
    <Card className="mt-6" title="Cargo details">
      <div className="overflow-auto inline-block w-full -mb-6 borderOfAtlaskitTableWithTotalRow">
        <DynamicTableStateless head={packagesHead} rows={packages} isLoading={isLoading} />
      </div>
    </Card>
  )
}

export default ShipmentPickupFormCargo
