import React from 'react'
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

// Apis
import { acceptQuotation } from 'api/shipments'

// Hooks
import useShipment from 'hooks/useShipment'

// Atlassian
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import Button, { LoadingButton } from '@atlaskit/button'

// Interfaces
import { Form, CheckCircleIcon, WarningBanner, useFlags } from '@royan-co/user-interface'

const AcceptModal = ({ isOpen, onClose, quotationId }) => {
  const navigate = useNavigate()
  const { showSuccessFlag } = useFlags()
  const { upsertShipmentData } = useShipment(quotationId)

  const acceptMutation = useMutation(acceptQuotation)
  const closeModal = () => {
    acceptMutation.reset()
    onClose()
  }

  const acceptQuotationHandler = () => {
    acceptMutation.mutate(quotationId, {
      onError: (e) => {
        console.error(e)
      },
      onSuccess: (res) => {
        showSuccessFlag(res?.message)
        upsertShipmentData(res?.shipment)
        setTimeout(() => {
          navigate(`/panel/shipments/${quotationId}`)
        }, 100)
      },
    })
  }

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={closeModal}>
          <Form onSubmit={acceptQuotationHandler}>
            {() => (
              <>
                <ModalHeader>
                  <ModalTitle>
                    <CheckCircleIcon className="!text-green-500" />
                    <span className="mx-2">Accept quotation</span>
                  </ModalTitle>
                </ModalHeader>
                <ModalBody>
                  <span>
                    You are about to accept this inquiry. Please check the terms of your quotation
                    once more.
                  </span>
                  {acceptMutation.isError && <WarningBanner />}
                </ModalBody>

                <ModalFooter>
                  <Button onClick={closeModal} appearance="subtle">
                    Cancel
                  </Button>
                  {!acceptMutation.isSuccess ? (
                    <LoadingButton
                      isDisabled={acceptMutation.isSuccess}
                      isLoading={acceptMutation.isLoading}
                      type="submit"
                      autoFocus
                      className="!bg-green-50"
                    >
                      Accept
                    </LoadingButton>
                  ) : null}
                </ModalFooter>
              </>
            )}
          </Form>
        </Modal>
      )}
    </ModalTransition>
  )
}

export default AcceptModal
