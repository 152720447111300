import { useMediaQuery } from 'react-responsive'
import { transformSelectField } from 'utils/helpers'
import {
  registryClassificationOptions,
  registryClientTypeOptions,
  registryEntityTypeOptions,
  registryFreightForwarderOptions,
  registryTypeOptions,
} from '../constants'

//Atlassian
import { LoadingButton } from '@atlaskit/button'
import Drawer from '@atlaskit/drawer'
import { ErrorMessage } from '@atlaskit/form'
import Select from '@atlaskit/select'
import Textfield from '@atlaskit/textfield'

//Interfaces
import { Field, Form, WarningBannerField } from '@royan-co/user-interface'

const RegistryDrawer = ({ onClose, isOpen, data, onSubmit, isSubmitting = false }) => {
  const isMobile = useMediaQuery({ query: `(max-width: 991.98px)` })

  const submitData = (data, { setError }) => {
    const formData = { ...data }
    if (data.type !== 0) delete formData.client_type
    onSubmit(formData, { setError })
  }

  return (
    <Drawer isOpen={isOpen} onClose={onClose} width={isMobile ? 'full' : 'medium'}>
      <div className="drawer">
        <h3>New Registry</h3>

        <Form onSubmit={submitData} defaultValues={data}>
          {({ watch }) => (
            <>
              <Field isRequired label="Name" defaultValue="" className="!mt-4" name="company_name">
                {({ fieldProps, error }) => (
                  <>
                    <Textfield placeholder="Type a name" {...fieldProps} />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>

              <Field
                isRequired
                label="Type"
                type="select"
                name="type"
                className="!mt-4"
                transform={transformSelectField(registryTypeOptions)}
              >
                {({ fieldProps }) => (
                  <Select
                    isClearable={true}
                    options={registryTypeOptions}
                    placeholder="Choose a type"
                    {...fieldProps}
                  />
                )}
              </Field>

              {watch('type') === 0 && (
                <Field
                  isRequired
                  type="select"
                  label="Client type"
                  name="client_type"
                  className="!mt-4"
                  transform={transformSelectField(registryClientTypeOptions)}
                >
                  {({ fieldProps }) => (
                    <Select
                      isClearable={true}
                      options={registryClientTypeOptions}
                      placeholder="Choose a clinet type"
                      {...fieldProps}
                    />
                  )}
                </Field>
              )}

              <Field
                isRequired
                type="select"
                className="!mt-4"
                name="entity_type"
                label="Entity type"
                transform={transformSelectField(registryEntityTypeOptions)}
              >
                {({ fieldProps, error }) => (
                  <>
                    <Select
                      options={registryEntityTypeOptions}
                      placeholder="Choose"
                      {...fieldProps}
                    />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>

              <Field
                isRequired
                type="select"
                className="!mt-4"
                name="list_type"
                label="Classification"
                transform={transformSelectField(registryClassificationOptions)}
              >
                {({ fieldProps, error }) => (
                  <>
                    <Select
                      options={registryClassificationOptions}
                      placeholder="Choose"
                      {...fieldProps}
                    />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>

              <Field
                isRequired
                type="select"
                className="!mt-4"
                name="freight_forwarder"
                label="Freight forwarder"
                transform={transformSelectField(registryFreightForwarderOptions)}
              >
                {({ fieldProps, error }) => (
                  <>
                    <Select
                      options={registryFreightForwarderOptions}
                      placeholder="Choose"
                      {...fieldProps}
                    />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>

              <WarningBannerField />

              <LoadingButton
                type="submit"
                className="mt-6"
                shouldFitContainer
                appearance="primary"
                isLoading={isSubmitting}
              >
                save
              </LoadingButton>
            </>
          )}
        </Form>
      </div>
    </Drawer>
  )
}

export default RegistryDrawer
