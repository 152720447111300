import React from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { deleteRegistryDocument } from 'api/registries'

//Atlassian
import Modal, {
  ModalBody,
  ModalTitle,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import Button, { LoadingButton } from '@atlaskit/button'

//Interfaces
import { useFlags, WarningBanner } from '@royan-co/user-interface'

const RegistriesDeleteDocumentModal = ({ document, registryId, isOpen, onClose }) => {
  const { showSuccessFlag } = useFlags()
  const queryClient = useQueryClient()

  const DocumentDeleteMutation = useMutation(deleteRegistryDocument)

  const handleDeleteDocument = () => {
    DocumentDeleteMutation.mutate(document.id, {
      onSuccess: (res) => {
        queryClient.setQueryData(['registry-documents', registryId], (docs) =>
          docs.filter((d) => d.id !== document.id)
        )
        showSuccessFlag(res?.message)
        onClose?.()
      },
    })
  }

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={onClose}>
          <ModalHeader>
            <ModalTitle appearance="danger">Delete document</ModalTitle>
          </ModalHeader>
          <ModalBody>
            You are about to delete a document. Are you sure?
            {DocumentDeleteMutation.isError && <WarningBanner />}
          </ModalBody>
          <ModalFooter>
            <Button appearance="subtle" onClick={onClose}>
              Cancel
            </Button>
            <LoadingButton
              isLoading={DocumentDeleteMutation.isLoading}
              appearance="danger"
              onClick={handleDeleteDocument}
              autoFocus
            >
              Delete
            </LoadingButton>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  )
}

export default RegistriesDeleteDocumentModal
