import { useCallback, useMemo } from 'react'

// Interfaces
import { Field, TrashIcon, useFieldArray, useFormContext } from '@royan-co/user-interface'

// Atlassian
import Button from '@atlaskit/button'
import { CountrySelect } from '@atlaskit/select'
import { ErrorMessage } from '@atlaskit/form'
import { DynamicTableStateless } from '@atlaskit/dynamic-table'
import { allCountries as countries } from '/node_modules/@atlaskit/select/dist/es2019/data/countries.js'

// Components
import Card, { CardTitle } from 'components/UI/Card'

const CreationRoutesForm = () => {
  const { formState } = useFormContext()
  const {
    fields: routesFields,
    append: appendRoute,
    remove: removeRoute,
  } = useFieldArray({
    name: 'routes',
    rules: { validate: (data) => (data?.length === 0 ? `Routes are required` : true) },
  })

  const addRoute = useCallback(() => {
    const route = {
      arrival_country: '',
      departure_country: '',
    }

    appendRoute(route)
  }, [appendRoute])

  const tableHead = {
    cells: [
      { content: 'Departure country', width: '48' },
      { content: 'Arrival country', width: '48' },
      { content: '', width: '4' },
    ],
  }

  const tableRows = useMemo(
    () =>
      routesFields.map((_, index) => ({
        cells: [
          {
            content: (
              <Field
                isRequired
                type="select"
                name={`routes.${index}.departure_country`}
                transform={{
                  output: (c) => (c?.abbr !== undefined ? c.abbr : null),
                  input: (v) => countries?.find((c) => c.abbr === v),
                }}
                className="!mt-0 !w-100"
              >
                {({ fieldProps: { ref, ...fieldProps }, error }) => (
                  <>
                    <CountrySelect
                      isClearable
                      placeholder="Choose"
                      className="!w-100"
                      menuPortalTarget={document.body}
                      {...fieldProps}
                    />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>
            ),
          },
          {
            content: (
              <Field
                isRequired
                type="select"
                name={`routes.${index}.arrival_country`}
                transform={{
                  output: (c) => (c?.abbr !== undefined ? c.abbr : null),
                  input: (v) => countries?.find((c) => c.abbr === v),
                }}
                className="!mt-0 !w-100"
              >
                {({ fieldProps: { ref, ...fieldProps }, error }) => (
                  <>
                    <CountrySelect
                      isClearable
                      className="!w-100"
                      placeholder="Choose"
                      menuPortalTarget={document.body}
                      {...fieldProps}
                    />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>
            ),
          },
          {
            content: (
              <Button spacing="none" appearance="subtle-link" onClick={() => removeRoute(index)}>
                <TrashIcon className="text-red-400" />
              </Button>
            ),
          },
        ],
      })),
    [removeRoute, routesFields]
  )

  return (
    <div className="my-6">
      <div className="flex items-center">
        <CardTitle className="mb-3">Routes</CardTitle>

        <Button appearance="link" spacing="none" className="ml-auto" onClick={addRoute}>
          New route
        </Button>
      </div>

      <Card className="mb-0">
        {tableRows.length === 0 ? (
          <div className="text-xs text-neutral-200">Click on New route to create one.</div>
        ) : (
          <div className="inline-block w-full overflow-auto -mb-6 removeAtlaskitTableBorder">
            <DynamicTableStateless head={tableHead} rows={tableRows} />
          </div>
        )}
      </Card>
      {formState.errors?.routes?.root && (
        <ErrorMessage>{formState.errors.routes.root.message}</ErrorMessage>
      )}
    </div>
  )
}

export default CreationRoutesForm
