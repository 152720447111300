import { useMemo } from 'react'

// Utils
import { formatMonetaryNumber } from 'utils/numbers'

// Atlassian
import Heading from '@atlaskit/heading'
import { ArrowDownIcon, ArrowUpIcon } from '@royan-co/user-interface'

// Components
import CardDivider from './CardDivider'

const getColor = (value) =>
  value > 0 ? 'text-green-500' : value === 0 ? 'text-neutral-500' : 'text-red-500'

const InvoiceCard = ({ activeInvoices, passiveInvoices }) => {
  const margin = useMemo(() => activeInvoices - passiveInvoices, [activeInvoices, passiveInvoices])

  return (
    <div className="shadow rounded">
      <div className={`p-4 ${getColor(margin)}`}>
        <Heading level="h800">
          <span className={getColor(margin)}>{formatMonetaryNumber(Math.abs(margin))} €</span>
        </Heading>
        <p className="mt-1">Margin</p>
      </div>

      <CardDivider />

      <div className={`p-4 flex justify-between gap-1 items-center text-green-500`}>
        <div>
          <Heading level="h600">
            <span className="text-green-500">{formatMonetaryNumber(activeInvoices)} €</span>
          </Heading>
          <p className="mt-1">Active invoices</p>
        </div>

        {!!activeInvoices && <ArrowUpIcon />}
      </div>

      <CardDivider />

      <div className={`p-4 flex justify-between gap-1 items-center text-red-500`}>
        <div>
          <Heading level="h600">
            <span className="text-red-500">{formatMonetaryNumber(passiveInvoices)} €</span>
          </Heading>
          <p className="mt-1">Passive invoices</p>
        </div>

        {!!passiveInvoices && <ArrowDownIcon />}
      </div>
    </div>
  )
}

export default InvoiceCard
