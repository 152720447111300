import { useLocation } from 'react-router'

// Hooks
import useShipmentsAgents from 'hooks/useShipmentsAgents'
import useShipmentsHubs from 'hooks/useShipmentsHubs'
import useShipmentsOperators from 'hooks/useShipmentsOperators'

// Utils
import { findOption } from 'utils/helpers'
import {
  modeOptions,
  shipmentOwnershipOptions,
  shipmentTypeOptions,
  shipmentStatusOptions,
  shipmentInvoicedOptions,
  shipmentQuotationSentOptions,
  shipmentTemperatureOptions,
  containerTypes,
  truckTypes,
  shipmentCustomDocumentOptions,
  shipmentDOOptions,
  shipmentHubEnteredOptions,
  dangerousCargoOptions,
  quotationStatusOptions,
  shipmentEntranceImageOptions,
} from '../constants'

// Atlassian
import Spinner from '@atlaskit/spinner'

const AgentsFilter = ({ value }) => {
  const { shipmentsAgentsOptions, isLoadingShipmentsAgents } = useShipmentsAgents()

  return (
    <>
      agent:{' '}
      {isLoadingShipmentsAgents ? (
        <Spinner size="xsmall" />
      ) : (
        findOption(shipmentsAgentsOptions, value)?.label
      )}
    </>
  )
}

const HubsFilter = ({ value }) => {
  const { shipmentsHubsOptions, isLoadingShipmentsHubs } = useShipmentsHubs()

  return (
    <>
      Hub:{' '}
      {isLoadingShipmentsHubs ? (
        <Spinner size="xsmall" />
      ) : (
        findOption(shipmentsHubsOptions, value)?.label
      )}
    </>
  )
}

const OperatorsFilter = ({ value }) => {
  const { shipmentsOperatorsOptions, isLoadingShipmentsOperators } = useShipmentsOperators()

  return (
    <>
      Operator:{' '}
      {isLoadingShipmentsOperators ? (
        <Spinner size="xsmall" />
      ) : (
        findOption(shipmentsOperatorsOptions, value)?.label
      )}
    </>
  )
}

const CreatorsFilter = ({ value }) => {
  const { shipmentsOperatorsOptions, isLoadingShipmentsOperators } = useShipmentsOperators()

  return (
    <>
      Creator:{' '}
      {isLoadingShipmentsOperators ? (
        <Spinner size="xsmall" />
      ) : (
        findOption(shipmentsOperatorsOptions, value)?.label
      )}
    </>
  )
}

const StatusFilter = ({ value }) => {
  const { pathname } = useLocation()
  const isShipment = !!(
    pathname.substring(pathname.indexOf('/panel/') + 7, 7 + 'shipments'.length) === 'shipments'
  )

  return (
    <>
      Status:{' '}
      {findOption(isShipment ? shipmentStatusOptions : quotationStatusOptions, value)?.label}
    </>
  )
}

export const shipmentsFilters = [
  {
    key: 'shared',
    content: (v) => `Ownership: ${findOption(shipmentOwnershipOptions, v)?.label}`,
  },
  {
    key: 'shipment_type',
    content: (v) => `Mode: ${findOption(modeOptions, v)?.label}`,
  },
  {
    key: 'type_of_collies',
    content: (v, { shipment_type }) =>
      `Required service: ${
        findOption(shipment_type === 1 ? containerTypes : truckTypes, v)?.label
      }`,
  },
  {
    key: 'is_master',
    content: (v) => `Type: ${findOption(shipmentTypeOptions, v)?.label}`,
  },
  {
    key: 'status',
    content: (v) => <StatusFilter value={v} />,
  },
  {
    key: 'has_entrance_image',
    content: (v) => `Entrance image: ${findOption(shipmentEntranceImageOptions, v)?.label}`,
  },
  {
    key: 'created_from',
    content: (v) => `From Date: ${v}`,
  },
  {
    key: 'created_to',
    content: (v) => `To Date: ${v}`,
  },
  {
    key: 'departure_country',
    content: (v) => `Departure country: ${v}`,
  },
  {
    key: 'departure_city',
    content: (v) => `Departure city: ${v}`,
  },
  {
    key: 'departure_cap',
    content: (v) => `Departure Zip code: ${v}`,
  },
  {
    key: 'arrival_country',
    content: (v) => `Arrival country: ${v}`,
  },
  {
    key: 'arrival_city',
    content: (v) => `Arrival city: ${v}`,
  },
  {
    key: 'arrival_cap',
    content: (v) => `Arrival Zip code: ${v}`,
  },
  {
    key: 'operator',
    content: (v) => <OperatorsFilter value={v} />,
  },
  {
    key: 'creator',
    content: (v) => <CreatorsFilter value={v} />,
  },
  {
    key: 'invoiced_a',
    content: (v) => `Invoice: ${findOption(shipmentInvoicedOptions, v)?.label}`,
  },
  {
    key: 'quotation_sent',
    content: (v) => `Quotation sent: ${findOption(shipmentQuotationSentOptions, v)?.label}`,
  },
  {
    key: 'agent',
    content: (v) => <AgentsFilter value={v} />,
  },
  {
    key: 'consignee',
    content: (v) => `Consignee: ${v}`,
  },
  {
    key: 'client',
    content: (v) => `Client: ${v}`,
  },
  {
    key: 'shipper',
    content: (v) => `Shipper: ${v}`,
  },
  {
    key: 'custom_document_flag',
    content: (v) => `Customs document: ${findOption(shipmentCustomDocumentOptions, v)?.label}`,
  },
  {
    key: 'collies',
    content: (v) => `No. of collies: ${v}`,
  },
  {
    key: 'weight',
    content: (v) => `Weight: ${v} Kgs`,
  },
  {
    key: 'reefer',
    content: (v) => `Temperature Control: ${findOption(shipmentTemperatureOptions, v)?.label}`,
  },
  {
    key: 'adr_goods',
    content: (v) => `Dangerous Cargo: ${findOption(dangerousCargoOptions, v)?.label}`,
  },
  {
    key: 'waybill',
    content: (v) => `AWB: ${v}`,
  },
  {
    key: 'means_code',
    content: (v) => `Plate/Container number: ${v}`,
  },
  {
    key: 'released',
    content: (v) => `D/O status: ${findOption(shipmentDOOptions, v)?.label}`,
  },
  {
    key: 'entrance_number',
    content: (v) => `Entrance number: ${v} `,
  },
  {
    key: 'entrance_date',
    content: (v) => `Entrance date: ${v}`,
  },
  {
    key: 'has_entrance',
    content: (v) => `Entered in hub: ${findOption(shipmentHubEnteredOptions, v)?.label}`,
  },
  {
    key: 'hub',
    content: (v) => <HubsFilter value={v} />,
  },
]
