import { formatMonetaryNumber } from 'utils/numbers'

//Atlassian
import Lozenge from '@atlaskit/lozenge'

const RateCostFormat = ({ value }) => {
  return (
    <>
      {value ? (
        `${formatMonetaryNumber(value)} EUR`
      ) : (
        <Lozenge appearance="default">Not set</Lozenge>
      )}
    </>
  )
}

export default RateCostFormat
