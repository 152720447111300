import React, { useCallback, useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import Tabs, { Tab, TabList } from '@atlaskit/tabs'
import { Link, useLocation, useParams, Outlet, useNavigate } from 'react-router-dom'

//Hooks
import { useInvoice } from '../api/getInvoice'

// Utils
import { invoiceTypes } from '../utils/invoice-types'

//Icons
import { Skeleton } from '@royan-co/user-interface'

//Atlassian
import Spinner from '@atlaskit/spinner'
import PageHeader from '@atlaskit/page-header'
import Button, { ButtonGroup } from '@atlaskit/button'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'
import SectionMessage from '@atlaskit/section-message'
import DropdownMenu, { DropdownItemGroup } from '@atlaskit/dropdown-menu'

//Interfaces
import { useRoyanTableLastFilters, DotsIcon, useDisclosure } from '@royan-co/user-interface'

//Components
import DenyFromUserLevel from 'layouts/DenyFromUserLevel'
import ActiveInvoiceActions from '../components/Actions/ActiveInvoiceActions'
import DownloadInvoiceDocument from '../components/DownloadInvoiceDocument'
import PassiveInvoiceActions from '../components/Actions/PassiveInvoiceActions'
import NewRowAction from '../components/NewRowAction'
import BufferInvoiceActions from '../components/Actions/BufferInvoiceActions'
import ElaborateInvoiceModal from '../components/Actions/ElaborateInvoiceModal'
import TakeInChargeModal from '../components/Actions/TakeInChargeModal'
import DeleteInvoiceModal from '../components/Actions/delete/Modal'

const tabData = [
  { title: 'General information', path: '' },
  { title: 'Invoice rows', path: '/rows' },
  { title: 'History', path: '/history' },
]

const ViewInvoice = ({ type }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  let { invoiceId } = useParams()

  const [isOpenDeleteModal, deleteModalHandler] = useDisclosure('delete')
  const [isOpenElaborateModal, elaborateModalHandler] = useDisclosure('elaborate')
  const [isOpenTakeInChargeModal, takeInChargeModalHandler] = useDisclosure('take-in-charge')

  const openDeleteModal = useCallback(
    (invoiceId) => {
      deleteModalHandler.open(invoiceId)
    },
    [deleteModalHandler]
  )
  const openElaborateModal = useCallback(
    (invoiceId) => {
      elaborateModalHandler.open(invoiceId)
    },
    [elaborateModalHandler]
  )
  const openTakeInChargeModal = useCallback(
    (invoiceId) => {
      takeInChargeModalHandler.open(invoiceId)
    },
    [takeInChargeModalHandler]
  )

  const { invoice, isFetching, isLoading, isError, refetch } = useInvoice(invoiceId)

  const _type = useMemo(() => {
    return invoiceTypes.find((t) => t.name === type)
  }, [type])
  const pageTitle = useMemo(
    () =>
      `${invoice?.do_boolean && _type?.name === 'active' ? 'Credit note' : 'Invoice'} ${
        invoice?.invoice_number
      }`,
    [_type, invoice]
  )

  const { lastUrlSearch } = useRoyanTableLastFilters(_type?.key)

  const tabs = useMemo(
    () =>
      tabData.map((t) => ({
        ...t,
        path: `${_type?.pathList}/${invoiceId}${t.path}`,
      })),
    [_type?.pathList, invoiceId]
  )

  const selectedTabIndex = useMemo(
    () => tabs.findIndex((t) => t.path === pathname),
    [pathname, tabs]
  )

  const navigateToTab = (index) => {
    const tab = tabs[index]

    navigate(tab.path)
  }

  const InvoiceActions = useMemo(
    () =>
      _type?.name === 'active' ? (
        <ActiveInvoiceActions invoice={invoice} openDeleteModal={openDeleteModal} />
      ) : _type?.name === 'passive' ? (
        <PassiveInvoiceActions invoice={invoice} openDeleteModal={openDeleteModal} />
      ) : (
        <BufferInvoiceActions
          invoice={invoice}
          openElaborateModal={openElaborateModal}
          openTakeInChargeModal={openTakeInChargeModal}
        />
      ),
    [_type?.name, invoice, openDeleteModal, openElaborateModal, openTakeInChargeModal]
  )

  const SelectedTabButton = useMemo(() => {
    if (selectedTabIndex === 0) {
      return _type?.name === 'active' || _type?.name === 'passive' ? (
        <DownloadInvoiceDocument invoice={invoice} type={_type?.name} />
      ) : null
    } else if (selectedTabIndex === 1) {
      return _type?.name === 'buffer' || _type?.name === 'passive' ? (
        <NewRowAction invoice={invoice} type={_type?.name} />
      ) : null
    }
  }, [_type?.name, invoice, selectedTabIndex])

  return (
    <DenyFromUserLevel level="warehouse">
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <PageHeader
        breadcrumbs={
          <Breadcrumbs>
            <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
            <BreadcrumbsItem text="Accounting" />
            <BreadcrumbsItem text={_type?.title} to={_type?.pathList} component={Link} />
          </Breadcrumbs>
        }
        actions={
          <ButtonGroup>
            {SelectedTabButton}

            <Button
              component={Link}
              appearance="default"
              to={`${_type?.pathList}${lastUrlSearch ? `?${lastUrlSearch}` : ''}`}
            >
              Back
            </Button>

            <DropdownMenu
              placement="bottom-end"
              trigger={({ triggerRef, ...props }) => (
                <Button
                  {...props}
                  appearance="default"
                  children={<DotsIcon label="more" />}
                  ref={triggerRef}
                />
              )}
            >
              <DropdownItemGroup>{InvoiceActions}</DropdownItemGroup>
            </DropdownMenu>
          </ButtonGroup>
        }
      >
        {isLoading ? (
          <Skeleton height={32} maw={320} />
        ) : (
          <div className="flex items-center">
            {pageTitle}
            {isFetching && (
              <span className="ml-3 text-xs font-light text-neutral-50">updating...</span>
            )}
          </div>
        )}
      </PageHeader>

      <>
        <Tabs onChange={navigateToTab} selected={selectedTabIndex}>
          <TabList>
            {tabs.map((tab, i) => (
              <Tab key={i} on>
                {tab.title}
              </Tab>
            ))}
          </TabList>
        </Tabs>

        <div className="px-2 py-4">
          {isLoading ? (
            <div className="flex justify-center my-32">
              <Spinner />
            </div>
          ) : isError ? (
            <SectionMessage
              appearance="warning"
              title="Something wrong on loading data, please retry."
            >
              <Button onClick={() => refetch()}>Retry</Button>
            </SectionMessage>
          ) : (
            selectedTabIndex > -1 && <Outlet />
          )}
        </div>

        <DeleteInvoiceModal
          isOpen={isOpenDeleteModal}
          onClose={deleteModalHandler.close}
          invoiceId={deleteModalHandler.data}
          type={type}
        />

        <ElaborateInvoiceModal
          isOpen={isOpenElaborateModal}
          onClose={elaborateModalHandler.close}
          invoiceId={elaborateModalHandler.data}
        />

        <TakeInChargeModal
          isOpen={isOpenTakeInChargeModal}
          onClose={takeInChargeModalHandler.close}
          invoiceId={takeInChargeModalHandler.data}
        />
      </>
    </DenyFromUserLevel>
  )
}

export default ViewInvoice
