// Utils
import { findOption } from 'utils/helpers'

// Atlassian
import Select from '@atlaskit/select'
import { Label } from '@atlaskit/form'

// Constants
import { TemplateEmailType } from '../constants'

const SelectTemplate = ({ onChangeTemplate, defaultValue, ...props }) => {
  return (
    <div className="!-mt-2 mb-4">
      <Label>Template</Label>

      <Select
        {...props}
        defaultValue={findOption(TemplateEmailType, defaultValue) || undefined}
        options={TemplateEmailType}
        onChange={(option) => {
          onChangeTemplate(option?.value)
        }}
      />
    </div>
  )
}

export default SelectTemplate
