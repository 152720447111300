import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

import { deleteRegistry } from 'api/registries'

//Atlassian
import Modal, {
  ModalBody,
  ModalTitle,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import Button, { LoadingButton } from '@atlaskit/button'

//Interfaces
import { useFlags, useRoyanTable, WarningBanner } from '@royan-co/user-interface'

const RegistryDeleteModal = ({ isOpen, onClose, rateId }) => {
  const { refresh } = useRoyanTable()
  const { showSuccessFlag } = useFlags()
  const navigate = useNavigate()

  const registryDeleteMutation = useMutation(deleteRegistry)

  function closeModal() {
    onClose()
    registryDeleteMutation.reset()
  }

  const handleDeleteRate = async () => {
    try {
      const res = await registryDeleteMutation.mutateAsync(rateId)
      showSuccessFlag(res?.message)

      // if delete from list, close the modal and refresh table
      if (refresh) {
        refresh()
        closeModal()
        // else if delete from view rate, navigate to list
      } else {
        navigate('/panel/registries', { replace: true })
      }
    } catch (error) {
      console.error(error)
    }
  }
  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={closeModal}>
          <ModalHeader>
            <ModalTitle appearance="danger">Delete registry</ModalTitle>
          </ModalHeader>
          <ModalBody>
            You are about to delete a registry. Are you sure?
            {registryDeleteMutation.isError && <WarningBanner />}
          </ModalBody>
          <ModalFooter>
            <Button appearance="subtle" onClick={closeModal}>
              Cancel
            </Button>
            <LoadingButton
              isLoading={registryDeleteMutation.isLoading}
              appearance="danger"
              onClick={handleDeleteRate}
              autoFocus
            >
              Delete
            </LoadingButton>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  )
}

export default RegistryDeleteModal
