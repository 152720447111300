import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

// Apis
import { exportTemperatureReport, getTrackingRoutes } from 'api/consolidations'

// Hooks
import useConsolidation from 'hooks/useConsolidation'

//Atlassian
import Button from '@atlaskit/button'
import Spinner from '@atlaskit/spinner'
import SectionMessage from '@atlaskit/section-message'
import Tabs, { Tab, TabList, TabPanel } from '@atlaskit/tabs'

//Components
import ShipmentTrackingRouting from 'components/Shipments/View/TrackingRouting'
import ShipmentTrackingTemperatureChart from 'components/Shipments/View/TrackingTemperatureChart'
import ShipmentTrackingLogs from 'components/Shipments/View/TrackingLogs'

const ConsolidationViewTrackingPage = () => {
  const { consolidation } = useConsolidation()
  const { consolidationId: id } = useParams()

  const {
    data: tracking,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ['consolidation', 'tracking', id],
    queryFn: () => getTrackingRoutes(id),
  })

  const handleDownloadReport = async (format) => {
    return await exportTemperatureReport(format, id)
  }

  return (
    <>
      {isLoading ? (
        <div className="text-center mt-24">
          <Spinner />
        </div>
      ) : isError ? (
        <SectionMessage
          appearance="warning"
          title="Something wrong on loading tracking details, please retry."
        >
          <Button onClick={() => refetch()}>Retry</Button>
        </SectionMessage>
      ) : (
        <>
          <Tabs id="branch-tabs">
            <div className="pb-3">
              <TabList>
                <Tab>Routing</Tab>

                {consolidation?.reefer === 1 && <Tab>Temperature chart</Tab>}

                <Tab>Logs</Tab>
              </TabList>
            </div>

            <TabPanel>
              <ShipmentTrackingRouting
                tracking={tracking}
                entityType={consolidation?.shipment_type}
                entityStatus={consolidation?.status}
                departure={{
                  lat: consolidation?.hub?.lat,
                  lng: consolidation?.hub?.lng,
                  cap: consolidation?.hub?.cap,
                  city: consolidation?.hub?.city,
                  country: consolidation?.hub?.country_iso,
                }}
                arrival={{
                  country: consolidation?.arrival_country,
                }}
                arrivalStatus={2}
                departureStatus={0}
              />
            </TabPanel>

            {consolidation?.reefer === 1 && (
              <TabPanel>
                <ShipmentTrackingTemperatureChart
                  maxTemp={consolidation?.reefer_temperature?.split(',')[1]}
                  minTemp={consolidation?.reefer_temperature?.split(',')[0]}
                  trackingPins={tracking}
                  downloadReport={handleDownloadReport}
                />
              </TabPanel>
            )}

            <TabPanel>
              <ShipmentTrackingLogs parent="consolidation" parentId={id} />
            </TabPanel>
          </Tabs>
        </>
      )}
    </>
  )
}

export default ConsolidationViewTrackingPage
