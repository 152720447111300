import { useState, useEffect, useCallback, useRef } from 'react'
import axios from 'axios'

// Apis
import { getConsolidationReferenceAutocomplete } from 'api/consolidations'

// Interfaces
import { useDebounce } from '@royan-co/user-interface'

const useConsolidationReferenceAutocomplete = (isConsolidation) => {
  const controller = useRef()
  const [referenceAutocompleteOptions, setReferenceAutocompleteOptions] = useState([])
  const [term, setTerm] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const debouncedTerm = useDebounce(term)

  const getOptions = useCallback(async () => {
    try {
      setIsLoading(true)
      if (controller.current) {
        controller.current.abort()
      }

      controller.current = new AbortController()
      const data = await getConsolidationReferenceAutocomplete(debouncedTerm, controller.current)
      const _options = data.map((item) => ({
        label: item.reference,
        arrival: item.arrival,
        value: item.id_auto,
      }))

      setReferenceAutocompleteOptions(_options)
      setIsLoading(false)
    } catch (error) {
      if (!axios.isCancel(error)) {
        setIsLoading(false)
        console.error(error)
      }
    }
  }, [debouncedTerm])

  useEffect(() => {
    if (debouncedTerm) getOptions()
  }, [debouncedTerm, getOptions])

  return {
    referenceAutocompleteOptions,
    setTermReferenceAutocomplete: setTerm,
    isLoadingReferenceAutocomplete: isLoading,
  }
}

export default useConsolidationReferenceAutocomplete
