import { useQuery, useQueryClient } from '@tanstack/react-query'

// Apis
import { getInvoice } from './invoice-requests'

export const useInvoice = (invoiceId, { enabled = true, credit } = {}) => {
  const queryClient = useQueryClient()
  const _invoiceId = +invoiceId
  const params = credit ? { credit } : undefined

  const {
    data: invoice,
    isFetching,
    isLoading,
    isError,
    refetch,
  } = useQuery(
    ['invoice', params && 'credit', _invoiceId].filter((i) => !!i),
    () => getInvoice(_invoiceId, params),
    {
      staleTime: Infinity,
      enabled: !!invoiceId && enabled,
    }
  )

  const invalidate = () => {
    queryClient.invalidateQueries({ queryKey: ['invoice', _invoiceId], refetchType: 'none' })
  }

  return {
    invoice,
    isLoading,
    isFetching,
    isError,
    refetch,
    invalidate,
  }
}

export const useUpdateInvoice = (invoiceId) => {
  const queryClient = useQueryClient()
  const _invoiceId = +invoiceId

  const upsertInvoiceProperties = (properties) => {
    queryClient.setQueryData(['invoice', _invoiceId], (oldInvoice = {}) => ({
      ...oldInvoice,
      ...properties,
    }))
  }

  const editInvoiceOperator = (operator) => {
    queryClient.setQueryData(['invoice', _invoiceId], (oldInvoice = {}) => ({
      ...oldInvoice,
      operator: operator,
    }))
  }
  return { upsertInvoiceProperties, editInvoiceOperator }
}
