import { useMemo } from 'react'
import { Link, useParams } from 'react-router-dom'

// Utils
import { formatMonetaryNumber } from 'utils/numbers'
import { invoiceTypes } from '../utils/invoice-types'

//Hooks
import { useInvoice } from '../api/getInvoice'

//Atlassian
import Lozenge from '@atlaskit/lozenge'
import EmptyState from '@atlaskit/empty-state'
import { DynamicTableStateless } from '@atlaskit/dynamic-table'
import Heading from '@atlaskit/heading'
import Button from '@atlaskit/button'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'

//Interfaces
import { ShortcutIcon, DotsIcon, useDisclosure } from '@royan-co/user-interface'

//Components
import Card from 'components/UI/Card'
import RowDrawer from './RowDrawer'
import DeleteRowModal from './DeleteRowModal'
import DisabledElementTooltip from 'components/UI/DisabledElementTooltip'

const ViewInvoiceRows = () => {
  let { invoiceId } = useParams()
  const { invoice } = useInvoice(invoiceId)

  const [isOpenDeleteRowModal, deleteRowModalHandlers] = useDisclosure('delete')
  const [isOpenEditRowDrawer, editRowDrawerHandlers] = useDisclosure('edit-row')

  const _type = useMemo(() => {
    return invoiceTypes.find(
      (type) =>
        type.invoiceKeys.active_passive === invoice?.active_passive &&
        type.invoiceKeys.buffer === invoice?.buffer
    )
  }, [invoice])

  const disableRowActions = useMemo(
    () => !!((_type?.name === 'passive' || _type?.name === 'buffer') && invoice?.arca_status >= 3),
    [_type, invoice]
  )

  const rowsHead = useMemo(() => {
    let headItems = [
      { content: 'Shipment/Consolidation' },
      { content: 'Code' },
      { content: 'Description' },
      { content: 'Quantity' },
      { content: 'Unit price' },
      { content: 'Total' },
      { content: 'IVA code' },
      { content: 'IVA amount' },
      { content: 'Sub account' },
    ]

    if (_type.name === 'passive' || _type.name === 'buffer') headItems.push({ content: 'Actions' })

    return {
      cells: headItems,
    }
  }, [_type])

  const rows = useMemo(() => {
    const body =
      invoice?.cost_rows?.map((item, i) => {
        let _rows = [
          {
            id: `shipment${i}`,
            content: (
              <>
                {item?.shipment?.id_auto ? (
                  <Link to={`/panel/shipments/${item?.shipment?.id_auto}`} target="_blank">
                    <div className="whitespace-nowrap">
                      <span style={{ minWidth: '124px', display: 'inline-block' }}>
                        {item?.shipment?.reference}
                      </span>
                      <ShortcutIcon size={16} />
                    </div>
                  </Link>
                ) : item?.consolidation?.id_auto ? (
                  <Link
                    to={`/panel/consolidations/${item?.consolidation?.id_auto}`}
                    target="_blank"
                  >
                    <div className="whitespace-nowrap">
                      <span style={{ minWidth: '86px', display: 'inline-block' }}>
                        {item?.consolidation?.consolidation_code}
                      </span>
                      <ShortcutIcon size={16} />
                    </div>
                  </Link>
                ) : null}
              </>
            ),
          },
          {
            id: `code${i}`,
            content: item?.cost_code || <Lozenge>Not set</Lozenge>,
          },
          {
            id: `description${i}`,
            content: <small>{item?.service}</small> || <Lozenge>Not set</Lozenge>,
          },
          {
            id: `quantity${i}`,
            content: item?.quantity,
          },
          {
            id: `price${i}`,
            content:
              item?.quantity * 1
                ? formatMonetaryNumber((item?.amount * 1) / (item?.quantity * 1))
                : '-',
          },
          {
            id: `total${i}`,
            content: formatMonetaryNumber(item?.amount),
          },
          {
            id: `iva_code${i}`,
            content: item?.iva_code || <Lozenge>Not set</Lozenge>,
          },
          {
            id: `iva_amount${i}`,
            content: item?.iva_amount * 1 ? formatMonetaryNumber(item?.iva_amount) : '-',
          },
          {
            id: `sub_account${i}`,
            content: item?.subaccount || <Lozenge>Not set</Lozenge>,
          },
        ]

        if (_type.name === 'passive' || _type.name === 'buffer')
          _rows.push({
            content: (
              <DropdownMenu
                placement="bottom-end"
                trigger={({ triggerRef, ...props }) => (
                  <Button
                    {...props}
                    appearance="subtle"
                    children={<DotsIcon label="more" />}
                    ref={triggerRef}
                  />
                )}
              >
                <DropdownItemGroup>
                  <DisabledElementTooltip
                    tooltipContent="Invoice is processed."
                    isDisabled={disableRowActions}
                  >
                    <DropdownItem
                      children="Edit"
                      onClick={() => editRowDrawerHandlers.open(item)}
                      isDisabled={disableRowActions}
                    />
                  </DisabledElementTooltip>

                  <DisabledElementTooltip
                    tooltipContent="Invoice is processed."
                    isDisabled={disableRowActions}
                  >
                    <DropdownItem
                      children="Delete"
                      onClick={() => deleteRowModalHandlers.open(item.id_auto)}
                      isDisabled={disableRowActions}
                    />
                  </DisabledElementTooltip>
                </DropdownItemGroup>
              </DropdownMenu>
            ),
          })

        return {
          key: `rows${i}`,
          // eslint-disable-next-line no-sparse-arrays
          cells: _rows,
        }
      }) || []

    return body
  }, [
    _type.name,
    deleteRowModalHandlers,
    disableRowActions,
    editRowDrawerHandlers,
    invoice?.cost_rows,
  ])

  return (
    <>
      {_type.name === 'passive' || _type.name === 'buffer' ? (
        <div className="grid md:grid-cols-3 gap-4 mb-4">
          <div className="shadow p-4 rounded">
            <Heading level="h800">€ {formatMonetaryNumber(invoice?.total_amount || 0)}</Heading>
            <div className="mt-2 text-neutral-200">Total amount</div>
          </div>

          <div className="shadow p-4 rounded">
            <Heading level="h800">
              € {formatMonetaryNumber(invoice?.unassigned_amount || 0)}
            </Heading>
            <div className="mt-2 text-neutral-200">Left to assign</div>
          </div>
        </div>
      ) : null}

      <Card>
        {rows?.length < 1 ? (
          <EmptyState header="No rows" />
        ) : (
          <div className="overflow-auto inline-block w-full -mb-6">
            <DynamicTableStateless head={rowsHead} rows={rows} />
          </div>
        )}
      </Card>

      <RowDrawer
        isOpen={isOpenEditRowDrawer}
        onClose={editRowDrawerHandlers.close}
        invoiceId={invoiceId}
        row={editRowDrawerHandlers.data}
      />

      <DeleteRowModal
        isOpen={isOpenDeleteRowModal}
        onClose={deleteRowModalHandlers.close}
        rowId={deleteRowModalHandlers.data}
        invoiceId={invoiceId}
      />
    </>
  )
}

export default ViewInvoiceRows
