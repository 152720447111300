import React from 'react'
import { useParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

import { deleteRecordRate } from 'api/rates'

//Hooks
import useRateRecords from 'hooks/useRateRecords'

//Atlassian
import Modal, {
  ModalBody,
  ModalTitle,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import Button, { LoadingButton } from '@atlaskit/button'

//Interfaces
import { useFlags, WarningBanner } from '@royan-co/user-interface'

const RatesDeleteRecordModal = ({ record, isOpen, onClose }) => {
  const { showSuccessFlag } = useFlags()
  const { rateId } = useParams()

  const recordDeleteMutation = useMutation(deleteRecordRate)

  const { deleteRecordFromData } = useRateRecords(rateId)

  const handleDeleteRecord = () => {
    recordDeleteMutation.mutate(record.id_auto, {
      onSuccess: (res) => {
        deleteRecordFromData(record.id_auto)
        showSuccessFlag(res?.message)
        onClose?.()
      },
    })
  }

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={onClose}>
          <ModalHeader>
            <ModalTitle appearance="danger">Delete record</ModalTitle>
          </ModalHeader>
          <ModalBody>
            You are about to delete a record. Are you sure?
            {recordDeleteMutation.isError && <WarningBanner />}
          </ModalBody>
          <ModalFooter>
            <Button appearance="subtle" onClick={onClose}>
              Cancel
            </Button>
            <LoadingButton
              isLoading={recordDeleteMutation.isLoading}
              appearance="danger"
              onClick={handleDeleteRecord}
              autoFocus
            >
              Delete
            </LoadingButton>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  )
}

export default RatesDeleteRecordModal
