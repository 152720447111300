// Apis
import { downloadFBLDocument, editFBLDocument, getFBLFormDefaultValues } from '../api/fbl'
import {
  downloadAirwaybillDocument,
  editAirwaybillDocument,
  getAirwaybillFormDefaultValues,
} from '../api/airwaybill'
import { downloadCMRDocument, editCMRDocument, getCMRFormDefaultValues } from '../api/cmr'
import {
  editSeawaybillDocument,
  downloadSeawaybillDocument,
  getSeawaybillFormDefaultValues,
} from '../api/seawaybill'

// Components
import FBLForm from '../components/forms/FBLForm'
import CMRForm from '../components/forms/CMRForm'
import AirwayBillForm from '../components/forms/AirwayBill'
import SeawayBillForm from '../components/forms/SeawayBill'

// Constants
import {
  DownloadAirWaybillDocumentTypes,
  DownloadSeaWaybillDocumentTypes,
  DownloadCMRDocumentTypes,
  DownloadFBLDocumentTypes,
} from '../constants'

// mappers
import { getFBLDefaultValues } from './fbl-default-values'
import { getEditedAirwaybillData } from './airwaybill-edited-data'
import { getAirwaybillDefaultValues } from './airwaybill-default-values'
import { airwayBillSchema } from './airwaybill-schema'
import { getCMRDefaultValues } from './cmr-default-values'
import { getSeawaybillDefaultValues } from './seawaybill-default-values'

export const documentTypes = [
  {
    key: 'airwayBill',
    title: 'airway Bill',
    getSchema: airwayBillSchema,
    formComponent: AirwayBillForm,
    downloadTypes: DownloadAirWaybillDocumentTypes,
    defaultValuesRequest: getAirwaybillFormDefaultValues,
    defaultValuesMapper: getAirwaybillDefaultValues,
    submitValuesMapper: getEditedAirwaybillData,
    downloadRequest: downloadAirwaybillDocument,
    editDocumentRequest: editAirwaybillDocument,
  },
  {
    key: 'seawayBill',
    title: 'seaway Bill',
    formComponent: SeawayBillForm,
    downloadTypes: DownloadSeaWaybillDocumentTypes,
    defaultValuesRequest: getSeawaybillFormDefaultValues,
    defaultValuesMapper: getSeawaybillDefaultValues,
    downloadRequest: downloadSeawaybillDocument,
    editDocumentRequest: editSeawaybillDocument,
  },
  {
    key: 'fbl',
    title: 'FBL',
    formComponent: FBLForm,
    downloadTypes: DownloadFBLDocumentTypes,
    defaultValuesRequest: getFBLFormDefaultValues,
    defaultValuesMapper: getFBLDefaultValues,
    editDocumentRequest: editFBLDocument,
    downloadRequest: downloadFBLDocument,
  },
  {
    key: 'cmr',
    title: 'CMR',
    formComponent: CMRForm,
    downloadTypes: DownloadCMRDocumentTypes,
    defaultValuesRequest: getCMRFormDefaultValues,
    defaultValuesMapper: getCMRDefaultValues,
    editDocumentRequest: editCMRDocument,
    downloadRequest: downloadCMRDocument,
  },
]
