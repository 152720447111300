import { Link } from 'react-router-dom'

//Styles

import styles from './Navbar.module.scss'

//Images
import Logo from './../assets/psx-logo.png'
import LogoMinimized from './../assets/psx-logo-minimized.png'

//Items
import { Items } from '../services/PSXNavbarItems'

// Components
import MenuItem from './MenuItem'
import Account from './Account'
import { useState } from 'react'

const PSXNavbar = () => {
  const [openMenu, setOpenMenu] = useState(false)

  return (
    <>
      <div className={styles.navbar__space} />

      <div className={styles.wrapper}>
        <nav className={styles.navbar}>
          <div className={styles.navbar__topWrapper}>
            <div className={styles.navbar__top}>
              <div>
                <img src={Logo} className={styles.navbar__logo} alt="PSX" />
                <img
                  src={LogoMinimized}
                  className={`${styles.navbar__logo} ${styles.navbar__logoMinimized}`}
                  alt="PSX"
                />
              </div>

              <Account />
            </div>
          </div>

          <div className={styles.navbar__bottomWrapper}>
            <div className={styles.navbar__bottom}>
              <button
                className={styles.navbarToggler}
                type="button"
                onClick={() => setOpenMenu((m) => !m)}
              >
                <span className={styles.navbarTogglerIcon}></span>
              </button>

              <div className={`${styles.navbar__menuWrapper} ${openMenu ? styles.show__menu : ''}`}>
                <ul className={styles.navbar__menu}>
                  {Items.map((item, index) => {
                    const depthLevel = 0

                    return <MenuItem items={item} key={index} depthLevel={depthLevel} />
                  })}
                </ul>

                <div className={styles.actions}>
                  <Link to={'/shipments/track-and-trace'}>Track and trace</Link>
                  <a
                    href="https://www.parsimpex.com/request-a-quote-now"
                    className={styles.action__button}
                  >
                    Get a quote
                  </a>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  )
}

export default PSXNavbar
