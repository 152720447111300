import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getRegistryContactDetails } from 'api/registries'

const useContact = (contactId, { enabled = true } = {}) => {
  const queryClient = useQueryClient()

  const {
    data: contact,
    isFetching,
    isLoading,
    isError,
    refetch,
  } = useQuery(['contact_details', contactId], () => getRegistryContactDetails(contactId), {
    staleTime: Infinity,
    enabled,
  })

  const invalidate = () => {
    queryClient.invalidateQueries({ queryKey: ['contact_details', contactId], refetchType: 'none' })
  }

  return { contact, isLoading, isFetching, isError, refetch, invalidate }
}

export default useContact
