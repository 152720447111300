import React from 'react'

// Atlassian
import Heading from '@atlaskit/heading'

const ShipmentStatusCard = ({ icon, title, description }) => {
  return (
    <div className="rounded shadow px-3 py-8 text-neutral-500">
      <div className="flex flex-col items-center">
        <div className="mb-4">{icon}</div>
        <div>
          <Heading level="h500">{title}</Heading>
        </div>
        <div className="mt-2">
          <small>{description}</small>
        </div>
      </div>
    </div>
  )
}

export default ShipmentStatusCard
