// Utils
import { findOption } from 'utils/helpers'
import { vehicleTypeOptions } from './../utils/constants'

// Atlassian
import Lozenge from '@atlaskit/lozenge'

const VehicleTypeTag = ({ value }) => {
  const option = findOption(vehicleTypeOptions, value)
  return option ? <Lozenge appearance={option.appearance}>{option.label}</Lozenge> : null
}

export default VehicleTypeTag
