import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import { getConsolidationHistory } from 'api/consolidations'

//Atlassian
import Button from '@atlaskit/button'
import Spinner from '@atlaskit/spinner'
import EmptyState from '@atlaskit/empty-state'
import SectionMessage from '@atlaskit/section-message'

//Interfaces
import { Timeline, TimelineItem } from '@royan-co/user-interface'

const ConsolidationViewHistoryPage = () => {
  const { consolidationId } = useParams()

  const {
    data: history,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ['consolidation', 'history', consolidationId],
    queryFn: () => getConsolidationHistory(consolidationId),
  })

  return (
    <>
      {isLoading ? (
        <div className="text-center mt-24">
          <Spinner />
        </div>
      ) : isError ? (
        <SectionMessage
          appearance="warning"
          title="Something wrong on loading history, please retry."
        >
          <Button onClick={() => refetch()}>Retry</Button>
        </SectionMessage>
      ) : !history || history?.length < 1 ? (
        <EmptyState header="No history available." />
      ) : (
        <div>
          <Timeline>
            {history?.map((pin, index) => (
              <TimelineItem
                key={index}
                title={pin.title}
                description={
                  <>
                    Recorded at {pin?.creation_date} by {pin?.creator?.full_name}
                  </>
                }
              />
            ))}
          </Timeline>
        </div>
      )}
    </>
  )
}

export default ConsolidationViewHistoryPage
