import { useMemo } from 'react'
import { useLocation, matchPath } from 'react-router-dom'

// Contexts
import { useAuth } from 'contexts/AuthProvider'

// Atlassian
import {
  Footer,
  Section,
  NestingItem,
  SideNavigation,
  NavigationFooter,
  NestableNavigationContent,
} from '@atlaskit/side-navigation'

// Components
import SidebarLink from 'layouts/SidebarLink'
import { links } from './sidebar-links'

const generateSidebar = (items, stack) => {
  return items.map((item) => {
    if (item.children) {
      return (
        <NestingItem
          id={item.title}
          key={item.title}
          title={item.title}
          iconBefore={item.icon}
          isSelected={stack?.includes(item.title)}
        >
          <Section>{generateSidebar(item.children)}</Section>
        </NestingItem>
      )
    } else {
      return (
        <SidebarLink key={item.title} to={item.path} exactPath={false}>
          {item.title}
        </SidebarLink>
      )
    }
  })
}

const PanelLayoutSidebar = () => {
  const { permissions } = useAuth()
  const { pathname } = useLocation()

  const stack = useMemo(() => {
    let _stack = []

    const findRoute = (section) => {
      const accepted = section.children.find((item) => {
        if (item.children) return findRoute(item)
        else {
          return matchPath(`${item.path}/*`, pathname)
        }
      })
      if (accepted) _stack.push(section.title)
      return accepted
    }

    links.forEach((p) => findRoute(p))
    _stack.reverse()

    return _stack
  }, [pathname])

  const nestingSidebar = useMemo(() => {
    const filterLinks = (items) => {
      return items.flatMap((item) => {
        if (item.children) {
          item.children = filterLinks(item.children)
          return item.children.length > 0 ? item : []
        }

        return permissions.some((p) => p.resource.startsWith(item.permissionResource)) ? item : []
      })
    }

    const filteredLinks = filterLinks(links)

    return generateSidebar(filteredLinks, stack)
  }, [permissions, stack])

  return (
    <SideNavigation>
      <div className="mt-5" />

      <NestableNavigationContent initialStack={stack}>
        <Section>{nestingSidebar}</Section>
      </NestableNavigationContent>

      <NavigationFooter>
        <Footer description="Version 2.0.1">Expesoft</Footer>
      </NavigationFooter>
    </SideNavigation>
  )
}

export default PanelLayoutSidebar
