import { useMemo } from 'react'
import { findOption } from 'utils/helpers'

//Hooks
import useSurchargeEnumeration from 'hooks/useSurchargeEnumeration'

//Atlassian
import Select from '@atlaskit/select'
import Textfield from '@atlaskit/textfield'
import { ErrorMessage } from '@atlaskit/form'
import Toggle from '@atlaskit/toggle'

//Interfaces
import { Field, useFormContext } from '@royan-co/user-interface'

const RatesCreateSurchargeAgencyFee = ({ shipmentType }) => {
  const { getValues, setValue } = useFormContext()
  const { getSurchargeOptionsByShipmentType, isLoadingSurcharge } = useSurchargeEnumeration()

  const computedSurchargeOptions = useMemo(
    () => getSurchargeOptionsByShipmentType(shipmentType),
    [getSurchargeOptionsByShipmentType, shipmentType]
  )

  return (
    <>
      <Field
        isRequired
        label="Surcharge"
        type="select"
        name="surcharge_id"
        transform={{
          input: (v) => findOption(computedSurchargeOptions, v),
          output: (opt) => {
            if (getValues('surcharge_id') !== opt?.value) {
              setValue('brackets', [])
            }
            return opt?.value !== undefined ? opt.value : null
          },
        }}
        className="!mt-0"
      >
        {({ fieldProps }) => (
          <Select
            isClearable={true}
            options={computedSurchargeOptions}
            placeholder="Choose something"
            isLoading={isLoadingSurcharge}
            {...fieldProps}
          />
        )}
      </Field>

      <Field label="Rate" name="rate">
        {({ fieldProps, error }) => (
          <>
            <Textfield
              placeholder="Type the rate"
              {...fieldProps}
              elemAfterInput={
                <div className="text-neutral-200 whitespace-nowrap bg-neutral-20 self-stretch flex items-center px-2">
                  Euro (€)
                </div>
              }
            />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </>
        )}
      </Field>

      <div className="grid md:grid-cols-2 md:gap-x-4">
        <Field label="Minimum" name="minimum">
          {({ fieldProps, error }) => (
            <>
              <Textfield
                placeholder="Type the minimum"
                {...fieldProps}
                elemAfterInput={
                  <div className="text-neutral-200 whitespace-nowrap bg-neutral-20 self-stretch flex items-center px-2">
                    Euro (€)
                  </div>
                }
              />
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </>
          )}
        </Field>

        <Field label="Maximum" name="maximum">
          {({ fieldProps, error }) => (
            <>
              <Textfield
                placeholder="Type the maximum"
                {...fieldProps}
                elemAfterInput={
                  <div className="text-neutral-200 whitespace-nowrap bg-neutral-20 self-stretch flex items-center px-2">
                    Euro (€)
                  </div>
                }
              />
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </>
          )}
        </Field>
      </div>

      <Field label="Minimum freight rate" name="minimum_f_minimum">
        {({ fieldProps, error }) => (
          <>
            <Textfield
              placeholder="Type the minimum freight rate"
              {...fieldProps}
              elemAfterInput={
                <div className="text-neutral-200 whitespace-nowrap bg-neutral-20 self-stretch flex items-center px-2">
                  Euro (€)
                </div>
              }
            />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </>
        )}
      </Field>

      {shipmentType === 2 && (
        <Field
          type="checkbox"
          name="awb_use"
          label="Usable for AWB"
          defaultValue={false}
          key="awb_use"
        >
          {({ fieldProps, error }) => (
            <>
              <div>
                <Toggle {...fieldProps} id="awb_use" />
              </div>
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </>
          )}
        </Field>
      )}
    </>
  )
}

export default RatesCreateSurchargeAgencyFee
