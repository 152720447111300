// Atlaskit
import Textfield from '@atlaskit/textfield'
import { DatePicker } from '@atlaskit/datetime-picker'
import { ErrorMessage } from '@atlaskit/form'

//Components
import Card from 'components/UI/Card'

// Interfaces
import { Field, Grid } from '@royan-co/user-interface'

const CommercialInvoiceGeneralSection = () => {
  return (
    <>
      <Card className="mb-0">
        <Grid>
          <Grid.Col sm={6}>
            <Field label="Invoice number" name="number" isRequired>
              {({ fieldProps, error }) => (
                <>
                  <Textfield placeholder="Invoice number" {...fieldProps} />
                  {error && <ErrorMessage>{error}</ErrorMessage>}
                </>
              )}
            </Field>
          </Grid.Col>
          <Grid.Col sm={6}>
            <Field label="Invoice date" name="date" isRequired>
              {({ fieldProps, error }) => (
                <>
                  <DatePicker placeholder="2/18/2022" {...fieldProps} />
                  {error && <ErrorMessage>{error}</ErrorMessage>}
                </>
              )}
            </Field>
          </Grid.Col>
        </Grid>

        <Field label="Invoice amount" name="amount" isRequired>
          {({ fieldProps, error }) => (
            <>
              <Textfield placeholder="Invoice amount" {...fieldProps} />
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </>
          )}
        </Field>
      </Card>
    </>
  )
}

export default CommercialInvoiceGeneralSection
