import Lozenge from '@atlaskit/lozenge'
import { findOption } from 'utils/helpers'
import { registryAddressTypeOptions } from './constants'

const RegistryAddressTypeTag = ({ value }) => {
  const option = findOption(registryAddressTypeOptions, value)

  return option ? <Lozenge appearance={option.appearance}>{option.label}</Lozenge> : null
}

const RegistryAddressTypeTags = ({ value = '' }) => {
  return value
    ?.split(';')
    .filter((t) => !isNaN(parseInt(t)))
    .map((type, i) => (
      <div key={i} className="inline-block mr-1">
        <RegistryAddressTypeTag value={+type} className />
      </div>
    ))
}

export { RegistryAddressTypeTag }
export default RegistryAddressTypeTags
