import { useNavigate } from 'react-router-dom'

// Atlassian
import { DropdownItem } from '@atlaskit/dropdown-menu'

const DropdownLink = ({ children, to, target, options, ...props }) => {
  const navigate = useNavigate()

  return (
    <DropdownItem
      children={children}
      onClick={() => {
        if (target === '_blank') window.open(to, '_blank')
        else navigate(to, options)
      }}
      {...props}
    />
  )
}

export default DropdownLink
