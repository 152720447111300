import axios from 'services/Request'

export function getFBLFormDefaultValues(documentId) {
  return axios.get(`/api/shipments/documents/fbl/fetch/${documentId}`)
}

export function editFBLDocument(documentId, data) {
  return axios.post(`/api/shipments/documents/fbl/edit/${documentId}`, data)
}

export function downloadFBLDocument({ documentId, format }) {
  return axios.get(`/api/shipments/documents/fbl/download/${documentId}/${format}`, {
    responseType: 'arraybuffer',
  })
}
