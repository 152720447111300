import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

// Apis
import { editShipmentFinance } from 'api/shipments'
import { editConsolidationFinance } from 'api/consolidations'

// Hooks
import useShipmentsEnumerations from 'hooks/useShipmentsEnumerations'

// Contexts
import { useFinanceEditingContext } from 'contexts/FinanceEditingProvider'

// Atlassian
import { LoadingButton } from '@atlaskit/button'
import EmptyState from '@atlaskit/empty-state'

// Interfaces
import { Grid, WarningBanner, useFlags } from '@royan-co/user-interface'

// Components
import EditFinanceFromToFields from './FromToFields'
import EditFinanceActionsCard from './ActionsCard'
import EditFinanceSuppliersCard from './SuppliersCard'
import EditFinanceAccessoriesCard from './AccessoriesCard'
import EditFinanceTotalCostCard from './TotalCostCard'
import EditFinanceFormDetector from './FormDetector'
import { legTypes } from './legTypes'
import { validateSchema } from './validationSchema'
import { createFormValues } from './createFormValues'

const EditFinanceLegForm = () => {
  const { shipmentId, quotationId, consolidationId } = useParams()
  const navigate = useNavigate()
  const { showSuccessFlag } = useFlags()
  const formRef = useRef()

  const {
    entityType,
    entityId,
    currentLegIndex,
    currentLeg,
    updateFinanceData,
    financeData,
    updateFieldErrors,
    removeFieldError,
    fieldErrors,
    isEmpty,
  } = useFinanceEditingContext()
  const { getServiceCodeOptions } = useShipmentsEnumerations()
  const editRequest = entityType === 'shipment' ? editShipmentFinance : editConsolidationFinance
  const editFinanceMutation = useMutation(editRequest.bind(null, entityId))

  const formErrors = useMemo(
    () => fieldErrors.find((filedError) => filedError.legId === currentLeg?._id)?.fields,
    [currentLeg?._id, fieldErrors]
  )

  const onChangeFrom = useCallback(
    (data, { type, name }) => {
      if (type === 'change' || type === undefined) {
        updateFinanceData(currentLegIndex, data)
      }

      if (name && formErrors?.includes(name)) {
        removeFieldError(currentLeg?._id, name)
      }
    },
    [currentLeg?._id, currentLegIndex, formErrors, removeFieldError, updateFinanceData]
  )

  const onSubmit = () => {
    updateFieldErrors([])

    const _financeData = {
      rows: financeData.map((item) => {
        if (!item?._id) item = createFormValues(item)

        const { suppliers, accessories, ...fromTo } = item
        return {
          fromTo,
          accessories,
          suppliers: suppliers.map(({ _id, supplier, ...supplierData }) => ({
            ...supplierData,
            supplier: supplier?.value,
          })),
        }
      }),
    }

    const handleValidationSchema = validateSchema(_financeData, getServiceCodeOptions)
    const validationResult = handleValidationSchema.safeParse(_financeData) || { success: true }

    if (!validationResult.success) {
      const errors = validationResult.error.errors
      updateFieldErrors(errors)

      return
    }

    const validData = {
      rows: financeData.map((item) => {
        const type = legTypes.find((t) => t.value === item?.leg)
        const legData = {}

        const arrivalKey = type.arrival.arrivalKey
        const arrival = item[arrivalKey]
        const departureKey = type.departure.departureKey
        const departure = item[departureKey]

        if (arrivalKey === 'arrival_zone') {
          legData.arrival_city = arrival.city
          legData.arrival_cap = arrival.zipCode
          legData.arrival_country = arrival.countryAbbr
          legData.arrival_lat = arrival.lat
          legData.arrival_lng = arrival.lng
        } else legData[arrivalKey] = arrival

        if (departureKey === 'departure_zone') {
          legData.departure_city = departure.city
          legData.departure_cap = departure.zipCode
          legData.departure_country = departure.countryAbbr
          legData.departure_lat = departure.lat
          legData.departure_lng = departure.lng
        } else legData[departureKey] = departure

        return {
          ...legData,
          leg: item.leg,
          suppliers: item.suppliers.map(({ _id, supplier, parent, name, ...supplierData }) => ({
            ...supplierData,
            selected: !!supplierData?.selected,
            service_name: name,
            supplier_id: supplier?.value,
            surcharges: supplierData?.surcharges?.map(({ _id, service_code, ...surcharge }) => ({
              ...surcharge,
            })),
          })),
          accessories: item.accessories.map(({ _id, ...accessory }) => ({ ...accessory })),
        }
      }),
    }

    editFinanceMutation.mutate(validData, {
      onError: (e) => {
        console.error(e)
      },
      onSuccess: (res) => {
        showSuccessFlag(res.message)
        navigate(
          entityType === 'shipment'
            ? shipmentId
              ? `/panel/shipments/${shipmentId}/finance`
              : `/panel/quotations/${quotationId}/finance`
            : `/panel/consolidations/${consolidationId}/finance`
        )
      },
    })
  }

  useEffect(() => {
    let subscription = null

    if (formRef.current) {
      subscription = formRef.current.watch(onChangeFrom)
      formRef.current.clearErrors()
      formErrors?.forEach((path) => {
        formRef.current.setError(path, { type: 'custom', message: '' })
      })
    }

    return () => {
      subscription?.unsubscribe()
    }
  }, [formErrors, onChangeFrom])

  return (
    <EditFinanceFormDetector ref={formRef}>
      <Grid className="w-full">
        <Grid.Col lg={9}>
          {isEmpty ? (
            <EmptyState
              header="Create the first leg."
              description="There are no legs defined yet."
            />
          ) : (
            <>
              <EditFinanceFromToFields />

              <EditFinanceSuppliersCard />

              <EditFinanceAccessoriesCard />
            </>
          )}
          {editFinanceMutation.isError && <WarningBanner />}
        </Grid.Col>

        <Grid.Col lg={3}>
          {!isEmpty && <EditFinanceActionsCard />}

          <EditFinanceTotalCostCard />

          <LoadingButton
            shouldFitContainer
            type="button"
            appearance="primary"
            onClick={onSubmit}
            isLoading={editFinanceMutation.isLoading}
          >
            Save the changes
          </LoadingButton>
        </Grid.Col>
      </Grid>
    </EditFinanceFormDetector>
  )
}

export default EditFinanceLegForm
