import { useMutation, useQueryClient } from '@tanstack/react-query'

// Apis
import {
  createMarginCalculatorBracket,
  editMarginCalculatorBracket,
} from './margin-calculator-requests'

const useCreateBracket = (marginId, bracketId) => {
  const queryClient = useQueryClient()
  const _marginId = +marginId

  return useMutation({
    mutationFn: (data) =>
      bracketId
        ? editMarginCalculatorBracket(_marginId, bracketId, data)
        : createMarginCalculatorBracket(_marginId, data),
    onSuccess: (res) => {
      queryClient.setQueryData(['margin-calculator', 'brackets', _marginId], (oldBrackets) => {
        return bracketId
          ? oldBrackets.map((b) => (b.id === bracketId ? res.bracket : b))
          : [...oldBrackets, res.bracket]
      })
    },
    onError: (e) => {
      console.error(e)
    },
  })
}

export { useCreateBracket }
