// Atlassian
import Button from '@atlaskit/button'
import Tooltip from '@atlaskit/tooltip'

// Icons
import { QuestionCircleIcon } from '@royan-co/user-interface'

const MissingRowButton = ({ value, onClick }) => {
  return (
    <Button spacing="none" appearance="subtle-link" onClick={() => onClick()}>
      <Tooltip content="Report missing row">
        {(tooltipProps) => (
          <span {...tooltipProps}>
            <QuestionCircleIcon className={value ? 'text-yellow-500' : 'text-neutral-500'} />
          </span>
        )}
      </Tooltip>
    </Button>
  )
}

export default MissingRowButton
