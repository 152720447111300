import { useQuery } from '@tanstack/react-query'

// Apis
import { getDefaultsForCreatingInvoice } from './invoice-requests'

/**
 * get invoice default values
 * @param {object} params
 * @param {import('@tanstack/react-query').UseQueryOptions} options
 * @returns {import('@tanstack/react-query').UseQueryResult}
 */
export const useInvoiceDefaultValues = (params, options = {}) => {
  return useQuery({
    queryKey: ['invoice-defaults', params],
    queryFn: () => getDefaultsForCreatingInvoice(params),
    staleTime: Infinity,
    ...options,
  })
}
