import React from 'react'

//Atlassian
import Modal, {
  ModalBody,
  ModalTitle,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import Button from '@atlaskit/button'

const EditFinanceDeleteLegModal = ({ isOpen, onClose, onSubmit }) => {
  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={onClose}>
          <ModalHeader>
            <ModalTitle appearance="danger">Delete leg</ModalTitle>
          </ModalHeader>
          <ModalBody>You are about to delete a leg. Are you sure?</ModalBody>
          <ModalFooter>
            <Button appearance="subtle" onClick={onClose}>
              Cancel
            </Button>
            <Button appearance="danger" onClick={onSubmit} autoFocus>
              Delete
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  )
}

export default EditFinanceDeleteLegModal
