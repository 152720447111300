import axios from 'services/Request'

export function getStatistics(period, params) {
  return axios.get(`/api/statistics/${period}`, { params })
}

export function getChartsData(type, params) {
  return axios.get(`/api/charts/${type}`, { params })
}

export function getShipmentsStatistics(type, params) {
  return axios.get(`/api/statistics/shipments/${type}`, { params })
}

export function getShipmentsExport(type, params) {
  return axios.get(`/api/statistics/shipments/export/${type}`, { params })
}

export function getConsolidationsStatistics(type, params) {
  return axios.get(`/api/statistics/consolidations/${type}`, { params })
}

export function getConsolidationsExport(type, params) {
  return axios.get(`/api/statistics/consolidations/export/${type}`, { params })
}
