import Lozenge from '@atlaskit/lozenge'

const InvoicePaidTag = ({ value }) => {
  return value === 0 ? (
    <Lozenge appearance="moved">No</Lozenge>
  ) : value === 2 ? (
    <Lozenge appearance="default">Partial</Lozenge>
  ) : (
    <Lozenge appearance="success">Yes</Lozenge>
  )
}

export default InvoicePaidTag
