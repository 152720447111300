import axios from 'services/Request'

export function getAirWaybillsStock(params) {
  return axios.get('/api/documents/airwaybills/stock/list', { params })
}

export function deleteAirWaybillStock(stockId) {
  return axios.delete(`/api/documents/airwaybills/stock/${stockId}`)
}

export function createAirWaybillStock(data) {
  return axios.post('/api/documents/airwaybills/stock', data)
}

export function getStockList(stockId, params) {
  return axios.get(`/api/documents/airwaybills/stock/waybills/${stockId}`, { params })
}

export function getStock(stockId) {
  return axios.get(`/api/documents/airwaybills/stock/fetch/${stockId}`)
}

export function updateBooking(waybillId) {
  return axios.get(`/api/documents/airwaybills/stock/waybills/booking/${waybillId}`)
}
