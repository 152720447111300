function Trapezoid({ width, background = "white" }) {
  const w = width * 1;
  return (
    <svg
      width={w * 2.3 + 28}
      height={13 * 1.9}
      viewBox={`0 0 ${w + 28} 13`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="14" width={w} height="13" fill={background} />
      <rect x="14" y="12" width={w} height="1" fill="black" />
      <path d="M1 0H14V13L1 0Z" fill={background} />
      <path d={`M${w + 14} 0H${w + 27}L${w + 14} 13V0Z`} fill={background} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.70711 0L13.7071 12H14L14 13H13.2929L0.292893 0H1.70711Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d={`M${w + 26}.293 0L${w + 14}.293 12H${w + 14}L${w + 14} 13H${
          w + 14
        }.707L${w + 27}.707 0H${w + 26}.293Z`}
        fill="black"
      />
    </svg>
  );
}

export default Trapezoid;
