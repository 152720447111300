import { getRate } from 'api/rates'
import { useQuery, useQueryClient } from '@tanstack/react-query'

const useRate = (rateId, { enabled = true } = {}) => {
  const queryClient = useQueryClient()

  const {
    data: rate,
    isFetching,
    isLoading,
    isError,
    refetch,
  } = useQuery(['rate', rateId], () => getRate(rateId), {
    staleTime: Infinity,
    enabled,
  })

  const invalidate = () => {
    queryClient.invalidateQueries({ queryKey: ['rate', rateId], refetchType: 'none' })
  }

  return { rate, isLoading, isFetching, isError, refetch, invalidate }
}

export default useRate
