import React, { useMemo } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { useMutation } from '@tanstack/react-query'

import { createRate, editRate } from 'api/rates'
import { getFilled } from 'utils/helpers'
import { handleErrorOnSubmit } from 'utils/errors'

//Hooks
import useRate from 'hooks/useRate'

//Atlassian
import Spinner from '@atlaskit/spinner'
import PageHeader from '@atlaskit/page-header'
import SectionMessage from '@atlaskit/section-message'
import Button, { LoadingButton } from '@atlaskit/button'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'

//Interfaces
import { Form, Grid, useFlags, WarningBannerField } from '@royan-co/user-interface'

//Components
import RateCreationGeneralInformationFields from 'components/Rates/Create/GeneralInformation'
import RateCreationSpecialConditionsFields from 'components/Rates/Create/SpecialConditions'
import RateCreationCalculationFields from 'components/Rates/Create/Calculation'
import DenyFromUserLevel from 'layouts/DenyFromUserLevel'

const RateCreationPage = () => {
  const navigate = useNavigate()
  const { showSuccessFlag } = useFlags()
  const { rateId } = useParams()
  const rateCreationMutation = useMutation(!rateId ? createRate : editRate.bind(null, rateId))

  const pageTitle = rateId ? 'Edit rate' : 'New rate'

  const { rate, isError, refetch, isLoading, isFetching, invalidate } = useRate(rateId, {
    enabled: !!rateId,
  })

  const defaultValues = useMemo(
    () => ({
      name: getFilled(rate, 'name', ''),
      supplier: rate?.supplier?.id_auto
        ? {
            label: rate.supplier.company_name,
            value: rate.supplier.id_auto,
            imported: rate.supplier?.arca_imported,
          }
        : null,
      from_to: getFilled(rate, 'from_to', null),
      type: getFilled(rate, 'type', ''),
      cost_code: getFilled(rate, 'cost_code', null),
      start_data: getFilled(rate, 'start_data', ''),
      end_data: getFilled(rate, 'end_data', ''),
      departure_country: getFilled(rate, 'departure_country', null),
      departure_hub: getFilled(rate, 'departure_hub', null),
      departure_airport: getFilled(rate, 'departure_airport', null),
      departure_port: getFilled(rate, 'departure_port', null),
      departure_station: getFilled(rate, 'departure_station', null),
      arrival_country: getFilled(rate, 'arrival_country', null),
      arrival_hub: getFilled(rate, 'arrival_hub', null),
      arrival_airport: getFilled(rate, 'arrival_airport', null),
      arrival_port: getFilled(rate, 'arrival_port', null),
      arrival_station: getFilled(rate, 'arrival_station', null),
      shipment_type: getFilled(rate, 'shipment_type', null),
      type_of_collies: getFilled(rate, 'type_of_collies', null),
      service_type: getFilled(rate, 'service_type', null),
      transit_time: getFilled(rate, 'transit_time', ''),
      multiple_pod: rateId
        ? ![
            'arrival_country',
            'arrival_hub',
            'arrival_airport',
            'arrival_port',
            'arrival_station',
          ].some((p) => !!rate?.[p])
        : false,
      multiple_pol: rateId
        ? ![
            'departure_country',
            'departure_hub',
            'departure_airport',
            'departure_port',
            'departure_station',
          ].some((p) => !!rate?.[p])
        : false,

      second_set: getFilled(rate, 'second_set', 0),
      insurance: getFilled(rate, 'insurance', 0),
      letter_of_credit: getFilled(rate, 'letter_of_credit', 0),
      must_go: getFilled(rate, 'must_go', 0),
      consolidate: getFilled(rate, 'consolidate', 0),
      promo: getFilled(rate, 'promo', 0),
      exclude_accessories: getFilled(rate, 'exclude_accessories', 0),
      no_cost: getFilled(rate, 'no_cost', 0),
      exclude_margin: getFilled(rate, 'exclude_margin', 0),

      allow_calculate: getFilled(rate, 'allow_calculate', !rateId ? 1 : 0),
      calculation_method: getFilled(rate, 'calculation_method', null),
      cost: getFilled(rate, 'cost', ''),
      margin: getFilled(rate, 'margin', ''),
      each: getFilled(rate, 'each', null),
      cubic_linear: getFilled(rate, 'cubic_linear', null),
      one_cubic_meter: getFilled(rate, 'one_cubic_meter', null),
      one_linear_meter: getFilled(rate, 'one_linear_meter', null),
      rounding: getFilled(rate, 'rounding', null),
      h_not_s: getFilled(rate, 'h_not_s', 0),
      h_not_s_c_l: getFilled(rate, 'h_not_s_c_l', null),
      one_cubic_meter_h: getFilled(rate, 'one_cubic_meter_h', ''),
      one_linear_meter_h: getFilled(rate, 'one_linear_meter_h', null),
      max_width: getFilled(rate, 'max_width', ''),
      max_length: getFilled(rate, 'max_length', ''),
      max_height: getFilled(rate, 'max_height', ''),
      max_weight: getFilled(rate, 'max_weight', ''),
    }),
    [rate, rateId]
  )

  const submit = (data, { setError }) => {
    const rateData = { ...data, type_of_collies: data.type_of_collies || 0 }
    if (!rateId && !rateData?.cubic_linear) delete rateData?.cubic_linear

    rateData.supplier_id = rateData.supplier?.value

    delete rateData.supplier
    delete rateData?.multiple_pod
    delete rateData?.multiple_pol

    rateCreationMutation.mutate(rateData, {
      onError: (e) => {
        handleErrorOnSubmit(e, setError, data)
      },
      onSuccess: (res) => {
        invalidate()
        showSuccessFlag(res?.message)
        navigate(`/panel/rates${rateId ? `/${rateId}` : ''}`)
      },
    })
  }

  return (
    <DenyFromUserLevel level="warehouse">
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <Grid gutter={24} className="w-full">
        <Grid.Col md={9} lg={6} offsetMd={3}>
          <PageHeader
            breadcrumbs={
              <Breadcrumbs>
                <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
                <BreadcrumbsItem text="Sales machine" />
                <BreadcrumbsItem text="Rates" to="/panel/rates" component={Link} />
              </Breadcrumbs>
            }
          >
            {pageTitle}
          </PageHeader>
        </Grid.Col>
      </Grid>

      {!!rateId && (isLoading || isFetching) ? (
        <div className="text-center my-8">
          <Spinner />
        </div>
      ) : isError ? (
        <Grid gutter={24} className="w-full">
          <Grid.Col md={6} offsetMd={3}>
            <SectionMessage
              appearance="warning"
              title="Something wrong on loading rate data, please retry"
            >
              <Button onClick={() => refetch()}>Retry</Button>
            </SectionMessage>
          </Grid.Col>
        </Grid>
      ) : (
        <Grid className="w-full">
          <Grid.Col md={6} offsetMd={3}>
            <Form defaultValues={defaultValues} onSubmit={submit}>
              {() => (
                <>
                  <RateCreationGeneralInformationFields />

                  <RateCreationSpecialConditionsFields />

                  <RateCreationCalculationFields />

                  <WarningBannerField />

                  <div className="mt-6 text-right">
                    <LoadingButton
                      type="submit"
                      appearance="primary"
                      isLoading={rateCreationMutation.isLoading}
                    >
                      {rateId ? 'Update' : 'Create'} Rate
                    </LoadingButton>
                  </div>
                </>
              )}
            </Form>
          </Grid.Col>
        </Grid>
      )}
    </DenyFromUserLevel>
  )
}

export default RateCreationPage
