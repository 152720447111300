import { useMemo, useState } from 'react'

//Atlassian
import Button from '@atlaskit/button'

const CollapsedWrapper = ({ items, maxItems }) => {
  const [showAll, setShowAll] = useState(false)

  const needsMoreButton = useMemo(() => items?.length > maxItems, [items, maxItems])

  const showItems = useMemo(() => {
    return needsMoreButton && !showAll ? maxItems : items?.length
  }, [items, maxItems, needsMoreButton, showAll])

  return (
    <>
      {items?.slice(0, showItems).map((item, index) => (
        <div key={index}>{item}</div>
      ))}

      {needsMoreButton && (
        <small className="table ml-auto">
          <Button spacing="none" appearance="subtle-link" onClick={() => setShowAll(!showAll)}>
            {showAll ? 'Show less' : `Show more (${items.length - maxItems})`}
          </Button>
        </small>
      )}
    </>
  )
}

export default CollapsedWrapper
