import { useMemo } from 'react'
import { useMediaQuery } from 'react-responsive'

import { transformSelectField } from 'utils/helpers'

import { discountCalculationMethodOptions, validCargoOptions } from '../constants'

//Lodash
import toNumber from 'lodash/toNumber'
import isNumber from 'lodash/isNumber'
import toString from 'lodash/toString'

//Hooks
import useShipmentsEnumerations from 'hooks/useShipmentsEnumerations'

//Atlassian
import Button, { LoadingButton } from '@atlaskit/button'
import Drawer from '@atlaskit/drawer'
import TextField from '@atlaskit/textfield'
import { ErrorMessage } from '@atlaskit/form'
import Select from '@atlaskit/select'
import { Checkbox } from '@atlaskit/checkbox'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'

//Interfaces
import { Field, Form, WarningBannerField, useFormContext } from '@royan-co/user-interface'

const StepDrawer = ({ onClose, isOpen, data, onSubmit, calculationMethod, isContainer }) => {
  const isMobile = useMediaQuery({ query: `(max-width: 991.98px)` })
  const isEditing = useMemo(() => data?.price, [data])

  const { watch } = useFormContext()
  const steps = watch('steps')

  const { getEnumerationOptionsByKey, isLoadingShipmentEnumeration } = useShipmentsEnumerations({
    enabled: calculationMethod === 6,
  })
  const transportationOptions = useMemo(() => {
    return getEnumerationOptionsByKey(isContainer ? 'container_types' : 'truck_types')
  }, [getEnumerationOptionsByKey, isContainer])

  const discountOptions = useMemo(() => {
    if (calculationMethod === 6)
      return discountCalculationMethodOptions.filter((i) => i.value !== 1)
    else return discountCalculationMethodOptions
  }, [calculationMethod])

  const defaultFromValue = useMemo(() => {
    if (!isEditing && [1, 2, 3, 5].includes(calculationMethod)) {
      // Gross weight, Chargeable weight, Freight charge,Number of collies
      const lastIntervalValue = toNumber(watch(`steps.${steps?.length - 1}.to`))
      const calculatedFromValue =
        lastIntervalValue && isNumber(lastIntervalValue) && lastIntervalValue >= 0
          ? toString(lastIntervalValue + 1)
          : ''
      return calculatedFromValue
    }
    return data?.from
  }, [calculationMethod, data?.from, isEditing, steps?.length, watch])

  return (
    <Drawer isOpen={isOpen} onClose={onClose} width={isMobile ? 'full' : 'medium'}>
      <div className="drawer">
        <h3>{isEditing ? 'Edit step' : 'New step'}</h3>

        <Form onSubmit={onSubmit} defaultValues={data}>
          {() => (
            <>
              {[1, 2, 3, 5].includes(calculationMethod) && (
                <div className="grid md:grid-cols-2 md:gap-x-4">
                  <Field
                    label={
                      calculationMethod === 1 || calculationMethod === 2
                        ? 'From (Kgs)'
                        : calculationMethod === 3
                        ? 'From (EUR)'
                        : calculationMethod === 5
                        ? 'From (Pkgs)'
                        : ''
                    }
                    name="from"
                    defaultValue={defaultFromValue}
                  >
                    {({ fieldProps, error }) => (
                      <>
                        <TextField {...fieldProps} />
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                      </>
                    )}
                  </Field>

                  <Field
                    label={
                      calculationMethod === 1 || calculationMethod === 2
                        ? 'To (Kgs)'
                        : calculationMethod === 3
                        ? 'To (EUR)'
                        : calculationMethod === 5
                        ? 'To (Pkgs)'
                        : ''
                    }
                    name="to"
                    defaultValue=""
                  >
                    {({ fieldProps, error }) => (
                      <>
                        <TextField {...fieldProps} />
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                      </>
                    )}
                  </Field>
                </div>
              )}

              {calculationMethod === 6 && (
                <Field
                  isRequired
                  type="select"
                  defaultValue=""
                  className="!mt-4"
                  name="transportation_unit_id"
                  transform={transformSelectField(transportationOptions)}
                  label={isContainer ? 'Container type' : 'Truck type'}
                >
                  {({ fieldProps, error }) => (
                    <>
                      <Select
                        isClearable={true}
                        options={transportationOptions}
                        placeholder="Choose something"
                        isLoading={isLoadingShipmentEnumeration}
                        {...fieldProps}
                      />
                      {error && <ErrorMessage>{error}</ErrorMessage>}
                    </>
                  )}
                </Field>
              )}

              <Field isRequired label="Rate" name="price" defaultValue="" className="!mt-4">
                {({ fieldProps, error }) => (
                  <>
                    <TextField
                      {...fieldProps}
                      elemAfterInput={
                        <div className="text-neutral-200 whitespace-nowrap bg-neutral-20 self-stretch flex items-center px-2">
                          Euro (€)
                        </div>
                      }
                    />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>

              {calculationMethod !== 6 && (
                <>
                  <Field label="Fixed rate" type="checkbox" name="fix" className="!mt-4">
                    {({ fieldProps }) => (
                      <Checkbox label="Rate is fixed in this step" {...fieldProps} />
                    )}
                  </Field>

                  <Field label="Additional fixed rate" name="fix_more" defaultValue="">
                    {({ fieldProps, error }) => (
                      <>
                        <TextField {...fieldProps} placeholder="Type the price" />
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                      </>
                    )}
                  </Field>
                </>
              )}

              <Field label="Discount" name="discount" defaultValue="" className="!mt-4">
                {({ fieldProps, error }) => (
                  <>
                    <TextField
                      {...fieldProps}
                      elemAfterInput={
                        <Field
                          name="discount_calculation_method"
                          defaultValue={0}
                          className="!mt-0 self-stretch flex"
                          transform={transformSelectField(discountOptions)}
                        >
                          {({ fieldProps: { value, onChange } }) => (
                            <DropdownMenu
                              placement="bottom-end"
                              trigger={({ triggerRef, onClick, isSelected }) => (
                                <button
                                  type="button"
                                  ref={triggerRef}
                                  onClick={onClick}
                                  className={`self-stretch whitespace-nowrap bg-neutral-20 flex items-center justify-center w-12 ${
                                    isSelected ? 'text-blue-400' : 'text-neutral-200'
                                  }`}
                                >
                                  {value?.label}
                                </button>
                              )}
                            >
                              <DropdownItemGroup>
                                {discountOptions.map((opt) => (
                                  <DropdownItem key={opt.value} onClick={() => onChange(opt)}>
                                    {opt.label}
                                  </DropdownItem>
                                ))}
                              </DropdownItemGroup>
                            </DropdownMenu>
                          )}
                        </Field>
                      }
                    />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>

              <Field
                type="select"
                label="Valid cargo"
                name="d_v_n"
                className="!mt-4"
                defaultValue={null}
                transform={transformSelectField(validCargoOptions)}
              >
                {({ fieldProps, error }) => (
                  <>
                    <Select options={validCargoOptions} placeholder="Choose one" {...fieldProps} />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>

              <WarningBannerField />

              <LoadingButton type="submit" className="mt-4" shouldFitContainer appearance="primary">
                {isEditing ? 'Edit' : 'Add'}
              </LoadingButton>
              <Button appearance="subtle" className="mt-2" shouldFitContainer onClick={onClose}>
                Cancel
              </Button>
            </>
          )}
        </Form>
      </div>
    </Drawer>
  )
}

export default StepDrawer
