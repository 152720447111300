import PropTypes from 'prop-types'
import { useAuth } from 'contexts/AuthProvider'
import NotFoundPage from 'views/NotFoundPage'

const DenyFromUserLevel = ({ children, level, fallback = <NotFoundPage /> }) => {
  const { isWarehouseUser } = useAuth()

  const levels = {
    warehouse: isWarehouseUser,
  }

  return levels[level] ? fallback : children
}

DenyFromUserLevel.propTypes = { level: PropTypes.oneOf(['warehouse', null]) }

export default DenyFromUserLevel
