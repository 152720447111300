import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useParams, useNavigate } from 'react-router'
import { useMutation } from '@tanstack/react-query'
import { Helmet } from 'react-helmet-async'

// Apis
import { editRegistries } from 'api/shipments'

// Hooks
import useShipment from 'hooks/useShipment'

// Utils
import { getAddressLabel, getContactLabel, getFilled } from 'utils/helpers'
import { handleErrorOnSubmit } from 'utils/errors'

// Atlassian
import PageHeader from '@atlaskit/page-header'
import Spinner from '@atlaskit/spinner'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'
import { LoadingButton } from '@atlaskit/button'

// Interfaces
import { Grid, Form, useFlags } from '@royan-co/user-interface'

// Components
import DenyFromUserLevel from 'layouts/DenyFromUserLevel'
import FormTabs from 'components/FormTabs'
import { registries } from 'components/Shipments/View/EditRegistries/registries'
import { tabs } from './tabs'

const ShipmentViewEditRegistriesPage = () => {
  const navigate = useNavigate()
  const { showSuccessFlag } = useFlags()
  const { shipmentId } = useParams()

  const { shipment, isLoading, invalidate, setLimitationExceptCreator } = useShipment()

  const editRegistriesMutation = useMutation(editRegistries.bind(null, shipmentId))

  const defaultValues = useMemo(() => {
    let values = {
      shipper_as_client: !!shipment?.shipper_id && shipment?.shipper_id === shipment?.client_id,
      loading_as_shipper: !!shipment?.pick_up_id && shipment?.pick_up_id === shipment?.shipper_id,
      loading_date: getFilled(shipment, 'loading_date', ''),
      loading_reference: getFilled(shipment, 'loading_reference', ''),
      delivery_as_Consignee:
        !!shipment?.delivery_id && shipment?.delivery_id === shipment?.consignee_id,
      delivery_date: getFilled(shipment, 'delivery_date', ''),
      notify_as_Consignee: !!shipment?.notify_id && shipment?.notify_id === shipment?.consignee_id,
      client_id: shipment?.client?.id_auto
        ? {
            label: shipment.client.company_name,
            value: shipment.client.id_auto,
            imported: shipment.client?.arca_imported,
          }
        : null,
      contacts: shipment?.contacts.map((c) => ({ label: c.full_name, value: c.id_auto })),
      departure_custom_needed: getFilled(shipment, 'departure_custom_needed', 0),
      departure_custom_nn_reason: getFilled(shipment, 'departure_custom_nn_reason', null),
      destination_custom_needed: getFilled(shipment, 'destination_custom_needed', 0),
      destination_custom_nn_reason: getFilled(shipment, 'destination_custom_nn_reason', null),
      destination_custom_terminal: getFilled(shipment, 'destination_terminal.id_auto', null),
      second_set: getFilled(shipment, 'second_set', 0),
    }

    Object.entries(registries).forEach(
      ([
        fieldName,
        {
          names: { registry: registryName, address: addressName, contact: contactName },
          default_value_keys: { registry: registryKey, address: addressKey, contact: contactKey },
        },
      ]) => {
        values[registryName] = shipment?.[registryKey]?.id_auto
          ? {
              label: shipment[registryKey].company_name,
              value: shipment[registryKey].id_auto,
              imported: shipment[registryKey]?.arca_imported,
            }
          : null
        values[addressName] = shipment?.[addressKey]?.id_auto
          ? { label: getAddressLabel(shipment[addressKey]), value: shipment[addressKey].id_auto }
          : null
        values[contactName] = shipment?.[contactKey]?.id_auto
          ? { label: getContactLabel(shipment[contactKey]), value: shipment[contactKey].id_auto }
          : null
      }
    )

    return values
  }, [shipment])

  const onSubmitEditRegistries = (data, { setError }) => {
    const getValue = (name) => {
      return data[name]?.value || null
    }

    const departure_needed = data.departure_custom_needed
    const destination_needed = data.destination_custom_needed
    const second_set = data.second_set

    const getRegistriesData = () => {
      let registryFields = {
        shipper_id: getValue('shipper_id'),
        shipper_address_id: getValue('shipper_address_id'),
        shipper_contact_id: getValue('shipper_contact_id'),
        pick_up_id: getValue('pick_up_id'),
        pick_up_address_id: getValue('pick_up_address_id'),
        pick_up_contact_id: getValue('pick_up_contact_id'),
        loading_date: data.loading_date || null,
        loading_reference: data.loading_reference || null,
        consignee_id: getValue('consignee_id'),
        consignee_address_id: getValue('consignee_address_id'),
        consignee_contact_id: getValue('consignee_contact_id'),
        delivery_id: getValue('delivery_id'),
        delivery_address_id: getValue('delivery_address_id'),
        delivery_contact_id: getValue('delivery_contact_id'),
        delivery_date: data.delivery_date || null,
        notify_id: getValue('notify_id'),
        notify_address_id: getValue('notify_address_id'),
        notify_contact_id: getValue('notify_contact_id'),
        agent_destination_id: getValue('agent_destination_id'),
        agent_destination_address_id: getValue('agent_destination_address_id'),
        agent_destination_contact_id: getValue('agent_destination_contact_id'),
        client_id: getValue('client_id'),
        contacts: data.contacts?.length > 0 ? data.contacts?.map((c) => c.value) : null,
        departure_custom_needed: departure_needed ? 1 : 0,
        destination_custom_needed: destination_needed ? 1 : 0,
      }

      if (departure_needed) {
        registryFields.custom_broker_id = getValue('custom_broker_id')
        registryFields.custom_broker_address_id = getValue('custom_broker_address_id')
        registryFields.custom_broker_contact_id = getValue('custom_broker_contact_id')
      } else {
        registryFields.departure_custom_nn_reason = data.departure_custom_nn_reason
      }

      if (destination_needed) {
        registryFields.custom_broker_destination_id = getValue('custom_broker_destination_id')
        registryFields.custom_broker_destination_address_id = getValue(
          'custom_broker_destination_address_id'
        )
        registryFields.custom_broker_destination_contact_id = getValue(
          'custom_broker_destination_contact_id'
        )
      } else {
        registryFields.destination_custom_terminal = data.destination_custom_terminal
        registryFields.destination_custom_nn_reason = data.destination_custom_nn_reason
      }

      if (second_set) {
        registryFields.second_shipper_id = getValue('second_shipper_id')
        registryFields.second_shipper_address_id = getValue('second_shipper_address_id')
        registryFields.second_shipper_contact_id = getValue('second_shipper_contact_id')
        registryFields.second_consignee_id = getValue('second_consignee_id')
        registryFields.second_consignee_address_id = getValue('second_consignee_address_id')
        registryFields.second_consignee_contact_id = getValue('second_consignee_contact_id')
      }

      return registryFields
    }

    const registriesData = getRegistriesData()

    editRegistriesMutation.mutate(registriesData, {
      onError: (e) => {
        handleErrorOnSubmit(e, setError, data)
      },
      onSuccess: (res) => {
        invalidate()
        showSuccessFlag(res?.message)
        navigate(`/panel/shipments/${shipmentId}`)
      },
    })
  }

  return (
    <DenyFromUserLevel level={setLimitationExceptCreator('warehouse')}>
      <Helmet>
        <title>Edit registries</title>
      </Helmet>

      <Grid className="w-full">
        <Grid.Col md={8} xl={6} offsetMd={2} offsetXl={3}>
          <PageHeader
            breadcrumbs={
              <Breadcrumbs>
                <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
                <BreadcrumbsItem text="Shipments" to="/panel/shipments" component={Link} />
                <BreadcrumbsItem
                  text="View shipment"
                  to={`/panel/shipments/${shipmentId}`}
                  component={Link}
                />
                <BreadcrumbsItem
                  text={isLoading ? <Spinner size="xsmall" /> : shipment?.shipment_code}
                  to={`/panel/shipments/${shipmentId}`}
                  component={Link}
                />
              </Breadcrumbs>
            }
          >
            Edit registries
          </PageHeader>

          <div className="py-4 -mx-2">
            {isLoading ? (
              <div className="flex justify-center my-32">
                <Spinner />
              </div>
            ) : (
              <Form onSubmit={onSubmitEditRegistries} defaultValues={defaultValues}>
                {({ formState }) => (
                  <>
                    <FormTabs tabs={tabs} errors={formState.errors} />

                    <LoadingButton
                      className="!table ml-auto mr-2"
                      appearance="primary"
                      type="submit"
                      isLoading={editRegistriesMutation.isLoading}
                    >
                      Save changes
                    </LoadingButton>
                  </>
                )}
              </Form>
            )}
          </div>
        </Grid.Col>
      </Grid>
    </DenyFromUserLevel>
  )
}

export default ShipmentViewEditRegistriesPage
