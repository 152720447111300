import { findOption } from 'utils/helpers'
import { shipmentDOOptions } from './constants'

//Atlassian
import Lozenge from '@atlaskit/lozenge'

const ShipmentDOTag = ({ value }) => {
  const option = findOption(shipmentDOOptions, value)

  return option ? <Lozenge appearance={option.appearance}>{option.label}</Lozenge> : null
}

export default ShipmentDOTag
