import { Link, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

//Api
import { getDocumentTypes } from '../api/document-types-requests'

//Atlassian
import PageHeader from '@atlaskit/page-header'
import Button, { ButtonGroup } from '@atlaskit/button'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'

//Contexts
import { useAuth } from 'contexts/AuthProvider'

//Interfaces
import { RoyanTable, useDisclosure } from '@royan-co/user-interface'

//Components
import { generateDocumentTypesColumns } from '../components/List/columns'
import DocumentTypesDeleteModal from '../components/views/DeleteModal'

const pageTitle = 'Document types'

const DocumentTypesListPage = () => {
  const navigate = useNavigate()
  const { currentUser } = useAuth()

  const [isOpenDeleteTypeModal, deleteTypeModalHandler] = useDisclosure('delete')

  const openDeleteModal = (typeId) => {
    deleteTypeModalHandler.open({ typeId })
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <PageHeader
        breadcrumbs={
          <Breadcrumbs>
            <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
            <BreadcrumbsItem text="Accounting" />
          </Breadcrumbs>
        }
        actions={
          <ButtonGroup>
            <Button appearance="primary" onClick={() => navigate('/panel/document-types/create')}>
              New document type
            </Button>
          </ButtonGroup>
        }
      >
        {pageTitle}
      </PageHeader>

      <RoyanTable
        sortOrder="DESC"
        name="document-typecodes-list"
        sortKey="code"
        request={getDocumentTypes}
        filters={[]}
        columns={generateDocumentTypesColumns(openDeleteModal, currentUser)}
      >
        <DocumentTypesDeleteModal
          isOpen={isOpenDeleteTypeModal}
          onClose={deleteTypeModalHandler.close}
          typeId={deleteTypeModalHandler.data?.typeId}
        />
      </RoyanTable>
    </>
  )
}

export default DocumentTypesListPage
