// Atlassian
import Tooltip from '@atlaskit/tooltip'

// Styles
import styles from './DisabledElementTooltip.module.scss'

const DisabledElementTooltip = ({ tooltipContent, isDisabled = false, children }) => {
  return (
    <Tooltip content={isDisabled ? tooltipContent : null}>
      {(tooltipProps) => (
        <div className={isDisabled ? styles.disabled__button : ''} {...tooltipProps}>
          {children}
        </div>
      )}
    </Tooltip>
  )
}

export default DisabledElementTooltip
