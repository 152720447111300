import { useQuery, useQueryClient } from '@tanstack/react-query'

// API
import { getLineRoute } from '../api/requests'

const useLineRoute = (routeId, options = {}) => {
  const {
    data = [],
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ['line-route', routeId],
    queryFn: () => getLineRoute(routeId),
    retry: 2,
    staleTime: Infinity,
    ...options,
  })

  return {
    data,
    isLoading,
    isError,
    refetch,
  }
}

export default useLineRoute
