import { useQuery } from '@tanstack/react-query'

// Apis
import { getStock } from './stock-requests'

/**
 * get AWB stock detail
 * @param {String|Number} stockId
 * @param {import('@tanstack/react-query').UseQueryOptions} options
 * @returns {import('@tanstack/react-query').UseQueryResult}
 */

const useStock = (stockId, options = {}) => {
  return useQuery({
    queryKey: ['stock', stockId],
    queryFn: () => getStock(stockId),
    staleTime: Infinity,
    retry: 2,
    ...options,
  })
}

export { useStock }
