import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

import { cancelShipment } from 'api/shipments'

//Atlassian
import Modal, {
  ModalBody,
  ModalTitle,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import Button, { LoadingButton } from '@atlaskit/button'

//Interfaces
import { useFlags, useRoyanTable, WarningBanner } from '@royan-co/user-interface'

const ShipmentsCancelModal = ({ isOpen, onClose, shipmentId, entity }) => {
  const { refresh } = useRoyanTable()
  const { showSuccessFlag } = useFlags()
  const navigate = useNavigate()

  const shipmentCancelMutation = useMutation(cancelShipment)

  function closeModal() {
    onClose()
    shipmentCancelMutation.reset()
  }

  const handleCancelShipment = async () => {
    try {
      const res = await shipmentCancelMutation.mutateAsync(shipmentId)
      showSuccessFlag(res?.message)

      // if delete from list, close the modal and refresh table
      if (refresh) {
        refresh()
        closeModal()
        // else if delete from view rate, navigate to list
      } else {
        navigate(`/panel/${entity}s`, { replace: true })
      }
    } catch (error) {
      console.error(error)
    }
  }
  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={closeModal}>
          <ModalHeader>
            <ModalTitle appearance="warning">Cancel {entity}</ModalTitle>
          </ModalHeader>
          <ModalBody>
            You are about to cancel a {entity}. Are you sure?
            {shipmentCancelMutation.isError && <WarningBanner />}
          </ModalBody>
          <ModalFooter>
            <Button appearance="subtle" onClick={closeModal}>
              Back
            </Button>
            <LoadingButton
              isLoading={shipmentCancelMutation.isLoading}
              appearance="warning"
              onClick={handleCancelShipment}
              autoFocus
            >
              Cancel {entity}
            </LoadingButton>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  )
}

export default ShipmentsCancelModal
