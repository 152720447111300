// Atlassian
import EmptyState from '@atlaskit/empty-state'

//Components
import Card from 'components/UI/Card'
import EditFinanceTableFields from './TableFields'
import { useFieldArray, useFormValues } from '@royan-co/user-interface'

const EditFinanceAccessoriesCard = () => {
  const { accessories } = useFormValues()
  const { remove: removeAccessory } = useFieldArray({ name: `accessories` })

  return (
    <Card title="Accessories" className="mb-3 mt-6">
      {!accessories.length ? (
        <EmptyState header="No accessories" />
      ) : (
        <div className="overflow-auto w-full inline-block -mb-6 removeAtlaskitTableBorder lessPaddingAtlaskitTable">
          <EditFinanceTableFields keyName="accessories" removeRow={removeAccessory} />
        </div>
      )}
    </Card>
  )
}

export default EditFinanceAccessoriesCard
