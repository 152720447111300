import { useMemo, useState } from 'react'
import { findOption } from 'utils/helpers'
import { useMutation } from '@tanstack/react-query'

// Hooks
import useShipmentsAgents from 'hooks/useShipmentsAgents'

// Atlassian
import Select from '@atlaskit/select'
import Spinner from '@atlaskit/spinner'
import Lozenge from '@atlaskit/lozenge'
import InlineEdit from '@atlaskit/inline-edit'

// Interfaces
import { useFlags } from '@royan-co/user-interface'

const ShipmentAgentInlineEdit = ({ agent, canEdit, changeAgentFn, changeAgentCallback }) => {
  const { showSuccessFlag, showWarningFlag } = useFlags()
  const [isEditMode, setIsEditMode] = useState(false)

  const { shipmentsAgents, isLoadingShipmentsAgents } = useShipmentsAgents({
    enabled: isEditMode,
  })

  const agentsOptions = useMemo(
    () =>
      shipmentsAgents.length === 0 && agent?.id
        ? [{ label: agent.full_name, value: agent.id }]
        : shipmentsAgents?.map((a) => ({ label: a.full_name, value: a.id })),
    [shipmentsAgents, agent]
  )

  const changeAgentMutation = useMutation({
    mutationFn: (data) => changeAgentFn(data),

    onSuccess: (res) => {
      showSuccessFlag(res.message)

      changeAgentCallback(res)
    },

    onError: (e) => {
      console.error(e)
      showWarningFlag(e?.response?.data?.message || 'Something wrong happened.')
    },
  })

  const handleChangeOperator = (data) => {
    if (agent?.id === data.value) return

    changeAgentMutation.mutate(data)
  }

  const viewTag = agent?.id ? agent?.full_name : <Lozenge appearance="default">Not set</Lozenge>

  return !canEdit ? (
    viewTag
  ) : (
    <div className="!-mt-2">
      <InlineEdit
        keepEditViewOpenOnBlur={true}
        onEdit={() => setIsEditMode(true)}
        defaultValue={findOption(agentsOptions, agent?.id)}
        editView={({ errorMessage, ...fieldProps }) => (
          <Select
            spacing="compact"
            options={agentsOptions}
            isLoading={isLoadingShipmentsAgents}
            {...fieldProps}
          />
        )}
        readView={() => (
          <div className="flex items-center font-normal gap-1 my-1.5">
            {viewTag}
            {changeAgentMutation.isLoading && <Spinner size="small" />}
          </div>
        )}
        onConfirm={(value) => handleChangeOperator(value)}
      />
    </div>
  )
}

export default ShipmentAgentInlineEdit
