import { Editor as CKEditor } from '@royan-co/user-interface'

//Styles
import styles from './Editor.module.scss'

//Atlassian
import Spinner from '@atlaskit/spinner'

const Editor = ({ defaultValue, disabled, isLoading, setEmailContent }) => {
  return (
    <div className={styles.editor}>
      <div className={styles.editor__Label}>
        Content <span>*</span>
        {isLoading && <Spinner size={12} />}
      </div>

      <div className={styles.editor__Content}>
        <CKEditor
          defaultValue={defaultValue}
          disabled={isLoading || disabled}
          onChange={(data) => setEmailContent(data)}
        />
      </div>
    </div>
  )
}

export default Editor
