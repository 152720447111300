import { useCallback, useMemo } from 'react'

// Utils
import { formatMonetaryNumber, formatNumber } from 'utils/numbers'

//Atlassian
import Modal, {
  ModalBody,
  ModalTitle,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import Button from '@atlaskit/button'

// Interfaces
import { CautionIcon, useDisclosure } from '@royan-co/user-interface'

// Components
import RowTable from 'components/UI/RowTable'
import Divider from 'components/UI/Divider'

const CardItem = ({ value, valueColor, unit, label, action }) => {
  return (
    <div className="shadow rounded flex items-center justify-between  px-5 py-3 grow w-full">
      <div>
        <div className="flex items-baseline wrap">
          <h3 className={`text-neutral-800 font-medium ${valueColor}`}>{value}</h3>

          <span className="ml-1">{unit}</span>
        </div>

        <p className="text-neutral-200 mt-1">{label}</p>
      </div>

      <div>{action ? action : null}</div>
    </div>
  )
}

const MarginAction = ({ data }) => {
  const [isOpenActionModal, actionModalHandler] = useDisclosure('margin')

  const getValue = useCallback(
    (value, className) => (
      <div className="flex items-baseline wrap justify-end">
        <p className={className}>{formatMonetaryNumber(value || '0')?.replace('-', '')}</p>

        <small className="ml-1">EUR</small>
      </div>
    ),
    []
  )

  return (
    <>
      <Button appearance="link" onClick={() => actionModalHandler.open()}>
        <CautionIcon className="text-neutral-500" size={24} />
      </Button>

      <ModalTransition>
        {isOpenActionModal && (
          <Modal onClose={actionModalHandler.close} width={'small'}>
            <ModalHeader>
              <ModalTitle>Margin details</ModalTitle>
            </ModalHeader>

            <ModalBody>
              <RowTable
                rows={[
                  {
                    title: 'Quotation - Shipments',
                    content: getValue(data.quotationShipments, 'text-green-400'),
                  },
                  {
                    title: 'Cost - Shipments',
                    content: getValue(data.costShipments, 'text-red-400'),
                  },
                  {
                    title: 'Cost - Consolidation',
                    content: getValue(data.costConsolidation, 'text-red-400'),
                  },
                ]}
                verticalAlign="align-center"
                width="w-1/2"
              />

              <Divider className="mb-1 mt-2" />

              <RowTable
                rows={[
                  {
                    title: 'Total',
                    content: getValue(
                      data.total,
                      data.total === 0 ? '' : data.total < 0 ? 'text-red-400' : 'text-green-400'
                    ),
                  },
                ]}
                verticalAlign="align-center"
                width="w-1/2"
              />
            </ModalBody>

            <ModalFooter>
              <Button appearance="subtle" onClick={actionModalHandler.close}>
                Discard
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </>
  )
}

const ConsolidationSelectedShipmentsTotals = ({ selectedShipments, agreedCost }) => {
  const totalWeight = useMemo(
    () =>
      formatNumber(
        selectedShipments?.reduce((p, c) => p + c?.weight * 1, 0),
        3
      ),
    [selectedShipments]
  )

  const totalChargeableWeight = useMemo(
    () =>
      formatNumber(
        selectedShipments?.reduce((p, c) => p + c?.chargeable_weight * 1, 0),
        3
      ),
    [selectedShipments]
  )

  const totalLDM = useMemo(
    () =>
      formatNumber(
        selectedShipments?.reduce((p, c) => p + c?.ldm * 1, 0),
        3
      ),
    [selectedShipments]
  )

  const totalMargin = useMemo(() => {
    const shipmentsMargin = selectedShipments?.reduce(
      (p, c) => p + (c?.financial_status?.margin * 1 || 0),
      0
    )

    return (shipmentsMargin * 1 || 0) - (agreedCost * 1 || 0)
  }, [agreedCost, selectedShipments])

  const quotationShipments = selectedShipments?.reduce(
    (p, c) => p + (c?.financial_status?.quotation * 1 || 0),
    0
  )

  const costShipments = selectedShipments?.reduce(
    (p, c) => p + (c?.financial_status?.cost * 1 || 0),
    0
  )

  return (
    <div className="flex flex-col md:flex-row gap-4 mb-5">
      <CardItem
        value={formatMonetaryNumber(totalMargin || '0')?.replace('-', '')}
        valueColor={totalMargin === 0 ? '' : totalMargin < 0 ? '!text-red-400' : '!text-green-400'}
        unit={'EUR'}
        label={'Margin'}
        action={
          <MarginAction
            data={{
              quotationShipments: quotationShipments,
              costShipments: costShipments,
              costConsolidation: agreedCost,
              total: totalMargin,
            }}
          />
        }
      />
      <CardItem value={totalWeight} unit={'Kgs'} label={'Gross weight'} />
      <CardItem value={totalChargeableWeight} unit={'Kgs'} label={'Chargeable weight'} />
      <CardItem value={totalLDM} unit={'LDM'} label={'Loading meter'} />
    </div>
  )
}

export default ConsolidationSelectedShipmentsTotals
