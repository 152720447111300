// Utils
import { findOption } from 'utils/helpers'

// Hooks
import useUserLevels from 'hooks/useUserLevels'

// Atlassian
import Spinner from '@atlaskit/spinner'

const RoleFilter = ({ value }) => {
  const { userLevelsOptions, isLoadingUserLevels } = useUserLevels()

  return (
    <>
      Role:{' '}
      {isLoadingUserLevels ? (
        <Spinner size="xsmall" />
      ) : (
        findOption(userLevelsOptions, value)?.label
      )}
    </>
  )
}

export const usersFilters = [
  {
    key: 'registry',
    content: (v) => `Registry: ${v}`,
  },
  {
    key: 'role',
    content: (v) => <RoleFilter value={v} />,
  },
]
