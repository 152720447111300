import { useState, useMemo } from 'react'

//Services
import { temperatureControlOptions } from './constants'

//Atlassian
import Select from '@atlaskit/select'
import { ErrorMessage } from '@atlaskit/form'
import Button from '@atlaskit/button/standard-button'
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTransition } from '@atlaskit/modal-dialog'

//Interfaces
import { Field, Form, RangeSlider } from '@royan-co/user-interface'

const ShipmentCreationTemperatureControlModal = ({ isOpen, onDiscard, onSubmit, defaultRange }) => {
  const [formState, setFormState] = useState({})

  const handleSubmit = ({ range }) => {
    onSubmit && onSubmit(range)
  }

  const selectedReeferOption = useMemo(() => {
    if (!defaultRange) return
    let [min, max] = defaultRange
    const selected = temperatureControlOptions.find(
      (o) => o.value.range.min === min && o.value.range.max === max
    )
    return selected || temperatureControlOptions.find((o) => o.value.type === 'custom')
  }, [defaultRange])

  return (
    <ModalTransition>
      {isOpen && (
        <Modal width="small" shouldScrollInViewport>
          <Form
            watch={setFormState}
            onSubmit={handleSubmit}
            defaultValues={{ reefer: selectedReeferOption, range: defaultRange || [-10, 25] }}
          >
            {({ setValue }) => (
              <>
                <ModalHeader>Temperature control</ModalHeader>
                <ModalBody>
                  <Field isRequired type="select" name="reefer" label="Range">
                    {({ fieldProps: { onChange, ...fieldProps }, errorType }) => (
                      <div>
                        <Select
                          placeholder="Choose a range"
                          onChange={(v) => {
                            onChange(v)
                            setValue('range', [
                              v?.value?.range?.min || defaultRange?.[0],
                              v?.value?.range?.max || defaultRange?.[1],
                            ])
                          }}
                          options={temperatureControlOptions}
                          {...fieldProps}
                        />
                        {errorType && <ErrorMessage>Please select range</ErrorMessage>}
                      </div>
                    )}
                  </Field>

                  {formState?.reefer?.value?.type === 'custom' && (
                    <div className="mt-3 mb-4">
                      <Field name="range" max={30} min={-25}>
                        {({ fieldProps: { ref, ...fieldProps } }) => (
                          <RangeSlider {...fieldProps} />
                        )}
                      </Field>
                    </div>
                  )}
                </ModalBody>

                <ModalFooter>
                  <Button onClick={onDiscard} appearance="subtle" type="button">
                    Discard
                  </Button>
                  <Button type="submit" appearance="primary">
                    Save
                  </Button>
                </ModalFooter>
              </>
            )}
          </Form>
        </Modal>
      )}
    </ModalTransition>
  )
}

export default ShipmentCreationTemperatureControlModal
