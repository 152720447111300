import axios from 'services/Request'

export function getAirwaybillFormDefaultValues(documentId) {
  return axios.get(`/api/shipments/documents/airwaybills/fetch/${documentId}`)
}

export function editAirwaybillDocument(documentId, data) {
  return axios.post(`/api/shipments/documents/airwaybills/edit/${documentId}`, data)
}

export function downloadAirwaybillDocument({ documentId, format }) {
  return axios.get(`/api/shipments/documents/airwaybills/download/${documentId}/${format}`, {
    responseType: 'arraybuffer',
  })
}
