import { useEffect, useMemo } from 'react'

import { findOption, transformSelectField } from 'utils/helpers'
import {
  calculationMethodOptions,
  calculationOnOptions,
  roundingOptions,
  calculationOnNotStackableOptions,
  calculationCBMValue,
  calculationLDMValue,
  calculationUnitOptions,
  formatOptions,
} from '../constants'

//Atlassian
import Select from '@atlaskit/select'
import Textfield from '@atlaskit/textfield'
import { ErrorMessage, Label } from '@atlaskit/form'
import Toggle from '@atlaskit/toggle'
import { Checkbox } from '@atlaskit/checkbox'

//Interfaces
import { Field, Grid, InputGroup, useFormContext, useFormValues } from '@royan-co/user-interface'

//Components
import Card from 'components/UI/Card'

const calculationOnItems = [
  {
    label: 'CBM calculation value',
    name: 'one_cubic_meter',
    options: calculationCBMValue,
    relatedCalculationOn: [0, 2],
  },
  {
    label: 'LDM calculation value',
    name: 'one_linear_meter',
    options: calculationLDMValue,
    relatedCalculationOn: [1, 2],
  },
]

const RateCreationCalculationFields = () => {
  const { setValue } = useFormContext()
  const {
    type: format,
    h_not_s: notStackableGoods,
    cubic_linear: calculationOn,
    h_not_s_c_l: calculationOnNotStackable,
    calculation_method: calculationMethod,
  } = useFormValues()

  const computedCalculationMethodOptions = useMemo(() => {
    let formatOption = findOption(formatOptions, format)

    return formatOption?.calculationMethods
      ? calculationMethodOptions.filter((o) => formatOption.calculationMethods.includes(o.value))
      : calculationMethodOptions
  }, [format])

  useEffect(() => {
    calculationOnItems.forEach((calculationOnItem) => {
      if (!calculationOnItem.relatedCalculationOn.includes(calculationOn)) {
        setValue(calculationOnItem.name, null)
      }
    })

    if (calculationMethod === 6) {
      setValue('max_width', '')
      setValue('max_length', '')
      setValue('max_height', '')
      setValue('max_weight', '')
    }

    if (calculationMethod !== 0) {
      setValue('cost', '')
      setValue('margin', '')
    }
    if (!notStackableGoods) {
      setValue('h_not_s_c_l', null)
      setValue('one_cubic_meter_h', '')
      setValue('one_linear_meter_h', null)
    }
    if (calculationMethod !== 2) {
      setValue('each', null)
      setValue('cubic_linear', null)
      setValue('one_cubic_meter', null)
      setValue('one_linear_meter', null)
      setValue('rounding', null)
      setValue('h_not_s', 0)
      setValue('h_not_s_c_l', null)
      setValue('one_cubic_meter_h', '')
      setValue('one_linear_meter_h', null)
    }
  }, [calculationMethod, calculationOn, notStackableGoods, setValue])

  useEffect(() => {
    if (
      calculationMethod !== null &&
      !computedCalculationMethodOptions.some((o) => o.value === calculationMethod)
    ) {
      setValue('calculation_method', null)
    }
  }, [calculationMethod, computedCalculationMethodOptions, format, setValue])

  return (
    <Card className="mb-6" title="Calculation">
      <Field
        name="allow_calculate"
        label="Allow calculation"
        type="checkbox"
        key="toggle-allow_calculate"
      >
        {({ fieldProps, error }) => (
          <>
            <div>
              <Toggle {...fieldProps} id="toggle-allow_calculate" />
            </div>
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </>
        )}
      </Field>

      <Field
        isRequired
        label="Method of calculation"
        name="calculation_method"
        type="select"
        transform={transformSelectField(computedCalculationMethodOptions)}
      >
        {({ fieldProps, error }) => (
          <>
            <Select
              isClearable={true}
              menuPlacement="auto"
              options={computedCalculationMethodOptions}
              placeholder="Choose a method"
              {...fieldProps}
            />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </>
        )}
      </Field>

      {calculationMethod === 2 && (
        <>
          <Grid gutter="md" className="mt-2">
            <Grid.Col sm={6}>
              <Field
                label="Calculation unit"
                name="each"
                type="select"
                className="!mt-0"
                transform={transformSelectField(calculationUnitOptions)}
              >
                {({ fieldProps, error }) => (
                  <>
                    <Select
                      isClearable={true}
                      options={calculationUnitOptions}
                      placeholder="Choose one"
                      {...fieldProps}
                    />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>
            </Grid.Col>

            <Grid.Col sm={6}>
              <Field
                label="Calculation on"
                name="cubic_linear"
                type="select"
                className="!mt-0"
                transform={transformSelectField(calculationOnOptions)}
              >
                {({ fieldProps, error }) => (
                  <>
                    <Select
                      isClearable={true}
                      options={calculationOnOptions}
                      placeholder="Choose one"
                      {...fieldProps}
                    />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>
            </Grid.Col>
          </Grid>

          <Grid grow gutter="md" className="mt-2">
            {calculationOnItems.map(
              (calculationOnItem) =>
                calculationOnItem.relatedCalculationOn.includes(calculationOn) && (
                  <Grid.Col sm={6} key={calculationOnItem.name}>
                    <Field
                      label={calculationOnItem.label}
                      name={calculationOnItem.name}
                      type="select"
                      className="!mt-0"
                      transform={transformSelectField(calculationOnItem.options)}
                    >
                      {({ fieldProps, error }) => (
                        <>
                          <Select
                            isClearable={true}
                            options={calculationOnItem.options}
                            placeholder="Choose one"
                            {...fieldProps}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                  </Grid.Col>
                )
            )}

            <Grid.Col sm={6}>
              <Field
                label="Rounding"
                name="rounding"
                type="select"
                className="!mt-0"
                transform={transformSelectField(roundingOptions)}
              >
                {({ fieldProps, error }) => (
                  <>
                    <Select
                      isClearable={true}
                      menuPlacement="auto"
                      options={roundingOptions}
                      placeholder="Choose one"
                      {...fieldProps}
                    />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>
            </Grid.Col>
          </Grid>

          <Field type="checkbox" label="LDM calculation value" name="h_not_s" className="!mt-4">
            {({ fieldProps }) => (
              <Checkbox label="Different calculation for not-stackable goods" {...fieldProps} />
            )}
          </Field>

          {!!notStackableGoods && (
            <>
              <Grid grow gutter="md" className="mt-2">
                <Grid.Col sm={6}>
                  <Field
                    label="Calculation on"
                    name="h_not_s_c_l"
                    type="select"
                    className="!mt-0"
                    transform={transformSelectField(calculationOnNotStackableOptions)}
                  >
                    {({ fieldProps, error }) => (
                      <>
                        <Select
                          isClearable={true}
                          menuPlacement="auto"
                          options={calculationOnNotStackableOptions}
                          placeholder="Choose one"
                          {...fieldProps}
                        />
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                      </>
                    )}
                  </Field>
                </Grid.Col>

                {calculationOnNotStackable === 0 && (
                  <Grid.Col sm={6}>
                    <Field label="Fixed height" name="one_cubic_meter_h" className="!mt-0">
                      {({ fieldProps, error }) => (
                        <>
                          <Textfield
                            placeholder="Type a height"
                            {...fieldProps}
                            elemAfterInput={
                              <div className="text-neutral-200 whitespace-nowrap bg-neutral-20 self-stretch flex items-center px-2">
                                cm
                              </div>
                            }
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                  </Grid.Col>
                )}

                {calculationOnNotStackable === 1 && (
                  <Grid.Col sm={6}>
                    <Field
                      label="LDM calculation value"
                      name="one_linear_meter_h"
                      type="select"
                      className="!mt-0"
                      menuPlacement="auto"
                      transform={transformSelectField(calculationLDMValue)}
                    >
                      {({ fieldProps, error }) => (
                        <>
                          <Select
                            isClearable={true}
                            options={calculationLDMValue}
                            placeholder="Choose one"
                            {...fieldProps}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                  </Grid.Col>
                )}
              </Grid>
            </>
          )}
        </>
      )}

      {format === 1 && (
        <Grid gutter="md" className="mt-2">
          <Grid.Col sm={6}>
            <Field isRequired label="Cost" name="cost" className="!mt-0">
              {({ fieldProps, error }) => (
                <>
                  <Textfield
                    placeholder="Type the amount"
                    {...fieldProps}
                    elemAfterInput={
                      <div className="text-neutral-200 whitespace-nowrap bg-neutral-20 self-stretch flex items-center px-2">
                        Euro (€)
                      </div>
                    }
                  />
                  {error && <ErrorMessage>{error}</ErrorMessage>}
                </>
              )}
            </Field>
          </Grid.Col>

          <Grid.Col sm={6}>
            <Field isRequired label="Quote" name="margin" className="!mt-0">
              {({ fieldProps, error }) => (
                <>
                  <Textfield
                    placeholder="Type the amount"
                    {...fieldProps}
                    elemAfterInput={
                      <div className="text-neutral-200 whitespace-nowrap bg-neutral-20 self-stretch flex items-center px-2">
                        Euro (€)
                      </div>
                    }
                  />
                  {error && <ErrorMessage>{error}</ErrorMessage>}
                </>
              )}
            </Field>
          </Grid.Col>
        </Grid>
      )}

      {!!calculationMethod && calculationMethod !== 6 && (
        <>
          <div className="mt-4">
            <Label>Maximum dimensions (cm)</Label>
            <InputGroup>
              <Field isRequired name="max_width" className="basis-1/4 !mt-0">
                {({ fieldProps }) => <Textfield placeholder="Width" {...fieldProps} />}
              </Field>
              <Field isRequired name="max_length" className="basis-1/4 !mt-0">
                {({ fieldProps }) => <Textfield placeholder="Length" {...fieldProps} />}
              </Field>
              <Field isRequired name="max_height" className="basis-1/4 !mt-0">
                {({ fieldProps }) => <Textfield placeholder="Height" {...fieldProps} />}
              </Field>
              <Field isRequired name="max_weight" className="basis-1/4 !mt-0">
                {({ fieldProps }) => <Textfield placeholder="Weight" {...fieldProps} />}
              </Field>
            </InputGroup>
          </div>
        </>
      )}
    </Card>
  )
}

export default RateCreationCalculationFields
