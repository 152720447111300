import { useMemo } from 'react'
import { modes } from './modes'
import useShipmentsEnumerations from 'hooks/useShipmentsEnumerations'

//Services
import { capitalizeFirstLetter, findOption } from 'utils/helpers'

//Atlassian
import Lozenge from '@atlaskit/lozenge'
import { DynamicTableStateless } from '@atlaskit/dynamic-table'

//Interfaces
import { useWizardFormContext } from '@royan-co/user-interface'

const ShipmentCreationGroupageSummary = () => {
  const {
    stepsValue: { routing, cargo },
  } = useWizardFormContext()
  const { getEnumerationOptionsByKey } = useShipmentsEnumerations()

  const mode = useMemo(() => modes.find((m) => m.id === routing?.modeType), [routing?.modeType])

  const packagesHeads = {
    cells: [
      { key: 'type', content: 'Type' },
      { key: 'quantity', content: 'Quantity' },
      { key: 'dimensions', content: 'Dimensions (W x L x H)' },
      cargo?.type_of_weight === 0 && { key: 'weight', content: 'Weight (Kgs)' },
      { key: 'stackable', content: 'Stackable' },
    ],
  }

  const packagesRows = cargo?.packages.map((item, i) => ({
    key: `item${i}`,
    cells: [
      {
        key: `type${i}`,
        content: (
          <div className="mt-2">
            {findOption(getEnumerationOptionsByKey('packaging_types'), item.box_type)?.label}
          </div>
        ),
      },
      {
        key: `quantity${i}`,
        content: <div className="mt-2">{item?.quantity || 0}</div>,
      },
      {
        key: `dimensions${i}`,
        content: (
          <div className="mt-2">
            {`${item?.width || 0}cm x ${item?.length || 0}cm x ${item?.height || 0}cm`}
          </div>
        ),
      },
      cargo?.type_of_weight === 0 && {
        key: `weight${i}`,
        content: <div className="mt-2">{item?.weight || 0} Kgs</div>,
      },
      {
        key: `stackable${i}`,
        content: (
          <div className="mt-2">
            {item.is_stackable ? (
              <Lozenge appearance="success">yes</Lozenge>
            ) : (
              <Lozenge appearance="moved">no</Lozenge>
            )}
          </div>
        ),
      },
    ],
  }))

  return cargo?.is_checked_packaging_details ? (
    <div className="mt-8">
      <div className="flex justify-between items-center">
        <h3>{capitalizeFirstLetter(mode.cargoKey)}</h3>
      </div>

      <div className="shadow rounded p-4 mt-2">
        {packagesRows.length === 0 ? (
          <div className="text-xs text-neutral-200">You don't have any {mode.cargoKey}</div>
        ) : (
          <div className="inline-block w-full -mb-6 removeAtlaskitTableBorder">
            <DynamicTableStateless head={packagesHeads} rows={packagesRows} />
          </div>
        )}
      </div>
    </div>
  ) : null
}

export default ShipmentCreationGroupageSummary
