import { useState, useCallback } from 'react'

const useOnloadMap = (pins) => {
  const [map, setMap] = useState(null)

  const onLoad = useCallback(
    async function callback(map) {
      const bounds = new window.google.maps.LatLngBounds()

      pins?.forEach((pin) => {
        bounds.extend(new window.google.maps.LatLng(pin.lat, pin.lng))
      })

      map?.fitBounds(bounds)

      setMap(map)
    },
    [pins]
  )

  return { onLoad, map }
}

export default useOnloadMap
