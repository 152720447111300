import axios from 'services/Request'

export function uploadAttachment(data, controller) {
  return axios.post('/api/upload', data, {
    signal: controller?.signal || controller,
  })
}

export function uploadFileAttachment(file, signal) {
  const formData = new FormData()
  formData.append('method', 'attachment')
  formData.append('file', file)

  return uploadAttachment(formData, signal)
}
