import { modes } from './modes'
import { useMemo } from 'react'
import { capitalizeFirstLetter, transformSelectField } from 'utils/helpers'

//Services
import useShipmentsEnumerations from 'hooks/useShipmentsEnumerations'

//Atlassian
import Button from '@atlaskit/button'
import Toggle from '@atlaskit/toggle'
import Select from '@atlaskit/select'
import Textfield from '@atlaskit/textfield'
import { DynamicTableStateless } from '@atlaskit/dynamic-table'

//Interfaces
import {
  Field,
  TrashIcon,
  SettingsIcon,
  useFieldArray,
  useFormContext,
  useWizardFormContext,
  WarningIcon,
  useHistoryModal,
} from '@royan-co/user-interface'

//Components
import ShipmentCreationTemperatureControlModal from './TemperatureControlModal'

const tableHead = {
  cells: [
    { key: 'type', content: 'Type', width: 42 },
    { key: 'quantity', content: 'Quantity', width: 20 },
    { key: 'reefer', content: 'Temperature control', width: 26 },
    { key: 'removeIcon', content: '', width: 12 },
  ],
}

const ShipmentCreationDedicatedFields = () => {
  const { setValue, formState } = useFormContext()
  const { openModal, closeModal, modalData, isOpenModal } = useHistoryModal('temperature')

  const {
    stepsValue: { routing, cargo },
  } = useWizardFormContext()

  const mode = useMemo(() => modes.find((m) => m.id === routing?.modeType), [routing?.modeType])

  const {
    fields: packagesFields,
    append: appendPackage,
    remove: removePackage,
  } = useFieldArray({ name: mode.cargoKey })

  const addPackage = () => {
    appendPackage({ type: '', quantity: '', reefer: false, reefer_temperature: null })
  }

  const { getEnumerationOptionsByKey, isLoadingShipmentEnumeration } = useShipmentsEnumerations()

  const tableRows = useMemo(
    () =>
      packagesFields.map((p, i) => ({
        key: p.id,
        cells: [
          {
            content: (
              <Field
                isRequired
                type="select"
                className="!mt-0"
                name={`${mode.cargoKey}.${i}.box_type`}
                transform={transformSelectField(getEnumerationOptionsByKey(mode.cargoTypeKey))}
              >
                {({ fieldProps }) => (
                  <Select
                    placeholder="Choose"
                    isLoading={isLoadingShipmentEnumeration}
                    options={getEnumerationOptionsByKey(mode.cargoTypeKey)}
                    {...fieldProps}
                  />
                )}
              </Field>
            ),
          },
          {
            content: (
              <Field
                name={`${mode.cargoKey}.${i}.quantity`}
                defaultValue=""
                isRequired
                className="!mt-0"
              >
                {({ fieldProps }) => <Textfield {...fieldProps} />}
              </Field>
            ),
          },
          {
            content: (
              <div className="flex items-center">
                <Field
                  type="checkbox"
                  className="!mt-0"
                  name={`${mode.cargoKey}.${i}.reefer`}
                  validate={(v) =>
                    !v || cargo?.[mode.cargoKey]?.[i]?.reefer_temperature?.[0] !== undefined
                  }
                >
                  {({ fieldProps }) => <Toggle {...fieldProps} />}
                </Field>

                <div className="">
                  {!!cargo?.[mode.cargoKey]?.[i]?.reefer &&
                    cargo?.[mode.cargoKey]?.[i]?.reefer_temperature?.[0] === undefined && (
                      <WarningIcon size={16} className="text-yellow-400 ml-1 mb-0.5" />
                    )}

                  {!!cargo?.[mode.cargoKey]?.[i]?.reefer &&
                    cargo?.[mode.cargoKey]?.[i]?.reefer_temperature?.[0] !== undefined && (
                      <small className="text-neutral-200 ml-1">
                        {cargo?.[mode.cargoKey]?.[i]?.reefer_temperature
                          ?.map((t) => (t > 0 ? `+${t}` : t))
                          .join(', ')}
                        {' °C'}
                      </small>
                    )}
                </div>
              </div>
            ),
          },
          {
            content: (
              <div className="flex items-center justify-end">
                {!!cargo?.[mode.cargoKey]?.[i]?.reefer && (
                  <Button
                    spacing="compact"
                    appearance="subtle"
                    className={`${
                      formState.errors?.[mode.cargoKey]?.[i]?.reefer &&
                      cargo?.[mode.cargoKey]?.[i]?.reefer_temperature?.[0] === undefined
                        ? '!border !border-red-500'
                        : ''
                    }`}
                    onClick={() =>
                      openModal({
                        index: i,
                        range: cargo?.[mode.cargoKey]?.[i]?.reefer_temperature,
                      })
                    }
                    iconBefore={<SettingsIcon className="text-neutral-500" />}
                  />
                )}

                <Button
                  spacing="compact"
                  appearance="subtle"
                  onClick={() => removePackage(i)}
                  iconBefore={<TrashIcon className="text-red-400" />}
                />
              </div>
            ),
          },
        ],
      })),
    [
      mode,
      cargo,
      formState,
      openModal,
      removePackage,
      packagesFields,
      getEnumerationOptionsByKey,
      isLoadingShipmentEnumeration,
    ]
  )
  return (
    <>
      <div className="mt-8">
        <div className="flex justify-between items-center">
          <h3>{capitalizeFirstLetter(mode.cargoKey)}</h3>

          <Button appearance="link" onClick={addPackage} className="!px-0">
            New {mode.cargoKey.slice(0, -1)}
          </Button>
        </div>

        <div className="shadow rounded p-4 mt-2">
          {tableRows.length === 0 ? (
            <div className="text-xs text-neutral-200">
              Click on New {mode.cargoKey.slice(0, -1)} to create new {mode.cargoKey.slice(0, -1)}
            </div>
          ) : (
            <div className="inline-block w-full -mb-6 removeAtlaskitTableBorder">
              <DynamicTableStateless head={tableHead} rows={tableRows} />
            </div>
          )}
        </div>
      </div>

      <ShipmentCreationTemperatureControlModal
        isOpen={isOpenModal}
        onDiscard={closeModal}
        defaultRange={modalData?.range}
        onSubmit={(v) => {
          setValue(`${mode.cargoKey}.${modalData.index}.reefer_temperature'`, v)
          closeModal()
        }}
      />

      {/* Special conditions */}
      <div className="mt-8">
        <div className="flex justify-between items-center">
          <h3>Special conditions</h3>
        </div>

        <div className="shadow rounded p-4 mt-2">
          <Field type="checkbox" name="adr_goods" label="Dangerous cargo">
            {({ fieldProps }) => (
              <div>
                <Toggle {...fieldProps} />
              </div>
            )}
          </Field>

          {!!cargo?.adr_goods && (
            <div className="grid md:grid-cols-2 md:gap-4">
              <Field label="UN Number" isRequired name="adr_number" defaultValue="">
                {({ fieldProps }) => (
                  <div>
                    <Textfield placeholder="Type your UN Number" {...fieldProps} />
                  </div>
                )}
              </Field>

              <Field
                isRequired
                type="select"
                label="Class"
                name="adr_class"
                transform={transformSelectField(getEnumerationOptionsByKey('adr_classes'))}
              >
                {({ fieldProps }) => (
                  <div>
                    <Select
                      placeholder="Choose a class"
                      isLoading={isLoadingShipmentEnumeration}
                      options={getEnumerationOptionsByKey('adr_classes')}
                      {...fieldProps}
                    />
                  </div>
                )}
              </Field>
            </div>
          )}

          <div className="mt-4">
            <Field type="checkbox" label="Cargo insurance" name="insurance">
              {({ fieldProps }) => (
                <div>
                  <Toggle {...fieldProps} />
                </div>
              )}
            </Field>

            {!!cargo?.insurance && (
              <div className="grid md:grid-cols-2 md:gap-4">
                <Field label="Cargo value" isRequired name="cargo_value" defaultValue="">
                  {({ fieldProps }) => <Textfield placeholder="Type cargo value" {...fieldProps} />}
                </Field>

                <Field
                  isRequired
                  type="select"
                  label="Currency"
                  name="currency"
                  transform={transformSelectField(getEnumerationOptionsByKey('currencies'))}
                >
                  {({ fieldProps }) => (
                    <div>
                      <Select
                        menuPlacement='auto'
                        placeholder="Choose a currency"
                        isLoading={isLoadingShipmentEnumeration}
                        options={getEnumerationOptionsByKey('currencies')}
                        {...fieldProps}
                      />
                    </div>
                  )}
                </Field>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default ShipmentCreationDedicatedFields
