import { useCallback } from 'react'

// Atlassian
import Tag from '@atlaskit/tag'

// Styles
import styles from './TaggedLabel.module.scss'

const TaggedLabel = ({ label = '', value = '', tagColor, onClick }) => {
  const tagCreator = useCallback(
    (tag) =>
      typeof onClick === 'function' ? (
        <button onClick={onClick} className={styles.tag__button} children={tag} />
      ) : (
        tag
      ),
    [onClick]
  )

  return (
    <>
      <div className="flex justify-between gap-1">
        <p>{label}</p>
        {tagCreator(<Tag text={value} color={tagColor} isRemovable={false} />)}
      </div>
    </>
  )
}

export default TaggedLabel
