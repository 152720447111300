//Atlassian
import { Field, useFormContext } from '@royan-co/user-interface'
import { ErrorMessage } from '@atlaskit/form'

//Components
import Card from 'components/UI/Card'
import RegistrySelect from 'components/Registries/RegistrySelect'
import RegistrySelectContactField from 'components/Registries/View/SelectContactsField'
import RegistryTransportationAutocompleteField from 'components/Registries/View/TransportationAutocompleteField'

const ConsolidationViewEditCarrier = () => {
  const { watch, getValues, setValue } = useFormContext()

  const carrier = watch('carrier')

  return (
    <Card className="w-full mb-6" title="Carrier">
      <Field
        isRequired
        type="select"
        name="carrier"
        label="Carrier"
        className="!mt-0"
        onValueChange={() => setValue('carrier_contacts', [])}
      >
        {({ fieldProps, error }) => (
          <>
            <RegistrySelect
              {...fieldProps}
              defaultData={getValues('carrier')}
              registryType="supplier"
            />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </>
        )}
      </Field>

      {carrier?.value && (
        <RegistrySelectContactField
          isMulti
          name="carrier_contacts"
          label="Contacts"
          registryId={carrier.value}
          className="!mt-4"
        />
      )}

      <RegistryTransportationAutocompleteField
        label="Transportation units"
        name="means"
        defaultMeansValues={getValues('means')}
        className="!mt-4"
      />
    </Card>
  )
}

export default ConsolidationViewEditCarrier
