import { useCallback, useMemo, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

// Utils
import { transformSelectField } from 'utils/helpers'

// Hooks
import useShipmentsEnumerations from 'hooks/useShipmentsEnumerations'

// Atlassian
import Drawer from '@atlaskit/drawer'
import TextField from '@atlaskit/textfield'
import Select from '@atlaskit/select'
import Toggle from '@atlaskit/toggle'
import { ErrorMessage } from '@atlaskit/form'
import { LoadingButton } from '@atlaskit/button'

// Interfaces
import { Field, Form, WarningBannerField, RangeSlider } from '@royan-co/user-interface'
import { temperatureControlOptions } from '../Create/constants'

const TruckDrawer = ({ onClose, isOpen, data, onSubmit, isSubmitting = false, name }) => {
  const isMobile = useMediaQuery({ query: `(max-width: 991.98px)` })
  const isEditing = useMemo(() => !!data?.box_type, [data])

  const { getEnumerationOptionsByKey, isLoadingShipmentEnumeration } = useShipmentsEnumerations()

  const boxTypeOptions = useMemo(
    () => getEnumerationOptionsByKey(`${name}_types`),
    [getEnumerationOptionsByKey, name]
  )

  const selectedReeferOption = useCallback((defaultRange) => {
    if (!defaultRange) return
    let [min, max] = defaultRange

    const selected = temperatureControlOptions.find((o) => {
      return o.value.range.min === min * 1 && o.value.range.max === max * 1
    })
    return selected || temperatureControlOptions.find((o) => o.value.type === 'custom')
  }, [])

  const defaultValues = {
    box_type: data?.box_type ? boxTypeOptions.find((o) => o.label === data.box_type) || null : null,
    quantity: data?.quantity || '',
    reefer: data?.reefer || '',
    range: selectedReeferOption(data?.reefer_temperature?.split(',')) || '',
    reefer_temperature: data?.reefer_temperature?.split(','),
  }

  const [formState, setFormState] = useState({})

  return (
    <Drawer isOpen={isOpen} onClose={onClose} width={isMobile ? 'full' : 'medium'}>
      <div className="drawer">
        <h3>
          {isEditing ? 'Edit ' : 'New '}
          {name}
        </h3>

        <Form watch={setFormState} onSubmit={onSubmit} defaultValues={defaultValues}>
          {({ setValue }) => (
            <>
              <Field isRequired type="select" className="!mt-4" name="box_type" label="Type">
                {({ fieldProps, error }) => (
                  <>
                    <Select
                      placeholder="Choose"
                      options={boxTypeOptions}
                      isLoading={isLoadingShipmentEnumeration}
                      {...fieldProps}
                    />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>

              <Field name="quantity" label="Quantity" defaultValue="">
                {({ fieldProps, error }) => (
                  <>
                    <TextField {...fieldProps} />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>

              <Field type="checkbox" name="reefer" label="Temperature control">
                {({ fieldProps, error }) => (
                  <div>
                    <Toggle {...fieldProps} />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </div>
                )}
              </Field>

              {formState.reefer && (
                <Field type="select" name="range" label="Temperature range">
                  {({ fieldProps: { onChange, ...fieldProps }, errorType }) => (
                    <div>
                      <Select
                        placeholder="Choose a range"
                        onChange={(v) => {
                          onChange(v)
                          setValue('reefer_temperature', [
                            v?.value?.range?.min || defaultValues.reefer_temperature?.[0],
                            v?.value?.range?.max || defaultValues.reefer_temperature?.[1],
                          ])
                        }}
                        options={temperatureControlOptions}
                        validate={(d) => !!d?.value?.range?.min}
                        {...fieldProps}
                      />
                      {errorType && <ErrorMessage>Please select range</ErrorMessage>}
                    </div>
                  )}
                </Field>
              )}

              {formState.reefer && formState?.range?.value?.type === 'custom' && (
                <div className="mt-3 mb-4">
                  <Field name="reefer_temperature" max={30} min={-25}>
                    {({ fieldProps: { ref, ...fieldProps } }) => <RangeSlider {...fieldProps} />}
                  </Field>
                </div>
              )}

              <WarningBannerField />

              <LoadingButton
                type="submit"
                className="mt-6"
                shouldFitContainer
                appearance="primary"
                isLoading={isSubmitting}
              >
                {isEditing ? 'Edit' : 'Create'}
              </LoadingButton>
            </>
          )}
        </Form>
      </div>
    </Drawer>
  )
}

export default TruckDrawer
