import { getFilled } from 'utils/helpers'

export const getAirwaybillDefaultValues = (airwaybill, currentUser) => {
  let defaultValues = {}

  // house
  if (airwaybill?.type === 0) {
    defaultValues = {
      number1: '',
      number2: getFilled(airwaybill, 'departure_airport', ''),
      number3: getFilled(airwaybill, 'shipment.reference', ''),
      number4:
        getFilled(airwaybill, 'airline_data.prefix', '') +
        ' - ' +
        getFilled(airwaybill, 'waybill', ''),
      number5: 'HAWB - ' + getFilled(airwaybill, 'shipment.reference', ''),
      issuedBy: airwaybill?.shipment?.client?.id_auto
        ? {
            value: airwaybill?.shipment?.client?.id_auto,
            label: airwaybill?.shipment?.client?.company_name,
          }
        : null,
      logoUrl: airwaybill?.shipment?.client?.logo?.includes('default-company.png')
        ? 'default-image'
        : airwaybill?.shipment?.client?.logo,
    }
  } else {
    defaultValues = {
      number1: getFilled(airwaybill, 'airline_data.prefix', ''),
      number2: getFilled(airwaybill, 'departure_airport', ''),
      number3: getFilled(airwaybill, 'waybill', ''),
      number4: getFilled(airwaybill, 'waybill', ''),
      number5:
        'AWB' +
        getFilled(airwaybill, 'airline_data.prefix', '') +
        ' - ' +
        getFilled(airwaybill, 'waybill', ''),
      issuedBy: airwaybill?.airline_data?.acronym
        ? {
            value: airwaybill?.airline_data?.acronym,
            label: airwaybill?.airline_data?.company_name,
          }
        : null,
      logoUrl: airwaybill.airline_data?.logo_url,
    }
  }

  defaultValues = {
    ...defaultValues,
    consignor: getFilled(airwaybill, 'shipper_text', ''),
    consigned: getFilled(airwaybill, 'consignee_text', ''),
    carrierAgentNameAndCity: getFilled(airwaybill, 'carrier_agent_text', ''),
    notify: getFilled(airwaybill, 'notify_text', ''),
    accountingInformation: getFilled(airwaybill, 'accounting_information', ''),
    agentIATACode: getFilled(airwaybill, 'carrier_agent.iata_code', ''),
    accountNo: getFilled(airwaybill, 'carrier_agent.vat_number', ''),
    airportOfDepartureAndRequestedRouting: airwaybill?.departure_airport
      ? {
          value: airwaybill?.departure_airport,
          label:
            getFilled(airwaybill, 'departure_airport', '') +
            ' - ' +
            getFilled(airwaybill, 'departure_airport_data.name', ''),
        }
      : null,
    byFirstCarrier: airwaybill?.airline_data?.acronym
      ? {
          value: airwaybill?.airline_data?.acronym,
          label: airwaybill?.airline_data?.company_name,
        }
      : null,

    currency: getFilled(airwaybill, 'currency', ''),
    chgsCode: getFilled(airwaybill, 'chgs_code', ''),
    ppd: airwaybill?.prepaid_collect === 0 ? 'X' : '',
    ppdOther: airwaybill?.prepaid_collect === 0 ? 'X' : '',
    coll: airwaybill?.prepaid_collect === 1 ? 'X' : '',
    collOther: airwaybill?.prepaid_collect === 1 ? 'X' : '',
    declaredValueCarriage: getFilled(airwaybill, 'declared_value_carriage', ''),
    declaredValueCustoms: getFilled(airwaybill, 'declared_value_customs', ''),
    airportDestination: airwaybill?.arrival_airport
      ? {
          value: airwaybill?.arrival_airport,
          label:
            getFilled(airwaybill, 'arrival_airport', '') +
            ' - ' +
            getFilled(airwaybill, 'arrival_airport_data.name', ''),
        }
      : null,
    requestedFlightNumber: getFilled(airwaybill, 'flight', ''),
    requestedFlightDate: getFilled(airwaybill, 'flight_date', ''),
    requestedFlightNumber2: getFilled(airwaybill, 'flight2', ''),
    requestedFlightDate2: getFilled(airwaybill, 'flight_date2', ''),
    amountInsurance: getFilled(airwaybill, 'amount_insurance', ''),
    handlingInformation: getFilled(airwaybill, 'handling_information', ''),
    sci: getFilled(airwaybill, 'sci', null),
    weightChargePrepaid:
      airwaybill?.prepaid_collect !== 0
        ? ''
        : airwaybill?.total_as_agreed
        ? 'AS AGREED'
        : getFilled(airwaybill, 'total', ''),
    weightChargeCollect:
      airwaybill.prepaid_collect !== 1
        ? ''
        : airwaybill.total_as_agreed
        ? 'AS AGREED'
        : getFilled(airwaybill, 'total', ''),
    valuationChargePrepaid:
      airwaybill?.prepaid_collect === 0 ? getFilled(airwaybill, 'valuation_charge', '') : '',

    valuationChargeCollect:
      airwaybill?.prepaid_collect === 1 ? getFilled(airwaybill, 'valuation_charge', '') : '',

    taxPrepaid: airwaybill?.prepaid_collect === 0 ? getFilled(airwaybill, 'tax', '') : '',

    taxCollect: airwaybill?.prepaid_collect === 1 ? getFilled(airwaybill, 'tax', '') : '',
    otherCharges: getFilled(airwaybill, 'other_charges', ''),
    totalChargesDueAgentPrepaid:
      airwaybill?.prepaid_collect === 0 ? getFilled(airwaybill, 'total_charges_agent', '') : '',

    totalChargesDueAgentCollect:
      airwaybill?.prepaid_collect === 1 ? getFilled(airwaybill, 'total_charges_agent', '') : '',

    totalChargesDueCarrierPrepaid:
      airwaybill?.prepaid_collect === 0 ? getFilled(airwaybill, 'total_charges_carrier', '') : '',

    totalChargesDueCarrierCollect:
      airwaybill?.prepaid_collect === 1 ? getFilled(airwaybill, 'total_charges_carrier', '') : '',

    totalPrepaid:
      airwaybill.prepaid_collect !== 0
        ? ''
        : airwaybill.total_as_agreed
        ? 'AS AGREED'
        : getFilled(airwaybill, 'total_total', ''),

    totalCollect:
      airwaybill.prepaid_collect !== 1
        ? ''
        : airwaybill.total_as_agreed
        ? 'AS AGREED'
        : getFilled(airwaybill, 'total_total', ''),

    issuerName: getFilled(currentUser, 'name', '') + '\n' + getFilled(currentUser, 'surname', ''),

    signatureShipperOrAgent:
      getFilled(currentUser, 'registry.company_name', '') +
      '\n' +
      getFilled(currentUser, 'registry.vat_number', ''),

    executedOn: getFilled(airwaybill, 'execute_on', ''),

    executedAt: getFilled(airwaybill, 'execute_place', ''),

    signatureIssuingCarrierOrAgent: getFilled(airwaybill, 'airline_data.company_name', ''),

    sumOfPackagesNumber: getFilled(airwaybill, 'collies_number', ''),

    sumOfPackagesGross: getFilled(airwaybill, 'weight', ''),

    sumOfPackagesTotal: airwaybill?.total_as_agreed
      ? 'AS AGREED'
      : getFilled(airwaybill, 'total', ''),

    packages: [
      {
        number: getFilled(airwaybill, 'collies_number', ''),
        gross: getFilled(airwaybill, 'weight', ''),
        unit: 'kg',
        rateClass: '',
        commodityItemNo: getFilled(airwaybill, 'commodities', ''),
        chargeableWeight: getFilled(airwaybill, 'chargeable_weight', ''),
        rateCharge: getFilled(airwaybill, 'rate', ''),
        total: airwaybill?.total_as_agreed ? 'AS AGREED' : getFilled(airwaybill, 'total', ''),
        goods: getFilled(airwaybill, 'description', ''),
      },
    ],
  }

  return defaultValues
}
