import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

// Contexts
import { useAuth } from 'contexts/AuthProvider'

// Utils
import { invoiceTypes } from '../utils/invoice-types'

// Hooks
import { useInvoice } from '../api/getInvoice'

//Atlassian
import Button from '@atlaskit/button'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'

// Interfaces
import { useDisclosure } from '@royan-co/user-interface'

// Components
import RowDrawer from './RowDrawer'
import DisabledElementTooltip from 'components/UI/DisabledElementTooltip'
import LinkInvoiceModal from './LinkInvoiceModal'

const NewRowAction = () => {
  const { invoiceId } = useParams()
  const { currentUser } = useAuth()

  const { invoice } = useInvoice(invoiceId)

  const _type = useMemo(() => {
    return invoiceTypes.find(
      (type) =>
        type.invoiceKeys.active_passive === invoice?.active_passive &&
        type.invoiceKeys.buffer === invoice?.buffer
    )
  }, [invoice])

  const disableRowActions = useMemo(
    () => !!((_type?.name === 'passive' || _type?.name === 'buffer') && invoice?.arca_status >= 3),
    [_type, invoice]
  )

  const [isOpenLinkInvoiceModal, linkInvoiceModalHandlers] = useDisclosure('link-invoice')
  const [isOpenInvoiceRowModal, invoiceRowModalHandlers] = useDisclosure('new-invoice')

  const accessabilityNewRow = useMemo(() => {
    if (
      invoice?.operator?.id &&
      (['master', 'admin'].includes(currentUser?.user_level_name?.name) ||
        currentUser.id === invoice?.operator?.id)
    )
      return true
    else return false
  }, [currentUser.id, currentUser?.user_level_name?.name, invoice?.operator?.id])

  return (
    <>
      <DisabledElementTooltip tooltipContent="Invoice is processed." isDisabled={disableRowActions}>
        <DropdownMenu
          placement="bottom-end"
          trigger={({ triggerRef, ...props }) => (
            <Button
              ref={triggerRef}
              appearance="primary"
              children="New row"
              {...props}
              isDisabled={!accessabilityNewRow || disableRowActions}
            />
          )}
        >
          <DropdownItemGroup>
            <DropdownItem
              children="Link to a shipment"
              onClick={() => linkInvoiceModalHandlers.open('shipment')}
            />
            <DropdownItem
              children="Link to a consolidation"
              onClick={() => linkInvoiceModalHandlers.open('consolidation')}
            />
            <DropdownItem children="New empty row" onClick={() => invoiceRowModalHandlers.open()} />
          </DropdownItemGroup>
        </DropdownMenu>
      </DisabledElementTooltip>

      <LinkInvoiceModal
        isOpen={isOpenLinkInvoiceModal}
        onClose={linkInvoiceModalHandlers.close}
        invoiceId={invoiceId}
        entity={linkInvoiceModalHandlers.data}
      />

      <RowDrawer
        isOpen={isOpenInvoiceRowModal}
        onClose={invoiceRowModalHandlers.close}
        invoiceId={invoiceId}
      />
    </>
  )
}

export default NewRowAction
