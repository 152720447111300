import React from 'react'

// Atlassian
import Modal, {
  ModalBody,
  ModalTitle,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import Button, { LoadingButton } from '@atlaskit/button'

// Interfaces
import { WarningBanner } from '@royan-co/user-interface'

const RemoveSharingModal = ({ isOpen, onClose, onSubmit, isLoading, isError }) => {
  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={onClose}>
          <ModalHeader>
            <ModalTitle appearance="danger">Remove sharing</ModalTitle>
          </ModalHeader>

          <ModalBody>
            You are about to remove a sharing. Are you sure?
            {isError && <WarningBanner />}
          </ModalBody>

          <ModalFooter>
            <Button appearance="subtle" onClick={onClose}>
              Cancel
            </Button>
            <LoadingButton isLoading={isLoading} appearance="danger" onClick={onSubmit} autoFocus>
              Remove
            </LoadingButton>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  )
}

export default RemoveSharingModal
