import { useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

// Apis
import { getConsolidationShipments } from 'api/consolidations'

// Utils
import { findOption } from 'utils/helpers'
import { formatNumber } from 'utils/numbers'

// Contexts
import { useAuth } from 'contexts/AuthProvider'

//Hooks
import useConsolidation from 'hooks/useConsolidation'

//Atlassian
import Lozenge from '@atlaskit/lozenge'
import { DynamicTableStateless } from '@atlaskit/dynamic-table'
import Button from '@atlaskit/button'
import Spinner from '@atlaskit/spinner'
import SectionMessage from '@atlaskit/section-message'
import EmptyState from '@atlaskit/empty-state'

//Interfaces
import { ShortcutIcon } from '@royan-co/user-interface'

//Components
import { shipmentStatusOptions } from 'components/Shipments/constants'
import Card from 'components/UI/Card'
import CountryFlag from 'components/UI/CountryFlag'
import SearchInput from 'components/UI/SearchInput'

const ConsolidationViewShipmentsPage = () => {
  const { currentUser } = useAuth()
  const { consolidationId } = useParams()
  const [searchTerm, setSearchTerm] = useState('')

  const { consolidation } = useConsolidation(consolidationId)

  const {
    data: consolidationShipments = [],
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useQuery({
    queryKey: ['consolidation', 'shipments', consolidationId],
    queryFn: () => getConsolidationShipments(consolidationId),
    retry: 2,
    staleTime: Infinity,
  })

  const isCreatorUser = useMemo(
    () =>
      currentUser?.registry_id === consolidation?.creator?.registry_id &&
      currentUser?.registry_address_id === consolidation?.creator?.registry_address_id,
    [consolidation, currentUser]
  )
  const isAirFreight = useMemo(() => consolidation?.shipment_type === 2, [consolidation])

  const shipmentsHead = useMemo(() => {
    let headItems = [
      { content: 'Reference' },
      { content: 'Client' },
      { content: 'Consignee' },
      { content: 'Shipper' },
      { content: 'Destination' },
      { content: 'Air waybill' },
      { content: 'Entrance no.' },
      { content: 'Tracking' },
      { content: 'No. of collies' },
      { content: 'Weight (Kgs)' },
      { content: 'LDM' },
      { content: 'CBM' },
    ]

    return {
      cells: headItems.filter((item) =>
        item.content === 'Air waybill'
          ? isAirFreight
          : item.content === 'Client'
          ? isCreatorUser
          : true
      ),
    }
  }, [isAirFreight, isCreatorUser])

  const shipments = useMemo(() => {
    const body =
      consolidationShipments
        ?.filter((d) => d?.reference?.toLocaleLowerCase()?.includes(searchTerm.toLocaleLowerCase()))
        ?.map((item, i) => {
          let shipmentsItems = [
            {
              id: `reference${i}`,
              content: (
                <>
                  {item?.reference ? (
                    <Link to={`/panel/shipments/${item?.id_auto}`} target="_blank">
                      {item?.reference} <ShortcutIcon size={16} />
                    </Link>
                  ) : null}

                  {item?.status ? (
                    <div>
                      <Lozenge appearance="default">
                        {findOption(shipmentStatusOptions, item?.status)?.label}
                      </Lozenge>
                    </div>
                  ) : null}

                  {item?.voluminous ? (
                    <div>
                      <Lozenge appearance="moved">Voluminous</Lozenge>{' '}
                    </div>
                  ) : null}

                  {item?.dense ? (
                    <div>
                      <Lozenge appearance="moved">Dense</Lozenge>
                    </div>
                  ) : null}
                </>
              ),
            },
            {
              id: `client${i}`,
              content: item?.client?.company_name || (
                <Lozenge appearance="default">Not set</Lozenge>
              ),
            },
            {
              id: `consignee${i}`,
              content: item?.consignee?.company_name || (
                <Lozenge appearance="default">Not set</Lozenge>
              ),
            },
            {
              id: `shipper${i}`,
              content: item?.shipper?.company_name || (
                <Lozenge appearance="default">Not set</Lozenge>
              ),
            },
            {
              id: `arrival_country${i}`,
              content: (
                <>
                  <CountryFlag iso={item?.arrival_country} />
                  {item?.arrival_country && item?.arrival_city && <span>, </span>}
                  {item?.arrival_city}
                </>
              ),
            },
            {
              id: `waybill${i}`,
              content: item?.entrance?.waybill || (
                <Lozenge appearance="default">Not issued</Lozenge>
              ),
            },
            {
              id: `entrance${i}`,
              content: item?.entrance?.numerator || <Lozenge appearance="default">Not set</Lozenge>,
            },
            {
              id: `has_tracking${i}`,
              content: item?.has_tracking ? (
                <Lozenge appearance="success">Enabled</Lozenge>
              ) : (
                <Lozenge appearance="moved">Disabled</Lozenge>
              ),
            },
            {
              id: `total_collies${i}`,
              content: formatNumber(item?.total_collies),
            },
            {
              id: `weight${i}`,
              content: item?.weight ? (
                <span className="whitespace-nowrap">{formatNumber(item?.weight)}</span>
              ) : (
                <Lozenge appearance="default">Not set</Lozenge>
              ),
            },
            {
              id: `ldm${i}`,
              content: item?.ldm ? (
                <span>{item?.ldm}</span>
              ) : (
                <Lozenge appearance="default">Not set</Lozenge>
              ),
            },
            {
              id: `cbm${i}`,
              content: item?.cbm ? (
                <span>{item?.cbm}</span>
              ) : (
                <Lozenge appearance="default">Not set</Lozenge>
              ),
            },
          ]

          return {
            key: `packagesRows${i}`,
            // eslint-disable-next-line no-sparse-arrays
            cells: shipmentsItems.filter((item) =>
              item.id.includes('waybill')
                ? isAirFreight
                : item.id.includes('client')
                ? isCreatorUser
                : true
            ),
          }
        }) || []

    let totalRow = [
      {
        content: <strong>Total</strong>,
      },
      ...new Array(shipmentsHead.cells?.length - 5 || 0).fill({
        content: '',
      }),
      {
        content: formatNumber(
          consolidationShipments?.reduce((p, c) => p + c.total_collies * 1, 0) || 0
        ),
      },
      {
        content: formatNumber(consolidationShipments?.reduce((p, c) => p + c.weight * 1, 0) || 0),
      },
      {
        content:
          consolidationShipments?.reduce((p, c) => p + parseFloat(c?.ldm || 0), 0).toFixed(3) || 0,
      },
      {
        content:
          consolidationShipments?.reduce((p, c) => p + parseFloat(c?.cbm || 0), 0).toFixed(3) || 0,
      },
    ]

    body.push({
      key: `shipmentsRows-total`,
      cells: totalRow,
    })

    return body
  }, [consolidationShipments, isAirFreight, isCreatorUser, searchTerm, shipmentsHead.cells?.length])

  return (
    <>
      {isLoading || isFetching ? (
        <div className="text-center mt-24">
          <Spinner />
        </div>
      ) : isError ? (
        <SectionMessage
          appearance="warning"
          title="Something wrong on loading shipments, please retry."
        >
          <Button onClick={() => refetch()}>Retry</Button>
        </SectionMessage>
      ) : (
        <>
          <div style={{ maxWidth: '240px' }}>
            <SearchInput onSubmit={setSearchTerm} delay={150} />
          </div>

          <Card className="mt-4">
            {consolidationShipments?.length < 1 ? (
              <EmptyState header="No shipments" />
            ) : (
              <div className="overflow-auto inline-block w-full -mb-6 borderOfAtlaskitTableWithTotalRow">
                <DynamicTableStateless
                  head={shipmentsHead}
                  rows={shipments}
                  isLoading={isLoading}
                />
              </div>
            )}
          </Card>
        </>
      )}
    </>
  )
}

export default ConsolidationViewShipmentsPage
