import { useEffect } from 'react'
// Interfaces
import { useFormContext } from '@royan-co/user-interface'

// Components
import CreateInvoiceFormGeneral from '../components/CreateInvoiceFormGeneral.js'
import CreateInvoiceFormRows from '../components/CreateInvoiceFormRows.js'

const CreateInvoiceFormContainer = ({ defaultValues, setClient, client }) => {
  const { setValue, watch } = useFormContext()
  const _client = watch('client')

  useEffect(() => {
    if (client !== null)
      for (const [key, value] of Object.entries(defaultValues)) {
        setValue(key, value)
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues])

  useEffect(() => {
    if (_client?.value !== defaultValues?.client?.value) setClient(_client?.value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_client])

  return (
    <>
      <CreateInvoiceFormGeneral />

      <CreateInvoiceFormRows />
    </>
  )
}

export default CreateInvoiceFormContainer
