//Atlassian
import Textfield from '@atlaskit/textfield'
import { ErrorMessage } from '@atlaskit/form'

//Interfaces
import { Field, PhoneInput, useFormContext, useGeoInfo } from '@royan-co/user-interface'

//Components
import Card from 'components/UI/Card'

const ConsolidationAddLoacalisationDriverForm = () => {
  const { geoInfo } = useGeoInfo()
  const { watch } = useFormContext()

  const address = watch('address')

  return !!address ? (
    <Card className="mt-6" title="Driver">
      <Field label="Name" name="driver_name" className="!mt-0">
        {({ fieldProps, error }) => (
          <>
            <Textfield placeholder="Type the name" {...fieldProps} />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </>
        )}
      </Field>

      <Field
        label="Phone"
        name="driver_phone"
        className="!mt-4"
        transform={{
          input: (v) => ({
            value: v?.value || v,
          }),
          output: (opt) => opt?.value,
        }}
      >
        {({ fieldProps: { ref, ...fieldProps }, error }) => (
          <>
            <PhoneInput defaultCountry={geoInfo?.countryCode?.toLowerCase()} {...fieldProps} />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </>
        )}
      </Field>
    </Card>
  ) : null
}

export default ConsolidationAddLoacalisationDriverForm
