import { BillingIcon, PackagesIcon, PeopleGroupIcon, SettingsIcon } from '@royan-co/user-interface'
import StarFilledIcon from '@atlaskit/icon/glyph/star-filled'

const links = [
  {
    title: 'Companies',
    icon: <PeopleGroupIcon />,
    children: [
      {
        title: 'Registries',
        path: '/panel/registries',
        permissionResource: 'registries',
      },
    ],
  },
  {
    title: 'Sales',
    icon: <StarFilledIcon />,
    children: [
      {
        title: 'Quotations',
        path: '/panel/quotations',
        permissionResource: 'quotations',
      },
      {
        title: 'Sales machine',
        children: [
          {
            title: 'Rates',
            path: '/panel/rates',
            permissionResource: 'rates',
          },
          {
            title: 'Margin calculator',
            path: '/panel/margin-calculator',
            permissionResource: 'margin-calculators',
          },
          {
            title: 'Lines',
            path: '/panel/sales-machine/lines',
            permissionResource: 'lines'
          }
        ],
      },
    ],
  },
  {
    title: 'Operation',
    icon: <PackagesIcon />,
    children: [
      {
        title: 'Shipments',
        path: '/panel/shipments',
        permissionResource: 'shipments',
      },
      {
        title: 'Consolidations',
        path: '/panel/consolidations',
        permissionResource: 'consolidations',
      },
      { title: 'Air waybills', path: '/panel/airwaybills', permissionResource: 'airway-bills' },
      { title: 'AWB Stock', path: '/panel/airwaybill-stock', permissionResource: 'awb-stock' },
    ],
  },
  {
    title: 'Accounting',
    icon: <BillingIcon />,
    children: [
      {
        title: 'Active invoices',
        path: '/panel/active-invoices',
        permissionResource: 'invoices',
      },
      {
        title: 'Passive invoices',
        path: '/panel/passive-invoices',
        permissionResource: 'invoices',
      },
      {
        title: 'Buffer invoices',
        path: '/panel/buffer-invoices',
        permissionResource: 'invoices',
      },
      {
        title: 'Banks',
        path: '/panel/banks',
        permissionResource: 'banks',
      },
      {
        title: 'Payment terms',
        path: '/panel/payment-terms',
        permissionResource: 'payment-terms',
      },
      {
        title: 'Document types',
        path: '/panel/document-types',
        permissionResource: 'document-types',
      },
      { title: 'Currencies', path: '/panel/currencies', permissionResource: 'currencies' },
    ],
  },
  {
    title: 'Configurations',
    icon: <SettingsIcon />,
    children: [
      {
        title: 'Services',
        path: '/panel/service-codes',
        permissionResource: 'service-codes',
      },
      {
        title: 'Users',
        path: '/panel/users',
        permissionResource: 'users',
      },
      { title: 'Vehicle Types', path: '/panel/vehicle-types', permissionResource: 'vehicle-types' },
    ],
  },
]

export { links }
