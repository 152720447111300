import styles from './PDFAsso.module.scss'

const PDFAsso = ({
  invoiceData,
  has_sender_telephone,
  has_sender_email,
  has_recipient_telephone,
  has_recipient_email,
}) => {
  return (
    <div className="overflow-auto inline-block w-full">
      <div className={styles.pdfAssoTable}>
        <table cellPadding="4" cellSpacing="4">
          <thead>
            <tr align="top">
              <td colSpan="2">Data Ricezione: {invoiceData.data_ricezione} </td>
            </tr>
            <tr>
              <td>
                <h3>Mittente</h3>
                Denominazione: <strong> {invoiceData.mittente_denominazione}</strong>
                <br />
                Identificativo fiscale ai fini IVA:{' '}
                <strong> {invoiceData.data_identificativo}</strong>
                <br />
                Codice fiscale: <strong>{invoiceData.mittente_codice}</strong>
                <br />
                Regime fiscale: <strong>{invoiceData.mittente_regime}</strong>
                <br />
                Indirizzo: <strong>{invoiceData.mittente_indirizzo}</strong>
                <br />
                Comune: <strong>{invoiceData.mittente_comune}</strong>
                <br />
                CAP: <strong>{invoiceData.mittente_cap}</strong>
                <br />
                {has_sender_telephone ? (
                  <>
                    Telefono: <strong>{invoiceData.mittente_telefono}</strong>
                  </>
                ) : null}
                <br />
                {has_sender_email ? (
                  <>
                    Email: <strong>{invoiceData.mittente_email}</strong>
                  </>
                ) : null}
                <br />
              </td>
              <td>
                <h3>Destinatario</h3>
                Denominazione: <strong>{invoiceData.destinatario_denominazione}</strong>
                <br />
                Identificativo fiscale ai fini IVA:{' '}
                <strong>{invoiceData.destinatario_identificativo}</strong>
                <br />
                Codice fiscale: <strong>{invoiceData.destinatario_codice}</strong>
                <br />
                Regime fiscale: <strong>{invoiceData.destinatario_regime}</strong>
                <br />
                Indirizzo: <strong>{invoiceData.destinatario_indirizzo}</strong>
                <br />
                Comune: <strong>{invoiceData.destinatario_comune}</strong>
                <br />
                CAP: <strong>{invoiceData.destinatario_cap}</strong>
                <br />
                {has_recipient_telephone ? (
                  <>
                    Telefono: <strong>{invoiceData.destinatario_telefono}</strong>
                  </>
                ) : null}
                <br />
                {has_recipient_email ? (
                  <>
                    Email: <strong>{invoiceData.destinatario_email}</strong>
                  </>
                ) : null}
                <br />
              </td>
            </tr>
          </thead>
        </table>

        <table cellPadding="4" cellSpacing="4">
          <thead>
            <tr>
              <th>Tipo Doc</th>
              <th>Causale</th>
              <td>Art73</td>
              <th>N. Fattura</th>
              <th>Data</th>
              <th>Codice Dest.</th>
            </tr>
          </thead>
          <tbody>
            <tr align="top">
              <td>{invoiceData.tipo_doc}</td>
              <td>{invoiceData.causale}</td>
              <td>{invoiceData.art73}</td>
              <td>{invoiceData.fattura}</td>
              <td>{invoiceData.data}</td>
              <td>{invoiceData.codice_dest}</td>
            </tr>
          </tbody>
        </table>

        <table cellPadding="4" cellSpacing="4">
          <thead>
            <tr>
              <th>Descrizione</th>
              <th>Qt.</th>
              <th>Prezzo</th>
              <th>Um</th>
              <th>Sc. Mag.</th>
              <th>%IVA</th>
              <th>Prezzo Totale</th>
            </tr>
          </thead>
          <tbody>
            {invoiceData.dettaglio_linee.map((item, i) => (
              <tr key={i}>
                <td>{item.descrizione}</td>
                <td>{item.qt}</td>
                <td>{item.prezzo}</td>
                <td>{item.um}</td>
                <td>{item.sc}</td>
                <td>{item.iva}</td>
                <td align="right">{item.totale}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <table cellPadding="4" cellSpacing="4">
          <thead>
            <tr>
              <th>Dati riepilogo</th>
              <th>%IVA</th>
              <th>Spese accessorie</th>
              <th>Totale imposta</th>
              <th>Totale imponibile</th>
            </tr>
          </thead>
          <tbody>
            {invoiceData.dati_riepilogo.map((item, i) => (
              <tr key={i}>
                <td>{item.dati}</td>
                <td>{item.iva}</td>
                <td>{item.accessorie}</td>
                <td align="right">{item.totale_imposta}</td>
                <td align="right">{item.totale_imponibile}</td>
              </tr>
            ))}

            <tr>
              <td colSpan="4" rowSpan="2"></td>
              <th>Importo Fattura</th>
            </tr>
            <tr>
              <td align="right" style={{ fontSize: '1.6rem', fontWeight: 'bold' }}>
                {invoiceData.importo_fattura} €
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default PDFAsso
