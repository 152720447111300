import { getFilled } from 'utils/helpers'

export const getSeawaybillDefaultValues = (seawaybill, currentUser) => {
  return {
    number: '',
    consignor: getFilled(seawaybill, 'shipper_text', ''),
    consigned: getFilled(seawaybill, 'consignee_text', ''),
    notifyAddress: getFilled(seawaybill, 'notify_text', ''),
    bookingNo: getFilled(seawaybill, 'booking_number', ''),
    blNo: getFilled(seawaybill, 'bl_number', ''),
    preCarriageBy: getFilled(seawaybill, 'precarriage_by', ''),
    por: getFilled(seawaybill, 'receipt_port_data.id_auto', ''),
    pod: getFilled(seawaybill, 'delivery_port_data.id_auto', ''),
    pol: getFilled(seawaybill, 'loading_port_data.id_auto', ''),
    podis: getFilled(seawaybill, 'discharge_port_data.id_auto'),
    vessel: getFilled(seawaybill, 'voyage_number', ''),
    finalDestination: getFilled(seawaybill, 'final_destination', ''),
    noOfOriginals: getFilled(seawaybill, 'original_bls', ''),
    description:
      'Received by the Carrier the Goods as specified blow in apparent good order and condition unless atherwise stated, to be ...',
    description2: getFilled(seawaybill, 'description_of_goods', ''),
    applyTo: getFilled(seawaybill, 'receiving_agent_text', ''),
    placeAndDateOfIssue: getFilled(seawaybill, 'issue_place_and_date', ''),
    freight: getFilled(seawaybill, 'freight', ''),
    freightPayableAt: getFilled(seawaybill, 'freight_payable_at', ''),
    signature: '',
    totalNumberOfContainers: '',
    packages: [
      {
        number: getFilled(seawaybill, 'marks_and_numbers', ''),
        packages: getFilled(seawaybill, 'number_and_kind', ''),
        goods: '',
        gross: getFilled(seawaybill, 'gross_weight', ''),
        measurement: getFilled(seawaybill, 'measurement', ''),
      },
    ],
  }
}
