import { useNavigate, useParams } from 'react-router-dom'

// Atlassian
import Button from '@atlaskit/button'

// Interfaces
import { PlusIcon } from '@royan-co/user-interface'

// Components
import ConsolidationTrackingRequestLocalisationAction from './TrackingRequestLocalisationAction'

const ConsolidationViewTrackingAction = () => {
  const navigate = useNavigate()
  const { consolidationId } = useParams()

  return (
    <>
      <Button
        appearance="primary"
        iconBefore={<PlusIcon />}
        onClick={() => {
          navigate(`/panel/consolidations/${consolidationId}/add-localisation`)
        }}
      >
        Add new localisation
      </Button>

      <ConsolidationTrackingRequestLocalisationAction />
    </>
  )
}

export default ConsolidationViewTrackingAction
