// Hooks
import { useTakeInChargeInvoice } from 'features/invoice/api/takeInChargeInvoice'

// Atlassian
import { DropdownItem } from '@atlaskit/dropdown-menu'

// Components
import ActionDropdownItem from 'components/ActionDropdownItem'
import DisabledElementTooltip from 'components/UI/DisabledElementTooltip'

const TakeInChargeInvoiceAction = ({ invoice, shipmentId, isBuffer, openTakeInChargeModal }) => {
  const takeInChargeInvoiceMutation = useTakeInChargeInvoice({
    invoiceId: invoice?.id_auto,
    shipmentId,
  })

  return invoice?.id_auto ? (
    <DisabledElementTooltip
      isDisabled={!!invoice?.take_in_charge_operator}
      tooltipContent="Invoice has a take-in-charge operator."
    >
      {isBuffer ? (
        <DropdownItem
          onClick={() => {
            openTakeInChargeModal(invoice.id_auto)
          }}
          isDisabled={!!invoice?.take_in_charge_operator}
        >
          Take in charge
        </DropdownItem>
      ) : (
        <ActionDropdownItem
          label="Take in charge"
          isDisabled={!!invoice?.take_in_charge_operator}
          mutation={takeInChargeInvoiceMutation}
        />
      )}
    </DisabledElementTooltip>
  ) : null
}

export default TakeInChargeInvoiceAction
