// Contexts
import { useFinanceEditingContext } from 'contexts/FinanceEditingProvider'

//Atlassian
import { ErrorMessage } from '@atlaskit/form'

//Interfaces
import { Field, PlacesAutoComplete } from '@royan-co/user-interface'

//Components
import Card from 'components/UI/Card'
import EnumerationField from 'components/Rates/List/EnumerationField'
import RatesAirportsField from 'components/Rates/List/AirportsField'

const fieldPerType = {
  airport: ({ name, label }) => <RatesAirportsField isRequired label={label} name={name} />,
  station: ({ name, label }) => (
    <EnumerationField isRequired label={label} name={name} optionsKey="stations" />
  ),
  port: ({ name, label }) => (
    <EnumerationField isRequired label={label} name={name} optionsKey="ports" />
  ),
  hub: ({ name, label }) => (
    <EnumerationField isRequired label={label} name={name} optionsKey="hubs" />
  ),
  zone: ({ name, label }) => (
    <Field
      isRequired
      name={name}
      label={label}
      type="text"
      validate={(data) => (!data.city || !data.countryAbbr ? `Address is not valid.` : true)}
    >
      {({ fieldProps, error }) => (
        <>
          <PlacesAutoComplete {...fieldProps} />
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </>
      )}
    </Field>
  ),
}

const EditFinanceFromToFields = () => {
  const { currentLegType } = useFinanceEditingContext()

  const arrivalField = fieldPerType[currentLegType?.arrival.type]?.({
    name: currentLegType?.arrival.arrivalKey,
    label: 'To',
  })

  const departureField = fieldPerType[currentLegType?.departure.type]?.({
    name: currentLegType?.departure.departureKey,
    label: 'From',
  })

  return (
    <Card className="mb-6">
      <div className="grid md:grid-cols-2 md:gap-4">
        {departureField}

        {arrivalField}
      </div>
    </Card>
  )
}

export default EditFinanceFromToFields
