//Atlassian
import Button from '@atlaskit/button'
import { DynamicTableStateless } from '@atlaskit/dynamic-table'
import Lozenge from '@atlaskit/lozenge'
import Modal, {
  ModalHeader,
  ModalFooter,
  ModalTitle,
  ModalTransition,
  ModalBody,
} from '@atlaskit/modal-dialog'

const RatesRecordsZonesModal = ({ isOpen, onClose, zones }) => {
  const tableHead = {
    cells: [{ content: 'Starting zip code' }, { content: 'Ending zip code' }],
  }

  const tableRow = zones?.map((zone, index) => ({
    key: index,
    cells: [
      {
        content: zone?.cap_from ? zone?.cap_from : <Lozenge appearance="default">Not set</Lozenge>,
      },
      { content: zone?.cap_to ? zone?.cap_to : <Lozenge appearance="default">Not set</Lozenge> },
    ],
  }))

  return (
    <>
      <ModalTransition>
        {isOpen && (
          <Modal width="small" onClose={onClose}>
            <ModalHeader>
              <ModalTitle>Zones</ModalTitle>
            </ModalHeader>

            <ModalBody>
              <div className="-mb-6">
                <DynamicTableStateless head={tableHead} rows={tableRow} />
              </div>
            </ModalBody>
            <ModalFooter>
              <Button appearance="subtle" onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </>
  )
}

export default RatesRecordsZonesModal
