import React, { useRef } from 'react'
import { Marker, GoogleMap, useJsApiLoader } from '@react-google-maps/api'
import Geocode from 'react-geocode'

const GoogleMapApiKey = 'AIzaSyDMXokLwLjiKEk5BkNAbs9NoqLgZ9V0ss8'

Geocode.setApiKey(GoogleMapApiKey)

const getCountryAndCity = (addressComponents) => {
  let country
  let countryAbbr
  let zipCode
  let city = null
  addressComponents.forEach((address) => {
    if (address.types[0] === 'country') {
      country = address.long_name
      countryAbbr = address.short_name
    }

    if (['locality', 'administrative_area_level_3'].includes(address.types[0])) {
      city = address.long_name
    }
    if (['postal_code'].includes(address.types[0])) {
      zipCode = address.long_name
    }
  })

  return { country, countryAbbr, city, zipCode }
}

const PickupLocation = ({ location, setLocation }) => {
  const markerRef = useRef(null)

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GoogleMapApiKey,
  })

  const Loading = <div>Loading ...</div>

  const renderMap = (
    <GoogleMap
      id="searchbox-example"
      mapContainerStyle={{
        height: '500px',
        width: '100%',
      }}
      zoom={15}
      center={location}
    >
      <Marker
        position={location}
        draggable
        ref={markerRef}
        onDragEnd={({ latLng }) => {
          const lat = latLng?.lat()
          const lng = latLng?.lng()

          Geocode.fromLatLng(lat, lng).then(
            (response) => {
              const { country, countryAbbr, city, zipCode } = getCountryAndCity(
                response.results[0].address_components
              )

              setLocation({
                lat,
                lng,
                city,
                zipCode,
                country,
                countryAbbr,
                label: response.results[0].formatted_address,
              })
            },
            (error) => {
              console.error(error)
            }
          )
        }}
      />
    </GoogleMap>
  )

  return isLoaded ? renderMap : Loading
}

export default PickupLocation
