import { useState } from 'react'

//Contexts
import { useAuth } from 'contexts/AuthProvider'

//Icons
import { ShortcutIcon } from '@royan-co/user-interface'

//Atlassian
import Popup from '@atlaskit/popup'
import Avatar from '@atlaskit/avatar'
import Spinner from '@atlaskit/spinner'
import { Profile } from '@atlaskit/atlassian-navigation'
import { ButtonItem, MenuGroup, Section } from '@atlaskit/menu'

const ProfilePopup = () => {
  const { currentUser, logout } = useAuth()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Popup
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      placement="bottom-start"
      content={() => (
        <MenuGroup>
          <div className="px-4 pt-4">
            {currentUser ? (
              <div className="flex items-center">
                <Avatar src={currentUser.avatar} />
                <div className="pl-2 min-w-0">
                  <div>{currentUser.name}</div>
                  <small className="text-neutral-200 text-ellipsis">{currentUser.email}</small>
                </div>
              </div>
            ) : (
              <div className="text-center">
                <Spinner />
              </div>
            )}
          </div>
          <Section title="Account">
            <a target="_blank" rel="noreferrer" href={process.env.REACT_APP_ACCOUNT_URL}>
              <ButtonItem iconAfter={<ShortcutIcon size={16} />}>Manage account</ButtonItem>
            </a>
          </Section>
          <Section hasSeparator>
            <ButtonItem onClick={logout}>Logout</ButtonItem>
          </Section>
        </MenuGroup>
      )}
      trigger={(triggerProps) => (
        <Profile
          {...triggerProps}
          icon={<Avatar size="small" src={currentUser?.avatar} />}
          onClick={() => setIsOpen(!isOpen)}
          tooltip={currentUser?.name}
        />
      )}
    />
  )
}

export default ProfilePopup
