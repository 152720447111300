import { useMemo } from 'react'

// Apis
import { useImportToARCAInvoice } from 'features/invoice/api/importToARCAInvoice'

// Components
import ActionDropdownItem from 'components/ActionDropdownItem'
import DisabledElementTooltip from 'components/UI/DisabledElementTooltip'

const ImportToARCAAction = ({ invoice, isPassive, shipmentId }) => {
  const importInvoiceMutation = useImportToARCAInvoice({ invoiceId: invoice?.id_auto, shipmentId })

  const accessabilityImportArca = useMemo(() => {
    if (!invoice?.sent_to_arca)
      if (
        (!isPassive && invoice?.arca_status < 3) ||
        (isPassive && invoice?.arca_status > 0 && invoice?.arca_status < 3)
      ) {
        if (
          (!isPassive && invoice?.client?.arca_imported) ||
          (isPassive && invoice?.supplier?.arca_imported)
        ) {
          if (!invoice?.cost_rows?.find((row) => !row.subaccount))
            if (!invoice?.cost_rows?.find((row) => !row.iva_code || row.iva_code === 207))
              return {
                access: true,
              }
            else
              return {
                access: false,
                message: 'One of the rows has no or incorrect IVA code',
              }
          else
            return {
              access: false,
              message: 'One of the rows do not have sub account.',
            }
        } else {
          return {
            access: false,
            message: 'Client is not in ARCA.',
          }
        }
      } else
        return {
          access: false,
          message: isPassive ? 'Invoice is not processed.' : 'Invoice already processed.',
        }
    else
      return {
        access: false,
        message: 'Invoice is already sent to ARCA',
      }
  }, [invoice, isPassive])

  return invoice?.id_auto ? (
    <DisabledElementTooltip
      isDisabled={!accessabilityImportArca?.access}
      tooltipContent={accessabilityImportArca?.message || null}
    >
      <ActionDropdownItem
        label="Import to ARCA"
        isDisabled={!accessabilityImportArca?.access}
        mutation={importInvoiceMutation}
      />
    </DisabledElementTooltip>
  ) : null
}

export default ImportToARCAAction
