import { useMemo } from 'react'
import { useMediaQuery } from 'react-responsive'

// Utils
import { getFilled } from 'utils/helpers'

// Atlassian
import Button, { LoadingButton } from '@atlaskit/button'
import Drawer from '@atlaskit/drawer'
import TextField from '@atlaskit/textfield'
import Toggle from '@atlaskit/toggle'
import { ErrorMessage } from '@atlaskit/form'

// Interfaces
import { Field, Form, WarningBannerField } from '@royan-co/user-interface'

const BracketDrawer = ({ onClose, isOpen, data = {}, onSubmit, isLoading = false }) => {
  const isMobile = useMediaQuery({ query: `(max-width: 991.98px)` })
  const isEditing = useMemo(() => data?.value, [data])

  const defaultValues = useMemo(
    () => ({
      from: getFilled(data, 'from', ''),
      to: getFilled(data, 'to', ''),
      value: getFilled(data, 'value', ''),
      fix_percent: getFilled(data, 'fix_percent', 0),
      index: getFilled(data, 'index', undefined),
    }),
    [data]
  )

  return (
    <Drawer isOpen={isOpen} onClose={onClose} width={isMobile ? 'full' : 'medium'}>
      <div className="drawer">
        <h3>{isEditing ? 'Edit bracket' : 'New bracket'}</h3>

        <Form onSubmit={onSubmit} defaultValues={defaultValues}>
          {() => (
            <>
              <div className="grid md:grid-cols-2 md:gap-x-4">
                <Field isRequired label="From" name="from" defaultValue="">
                  {({ fieldProps, error }) => (
                    <>
                      <TextField {...fieldProps} />
                      {error && <ErrorMessage>{error}</ErrorMessage>}
                    </>
                  )}
                </Field>

                <Field isRequired label="To" name="to" defaultValue="">
                  {({ fieldProps, error }) => (
                    <>
                      <TextField {...fieldProps} />
                      {error && <ErrorMessage>{error}</ErrorMessage>}
                    </>
                  )}
                </Field>
              </div>

              <Field isRequired label="Value" name="value" defaultValue="" className="!mt-4">
                {({ fieldProps, error }) => (
                  <>
                    <TextField
                      {...fieldProps}
                      elemAfterInput={
                        <div className="text-neutral-200 whitespace-nowrap bg-neutral-20 self-stretch flex items-center px-2">
                          Euro (€)
                        </div>
                      }
                    />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>

              <Field
                label="Fix"
                name="fix_percent"
                type="checkbox"
                key="fix_percent"
                className="!mt-4"
              >
                {({ fieldProps, error }) => (
                  <>
                    <div>
                      <Toggle {...fieldProps} id="fix_percent" />
                    </div>
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>

              <WarningBannerField />

              <LoadingButton
                isLoading={isLoading}
                type="submit"
                className="mt-4"
                shouldFitContainer
                appearance="primary"
              >
                {isEditing ? 'Edit' : 'Add'}
              </LoadingButton>
              <Button appearance="subtle" className="mt-2" shouldFitContainer onClick={onClose}>
                Cancel
              </Button>
            </>
          )}
        </Form>
      </div>
    </Drawer>
  )
}

export default BracketDrawer
