import { useCallback, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

//Apis
import { getShipments } from 'api/shipments'

//Atlassian
import Button from '@atlaskit/button'
import { DynamicTableStateless } from '@atlaskit/dynamic-table'
import EmptyState from '@atlaskit/empty-state'

//Interfaces
import { RoyanTable, ShortcutIcon, TrashIcon } from '@royan-co/user-interface'

//Components
import Card from 'components/UI/Card'
import CountryFlag from 'components/UI/CountryFlag'
import { SelectStatusInlineFilter } from 'components/UI/InlineFilter'
import SearchInput from 'components/UI/SearchInput'

const SelectShipmentsFromList = ({
  selectedShipments,
  dispatchSelectedShipments,
  selectedShipmentsId,
  sectionParameter,
}) => {
  const [searchTerm, setSearchTerm] = useState('')

  const selectShipment = useCallback(
    (shipment) => {
      dispatchSelectedShipments({ type: 'add', payload: shipment })
    },
    [dispatchSelectedShipments]
  )

  const removeShipment = useCallback(
    (shipmentId) => {
      dispatchSelectedShipments({ type: 'remove', payload: shipmentId })
    },
    [dispatchSelectedShipments]
  )

  const columns = useMemo(
    () => [
      {
        key: 'numerator_code',
        title: 'Reference',
        isSortable: false,
        show: true,
        content: (item) => (
          <Link to={`/panel/shipments/${item?.id_auto}`} target="_blank">
            <span className="whitespace-nowrap">
              {item?.shipment_code} <ShortcutIcon size={16} />
            </span>
          </Link>
        ),
      },
      {
        key: 'client',
        title: 'Client',
        show: true,
        content: (item) => item?.client?.company_name,
      },

      {
        key: 'destination',
        title: 'Destination',
        show: true,
        content: (item) => (
          <>
            <CountryFlag iso={item?.arrival_country} />
            {item?.arrival_country && item?.arrival_city && <span>, </span>}
            {item?.arrival_city}
          </>
        ),
      },
      {
        key: 'action',
        title: '',
        show: true,
        content: (item) => <Button onClick={() => selectShipment(item)}>Add</Button>,
      },
    ],
    [selectShipment]
  )

  const selectedShipmentsTableRows = useMemo(
    () =>
      selectedShipments
        ?.filter((d) => d?.reference?.toLocaleLowerCase()?.includes(searchTerm.toLocaleLowerCase()))
        ?.map((item) => ({
          key: item?.id_auto,
          cells: [
            {
              content: (
                <Link to={`/panel/shipments/${item?.id_auto}`} target="_blank">
                  <span className="whitespace-nowrap">
                    {item?.shipment_code} <ShortcutIcon size={16} />
                  </span>
                </Link>
              ),
            },
            {
              content: item?.client?.company_name,
            },
            {
              content: (
                <>
                  <CountryFlag iso={item?.arrival_country} />
                  {item?.arrival_country && item?.arrival_city && <span>, </span>}
                  {item?.arrival_city}
                </>
              ),
            },
            {
              content: (
                <Button
                  spacing="none"
                  appearance="subtle-link"
                  onClick={() => removeShipment(item?.id_auto)}
                >
                  <TrashIcon className="text-red-400" />
                </Button>
              ),
            },
          ],
        })),

    [removeShipment, searchTerm, selectedShipments]
  )

  const selectedShipmentsTableHead = {
    cells: [
      { content: 'Reference' },
      { content: 'Client' },
      { content: 'Destination' },
      { content: '' },
    ],
  }

  return (
    <div className="mt-4">
      <div className="md:grid md:grid-cols-2 md:gap-x-4">
        <Card className="mt-0 mb-4 md:mb-0">
          <RoyanTable
            name="selectable-shipments-list"
            filterData={(item) => !selectedShipmentsId.includes(item.id_auto)}
            request={(params) => {
              if (params?.status === undefined) {
                return {
                  data: [],
                  total: 0,
                }
              }

              return getShipments(
                'dossiers',
                sectionParameter ? { ...params, section: sectionParameter } : params
              )
            }}
            filters={[
              {
                key: 'status',
                inline: true,
                content: (v) => v,
              },
            ]}
            inlineFilterFields={
              <SelectStatusInlineFilter
                options={[
                  { label: 'All', value: 'all' },
                  { label: 'Taken in charge', value: 0 },
                  { label: 'Shipper contacted', value: 1 },
                  { label: 'Collection planned', value: 2 },
                  { label: 'Arrived in hub', value: 4 },
                ]}
              />
            }
            columns={columns}
            rowsControl={false}
            columnsControl={false}
            emptyView={<EmptyState header="Please apply a filter" />}
          />
        </Card>

        <Card className="mt-0">
          <div style={{ maxWidth: '200px', marginBottom: '16px' }}>
            <SearchInput onSubmit={setSearchTerm} delay={150} />
          </div>

          <div className="overflow-auto inline-block w-full -mb-2 pb-0.5">
            <DynamicTableStateless
              head={selectedShipmentsTableHead}
              rows={selectedShipmentsTableRows}
              emptyView={<EmptyState header="No shipments selected" />}
            />
          </div>
        </Card>
      </div>
    </div>
  )
}

export default SelectShipmentsFromList
