// Hooks
import { useDownloadFinancialBalanceRegistry } from '../api/downloadFinancialBalance'

// Atlassian
import Button from '@atlaskit/button'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'
import Spinner from '@atlaskit/spinner'

const RegistriesFinancialBalanceActions = ({ registry }) => {
  const downloadFinancialBalanceMutation = useDownloadFinancialBalanceRegistry({
    registryId: registry?.id_auto,
    companyName: registry?.company_name,
  })

  const downloadFinancialReport = (e) => {
    e.stopPropagation()
    downloadFinancialBalanceMutation.mutate()
  }

  return (
    <DropdownMenu
      placement="bottom-end"
      trigger={({ triggerRef, ...props }) => (
        <Button ref={triggerRef} appearance="primary" children="Financial report" {...props} />
      )}
    >
      <DropdownItemGroup>
        <div style={{ minWidth: '115px' }}>
          <DropdownItem onClick={downloadFinancialReport}>
            Download
            {downloadFinancialBalanceMutation.isLoading && (
              <span className="ml-2">
                <Spinner size="small" />
              </span>
            )}
          </DropdownItem>
        </div>
      </DropdownItemGroup>
    </DropdownMenu>
  )
}

export default RegistriesFinancialBalanceActions
