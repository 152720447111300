import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

// Apis
import { deleteAirWaybillStock } from './stock-requests'

// Interfaces
import { useFlags, useRoyanTable } from '@royan-co/user-interface'

const useDeleteAirWaybillStock = (stockId) => {
  const navigate = useNavigate()
  const { refresh } = useRoyanTable()
  const { showSuccessFlag } = useFlags()

  return useMutation({
    mutationFn: () => deleteAirWaybillStock(stockId),

    onSuccess: (res) => {
      showSuccessFlag(res.message)
      if (refresh) {
        refresh()
      } else navigate('/panel/airwaybill-stock')
    },

    onError: (e) => {
      console.error(e)
    },
  })
}

export { useDeleteAirWaybillStock }
