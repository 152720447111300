import { Outlet } from 'react-router-dom'
import RequireAuth from 'layouts/RequireAuth'

//Components
import PanelLayoutSidebar from './Sidebar'
import AppShell from 'components/UI/AppShell'
import TopNavigation from 'layouts/TopNavigation'

const PanelLayout = () => {
  return (
    <RequireAuth>
      <AppShell
        renderTopNavigation={(props) => <TopNavigation {...props} />}
        leftSidebar={<PanelLayoutSidebar />}
      >
        <div className="mx-6 md:mx-8 my-6">
          <Outlet />
        </div>
      </AppShell>
    </RequireAuth>
  )
}

export default PanelLayout
