import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

import { createNote, deleteNote, editNote } from 'api/shipments'
import { handleErrorOnSubmit } from 'utils/errors'

//Hooks
import useShipmentNotes from 'hooks/useShipmentNotes'

//Contexts
import { useAuth } from 'contexts/AuthProvider'

//Atlassian
import Button from '@atlaskit/button'
import Spinner from '@atlaskit/spinner'
import EmptyState from '@atlaskit/empty-state'
import SectionMessage from '@atlaskit/section-message'

//Interfaces
import { PlusIcon, useDisclosure, useFlags } from '@royan-co/user-interface'

//Components
import NoteBox from 'components/Shipments/View/NoteBox'
import NoteModal from 'components/Shipments/View/NoteModal'
import DeleteNoteModal from 'components/Shipments/View/DeleteNoteModal'

const ShipmentViewNotesPage = () => {
  const { currentUser } = useAuth()
  const { showSuccessFlag } = useFlags()
  const { shipmentId, quotationId } = useParams()
  const entityId = shipmentId || quotationId
  const { notes, isLoading, isError, refetch, upsertNoteToData, deleteNoteFromData } =
    useShipmentNotes(entityId)

  const [isOpenNoteModal, noteModalHandlers] = useDisclosure('note')
  const [isOpenDeleteNoteModal, deleteNoteModalHandlers] = useDisclosure('delete')

  const isEditing = useMemo(() => noteModalHandlers?.data?.id !== undefined, [noteModalHandlers])

  const NoteCreationMutation = useMutation(
    !isEditing
      ? createNote.bind(null, entityId)
      : editNote.bind(null, noteModalHandlers?.data?.id)
  )
  const NoteDeleteMutation = useMutation(deleteNote)

  const handleDeleteNote = () => {
    const note = deleteNoteModalHandlers?.data

    NoteDeleteMutation.mutate(note.id, {
      onSuccess: (res) => {
        deleteNoteFromData(note.id)
        showSuccessFlag(res?.message)
        deleteNoteModalHandlers.close?.()
      },
    })
  }

  const handleCreateNote = (data, { setError }) => {
    const formData = isEditing
      ? data
      : {
          ...data,
          type: 1,
          creator_id: currentUser?.id,
        }

    NoteCreationMutation.mutate(formData, {
      onError: (e) => {
        handleErrorOnSubmit(e, setError, data)
      },
      onSuccess: (res) => {
        upsertNoteToData(res.note)
        showSuccessFlag(res.message)
        noteModalHandlers.close()
      },
    })
  }

  return (
    <>
      {isLoading ? (
        <div className="text-center mt-24">
          <Spinner />
        </div>
      ) : isError ? (
        <SectionMessage
          appearance="warning"
          title="Something wrong on loading notes, please retry."
        >
          <Button onClick={() => refetch()}>Retry</Button>
        </SectionMessage>
      ) : (
        <>
          <Button
            appearance="default"
            iconBefore={<PlusIcon />}
            onClick={() => noteModalHandlers.open()}
          >
            Add a note
          </Button>

          <div className="mt-6">
            {!notes || notes?.length < 1 ? (
              <EmptyState header="There is no note to display" />
            ) : (
              notes?.map((note, i) => (
                <NoteBox
                  key={i}
                  note={note}
                  className="mb-4"
                  onEdit={() => noteModalHandlers.open(note)}
                  onDelete={() => deleteNoteModalHandlers.open(note)}
                />
              ))
            )}
          </div>
        </>
      )}

      <NoteModal
        isOpen={isOpenNoteModal}
        note={noteModalHandlers.data}
        onClose={noteModalHandlers.close}
        onSubmit={handleCreateNote}
        isLoading={NoteCreationMutation.isLoading}
        isError={NoteCreationMutation.isError}
      />

      <DeleteNoteModal
        isOpen={isOpenDeleteNoteModal}
        note={deleteNoteModalHandlers.data}
        onClose={deleteNoteModalHandlers.close}
        onSubmit={handleDeleteNote}
        isLoading={NoteDeleteMutation.isLoading}
        isError={NoteDeleteMutation.isError}
      />
    </>
  )
}

export default ShipmentViewNotesPage
