// Hooks
import { useDisputeInvoice } from 'features/invoice/api/disputeInvoice'

// Components
import ActionDropdownItem from 'components/ActionDropdownItem'


const DisputeInvoiceAction = ({ invoice }) => {
  const disputeInvoiceMutation = useDisputeInvoice({ invoiceId: invoice?.id_auto })

  return invoice?.id_auto ? (
    <ActionDropdownItem
      mutation={disputeInvoiceMutation}
      label={`${invoice?.disputed ? 'Unset' : 'Set'} dispute`}
    />
  ) : null
}

export default DisputeInvoiceAction
