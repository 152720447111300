import { useEffect, useMemo, useState } from 'react'
import { useMutation } from '@tanstack/react-query'

// Apis
import { sendEmail } from 'api/communication'
import { getDefaultsForComposingEmail } from 'api/shipments'
import { createRegistryContact } from 'api/registries'

// Hooks
import { useShipmentDocuments } from 'hooks/useShipmentDocuments'
import { useAuth } from 'contexts/AuthProvider'

// Utils
import { handleErrorOnSubmit } from 'utils/errors'

//Atlassian
import Spinner from '@atlaskit/spinner'
import SectionMessage from '@atlaskit/section-message'
import Button, { LoadingButton } from '@atlaskit/button'

// Interfaces
import { useFlags } from '@royan-co/user-interface'

// Components
import Card from 'components/UI/Card'
import ComposingEmail from 'components/UI/ComposingEmail'
import { SelectTemplate } from 'features/mail'

const getViewDocumentPath = (document) => {
  const types = {
    awb: `/panel/document/airwayBill/${document.awb}`,
    shipment_fbl_id: `/panel/document/fbl/${document.shipment_fbl_id}`,
    shipment_cmr_id: `/panel/document/cmr/${document.shipment_cmr_id}`,
    seawaybill_id: `/panel/document/seawayBill/${document.seawaybill_id}`,
  }
  const documentKey = ['awb', 'shipment_fbl_id', 'shipment_cmr_id', 'seawaybill_id'].find((key) =>
    !!document[key] ? true : false
  )
  return documentKey ? types[documentKey] : null
}

const ShipmentComposingEmail = ({
  shipmentId,
  registryId,
  to = {},
  cc = {},
  content = {},
  documents = {},
  selectedDocuments,
  originTemplate,
  onCancel,
  onSuccess,
  disabledFields,
  enableCreateNewContact,
  canChangeTemplate,
}) => {
  const { currentUser } = useAuth()
  const { showSuccessFlag, showWarningFlag } = useFlags()

  const [defaultValueData, setDefaultValueData] = useState()
  const [contact, setContact] = useState()
  const [errorMessage, setErrorMessage] = useState('')
  const [selectedTemplate, setSelectedTemplate] = useState(null)

  const contactId = useMemo(() => contact?.id_auto, [contact])
  const template = useMemo(
    () => selectedTemplate || originTemplate,
    [originTemplate, selectedTemplate]
  )

  const shipmentsDocumentQuery = useShipmentDocuments(shipmentId)

  const createRegistryContactMutation = useMutation(createRegistryContact.bind(null, ''))
  const createNewContact = async (email) => {
    if (registryId) {
      try {
        const res = await createRegistryContactMutation.mutateAsync({
          registry_id: registryId,
          email,
        })
        showSuccessFlag(res?.message)
        return res.contact
      } catch (error) {
        console.error(error)
        showWarningFlag('The new contact could not be created.')
        return false
      }
    } else return true
  }

  const defaultValuesMutation = useMutation({
    mutationFn: (params) => getDefaultsForComposingEmail(shipmentId, template, contactId, params),
    onSuccess: (res) => {
      setDefaultValueData(res?.data)
    },
    onError: (error) => {
      console.error(error)
      setErrorMessage(
        error?.response?.data?.message || 'Something wrong on loading data, please retry'
      )
    },
  })

  const emailDefaultValues = useMemo(() => {
    return {
      to: defaultValueData?.recipient
        ? {
            id_auto: defaultValueData?.recipient?.id_auto,
            email: defaultValueData?.recipient?.email,
          }
        : null,
      cc: null,
      subject: defaultValueData?.subject,
      content: defaultValueData?.body,
      attachments: defaultValueData?.attachments,
    }
  }, [defaultValueData])

  const documentsList = useMemo(
    () =>
      shipmentsDocumentQuery?.data?.map((document) => ({
        id: document.id,
        title: document.name,
        url: getViewDocumentPath(document) || document.url,
        creation_date: document.creation_date,
      })) || [],
    [shipmentsDocumentQuery?.data]
  )

  const sendEmailMutation = useMutation(sendEmail)
  const onSubmit = (data, setError) => {
    let formData = {
      shipment_id: shipmentId,
      email: data.to.email,
      recipient: data.to.email,
      cc: data?.cc?.map((c) => c.email),
      subject: data.subject,
      body: data.content,
      creator_user: currentUser.id,
      attachments: data?.attachments?.map((file) => ({
        title: file.title,
        document_name: file.document_name,
        document_path: file.document_path,
        size: file.size,
        id: file?.id,
      })),
      reminder_needed: false,
    }

    if (originTemplate === 'first-contact') formData.purpose = 'first-contact'

    formData.cc = [...formData.cc, currentUser?.email]

    sendEmailMutation.mutate(formData, {
      onError: (e) => {
        handleErrorOnSubmit(e, setError, formData)
        console.error(e)
      },
      onSuccess: () => {
        showSuccessFlag('Mail document is done successfully.')
        onSuccess()
      },
    })
  }

  useEffect(() => {
    if (!!template) {
      defaultValuesMutation.mutate({
        documents: selectedDocuments,
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipmentId, contactId, template])

  return (
    <>
      <Card className="mt-6">
        {canChangeTemplate && (
          <SelectTemplate onChangeTemplate={setSelectedTemplate} defaultValue={originTemplate} />
        )}

        {template && (
          <>
            {!contactId &&
            !contact?.isNew &&
            (defaultValuesMutation.isLoading || defaultValuesMutation.isFetching) ? (
              <div className="w-full text-center my-2">
                <Spinner />
              </div>
            ) : defaultValuesMutation.isError ? (
              <div className="my-2">
                <SectionMessage appearance="warning" title={errorMessage}>
                  <Button
                    onClick={() =>
                      defaultValuesMutation.mutate({
                        documents: selectedDocuments,
                      })
                    }
                  >
                    Retry
                  </Button>
                </SectionMessage>
              </div>
            ) : defaultValueData ? (
              <>
                <ComposingEmail
                  id="composing_email_form"
                  defaultValues={emailDefaultValues}
                  disabledFields={disabledFields}
                  to={{
                    ...to,
                    onValueChange: (newContact) => setContact(newContact),
                  }}
                  cc={cc}
                  content={{ ...content, isLoading: contactId && defaultValuesMutation.isLoading }}
                  documents={{
                    ...documents,
                    list: documentsList,
                    isLoading: shipmentsDocumentQuery.isLoading,
                    isError: shipmentsDocumentQuery.isError,
                    refetch: shipmentsDocumentQuery.refetch,
                  }}
                  onCreateNewContact={enableCreateNewContact && createNewContact}
                  onSubmit={onSubmit}
                  onCancel={onCancel}
                  loadingSubmitButton={sendEmailMutation.isLoading}
                />
              </>
            ) : null}
          </>
        )}
      </Card>

      <div className="w-full text-right mt-6">
        <Button appearance="subtle" onClick={onCancel} className="mr-2">
          Cancel
        </Button>

        <LoadingButton
          type="submit"
          form="composing_email_form"
          appearance="primary"
          isLoading={sendEmailMutation.isLoading}
          isDisabled={!template || defaultValuesMutation.isLoading || defaultValuesMutation.isError}
        >
          Send
        </LoadingButton>
      </div>
    </>
  )
}

export default ShipmentComposingEmail
