import { Link, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

// Api
import { getRegistries } from 'api/registries'

// Atlassian
import PageHeader from '@atlaskit/page-header'
import Button, { ButtonGroup } from '@atlaskit/button'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'

// Interfaces
import { RoyanTable, useDisclosure } from '@royan-co/user-interface'

// Components
import RegistriesFilterFields from 'components/Registries/List/FilterFields'
import RegistryDeleteModal from 'components/Registries/View/DeleteModal'
import RegistryDeactivateModal from 'components/Registries/View/DeactivateModal'
import { generateRegistriesColumns } from 'components/Registries/List/columns'
import { registriesFilters } from 'components/Registries/List/filters'

const pageTitle = 'Registries'

const RegistriesListPage = () => {
  const navigate = useNavigate()

  const [isOpenDeleteRegistryModal, deleteRegistryModalHandler] = useDisclosure('delete-registry')
  const [isOpenDeactivateRegistryModal, deactivateRegistryModalHandler] =
    useDisclosure('deactivate-registry')

  const handleDoubleClick = (e, item) => {
    navigate(`/panel/registries/${item.registry.id_auto}/${item?.user?.auto_id}`)
  }

  const handleHighlightRow = (item) => (item.registry.deactivated ? 'removed' : false)

  const openDeleteModal = (registryId) => {
    deleteRegistryModalHandler.open(registryId)
  }

  const openDeactivateModal = (registry) => {
    deactivateRegistryModalHandler.open({
      deactivated: registry.deactivated,
      registryId: registry.id_auto,
    })
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <PageHeader
        breadcrumbs={
          <Breadcrumbs>
            <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
            <BreadcrumbsItem text="Companies" />
          </Breadcrumbs>
        }
        actions={
          <ButtonGroup>
            <Button appearance="primary" component={Link} to="/panel/registries/create">
              New registry
            </Button>
          </ButtonGroup>
        }
      >
        {pageTitle}
      </PageHeader>

      <RoyanTable
        sortOrder="DESC"
        name="registries-list"
        sortKey="creation_date"
        request={getRegistries}
        filters={registriesFilters}
        onRowDoubleClick={handleDoubleClick}
        onRowHighlight={handleHighlightRow}
        columns={generateRegistriesColumns(openDeleteModal, openDeactivateModal)}
        filterFields={<RegistriesFilterFields />}
      >
        <RegistryDeleteModal
          isOpen={isOpenDeleteRegistryModal}
          onClose={deleteRegistryModalHandler.close}
          rateId={deleteRegistryModalHandler.data}
        />
        <RegistryDeactivateModal
          isOpen={isOpenDeactivateRegistryModal}
          onClose={deactivateRegistryModalHandler.close}
          registryId={deactivateRegistryModalHandler.data?.registryId}
          deactivated={deactivateRegistryModalHandler.data?.deactivated}
        />
      </RoyanTable>
    </>
  )
}

export default RegistriesListPage
