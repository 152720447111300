import { useMutation } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

// Apis
import { changeCompanyLogo } from 'api/registries'

// Hooks
import useRegistry from 'hooks/useRegistry'

// Interfaces
import { useFlags, AspectRatio, OfficeBuildingIcon, Skeleton } from '@royan-co/user-interface'

// Atlassian
import Lozenge from '@atlaskit/lozenge'

// Components
import LogoPicker from 'components/UI/LogoPicker'

const RegistryCard = () => {
  const { registryId } = useParams()
  const { showSuccessFlag, showWarningFlag } = useFlags()

  const { registry, changeLogo, isLoading } = useRegistry(registryId)

  const changeLogoMutation = useMutation(changeCompanyLogo.bind(null, registryId))

  const onChangeImage = (file) => {
    const formData = new FormData()
    formData.append('logo', file)

    changeLogoMutation.mutate(formData, {
      onError: (e) => {
        showWarningFlag('Something wrong on changing logo.')
        console.error(e)
      },
      onSuccess: (res) => {
        changeLogo(res)
        showSuccessFlag('Logo changed successfully.')
      },
    })
  }

  return (
    <div className="shadow rounded">
      <div className="bg-neutral-20 py-8"></div>
      <div className="p-4 flex items-end justify-between">
        <AspectRatio ratio={1} className="-mt-16" style={{ width: '96px' }}>
          <LogoPicker
            imageUrl={registry?.logo}
            onChangeImage={onChangeImage}
            isLoading={changeLogoMutation.isLoading}
            placeholder={<OfficeBuildingIcon size={48} className="text-white" />}
          />
        </AspectRatio>

        {isLoading ? (
          <Skeleton height={15} maw={80} />
        ) : registry?.deactivated ? (
          <Lozenge appearance="removed">Deactivated</Lozenge>
        ) : (
          <Lozenge appearance="success">Active</Lozenge>
        )}
      </div>
    </div>
  )
}

export default RegistryCard
