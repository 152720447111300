import styles from './ActionCard.module.scss'

const ActionCard = ({ title, icon, children }) => {
  return (
    <div className={styles.actionCard}>
      <div className={styles.actionCard__header}>
        {icon && (
          <div
            className={styles.actionCard__icon}
            style={{
              bottom: '-15px',
              left: '24px',
              width: '38px',
              height: '38px',
              background: '#59ADDF',
            }}
          >
            {icon}
          </div>
        )}
      </div>

      <div className={styles.actionCard__title}>{title}</div>

      {children && <div className={styles.actionCard__content}>{children}</div>}
    </div>
  )
}

export default ActionCard
