import set from 'lodash/set'
import cloneDeep from 'lodash/cloneDeep'
import { useCallback, useEffect, useState } from 'react'

// Hooks
import useShipmentsEnumerations from 'hooks/useShipmentsEnumerations'

// Utils
import { findOption } from 'utils/helpers'

// Atlassian
import Select from '@atlaskit/select'
import TextArea from '@atlaskit/textarea'
import Textfield from '@atlaskit/textfield'
import InlineEdit from '@atlaskit/inline-edit'

// Assets
import './styles.scss'

// Components
import InlineEditableSelect from './InlineEditable/InlineEditableSelect'

const seawayBillFields = {
  number: '',
  consignor: '',
  consigned: '',
  notifyAddress: '',
  bookingNo: '',
  blNo: '',
  preCarriageBy: '',
  por: '',
  pod: '',
  pol: '',
  podis: '',
  vessel: '',
  finalDestination: '',
  noOfOriginals: '',
  description:
    'Received by the Carrier the Goods as specified blow in apparent good order and condition unless atherwise stated, to be ...',
  description2: '',
  applyTo: '',
  placeAndDateOfIssue: '',
  freightPayableAt: '',
  freight: '',
  signature: '',
  totalNumberOfContainers: '',
  packages: [{ number: '', packages: '', goods: '', gross: '', measurement: '' }],
}

const SeawayBillForm = ({ defaultValues, onChange, companyLogo, previewMode }) => {
  const placeHolder = ''
  const { isLoadingShipmentEnumeration, getSelectOptionsByKey } = useShipmentsEnumerations()
  const portOptions = getSelectOptionsByKey('ports')

  const [formData, setFormData] = useState({
    ...seawayBillFields,
    ...cloneDeep(defaultValues),
  })

  const onChangeFormData = useCallback(
    (key, value) => {
      const NewFormData = {
        ...formData,
        ...set(formData, key, value),
      }
      onChange?.(NewFormData)
      setFormData(NewFormData)
    },
    [formData, onChange]
  )

  useEffect(() => {
    onChange?.({
      ...seawayBillFields,
      ...cloneDeep(defaultValues),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="paper-document-wrapper">
      <div className="paper-document">
        <div className="fix w-50 h-8line border-top border-left l-0">
          <div className="px-2mm">Shipper</div>
          <div style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.consignor}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="7"
                  className="overflow-hidden"
                  maxHeight={7 * 1.618 + 'em'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-7line read-view-text-aria">
                  {formData.consignor || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'consignor')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-25 l-50 h-2line border-top border-left">
          <div className="px-2mm">Booking No.</div>
          <div className="input-1line" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.bookingNo}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className="h-1line read-view-text">{formData.bookingNo || placeHolder}</div>
              )}
              onConfirm={onChangeFormData.bind(null, 'bookingNo')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-25 l-75 h-2line border-top border-left border-right">
          <div className="px-2mm">B/L No.</div>
          <div className="input-1line" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.blNo}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className="h-1line read-view-text">{formData.blNo || placeHolder}</div>
              )}
              onConfirm={onChangeFormData.bind(null, 'blNo')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-50 l-50 top-2line h-22line border-top border-left border-right"></div>

        <div className="fix w-50 h-3line l-50 top-3line text-center">
          {companyLogo && <img className="h-3line" src={companyLogo} alt="" />}
        </div>

        <div className="fix w-50 h-2line l-50 top-7line pt-2mm line-height-1 text-center">
          <div className="bold size-2">Bill of Lading</div>
        </div>

        <div className="fix w-50 l-50 top-10line h-12line">
          <div className="px-1px" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.description}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="12"
                  className="overflow-hidden"
                  maxHeight={12 * 1.618 + 'em'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-12line read-view-text-aria">
                  {formData.description || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'description')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-50 h-8line border-top border-left top-8line l-0">
          <div className="px-2mm">Consignee</div>
          <div className="px-1px" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.consigned}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="7"
                  className="overflow-hidden"
                  maxHeight={7 * 1.618 + 'em'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-7line read-view-text-aria">
                  {formData.consigned || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'consigned')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-50 h-8line border-top border-left top-16line l-0">
          <div className="px-2mm">Notify</div>
          <div className="px-1px" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.notifyAddress}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="7"
                  className="overflow-hidden"
                  maxHeight={7 * 1.618 + 'em'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-7line read-view-text-aria">
                  {formData.notifyAddress || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'notifyAddress')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-25 h-2line border-top border-left border-right top-24line l-0">
          <div className="px-2mm">pre-carriage by</div>
          <div className="input-1line" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.preCarriageBy}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className="h-1line read-view-text">
                  {formData.preCarriageBy || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'preCarriageBy')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-25 h-2line border-top border-right top-24line l-25">
          <div className="px-2mm">Port of receipt</div>
          <div>
            <InlineEditableSelect
              disabled={previewMode === false}
              value={findOption(portOptions, formData.por)}
              readView={findOption(portOptions, formData.por)?.label || placeHolder}
              onChange={(data) => {
                onChangeFormData('por', data?.value)
              }}
              selectComponent={
                <Select
                  isClearable={true}
                  placeholder="Choose a port"
                  options={portOptions}
                  isLoading={isLoadingShipmentEnumeration}
                />
              }
            />
          </div>
        </div>

        <div className="fix w-25 h-2line border-top top-24line l-50">
          <div className="px-2mm">Port of loading</div>
          <div>
            <InlineEditableSelect
              disabled={previewMode === false}
              value={findOption(portOptions, formData.pol)}
              readView={findOption(portOptions, formData.pol)?.label || placeHolder}
              onChange={(data) => {
                onChangeFormData('pol', data?.value)
              }}
              selectComponent={
                <Select
                  isClearable={true}
                  placeholder="Choose a port"
                  options={portOptions}
                  isLoading={isLoadingShipmentEnumeration}
                />
              }
            />
          </div>
        </div>

        <div className="fix w-25 h-2line border-top border-left border-right top-24line l-75">
          <div className="px-2mm">No. of Original B(s)/L</div>
          <div className="input-1line" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.noOfOriginals}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className="h-1line read-view-text">
                  {formData.noOfOriginals || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'noOfOriginals')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-25 h-2line border-top border-left border-right top-26line l-0">
          <div className="px-2mm">Ocean Vessel / Voyage no.</div>
          <div className="input-1line" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.vessel}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className="h-1line read-view-text">{formData.vessel || placeHolder}</div>
              )}
              onConfirm={onChangeFormData.bind(null, 'vessel')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-25 h-2line border-top border-right top-26line l-25">
          <div className="px-2mm">Port of discharge</div>
          <div>
            <InlineEditableSelect
              disabled={previewMode === false}
              value={findOption(portOptions, formData.podis)}
              readView={findOption(portOptions, formData.podis)?.label || placeHolder}
              onChange={(data) => {
                onChangeFormData('podis', data?.value)
              }}
              selectComponent={
                <Select
                  isClearable={true}
                  placeholder="Choose a port"
                  options={portOptions}
                  isLoading={isLoadingShipmentEnumeration}
                />
              }
            />
          </div>
        </div>

        <div className="fix w-25 h-2line border-top top-26line l-50">
          <div className="px-2mm">Place of delivery</div>
          <div>
            <InlineEditableSelect
              disabled={previewMode === false}
              value={findOption(portOptions, formData.pod)}
              readView={findOption(portOptions, formData.pod)?.label || placeHolder}
              onChange={(data) => {
                onChangeFormData('pod', data?.value)
              }}
              selectComponent={
                <Select
                  isClearable={true}
                  placeholder="Choose a port"
                  options={portOptions}
                  isLoading={isLoadingShipmentEnumeration}
                />
              }
            />
          </div>
        </div>

        <div className="fix w-25 h-2line border-top border-left border-right top-26line l-75">
          <div className="px-2mm">Final destination</div>
          <div className="input-1line" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.finalDestination}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className="h-1line read-view-text">
                  {formData.finalDestination || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'finalDestination')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-100 top-28line h-2line border-left border-right border-top text-center">
          <div className="size-1-1 py-1_2line">PARTICULARS FURNISHED BY SHIPPER</div>
        </div>

        <div className="fix top-30line w-25 h-3line l-0 border-left border-top">
          <div className="px-2mm h-2line">
            Container seal no. <br /> Marks and numbers
          </div>
          <div className="input-1line" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.packages[0].number}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className="h-1line read-view-text">
                  {formData.packages[0].number || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'packages.0.number')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix top-30line w-25 h-3line l-25 border-top">
          <div className="px-2mm h-2line">No. and kind of containers/packages</div>
          <div className="input-1line" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.packages[0].packages}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className="h-1line read-view-text">
                  {formData.packages[0].packages || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'packages.0.packages')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix top-30line w-20 h-3line l-50 border-top">
          <div className="px-2mm h-2line">Description of goods</div>
          <div className="input-1line" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.packages[0].goods}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className="h-1line read-view-text">
                  {formData.packages[0].goods || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'packages.0.goods')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix top-30line w-15 h-3line l-70 border-top">
          <div className="px-2mm h-2line">Gross weight</div>
          <div className="input-1line" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.packages[0].gross}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className="h-1line read-view-text">
                  {formData.packages[0].gross || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'packages.0.gross')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix top-30line w-15 h-3line l-85 border-left-transparent border-right border-top">
          <div className="px-2mm h-2line">Measurement</div>
          <div className="input-1line" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.packages[0].measurement}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className="h-1line read-view-text">
                  {formData.packages[0].measurement || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'packages.0.measurement')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-100 h-1line top-33line border-left border-right"></div>

        <div className="fix w-100 h-14line top-34line border-left border-right">
          <div style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.description2}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="14"
                  className="overflow-hidden"
                  maxHeight={14 * 1.618 + 'em'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-14line read-view-text-aria">
                  {formData.description2 || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'description2')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-100 h-2line top-48line border-left border-right border-top flex">
          <div className="px-2mm py-2mm">Total number of containers or packages (in words):</div>

          <div className="px-2mm grow" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.totalNumberOfContainers}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className="py-2 h-2line read-view-text">
                  {formData.totalNumberOfContainers || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'totalNumberOfContainers')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-50 h-11line top-50line border-left border-top border-bottom">
          <div className="px-2mm">For delivery of goods please apply to</div>
          <div style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.applyTo}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="10"
                  className="overflow-hidden"
                  maxHeight={10 * 1.618 + 'em'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-10line read-view-text-aria">
                  {formData.applyTo || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'applyTo')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-25 l-50 h-2line top-50line border-left border-top">
          <div className="px-2mm">Freight</div>
          <div className="input-1line" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.freight}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className="h-1line read-view-text">{formData.freight || placeHolder}</div>
              )}
              onConfirm={onChangeFormData.bind(null, 'freight')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-25 l-75 h-2line top-50line border-left border-top border-right">
          <div className="px-2mm">Freight payable at</div>
          <div className="input-1line" style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.freightPayableAt}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <Textfield {...fieldProps} ref={ref} />
              )}
              readView={() => (
                <div className="h-1line read-view-text">
                  {formData.freightPayableAt || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'freightPayableAt')}
              hideActionButtons
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-50 l-50 h-3line top-52line border-left border-top border-right">
          <div className="px-2mm">Place and date of issue</div>
          <div style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.placeAndDateOfIssue}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="2"
                  className="overflow-hidden"
                  maxHeight={2 * 1.618 + 'em'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-2line read-view-text-aria">
                  {formData.placeAndDateOfIssue || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'placeAndDateOfIssue')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>

        <div className="fix w-50 l-50 h-6line top-55line border-left border-top border-right border-bottom">
          <div className="px-2mm">Stamp and Signature</div>
          <div style={{ marginTop: '-8px' }}>
            <InlineEdit
              isEditing={previewMode}
              defaultValue={formData.signature}
              editView={({ errorMessage, ...fieldProps }, ref) => (
                <TextArea
                  ref={ref}
                  minimumRows="5"
                  className="overflow-hidden"
                  maxHeight={5 * 1.618 + 'em'}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div className="h-5line read-view-text-aria">
                  {formData.signature || placeHolder}
                </div>
              )}
              onConfirm={onChangeFormData.bind(null, 'signature')}
              hideActionButtons
              // keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SeawayBillForm
