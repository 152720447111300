import { Link, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

// Api
import { getMarginCalculators } from '../api/margin-calculator-requests'

// Atlassian
import PageHeader from '@atlaskit/page-header'
import Button, { ButtonGroup } from '@atlaskit/button'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'

// Interfaces
import { RoyanTable, useDisclosure } from '@royan-co/user-interface'

// Components
import { generateMarginCalculatorColumns } from '../components/List/columns'
import { marginCalculatorFilters } from '../components/List/filters'
import MarginCalculatorFilterFields from '../components/List/FilterFields'
import MarginCalculatorDeleteModal from '../components/view/DeleteModal'

const pageTitle = 'Margin calculator'

const MarginCalculatorListPage = () => {
  const navigate = useNavigate()
  const [isOpenDeleteModal, deleteModalHandler] = useDisclosure('delete')

  const handleDoubleClick = (e, item) => {
    navigate(`/panel/margin-calculator/${item.id}`)
  }

  const openDeleteModal = (item) => {
    deleteModalHandler.open(item.id)
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <PageHeader
        breadcrumbs={
          <Breadcrumbs>
            <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
            <BreadcrumbsItem text="Sales machine" />
          </Breadcrumbs>
        }
        actions={
          <ButtonGroup>
            <Button
              appearance="primary"
              onClick={() => navigate('/panel/margin-calculator/create')}
            >
              New margin calculator
            </Button>
          </ButtonGroup>
        }
      >
        {pageTitle}
      </PageHeader>

      <RoyanTable
        sortOrder="DESC"
        name="margin-calculator-list"
        sortKey="creation_date"
        request={getMarginCalculators}
        onRowDoubleClick={handleDoubleClick}
        filterFields={<MarginCalculatorFilterFields />}
        filters={marginCalculatorFilters}
        columns={generateMarginCalculatorColumns(openDeleteModal)}
      >
        <MarginCalculatorDeleteModal
          isOpen={isOpenDeleteModal}
          onClose={deleteModalHandler.close}
          marginId={deleteModalHandler.data}
        />
      </RoyanTable>
    </>
  )
}

export default MarginCalculatorListPage
