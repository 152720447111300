import { useParams } from 'react-router-dom'
import { useMemo } from 'react'
import { useMutation } from '@tanstack/react-query'

//Utils
import { getFilled } from 'utils/helpers'
import { handleErrorOnSubmit } from 'utils/errors'

// APIS
import { createRegistryAddress, editRegistryAddress } from 'api/registries'

// Hooks
import useRegistry from 'hooks/useRegistry'

// Atlassian
import EmptyState from '@atlaskit/empty-state'
import Button from '@atlaskit/button'
import { DynamicTableStateless } from '@atlaskit/dynamic-table'

// Interfaces
import { EditIcon, TrashIcon, useDisclosure, useFlags } from '@royan-co/user-interface'

// Components
import Card from 'components/UI/Card'
import CountryFlag from 'components/UI/CountryFlag'
import BranchDrawer from '../Create/BranchDrawer'
import RegistryAddressTypeTags from '../AddressTypeTag'
import RegistryDeleteBranchModal from './DeleteBranchModal'

const tableHead = {
  cells: [
    { content: 'Address type' },
    { content: 'Country' },
    { content: 'City' },
    { content: 'Zipcode' },
    { content: 'Phone' },
    { content: '' },
  ],
}

const RegistryBranchesSection = ({ className }) => {
  const { registryId, creatorId } = useParams()
  const { showSuccessFlag } = useFlags()
  const [isOpenBranchDrawer, branchDrawerHandlers] = useDisclosure('branch')
  const [isOpenDeleteModal, deleteModalHandlers] = useDisclosure('delete-branch')
  const { registry, upsertBranchesToData, isLoading } = useRegistry(registryId, { creatorId })

  const isEdited = useMemo(() => !!branchDrawerHandlers?.data?.id_auto, [branchDrawerHandlers])

  const createRegistryAddressMutation = useMutation(
    !isEdited
      ? createRegistryAddress.bind(null, registry?.id_auto)
      : editRegistryAddress.bind(null, branchDrawerHandlers.data.id_auto)
  )

  const onSubmit = (data, { setError }) => {
    createRegistryAddressMutation.mutate(data, {
      onError: (e) => {
        handleErrorOnSubmit(e, setError, data)
        console.error(e)
      },
      onSuccess: (res) => {
        showSuccessFlag(res.message)
        upsertBranchesToData(res.address)
        branchDrawerHandlers.close()
      },
    })
  }

  const tableRows = useMemo(
    () =>
      (registry?.addresses || []).map((address, i) => ({
        cells: [
          {
            content: (
              <div className="py-1">
                <RegistryAddressTypeTags value={address.type} />
              </div>
            ),
          },
          {
            content: <CountryFlag iso={address.country_iso} />,
          },
          {
            content: address.city,
          },
          {
            content: address.cap,
          },
          {
            content: address.telephone,
          },
          {
            key: i,
            content: (
              <div className="flex justify-end">
                <Button
                  spacing="compact"
                  appearance="subtle"
                  iconBefore={<EditIcon />}
                  onClick={() =>
                    branchDrawerHandlers.open({
                      ...address,
                      uuid: address.id_auto,
                      cap: getFilled(address, 'cap', ''),
                      province: getFilled(address, 'province', ''),
                      type: address.type
                        .split(';')
                        .filter((t) => !isNaN(parseInt(t)))
                        .map((t) => +t),
                    })
                  }
                />
                <Button
                  spacing="compact"
                  appearance="subtle"
                  onClick={() => deleteModalHandlers.open(address)}
                  iconBefore={<TrashIcon className="text-red-400" />}
                />
              </div>
            ),
          },
        ],
      })),
    [branchDrawerHandlers, deleteModalHandlers, registry?.addresses]
  )

  return isLoading ? null : (
    <div className={className}>
      <div className="flex items-center">
        <h3>Branches</h3>

        <Button
          appearance="link"
          spacing="none"
          className="ml-auto"
          onClick={() => branchDrawerHandlers.open()}
        >
          New branch
        </Button>
      </div>

      <Card className="mt-2">
        {tableRows.length === 0 ? (
          <EmptyState header="No branches" />
        ) : (
          <div className="overflow-auto inline-block w-full -mb-6">
            <DynamicTableStateless head={tableHead} rows={tableRows} />
          </div>
        )}
      </Card>

      <BranchDrawer
        onSubmit={onSubmit}
        data={branchDrawerHandlers.data}
        isOpen={isOpenBranchDrawer}
        onClose={branchDrawerHandlers.close}
        isSubmitting={createRegistryAddressMutation.isLoading}
      />

      <RegistryDeleteBranchModal
        isOpen={isOpenDeleteModal}
        onClose={deleteModalHandlers.close}
        branch={deleteModalHandlers.data}
      />
    </div>
  )
}

export default RegistryBranchesSection
