import { useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { findOption } from 'utils/helpers'
import { formatNumber } from 'utils/numbers'

//Hooks
import useRate from 'hooks/useRate'
import useRateSurcharges from 'hooks/useRateSurcharges'

//Atlassian
import Lozenge from '@atlaskit/lozenge'
import Button from '@atlaskit/button'
import Spinner from '@atlaskit/spinner'
import SectionMessage from '@atlaskit/section-message'
import EmptyState from '@atlaskit/empty-state'
import { DynamicTableStateless } from '@atlaskit/dynamic-table'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'

//Interfaces
import { DotsIcon, Grid, PlusIcon, useDisclosure } from '@royan-co/user-interface'

//Components
import RowTable from 'components/UI/RowTable'
import Card, { CardSubtitle } from 'components/UI/Card'
import { calculationMethodOptions } from 'components/Rates/constants'
import RatesStatusTag from 'components/Rates/StatusTag'
import { CardTitle } from 'components/UI/Card'
import RateCostFormat from 'components/Rates/CostFormat'
import useShipmentsEnumerations from 'hooks/useShipmentsEnumerations'
import RateCreateSurchargeModal from 'components/Rates/view/CreateSurchargeModal'
import RatesDeleteSurchargeModal from 'components/Rates/view/DeleteSurchargeModal'

const RateSurchargesPage = () => {
  let { rateId } = useParams()
  const { rate } = useRate(rateId)
  const { surcharges, isLoadingRateSurcharges, isErrorRateSurcharges, refetchRateSurcharges } =
    useRateSurcharges(rateId)

  const { getEnumerationByKey, isLoadingShipmentEnumeration } = useShipmentsEnumerations()
  const [openSurchargeModal, surchargeModalHandlers] = useDisclosure('surcharge')
  const [openDeleteSurchargeModal, deleteSurchargeModalHandlers] = useDisclosure('delete-surcharge')

  const agencyRows = useCallback(
    (surcharge) => {
      let agencyRowsItems = []

      if (rate?.shipment_type === 2) {
        agencyRowsItems.push({
          title: 'Usable for AWB',
          content: surcharge?.awb_use ? (
            <Lozenge appearance="success">Yes</Lozenge>
          ) : (
            <Lozenge appearance="moved">No</Lozenge>
          ),
        })
      }

      agencyRowsItems.push(
        {
          title: 'Calculation method',
          content: findOption(calculationMethodOptions, surcharge?.surcharge?.calculation_method)
            ?.label,
        },
        {
          title: 'Rate',
          content: <RateCostFormat value={surcharge?.rate} />,
        },
        {
          title: 'Minimum',
          content: <RateCostFormat value={surcharge?.minimum} />,
        },
        {
          title: 'Maximum',
          content: <RateCostFormat value={surcharge?.maximum} />,
        },
        {
          title: 'Minimum freight rate',
          content: <RateCostFormat value={surcharge?.minimum_f_minimum} />,
        }
      )

      return agencyRowsItems
    },
    [rate]
  )

  const tableHead = useCallback(
    (calculation_method) => {
      let bracketHeadTableByMethod = []

      if (calculation_method === 1 || calculation_method === 2)
        bracketHeadTableByMethod.push({ content: 'From (Kgs)' }, { content: 'To (Kgs)' })

      if (calculation_method === 3)
        bracketHeadTableByMethod.push({ content: 'From (EUR)' }, { content: 'To (EUR)' })

      if (calculation_method === 5)
        bracketHeadTableByMethod.push({ content: 'From (Pkgs)' }, { content: 'To (Pkgs)' })

      if (calculation_method === 6) {
        if (rate.shipment_type === 0) bracketHeadTableByMethod.push({ content: 'Truck type' })
        if (rate.shipment_type === 1) bracketHeadTableByMethod.push({ content: 'Container type' })
      }

      bracketHeadTableByMethod.push({ content: 'Rate' }, { content: 'Fix' })

      return {
        cells: bracketHeadTableByMethod,
      }
    },
    [rate.shipment_type]
  )

  const tableRows = useCallback(
    (brackets, calculation_method) =>
      brackets?.map((item) => {
        let bracketRowsByMethod = []

        if ([1, 2, 3, 5].includes(calculation_method)) {
          // Gross weight, Chargeable weight, Freight charge,Number of collies
          bracketRowsByMethod = [
            {
              content: formatNumber(item?.from),
            },
            {
              content: formatNumber(item?.to),
            },
          ]
        } else if (calculation_method === 6) {
          // Transportation unit
          bracketRowsByMethod = [
            {
              content: isLoadingShipmentEnumeration ? (
                <Spinner size="small" />
              ) : (
                getEnumerationByKey(rate.shipment_type === 0 ? 'truck_types' : 'container_types')[
                  item.transportation_unit
                ]
              ),
            },
          ]
        }

        return {
          cells: [
            ...bracketRowsByMethod,
            {
              content: <RateCostFormat value={item?.price} />,
            },
            {
              content: <RatesStatusTag value={item?.fix} />,
            },
          ],
        }
      }),
    [getEnumerationByKey, rate.shipment_type, isLoadingShipmentEnumeration]
  )

  return (
    <>
      {isLoadingRateSurcharges ? (
        <div className="text-center mt-24">
          <Spinner />
        </div>
      ) : isErrorRateSurcharges ? (
        <SectionMessage
          appearance="warning"
          title="Something wrong on loading surcharges, please retry."
        >
          <Button onClick={() => refetchRateSurcharges()}>Retry</Button>
        </SectionMessage>
      ) : (
        <>
          <Button
            appearance="default"
            iconBefore={<PlusIcon />}
            onClick={() => {
              surchargeModalHandlers.open()
            }}
          >
            Add new surcharge
          </Button>

          {!surcharges || surcharges?.length < 1 ? (
            <EmptyState header="No surcharges are available." />
          ) : (
            <>
              {surcharges?.map((surcharge, index) => (
                <Card className="mt-6" key={index}>
                  <div className="flex justify-between">
                    <CardTitle className="mb-2 mt-1 text-base">
                      {surcharge?.surcharge?.name}
                    </CardTitle>
                    <DropdownMenu
                      placement="bottom-end"
                      trigger={({ triggerRef, ...props }) => (
                        <Button
                          {...props}
                          appearance="subtle"
                          children={<DotsIcon label="more" />}
                          ref={triggerRef}
                        />
                      )}
                    >
                      <DropdownItemGroup>
                        <DropdownItem
                          children="Edit"
                          onClick={() => surchargeModalHandlers.open(surcharge)}
                        />
                        <DropdownItem
                          children="Delete"
                          onClick={() => deleteSurchargeModalHandlers.open(surcharge)}
                        />
                      </DropdownItemGroup>
                    </DropdownMenu>
                  </div>

                  <Grid grow className="w-full">
                    <Grid.Col lg={6}>
                      <RowTable rows={agencyRows(surcharge)} width="w-1/2" />
                    </Grid.Col>

                    {[1, 2, 3, 5, 6].includes(surcharge?.surcharge?.calculation_method) &&
                      surcharge?.brackets?.length > 0 && (
                        <Grid.Col lg={6}>
                          <CardSubtitle>Brackets</CardSubtitle>

                          <div className="inline-block w-full -mb-6">
                            <DynamicTableStateless
                              head={tableHead(surcharge?.surcharge?.calculation_method)}
                              rows={tableRows(
                                surcharge?.brackets,
                                surcharge?.surcharge?.calculation_method
                              )}
                            />
                          </div>
                        </Grid.Col>
                      )}
                  </Grid>
                </Card>
              ))}
            </>
          )}

          <RateCreateSurchargeModal
            isOpen={openSurchargeModal}
            onClose={surchargeModalHandlers.close}
            data={surchargeModalHandlers.data}
            shipmentType={rate?.shipment_type}
          />

          <RatesDeleteSurchargeModal
            isOpen={openDeleteSurchargeModal}
            onClose={deleteSurchargeModalHandlers.close}
            surcharge={deleteSurchargeModalHandlers.data}
          />
        </>
      )}
    </>
  )
}

export default RateSurchargesPage
