import { useState, useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import dayjs from 'dayjs'

// Atlassian
import PageHeader from '@atlaskit/page-header'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'
import Button, { LoadingButton } from '@atlaskit/button'
import Spinner from '@atlaskit/spinner'
import SectionMessage from '@atlaskit/section-message'

//Contexts
import { useApp } from 'contexts/AppProvider.js'

// Hooks
import { useInvoiceDefaultValues } from '../api/getIvoiceDefaultValues'

// Utils
import { getFilled } from 'utils/helpers'
import { handleErrorOnSubmit } from 'utils/errors'

// Apis
import { createInvoice } from 'features/invoice/api/createInvoice'

// Interfaces
import { Grid, Form, useFlags, WarningBannerField } from '@royan-co/user-interface'

// Components
import CreateInvoiceFormContainer from '../components/CreateInvoiceFormContainer'

const CreateActiveInvoice = () => {
  const { invoiceId } = useParams()
  const navigate = useNavigate()
  const { showSuccessFlag } = useFlags()
  const { setLoadingOverlay } = useApp()

  const [client, setClient] = useState(null)

  const isEditing = !!invoiceId
  const pageTitle = isEditing ? 'Edit active invoice' : 'New active invoice'

  const { data, isLoading, isError, refetch } = useInvoiceDefaultValues(
    {
      resource: 'shipments',
      client_id: client,
    },
    { enabled: !!client }
  )

  const createInvoiceMutation = useMutation(createInvoice)

  const defaultValues = useMemo(() => {
    return {
      currency: 'EUR',
      document_type: 'FTE',
      payment_condition: getFilled(data, 'payment_condition.code', ''),
      bank: getFilled(data, 'bank.id_auto', ''),
      notes: getFilled(data, 'notes', ''),
      client: data?.client?.id_auto
        ? {
            label: data?.client?.company_name,
            value: data?.client?.id_auto,
            imported: data?.client?.arca_imported,
          }
        : null,
    }
  }, [data])

  useEffect(() => {
    if (client && !isLoading) setLoadingOverlay(false)
  }, [client, isLoading, setLoadingOverlay])

  const onSubmitIssueInvoice = (data, { setError }) => {
    const formData = {
      ...data,
      invoice_date: dayjs().format('YYYY-MM-D'),
      rows: data.rows.filter((row) => row.checked).map(({ checked, ...row }) => ({ ...row })),
      client: data?.client?.value,
      active_passive: 0,
    }

    createInvoiceMutation.mutate(formData, {
      onError: (e) => {
        handleErrorOnSubmit(e, setError, data)
      },
      onSuccess: (res) => {
        showSuccessFlag(res?.message)
        navigate(`/panel/active-invoices/${res?.invoice?.id_auto}`, { replace: true })
      },
    })
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <Grid className="w-full">
        <Grid.Col md={10} xl={8} offsetMd={1} offsetXl={2}>
          <PageHeader
            breadcrumbs={
              <Breadcrumbs>
                <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
                <BreadcrumbsItem text="Accounting" to="" component={Link} />
                <BreadcrumbsItem
                  text="Active invoices"
                  to="/panel/active-invoices"
                  component={Link}
                />
                {/* {invoiceId && (
                  <BreadcrumbsItem
                    text={
                      isLoading ? <Spinner size="xsmall" /> : `Invoice ${invoice?.invoice_number}`
                    }
                    to={`/panel/passive-invoices/${invoiceId}`}
                    component={Link}
                  />
                )} */}
              </Breadcrumbs>
            }
          >
            {pageTitle}
          </PageHeader>

          <div className="py-4">
            {isError ? (
              <SectionMessage
                appearance="warning"
                title="Something wrong on loading data, please retry."
              >
                <Button onClick={() => refetch()}>Retry</Button>
              </SectionMessage>
            ) : (
              <Form onSubmit={onSubmitIssueInvoice} defaultValues={defaultValues}>
                {() => (
                  <>
                    <CreateInvoiceFormContainer
                      defaultValues={defaultValues}
                      setClient={setClient}
                      client={client}
                    />

                    <WarningBannerField />

                    <LoadingButton
                      className="!table ml-auto mt-6"
                      appearance="primary"
                      type="submit"
                      isLoading={createInvoiceMutation.isLoading}
                    >
                      {isEditing ? 'Edit invoice' : 'Issue invoice'}
                    </LoadingButton>
                  </>
                )}
              </Form>
            )}
          </div>
        </Grid.Col>
      </Grid>
    </>
  )
}

export default CreateActiveInvoice
