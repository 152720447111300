import React from 'react'
import { Outlet } from 'react-router-dom'

//Atlassian
import { Main } from '@atlaskit/page-layout'

// Features
import { PSXNavbar } from 'features/PSXNavbar'

const PublicPagesLayout = () => {
  return (
    <>
      <PSXNavbar />

      <Main>
        <div className="px-6 pt-6">
          <Outlet />
        </div>
      </Main>
    </>
  )
}

export default PublicPagesLayout
