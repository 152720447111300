import { Link, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

// Contexts
import { FinanceEditingProvider } from 'contexts/FinanceEditingProvider'

//Atlassian
import PageHeader from '@atlaskit/page-header'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'

// Interfaces
import { Grid } from '@royan-co/user-interface'

//Components
import EditFinance from 'components/Shipments/EditFinance/index.js'

const ShipmentViewEditFinancePage = () => {
  const { shipmentId, quotationId } = useParams()
  const entityType = 'shipment'
  const entityId = quotationId || shipmentId

  const pageTitle = 'Edit finance'

  return (
    <FinanceEditingProvider entityType={entityType} entityId={entityId}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <Grid className="w-full">
        <Grid.Col md={11} offsetMd={0.5}>
          <PageHeader
            breadcrumbs={
              <Breadcrumbs>
                <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
                <BreadcrumbsItem text="Quotations" to="/panel/quotations" component={Link} />
                <BreadcrumbsItem
                  text="View quotation"
                  to={`/panel/quotations/${quotationId}`}
                  component={Link}
                />
                <BreadcrumbsItem
                  text="Finance"
                  to={`/panel/quotations/${quotationId}/finance`}
                  component={Link}
                />
              </Breadcrumbs>
            }
          >
            {pageTitle}
          </PageHeader>

          <EditFinance />
        </Grid.Col>
      </Grid>
    </FinanceEditingProvider>
  )
}

export default ShipmentViewEditFinancePage
