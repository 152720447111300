import { useState } from 'react'
import { findOption } from 'utils/helpers'
import { useMutation } from '@tanstack/react-query'

// Api
import { editInvoice } from '../api/invoice-requests'

// Hooks
import useInvoiceEnumerations from 'hooks/useInvoiceEnumerations'
import { useUpdateInvoice } from '../api/getInvoice'

// Atlassian
import Select from '@atlaskit/select'
import Spinner from '@atlaskit/spinner'
import Lozenge from '@atlaskit/lozenge'
import InlineEdit from '@atlaskit/inline-edit'

// Interfaces
import { useFlags } from '@royan-co/user-interface'

const InvoiceDocumentTypeInlineEdit = ({ documentType, canEdit = true, invoiceId }) => {
  const { showSuccessFlag, showWarningFlag } = useFlags()
  const [isEditMode, setIsEditMode] = useState(false)

  const { bufferInvoiceDocCodesOptions, isLoadingInvoiceEnumerations } = useInvoiceEnumerations({
    enabled: isEditMode,
  })
  const { upsertInvoiceProperties } = useUpdateInvoice(invoiceId)

  const changeDataMutation = useMutation({
    mutationFn: (data) =>
      editInvoice(invoiceId, 'do', {
        do: data.value,
      }),

    onSuccess: (res) => {
      showSuccessFlag(res.message)
      upsertInvoiceProperties(res.invoice)
    },

    onError: (e) => {
      console.error(e)
      showWarningFlag(e?.response?.data?.message || 'Something wrong happened.')
    },
  })

  const handleChangeDocument = (data) => {
    if (documentType?.code === data.value) return

    changeDataMutation.mutate(data)
  }

  const viewTag = documentType?.code ? (
    `${documentType.code} - ${documentType.description}`
  ) : (
    <Lozenge>Not set</Lozenge>
  )

  return !canEdit ? (
    viewTag
  ) : (
    <div className="!-mt-2">
      <InlineEdit
        keepEditViewOpenOnBlur={true}
        onEdit={() => setIsEditMode(true)}
        defaultValue={findOption(bufferInvoiceDocCodesOptions, documentType?.code)}
        editView={({ errorMessage, ...fieldProps }) => (
          <Select
            spacing="compact"
            options={bufferInvoiceDocCodesOptions}
            isLoading={isLoadingInvoiceEnumerations}
            {...fieldProps}
          />
        )}
        readView={() => (
          <div className="flex items-center font-normal gap-1 my-1.5">
            {viewTag}
            {changeDataMutation.isLoading && <Spinner size="small" />}
          </div>
        )}
        onConfirm={(value) => handleChangeDocument(value)}
      />
    </div>
  )
}

export default InvoiceDocumentTypeInlineEdit
