import MenuItem from './'

import styles from './menu.module.scss'

const Dropdown = ({ submenus, dropdown, depthLevel }) => {
  depthLevel = depthLevel + 1
  return (
    <ul
      className={
        styles.dropdown +
        ' ' +
        (depthLevel > 1 ? styles.dropdownSubmenu : '') +
        ' ' +
        (dropdown ? styles.show : '')
      }
    >
      {submenus.map((submenu, index) => (
        <MenuItem items={submenu} key={index} depthLevel={depthLevel} />
      ))}
    </ul>
  )
}

export default Dropdown
