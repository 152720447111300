import { useQuery } from '@tanstack/react-query'

// Apis
import { getLine } from '../api/requests'

export const useLine = (lineId, options = {}) => {
  return useQuery({
    queryKey: ['line', lineId],
    queryFn: () => getLine(lineId),
    staleTime: Infinity,
    ...options,
  })
}
